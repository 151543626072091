export const SET_STATUS = 'statusChange/SET_STATUS';

const initialValues = {
  loading: false,
  loaded: false,
  error: false,
  status: null,
};

export default (state = initialValues, action) => {
  switch (action.type) {
  case SET_STATUS:
    return {
      ...state,
      status: action.payload,
    };

  default:
    return state;
  }
};

export const setStatus = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_STATUS,
      payload: data,
    });
  };
};
