export default {

  // Employee Module access
  GET_EMPLOYEE_LIST: '/employee/list',
  EMPLOYEE_ADD: '/employee/add',
  EMPLOYEE_PROFILE: '/employee/details/profile',
  EMPLOYEE_ADDRESS: '/employee/details/address',
  EMPLOYEE_DOCUMENT: '/employee/details/document',
  EMPLOYEE_OFFER_LETTER: '/employee/details/offerletter',
  EMPLOYEE_REFERENCES: '/employee/details/references',
  EMPLOYEE_BENEFITS: '/employee/details/benefits',
  EMPLOYEE_HR_SECTION: '/employee/details/hrsection',
  EMPLOYEE_BANK_DETAILS: '/employee/details/bankdetails',
  EMPLOYEE_SALARY: '/employee/details/salary',
  EMPLOYEE_PASSPORT: '/employee/details/passportvisa',
  EMPLOYEE_SMS_SECTION: '/employee/details/smsSection',
  COMMITEE_MEETING_EMPLOYEE: '/committee/meeting/list',
  COMMITEE_MEETING_STATUS_REPORT: '/committee/meeting/status/report',
  EMPLOYEE_COMMITTEE_LIST: '/committee/list',
  EMPLOYEE_ALERT_CONFIG: '/employee/configuration/mail/alert',
  EMPLOYEE_ON_DEMAND_ALERT: '/employee/configuration/on-demand/alert',

  // Accounting Module Access
  ADMIN_REVENUE_MONTHLY: '/accounting/revenue/monthly',
  ADMIN_REVENUE_WEEKLY: '/accounting/revenue/weekly',
  ADMIN_NON_CLINICAL_REVENUE: '/accounting/revenue/non-clinical',
  ADMIN_REVENUE_LIST: '/accounting/revenue',
  NON_CLINICAL_INVOICE_LIST: '/accounting/invoices/non-clinical',
  HOUSING_INVOICE_LIST: '/accounting/invoices/housing',
  DUE_INVOICE_LIST: '/accounting/invoices/due',
  ESTIMATE_LIST: '/accounting/invoices/estimate',
  SCHOLAR_SHIP: '/accounting/scholarship',
  MED_6_LOA: '/accounting/loa/med6Loa',
  UNVERIFIED_PAYMENTS: '/accounting/unverified-notifications',
  ACCOUNTING_INVOICE_REPORTS_LIST: '/accounting/invoice-requests',
  ACCEPTED_LEAD: '/accounting/lead',
  ADMIN_ACCOUNTING_INVOICE_REMINDER: '/accounting/invoices/send-reminder',
  ACCOUNTING_LOA_CLINICAL: '/accounting/loa/clinicalLoa',
  ACCOUNTING_PAYMENT: '/accounting/payments/list',

  ACCOUNTING_NOTIFICATIONS_ICON: '/accounting/notifications',

  // Admin Module access
  USER: '/admin/user',
  MENU: '/admin/menu/list',
  ADMIN_GROUP: '/admin/group/list',
  GROUP_ACCESS: '/admin/group/access',
  COMPONENT: '/admin/component/list',
  ALERT_CONFIG: '/admin/alert/configuration',
  ASSIGN_CLASS: '/admin/assign-class',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_MENU_ACCESS: '/admin/menu-access',
  ADMIN_ON_DEMAND_TRIGGER: '/admin/on-demand-trigger',

  // Admin Lead Settings
  ADMIN_LEAD_SETTINGS_FACULTY_TIMESLOT: '/admin/lead-settings/faculty-timeslot',
  ADMIN_LEAD_CURRENT_SEMESTER: '/admin/lead-settings/lead-current-semester',
  LEAD_TASK_SUBJECTS_LIST: '/admin/lead-settings/lead-task',
  LEAD_SOURCE_LIST: '/admin/lead-settings/lead-sources',

  // Admin Accounting Settings
  ADMIN_FEE_HEAD: '/admin/accounting-settings/feehead/list',
  ADMIN_FEE_GROUP_HEAD: '/admin/accounting-settings/feegrouphead/list',
  ADMIN_REVENUE_RECOGNITION_SETTING: '/admin/accounting-settings/revenue-setting',
  ADMIN_ACCOUNTING_HEAD: '/admin/accounting-settings/accounting-head',
  ADMIN_MASTER_VALUES: '/admin/accounting-settings/master-values/list',
  ADMIN_INSTALLMENT_END_DATE: '/admin/accounting-settings/installment-end-date',
  ADMIN_BULK_UPDATE_ACCOUNTING: '/admin/accounting-settings/bulk-update/accounting',
  ADMIN_BULK_UPDATE_FINANCIAL_STATUS: '/admin/accounting-settings/bulk-update/financial-status',
  ADMIN_BULK_UPDATE_INVOICE_FINANCIAL_STATUS: '/admin/accounting-settings/bulk-update/invoice-financial-status',
  ADMIN_IMMIGRATION_DEPOSIT_FEE: '/admin/accounting-settings/immigration-deposit',
  ADMIN_ACCOUNTING_FEE_LEAD: '/admin/accounting-settings/fees/leads',
  ADMIN_ACCOUNTING_FEE_PREMED: '/admin/accounting-settings/fees/pre-med',
  ADMIN_ACCOUNTING_FEE_MED: '/admin/accounting-settings/fees/med',
  ADMIN_ACCOUNTING_FEE_MED6: '/admin/accounting-settings/fees/med6',
  ADMIN_ACCOUNTING_FEE_NON_CLINICAL_OTHER: '/admin/accounting-settings/fees/nonClinicalOther',
  ADMIN_ACCOUNTING_FEE_INSURANCE: '/admin/accounting-settings/fees/insurance',
  ADMIN_ACCOUNTING_FEE_CLINICAL: '/admin/accounting-settings/fees/clinical',
  ADMIN_ACCOUNTING_FEE_CLINICAL_OTHER: '/admin/accounting-settings/fees/clinical-other',
  ADMIN_ACCOUNTING_FEE_GENERAL: '/admin/accounting-settings/fees/general',
  ADMIN_ACCOUNTING_FEE_INSTALLMENT: '/admin/accounting-settings/fees/installment',
  ADMIN_ACCOUNTING_FEE_HOUSING_FEE: '/admin/accounting-settings/fees/housing-fees',
  ADMIN_ACCOUNTING_FEE_BASIC_SCIENCE_LOA: '/admin/accounting-settings/fees/basic-science-loa',
  ADMIN_ACCOUNTING_FEE_ASP: '/admin/accounting-settings/fees/asp',
  ADMIN_ACCOUNTING_FEE_PREMEDLOA: '/admin/accounting-settings/fees/premed-loa',
  // Admin Control Table
  ADMIN_SEMESTER_CTL: '/admin/control-table/admin/semester',
  SEMESTER_STUDENT_FOLDER: '/admin/control-table/admin/semester-student-folder',
  STUDENT_STATUS_CT: '/admin/control-table/admin/student-status',
  REGISTRATION_DATE: '/admin/control-table/admin/registration-dates',
  ADMIN_COURSE_LIST: '/admin/control-table/admin/course',
  ADMIN_STAGE_CTL: '/admin/control-table/admin/stage',
  ADMIN_TASK_PRIORITY: '/admin/control-table/admin/task-priority',
  ADMIN_HELP_TEXT: '/admin/control-table/admin/help-text',
  ADMIN_COUNTRY_LIST: '/admin/control-table/admin/country',
  ADMIN_DOCUMENT_CATEGORY_LIST: '/admin/control-table/admin/document-category',
  ADMIN_DOCUMENT_TEMPLATE: '/admin/control-table/admin/document-template',
  ADMIN_EMAIL_TEMPLATE: '/admin/control-table/admin/email-template',
  ADMIN_ESL_FOLDER: '/admin/control-table/admin/esl-student-folder',
  ADMIN_NURSING_STUDENT_FOLDER: '/admin/control-table/admin/nursing-student-folder',
  ADMIN_REPLY_TO: '/admin/control-table/admin/reply-to',
  ADMIN_SEMESTER_TYPE: '/admin/control-table/admin/semester-type',

  // HR Control Table
  ARUBA_INCOMING_MAIL_LOG: '/admin/control-table/hr/aruba-incoming-mail-logs',
  HR_INCOMING_MAIL_LOG: '/admin/control-table/hr/incoming-mail-logs',
  HR_POSITIONS: '/admin/control-table/hr/positions',
  HR_TODO_STATUS: '/admin/control-table/hr/todo',
  HR_EMPLOYEE_STATUS: '/admin/control-table/hr/employee-status',
  HR_ARUBA_OUTGOING_LIST: '/admin/control-table/hr/arubaoutgoingtype',
  HR_OUTGOING_LIST: '/admin/control-table/hr/outgoingmailtype',

  // Clinicals Control Table
  CLINICAL_DOCUMENT_TYPE: '/admin/control-table/clinicals/document',
  ROTATION: '/admin/control-table/clinicals/rotation',
  ROTATION_SYLLABUS: '/admin/control-table/clinicals/rotation/syllabus',
  CLINICAL_PROBLEMS: '/admin/control-table/clinicals/problems',
  CLINICAL_OPTIONS: '/admin/control-table/clinicals/options',

  // Lead Control Table
  LEAD_REFERRAL: '/admin/control-table/lead/referral',
  LEAD_SCHOOL: '/admin/control-table/lead/school',
  PREMED_HIGHEST_DEGREE: '/admin/control-table/lead/premed',

  // Registrar Control Table
  RESIDENCY_TYPE: '/admin/control-table/registrar/residency/type',
  RESIDENCY_SPECIALITY: '/admin/control-table/registrar/residency/speciality',
  GRADE_POINT_TYPE: '/admin/control-table/registrar/grade/type',
  CT_ACADEMIC_STANDINGS: '/admin/control-table/registrar/academic/standing',
  SUBJECT: '/admin/control-table/registrar/subject',
  SHELF_DISCIPLINE: '/admin/control-table/registrar/shelf',
  COMP_DISCIPLINE: '/admin/control-table/registrar/comp',
  ADMIN_SCHOLARSHIP_CTL: '/admin/control-table/registrar/scholarship',
  REGISTRAR_CLASS_LIST: '/admin/control-table/registrar/classes',
  REGISTRAR_DEGREE_LIST: '/admin/control-table/registrar/degree',
  REGISTRAR_EQUIVALANCE_LIST: '/admin/control-table/registrar/equivalence-score',
  REGISTRAR_LOA_REASON_LIST: '/admin/control-table/registrar/loa-reason',
  REGISTRAR_GRADE_POINT_SETTING_LIST: '/admin/control-table/registrar/grade-point-settings',

  // Accounting Control Table
  SHIPPING_CHARGES: '/admin/control-table/accounting/shipping-price',
  ACCOUNT_NAME_ACCOUNTING: '/admin/control-table/accounting/account-name',
  FINANCIAL_AID: '/admin/control-table/accounting/financial-aid',

  // Housing Control Table
  HOUSING_ROOM_CT: '/admin/control-table/housing/rooms',
  HOUSING_MEAL_CT: '/admin/control-table/housing/meal-plan',
  HOUSING_DATES_CT: '/admin/control-table/housing/movingDates',
  HOUSING_FEE_SETTING: '/admin/control-table/housing/fee-setting',
  HOUSING_ROOM_FEE_SETTING: '/admin/control-table/housing/fee-setting/room/list',
  HOUSING_MEAL_PLAN_SETTING: '/admin/control-table/housing/fee-setting/meal-plan/list',
  HOUSING_DAILY_ROOM_FEE_SETTING: '/admin/control-table/housing/fee-setting/daily-fee/list',

  // Applicant Control table
  APPLICANT_SEMESTER_YEAR: '/admin/control-table/applicant/semester/year',

  // Lead Module
  LEAD_LIST: '/lead/list',
  LEAD_ADD: '/lead/list/add',
  LEAD_PROFILE: '/lead/details/profile',
  LEAD_PROFILE_PERSONAL: '/lead/details/profile/edit',
  LEAD_ADDRESS: '/lead/details/address',
  LEAD_MORE_INFO_EDIT: '/lead/details/profile/more-info',
  LEAD_PROFILE_MAILING: '/lead/details/profile/address',
  LEAD_STATUS_CHANGE: '/lead/details/status-change',
  LEAD_SEMESTER_DEFERMENT: '/lead/details/semester',
  LEAD_EMAIL: '/lead/details/email',
  LEAD_NOTES: '/lead/details/notes',
  LEAD_PHONE_NOTES: '/lead/details/phone-notes',
  LEAD_TASK_ADD: '/lead/details/task',
  LEAD_TASK_EDIT: '/lead/details/task/edit',
  LEAD_TASK_FORM: '/lead/details/task/fillForm',
  LEAD_TASK_DOWNLOAD: '/lead/details/task/download',
  LEAD_ACTIVITY_LOG: '/lead/details/activity-log',
  LEAD_DOCUMENT: '/lead/details/document',
  LEAD_ADMISSION_FORMS: '/lead/details/admissionforms',
  LEAD_APPLICATION_ADDRESS: '/lead/details/application/addressTab/address',
  LEAD_APPLICATION_BACKGROUND: '/lead/details/application/addressTab/background',
  LEAD_APPLICATION_ACADEMIC: '/lead/details/application/addressTab/academics',
  LEAD_APPLICATION_ADDITIONAL_QUESTIONS: '/lead/details/application/addressTab/additonal-questions',
  LEAD_APPLICATION_SUBMIT_APPLICATION: '/lead/details/application/addressTab/submit',
  LEAD_APPLICATION_DOWNLOAD: '/lead/details/application/addressTab/download',
  LEAD_ESTIMATES: '/lead/details/estimate',
  LEAD_ACADEMIC_RECORDS: '/lead/details/academicrecords',
  LEAD_IMMIGRATION_PERMIT: '/lead/details/immigration/document/permit',
  LEAD_IMMIGRATION_CENSUS: '/lead/details/immigration/document/census',
  LEAD_IMMIGRATION_DIMAS: '/lead/details/immigration/document/dimas',
  LEAD_IMMIGRATION_DOCUMENT: '/lead/details/immigration/document',
  LEAD_IMMIGRATION_DOCUMENT_VERIFY: '/lead/details/immigration/document/verify',
  LEAD_IMMIGRATION_DZB: '/lead/details/immigration/document/dzb',
  LEAD_IMMIGRATION_SBV: '/lead/details/immigration/document/sbv',
  LEAD_IMMIGRATION_TEST: '/lead/details/immigration/document/test',
  LEAD_IMMIGRATION_VISA: '/lead/details/immigration/document/visa',
  LEAD_IMMIGRATION_VTA: '/lead/details/immigration/document/vta',

  // Lead Dashboard
  LEAD_DASHBOARD: '/lead/dashboard',

  // Lead Reports
  LEAD_REPORTS_STATUS_WISE: '/lead/reports/status-wise',
  LEAD_REPORTS_STATUS_CONVERSION: '/lead/reports/status-conversion',
  LEAD_REPORTS_PENDING_LEADS: '/lead/reports/pending-leads',
  LEAD_REPORTS_DAILY_ACTIVITY_COUNT: '/lead/reports/activity-count',
  LEAD_REPORTS_TASK_COUNT: '/lead/reports/task-count',
  LEAD_REPORTS_SEAT_DEPOSIT_DUE: '/lead/reports/seat-deposit-due',
  LEAD_REPORTS_ADVANCED: '/lead/reports/advanced',
  LEAD_REPORTS_INTERVIEW_SCHDULED: '/lead/reports/interview-scheduled',
  LEAD_REPORTS_LEAD_PAID: '/lead/reports/lead-paid',

  // Lead Tasks
  LEAD_TASK_TODAY: '/lead/task/search',
  LEAD_TASK_ALL: '/lead/task/all-tasks',

  // Lead Unverified
  LEAD_UNVERIFIED_EMAIL: '/lead/unverified/email',

  // Lead Admin
  LEAD_ADMIN_LEAD_MERGE: '/lead/lead-admin/lead-merge',
  LEAD_ADMIN_CLOSED_LEAD: '/lead/lead-admin/closed-leads',
  LEAD_ADMIN_REPVACATION: '/lead/lead-admin/rep-vacation',
  LEAD_ADMIN_DUPLICATE_LEAD: '/lead/lead-admin/duplicate-lead',
  LEAD_EMAIL_TEMPLATE: '/lead/lead-admin/email-template',
  LEAD_BULK_UPDATE: '/lead/lead-admin/bulk-update',

  // Lead Notification
  LEAD_NOTIFICATION_DOCUMENT: '/lead/notifications/documents',
  LEAD_BULK_UPLOAD: '/lead/notifications/bulk-upload',
  LEAD_UNASSIGNED_LEAD: '/lead/notifications/unassigned-lead',
  LEAD_NOTIFICATION_COLLEGE_FAIR: '/lead/notifications/fairlist',

  // Student Module Access
  STUDENT_PROFILE_PHOTO: '/student/details/',
  STUDENT_ADD: '/student/add',
  STUDENT_ADDRESS: '/student/details/address',
  STUDENT_ADDRESS_MORE: '/student/details/address/more',
  STUDENT_NOTES: '/student/details/notes',
  STUDENT_MED6_NOTES: '/student/details/med6notes',
  STUDENT_ACCOUNTING_NOTES: '/student/details/accountingnotes',
  STUDENT_ALUMNI: '/student/details/alumni',
  STUDENT_LIST: '/student/list',
  STUDENT_PERSONAL: '/student/details/personal',
  STUDENT_PROFILE: '/student/details/profile',
  CLINICAL_DOCUMENT: '/student/details/document/studentdocument/clinicaldocument',
  STUDENT_DOCUMENT: '/student/details/document/studentdocument/document',
  STUDENT_ACADEMIC_DETAILS: '/student/details/academicdetails',
  DOCUSIGN_DOC: '/student/details/docusign',
  QUERIES: '/student/details/queries',
  STUDENT_USMLE: '/student/details/usmletest',
  STUDENT_LEAVE: '/student/details/leave',
  STUDENT_COURSE: '/student/details/courses',
  STUDENT_COURSE_NO_OF_WEEKS: '/student/details/course/edit',
  STUDENT_ROTATION: '/student/details/rotation',
  STUDENT_REQUESTS: '/student/details/requests',
  STUDENT_STATUS: '/student/details/status-change',
  STUDENT_ACTIVITY_LOG: '/student/details/activity-log',
  CREDITS_AND_SCHOLARSHIP: '/student/details/credits-scholarship',
  STUDENT_CLINICAL_LOG: '/student/details/courses/clinical-log/view',
  STUDENT_ACCOUNTING_DETAILS: '/student/details/accounting-details',
  STUDENT_TRANSCRIPT: '/student/details/transcript',
  STUDENT_ACADEMIC_RECORDS: '/student/details/academicrecords',
  STUDENT_INVOICE_PAYMENTS: '/student/details/invoice-payments',
  STUDENT_CANCEL_INVOICE: '/student/details/invoice-payments/cancel-invoice',
  STUDENT_INVOICE_PAYMENTS_CANCEL_PAYMENT: '/student/details/invoice-payments/cancel-payment',
  STUDENT_INVOICE_PAYMENTS_PAYMENTS: '/student/details/invoice-payments/payments',
  STUDENT_ADMISSION_FORMS: '/student/details/admissionforms',
  INVOICE_AND_PAYMENTS_LOAN: '/student/details/invoice-payments/student-loan',
  STUDENT_SMS_SECTION: '/student/details/sms-section',
  STUDENT_EMAIL: '/student/details/email',
  STUDENT_PARENT_DETAILS: '/student/details/parents-details',
  STUDENT_EXAM_REQUEST: '/student/details/exams',
  STUDENT_IMMIGRATION_PERMIT: '/student/details/immigration/document/permit',
  STUDENT_IMMIGRATION_CENSUS: '/student/details/immigration/document/census',
  STUDENT_IMMIGRATION_DIMAS: '/student/details/immigration/document/dimas',
  STUDENT_IMMIGRATION_DOCUMENT: '/student/details/immigration/document',
  STUDENT_IMMIGRATION_DOCUMENT_VERIFY: '/student/details/immigration/document/verify',
  STUDENT_IMMIGRATION_DZB: '/student/details/immigration/document/dzb',
  STUDENT_IMMIGRATION_SBV: '/student/details/immigration/document/sbv',
  STUDENT_IMMIGRATION_TEST: '/student/details/immigration/document/test',
  STUDENT_IMMIGRATION_VISA: '/student/details/immigration/document/visa',
  STUDENT_IMMIGRATION_VTA: '/student/details/immigration/document/vta',
  STUDENT_DETAILS_DEGREE: '/student/details/degree',
  STUDENT_OTHER_DETAILS: '/student/details/other-details',
  STUDENT_DETAILS_PROFILE: '/student/details/profile/view',
  STUDENT_DETAILS_SNAP_SHOT: '/student/details/snap-shot',
  STUDENT_HOUSING_DETAILS: '/student/details/housing',
  STUDENT_MCAT: '/student/details/mcat',
  STUDENT_TIME_TABLE: '/student/details/time-table',
  // Clinical Module Access
  CLINICAL_HOSPITAl_LIST: '/medical/clinicals/hospitals/list',
  CLINICAL_PRECEPTOR_LIST: '/medical/clinicals/preceptors/list',

  // REQUESTS
  STUDENT_LEAVEOFABSENCE: '/medical/request/absence',
  STUDENT_CLASSWITHDRAWAL: '/medical/request/class-withdrawal',
  STUDENT_PROGRAMWITHDRAWAL: '/medical/request/program-withdrawal',
  STUDENT_CLINICALREQUEST: '/medical/request/clinical-request',
  STUDENT_COMPLAINT: '/medical/request/complaint',
  STUDENT_DIPLOMA: '/medical/request/graduation-application',
  STUDENT_ENROLLMENT: '/medical/request/enrollment-request',
  STUDENT_GRADUATION: '/medical/request/graduation-application',
  STUDENT_I94: '/medical/request/i94letter-request',
  STUDENT_MSPE: '/medical/request/mpse-requests',
  STUDENT_PREMED: '/medical/request/premedical-basic',
  STUDENT_FINANCE: '/medical/request/finance-request',
  APPEAL_REQUEST: '/medical/request/appeal-request',
  APPEAL_DRAFT: '/medical/request/appeal/draft',
  APPEAL_STATUS: '/medical/request/appeal/status',
  EXAM_REQUEST: '/medical/request/exam-request',
  SEMESTER_DEFERMENT: '/student/details/semester/deferment',
  STUDENT_TRANSCRIPTREQUEST: '/medical/request/transcript-request',

  // CLINICALS
  CLINICAL_HOSPITAL_DOCUMENT: '/medical/clinicals/hospitals/list/document',
  CLINICAL_ASSIGNROTATION_LIST: '/medical/clinicals/assign-rotation/list',
  CLINICAL_ROTATION_GRAPH: '/medical/clinicals/assign-rotation/graph',
  CLINICAL_ROTATION_ROASTER: '/medical/clinicals/assign-rotation/roaster',
  CLINICAL_CLINICAL_LOG: '/medical/clinicals/clinical-log',
  CLINICAL_THIRD_PARTY: '/medical/clinicals/third-party/list',
  PLAN_ROTATION_LIST: '/medical/clinicals/assign-rotation/planned',
  PLAN_ROTATION_DELETE: '/medical/clinicals/assign-rotation/delete',

  // Registrar
  FINANCIALLY_CLEARED_LIST: '/medical/registrar',

  // Attendance
  STAGE_SCHEDULE: '/medical/attendance/stage-schedule',
  WEEKLY_SCHEDULE: '/medical/attendance/weekly-schedule',
  SEMESTER_SCHEDULE: '/medical/attendance/semester-schedule',
  PUBLISHED_SCHEDULE: '/medical/attendance/published/schedule',
  TIME_TABLE: '/medical/attendance/time-table',
  PAST_ATTENDANCE: '/medical/attendance/past-attendance',
  HOLIDAYS: '/medical/attendance/holidays',
  USER_ACCESS: '/medical/attendance/user-access',
  REPORTS: '/medical/attendance/reports',

  // alumni
  ALUMNI_LIST: '/medical/alumniList',

  // REPORTS
  TRANSCRIPT_REPORT: '/reports/student-report/transcript-report',
  ATTENDANCE_REPORT: '/reports/student-report/attendance-report',
  STUDENT_REPORTS_LIST: '/reports/student-report/student',
  STUDENT_ROTATION_PRICE: '/reports/student-report/rotation/price',
  STUDENT_ROTATION_COUNT: '/reports/student-report/rotation/count',
  LEAVE_REPORTS: '/reports/student-report/leave',
  CLINICAL_LOG_MISSING: '/reports/student-report/missing/log',
  STUDENT_BIRTHDAY_REPORT: '/reports/student-report/birthday',
  GRADE_REPORT: '/reports/student-report/grade-report',
  ADJUSTMENT_REPORT: '/reports/accounting-report/adjustment-report',
  ADDRESS_REPORT: '/reports/applicant-report/address-report',
  EMPLOYEE_BIRTHDAY_REPORT: '/reports/employee-report/birthday-report',
  PAYMENT_REPORT: '/reports/accounting-report/payment-report',
  BALANCE_REPORT: '/reports/accounting-report/balance-report',
  LEDGER_REPORT: '/reports/accounting-report/ledger-report',
  APPLICANT_POOL_REPORT: '/reports/applicant-report/applicant-pool',
  CASE_REPORTS: '/reports/case-report/case',
  PENDING_INVOICE_REPORT: '/reports/accounting-report/pending-invoice-report',
  SEMESTER_INVOICE_REPORT: '/reports/accounting-report/semester-invoice-report',
  BILLED_NOTBILLED_REPORT: '/reports/accounting-report/billed-notbilled-report',
  INVOICE_PROMOTIONAL_REPORT: '/reports/accounting-report/invoice-promotional-report',
  TUITION_FEES_ACCEPTED_REPORT: '/reports/accounting-report/tuition-fees-accepted-report',
  INVOICE_PROMOTIONAL_MISMATCH_REPORT: '/reports/accounting-report/invoice-promotional-mismatch-report',
  STAGE_GPA_REPORT: '/reports/student-report/stage-gpa-report',
  MED_6_LOA_LEAVE_REPORT: '/reports/student-report/med6loa-student-leave-time-report',
  USMLE_PENDING: '/reports/student-report/usmle-pending-report',
  STUDENT_PHOTO_REPORT: '/reports/student-report/photo-report',
  STUDENT_WITHDRAW_REPORT: '/reports/student-report/withdraw-report',
  STUDENT_COMPLAINT_REPORT: '/reports/student-report/complaint-report',
  ADMINISTRATIVE_INVOICE_REPORT: '/reports/accounting-report/administrative-invoice-report',
  MED_6_LOA_BALANCE_REPORT: '/reports/accounting-report/med6-loa-report',
  REFUND_REPORT: '/reports/accounting-report/refund-report',
  SCHOLAR_SHIP_REPORT: '/reports/accounting-report/scholarsip-report',
  SCHEDULED_REPORT: '/reports/accounting-report/scheduled-available',
  PROMOTIONAL_REPORT: '/reports/accounting-report/promotional-report',
  TUTION_FEE_REPORT: '/reports/accounting-report/tuition-fee-report',
  INVOICE_AND_PAYMENTS_REPORT: '/reports/accounting-report/invoice-payment-report',
  PAYMENT_PLAN_REPORT: '/reports/accounting-report/payment-plan-report',
  PAYMENT_TAG_REPORT: '/reports/accounting-report/payment-tag-report',
  APPLICANT_STATUS_REPORT: '/reports/applicant-report/applicant-status',
  USMLE_REPORT: '/reports/case-report/usmle-report',
  CLINICAL_MISSING_GRADE_REPORT: '/reports/student-report/missing-grade',
  MISSING_INFORMATION_REPORT: '/reports/student-report/missing-information-report',
  CLINICAL_GRADUATION_STATUS_REPORT: '/reports/student-report/clinical-graduation-status-report',
  ROTATION_ENDING_WEEKS_REPORT: '/reports/student-report/rotation-ending-weeks-report',
  SNAPSHOT_STUDENT: '/reports/student-report/snapshot-report',
  COMPLETED_CLINICAL_REPORT: '/reports/student-report/completed-clinical-report',
  GRADUATE_SURVEY_REPORT: '/reports/survey-report/graduate-survey-report',
  CREDIT_BALANCE_REPORT: '/reports/accounting-report/credit-balance-report',
  BASIC_SCIENCE_SURVEY_REPORT: '/reports/survey-report/basic-science-survey-report',

  // PRECEPTOR LOGIN
  PRECEPTOR_PROFILE: '/preceptor/user/profile',

  // EXAM SECTION
  KAPLAN_DIAGONISTIC_SEARCH: '/exams/kaplan',
  NBME_SHLEF: '/exams/nbme-shelf',
  NBME_COMPREHENSIVE: '/exams/nbme-comprehensive',
  RETAKE_EXAMS: '/exams/retake-exams',
  RETAKE_EXAMS_CANCEL: '/exams/retake-exams/cancel',
  NBME_COMPREHENSIVE_BULK_UPLOAD: '/exams/bulk-update/nbme-system/comprehensive',
  NBME_ROTATION_BULK_UPLOAD: '/exams/bulk-update/nbme-system/rotation',
  NBME_SYSTEM_SUBJECT_BULK_UPLOAD: '/exams/bulk-update/nbme-system/exams',

  // mail log
  MAIL_LOG_ARUBA_INCOMING_LOG: '/mailLog/aruba/incoming',
  MAIL_LOG_ARUBA_OUTGOING_LOG: '/mailLog/aruba/outgoing',
  MAIL_LOG_INCOMING_LOG: '/mailLog/incoming',
  MAIL_LOG_OUTGOING_LOG: '/mailLog/outgoing',
  RETURNED_MAIL_LOG: '/mailLog/returned',

  ADMIN_TASK: '/task/list',
  ADMIN_TODO: '/admin/todolist',
  ADMIN_NOTIFY: '/admin/notify-all',

  STUDENT_ATTENDANCE_REPORT: '/medical/attendance/reports/total',
  INDIVIDUAL_STUDENT_REPORT: '/medical/attendance/reports/individual',
  MULTI_STUDENT_REPORT: '/medical/attendance/reports/multi',
  STUDENT_FACULTY_REPORT: '/medical/attendance/reports/faculty',
  TOTAL_SUBJECT_REPORT: '/medical/attendance/reports/total-subject',
  TOTAL_TOPIC_REPORT: '/medical/attendance/reports/total-topic',

  CT_ADMIN_TOPICS_REPORT: '/admin/control-table/admin/topic',
  CT_SURVEY_CLASS_CHAIR_LIST: '/admin/control-table/survey/class-chair/list',
  CBSE_EXAM_REPORT: '/reports/cbse',
  HOSPITAL_REPORT_LIST: '/reports/student-report/hospital',
  IMMIGRATION_REPORT: '/reports/student-report/immigration-report',
  INFORMATION_NOTIFY_REPORT: '/reports/student-report/information-notify-report',

  CT_SURVEY_BASIC: '/admin/control-table/survey/basic-science',
  CT_SURVEY_CLASS_EVAL: '/admin/control-table/survey/class-eval',
  CT_SURVEY: '/admin/control-table/survey/ct-survey',
  CT_SURVEY_SAVE: '/admin/control-table/survey/ct-survey/save',

  MED_GRADE_LIST_REPORT: '/reports/student-report/med-grade-list-report',
  CT_SURVEY_ADHOC_LIST: '/admin/control-table/survey/adhoc/list',

  // Housing SECTION
  HOUSING_ALLOCATION_LIST: '/housing/allocation',
  HOUSING_SUITE_ROOM_LIST: '/housing/list',
  HOUSING_QUEUE_LIST: '/housing/queue-list',
  HOUSING_MASTER_LIST: '/housing/master/list',
};
