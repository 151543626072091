import { combineReducers } from 'redux';
import accounting from './accounting';
import invoices from './invoices';

const accountingReducer = combineReducers({
  accounting,
  invoices
});

export default accountingReducer;
