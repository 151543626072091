export const ADD_DOCUMENT_REQUESTED = 'document/ADD_DOCUMENT_REQUESTED';
export const ADD_DOCUMENT_SUCCESS = 'document/ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAILURE = 'document/ADD_DOCUMENT_FAILURE';

export const GET_SESSION_BY_EMPLOYEE_ID = 'document/GET_SESSION_BY_EMPLOYEE_ID';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  sessionSeeddmsData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_DOCUMENT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_DOCUMENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ADD_DOCUMENT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }

  case GET_SESSION_BY_EMPLOYEE_ID: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      sessionSeeddmsData: action.result && action.result.data
    };
  }
  default:
    return state;
  }
};

export const getSessionByEmployeeById = (id, employee) => {
  return {
    types: [ADD_DOCUMENT_REQUESTED, GET_SESSION_BY_EMPLOYEE_ID, ADD_DOCUMENT_FAILURE],
    promise: (client) => client.get(`sessionSeeddms/${id}/${employee}`)
  };
};
