export const SET_SIDE_MENU = 'sideMenuChange/SET_SIDE_MENU';
export const SET_STUDENT_SIDE_MENU = 'sideMenuChange/SET_STUDENT_SIDE_MENU';

const initialValues = {
  loading: false,
  loaded: false,
  error: false,
  sideMenu: null,
  studentSideMenu: null,
};

export default (state = initialValues, action) => {
  switch (action.type) {
  case SET_SIDE_MENU:
    return {
      ...state,
      sideMenu: action.payload,
    };
  case SET_STUDENT_SIDE_MENU:
    return {
      ...state,
      studentSideMenu: action.payload,
    };
  default:
    return state;
  }
};

export const setSideMenu = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SIDE_MENU,
      payload: data || {},
    });
  };
};

export const setStudentSideMenu = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_STUDENT_SIDE_MENU,
      payload: data || {},
    });
  };
};
