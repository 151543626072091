/* eslint-disable import/prefer-default-export */
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import createDebounce from 'redux-debounced';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import createMiddleware from '../middleware/clientMiddleware';
import ApiClient from '../../helpers/ApiClient';

const client = new ApiClient();

const middlewares = [
  createMiddleware(client),
  thunk,
  createDebounce()
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
