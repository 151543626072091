export const ADD_ACTION_REQUESTED = 'ls/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'ls/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'ls/ADD_ACTION_FAILURE';

export const GET_LEAD_SOURCES_LIST = 'ls/GET_LEAD_SOURCES_LIST';
export const GET_LEAD_SOURCE_BY_ID = 'ls/GET_LEAD_SOURCE_BY_ID';

export const GET_TASK_SUBJECT_LIST = 'ls/GET_TASK_SUBJECT_LIST';
export const GET_TASK_SUBJECT_BY_ID = 'ls/GET_TASK_SUBJECT_BY_ID';

export const GET_BILL_SUMMARY_REQUESTED = 'ls/GET_BILL_SUMMARY_REQUESTED';
export const GET_BILL_SUMMARY_SUCCESS = 'ls/GET_BILL_SUMMARY_SUCCESS';
export const GET_BILL_SUMMARY_FAILURE = 'ls/GET_BILL_SUMMARY_FAILURE';

export const GET_SEM_INV_DETAILS_REQUESTED = 'ls/GET_SEM_INV_DETAILS_REQUESTED';
export const GET_SEM_INV_DETAILS_SUCCESS = 'ls/GET_SEM_INV_DETAILS_SUCCESS';
export const GET_SEM_INV_DETAILS_FAILURE = 'ls/GET_SEM_INV_DETAILS_FAILURE';

export const GET_PAYEE_COUNT_LIST = 'ls/GET_PAYEE_COUNT_LIST';
export const GET_PAYEE_COUNT_REQUESTED = 'ls/GET_PAYEE_COUNT_REQUESTED';
export const GET_PAYEE_COUNT_FAILURE = 'ls/GET_PAYEE_COUNT_FAILURE';

export const GET_STUDENT_PAYEE_COUNT_REQUESTED = 'ls/GET_STUDENT_PAYEE_COUNT_REQUESTED';
export const GET_STUDENT_PAYEE_COUNT_LIST = 'ls/GET_STUDENT_PAYEE_COUNT_LIST';
export const GET_STUDENT_PAYEE_COUNT_FAILURE = 'ls/GET_STUDENT_PAYEE_COUNT_FAILURE';

export const GET_STUDENT_ROTATION_LIST = 'ls/GET_STUDENT_ROTATION_LIST';
export const GET_STUDENT_ROTATION_DETAILED_LIST = 'ls/GET_STUDENT_ROTATION_DETAILED_LIST';

export const LAST_NINE_SEM_REQUESTED = 'ls/LAST_NINE_SEM_REQUESTED';
export const GET_STUDENT_COUNT_LAST_NINE_SEM = 'ls/GET_STUDENT_COUNT_LAST_NINE_SEM';
export const LAST_NINE_SEM_FAILURE = 'ls/LAST_NINE_SEM_FAILURE';

export const GET_LAST_NINE_CLINICAL_SEM_DETAILS = 'ls/GET_LAST_NINE_CLINICAL_SEM_DETAILS';
export const GET_LAST_NINE_MED_PREMED_SEM_DETAILS = 'ls/GET_LAST_NINE_MED_PREMED_SEM_DETAILS';

export const STATUS_WISE_REPORT_REQUESTED = 'ls/STATUS_WISE_REPORT_REQUESTED';
export const GET_STATUS_WISE_REPORT = 'ls/GET_STATUS_WISE_REPORT';
export const GET_STATUS_WISE_DETAILED_REPORT = 'ls/GET_STATUS_WISE_DETAILED_REPORT';
export const STATUS_WISE_REPORT_FAILURE = 'ls/STATUS_WISE_REPORT_FAILURE';

export const DASHBOARD_LOADING = 'ls/DASHBOARD_LOADING';
export const GET_DASHBOARD_ORDER = 'ls/GET_DASHBOARD_ORDER';
export const DASHBOARD_FAILURE = 'ls/DASHBOARD_FAILURE';
export const ADD_UPDATE_DASHBOARD_ORDER = 'ls/ADD_UPDATE_DASHBOARD_ORDER';
export const ADD_UPDATE_DASHBOARD_LOADING = 'ls/ADD_UPDATE_DASHBOARD_LOADING';
export const ADD_UPDATE_DASHBOARD_FAILURE = 'ls/ADD_UPDATE_DASHBOARD_FAILURE';

export const GET_PAYMENT_REPORT_LOADING = 'ls/GET_PAYMENT_REPORT_LOADING';
export const GET_PAYMENT_REPORT = 'ls/GET_PAYMENT_REPORT';
export const GET_PAYMENT_REPORT_FAILURE = 'ls/GET_PAYMENT_REPORT_FAILURE';

export const GET_STUDENT_COUNT_LOADING = 'ls/GET_STUDENT_COUNT_LOADING';
export const GET_STUDENT_COUNT = 'ls/GET_STUDENT_COUNT';
export const GET_STUDENT_COUNT_FAILURE = 'ls/GET_STUDENT_COUNT_FAILURE';

export const GET_ACTIVE_USERS_LOADING = 'ls/GET_ACTIVE_USERS_LOADING';
export const GET_ACTIVE_USERS_SUCCESS = 'ls/GET_ACTIVE_USERS_SUCCESS';
export const GET_ACTIVE_USERS_FAILURE = 'ls/GET_ACTIVE_USERS_FAILURE';

export const USER_LOGOUT_SUCCESS = 'ls/USER_LOGOUT';

export const GET_INVOICE_SEMESTER_COUNT_LOADING = 'ls/GET_INVOICE_SEMESTER_COUNT_LOADING';
export const GET_INVOICE_SEMESTER_COUNT_SUCCESS = 'ls/GET_INVOICE_SEMESTER_COUNT_SUCCESS';
export const GET_INVOICE_SEMESTER_COUNT_FAILURE = 'ls/GET_INVOICE_SEMESTER_COUNT_FAILURE';
export const GET_INVOICE_SEMESTER_DETAILED_COUNT_SUCCESS = 'ls/GET_INVOICE_SEMESTER_DETAILED_COUNT_SUCCESS';

export const GET_CLINICAL_ROTATION_COUNT_LOADING = 'ls/GET_CLINICAL_ROTATION_COUNT_LOADING';
export const GET_CLINICAL_ROTATION_COUNT_SUCCESS = 'ls/GET_CLINICAL_ROTATION_COUNT_SUCCESS';
export const GET_CLINICAL_ROTATION_COUNT_FAILURE = 'ls/GET_CLINICAL_ROTATION_COUNT_FAILURE';

export const MED6_LOA_CBSE_STATUS_LOADING = 'ls/MED6_LOA_CBSE_STATUS_LOADING';
export const MED6_LOA_CBSE_STATUS_SUCCESS = 'ls/MED6_LOA_CBSE_STATUS_SUCCESS';
export const MED6_LOA_CBSE_STATUS_FAILURE = 'ls/MED6_LOA_CBSE_STATUS_FAILURE';

export const GET_REVENUE_LIST_LOADING = 'ls/GET_REVENUE_LIST_LOADING';
export const GET_REVENUE_LIST_SUCCESS = 'ls/GET_REVENUE_LIST_SUCCESS';
export const GET_REVENUE_LIST_FAILURE = 'ls/GET_REVENUE_LIST_FAILURE';

export const MED6_LOA_CBSE_STATUS_DETAILED_SUCCESS = 'ls/MED6_LOA_CBSE_STATUS_DETAILED_SUCCESS';
export const MED6_LOA_CBSE_STATUS_DETAILED_REQUEST = 'ls/MED6_LOA_CBSE_STATUS_DETAILED_REQUEST';
export const MED6_LOA_CBSE_STATUS_DETAILED_FAILURE = 'ls/MED6_LOA_CBSE_STATUS_DETAILED_FAILURE';

export const GET_APPEAL_SUMMARY_LIST_REQUESTED = 'ls/GET_APPEAL_SUMMARY_LIST_REQUESTED';
export const GET_APPEAL_SUMMARY_LIST_SUCCESS = 'ls/GET_APPEAL_SUMMARY_LIST_SUCCESS';
export const GET_APPEAL_SUMMARY_LIST_FAILURE = 'ls/GET_APPEAL_SUMMARY_LIST_FAILURE';

export const GET_APPEAL_SUMMARY_DETAILS_REQUESTED = 'ls/GET_APPEAL_SUMMARY_DETAILS_REQUESTED';
export const GET_APPEAL_SUMMARY_DETAILS_SUCCESS = 'ls/GET_APPEAL_SUMMARY_DETAILS_SUCCESS';
export const GET_APPEAL_SUMMARY_DETAILS_FAILURE = 'ls/GET_APPEAL_SUMMARY_DETAILS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  loadingFlag: false,
  payeeLoading: false,
  nineSemLoading: false,
  statusWiseReportLoading: false,
  dashboardOrderLoading: false,
  addUpdateDashboardLoading: false,
  paymentReportLoading: false,
  studentCountLoading: false,
  activeUsersLoading: false,
  clinicalRotationCountReportLoading: false,
  revenueLoading: false,
  invoiceSemesterCountReportLoading: false,
  med6LoaCbseStatusLoading: false,
  sourceList: null,
  sourceById: null,
  taskList: null,
  taskById: null,
  billSummary: null,
  semInvDetails: null,
  payeeCountList: null,
  payeeStudentCountList: null,
  studentRotationList: null,
  studentRotationDetailList: null,
  lastNineSemList: null,
  lastNineClinicalSemDetails: null,
  lastNineMedPremedSemDetails: null,
  statusWiseReportList: null,
  statusWiseDetailedReportList: null,
  dashboardOrder: null,
  paymentReportList: null,
  studentCountList: null,
  activeUsersList: null,
  clinicalRotationCountList: null,
  revenueList: null,
  invoiceSemesterCountList: null,
  med6LoaCbseStatusList: null,
  med6LoaCbseStatusDetailedList: null,
  invoiceSemesterDetailedCountList: null,
  appealSummaryList: null,
  appealSummaryListDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_LEAD_SOURCES_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      sourceList: action.result
    };
  }
  case GET_LEAD_SOURCE_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      sourceById: action.result
    };
  }
  case GET_TASK_SUBJECT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskList: action.result
    };
  }
  case GET_TASK_SUBJECT_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskById: action.result
    };
  }
  case GET_BILL_SUMMARY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_BILL_SUMMARY_SUCCESS: {
    return {
      ...state,
      loading: false,
      laoded: true,
      error: false,
      billSummary: action.result
    };
  }
  case GET_BILL_SUMMARY_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_SEM_INV_DETAILS_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false,
    };
  }
  case GET_SEM_INV_DETAILS_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      laoded: true,
      error: false,
      semInvDetails: action.result
    };
  }
  case GET_SEM_INV_DETAILS_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true
    };
  }
  case GET_PAYEE_COUNT_REQUESTED: {
    return {
      ...state,
      payeeLoading: true,
      loaded: false
    };
  }
  case GET_PAYEE_COUNT_LIST: {
    return {
      ...state,
      payeeLoading: false,
      loaded: true,
      error: false,
      payeeCountList: action.result && action.result.data
    };
  }
  case GET_PAYEE_COUNT_FAILURE: {
    return {
      ...state,
      payeeLoading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_PAYEE_COUNT_REQUESTED: {
    return {
      ...state,
      payeeLoading: true,
      loaded: false
    };
  }
  case GET_STUDENT_PAYEE_COUNT_LIST: {
    return {
      ...state,
      payeeLoading: false,
      loaded: true,
      error: false,
      payeeStudentCountList: action.result && action.result?.data
    };
  }
  case GET_STUDENT_PAYEE_COUNT_FAILURE: {
    return {
      ...state,
      payeeLoading: false,
      loaded: true,
      error: true,
      payeeStudentCountList: null
    };
  }

  case GET_STUDENT_ROTATION_LIST: {
    return {
      ...state,
      payeeLoading: false,
      loaded: true,
      error: false,
      studentRotationList: action.result && action.result.data
    };
  }
  case GET_STUDENT_ROTATION_DETAILED_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentRotationDetailList: action.result && action.result.data
    };
  }
  case LAST_NINE_SEM_REQUESTED: {
    return {
      ...state,
      nineSemLoading: true,
      loaded: false,
      error: false
    };
  }
  case GET_STUDENT_COUNT_LAST_NINE_SEM: {
    return {
      ...state,
      nineSemLoading: false,
      loaded: true,
      error: false,
      lastNineSemList: action.result && action.result.data
    };
  }
  case LAST_NINE_SEM_FAILURE: {
    return {
      ...state,
      nineSemLoading: false,
      loaded: true,
      error: true
    };
  }
  case GET_LAST_NINE_CLINICAL_SEM_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      lastNineClinicalSemDetails: action.result && action.result.data
    };
  }
  case GET_LAST_NINE_MED_PREMED_SEM_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      lastNineMedPremedSemDetails: action.result && action.result.data
    };
  }
  case STATUS_WISE_REPORT_REQUESTED: {
    return {
      ...state,
      statusWiseReportLoading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STATUS_WISE_REPORT: {
    return {
      ...state,
      statusWiseReportLoading: false,
      loaded: true,
      error: false,
      statusWiseReportList: action.result && action.result.data
    };
  }
  case GET_STATUS_WISE_DETAILED_REPORT: {
    return {
      ...state,
      statusWiseReportLoading: false,
      loaded: true,
      error: false,
      statusWiseDetailedReportList: action.result && action.result.data
    };
  }
  case STATUS_WISE_REPORT_FAILURE: {
    return {
      ...state,
      statusWiseReportLoading: false,
      loaded: true,
      error: true
    };
  }
  case DASHBOARD_LOADING: {
    return {
      ...state,
      dashboardOrderLoading: true,
      loaded: false,
      error: false
    };
  }
  case GET_DASHBOARD_ORDER: {
    return {
      ...state,
      dashboardOrderLoading: false,
      loaded: true,
      error: false,
      dashboardOrder: action.result && action.result.data
    };
  }
  case DASHBOARD_FAILURE: {
    return {
      ...state,
      dashboardOrderLoading: false,
      loaded: true,
      error: true,
    };
  }
  case ADD_UPDATE_DASHBOARD_ORDER: {
    return {
      ...state,
      // loading : false,
      addUpdateDashboardLoading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_UPDATE_DASHBOARD_LOADING: {
    return {
      ...state,
      addUpdateDashboardLoading: true,
      loaded: false,
      error: false
    };
  }
  case ADD_UPDATE_DASHBOARD_FAILURE: {
    return {
      ...state,
      addUpdateDashboardLoading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PAYMENT_REPORT_LOADING: {
    return {
      ...state,
      paymentReportLoading: true,
      loaded: false,
      error: false
    };
  }
  case GET_PAYMENT_REPORT: {
    return {
      ...state,
      paymentReportLoading: false,
      loaded: true,
      error: false,
      paymentReportList: action.result && action.result.data
    };
  }
  case GET_PAYMENT_REPORT_FAILURE: {
    return {
      ...state,
      paymentReportLoading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_COUNT_LOADING: {
    return {
      ...state,
      studentCountLoading: true,
      loaded: false,
      error: false
    };
  }
  case GET_STUDENT_COUNT: {
    return {
      ...state,
      studentCountLoading: false,
      loaded: true,
      error: false,
      studentCountList: action.result && action.result.data
    };
  }
  case GET_STUDENT_COUNT_FAILURE: {
    return {
      ...state,
      studentCountLoading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ACTIVE_USERS_LOADING: {
    return {
      ...state,
      activeUsersLoading: true,
      loaded: false,
      error: false
    };
  }
  case GET_ACTIVE_USERS_SUCCESS: {
    return {
      ...state,
      activeUsersLoading: false,
      loaded: true,
      error: false,
      activeUsersList: action.result
    };
  }
  case GET_ACTIVE_USERS_FAILURE: {
    return {
      ...state,
      activeUsersLoading: false,
      loaded: true,
      error: true
    };
  }
  case USER_LOGOUT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_INVOICE_SEMESTER_COUNT_LOADING: {
    return {
      ...state,
      invoiceSemesterCountReportLoading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICE_SEMESTER_COUNT_SUCCESS: {
    return {
      ...state,
      invoiceSemesterCountReportLoading: false,
      loaded: true,
      error: false,
      invoiceSemesterCountList: action.result && action.result.data
    };
  }
  case GET_INVOICE_SEMESTER_COUNT_FAILURE: {
    return {
      ...state,
      invoiceSemesterCountReportLoading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_CLINICAL_ROTATION_COUNT_LOADING: {
    return {
      ...state,
      clinicalRotationCountReportLoading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_CLINICAL_ROTATION_COUNT_SUCCESS: {
    return {
      ...state,
      clinicalRotationCountReportLoading: false,
      loaded: true,
      error: false,
      clinicalRotationCountList: action.result && action.result.data
    };
  }
  case GET_CLINICAL_ROTATION_COUNT_FAILURE: {
    return {
      ...state,
      clinicalRotationCountReportLoading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_REVENUE_LIST_LOADING: {
    return {
      ...state,
      revenueLoading: true,
      loaded: false,
      error: false
    };
  }
  case GET_REVENUE_LIST_SUCCESS: {
    return {
      ...state,
      revenueLoading: false,
      loaded: true,
      error: false,
      revenueList: action.result && action.result.data
    };
  }
  case GET_REVENUE_LIST_FAILURE: {
    return {
      ...state,
      revenueLoading: false,
      loaded: true,
      error: true
    };
  }
  case MED6_LOA_CBSE_STATUS_LOADING: {
    return {
      ...state,
      med6LoaCbseStatusLoading: true,
      loaded: false,
      error: false
    };
  }
  case MED6_LOA_CBSE_STATUS_SUCCESS: {
    return {
      ...state,
      med6LoaCbseStatusLoading: false,
      loaded: true,
      error: false,
      med6LoaCbseStatusList: action.result && action.result.data
    };
  }
  case MED6_LOA_CBSE_STATUS_FAILURE: {
    return {
      ...state,
      med6LoaCbseStatusLoading: false,
      loaded: true,
      error: true
    };
  }
  case MED6_LOA_CBSE_STATUS_DETAILED_REQUEST: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case MED6_LOA_CBSE_STATUS_DETAILED_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case MED6_LOA_CBSE_STATUS_DETAILED_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      med6LoaCbseStatusDetailedList: action.result && action.result.data
    };
  }
  case GET_INVOICE_SEMESTER_DETAILED_COUNT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoiceSemesterDetailedCountList: action.result && action.result.data
    };
  }
  case GET_APPEAL_SUMMARY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_APPEAL_SUMMARY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      laoded: true,
      error: false,
      appealSummaryList: action.result
    };
  }
  case GET_APPEAL_SUMMARY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      appealSummaryList: null,
    };
  }
  case GET_APPEAL_SUMMARY_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_APPEAL_SUMMARY_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      laoded: true,
      error: false,
      appealSummaryListDetails: action.result
    };
  }
  case GET_APPEAL_SUMMARY_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      appealSummaryListDetails: null,
    };
  }
  default: {
    return {
      ...state
    };
  }
  }
};

export const getLeadSources = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_LEAD_SOURCES_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getLeadSources', { params })
  };
};

export const getLeadSourceById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_LEAD_SOURCE_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getLeadSourceById/${id}`)
  };
};
export const addLeadSource = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addLeadSource', { data })
  };
};
export const getTaskSubject = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TASK_SUBJECT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getTaskSubject', { params })
  };
};

export const getTaskSubjectById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TASK_SUBJECT_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getTaskSubjectById/${id}`)
  };
};
export const addTaskSubject = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addTaskSubject', { data })
  };
};

export const getBillSummary = () => {
  return {
    types: [GET_BILL_SUMMARY_REQUESTED, GET_BILL_SUMMARY_SUCCESS, GET_BILL_SUMMARY_FAILURE],
    promise: (client) => client.get('getDashboardBillSummary')
  };
};

export const getDashboardAppealList = () => {
  return {
    types: [GET_APPEAL_SUMMARY_LIST_REQUESTED, GET_APPEAL_SUMMARY_LIST_SUCCESS, GET_APPEAL_SUMMARY_LIST_FAILURE],
    promise: (client) => client.get('getDashboardAppealList')
  };
};

export const getSemInvoiceDetails = () => {
  return {
    types: [GET_SEM_INV_DETAILS_REQUESTED, GET_SEM_INV_DETAILS_SUCCESS, GET_SEM_INV_DETAILS_FAILURE],
    promise: (client) => client.get('getSemesterInvoiceDetails')
  };
};

export const getPayeeCountList = () => {
  return {
    types: [GET_PAYEE_COUNT_REQUESTED, GET_PAYEE_COUNT_LIST, GET_PAYEE_COUNT_FAILURE],
    promise: (client) => client.get('getPaidCountList')
  };
};

export const getStudentPayeeCountList = () => {
  return {
    types: [GET_STUDENT_PAYEE_COUNT_REQUESTED, GET_STUDENT_PAYEE_COUNT_LIST, GET_STUDENT_PAYEE_COUNT_FAILURE],
    promise: (client) => client.get('getStudentPaidCountList')
  };
};

export const getStudentRotationList = (params) => {
  return {
    types: [GET_PAYEE_COUNT_REQUESTED, GET_STUDENT_ROTATION_LIST, GET_PAYEE_COUNT_FAILURE],
    promise: (client) => client.post('postStudentRotationList', { params })
  };
};

export const getStudentRotationDetailList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_ROTATION_DETAILED_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postStudentRotationDetailList', { params })
  };
};

export const getLastNineSemStudentCount = () => {
  return {
    types: [LAST_NINE_SEM_REQUESTED, GET_STUDENT_COUNT_LAST_NINE_SEM, LAST_NINE_SEM_FAILURE],
    promise: (client) => client.get('getLastNineSem')
  };
};

export const getLastNineClinicalSemDetails = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_LAST_NINE_CLINICAL_SEM_DETAILS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getLast9ClinicalSemDetails', { params })
  };
};

export const getLastNineMedPremedSemDetails = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_LAST_NINE_MED_PREMED_SEM_DETAILS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getLast9MedAndPremedSemDetails', { params })
  };
};

export const getStatusWiseReport = () => {
  return {
    types: [STATUS_WISE_REPORT_REQUESTED, GET_STATUS_WISE_REPORT, STATUS_WISE_REPORT_FAILURE],
    promise: (client) => client.get('getStatusWiseReport')
  };
};

export const getStatusWiseDetailedReport = (courseStatus, params) => {
  return {
    types: [STATUS_WISE_REPORT_REQUESTED, GET_STATUS_WISE_DETAILED_REPORT, STATUS_WISE_REPORT_FAILURE],
    promise: (client) => client.get(`getDetailedStatusWiseReport/${courseStatus}`, { params })
  };
};

export const getDashboardOrder = (userId) => {
  return {
    types: [DASHBOARD_LOADING, GET_DASHBOARD_ORDER, DASHBOARD_FAILURE],
    promise: (client) => client.get(`getDashboardOrder/${userId}`)
  };
};

export const addUpdateDashboard = (data) => {
  return {
    types: [ADD_UPDATE_DASHBOARD_LOADING, ADD_UPDATE_DASHBOARD_ORDER, ADD_UPDATE_DASHBOARD_FAILURE],
    promise: (client) => client.post('addUpdateDashboardOrder', { data })
  };
};

export const getPaymentReport = () => {
  return {
    types: [GET_PAYMENT_REPORT_LOADING, GET_PAYMENT_REPORT, GET_PAYMENT_REPORT_FAILURE],
    promise: (client) => client.get('getPaymentReport')
  };
};

export const getStudentCount = () => {
  return {
    types: [GET_STUDENT_COUNT_LOADING, GET_STUDENT_COUNT, GET_STUDENT_COUNT_FAILURE],
    promise: (client) => client.get('getStudentCount')
  };
};

export const getActiveUsersList = (params) => {
  return {
    types: [GET_ACTIVE_USERS_LOADING, GET_ACTIVE_USERS_SUCCESS, GET_ACTIVE_USERS_FAILURE],
    promise: (client) => client.get('getActiveUsersList', { params })
  };
};

export const postUserLogout = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, USER_LOGOUT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('userSessionLogout', { params })
  };
};

export const getInvoiceSemesterCountReport = () => {
  return {
    types: [GET_INVOICE_SEMESTER_COUNT_LOADING, GET_INVOICE_SEMESTER_COUNT_SUCCESS, GET_INVOICE_SEMESTER_COUNT_FAILURE],
    promise: (client) => client.get('invoiceSemesterCountReport')
  };
};

export const getInvoiceSemesterCountDetailedReport = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_INVOICE_SEMESTER_DETAILED_COUNT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('invoiceSemesterCountDetailedReport', { data })
  };
};

export const getClinicalRotationCountReport = () => {
  return {
    types: [GET_CLINICAL_ROTATION_COUNT_LOADING, GET_CLINICAL_ROTATION_COUNT_SUCCESS, GET_CLINICAL_ROTATION_COUNT_FAILURE],
    promise: (client) => client.get('clinicalRotationCountReport')
  };
};

export const getMed6LoaCbseStatusReport = () => {
  return {
    types: [MED6_LOA_CBSE_STATUS_LOADING, MED6_LOA_CBSE_STATUS_SUCCESS, MED6_LOA_CBSE_STATUS_FAILURE],
    promise: (client) => client.get('med6LoaCbseStatusReport')
  };
};

export const getRevenueList = () => {
  return {
    types: [GET_REVENUE_LIST_LOADING, GET_REVENUE_LIST_SUCCESS, GET_REVENUE_LIST_FAILURE],
    promise: (client) => client.get('getRevenueReport')
  };
};

export const getMed6LoaCbseStatusDetailedReport = (params) => {
  return {
    types: [MED6_LOA_CBSE_STATUS_DETAILED_REQUEST, MED6_LOA_CBSE_STATUS_DETAILED_SUCCESS, MED6_LOA_CBSE_STATUS_DETAILED_FAILURE],
    promise: (client) => client.get('med6LoaCbseDetailed', { params })
  };
};

export const getAppealDashboardCountDetails = (params) => {
  return {
    types: [GET_APPEAL_SUMMARY_DETAILS_REQUESTED, GET_APPEAL_SUMMARY_DETAILS_SUCCESS, GET_APPEAL_SUMMARY_DETAILS_FAILURE],
    promise: (client) => client.get('getAppealDashboardCountDetails', { params })
  };
};
