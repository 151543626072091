import React, { useEffect } from 'react';
import {
  Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNottificationCount, setStep1UnverifiedSelectedList, step1ShowUnverified } from '../../../redux/reducers/accounting/accounting';
import { checkAccess } from '../../../utility/helpers/accessRightHelper';
import { ACCESS_RIGHTS } from '../../../constants';

const NotificationPayent = (props) => {
  const notificationsCount = props.notificationList && (props.notificationList.paymentsVerify + props.notificationList.invoiceRequests + props.notificationList.transcriptCompletion + props.notificationList.transcriptVerify + (props.notificationList.unAssignedLeadCount || 0) + (props.notificationList.unverfiedDocumentCount || 0) + (props.notificationList?.unverifiedEmailCount || 0));

  useEffect(() => {
    props.getNottificationCount();
    // eslint-disable-next-line
      },[])
  const transcriptAccess = checkAccess(ACCESS_RIGHTS.STUDENT_TRANSCRIPTREQUEST);
  const bulkUpdate = checkAccess(ACCESS_RIGHTS.LEAD_UNASSIGNED_LEAD);
  const leadNotification = checkAccess(ACCESS_RIGHTS.LEAD_NOTIFICATION_DOCUMENT);
  const unverifiedpaymennts = checkAccess(ACCESS_RIGHTS.UNVERIFIED_PAYMENTS);
  const accountingInvoiceaccess = checkAccess(ACCESS_RIGHTS.ACCOUNTING_INVOICE_REPORTS_LIST);
  const unverifiedEmailaccess = checkAccess(ACCESS_RIGHTS.LEAD_UNVERIFIED_EMAIL);

  return (
    <UncontrolledDropdown>
      {(unverifiedpaymennts?.read || accountingInvoiceaccess?.read || transcriptAccess?.read || bulkUpdate?.read || leadNotification?.read || unverifiedEmailaccess?.read) && (

        <DropdownToggle nav caret>
          <Icon.Bell size={25} data-tour="Notification" />
          <Badge color="primary" className="notifHeader-badge">
            {notificationsCount || 0}
          </Badge>
        </DropdownToggle>
      )}
      <DropdownMenu tag="ul" className="dropdown-menu-media">
        {/* <DropdownItem>
                  <h5 className='text-black bold'>Accounting Notifications</h5>
                  </div>
                </DropdownItem> */}
        {unverifiedpaymennts?.read && (
          <DropdownItem color="primary" className="w-100">
            <Link
              to="/accounting/unverified-notifications"
              onClick={() => {
                props.setStep1UnverifiedSelectedList([]);
                props.step1ShowUnverified(false);
              }}
            >
              <span className=" text-wrap lh-1">
                <b className="text-capitalize"> {props.notificationList && props.notificationList.paymentsVerify} Payments</b> are pending to be verify
              </span>
            </Link>
          </DropdownItem>
        )}
        {accountingInvoiceaccess?.read && (
          <DropdownItem>
            <Link to="/accounting/invoice-requests">
              <span className="text-wrap lh-1">
                <b className="text-capitalize"> {props.notificationList && props.notificationList.invoiceRequests} Invoice Requests</b>  are pending to be review
              </span>
            </Link>
          </DropdownItem>
        )}
        {transcriptAccess?.read && (
          <>

            <DropdownItem>
              <Link to="/medical/request/transcript-request?status=Paid">
                <span className=" text-wrap lh-1">
                  <b className="text-capitalize"> {props.notificationList && props.notificationList.transcriptVerify} students Transcript request</b> are pending to be verify
                </span>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/medical/request/transcript-request?status=Verified">
                <span className=" text-wrap lh-1">
                  <b className="text-capitalize"> {props.notificationList && props.notificationList.transcriptCompletion} students Transcript request</b> are pending for completion
                </span>
              </Link>
            </DropdownItem>
          </>
        )}
        {bulkUpdate?.read && (
          <DropdownItem>
            <Link to="/lead/notifications/unassigned-lead">
              <span className=" text-wrap lh-1">
                <b> {(props.notificationList && props.notificationList.unAssignedLeadCount) || 0} Unassigned Leads</b> are pending to be verified
              </span>
            </Link>
          </DropdownItem>
        )}
        {leadNotification?.read && (
          <DropdownItem>
            <Link to="/lead/notifications/documents">
              <span className=" text-wrap lh-1">
                <b> {(props.notificationList && props.notificationList.unverfiedDocumentCount) || 0} New Documents</b> are pending to be verified
              </span>
            </Link>
          </DropdownItem>
        )}
        {unverifiedEmailaccess?.read && (
          <DropdownItem>
            <Link to="/lead/unverified/email">
              <span className=" text-wrap lh-1">
                <b> {(props.notificationList && props.notificationList.unverifiedEmailCount) || 0} Emails</b> are pending to be verified
              </span>
            </Link>
          </DropdownItem>
        )}
        {/* <li className='dropdown-menu-footer bg-white'>
                  <DropdownItem tag='a' className='p-1 text-center'>
                    <span className='align-middle'>Read all notifications</span>
                  </DropdownItem>
                </DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapStateToProps = ({ accountingReducer: { accounting } }) => ({
  notificationList: accounting.notificationList
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getNottificationCount,
  setStep1UnverifiedSelectedList,
  step1ShowUnverified
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPayent);
