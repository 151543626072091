import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import NotificationTodoModal from '../../../components/Task/ModalTodo';
import moment from 'moment';
import { addTodo, getTodoListByWeek } from '../../../redux/reducers/todo/todo';
import { toast } from 'react-toastify';
import { fileExportFromStream } from '../../../utility/helpers/helperFunction';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const TodoTaskList = (props) => {
  const validationSchema = Yup.object().shape({
    addTodo: Yup.string().trim().max(400, 'Description can be at most 400 characters').required('Description is required')
      .nullable()
  });

  const { todoModalOpen, setTodoModalOpen } = props;
  const [styld, setStyld] = useState(false);
  const [add, setAdd] = useState(false);

  const currentDate = new Date();
  const firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
  const lastday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6));
  const initialValues = {
    firstday,
    lastday
  };
  const [headerDates, setHeaderDates] = useState(initialValues);

  useEffect(() => {
    props.getTodoListByWeek({ startDate: moment(headerDates.firstday).format('MM/DD/YYYY'), endDate: moment(headerDates.lastday).format('MM/DD/YYYY') }).then((res) => {
      if (res.data && res.data.length) {
        if (window.localStorage.getItem('todoOpen') !== 'false') {
          setTodoModalOpen(true);
          window.localStorage.setItem('todoOpen', 'false');
        }
      }
    }).catch((err) => toast.error(err.message || 'Failed to fetch todo, please try again!'));
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    const params = {
      description: (values?.addTodo && String(values?.addTodo).trim()) || null,
      style: styld === true ? 1 : 0
    };

    props.addTodo(params).then(() => {
      resetForm({});
      setStyld(false);
      toast.success('Todo Added Successfully');
    }).then(() => {
      props.getTodoListByWeek({ startDate: moment(headerDates.firstday).format('MM/DD/YYYY'), endDate: moment(headerDates.lastday).format('MM/DD/YYYY') });
    }).catch((err) => toast.error(err.message || 'Failed to add todo, please try again!'));
    resetForm({});
  };

  const reloadList = () => {
    props.getTodoListByWeek({ startDate: moment(headerDates.firstday).format('MM/DD/YYYY'), endDate: moment(headerDates.lastday).format('MM/DD/YYYY') }).catch((err) => toast.error(err.message || 'Failed to fetch todo, please try again!'));
  };

  const setToPrevWeek = () => {
    setHeaderDates({ firstday: new Date(headerDates.firstday.setDate(headerDates.firstday.getDate() - 7)), lastday: new Date(headerDates.lastday.setDate(headerDates.lastday.getDate() - 7)) });
    reloadList();
  };

  const setToNextWeek = () => {
    setHeaderDates({ firstday: new Date(headerDates.firstday.setDate(headerDates.firstday.getDate() + 7)), lastday: new Date(headerDates.lastday.setDate(headerDates.lastday.getDate() + 7)) });
    reloadList();
  };

  const setHeaderDatesCurrentWeek = () => {
    setHeaderDates(initialValues);
    props.getTodoListByWeek({ startDate: moment(initialValues.firstday).format('MM/DD/YYYY'), endDate: moment(initialValues.lastday).format('MM/DD/YYYY') }).catch((err) => toast.error(err.message || 'Failed to fetch todo, please try again!'));
  };

  const onCloseModal = () => {
    setAdd(false);
    setTodoModalOpen(false);
  };

  const exportToExcelTodo = () => {
    fileExportFromStream({
      path: 'todoExportToExcel',
      method: 'POST',
      fileName: 'todo_list.xlsx',
      params: { startDate: moment(headerDates.firstday).format('MM/DD/YYYYY'), endDate: moment(headerDates.lastday).format('MM/DD/YYYY') }
    });
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      render={(renderProps) => (
        <NotificationTodoModal
          renderProps={renderProps}
          isOpen={todoModalOpen}
          onCloseModal={onCloseModal}
          styld={styld}
          setStyld={setStyld}
          setToPrevWeek={setToPrevWeek}
          setToNextWeek={setToNextWeek}
          todoList={props.todoList}
          headerDates={headerDates}
          setHeaderDates={setHeaderDates}
          reloadList={reloadList}
          exportToExcelTodo={exportToExcelTodo}
          add={add}
          setAdd={setAdd}
          firstday={firstday}
          lastday={lastday}
          setHeaderDatesCurrentWeek={setHeaderDatesCurrentWeek}
        />
      )}
      onSubmit={handleSubmit}
      enableReinitialize
    />
  );
};

const mapStateToProps = ({ todoApp: { todo } }) => ({
  loading: todo.loading,
  todoList: todo.todoListByWeek
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  addTodo,
  getTodoListByWeek
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TodoTaskList));
