/* eslint-disable camelcase */
export const GET_HOSPITAL_LIST_REQUESTED = 'clinicals/GET_HOSPITAL_LIST_REQUESTED';
export const GET_HOSPITAL_LIST_SUCCESS = 'clinicals/GET_HOSPITAL_LIST_SUCCESS';
export const GET_HOSPITAL_LIST_FAILURE = 'clinicals/GET_HOSPITAL_LIST_FAILURE';

export const ADD_OR_UPDATE_REQUESTED = 'clinicals/ADD_OR_UPDATE_REQUESTED';
export const ADD_OR_UPDATE_SUCCESS = 'clinicals/ADD_OR_UPDATE_SUCCESS';
export const ADD_OR_UPDATE_FAILURE = 'clinicals/ADD_OR_UPDATE_FAILURE';

export const GET_HOSPITAL_DETAILS_BY_ID_REQUESTED = 'clinicals/GET_HOSPITAL_DETAILS_BY_ID_REQUESTED';
export const GET_HOSPITAL_DETAILS_BY_ID_SUCCESS = 'clinicals/GET_HOSPITAL_DETAILS_BY_ID_SUCCESS';
export const GET_HOSPITAL_DETAILS_BY_ID_FAILURE = 'clinicals/GET_HOSPITAL_DETAILS_BY_ID_FAILURE';

export const AVALIABLE_ROTATION_LIST_REQUESTED = 'clinicals/AVALIABLE_ROTATION_LIST_REQUESTED';
export const AVALIABLE_ROTATION_LIST_SUCCESS = 'clinicals/AVALIABLE_ROTATION_LIST_SUCCESS';
export const AVALIABLE_ROTATION_LIST_FAILURE = 'clinicals/AVALIABLE_ROTATION_LIST_FAILURE';

export const THIRD_PARTY_LIST_REQUESTED = 'clinicals/THIRD_PARTY_LIST_REQUESTED';
export const THIRD_PARTY_LIST_SUCCESS = 'clinicals/THIRD_PARTY_LIST_SUCCESS';
export const THIRD_PARTY_LIST_FAILURE = 'clinicals/THIRD_PARTY_LIST_FAILURE';

export const CLINICAL_DOCUMENT_LIST_FAILURE = 'student/CLINICAL_DOCUMENT_LIST_FAILURE';
export const CLINICAL_DOCUMENT_LIST_SUCCESS = 'student/CLINICAL_DOCUMENT_LIST_SUCCESS';
export const CLINICAL_DOCUMENT_LIST_REQUESTED = 'student/CLINICAL_DOCUMENT_LIST_REQUESTED';

export const CLINICAL_ROTATION_LIST_REQUESTED = 'clinicals/CLINICAL_ROTATION_LIST_REQUESTED';
export const CLINICAL_ROTATION_LIST_SUCCESS = 'clinicals/CLINICAL_ROTATION_LIST_SUCCESS';
export const CLINICAL_ROTATION_LIST_FAILURE = 'clinicals/CLINICAL_ROTATION_LIST_FAILURE';

export const CLINICAL_LOG_CLINICALS_REQUESTED = 'clinicals/CLINICAL_LOG_CLINICALS_REQUESTED';
export const CLINICAL_LOG_CLINICALS_SUCCESS = 'clinicals/CLINICAL_LOG_CLINICALS_SUCCESS';
export const CLINICAL_LOG_CLINICALS_FAILURE = 'clinicals/CLINICAL_LOG_CLINICALS_FAILURE';

export const ROTATION_NAME_IS_ACTIVE_REQUESTED = 'clinicals/ROTATION_NAME_IS_ACTIVE_REQUESTED';
export const ROTATION_NAME_IS_ACTIVE_SUCCESS = 'clinicals/ROTATION_NAME_IS_ACTIVE_SUCCESS';
export const ROTATION_NAME_IS_ACTIVE_FAILURE = 'clinicals/ROTATION_NAME_IS_ACTIVE_FAILURE';

export const CLINICAL_PRECEPTOR_LIST_REQUESTED = 'clinicals/CLINICAL_PRECEPTOR_LIST_REQUESTED';
export const CLINICAL_PRECEPTOR_LIST_SUCCESS = 'clinicals/CLINICAL_PRECEPTOR_LIST_SUCCESS';
export const CLINICAL_PRECEPTOR_LIST_FAILURE = 'clinicals/CLINICAL_PRECEPTOR_LIST_FAILURE';

export const CLINICAL_STUDENT_SEARCH_REQUESTED = 'clinicals/CLINICAL_STUDENT_SEARCH_REQUESTED';
export const CLINICAL_STUDENT_SEARCH_SUCCESS = 'clinicals/CLINICAL_STUDENT_SEARCH_SUCCESS';
export const CLINICAL_STUDENT_SEARCH_FAILURE = 'clinicals/CLINICAL_STUDENT_SEARCH_FAILURE';

export const GET_HOSPITAl_PRICE_PER_WEEK_REQUESTED = 'clinicals/GET_HOSPITAl_PRICE_PER_WEEK_REQUESTED';
export const GET_HOSPITAl_PRICE_PER_WEEK_SUCCESS = 'clinicals/GET_HOSPITAl_PRICE_PER_WEEK_SUCCESS';
export const GET_HOSPITAl_PRICE_PER_WEEK_FAILURE = 'clinicals/GET_HOSPITAl_PRICE_PER_WEEK_FAILURE';

export const GET_REPLY_TO_MAILS_REQUESTED = 'clinicals/GET_REPLY_TO_MAILS_REQUESTED';
export const GET_REPLY_TO_MAILS_SUCCESS = 'clinicals/GET_REPLY_TO_MAILS_SUCCESS';
export const GET_REPLY_TO_MAILS_FAILURE = 'clinicals/GET_REPLY_TO_MAILS_FAILURE';

export const GET_ROTATION_WITH_WEEKS_REQUESTED = 'clinicals/GET_ROTATION_WITH_WEEKS_REQUESTED';
export const GET_ROTATION_WITH_WEEKS_SUCCESS = 'clinicals/GET_ROTATION_WITH_WEEKS_SUCCESS';
export const GET_ROTATION_WITH_WEEKS_FAILURE = 'clinicals/GET_ROTATION_WITH_WEEKS_FAILURE';

export const GET_PRECEPTOR_DETAILS_BY_ID_REQUESTED = 'clinicals/GET_PRECEPTOR_DETAILS_BY_ID_REQUESTED';
export const GET_PRECEPTOR_DETAILS_BY_ID_SUCCESS = 'clinicals/GET_PRECEPTOR_DETAILS_BY_ID_SUCCESS';
export const GET_PRECEPTOR_DETAILS_BY_ID_FAILURE = 'clinicals/GET_PRECEPTOR_DETAILS_BY_ID_FAILURE';

export const GET_ROTATION_ROASTER_BY_ID_REQUESTED = 'clinicals/GET_ROTATION_ROASTER_BY_ID_REQUESTED';
export const GET_ROTATION_ROASTER_BY_ID_SUCCESS = 'clinicals/GET_ROTATION_ROASTER_BY_ID_SUCCESS';
export const GET_ROTATION_ROASTER_BY_ID_FAILURE = 'clinicals/GET_ROTATION_ROASTER_BY_ID_FAILURE';

export const GET_CLINICAL_ASSIGN_ROTATION_REQUESTED = 'student/GET_CLINICAL_ASSIGN_ROTATION_REQUESTED';
export const GET_CLINICAL_ASSIGN_ROTATION_SUCCESS = 'student/GET_CLINICAL_ASSIGN_ROTATION_SUCCESS';
export const GET_CLINICAL_ASSIGN_ROTATION_FAILURE = 'student/GET_CLINICAL_ASSIGN_ROTATION_FAILURE';

export const STEP1ROTATION_CLINICALS_SHOW = 'student/STEP1ROTATION_CLINICALS_SHOW';
export const STEP2ROTATION_CLINICALS_SHOW = 'student/STEP2ROTATION_CLINICALS_SHOW';
export const STEP3ROTATION_CLINICALS_SHOW = 'student/STEP3ROTATION_CLINICALS_SHOW';
export const ASSIGN_ROTATION_STEP1_DATA = 'student/ASSIGN_ROTATION_STEP1_DATA';
export const SUBMITTED_ROTATION_DATA = 'student/SUBMITTED_ROTATION_DATA';

export const GET_ASSIGN_ROTATION_GRAPH_REQUESTED = 'student/GET_ASSIGN_ROTATION_GRAPH_REQUESTED';
export const GET_ASSIGN_ROTATION_GRAPH_SUCCESS = 'student/GET_ASSIGN_ROTATION_GRAPH_SUCCESS';
export const GET_ASSIGN_ROTATION_GRAPH_FAILURE = 'student/GET_ASSIGN_ROTATION_GRAPH_FAILURE';

export const GET_PLANNED_ROTATION_LIST_REQUESTED = 'student/GET_PLANNED_ROTATION_LIST_REQUESTED';
export const GET_PLANNED_ROTATION_LIST_SUCCESS = 'student/GET_PLANNED_ROTATION_LIST_SUCCESS';
export const GET_PLANNED_ROTATION_LIST_FAILURE = 'student/GET_PLANNED_ROTATION_LIST_FAILURE';

export const GET_PLANNED_ROTATION_DETAILS_REQUESTED = 'student/GET_PLANNED_ROTATION_DETAILS_REQUESTED';
export const GET_PLANNED_ROTATION_DETAILS_SUCCESS = 'student/GET_PLANNED_ROTATION_DETAILS_SUCCESS';
export const GET_PLANNED_ROTATION_DETAILS_FAILURE = 'student/GET_PLANNED_ROTATION_DETAILS_FAILURE';

export const GET_PLANNED_CHECKED_STUDENTS_SUCCESS = 'student/GET_PLANNED_CHECKED_STUDENTS_SUCCESS';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  hospitalList: null,
  hospitalDataById: null,
  avaliableRotationsList: null,
  thirdPartyList: null,
  clinicalDocument: null,
  clinicalRotationList: null,
  clinicalLogList: null,
  rotationListIsActive: [],
  clinicalPreceptorList: [],
  clinicalStudentList: [],
  hospitalPricePerWeek: null,
  replyToMailsList: [],
  rotationWithWeeks: [],
  preceptorDetailsById: {},
  rotationRoasterById: null,
  clinicalAssignRotation: {},
  showStep1AssignRotations: true,
  showStep2AssignRotations: false,
  showStep3AssignRotations: false,
  step1RotationData: {},
  submittedRottaionData: {},
  assignRotationGraph: null,
  plannedRotationList: null,
  plannedRotationDetails: null,
  getPlannedCheckedStudents: null
};

export default (state = initialState, action) => {
  switch (action.type) {
  case GET_HOSPITAL_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOSPITAL_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      hospitalList: action.result
    };
  }

  case GET_HOSPITAL_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_HOSPITAL_DETAILS_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOSPITAL_DETAILS_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      hospitalDataById: action.result
    };
  }

  case GET_HOSPITAL_DETAILS_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case ADD_OR_UPDATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case ADD_OR_UPDATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ADD_OR_UPDATE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case AVALIABLE_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case AVALIABLE_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      avaliableRotationsList: action.result,
    };
  }
  case AVALIABLE_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case THIRD_PARTY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case THIRD_PARTY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      thirdPartyList: action.result
    };
  }
  case THIRD_PARTY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case CLINICAL_DOCUMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case CLINICAL_DOCUMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalDocument: action.result && action.result.data,
    };
  }
  case CLINICAL_DOCUMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case CLINICAL_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLINICAL_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalRotationList: action.result && action.result.data
    };
  }
  case CLINICAL_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case CLINICAL_LOG_CLINICALS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLINICAL_LOG_CLINICALS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalLogList: action.result && action.result.data
    };
  }
  case CLINICAL_LOG_CLINICALS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case ROTATION_NAME_IS_ACTIVE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case ROTATION_NAME_IS_ACTIVE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationListIsActive: action.result && action.result.data
    };
  }
  case ROTATION_NAME_IS_ACTIVE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case CLINICAL_PRECEPTOR_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLINICAL_PRECEPTOR_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalPreceptorList: action.result && action.result.data
    };
  }
  case CLINICAL_PRECEPTOR_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case CLINICAL_STUDENT_SEARCH_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLINICAL_STUDENT_SEARCH_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalStudentList: action.result && action.result.data
    };
  }
  case CLINICAL_STUDENT_SEARCH_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_HOSPITAl_PRICE_PER_WEEK_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOSPITAl_PRICE_PER_WEEK_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      hospitalPricePerWeek: action.result && action.result.data
    };
  }
  case GET_HOSPITAl_PRICE_PER_WEEK_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_REPLY_TO_MAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_REPLY_TO_MAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      replyToMailsList: action.result && action.result.data
    };
  }
  case GET_REPLY_TO_MAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ROTATION_WITH_WEEKS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ROTATION_WITH_WEEKS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationWithWeeks: action.result && action.result.data
    };
  }
  case GET_ROTATION_WITH_WEEKS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PRECEPTOR_DETAILS_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_PRECEPTOR_DETAILS_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      preceptorDataById: action.result && action.result.data
    };
  }

  case GET_PRECEPTOR_DETAILS_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_ROTATION_ROASTER_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ROTATION_ROASTER_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationRoasterById: action.result
    };
  }

  case GET_ROTATION_ROASTER_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_CLINICAL_ASSIGN_ROTATION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_CLINICAL_ASSIGN_ROTATION_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalAssignRotation: action.result
    };
  }
  case GET_CLINICAL_ASSIGN_ROTATION_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case STEP1ROTATION_CLINICALS_SHOW: {
    return {
      ...state,
      showStep1AssignRotations: action.payload
    };
  } case STEP2ROTATION_CLINICALS_SHOW: {
    return {
      ...state,
      showStep2AssignRotations: action.payload
    };
  } case STEP3ROTATION_CLINICALS_SHOW: {
    return {
      ...state,
      showStep3AssignRotations: action.payload
    };
  }

  case ASSIGN_ROTATION_STEP1_DATA: {
    return {
      ...state,
      step1RotationData: action.payload
    };
  }

  case SUBMITTED_ROTATION_DATA: {
    return {
      ...state,
      submittedRottaionData: action.payload
    };
  }
  case GET_ASSIGN_ROTATION_GRAPH_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_ASSIGN_ROTATION_GRAPH_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      assignRotationGraph: action.result && action.result.data,
    };
  }
  case GET_ASSIGN_ROTATION_GRAPH_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PLANNED_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_PLANNED_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      plannedRotationList: action.result && action.result.data,
    };
  }
  case GET_PLANNED_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PLANNED_ROTATION_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_PLANNED_ROTATION_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      plannedRotationDetails: action.result,
    };
  }
  case GET_PLANNED_ROTATION_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PLANNED_CHECKED_STUDENTS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getPlannedCheckedStudents: action.result,
    };
  }

  default:
    return state;
  }
};

export const getHospitalList = (params) => {
  return {
    types: [GET_HOSPITAL_LIST_REQUESTED, GET_HOSPITAL_LIST_SUCCESS, GET_HOSPITAL_LIST_FAILURE],
    promise: (client) => client.get('hospitalList', { params })
  };
};

export const saveHospital = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('saveHospital', { data, isMultiPart: true })
  };
};

export const getHospitalDetailsById = (id) => {
  return {
    types: [GET_HOSPITAL_DETAILS_BY_ID_REQUESTED, GET_HOSPITAL_DETAILS_BY_ID_SUCCESS, GET_HOSPITAL_DETAILS_BY_ID_FAILURE],
    promise: (client) => client.get(`hospitalDataById/${id}`)
  };
};

export const getAvaliableRotations = () => {
  return {
    types: [AVALIABLE_ROTATION_LIST_REQUESTED, AVALIABLE_ROTATION_LIST_SUCCESS, AVALIABLE_ROTATION_LIST_FAILURE],
    promise: (client) => client.get('avaliableRotations')
  };
};

export const getThirdPartyList = () => {
  return {
    types: [THIRD_PARTY_LIST_REQUESTED, THIRD_PARTY_LIST_SUCCESS, THIRD_PARTY_LIST_FAILURE],
    promise: (client) => client.get('thirdParty')
  };
};

export const clinicalDocumentList = (id) => {
  return {
    types: [CLINICAL_DOCUMENT_LIST_REQUESTED, CLINICAL_DOCUMENT_LIST_SUCCESS, CLINICAL_DOCUMENT_LIST_FAILURE],
    promise: (client) => client.get(`clinicalDocumentList/${id}`)

  };
};

export const clinicalDocumentAdd = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('clinicalDocumentAddHospitals', { data, isMultiPart: true })

  };
};

export const getClinicalsRotationList = (params) => {
  return {
    types: [CLINICAL_ROTATION_LIST_REQUESTED, CLINICAL_ROTATION_LIST_SUCCESS, CLINICAL_ROTATION_LIST_FAILURE],
    promise: (client) => client.get('clinicalsRotationList', { params })
  };
};

export const getClinicalList = (params) => {
  return {
    types: [CLINICAL_LOG_CLINICALS_REQUESTED, CLINICAL_LOG_CLINICALS_SUCCESS, CLINICAL_LOG_CLINICALS_FAILURE],
    promise: (client) => client.get('clinicalsClinicalLog', { params })
  };
};

export const getRotationIsActive = (isActive) => {
  return {
    types: [ROTATION_NAME_IS_ACTIVE_REQUESTED, ROTATION_NAME_IS_ACTIVE_SUCCESS, ROTATION_NAME_IS_ACTIVE_FAILURE],
    promise: (client) => client.get(`rotationListActive/${isActive}`)
  };
};

export const getClinicalsPreceptorlist = (params) => {
  return {
    types: [CLINICAL_PRECEPTOR_LIST_REQUESTED, CLINICAL_PRECEPTOR_LIST_SUCCESS, CLINICAL_PRECEPTOR_LIST_FAILURE],
    promise: (client) => client.get('clinicalPreceptorList', { params })
  };
};

export const clinicalPreceptorAdd = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('clinicalPreceptorAdd', { data, isMultiPart: true })
  };
};

export const clinicalStudentsearch = (params) => {
  return {
    types: [CLINICAL_STUDENT_SEARCH_REQUESTED, CLINICAL_STUDENT_SEARCH_SUCCESS, CLINICAL_STUDENT_SEARCH_FAILURE],
    promise: (client) => client.get('clinicalStudentList', { params })
  };
};

export const getHospitalPricePerWeeks = (hospitalId) => {
  return {
    types: [GET_HOSPITAl_PRICE_PER_WEEK_REQUESTED, GET_HOSPITAl_PRICE_PER_WEEK_SUCCESS, GET_HOSPITAl_PRICE_PER_WEEK_FAILURE],
    promise: (client) => client.get(`pricePerWeekHospi/${hospitalId}`)
  };
};

export const assignRotationClinicals = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('clinicalAssignRotation', { data })
  };
};

export const getReplyToMails = () => {
  return {
    types: [GET_REPLY_TO_MAILS_REQUESTED, GET_REPLY_TO_MAILS_SUCCESS, GET_REPLY_TO_MAILS_FAILURE],
    promise: (client) => client.get('getReplyToMails')
  };
};

export const getRotationWithWeeks = () => {
  return {
    types: [GET_ROTATION_WITH_WEEKS_REQUESTED, GET_ROTATION_WITH_WEEKS_SUCCESS, GET_ROTATION_WITH_WEEKS_FAILURE],
    promise: (client) => client.get('rotationWithWeeks/Core')
  };
};

export const getRotationWithWeeksOnType = (type) => {
  return {
    types: [GET_ROTATION_WITH_WEEKS_REQUESTED, GET_ROTATION_WITH_WEEKS_SUCCESS, GET_ROTATION_WITH_WEEKS_FAILURE],
    promise: (client) => client.get(`rotationWithWeeks/${type}`)
  };
};

export const getPreceptorDetailsById = (id) => {
  return {
    types: [GET_PRECEPTOR_DETAILS_BY_ID_REQUESTED, GET_PRECEPTOR_DETAILS_BY_ID_SUCCESS, GET_PRECEPTOR_DETAILS_BY_ID_FAILURE],
    promise: (client) => client.get(`preceptorDataById/${id}`)
  };
};

export const getRotationRoasterById = (id) => {
  return {
    types: [GET_ROTATION_ROASTER_BY_ID_REQUESTED, GET_ROTATION_ROASTER_BY_ID_SUCCESS, GET_ROTATION_ROASTER_BY_ID_FAILURE],
    promise: (client) => client.get(`getClinicalRoaster/${id}`)
  };
};

export const updateRotationRoaster = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('updateClinicalRoaster', { data })
  };
};

export const getClinicalAssignRotation = (rotationId) => {
  return {
    types: [GET_CLINICAL_ASSIGN_ROTATION_REQUESTED, GET_CLINICAL_ASSIGN_ROTATION_SUCCESS, GET_CLINICAL_ASSIGN_ROTATION_FAILURE],
    promise: (client) => client.get(`getClinicalAssignRotation/${rotationId}`)
  };
};

export const setStep1AssignRotationClinicals = (data) => {
  return (dispatch) => {
    dispatch({
      type: STEP1ROTATION_CLINICALS_SHOW,
      payload: data,
    });
  };
};

export const setStep2AssignRotationClinicals = (data) => {
  return (dispatch) => {
    dispatch({
      type: STEP2ROTATION_CLINICALS_SHOW,
      payload: data,
    });
  };
};

export const setStep3AssignRotationClinicals = (data) => {
  return (dispatch) => {
    dispatch({
      type: STEP3ROTATION_CLINICALS_SHOW,
      payload: data,
    });
  };
};

export const step1RotationData = (data) => {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_ROTATION_STEP1_DATA,
      payload: data,
    });
  };
};

export const setSubmittedRotationData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SUBMITTED_ROTATION_DATA,
      payload: data,
    });
  };
};

export const getAssignRotationGraph = (params) => {
  return {
    types: [GET_ASSIGN_ROTATION_GRAPH_REQUESTED, GET_ASSIGN_ROTATION_GRAPH_SUCCESS, GET_ASSIGN_ROTATION_GRAPH_FAILURE],
    promise: (client) => client.post('assignRotationGraph', { params })
  };
};

export const planRotation = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addPlanRotation', { data })
  };
};

export const getPlannedRotationList = (params) => {
  return {
    types: [GET_PLANNED_ROTATION_LIST_REQUESTED, GET_PLANNED_ROTATION_LIST_SUCCESS, GET_PLANNED_ROTATION_LIST_FAILURE],
    promise: (client) => client.get('getPlannedRotationList', { params })
  };
};

export const getPlannedRotationDetails = (rotationId) => {
  return {
    types: [GET_PLANNED_ROTATION_DETAILS_REQUESTED, GET_PLANNED_ROTATION_DETAILS_SUCCESS, GET_PLANNED_ROTATION_DETAILS_FAILURE],
    promise: (client) => client.get(`getPlannedRotationDetails/${rotationId}`)
  };
};

export const scheduleRotation = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('scheduleRotation', { params })
  };
};

export const getPlannedCheckedStudents = (rotationId) => {
  return {
    types: [GET_PLANNED_ROTATION_DETAILS_REQUESTED, GET_PLANNED_CHECKED_STUDENTS_SUCCESS, GET_PLANNED_ROTATION_DETAILS_FAILURE],
    promise: (client) => client.get(`getPlannedCheckedStudents/${rotationId}`)
  };
};

export const deletePlannedRotation = (plannedRotationId, operation) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get(`deletePlannedRotation/${plannedRotationId}/${operation}`)
  };
};
