export default {
  INITIAL: ['activityLog', 'profile', 'status-change', 'admisiion-forms', 'address', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'immigration', 'application'],
  CREATED_PROFILE: ['activityLog', 'profile', 'status-change', 'admisiion-forms', 'application', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'immigration'],
  ACCEPTED: ['activityLog', 'profile', 'status-change', 'semester', 'application', 'admisiion-forms', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'estimates', 'immigration'],
  STUDENT: ['activityLog', 'profile', 'status-change', 'application', 'admisiion-forms', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'estimates', 'immigration'],
  ISTANBUL_INITIAL: ['activityLog', 'profile', 'status-change', 'address', 'document-list', 'notes', 'immigration', 'application'],
  ISTANBUL_CREATED_PROFILE: ['activityLog', 'profile', 'status-change', 'application', 'document-list', 'notes', 'immigration'],
  ISTANBUL_ACCEPTED: ['activityLog', 'profile', 'status-change', 'application', 'document-list', 'notes', 'semester', 'immigration'],
  INITIAL_STUDENTID: ['activityLog', 'profile', 'status-change', 'admisiion-forms', 'address', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'academicrecords', 'immigration', 'application'],
  CREATED_PROFILE_STUDENTID: ['activityLog', 'profile', 'status-change', 'admisiion-forms', 'application', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'academicrecords', 'immigration'],
  ACCEPTED_STUDENTID: ['activityLog', 'profile', 'status-change', 'semester', 'immigration', 'application', 'admisiion-forms', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'estimates', 'academicrecords', 'immigration', 'billingContact'],
  STUDENT_STUDENTID: ['activityLog', 'profile', 'status-change', 'application', 'admisiion-forms', 'document-list', 'email', 'notes', 'phone-notes', 'addtasks', 'estimates', 'academicrecords', 'immigration'],
  ISTANBUL_INITIAL_STUDENTID: ['activityLog', 'profile', 'status-change', 'address', 'document-list', 'notes', 'academicrecords', 'immigration', 'application'],
  ISTANBUL_ACCEPTED_STUDENTID: ['activityLog', 'profile', 'status-change', 'application', 'immigration', 'document-list', 'notes', 'semester', 'academicrecords', 'immigration'],
  ISTANBUL_CREATED_PROFILE_STUDENTID: ['activityLog', 'profile', 'status-change', 'application', 'document-list', 'notes', 'academicrecords', 'immigration'],

  // student
  ACCEPTED_STUDENT: ['personal', 'Address', 'admissionForms', 'semesterDeferment', 'files', 'housing', 'timeTable'],
  PAID_FINANCIAL_IMMIGRATION: ['personal', 'Address', 'admissionForms', 'courses', 'billing-educational', 'InvoiceAndPayments', 'creditsAndScholarships', 'billingContact', 'semesterDeferment', 'application', 'files', 'housing', 'timeTable'],
  STUDENT_SIDEMENU: ['personal', 'admissionForms', 'semesterDeferment', 'Address', 'courses', 'transcript', 'document-list', 'billing-educational', 'InvoiceAndPayments', 'creditsAndScholarships', 'queries', 'rotation', 'libraryCatalog', 'clinical-log', 'calendar', 'billingContact', 'exams', 'immigration', 'application', 'files', 'housing', 'timeTable'],
  STUDENT_NOT_AUTHORIZED: ['activity-log', 'personal', 'Address', 'academic-details', 'mcat', 'accounting-details', 'courses', 'transcript', 'document-list', 'billing-educational', 'notes', 'clinical-document', 'usmleTest', 'leave', 'degree', 'requets', 'accountingNotes', 'InvoiceAndPayments', 'creditsAndScholarships', 'queries', 'rotation', 'email', 'otherDetails', 'statusChange', 'application', 'academicrecords', 'academic-records2', 'academic-records3', 'academic-records4', 'profile', 'chats', 'files', 'smsSection', 'med6loanotes', 'billingContact', 'exams', 'immigration', 'snap-shot', 'housing', 'timeTable'],
  STUDENT_WITHDRAWN_ADMIN: ['activity-log', 'personal', 'Address', 'academic-details', 'mcat', 'accounting-details', 'courses', 'transcript', 'document-list', 'billing-educational', 'notes', 'clinical-document', 'usmleTest', 'leave', 'degree', 'requets', 'accountingNotes', 'InvoiceAndPayments', 'creditsAndScholarships', 'queries', 'rotation', 'email', 'otherDetails', 'statusChange', 'application', 'profile', 'chats', 'smsSection', 'med6loanotes', 'exams', 'immigration', 'snap-shot', 'files', 'housing', 'timeTable'],
  ALUMNI: ['semesterDeferment', 'activity-log', 'alumni', 'personal', 'Address', 'academic-details', 'mcat', 'accounting-details', 'courses', 'transcript', 'document-list', 'billing-educational', 'notes', 'clinical-document', 'usmleTest', 'leave', 'degree', 'requets', 'accountingNotes', 'InvoiceAndPayments', 'creditsAndScholarships', 'queries', 'rotation', 'email', 'otherDetails', 'statusChange', 'application', 'profile', 'chats', 'smsSection', 'med6loanotes', 'exams', 'immigration', 'snap-shot', 'files', 'housing', 'timeTable'],
  WITHDRAWN: ['transcript', 'queries', 'files', 'timeTable'],
  STUDENT_ALUMNI: ['alumni', 'courses', 'transcript', 'queries', 'calendar', 'files', 'housing', 'timeTable'],
  STUDENT_LEAD: ['semesterDeferment', 'admissionForms', 'personal', 'Address', 'courses', 'document-list', 'billing-educational', 'InvoiceAndPayments', 'creditsAndScholarships', 'billingContact', 'immigration', 'application', 'files', 'housing', 'timeTable'],
  STUDENT_MED: ['semesterDeferment', 'personal', 'Address', 'transcript', 'libraryCatalog', 'files', 'housing', 'timeTable'],
  STUDENT_SIDEMENU_GRAD: ['semesterDeferment', 'admissionForms', 'personal', 'Address', 'courses', 'transcript', 'files', 'billing-educational', 'document-list', 'InvoiceAndPayments', 'creditsAndScholarships', 'queries', 'rotation', 'libraryCatalog', 'clinical-log', 'calendar', 'graduation', 'billingContact', 'application', 'housing', 'timeTable'],
  FINANCIAL_BLOCK: ['InvoiceAndPayments', 'timeTable'],
  EMPLOYEE_JOB_OFFER: ['offerLetter', 'timeTable'],
  NOTIFY_STATUS: ['personal', 'billingContact', 'timeTable']

};
