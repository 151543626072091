export const ADD_OR_UPDATE_REQUESTED = 'requests/ADD_OR_UPDATE_REQUESTED';
export const ADD_OR_UPDATE_SUCCESS = 'requests/ADD_OR_UPDATE_SUCCESS';
export const ADD_OR_UPDATE_FAILURE = 'requests/ADD_OR_UPDATE_FAILURE';

export const MPSE_REQUESTS_LIST_REQUESTED = 'requests/MPSE_REQUESTS_LIST_REQUESTED';
export const MPSE_REQUESTS_LIST_SUCCESS = 'requests/MPSE_REQUESTS_LIST_SUCCESS';
export const MPSE_REQUESTS_LIST_FAILURE = 'requests/MPSE_REQUESTS_LIST_FAILURE';

export const GET_PROGRAM_DATA_BY_ID_REQUESTED = 'requests/GET_PROGRAM_DATA_BY_ID_REQUESTED';
export const GET_PROGRAM_DATA_BY_ID_SUCCESS = 'requests/GET_PROGRAM_DATA_BY_ID_SUCCESS';
export const GET_PROGRAM_DATA_BY_ID_FAILURE = 'requests/GET_PROGRAM_DATA_BY_ID_FAILURE';

export const GET_PROGRAM_LIST_REQUESTED = 'requests/GET_PROGRAM_LIST_REQUESTED';
export const GET_PROGRAM_LIST_SUCCESS = 'requests/GET_PROGRAM_LIST_SUCCESS';
export const GET_PROGRAM_LIST_FAILURE = 'requests/GET_PROGRAM_LIST_FAILURE';

export const GET_CLASS_WITHDRAWAL_LIST_REQUESTED = 'requests/GET_CLASS_WITHDRAWAL_LIST_REQUESTED';
export const GET_CLASS_WITHDRAWAL_LIST_SUCCESS = 'requests/GET_CLASS_WITHDRAWAL_LIST_SUCCESS';
export const GET_CLASS_WITHDRAWAL_LIST_FAILURE = 'requests/GET_CLASS_WITHDRAWAL_LIST_FAILURE';

export const GET_CLASS_WITHDRAWAL_LIST_BY_ID_REQUESTED = 'requests/GET_CLASS_WITHDRAWAL_LIST_BY_ID_REQUESTED';
export const GET_CLASS_WITHDRAWAL_LIST_BY_ID_SUCCESS = 'requests/GET_CLASS_WITHDRAWAL_LIST_BY_ID_SUCCESS';
export const GET_CLASS_WITHDRAWAL_LIST_BY_ID_FAILURE = 'requests/GET_CLASS_WITHDRAWAL_LIST_BY_ID_FAILURE';

export const GET_STUDENT_FINANCE_LIST_REQUESTED = 'requests/GET_STUDENT_FINANCE_LIST_REQUESTED';
export const GET_STUDENT_FINANCE_LIST_SUCCESS = 'requests/GET_STUDENT_FINANCE_LIST_SUCCESS';
export const GET_STUDENT_FINANCE_LIST_FAILURE = 'requests/GET_STUDENT_FINANCE_LIST_FAILURE';

export const GET_STUDENT_FINANCE_LIST_BY_ID_REQUESTED = 'requests/GET_STUDENT_FINANCE_LIST_BY_ID_REQUESTED';
export const GET_STUDENT_FINANCE_LIST_BY_ID_SUCCESS = 'requests/GET_STUDENT_FINANCE_LIST_BY_ID_SUCCESS';
export const GET_STUDENT_FINANCE_LIST_BY_ID_FAILURE = 'requests/GET_STUDENT_FINANCE_LIST_BY_ID_FAILURE';

export const GET_MSPE_LIST_BY_ID_REQUESTED = 'requests/GET_MSPE_LIST_BY_ID_REQUESTED';
export const GET_MSPE_LIST_BY_ID_SUCCESS = 'requests/GET_MSPE_LIST_BY_ID_SUCCESS';
export const GET_MSPE_LIST_BY_ID_FAILURE = 'requests/GET_MSPE_LIST_BY_ID_FAILURE';

export const GET_TRANSCRIPT_REQUEST_REQUESTED = 'requests/GET_TRANSCRIPT_REQUEST_REQUESTED';
export const GET_TRANSCRIPT_REQUEST_SUCCESS = 'requests/GET_TRANSCRIPT_REQUEST_SUCCESS';
export const GET_TRANSCRIPT_REQUEST_FAILURE = 'requests/GET_TRANSCRIPT_REQUEST_FAILURE';

export const GET_TRANSCRIPT_BY_ID_REQUESTED = 'requests/GET_TRANSCRIPT_BY_ID_REQUESTED';
export const GET_TRANSCRIPT_BY_ID_SUCCESS = 'requests/GET_TRANSCRIPT_BY_ID_SUCCESS';
export const GET_TRANSCRIPT_BY_ID_FAILURE = 'requests/GET_TRANSCRIPT_BY_ID_FAILURE';

export const REQUESTS_INVOICE_LIST_SUCCESS = 'requests/REQUESTS_INVOICE_LIST_SUCCESS';

export const REQUESTS_INVOICE_BY_ID_SUCCESS = 'requests/REQUESTS_INVOICE_BY_ID_SUCCESS';

export const REQUEST_INVOICE_HEAD_LIST_SUCCESS = 'requests/REQUEST_INVOICE_HEAD_LIST_SUCCESS';

export const FINANCE_REQUEST_LOGS_REQUESTED = 'student/FINANCE_REQUEST_LOGS_REQUESTED';
export const FINANCE_REQUEST_LOGS_SUCCESS = 'student/FINANCE_REQUEST_LOGS_SUCCESS';
export const FINANCE_REQUEST_LOGS_FAILURE = 'student/FINANCE_REQUEST_LOGS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  mpseList: [],
  programDataById: {},
  programWithdrawalList: {},
  classWithDrawalList: {},
  classWithDrawalListById: {},
  studentFinanceList: null,
  studentFinanceListById: {},
  mspeDataById: {},
  transcriptRequestList: null,
  transcriptRequestById: null,
  invoiceRequestsList: null,
  invoiceRequestsById: null,
  invoiceHeadListRequest: null,
  getFinanceLogs: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case MPSE_REQUESTS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case MPSE_REQUESTS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      mpseList: action.result && action.result.data
    };
  }
  case MPSE_REQUESTS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case ADD_OR_UPDATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case ADD_OR_UPDATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_OR_UPDATE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PROGRAM_DATA_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_PROGRAM_DATA_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      programDataById: action.result && action.result.data
    };
  }
  case GET_PROGRAM_DATA_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PROGRAM_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_PROGRAM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      programWithdrawalList: action.result && action.result.data
    };
  }
  case GET_PROGRAM_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      classWithDrawalList: action.result && action.result.data
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      classWithDrawalListById: action.result && action.result.data
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_STUDENT_FINANCE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_STUDENT_FINANCE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentFinanceList: action.result && action.result.data
    };
  }
  case GET_STUDENT_FINANCE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_FINANCE_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_STUDENT_FINANCE_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentFinanceListById: action.result && action.result.data
    };
  }
  case GET_STUDENT_FINANCE_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_MSPE_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MSPE_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getMSPEListById: action.result && action.result.data
    };
  }
  case GET_MSPE_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_TRANSCRIPT_REQUEST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_TRANSCRIPT_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      transcriptRequestList: action.result
    };
  }
  case GET_TRANSCRIPT_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_TRANSCRIPT_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_TRANSCRIPT_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      transcriptRequestById: action.result
    };
  }
  case GET_TRANSCRIPT_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case REQUESTS_INVOICE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoiceRequestsList: action.result?.data
    };
  }
  case REQUESTS_INVOICE_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoiceRequestsById: action.result?.data
    };
  }
  case REQUEST_INVOICE_HEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoiceHeadListRequest: action.result?.data
    };
  }
  case FINANCE_REQUEST_LOGS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case FINANCE_REQUEST_LOGS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getFinanceLogs: action.result && action.result.data,
    };
  }

  case FINANCE_REQUEST_LOGS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      getFinanceLogs: null,
    };
  }
  default:
    return state;
  }
};

export const getMpseRequests = (params) => {
  return {
    types: [MPSE_REQUESTS_LIST_REQUESTED, MPSE_REQUESTS_LIST_SUCCESS, MPSE_REQUESTS_LIST_FAILURE],
    promise: (client) => client.get('mspeList', { params })
  };
};

export const addProgramWithDrawal = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('programWithdrawalUpdate', { data })
  };
};

export const getProgramWithDrawalById = (id) => {
  return {
    types: [GET_PROGRAM_DATA_BY_ID_REQUESTED, GET_PROGRAM_DATA_BY_ID_SUCCESS, GET_PROGRAM_DATA_BY_ID_FAILURE],
    promise: (client) => client.get(`programWithdrawalById/${id}`)
  };
};

export const getProgramWithDrawalList = (params) => {
  return {
    types: [GET_PROGRAM_LIST_REQUESTED, GET_PROGRAM_LIST_SUCCESS, GET_PROGRAM_LIST_FAILURE],
    promise: (client) => client.get('programWithdrawalList', { params })
  };
};

export const getClassWithDrawalList = (params) => {
  return {
    types: [GET_CLASS_WITHDRAWAL_LIST_REQUESTED, GET_CLASS_WITHDRAWAL_LIST_SUCCESS, GET_CLASS_WITHDRAWAL_LIST_FAILURE],
    promise: (client) => client.get('classWithdrawalList', { params })
  };
};

export const getClassWithDrawalListById = (id) => {
  return {
    types: [GET_CLASS_WITHDRAWAL_LIST_BY_ID_REQUESTED, GET_CLASS_WITHDRAWAL_LIST_BY_ID_SUCCESS, GET_CLASS_WITHDRAWAL_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`classWithdrawalById/${id}`)
  };
};

export const addClassWithdrawalById = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addClassWithdrawalById', { data })
  };
};

export const getStudentFinanceList = (params) => {
  return {
    types: [GET_STUDENT_FINANCE_LIST_REQUESTED, GET_STUDENT_FINANCE_LIST_SUCCESS, GET_STUDENT_FINANCE_LIST_FAILURE],
    promise: (client) => client.get('studentFinanceList', { params })
  };
};

export const getStudentFinancewithId = (studentId, id) => {
  return {
    types: [GET_STUDENT_FINANCE_LIST_BY_ID_REQUESTED, GET_STUDENT_FINANCE_LIST_BY_ID_SUCCESS, GET_STUDENT_FINANCE_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`studentFinanceListbyId/${studentId}/${id}`)
  };
};

export const getMSPEListById = (id) => {
  return {
    types: [GET_MSPE_LIST_BY_ID_REQUESTED, GET_MSPE_LIST_BY_ID_SUCCESS, GET_MSPE_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`getMSPEListById/${id}`)
  };
};

export const addUpdateMSPEData = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addUpdateMSPE', { data, isMultiPart: true })
  };
};

export const updateStudentFinance = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('studentFinanceAdd', { data, isMultiPart: true })
  };
};

export const getTranscriptRequestList = (params) => {
  return {
    types: [GET_TRANSCRIPT_REQUEST_REQUESTED, GET_TRANSCRIPT_REQUEST_SUCCESS, GET_TRANSCRIPT_REQUEST_FAILURE],
    promise: (client) => client.get('getTranscriptRequestList', { params })
  };
};

export const payTranscriptRequest = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('payTranscriptRequest', { data })
  };
};

export const rejectTranscriptRequest = (transcriptId) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post(`rejectTranscriptRequest/${transcriptId}`)
  };
};

export const getTranscriptRequestById = (id) => {
  return {
    types: [GET_TRANSCRIPT_BY_ID_REQUESTED, GET_TRANSCRIPT_BY_ID_SUCCESS, GET_TRANSCRIPT_BY_ID_FAILURE],
    promise: (client) => client.get(`getTranscriptRequestById/${id}`)
  };
};

export const getInvoiceRequestList = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, REQUESTS_INVOICE_LIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('invoiceRequestList', { params })
  };
};

export const getInvoiceRequestById = (id) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, REQUESTS_INVOICE_BY_ID_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get(`invoiceRequestList/${id}`)
  };
};

export const getInvoiceRequestHeadList = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, REQUEST_INVOICE_HEAD_LIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('invoiceHeadListRequest', { params })
  };
};

export const getFinanceRequestLogs = (id) => {
  return {
    types: [FINANCE_REQUEST_LOGS_REQUESTED, FINANCE_REQUEST_LOGS_SUCCESS, FINANCE_REQUEST_LOGS_FAILURE],
    promise: (client) => client.get(`financeRequestLogs/${id}`)
  };
};
