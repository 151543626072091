export default {
  ASSIGNEDTOTYPEID: '70b60604-de44-11eb-ba80-0242ac130004', // 2
  STATES_ID: '74E63F99-5E0F-4DFC-B540-6C92E6904F8C', // 0

  // Employee
  jobOfferedStatus: '7791318E-2B7B-4263-B9FF-D5D481232C38', // 5
  terminatedStatus: '0A1AF8D5-2265-43DA-BA71-2BE97832BCFD', // 1
  probabtionStatus: 'C8A88AB2-6EDB-43E3-A276-BA4426E8AE97', // 4
  CATEGORY_PASSPORT: 'A43AA166-DE43-11EB-BA80-0242AC130004', // 9
  CATEGORY_VISA: 'A43AA2D8-DE43-11EB-BA80-0242AC130004', // 10
  CATEGORY_DL: 'A43AA3C8-DE43-11EB-BA80-0242AC130004', // 11
  CATEGORY_OTHERS: 'A43AA4AE-DE43-11EB-BA80-0242AC130004', // 12

  // employee document type
  EMP_DOCUMENTS: 1,
  EMP_DOC_TYPE_OFFER: 2,
  EMP_DOC_TYPE_PASSPORT: 3,

  US_COUNTRYID: 'FF81A9AE-8C2E-446B-9DEB-6E8FA622F2EB',

  JOB_OFFERED: 'Job Offered',

  // student note type
  STUDENT_NOTE_TYPE: 1,
  STUDENT_ACCOUNTING_NOTE_TYPE: 2,
  STUDENT_MED6_NOTE_TYPE: 3,

  // Alumni
  POSITION_TYPE: 'Residency',
  OTHER_POSITION_TYPE: 'Other',

  // LEAD STATUS IDS
  LEAD_STATUS_ACCEPTED: '21000C4F-0A62-4DF6-B132-E0DCC47E73F4',
  LEAD_STATUS_FINANCIALLY_CLEARED: '6E137B56-9BEA-4F8E-A202-D5D9E2BAC989',
  LEAD_STATUS_PAID_SEAT_DEPOSIT: 'EDA4998B-FE87-4F34-A4D6-287056B737E1',
  LEAD_STATUS_IMMIGRATION_CLEARED: '605A40BC-DC19-4DF9-BF2D-D8BD734A133F',
  LEAD_STATUS_STUDENT: '4CF7D782-0C25-404E-898D-5384F0660654',

  // Student Courses
  PREMED_COURSE: '8DBC4466-80D6-4B83-AEEC-10122D9734C0',
  MSC_HEALTH_SC_COURSE: '63132E00-81F8-4765-977E-6873D71A831E',
  MED_COURSE: '6E7B25B2-8654-473E-877B-AA41493F5682',
  DOCTOR_MEDICINE_COURSE: '82CF0BB6-7048-4F96-A31C-5DB7A19F0120',

  // Student Clinical Transfer
  NON_ASSOCIATED_HOSPITAL: '9b8c3a4b-03f3-434c-b2fa-d7967bfd7973',
  STUDY_ROTATION: '5EA932C1-01CA-4F12-AF66-2109D6EA425F',
  MEDICAL_LOA: '6B8B8379-213D-4495-B10E-092F362B5BC8',

  // Non-Clincal Course Stage
  MED_6: '54651B24-7038-41B1-AE05-B503C13B3AD4',
  MED6_LOA: '20',
  PRE_PRE_MED: '8E5E2141-FBF1-477E-9BB8-527C77172C76',

  // Regex
  zipCode: /^[0-9]|(\d+[A-Za-z]+)|([A-Za-z]+\d+)|\w-\w+$/,
  // phoneValidation: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
  // phoneValidation: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
  phoneValidation: /^\D*(?:\d\D*){10,}$/,
  // phoneValidation: /^(\+?\d{0,4})?(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
  phoneValidationNew: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{5}\)?)?$/,
  passwordRegex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
  passportValidation: /^[a-zA-Z0-9]+$/,
  paymentWithDecimalValidation: /^(\d+)(\.\d{1,2})?$/,
  startEndWhiteSpaceValidation: /^[^\s]+(\s+[^\s]+)*$/,

  gpaWithDecimalValidation: /^$|^[0-9]{1,2}(\.[0-9]{1,2})?$/,
  // answerType
  SURVEY_ANSWER_TYPE_RADIO_1: 1,
  SURVEY_ANSWER_TYPE_RADIO_7: 7,
  SURVEY_ANSWER_TYPE_RADIO_8: 8,
  SURVEY_ANSWER_TYPE_RADIO_9: 9,
  SURVEY_ANSWER_TYPE_RADIO_10: 10,
  SURVEY_ANSWER_TYPE_RADIO_11: 11,
  SURVEY_ANSWER_TYPE_RADIO_13: 13,
  SURVEY_ANSWER_TYPE_RADIO_14: 14,
  SURVEY_ANSWER_TYPE_RADIO_15: 15,
  SURVEY_ANSWER_TYPE_TEXT: 2,
  SURVEY_ANSWER_TYPE_DESCRIPTION: 3,
  SURVEY_ANSWER_TYPE_CHECK: 4,
  SURVEY_ANSWER_TYPE_RADIO_TEXT: 5,
  SURVEY_ANSWER_TYPE_CHECK_TEXT: 6,
  OTHER_OPTION_ID: '1FA490E3-1370-41B0-974B-A45D6DDD5A21',

  ACCOUNTING_TYPE: 1,
  FINANCIAL_TYPE: 2,
  // Query Status
  WAITING_FOR_ACCEPTANCE: 'Waiting for acceptance',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  INPROGRESS: 'In Progress',
  SUBMITTED: 'Submitted',
  COMPLETED: 'Completed',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  WAITING_FOR_APPROVAL: 'Waiting for Approval',
  PENDING_NO_WEEKS_AVAILABLE: 'Pending no weeks available',

  // Calendar Event Groups
  CALENDAR_GROUP: 'f5e838d8-2089-11ec-9621-0242ac130002',
  EVENT_TYPE_ROTATION: '979972b2-21d1-11ec-9621-0242ac130002',
  EVENT_TYPE_ROTATION_GREEN: 'c8786324-8a4b-11ec-bdca-0e028a927cc7',
  EVENT_TYPE_ROTATION_ORANGE: 'cca36a85-8a4b-11ec-bdca-0e028a927cc7',
  EVENT_TYPE_ROTATION_RED: '8e4b5368-9ebe-11ec-bf9e-900f0cb2dae3',

  // survey form
  FIRST_AND_LAST_NAME_ID: '29D0BB61-13D9-4F47-AD64-1EFBA39DD11A',
  PRECEPTOR_NAME_ID: '7EC4177E-6BA0-4D8F-B123-BE32F934FA91',
  STARTDATE_ENDDATE_ID: '532FC28D-35E4-4CCC-9E43-BDBCBA0EB482',
  HOSPITAL_NAME_ID: '836B9F03-2774-4798-B096-5B6EA36344D3',

  // Accounting course ID
  CLINICAL: '0D77E3F4-358D-40C2-957A-3DDCEE31D6A5',
  PREMED: '8DBC4466-80D6-4B83-AEEC-10122D9734C0',
  MED: '6E7B25B2-8654-473E-877B-AA41493F5682',
  MDFP: '51E45657-6FB5-49A7-A1EA-2D34FDB72067',

  PREMED1: 'E3478E0F-CDB4-40C9-956C-2003F46DE203',

  // Country Id's
  ARUBA_ID: '3282D9BA-45BA-46E2-BEAE-3B22C7BBE5D7',
  CANADA_ID: '43D24582-C58F-4ACE-9021-275BB6881ADF',
  UNITED_STATES_ID: 'FF81A9AE-8C2E-446B-9DEB-6E8FA622F2EB',
  SPRING_2022: '66feaed5-b795-421e-bca7-2843d803a455',
  FALL_2021: '80bfe384-d4fe-4a49-88d6-8624779d9bbc',
  // fee settings
  PreMed: 1,
  Med1to5: 2,
  Med6: 3,
  Clinical: 4,
  NonClinicalOtherFees: 5,
  ClinicalOtherFees: 6,
  General: 7,
  Leads: 8,
  Installment: 9,
  HousingFee: 10,
  BasicScienceLoa: 11,
  premedLoa: 12,
  ASP: 13,

  // DocuSign
  EVENT_COMPLETE: 'signing_complete',
  EVENT_DECLINE: 'decline',
  EVENT_EXPIRED: 'ttl_expired',
  INITIATED: 'Initiated',
  CONTRACT_SENT: 'Contract Sent',

  GRADUATION_FORM: 'graduation_form',
  CLINICAL_REQUEST: 'clinical_request',
  CLASS_WITHDRAWAL_FORM: 'class_withdrawal_form',
  COMPLAINT_FORM: 'complaint-form',
  FINANCE_REQUEST_FORM: 'finance_request_form',
  VERIFICATION_OF_ENROLLMENT: 'verification_of_enrollment',
  PROGRAM_WITHDRAWAL_FORM: 'program_withdrawal_form',
  BASIC_SCIENCE_LEAVE_OF_ABSENCE_REQUEST: 'basic_science_leave_of_absence_request',
  LEAVE_OF_ABSENCE_REQUEST: 'leave_of_absence_request',
  I_94_LETTER: 'i-94_letter',
  MSPE_REQUEST: 'mspe_request',
  REPLACEMENT_DIPLOMA_REQUEST: 'replacement_diploma_request',
  MEMORANDUM_OF_UNDERSTANDING: 'memorandum_of_understanding',
  TRANSFER_CREDITS: 'transfer_credits',
  STATEMENT_OF_UNDERSTANDING: 'statement_of_understanding',
  STATE_MEDICAL_LICENSURE: 'state_medical_licensure',
  CONSENT_FORM: 'consent_form',
  OFFER_LETTER: 'employee_offer_letter',
  APPEAL_REQUEST: 'appeal',
  DENIED_APPEAL: 'appeal_denied',
  STUDENT_APPEAL: 'student_appeal',
  ADMISSION_DOCUMENTS: 'admission_documents',
  ACCEPTANCE_LETTER: 'acceptance_letter',
  SEMESTER_DEFERMENT: 'semester_deferment',
  PRECEPTOR_EVAL: 'preceptor_evaluation',
  STUDENT_HANDBOOK: 'student_handbook',
  MOM: 'committee_meeting_mom',
  STUDENT_CLASS: 'accepet_course_classes',
  STUDENT_COURSE_HANDBOOK: 'course_student_handbook',
  STUDENT_ROTATION: 'accept_plan_rotation',
  HOUSING_CONTRACT: 'housing_student_contract',

  CLINICAL_LOG_OTHER: 'b4b017da-88d3-11ec-bdca-0e028a927cc7',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  SIGNING_COMPLETE: 'signing complete',

  // Invoice
  ROOM_FEE: 7000,

  // REVENUE
  MONTHLY_COURSE_TYPE: 3,
  WEEKLY_COURSE_TYPE: 2,
  NON_CLINICAL_COURSE_TYPE: 1,

  // Course Status
  ADMINISTRATIVE_WITHDRAWN: 'Administrative Withdrawn',
  TRANSFER: 'Transfer',
  UNKNOWN: 'Unknown',
  CLOSED: 'Closed',
  WITHDRAWN: 'Withdrawn',

  // Pay Seat Deposit Status
  ALREADY_PAID: 'Already Paid',
  PENDING: 'Pending',

  // DocuSign Signer Type
  STUDENT: 'STUDENT',
  REGISTRAR: 'REGISTRAR',
  BURSAR: 'BURSAR',
  ACCOUNTING: 'ACCOUNTING',
  DEAN: 'DEAN',
  CLINICAL_DEAN: 'CLINICAL_DEAN',
  CONTROLLER: 'CONTROLLER',

  // Firebase FCM
  fcmUrl: 'https://fcm.googleapis.com/fcm/send',
  fcmAuthorization: 'key=AAAA0aZCWp4:APA91bFbHBHfjBC_-Bw_r3f_Wg-nxTmyjFgyHKYjPNnRAxjX125jTKz8Exj4qJpnC2A60gYVuvkLqxmHL46WMN28XuOUMIyJ26Z9Hx44GUycun6lBzP8jRehM6bmbhE2fyWE2IJ1kIpb',
  EVENT_MEETUP: '722d56d8-d9b6-449c-b3ff-2a9e6280b1d8',
  EVENT_GROUP: 'f5e838d8-2089-11ec-9621-0242ac130002',
  GROUP_STUDENT: '507b2925-7380-4a21-b942-a0b0babbe4b9',

  // Appeal request Conditions
  APPEAL_PENDING: 'Pending',
  GRANTED: 'Granted',
  APPEAL_GRANTED: 'Granted (Pending Admin Approval of Conditions)',
  APPEAL_GRANTED_PENDING_SIGNATURE: 'Granted (Pending student Signature)',
  APPEAL_DENIED: 'Denied',
  APPEAL_GRANTED_PENDING_CONDITIONS: 'Granted (Pending conditions)',
  APPEAL_APPROVED: 'Approved (All conditions met)',
  NOT_APPROVED: 'Not Approved (Conditions not met)',
  APPEAL_CANCELLED: 'Cancelled by student',

  // survey
  SURVEY_GRADUATION_TYPE: 'Graduation',

  // Assign Class
  FACULTY_ID: '7AA36493-C6D7-4AEA-970A-4EEAAAD649D5',

  // dms url

  DOCUMENT_DMS: 'http://35.86.211.251/seeddms/out/out.ViewFolder.php',

  // Dashboard

  //  DASHBOARD: 'http://35.82.98.159:8086/d/xz4sJ4jnz/admin-dashboard?orgId=1&from=1623158892000&to=1662644099000&kiosk&theme=light',
  DASHBOARD: 'https://grafanaprod.xusom.com/d/xz4sJ4jnz/admin-dashboard?orgId=1&from=1623177000000&to=1662748199000&theme=light&kiosk',
  TUITION_FEE_SERVICE: 'TutionFee',
  ROOM_AND_BOARD_FEE_SERVICE: 'RoomAndBoardFee',
  INSURANCE_SERVICE: 'Insurance',

  // Citizenship
  US: 'FF81A9AE-8C2E-446B-9DEB-6E8FA622F2EB',
  INDIA: 'C520668A-39BB-49D6-A701-872F41CDB2CE',
  CANADA: '43D24582-C58F-4ACE-9021-275BB6881ADF',
  GHANA: 'A49986B7-2B9D-4A71-899F-251FD215C305',
  PALESTINE: '42B0F6A1-32E1-4349-BE07-9E60A383FDC8',
  OTHER: '5090E859-A1C2-4349-BEEB-AE067BE4D385',

  BASIC_SCIENCE_LOA: 'D65E389F-3EFB-4515-841F-5DA7472D74CF',

  ClinicalMedicineRotation: 'DE9A20CC-6EC6-48EB-811D-098E1DF4136B',
  ClinicalMedicinePendingtostart: 'BC5A20CC-6EC6-48EB-811D-098E1DF4156C',
  ClinicalMedicineLOA: '54651B24-7998-41D1-RD05-D502R16B9AD4',
  CompletedClincal: '678A1B24-7998-41D1-DS05-F502R26F9AX4',

  // MOM STATUS
  FINAL_MOM_PENDING: 'Final MOM Sent pending signatures',
  MOM_SIGNED: 'MOM Signed',
  SCHEDULED: 'Scheduled',
  CANCELLED: 'Cancelled',
  MOM_DRAFT: 'Pending Draft MOM to be sent',
  COMMITTEE_MEETING: 'f4cd2d92-5b47-11ed-94c3-b48c9d08af75',

  // EXAMS
  CLINICAL_SKILL_ASSESSMENT: 'f7bcc0f1-d2c4-11ec-b1f3-0e028a927cc7',
  OET_EXAM: '01bff8d1-4de3-11ed-9ed5-0e32c7f6f105',
  CBS_Exam: 'BF845F0F-6468-4B2A-B1B3-638584007A84',
  CCS_Exam: 'E2D0456D-C6C4-4798-95EA-DC947286C105',

  //  Document Folders
  UNOFFICIAL_TRANSSCRIPTS_FOLDER: '519687E7-DE19-40B9-96B5-86EF4984BE60',
  OFFICIAL_TRANSSCRIPTS_FOLDER: '0A8535F8-E7E1-487E-956E-6B43AFE83C0C',
  LEAD_OFFICIAL_TRANSSCRIPTS_FOLDER: '39A5034D-9E47-40B9-9642-6C9F2332CF51',
  LEAD_UNOFFICIAL_TRANSSCRIPTS_FOLDER: '690E6218-42DC-4AAA-98AE-9CA9C22364A8',

  //  Document Folders
  UNOFFICIAL_TRANSSCRIPTS_DOCTYPE: '690E6218-42DC-4AAA-98AE-9CA9C22364A8',
  OFFICIAL_TRANSSCRIPTS_DOCTYPE: '39A5034D-9E47-40B9-9642-6C9F2332CF51',
  OCCUPIED: 'Occupied',
  AVAILABE: 'Available',
  UNDER_MAINTAINANCE: 'Under Maintainance',
  PROFILE_PHOTO_CATEGORY_ID: '502f93cb-8a92-11ee-8946-b48c9d08af8d',

  // Housing Status
  FULLY_PAID: 'FP',

  // MCAT
  MCAT_EXAM: '9E6A65FA-C6EB-4E5B-B413-F83046B1142A',

  // Campus
  VETERINARY_CAMPUS: 'RF30W73A-4DD5-4917-S450-GTB985BF206E',
};
