export const ADD_ACTION_REQUESTED = 'ct/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'ct/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'ct/ADD_ACTION_FAILURE';

export const GET_CT_SEMESTER_SUCCESS = 'ct/GET_CT_SEMESTER_SUCCESS';

export const GET_CT_INCOMING_MAIL_LOG_SUCCESS = 'ct/GET_CT_INCOMING_MAIL_LOG';

export const GET_CT_INCOMING_MAIL_LOG_BY_ID_SUCCESS = 'ct/GET_CT_INCOMING_MAIL_LOG_BY_ID_SUCCESS';

export const GET_CT_ARUBA_INCOMING_SUCCESS = 'ct/GET_CT_ARUBA_INCOMING_SUCCESS';

export const GET_CT_ARUBA_INCOMING_LOG_BY_ID_SUCCESS = 'ct/GET_CT_ARUBA_INCOMING_LOG_BY_ID_SUCCESS';

export const GET_ARUBA_OUTGOING_MAIL_LIST = 'admin/GET_ARUBA_OUTGOING_MAIL_LIST';

export const GET_OUTGOING_MAIL_LIST = 'admin/GET_OUTGOING_MAIL_LIST';

export const GET_TODO_STATUS_LIST = 'admin/GET_TODO_STATUS_LIST';

export const GET_EMPLOYEE_STATUS_LIST = 'admin/GET_EMPLOYEE_STATUS_LIST';

export const GET_POSITION_STATUS_LIST = 'admin/GET_POSITION_STATUS_LIST';

export const GET_ARUBA_BY_ID_SUCCESS = 'admin/GET_ARUBA_BY_ID_SUCCESS';

export const GET_CT_SEMESTER_BY_ID_SUCCESS = 'ct/GET_CT_SEMESTER_BY_ID_SUCCESS';

export const GET_CT_CLINICALS_OPTIONS_BY_ID_SUCCESS = 'ct/GET_CT_CLINICALS_OPTIONS_BY_ID_SUCCESS';

export const GET_CT_CLINICALS_OPTIONS_LIST_SUCCESS = 'ct/GET_CT_CLINICALS_OPTIONS_LIST_SUCCESS';

export const GET_CT_CLINICALS_PROBLEMS_LIST_SUCCESS = 'ct/GET_CT_CLINICALS_PROBLEMS_LIST_SUCCESS';

export const GET_CT_CLINICALS_PROBLEMS_BY_ID_SUCCESS = 'ct/GET_CT_CLINICALS_PROBLEMS_BY_ID_SUCCESS';

export const GET_CLINICAL_DOCUMENT_TYPE_LIST_SUCCESS = 'ct/GET_CLINICAL_DOCUMENT_TYPE_LIST_SUCCESS';

export const GET_CLINICAL_DOCUMENT_TYPE_DATA_SUCCESS = 'ct/GET_CLINICAL_DOCUMENT_TYPE_DATA_SUCCESS';

export const GET_REGISTRAR_DEGREE_SUCCESS = 'ct/GET_REGISTRAR_DEGREE_SUCCESS';

export const GET_REGISTRAR_DEGREE_BY_ID_SUCCESS = 'ct/GET_REGISTRAR_DEGREE_BY_ID_SUCCESS';

export const GET_REGISTRAR_GRADE_POINT_SETTING_BY_ID_SUCCESS = 'ct/GET_REGISTRAR_GRADE_POINT_SETTING_BY_ID_SUCCESS';

export const GET_REGISTRAR_GRADE_POINT_SETTING_SUCCESS = 'ct/GET_REGISTRAR_GRADE_POINT_SETTING_SUCCESS';

export const GET_COURSE_ADMIN_SUCCESS = 'ct/GET_COURSE_ADMIN_SUCCESS';

export const GET_ADMIN_COUSE_BY_ID = 'ct/GET_ADMIN_COUSE_BY_ID';

export const GET_ROTATION_SYLLABUS_LIST_SUCCESS = 'ct/GET_ROTATION_SYLLABUS_LIST_SUCCESS';

export const GET_ROTATION_LIST_SUCCESS = 'ct/GET_ROTATION_LIST_SUCCESS';

export const GET_CLINICAL_ROTATION_DATA_SUCCESS = 'ct/GET_CLINICAL_ROTATION_DATA_SUCCESS';

export const GET_CT_SEMESTER_SEARCH_SUCCESS = 'ct/GET_CT_SEMESTER_SEARCH_SUCCESS';

export const GET_SEMESTER_TYPES_SUCCESS = 'ct/GET_SEMESTER_TYPES_SUCCESS';

export const GET_SEMESTER_BY_ID_SUCCESS = 'ct/GET_SEMESTER_BY_ID_SUCCESS';

export const GET_STUDENT_STATUS_SEARCH_SUCCESS = 'ct/GET_STUDENT_STATUS_SEARCH_SUCCESS';

export const GET_STUDENT_STATUS_BY_ID_SUCCESS = 'ct/GET_STUDENT_STATUS_BY_ID_SUCCESS';

export const GET_STUDENT_FOLDER_SEARCH_SUCCESS = 'ct/GET_STUDENT_FOLDER_SEARCH_SUCCESS';

export const GET_STUDENT_FOLDER_BY_ID_SUCCESS = 'ct/GET_STUDENT_FOLDER_BY_ID_SUCCESS';

export const GET_REGISTRATION_DATE_SUCCESS = 'ct/GET_REGISTRATION_DATE_SUCCESS';

export const GET_REGISTRATION_DATE_BY_ID_SUCCESS = 'ct/GET_REGISTRATION_DATE_BY_ID_SUCCESS';

export const GET_REFERRAL_SOURCE_LIST_SUCCESS = 'ct/GET_REFERRAL_SOURCE_LIST_SUCCESS';

export const GET_REFERRAL_SOURCE_DATA_SUCCESS = 'ct/GET_REFERRAL_SOURCE_DATA_SUCCESS';

export const GET_PREMED_HIGHEST_LIST_SUCCESS = 'ct/GET_PREMED_HIGHEST_LIST_SUCCESS';

export const GET_PREMED_DEGREE_DATA_SUCCESS = 'ct/GET_PREMED_DEGREE_DATA_SUCCESS';

export const GET_RESIDENCY_TYPE_LIST_SUCCESS = 'ct/GET_RESIDENCY_TYPE_LIST_SUCCESS';

export const GET_RESIDENCY_TYPE_DATA_SUCCESS = 'ct/GET_RESIDENCY_TYPE_DATA_SUCCESS';

export const GET_RESIDENCY_PROGRAM_LIST_SUCCESS = 'ct/GET_RESIDENCY_PROGRAM_LIST_SUCCESS';

export const GET_RESIDENCY_PROGRAM_DATA_SUCCESS = 'ct/GET_RESIDENCY_PROGRAM_DATA_SUCCESS';

export const GET_COUNTRY_LIST_SUCCESS = 'ct/GET_COUNTRY_LIST_SUCCESS';

export const GET_COUNTRY_BY_ID_SUCCESS = 'ct/GET_COUNTRY_BY_ID_SUCCESS';

export const GET_OUTGOING_BY_ID_SUCCESS = 'ct/GET_OUTGOING_BY_ID_SUCCESS';

export const GET_POSITION_BY_ID_SUCCESS = 'ct/GET_POSITION_BY_ID_SUCCESS';

export const GET_EMP_STATUS_BY_ID_SUCCESS = 'ct/GET_EMP_STATUS_BY_ID_SUCCESS';

export const GET_TODO_BY_ID_SUCCESS = 'ct/GET_TODO_BY_ID_SUCCESS';

export const GET_GRADE_TYPE_LIST_SUCCESS = 'ct/GET_GRADE_TYPE_LIST_SUCCESS';

export const GET_GRADE_TYPE_DATA_SUCCESS = 'ct/GET_GRADE_TYPE_DATA_SUCCESS';

export const GET_ACADEMIC_STANDING_SUCCESS = 'ct/GET_ACADEMIC_STANDING_SUCCESS';

export const GET_ACADEMIC_STANDING_DATA_SUCCESS = 'ct/GET_ACADEMIC_STANDING_DATA_SUCCESS';

export const GET_SUBJECT_SUCCESS = 'ct/GET_SUBJECT_SUCCESS';

export const GET_SUBJECT_DATA_SUCCESS = 'ct/GET_SUBJECT_DATA_SUCCESS';

export const GET_SHELF_DISCIPLINE_SUCCESS = 'ct/GET_SHELF_DISCIPLINE_SUCCESS';

export const GET_SHELF_DISCIPLINE_DATA_SUCCESS = 'ct/GET_SHELF_DISCIPLINE_DATA_SUCCESS';

export const GET_COMP_DISCIPLINE_SUCCESS = 'ct/GET_COMP_DISCIPLINE_SUCCESS';

export const GET_COMP_DISCIPLINE_DATA_SUCCESS = 'ct/GET_COMP_DISCIPLINE_DATA_SUCCESS';

export const GET_COURSE_LIST = 'ct/GET_COURSE_LIST';

export const GET_COURSE_BY_ID = 'ct/GET_COURSE_BY_ID';

export const GET_EMAIL_TEMPLATE_LIST = 'ct/GET_EMAIL_TEMPLATE_LIST';

export const GET_EMAIL_TEMPLATE_BY_ID = 'ct/GET_EMAIL_TEMPLATE_BY_ID';

export const GET_REPLY_TO_LIST = 'ct/GET_REPLY_TO_LIST';

export const GET_REPLY_TO_BY_ID = 'ct/GET_REPLY_TO_BY_ID';

export const GET_NURSING_STUDENT_LIST = 'ct/GET_NURSING_STUDENT_LIST';

export const GET_NURSING_STUDENT_BY_ID = 'ct/GET_NURSING_STUDENT_BY_ID';
export const GET_LOA_REASON_LIST = 'ct/GET_LOA_REASON_LIST';

export const GET_LOA_REASON_BY_ID = 'ct/GET_LOA_REASON_BY_ID';

export const GET_EQUIVALENCE_SCORE_BY_ID = 'ct/GET_EQUIVALENCE_SCORE_BY_ID';

export const GET_EQUIVALENCE_SCORE_LIST = 'ct/GET_EQUIVALENCE_SCORE_LIST';

export const GET_TASK_PRIORITY_SUCCESS = 'ct/GET_TASK_PRIORITY_SUCCESS';

export const GET_TASK_PRIORITY_BY_ID_SUCCESS = 'ct/GET_TASK_PRIORITY_BY_ID_SUCCESS';

export const GET_STAGE_SUCCESS = 'ct/GET_STAGE_SUCCESS';

export const GET_STAGE_BY_ID_SUCCESS = 'ct/GET_STAGE_BY_ID_SUCCESS';

export const GET_DOCUMENT_CATEGORY_LIST = 'ct/GET_DOCUMENT_CATEGORY_LIST';

export const GET_DOCUMENT_CATEGORY_BY_ID = 'ct/GET_DOCUMENT_CATEGORY_BY_ID';

export const GET_PARENT_TYPE_REQUESTED = 'ct/GET_PARENT_TYPE_REQUESTED';

export const GET_PARENT_TYPE_FAILURE = 'ct/GET_PARENT_TYPE_FAILURE';

export const GET_PARENT_TYPE_SUCCESS = 'ct/GET_PARENT_TYPE_SUCCESS';

export const GET_DOCUMENT_TEMPLATE_LIST = 'ct/GET_DOCUMENT_TEMPLATE_LIST';

export const GET_DOCUMENT_TEMPLATE_BY_ID = 'ct/GET_DOCUMENT_TEMPLATE_BY_ID';
export const GET_ACCOUNT_NAME_SUCCESS = 'ct/GET_ACCOUNT_NAME_SUCCESS';

export const GET_ACCOUNT_NAME_BY_ID_SUCCESS = 'ct/GET_ACCOUNT_NAME_BY_ID_SUCCESS';

export const GET_SHIPPING_CHARGES_SUCCESS = 'ct/GET_SHIPPING_CHARGES_SUCCESS';

export const GET_SHIPPING_CHARGES_BY_ID_SUCCESS = 'ct/GET_SHIPPING_CHARGES_BY_ID_SUCCESS';

export const GET_HELP_TEXT_LIST = 'ct/GET_HELP_TEXT_LIST';

export const GET_HELP_TEXT_BY_ID = 'ct/GET_HELP_TEXT_BY_ID';

export const GET_ESL_STUDENT_LIST = 'ct/GET_ESL_STUDENT_LIST';

export const GET_ESL_STUDENT_BY_ID = 'ct/GET_ESL_STUDENT_BY_ID';
export const GET_SCHOOL_LIST_SUCCESS = 'ct/GET_SCHOOL_LIST_SUCCESS';

export const GET_SCHOOL_BY_ID_SUCCESS = 'ct/GET_SCHOOL_BY_ID_SUCCESS';

export const GET_FA_LIST_SUCCESS = 'ct/GET_FA_LIST_SUCCESS';

export const GET_FA_BY_ID_SUCCESS = 'ct/GET_FA_BY_ID_SUCCESS';

export const CONTROL_USER_LIST_SUCCESS = 'ct/CONTROL_USER_LIST_SUCCESS';

const SCHOLARSHIP_LIST_SUCCESS = 'ct/SCHOLARSHIP_LIST_SUCCESS';

const SCHOLARSHIP_BYID_SUCCESS = 'ct/SCHOLARSHIP_BYID_SUCCESS';

const COMMITTE_BYID_SUCCESS = 'ct/COMMITTE_BYID_SUCCESS';

const COMMITTE_LIST_SUCCESS = 'ct/COMMITTE_LIST_SUCCESS';

export const ADD_TOPIC_LIST_SUCCESS = 'ct/ADD_TOPIC_LIST_SUCCESS';

export const GET_TOPIC_LIST_SUCCESS = 'ct/GET_TOPIC_LIST_SUCCESS';

export const GET_TOPIC_BY_ID_SUCCESS = 'ct/GET_TOPIC_BY_ID_SUCCESS';

export const UPDATE_TOPIC_BY_ID_SUCCESS = 'ct/UPDATE_TOPIC_BY_ID_SUCCESS';

export const CLASS_CHAIR_REQUESTED = 'ct/CLASS_CHAIR_REQUESTED';
export const CLASS_CHAIR_SUCCESS = 'ct/CLASS_CHAIR_SUCCESS';
export const CLASS_CHAIR_FAILURE = 'ct/CLASS_CHAIR_FAILURE';

export const CLASS_CHAIR_BY_ID_REQUESTED = 'ct/CLASS_CHAIR_BY_ID_REQUESTED';
export const CLASS_CHAIR_BY_ID_SUCCESS = 'ct/CLASS_CHAIR_BY_ID_SUCCESS';
export const CLASS_CHAIR_BY_ID_FAILURE = 'ct/CLASS_CHAIR_BY_ID_FAILURE';

export const BASIC_SCIENCE_SURVEY_REQUESTED = 'ct/BASIC_SCIENCE_SURVEY_REQUESTED';
export const BASIC_SCIENCE_SURVEY_SUCCESS = 'ct/BASIC_SCIENCE_SURVEY_SUCCESS';
export const BASIC_SCIENCE_SURVEY_FAILURE = 'ct/BASIC_SCIENCE_SURVEY_FAILURE';

export const GET_SURVEY_LIST_SUCCESS = 'ct/GET_SURVEY_LIST_SUCCESS';

export const SURVEY_DROPDOWN_REQUESTED = 'ct/SURVEY_DROPDOWN_REQUESTED';
export const SURVEY_DROPDOWN_SUCCESS = 'ct/SURVEY_DROPDOWN_SUCCESS';
export const SURVEY_DROPDOWN_FAILURE = 'ct/SURVEY_DROPDOWN_FAILURE';

export const GET_APPLICANT_SEM_LIST_REQUESTED = 'ct/GET_APPLICANT_SEM_LIST_REQUESTED';
export const GET_APPLICANT_SEM_LIST_SUCCESS = 'ct/GET_APPLICANT_SEM_LIST_SUCCESS';
export const GET_APPLICANT_SEM_LIST_FAILURE = 'ct/GET_APPLICANT_SEM_LIST_FAILURE';

export const SURVEY_SAVE_SUCCESS = 'ct/SURVEY_SAVE_SUCCESS';

export const GET_SURVEY_LIST_OPTIONS_SUCCESS = 'ct/GET_SURVEY_LIST_OPTIONS_SUCCESS';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  arubaOutgoingMails: null,
  semesterTypeList: null,
  incomingMailLogList: null,
  incomingMailLogById: null,
  arubaIncomingMailList: null,
  arubaIncomingMailLogById: null,
  outGoingMails: null,
  statusList: null,
  positionList: null,
  employeeStatus: null,
  arubaById: null,
  semesterById: null,
  clinicalsOptionsById: null,
  clincialOptionsList: null,
  clincialProblemList: null,
  clinicalsProblemById: null,
  clinicalDocList: null,
  clinicalDocData: null,
  registrarDegreeList: null,
  registrarDegreebyId: {},
  registrarGradePointSettingList: null,
  registrarGradePointSettingbyId: null,
  courseAdminSearchList: null,
  courseByid: null,
  rotationList: null,
  rotationData: null,
  rotationSyllabusList: null,
  semesterSearch: null,
  semesterByIdCtl: null,
  semesterTypes: null,
  studentStatusList: null,
  studentStatusById: null,
  studentFolderList: null,
  studentFolderById: null,
  registrationDateList: null,
  registrationDateById: null,
  referralSourceList: null,
  referralSourceData: null,
  residencyTypeList: null,
  residencyTypeData: null,
  residencyProgramList: null,
  residencyProgramData: null,
  countryList: null,
  countryItem: null,
  outgoingById: null,
  positionById: null,
  employeeById: null,
  todoById: null,
  gradeTypeList: null,
  gradeTypeData: null,
  academicStandingList: null,
  academicStandingData: null,
  subjectList: null,
  subjectData: null,
  shelfDisciplineList: null,
  shelfDisciplineData: null,
  compDisciplineList: null,
  compDisciplineData: null,
  classList: null,
  courseDataById: null,
  emailTemplateList: null,
  emailTemplateById: null,
  replyToList: null,
  replyToById: null,
  nursingStudentList: null,
  nursingStudentById: null,
  loaReasonList: null,
  loaReasonById: null,
  equivalenceScoreId: null,
  equivalenceScoreList: null,
  taskPriorityList: null,
  taskPriorityById: null,
  stageList: null,
  stageById: null,
  documentCategoryList: null,
  documentCategoryById: null,
  parentTypeLoading: false,
  parentTypeList: null,
  documentTemplateList: null,
  documentTemplateById: null,
  accountNameList: null,
  accountNameById: null,
  shippingChargesList: null,
  shippingChargesById: null,
  helpTextList: null,
  helpTextById: null,
  eslStudentList: null,
  eslStudentById: null,
  schoolList: null,
  schoolById: null,
  faList: null,
  faById: null,
  usersList: null,
  scholarshipList: null,
  scholarshipByIdDetails: null,
  committeById: null,
  committeList: null,
  committeMettingById: null,
  committeMettingList: null,
  topicList: null,
  topicById: null,
  classChairList: null,
  classChairById: null,
  basicScienceSurvey: null,
  surveyList: null,
  surveyDropDownLoading: false,
  surveyDropDownList: null,
  surveyListOption: null,
  getApplicantCTSemList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case CONTROL_USER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      usersList: action.result
    };
  }
  case GET_CT_SEMESTER_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterTypeList: action.result && action.result.data
    };
  }
  case GET_ARUBA_OUTGOING_MAIL_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      arubaOutgoingMails: action.result
    };
  }
  case GET_OUTGOING_MAIL_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      outGoingMails: action.result
    };
  }
  case GET_TODO_STATUS_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      statusList: action.result
    };
  }
  case GET_POSITION_STATUS_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      positionList: action.result
    };
  }
  case GET_EMPLOYEE_STATUS_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      employeeStatus: action.result
    };
  }
  case GET_ARUBA_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      arubaById: action.result
    };
  }
  case GET_CLINICAL_DOCUMENT_TYPE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalDocList: action.result && action.result.data
    };
  }
  case GET_CLINICAL_DOCUMENT_TYPE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalDocData: action.result,
    };
  }
  case GET_CT_SEMESTER_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterById: action.result && action.result.data
    };
  }
  case GET_CT_CLINICALS_OPTIONS_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalsOptionsById: action.result
    };
  }
  case GET_CT_CLINICALS_OPTIONS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clincialOptionsList: action.result && action.result.data
    };
  }
  case GET_CT_CLINICALS_PROBLEMS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clincialProblemList: action.result && action.result.data
    };
  }
  case GET_CT_CLINICALS_PROBLEMS_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalsProblemById: action.result
    };
  }
  case GET_REGISTRAR_DEGREE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      registrarDegreeList: action.result && action.result.data
    };
  }
  case GET_REGISTRAR_DEGREE_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      registrarDegreebyId: action.result && action.result.data
    };
  }
  case GET_REGISTRAR_GRADE_POINT_SETTING_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      registrarGradePointSettingList: action.result && action.result.data
    };
  }
  case GET_REGISTRAR_GRADE_POINT_SETTING_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      registrarGradePointSettingbyId: action.result && action.result.data
    };
  }
  case GET_COURSE_ADMIN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      courseAdminSearchList: action.result && action.result.data
    };
  }
  case GET_ADMIN_COUSE_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      courseByid: action.result && action.result.data
    };
  }
  case GET_COUNTRY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      countryList: action && action.result
    };
  }
  case GET_COUNTRY_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      countryItem: action && action.result
    };
  }
  case GET_CLINICAL_ROTATION_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationData: action.result,
    };
  }
  case GET_ROTATION_SYLLABUS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationSyllabusList: action.result && action.result.data
    };
  }
  case GET_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationList: action.result && action.result.data
    };
  }
  case GET_OUTGOING_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      outgoingById: action.result
    };
  }
  case GET_POSITION_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      positionById: action.result
    };
  }
  case GET_EMP_STATUS_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      employeeById: action.result
    };
  }
  case GET_TODO_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      todoById: action.result
    };
  }
  case GET_CT_INCOMING_MAIL_LOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      incomingMailLogList: action.result && action.result.data
    };
  }
  case GET_CT_ARUBA_INCOMING_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      arubaIncomingMailList: action.result && action.result.data
    };
  }
  case GET_CT_INCOMING_MAIL_LOG_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      incomingMailLogById: action.result
    };
  }
  case GET_CT_ARUBA_INCOMING_LOG_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      arubaIncomingMailLogById: action.result
    };
  }
  case GET_REFERRAL_SOURCE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      referralSourceList: action.result && action.result.data
    };
  }
  case GET_REFERRAL_SOURCE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      referralSourceData: action.result,
    };
  }
  case GET_PREMED_HIGHEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      premedDegreeList: action.result && action.result.data
    };
  }
  case GET_PREMED_DEGREE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      premedDegreeData: action.result,
    };
  }
  case GET_RESIDENCY_TYPE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      residencyTypeList: action.result && action.result.data
    };
  }
  case GET_RESIDENCY_TYPE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      residencyTypeData: action.result && action.result.data,
    };
  }
  case GET_RESIDENCY_PROGRAM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      residencyProgramList: action.result && action.result.data
    };
  }
  case GET_RESIDENCY_PROGRAM_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      residencyProgramData: action.result && action.result.data,
    };
  }
  case GET_GRADE_TYPE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      gradeTypeList: action.result && action.result.data
    };
  }
  case GET_GRADE_TYPE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      gradeTypeData: action.result && action.result.data,
    };
  }
  case GET_COURSE_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      classList: action.result && action.result.data,
    };
  }
  case GET_COURSE_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      courseDataById: action.result && action.result.data,
    };
  }
  case GET_LOA_REASON_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      loaReasonList: action.result && action.result.data
    };
  }
  case GET_LOA_REASON_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      loaReasonById: action.result && action.result.data
    };
  }
  case GET_EQUIVALENCE_SCORE_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      equivalenceScoreId: action.result && action.result.data
    };
  }
  case GET_EQUIVALENCE_SCORE_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      equivalenceScoreList: action.result && action.result.data
    };
  }

  case GET_CT_SEMESTER_SEARCH_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterSearch: action.result && action.result.data
    };
  }
  case GET_SEMESTER_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterByidCtl: action.result && action.result.data
    };
  }
  case GET_SEMESTER_TYPES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterTypes: action.result && action.result.data
    };
  }
  case GET_STUDENT_STATUS_SEARCH_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentStatusList: action.result && action.result.data
    };
  }
  case GET_STUDENT_STATUS_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentStatusById: action.result && action.result.data
    };
  }
  case GET_STUDENT_FOLDER_SEARCH_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentFolderList: action.result && action.result.data
    };
  }
  case GET_STUDENT_FOLDER_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentFolderById: action.result && action.result.data
    };
  }
  case GET_REGISTRATION_DATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      registrationDateList: action.result && action.result.data
    };
  }
  case GET_REGISTRATION_DATE_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      registrationDateById: action.result && action.result.data
    };
  }
  case GET_TASK_PRIORITY_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskPriorityList: action.result && action.result.data
    };
  }
  case GET_TASK_PRIORITY_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskPriorityById: action.result && action.result.data
    };
  }
  case GET_STAGE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      stageList: action.result && action.result.data
    };
  }
  case GET_STAGE_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      stageById: action.result && action.result.data
    };
  }
  case GET_EMAIL_TEMPLATE_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      emailTemplateList: action.result && action.result.data
    };
  }
  case GET_EMAIL_TEMPLATE_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      emailTemplateById: action.result && action.result.data
    };
  }
  case GET_REPLY_TO_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      replyToList: action.result && action.result.data
    };
  }
  case GET_REPLY_TO_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      replyToById: action.result && action.result.data
    };
  }
  case GET_NURSING_STUDENT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      nursingStudentList: action.result && action.result.data
    };
  }
  case GET_NURSING_STUDENT_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      nursingStudentById: action.result && action.result.data
    };
  }
  case GET_DOCUMENT_CATEGORY_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      documentCategoryList: action.result && action.result.data
    };
  }
  case GET_DOCUMENT_CATEGORY_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      documentCategoryById: action.result && action.result.data
    };
  }
  case GET_PARENT_TYPE_REQUESTED: {
    return {
      ...state,
      parentTypeLoading: true,
      loaded: false,
    };
  }
  case GET_PARENT_TYPE_FAILURE: {
    return {
      ...state,
      parentTypeLoading: false,
      error: true,
      loaded: true,
    };
  }
  case GET_PARENT_TYPE_SUCCESS: {
    return {
      ...state,
      parentTypeLoading: false,
      loaded: true,
      error: false,
      parentTypeList: action.result
    };
  }
  case GET_DOCUMENT_TEMPLATE_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      documentTemplateList: action.result && action.result.data
    };
  }
  case GET_DOCUMENT_TEMPLATE_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      documentTemplateById: action.result && action.result.data
    };
  }
  case GET_ACCOUNT_NAME_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      accountNameById: action.result && action.result.data
    };
  }
  case GET_ACCOUNT_NAME_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      accountNameList: action.result && action.result.data
    };
  }
  case GET_SHIPPING_CHARGES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      shippingChargesList: action.result && action.result.data
    };
  }
  case GET_SHIPPING_CHARGES_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      shippingChargesById: action.result && action.result.data
    };
  }
  case GET_HELP_TEXT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      helpTextList: action.result
    };
  }
  case GET_HELP_TEXT_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      helpTextById: action.result
    };
  }
  case GET_ACADEMIC_STANDING_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      academicStandingList: action.result && action.result.data
    };
  }
  case GET_SUBJECT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      subjectList: action.result && action.result.data
    };
  }
  case GET_ACADEMIC_STANDING_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      academicStandingData: action.result && action.result.data
    };
  }
  case GET_SUBJECT_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      subjectData: action.result && action.result.data
    };
  }
  case GET_COMP_DISCIPLINE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      compDisciplineList: action.result && action.result.data
    };
  }
  case GET_COMP_DISCIPLINE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      compDisciplineData: action.result && action.result.data
    };
  }
  case GET_SHELF_DISCIPLINE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      shelfDisciplineList: action.result && action.result.data
    };
  }
  case GET_SHELF_DISCIPLINE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      shelfDisciplineData: action.result && action.result.data
    };
  }
  case GET_ESL_STUDENT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      eslStudentList: action.result && action.result.data
    };
  }
  case GET_ESL_STUDENT_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      eslStudentById: action.result && action.result.data
    };
  }
  case GET_SCHOOL_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      schoolList: action.result
    };
  }
  case GET_SCHOOL_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      schoolById: action.result
    };
  }
  case GET_FA_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      faList: action.result
    };
  }
  case GET_FA_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      faById: action.result
    };
  }
  case SCHOLARSHIP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      scholarshipList: action.result && action.result.data
    };
  }
  case SCHOLARSHIP_BYID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      scholarshipByIdDetails: action.result && action.result.data
    };
  }

  case COMMITTE_BYID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      committeById: action.result && action.result.data
    };
  }

  case COMMITTE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      committeList: action.result && action.result.data
    };
  }

  case GET_TOPIC_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      laoded: true,
      error: false,
      topicList: action && action.result
    };
  }

  case GET_TOPIC_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      laoded: true,
      error: false,
      topicById: action && action.result
    };
  }
  case ADD_TOPIC_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      laoded: true,
      error: false
    };
  }
  case UPDATE_TOPIC_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      laoded: true,
      error: false
    };
  }
  case CLASS_CHAIR_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLASS_CHAIR_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      classChairById: action && action.result && action.result?.data
    };
  }
  case CLASS_CHAIR_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      classChairById: null
    };
  }
  case CLASS_CHAIR_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLASS_CHAIR_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      classChairList: action && action.result && action.result?.data
    };
  }
  case CLASS_CHAIR_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      classChairList: null
    };
  }
  case BASIC_SCIENCE_SURVEY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case BASIC_SCIENCE_SURVEY_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      basicScienceSurvey: action && action.result && action.result?.data
    };
  }
  case BASIC_SCIENCE_SURVEY_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      classChairList: null
    };
  }
  case GET_SURVEY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      surveyList: action?.result?.data
    };
  }
  case SURVEY_DROPDOWN_REQUESTED: {
    return {
      ...state,
      surveyDropDownLoading: true,
      loaded: false,
      error: false
    };
  }
  case SURVEY_DROPDOWN_SUCCESS: {
    return {
      ...state,
      surveyDropDownLoading: false,
      loaded: true,
      error: false,
      surveyDropDownList: action?.result?.data
    };
  }
  case SURVEY_DROPDOWN_FAILURE: {
    return {
      ...state,
      surveyDropDownLoading: false,
      loaded: true,
      error: true,
    };
  }
  case SURVEY_SAVE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_SURVEY_LIST_OPTIONS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      surveyListOption: action && action.result && action.result?.data
    };
  }
  case GET_APPLICANT_SEM_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_APPLICANT_SEM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getApplicantCTSemList: action.result?.data
    };
  }
  case GET_APPLICANT_SEM_LIST_FAILURE: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
      getApplicantCTSemList: action.result?.data
    };
  }

  default: {
    return {
      ...state
    };
  }
  }
};

export const getArubaOutgoingMailList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ARUBA_OUTGOING_MAIL_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('arubaOutgoingMailList', { params })
  };
};
export const addAdminCtTable = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('adminCtTable', { data })
  };
};

export const getAdminCtTable = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_SEMESTER_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('adminCtTableList', { params })
  };
};
export const getOutgoingMails = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_OUTGOING_MAIL_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getOutgoingMails', { params })
  };
};
export const getStatusList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TODO_STATUS_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getStatusList', { params })
  };
};
export const getPositionList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_POSITION_STATUS_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getPositionList', { params })
  };
};

export const getEmployeeStatus = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_EMPLOYEE_STATUS_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getEmployeeStatus', { params })
  };
};
export const postArubaOutgoingMail = (params, data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postArubaOutgoingMail', { params, data })
  };
};
export const postOutgoingMails = (params, data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postOutgoingMails', { params, data })
  };
};
export const postStatusList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postStatusList', { data })
  };
};
export const postPositionList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postPositionList', { data })
  };
};
export const postEmployeeStatus = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postEmployeeStatus', { data })
  };
};

export const getArubaOutgoingMailById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ARUBA_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getArubaOutgoingMailById/${id}`)
  };
};

export const getOutgoingMailById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_OUTGOING_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getOutgoingMailById/${id}`)
  };
};
export const getPositionsById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_POSITION_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getPositionsById/${id}`)
  };
};
export const getTodoStatusById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TODO_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getTodoStatusById/${id}`)
  };
};
export const getEmployeeStatusById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_EMP_STATUS_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getEmployeeStatusById/${id}`)
  };
};

export const getIncomingMailLogList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_INCOMING_MAIL_LOG_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('hrIncomingMailLog', { params })
  };
};

export const getArubaIncomingMailLogList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_ARUBA_INCOMING_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('hrArubaIncoming', { params })
  };
};
export const updateAdminCtTable = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('updateSemesertype', { data })
  };
};

export const getAdminCtTableSemesterTypeById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_SEMESTER_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`semestertypeAdminbyid/${id}`)
  };
};

export const addClinicalsOptionsCtTable = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('clinicalsOptionsAdd', { data })
  };
};

export const getClinicalsOptionsTable = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_CLINICALS_OPTIONS_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('clinicalsOptionsTableList', { params })
  };
};

export const getClinicalsOptionsTableById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_CLINICALS_OPTIONS_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`clinicalsOptionsTableList/${id}`)
  };
};

export const addClinicalsProblemsCtTable = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('adminClinicalProblemAdd', { data })
  };
};

export const getClinicalsProblemsTable = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_CLINICALS_PROBLEMS_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('adminClinicalProblemSearch', { params })
  };
};

export const getClinicalsProblemTableById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_CLINICALS_PROBLEMS_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`adminClinicalProblemSearch/${id}`)
  };
};
export const addClinicalDocumentType = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('clinicalDocumentAdd', { data })
  };
};

export const getClinicalDocumentList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CLINICAL_DOCUMENT_TYPE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getClinicaDocumentList', { params })
  };
};

export const getClinicalDocumentbyId = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CLINICAL_DOCUMENT_TYPE_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getClinicalDocumentbyId/${id}`)
  };
};

export const addregistrarDegree = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('registrarDegreeAdd', { data })
  };
};

export const searchRegistrarDegree = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REGISTRAR_DEGREE_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('registrarDegreeSearch', { params })
  };
};

export const getRegistrarById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REGISTRAR_DEGREE_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`registrarDegreeById/${id}`)
  };
};

export const addregistrarGradePointSetting = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('registrarGradePointSettingAdd', { data })
  };
};

export const searchRegistrarGradePointSetting = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REGISTRAR_GRADE_POINT_SETTING_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('registrarGradePointSettingSearch', { params })
  };
};

export const getRegistrarGradePointSettingById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REGISTRAR_GRADE_POINT_SETTING_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`registrarGradePointSettingById/${id}`)
  };
};

export const getAdminCourselist = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COURSE_ADMIN_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('serachCourseAdmin', { params })
  };
};

export const addCourseAdmin = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('serachCourseAdmin', { data })
  };
};

export const updateCourseAdmin = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('serachCourseAdmin', { data })
  };
};

export const addCCourseAdmin = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('clinicalCourseAdmin', { data })
  };
};

export const updateCCourseAdmin = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('clinicalCourseAdmin', { data })
  };
};
export const getCourseAdminById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ADMIN_COUSE_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`serachCourseAdmin/${id}`)
  };
};

export const addRotation = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('rotationAdd', { data })
  };
};

export const getRotationList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ROTATION_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getRotationsList', { params })
  };
};

export const getRotationById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CLINICAL_ROTATION_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getRotationById/${id}`)
  };
};

export const getRotationSyllabusList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ROTATION_SYLLABUS_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getRotationSyllabusList', { params })
  };
};

export const addRotationSyllabus = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addRotationSyllabus', { data, isMultiPart: true })
  };
};

export const getReferralSourceList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REFERRAL_SOURCE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getReferralSourceList', { params })
  };
};

export const getReferralSourcebyId = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REFERRAL_SOURCE_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getReferralSourceData/${id}`)
  };
};

export const addReferralSource = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addReferralSource', { data })
  };
};

export const getPremedHighestDegreeList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PREMED_HIGHEST_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('premedList', { params })
  };
};

export const getPremedDegreebyId = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PREMED_DEGREE_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`premedListById/${id}`)
  };
};

export const addPremedDegree = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addPremedDegree', { data })
  };
};

export const getResidencyTypeList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RESIDENCY_TYPE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getResidencyTypeList', { params })
  };
};

export const getResidencyTypeById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RESIDENCY_TYPE_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getResidencyTypeData/${id}`)
  };
};

export const addResidencyType = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addResidencyType', { data })
  };
};

export const getResidencySpecialityList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RESIDENCY_PROGRAM_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getResidencySpecialityList', { params })
  };
};

export const getResidencySpecialityDataById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RESIDENCY_PROGRAM_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getResidencySpecialityData/${id}`)
  };
};

export const addResidencyProgram = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addResidencyProgram', { data })
  };
};

export const postIncomingMailLog = (params, data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('hrIncomingMailLogCreate', { params, data })
  };
};

export const getIncomingMailLogById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_INCOMING_MAIL_LOG_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`hrGetIncomingMailLogById/${id}`)
  };
};

export const postArubaIncomingMailLog = (params, data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('hrArubaIncomingLogCreate', { params, data })
  };
};

export const getArubaIncomingMailLogById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_ARUBA_INCOMING_LOG_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`hrArubaIncomingLogById/${id}`)
  };
};
export const getCountryList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COUNTRY_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getCountryList', { params })
  };
};
export const getCountryById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COUNTRY_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getCountryList/${id}`)
  };
};

export const postCountryList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getCountryList', { data })
  };
};
export const updateCountryList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('getCountryList', { data })
  };
};

export const getGradeTypeList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_GRADE_TYPE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('gradePointTypeSearch', { params })
  };
};

export const getGradePointTypeById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_GRADE_TYPE_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getGradePointTypeById/${id}`)
  };
};

export const addGradePointType = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addGradePointType', { data })
  };
};

export const getSemesterCtl = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CT_SEMESTER_SEARCH_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('semesterCtl', { params })
  };
};

export const getSemesterByIdCtl = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SEMESTER_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`semesterCtl/${id}`)
  };
};

export const addSemesterCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('semesterCtl', { data })
  };
};

export const updateSemesterCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('semesterCtl', { data })
  };
};

export const getSemesterTypes = () => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SEMESTER_TYPES_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSemesterTypes')
  };
};

export const getStudentStatusList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_STATUS_SEARCH_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getstudentStatus', { params })
  };
};

export const getStudentStatusByIdCtl = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_STATUS_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getstudentStatus/${id}`)
  };
};

export const addStudentStatusCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getstudentStatus', { data })
  };
};

export const updateStudentStatusCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('getstudentStatus', { data })
  };
};

export const getStudentFolderList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_FOLDER_SEARCH_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getStudentFolderSearch', { params })
  };
};

export const getStudentFolderByIdCtl = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_FOLDER_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getStudentFolderSearch/${id}`)
  };
};

export const addStudentFolderCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getStudentFolderSearch', { data })
  };
};

export const updateStudentFolderCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('getStudentFolderSearch', { data })
  };
};

export const getRegistrationDateList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REGISTRATION_DATE_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getRegistrationDateSearch', { params })
  };
};

export const getRegistrationDateByIdCtl = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REGISTRATION_DATE_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getRegistrationDateSearch/${id}`)
  };
};

export const addRegistrationDateCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getRegistrationDateSearch', { data })
  };
};

export const updateRegistrationDateCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('getRegistrationDateSearch', { data })
  };
};
export const getAcademicStandingList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ACADEMIC_STANDING_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('academicStandingSearch', { params })
  };
};

export const addAcademicStanding = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addAcademicStanding', { data })
  };
};

export const getAcademicStandingById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ACADEMIC_STANDING_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getAcademicStandingData/${id}`)
  };
};

export const getSubjectsList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SUBJECT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('subjectSearch', { params })
  };
};

export const getSubjectById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SUBJECT_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getSubjectData/${id}`)
  };
};

export const addSubject = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addSubject', { data })
  };
};

export const getShelfDisciplineList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SHELF_DISCIPLINE_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('shelfDisciplineSearch', { params })
  };
};

export const addShelfDiscipline = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addShelfDiscipline', { data })
  };
};

export const getShelfDisciplineById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SHELF_DISCIPLINE_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getShelfDisciplineById/${id}`)
  };
};

export const getCompDisciplineSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COMP_DISCIPLINE_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getCompDisciplineSearch', { params })
  };
};

export const getCompDisciplineById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COMP_DISCIPLINE_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getCompDisciplineData/${id}`)
  };
};

export const addCompDiscipline = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addCompDiscipline', { data })
  };
};
export const getRegistrarClassById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COURSE_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`registrarClassId/${id}`)
  };
};

export const getRegistrarClassList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COURSE_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('registrarClassSearch', { params })
  };
};

export const saveRegistrarClass = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('registrarClassSave', { data })
  };
};

export const getEmailTemplateList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_EMAIL_TEMPLATE_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getEmailTemplateList', { params })
  };
};

export const postEmailTemplate = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addEmailTemplate', { data })
  };
};

export const getEmailTemplateById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_EMAIL_TEMPLATE_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getEmailTemplateById/${id}`)
  };
};

export const updateEmailTemplate = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('editEmailTemplateById', { data })
  };
};

export const getReplyToList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REPLY_TO_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getReplyToList', { params })
  };
};

export const postReplyTo = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addReplyTo', { data })
  };
};

export const getReplyToById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REPLY_TO_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getReplyToById/${id}`)
  };
};

export const updateReplyTo = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('editReplyToById', { data })
  };
};

export const getNursingStudentList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NURSING_STUDENT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getNursingStudentList', { params })
  };
};

export const postNursingStudent = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addNursingStudentList', { data })
  };
};

export const getNursingStudentById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NURSING_STUDENT_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getNursingStudentById/${id}`)
  };
};

export const updateNursingStudent = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('editNursingStudentList', { data })
  };
};

export const getRegistrarLoaReasonById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_LOA_REASON_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`loaReasonById/${id}`)
  };
};

export const getRegistrarLoaReasonList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_LOA_REASON_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getLoaReasonList', { params })
  };
};

export const saveRegistrarLoaReason = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveLoaReason', { data })
  };
};

export const getRegistrarEuqivalenceById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_EQUIVALENCE_SCORE_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`equivalenceResgitrarById/${id}`)
  };
};

export const getRegistrarEuqivalenceList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_EQUIVALENCE_SCORE_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('equivalenceResgitrarSearch', { params })
  };
};

export const saveRegistrarEuqivalence = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('equivalenceResgitrarAdd', { data })
  };
};
export const getTaskPriorityList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TASK_PRIORITY_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getTaskPrioritySearch', { params })
  };
};

export const getTaskPriorityByIdCtl = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TASK_PRIORITY_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getTaskPrioritySearch/${id}`)
  };
};

export const addTaskPriorityCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getTaskPrioritySearch', { data })
  };
};

export const updateTaskPriorityCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('getTaskPrioritySearch', { data })
  };
};

export const getStageList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STAGE_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getStageSearch', { params })
  };
};

export const getStageByIdCtl = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STAGE_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getStageSearch/${id}`)
  };
};

export const addStageCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getStageSearch', { data })
  };
};

export const updateStageCtl = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('getStageSearch', { data })
  };
};

export const getDocumentCategoryList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_DOCUMENT_CATEGORY_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getDocumentCategoryList', { params })
  };
};

export const postDocumentCategory = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postDocumentCategory', { data })
  };
};

export const getDocumentCategoryById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_DOCUMENT_CATEGORY_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getDocumentCategoryById/${id}`)
  };
};

export const getParentTypeList = () => {
  return {
    types: [GET_PARENT_TYPE_REQUESTED, GET_PARENT_TYPE_SUCCESS, GET_PARENT_TYPE_FAILURE],
    promise: (client) => client.get('getDocumentCategoryParentType')
  };
};

export const getDocumentTemplateList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_DOCUMENT_TEMPLATE_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getDocumentTemplateList', { params })
  };
};

export const postDocumentTemplate = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postDocumentTemplate', { data, isMultiPart: true })
  };
};

export const getDocumentTemplateById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_DOCUMENT_TEMPLATE_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getDocumentTemplateById/${id}`)
  };
};
export const getAccuontNameList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ACCOUNT_NAME_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('accountSearch', { params })
  };
};

export const getAccountNamrById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ACCOUNT_NAME_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`accountById/${id}`)
  };
};

export const addAccountName = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveAccount', { data })
  };
};

export const getShippingChargesList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SHIPPING_CHARGES_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('shippingChargesSearch', { params })
  };
};

export const getShippingChargesById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SHIPPING_CHARGES_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`shippingChargesById/${id}`)
  };
};

export const addShippingCharges = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('shippingCharges', { data })
  };
};
export const getHelpText = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_HELP_TEXT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getHelpText', { params })
  };
};
export const addHelpText = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getHelpText', { data })
  };
};
export const getHelpTextById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_HELP_TEXT_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getHelpTextById/${id}`)
  };
};

export const updateHelpText = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('updateHelpText', { data })
  };
};

export const UpdateShippingCharges = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('shippingChargesUpdate', { data })
  };
};

export const getEslStudentList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ESL_STUDENT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getEslStudentList', { params })
  };
};

export const postEslStudent = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getEslStudentList', { data })
  };
};

export const getEslStudentById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ESL_STUDENT_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getEslStudentList/${id}`)
  };
};

export const updateEslStudent = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('getEslStudentList', { data })
  };
};
export const getSchoolList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SCHOOL_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSchoolList', { params })
  };
};
export const getSchoolById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SCHOOL_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getSchoolById/${id}`)
  };
};
export const addSchool = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addSchool', { data })
  };
};
export const getFinancialAidList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_FA_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getFinancialAidList', { params })
  };
};
export const getFinancialAidById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_FA_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getFinancialAidById/${id}`)
  };
};
export const addFinancialAid = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addFinancialAid', { data })
  };
};

export const getUsers = () => {
  return {
    types: [ADD_ACTION_REQUESTED, CONTROL_USER_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getUsers')
  };
};

export const uploadClinicalEvaluation = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('clinicalEvaluationUpload', { data, isMultiPart: true })
  };
};
export const searchScholarship = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, SCHOLARSHIP_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('searchScholarship', { params })
  };
};

export const getScholarship = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, SCHOLARSHIP_BYID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getScholarship/${id}`)
  };
};

export const addOrUpdateScholarship = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addOrUpdateScholarship', { data })
  };
};

export const addCommitte = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addCommitte', { data })
  };
};
export const getCommitte = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, COMMITTE_BYID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getCommitte/${id}`)
  };
};
export const searchCommitte = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, COMMITTE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('searchCommitte', { params })
  };
};

export const getTopicList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TOPIC_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getTopicList', { params })
  };
};
export const getTopicById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TOPIC_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getTopicById/${id}`)
  };
};
export const addTopic = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_TOPIC_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addCTTopic', { params })
  };
};
export const updateTopicById = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, UPDATE_TOPIC_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('updateTopicById', { params })
  };
};

export const saveClassChair = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('classChairSave', { data })
  };
};

export const getClassChairById = (id) => {
  return {
    types: [CLASS_CHAIR_BY_ID_REQUESTED, CLASS_CHAIR_BY_ID_SUCCESS, CLASS_CHAIR_BY_ID_FAILURE],
    promise: (client) => client.get(`classChairById/${id}`)
  };
};

export const getClassChairList = (params) => {
  return {
    types: [CLASS_CHAIR_REQUESTED, CLASS_CHAIR_SUCCESS, CLASS_CHAIR_FAILURE],
    promise: (client) => client.get('classChairList', { params })
  };
};

export const getBasicScienceSurveyList = (params) => {
  return {
    types: [BASIC_SCIENCE_SURVEY_REQUESTED, BASIC_SCIENCE_SURVEY_SUCCESS, BASIC_SCIENCE_SURVEY_FAILURE],
    promise: (client) => client.get('basicScienceSurvey', { params })
  };
};

export const saveBasicScienceSurvey = (data, params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('basicScienceSurveySave', { data, params })
  };
};

export const getSurveyList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SURVEY_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSurveyList', { params })
  };
};

export const getSurveyDropDownList = () => {
  return {
    types: [SURVEY_DROPDOWN_REQUESTED, SURVEY_DROPDOWN_SUCCESS, SURVEY_DROPDOWN_FAILURE],
    promise: (client) => client.get('getSurveyListDropDown')
  };
};

export const addUpdateSurvey = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, SURVEY_SAVE_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateSurvey', { data })
  };
};

export const surveySendMails = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('surveySendMail', { data })
  };
};

export const getSurveyListOptions = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SURVEY_LIST_OPTIONS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSurveyListOptions', { data })
  };
};

export const getApplicantSemester = (params) => {
  return {
    types: [GET_APPLICANT_SEM_LIST_REQUESTED, GET_APPLICANT_SEM_LIST_SUCCESS, GET_APPLICANT_SEM_LIST_FAILURE],
    promise: (client) => client.get('getApplicantSemester', { params })
  };
};

export const addApplicantSemYear = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addApplicantSemYear', { params })
  };
};

export const deleteApplicantSemYear = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('deleteApplicantSemYear', { params })
  };
};
