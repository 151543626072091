import React from 'react';
import whatsApp from '../../../assets/img/svg/whatsAppLogo.svg';
import { withRouter } from 'react-router-dom';
import { getAuthUserData } from '../../../utility/helpers/helperFunction';

const FloatingIcon = () => {
  const userTypeLink = getAuthUserData().userType === 'Student' ? 'https://wa.me/15168589826' : 'https://cmd.tyntec.com/login/signin';

  return (
    <a
      className="position-fixed blockOverlay rounded-circle"
      target="_blank"
      rel="noopener noreferrer"
      href={userTypeLink}
      style={{
        width: '60px',
        height: '60px',
        bottom: '35px',
        right: '70px',
        boxShadow: '0px 0px 11px rgb(0 0 0 / 50%)'
      }}
    >
      <img className="img-fluid" src={whatsApp} alt="WhatsAppLogo" />
    </a>
  );
};

export default withRouter(FloatingIcon);
