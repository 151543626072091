export const ADD_ACTION_REQUESTED = 'reports/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'reports/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'reports/ADD_ACTION_FAILURE';

export const STUDENT_LEAVE_REPORT_SUCCESS = 'reports/STUDENT_LEAVE_REPORT_SUCCESS';

export const STUDENT_REPORT_SUCCESS = 'reports/STUDENT_REPORT_SUCCESS';

export const STUDENT_ROTATION_COUNT_SUCCESS = 'reports/STUDENT_ROTATION_COUNT_SUCCESS';

export const STUDENT_ROTATION_PRICE_SUCCESS = 'reports/STUDENT_ROTATION_PRICE_SUCCESS';

export const STUDENT_BIRTHDAY_SUCCESS = 'reports/STUDENT_BIRTHDAY_SUCCESS';

export const STUDENT_CLINICAL_LOG_REQUESTED = 'reports/STUDENT_CLINICAL_LOG_REQUESTED';
export const STUDENT_CLINICAL_LOG_SUCCESS = 'reports/STUDENT_CLINICAL_LOG_SUCCESS';
export const STUDENT_CLINICAL_LOG_FAILURE = 'reports/STUDENT_CLINICAL_LOG_FAILURE';

export const TRANSCRIPT_REPORT_SUCCESS = 'reports/TRANSCRIPT_REPORT_SUCCESS';

export const ATTENDANCE_REPORT_SUCCESS = 'reports/ATTENDANCE_REPORT_SUCCESS';

export const STUDENT_ROTATION_DETAILS_SUCCESS = 'reports/STUDENT_ROTATION_DETAILS_SUCCESS';

export const STUDENT_PRICE_DETAILS_SUCCESS = 'reports/STUDENT_PRICE_DETAILS_SUCCESS';

export const GET_GRADE_REPORT_LIST = 'reports/GET_GRADE_REPORT_LIST';

export const GET_SEMESTER_DATES_LIST_BY_ID = 'reports/GET_SEMESTER_DATES_LIST_BY_ID';

export const GET_PHOTO_REPORT_LIST = 'reports/GET_PHOTO_REPORT_LIST';

export const GET_COMPLAINT_REPORT_LIST = 'reports/GET_COMPLAINT_REPORT_LIST';

export const GET_PAYMENT_REPORT_LIST = 'reports/GET_PAYMENT_REPORT_LIST';

export const APPLICANT_POOL_REPORT_LIST = 'reports/APPLICANT_POOL_REPORT_LIST';

export const GET_STUDENT_WITHDRAWN_REPORT_REQUESTED = 'reports/GET_STUDENT_WITHDRAWN_REPORT_REQUESTED';
export const GET_STUDENT_WITHDRAWN_REPORT_SUCCESS = 'reports/GET_STUDENT_WITHDRAWN_REPORT_SUCCESS';
export const GET_STUDENT_WITHDRAWN_REPORT_FAILURE = 'reports/GET_STUDENT_WITHDRAWN_REPORT_FAILURE';

export const GET_STUDENT_LEDGER_REPORT_SUCCESS = 'reports/GET_STUDENT_LEDGER_REPORT_SUCCESS';

export const GET_STUDENT_USMLE_REPORT_SUCCESS = 'reports/GET_STUDENT_USMLE_REPORT_SUCCESS';

export const GET_STUDENT_USMLE_DETAILED_REPORT_SUCCESS = 'reports/GET_STUDENT_USMLE_DETAILED_REPORT_SUCCESS';

export const GET_CLINICAL_MISSING_GRADE_REPORT_SUCCESS = 'reports/GET_CLINICAL_MISSING_GRADE_REPORT_SUCCESS';

export const GET_STAGE_GPA_REPORT_LIST = 'reports/GET_STAGE_GPA_REPORT_LIST';

export const GET_INVOICE_PROMOTIONAL_REPORT_SUCCESS = 'reports/GET_INVOICE_PROMOTIONAL_REPORT_SUCCESS';
export const GET_BILLED_UNBILLED_REPORT_SUCCESS = 'reports/GET_BILLED_UNBILLED_REPORT_SUCCESS';
export const GET_SEMESTER_INVOICE_REPORT_SUCCESS = 'reports/GET_SEMESTER_INVOICE_REPORT_SUCCESS';
export const GET_PENDING_INVOICE_REPORT_SUCCESS = 'reports/GET_PENDING_INVOICE_REPORT_SUCCESS';

export const GET_STUDENT_INVOICE_PAYMENT_LIST = 'reports/GET_STUDENT_INVOICE_PAYMENT_LIST';

export const GET_MED6LOA_STUDENT_LEAVE_TIME_REPORT = 'reports/GET_MED6LOA_STUDENT_LEAVE_TIME_REPORT';

export const GET_PAYMENT_TAG_REPORT_LIST = 'reports/GET_PAYMENT_TAG_REPORT_LIST';
export const PAYMENT_TAG_REPORT_BANK_ACCOUNT_REQUESTED = 'reports/PAYMENT_TAG_REPORT_BANK_ACCOUNT_REQUESTED';
export const PAYMENT_TAG_REPORT_BANK_ACCOUNT_FAILURE = 'reports/PAYMENT_TAG_REPORT_BANK_ACCOUNT_FAILURE';
export const GET_PAYMENT_TAG_REPORT_BANK_ACCOUNT = 'reports/GET_PAYMENT_TAG_REPORT_BANK_ACCOUNT';
export const PAYMENT_TAG_REPORT_CREATED_BY_REQUESTED = 'reports/PAYMENT_TAG_REPORT_CREATED_BY_REQUESTED';
export const PAYMENT_TAG_REPORT_CREATED_BY_FAILURE = 'reports/PAYMENT_TAG_REPORT_CREATED_BY_FAILURE';
export const GET_PAYMENT_TAG_REPORT_CREATED_BY = 'reports/GET_PAYMENT_TAG_REPORT_CREATED_BY';
export const GET_ADJUSTMENT_REPORT_LIST = 'reports/GET_ADJUSTMENT_REPORT_LIST';
export const GET_PAYMENT_TAG_REPORT_VIEW = 'reports/GET_PAYMENT_TAG_REPORT_VIEW';

export const GET_ADDRESS_REPORT_LIST = 'reports/GET_ADDRESS_REPORT_LIST';

export const GET_BALANCE_REPORT_LIST = 'reports/GET_BALANCE_REPORT_LIST';
export const GET_SCHLOARSHIP_SUCCESS = 'reports/GET_SCHLOARSHIP_SUCCESS';

export const GET_REFUND_REPORT = 'report/GET_REFUND_REPORT';

export const GET_MED6LOA_BALANCE_REPORT = 'report/GET_MED6LOA_BALANCE_REPORT';

export const GET_APPICATION_STATUS_LIST = 'reports/GET_APPICATION_STATUS_LIST';

export const GET_INVOICE_PROMOTIONAL_MISMATCH_REPORT_SUCCESS = 'reports/GET_INVOICE_PROMOTIONAL_MISMATCH_REPORT_SUCCESS';
export const GET_TUITION_FEE_ACCEPTED_SUCCESS = 'reports/GET_TUITION_FEE_ACCEPTED_SUCCESS';

export const GET_ADVANCED_REPORT_LIST = 'reports/GET_ADVANCED_REPORT_LIST';
export const DELETE_ADVANCED_REPORT_LIST = 'reports/DELETE_ADVANCED_REPORT_LIST';
export const GET_TUTION_FEE_REPORT_LIST = 'reports/GET_TUTION_FEE_REPORT_LIST';

export const GET_PROMOTIONAL_REPORT_LIST = 'reports/GET_PROMOTIONAL_REPORT_LIST';

export const GET_ADMINISTRATIVE_INVOICE_REPORT_COUNT = 'reports/GET_ADMINISTRATIVE_INVOICE_REPORT_COUNT';

export const GET_ADMINISTRATIVE_INVOICE_REPORT_DETAILS = 'reports/GET_ADMINISTRATIVE_INVOICE_REPORT_DETAILS';

export const GET_SCHEDULED_AND_AVAIABLE_REPORT_SUCCESS = 'reports/GET_SCHEDULED_AND_AVAIABLE_REPORT_SUCCESS';

export const STUDENT_ATENDANCE_REPORT_SUCCESS = 'reports/STUDENT_ATENDANCE_REPORT_SUCCESS';

export const PAYMENT_TAG_ID_REPORT = 'reports/PAYMENT_TAG_ID_REPORT';

export const GET_BILL_SUMMARY_REPORT_SUCCESS = 'reports/GET_BILL_SUMMARY_REPORT_SUCCESS';
export const GET_SEMESTER_INVOICE_DETAILS_SUCCESS = 'reports/GET_SEMESTER_INVOICE_DETAILS_SUCCESS';

export const GET_PAYMENT_STAGE_SUMMARY_REPORT_SUCCESS = 'reports/GET_PAYMENT_STAGE_SUMMARY_REPORT_SUCCESS';

export const GET_SEMESTER_INVOICE_REPORT_DASHBOARD_SUCCESS = 'reports/GET_SEMESTER_INVOICE_REPORT_DASHBOARD_SUCCESS';

export const GET_MISSING_INFO_REPORT_LIST = 'reports/GET_MISSING_INFO_REPORT_LIST';

export const GET_STUDENT_SNAP_SHOP_REPORT = 'reports/GET_STUDENT_SNAP_SHOP_REPORT';

export const GET_CLINICAL_GRADUATION_STATUS_REPORT = 'reports/GET_CLINICAL_GRADUATION_STATUS_REPORT';

export const GET_ROTATION_ENDING_WEEKS_LIST = 'reports/GET_ROTATION_ENDING_WEEKS_LIST';

export const GET_STUDENT_SNAP_SHOP_REPORT_FAILURE = 'reports/GET_STUDENT_SNAP_SHOP_REPORT_FAILURE';

export const GET_STUDETN_CBSE_EXAM_REPORT_SUCCESS = 'reports/GET_STUDETN_CBSE_EXAM_REPORT_SUCCESS';

export const GET_COMPLETED_CLINICAL_GRADUATION_REPORT_SUCCESS = 'reports/GET_COMPLETED_CLINICAL_GRADUATION_REPORT_SUCCESS';

export const GET_STUDENT_HOSPITAL_REPORT_SUCCESS = 'reports/GET_STUDENT_HOSPITAL_REPORT_SUCCESS';
export const GET_STUDENT_HOSPITAL_REPORT_FAILURE = 'reports/GET_STUDENT_HOSPITAL_REPORT_FAILURE';

export const GET_IMMIGRATION_REPORT_LIST = 'reports/GET_IMMIGRATION_REPORT_LIST';

export const GET_GRADUATION_SURVEY_REPORT_SUCCESS = 'reports/GET_GRADUATION_SURVEY_REPORT_SUCCESS';
export const GET_BASIC_SCIENCE_SURVEY_REPORT_SUCCESS = 'reports/GET_BASIC_SCIENCE_SURVEY_REPORT_SUCCESS';
export const GET_BASIC_SCIENCE_SURVEY_REPORT_FAILURE = 'reports/GET_BASIC_SCIENCE_SURVEY_REPORT_FAILURE';

export const GET_CREDIT_BALANCE_REPORT_SUCCESS = 'reports/GET_CREDIT_BALANCE_REPORT_SUCCESS';
export const GET_BULK_NOTIFY_REPORT_LIST = 'reports/GET_BULK_NOTIFY_REPORT_LIST';

export const GET_CLINICAL_ROTATION_DETAILED_REPORT = 'reports/GET_CLINICAL_ROTATION_DETAILED_REPORT';

export const GET_MED_GRADE_LIST_REPORT_SUCCESS = 'reports/GET_MED_GRADE_LIST_REPORT_SUCCESS';

export const GET_MED_GRADE_LIST_REPORT_STUDENT_SUCCESS = 'reports/GET_MED_GRADE_LIST_REPORT_STUDENT_SUCCESS';
export const GET_MED_GRADE_LIST_REPORT_STUDENT_DETAILS_SUCCESS = 'reports/GET_MED_GRADE_LIST_REPORT_STUDENT_DETAILS_SUCCESS';

export const GET_PAYMENT_REPORT_DETAILS_REQUESTED = 'reports/GET_PAYMENT_REPORT_DETAILS_REQUESTED';
export const GET_PAYMENT_REPORT_DETAILS_SUCCESS = 'reports/GET_PAYMENT_REPORT_DETAILS_SUCCESS';
export const GET_PAYMENT_REPORT_DETAILS_FAILURE = 'reports/GET_PAYMENT_REPORT_DETAILS_FAILURE';

export const GET_EMPLOYEE_BIRTHDAY_REPORT_REQUESTED = 'reports/GET_EMPLOYEE_BIRTHDAY_REPORT_REQUESTED';
export const GET_EMPLOYEE_BIRTHDAY_REPORT_SUCCESS = 'reports/GET_EMPLOYEE_BIRTHDAY_REPORT_SUCCESS';
export const GET_EMPLOYEE_BIRTHDAY_REPORT_FAILURE = 'reports/GET_EMPLOYEE_BIRTHDAY_REPORT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  leaveReport: null,
  studentReport: null,
  studentRotationCount: null,
  studentRotationPrice: null,
  studentBirthday: null,
  transcriptList: null,
  attendanceList: null,
  rotationDetailsList: null,
  rotationPriceDetailsList: null,
  studentGradeReportList: null,
  semesterDatesBySemesterId: null,
  photoReportList: null,
  complaintReportList: null,
  paymentReportList: null,
  applicantPoolList: null,
  withdrawnList: null,
  ledgerReportList: null,
  USMLEReport: null,
  USMLEDtldReport: null,
  clinicalMissingReport: null,
  stageGpaReportList: null,
  invoicePromotionalList: null,
  billedUnbilledList: null,
  semesterInvoiceReportList: null,
  pendingInvoiceReportList: null,
  invoicePaymentList: null,
  med6LoaStudentLeaveTimeReportList: null,
  paymentTagReportList: null,
  paymentTagReportBankAccountLoading: false,
  paymentTagReportBankAccount: null,
  paymentTagReportCreatedByLoading: false,
  paymentTagReportCreatedBy: null,
  adjustmentReportList: null,
  addressReportList: null,
  balanceReportList: null,
  applicationStatusReportList: null,
  invoicePromotionalMismatchList: null,
  tuitionFeeReportList: null,
  paymentTagReportView: null,
  advancedReportStepOneList: null,
  schloarListReport: null,
  refundReportList: null,
  med6LoaBalanceReport: null,
  tuitionFeeReport: null,
  promotionalReport: null,
  invoiceAdministrativeList: null,
  invoiceAdministrativeListDetails: null,
  scheduledAndAvailableReport: null,
  attendanceDetailsReportList: null,
  paymentTagIdReport: null,
  billSummaryReport: null,
  semesterInvoiceDetails: null,
  paymentStageSummaryReport: null,
  semesterInvoiceDashboard: null,
  missingInfoReportList: null,
  snapshotDetails: null,
  clinicalGraduationStatusReportList: null,
  rotationEndingWeeksList: null,
  cbseList: null,
  completedClinicalGraduationReportList: null,
  hospitalReportList: null,
  immigrationReportList: null,
  graduationSurveyReportList: null,
  basicScienceSurveyReportList: null,
  creditBalanceReportList: null,
  bulkNotifyReportList: null,
  clinicalRotationDetailedList: null,
  medGradeList: null,
  medGradeListStudent: null,
  medGradeListStudentDetails: null,
  paymentReportDetails: null,
  employeeBirthdayReportDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case STUDENT_LEAVE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      leaveReport: action.result && action.result.data,
    };
  }
  case STUDENT_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentReport: action.result && action.result.data,
    };
  }
  case STUDENT_ROTATION_COUNT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentRotationCount: action.result && action.result.data,
    };
  }
  case STUDENT_ROTATION_PRICE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentRotationPrice: action.result && action.result.data,
    };
  }
  case STUDENT_BIRTHDAY_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentBirthday: action.result && action.result.data,
    };
  }
  case STUDENT_CLINICAL_LOG_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_CLINICAL_LOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentLog: action.result && action.result.data,
    };
  }
  case STUDENT_CLINICAL_LOG_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case TRANSCRIPT_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      transcriptList: action.result && action.result.data,
    };
  }
  case ATTENDANCE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      attendanceList: action.result && action.result.data,
    };
  }
  case STUDENT_ROTATION_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationDetailsList: action.result && action.result.data,
    };
  }
  case STUDENT_PRICE_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationPriceDetailsList: action.result && action.result.data
    };
  }
  case GET_GRADE_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentGradeReportList: action.result && action.result.data
    };
  }
  case GET_SEMESTER_DATES_LIST_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterDatesBySemesterId: action.result && action.result.data
    };
  }
  case GET_PHOTO_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      photoReportList: action.result && action.result.data
    };
  }
  case GET_COMPLAINT_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      complaintReportList: action.result && action.result.data
    };
  }

  case GET_STUDENT_WITHDRAWN_REPORT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_WITHDRAWN_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      withdrawnList: action.result
    };
  }
  case GET_STUDENT_WITHDRAWN_REPORT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_STUDENT_LEDGER_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      ledgerReportList: action.result
    };
  }
  case GET_STUDENT_USMLE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      USMLEReport: action && action.result
    };
  }
  case GET_STUDENT_USMLE_DETAILED_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      USMLEDtldReport: action && action.result
    };
  }
  case GET_CLINICAL_MISSING_GRADE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      clinicalMissingReport: action && action.result
    };
  }
  case GET_STAGE_GPA_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      stageGpaReportList: action.result && action.result.data
    };
  }
  case APPLICANT_POOL_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      applicantPoolList: action.result && action.result.data
    };
  }

  case GET_INVOICE_PROMOTIONAL_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      invoicePromotionalList: action.result && action.result.data,
    };
  }

  case GET_BILLED_UNBILLED_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      billedUnbilledList: action.result && action.result.data,
    };
  }

  case GET_STUDENT_INVOICE_PAYMENT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoicePaymentList: action.result && action.result.data
    };
  }
  case GET_PAYMENT_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      paymentReportList: action.result && action.result.data
    };
  }

  case GET_SEMESTER_INVOICE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      semesterInvoiceReportList: action.result && action.result.data,
    };
  }

  case GET_PENDING_INVOICE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      pendingInvoiceReportList: action.result && action.result.data,
    };
  }

  case GET_ADJUSTMENT_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      adjustmentReportList: action.result && action.result.data
    };
  }
  case GET_MED6LOA_STUDENT_LEAVE_TIME_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      med6LoaStudentLeaveTimeReportList: action.result && action.result.data
    };
  }
  case GET_PAYMENT_TAG_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      paymentTagReportList: action.result && action.result.data
    };
  }
  case PAYMENT_TAG_REPORT_BANK_ACCOUNT_REQUESTED: {
    return {
      ...state,
      paymentTagReportBankAccountLoading: true,
      loaded: false
    };
  }
  case PAYMENT_TAG_REPORT_BANK_ACCOUNT_FAILURE: {
    return {
      ...state,
      paymentTagReportBankAccountLoading: false,
      error: true,
      loaded: true
    };
  }
  case GET_PAYMENT_TAG_REPORT_BANK_ACCOUNT: {
    return {
      ...state,
      paymentTagReportBankAccountLoading: false,
      loaded: true,
      error: false,
      paymentTagReportBankAccount: action.result && action.result.data
    };
  }
  case PAYMENT_TAG_REPORT_CREATED_BY_REQUESTED: {
    return {
      ...state,
      paymentTagReportCreatedByLoading: true,
      loaded: false,
    };
  }
  case PAYMENT_TAG_REPORT_CREATED_BY_FAILURE: {
    return {
      ...state,
      paymentTagReportCreatedByLoading: false,
      error: true,
      loaded: true,
    };
  }
  case GET_PAYMENT_TAG_REPORT_CREATED_BY: {
    return {
      ...state,
      paymentTagReportCreatedByLoading: false,
      loaded: true,
      error: false,
      paymentTagReportCreatedBy: action.result && action.result.data
    };
  }
  case GET_APPICATION_STATUS_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      applicationStatusReportList: action.result && action.result.data
    };
  }
  case GET_TUTION_FEE_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      tuitionFeeReport: action.result && action.result.data
    };
  }
  case GET_PROMOTIONAL_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      tuitionFeeReport: action.result && action.result.data
    };
  }
  case GET_SCHLOARSHIP_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      schloarListReport: action.result && action.result.data
    };
  }
  case GET_REFUND_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      refundReportList: action.result && action.result.data
    };
  }
  case GET_MED6LOA_BALANCE_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      med6LoaBalanceReport: action.result && action.result.data
    };
  }
  case GET_ADMINISTRATIVE_INVOICE_REPORT_COUNT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoiceAdministrativeList: action.result && action.result.data
    };
  }
  case GET_ADMINISTRATIVE_INVOICE_REPORT_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoiceAdministrativeListDetails: action.result && action.result.data
    };
  }
  case GET_SCHEDULED_AND_AVAIABLE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      scheduledAndAvailableReport: action && action.result
    };
  }
  case GET_PAYMENT_TAG_REPORT_VIEW: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      paymentTagReportView: action.result && action.result.data
    };
  }
  case GET_ADVANCED_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      advancedReportStepOneList: action.result && action.result.data
    };
  }
  case DELETE_ADVANCED_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }

  case GET_ADDRESS_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      addressReportList: action.result && action.result.data
    };
  }

  case GET_BALANCE_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      balanceReportList: action.result && action.result.data
    };
  }

  case GET_INVOICE_PROMOTIONAL_MISMATCH_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      invoicePromotionalMismatchList: action.result && action.result.data
    };
  }

  case GET_TUITION_FEE_ACCEPTED_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      tuitionFeeReportList: action.result && action.result.data
    };
  }
  case STUDENT_ATENDANCE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      attendanceDetailsReportList: action.result && action.result.data
    };
  }
  case PAYMENT_TAG_ID_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      paymentTagIdReport: action.result && action.result.data
    };
  }
  case GET_BILL_SUMMARY_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      billSummaryReport: action.result && action.result.data
    };
  }
  case GET_SEMESTER_INVOICE_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterInvoiceDetails: action.result && action.result.data
    };
  }
  case GET_PAYMENT_STAGE_SUMMARY_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      paymentStageSummaryReport: action.result && action.result.data
    };
  }
  case GET_SEMESTER_INVOICE_REPORT_DASHBOARD_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterInvoiceDashboard: action.result && action.result.data
    };
  }
  case GET_MISSING_INFO_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      missingInfoReportList: action.result && action.result.data
    };
  }
  case GET_STUDENT_SNAP_SHOP_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      snapshotDetails: action.result && action.result.data
    };
  }
  case GET_STUDENT_SNAP_SHOP_REPORT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      snapshotDetails: null
    };
  }
  case GET_CLINICAL_GRADUATION_STATUS_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalGraduationStatusReportList: action.result && action.result.data
    };
  }
  case GET_ROTATION_ENDING_WEEKS_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      rotationEndingWeeksList: action.result && action.result.data
    };
  }

  case GET_STUDETN_CBSE_EXAM_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      cbseList: action && action.result
    };
  }
  case GET_COMPLETED_CLINICAL_GRADUATION_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      completedClinicalGraduationReportList: action.result && action.result.data
    };
  }
  case GET_STUDENT_HOSPITAL_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      hospitalReportList: action && action.result && action.result.data
    };
  }
  case GET_STUDENT_HOSPITAL_REPORT_FAILURE: {
    return {
      ...state,
      hospitalReportList: null,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_IMMIGRATION_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      immigrationReportList: action.result && action.result.data,
    };
  }
  case GET_CREDIT_BALANCE_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      creditBalanceReportList: action.result && action.result.data,
    };
  }
  case GET_GRADUATION_SURVEY_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      graduationSurveyReportList: action.result && action.result.data,
    };
  }
  case GET_BASIC_SCIENCE_SURVEY_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      basicScienceSurveyReportList: action.result && action.result.data,
    };
  }
  case GET_BASIC_SCIENCE_SURVEY_REPORT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      basicScienceSurveyReportList: null,
    };
  }
  case GET_BULK_NOTIFY_REPORT_LIST: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      bulkNotifyReportList: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_ROTATION_DETAILED_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalRotationDetailedList: action.result && action.result.data
    };
  }
  case GET_MED_GRADE_LIST_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      medGradeList: action.result && action.result.data,
    };
  }
  case GET_MED_GRADE_LIST_REPORT_STUDENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      medGradeListStudent: action.result && action.result.data,
    };
  }
  case GET_MED_GRADE_LIST_REPORT_STUDENT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      medGradeListStudentDetails: action.result && action.result.data,
    };
  }

  case GET_PAYMENT_REPORT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_PAYMENT_REPORT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      paymentReportDetails: action.result?.data,
    };
  }
  case GET_PAYMENT_REPORT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      paymentReportDetails: null,
    };
  }
  case GET_EMPLOYEE_BIRTHDAY_REPORT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_BIRTHDAY_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      employeeBirthdayReportDetails: action.result?.data,
    };
  }
  case GET_EMPLOYEE_BIRTHDAY_REPORT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      employeeBirthdayReportDetails: null,
    };
  }
  default: {
    return {
      ...state
    };
  }
  }
};

export const studentLeaveReports = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, STUDENT_LEAVE_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentLeaveReportList', { data })
  };
};

export const studentReportList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, STUDENT_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentReportsList', { data })
  };
};

export const studentRotationCountList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, STUDENT_ROTATION_COUNT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentRotationCount', { data })
  };
};

export const studentRotationPriceList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, STUDENT_ROTATION_PRICE_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentRotationPrice', { data })
  };
};

export const studentBirthdayList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, STUDENT_BIRTHDAY_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentBirthdayReport', { data })
  };
};

export const studentClinicalLogList = (days) => {
  return {
    types: [STUDENT_CLINICAL_LOG_REQUESTED, STUDENT_CLINICAL_LOG_SUCCESS, STUDENT_CLINICAL_LOG_FAILURE],
    promise: (client) => client.get(`studentLogReports/${days}`)
  };
};

export const transcriptReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, TRANSCRIPT_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('transcriptReports', { params })
  };
};

export const attendanceReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ATTENDANCE_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('attendanceReport', { params })
  };
};

export const studentRotationDetailsList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, STUDENT_ROTATION_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('rotationCountReportsDetails', { params })
  };
};

export const studentRotationPriceDetailsList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, STUDENT_PRICE_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('rotationPriceReportsDetails', { data })
  };
};

export const studentGradeReport = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_GRADE_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('gradeReportList', { data })
  };
};

export const studentGradeNonClinicalReport = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_GRADE_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('gradereportNonClinicalList', { data })
  };
};

export const getSemesterDatesBySemesterId = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SEMESTER_DATES_LIST_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getSemesterDateBySemesterId/${id}`)
  };
};

export const studentPhotoReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PHOTO_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('photoReportList', { params })
  };
};

export const studentComplaintReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COMPLAINT_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('complaintReport', { params })
  };
};

export const studentPaymentReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PAYMENT_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('paymentReport', { params })
  };
};

export const getApplicantPoolList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, APPLICANT_POOL_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('applicantPool', { params })
  };
};
export const getStudentWithdrawnReport = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_STUDENT_WITHDRAWN_REPORT_SUCCESS, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.get('getStudentWithdrawnReport', { params })
  };
};

export const getStudentLedgerReport = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_STUDENT_LEDGER_REPORT_SUCCESS, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.post('getStudentLedgerReport', { params })
  };
};

export const getUSMLEReport = (data) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_STUDENT_USMLE_REPORT_SUCCESS, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.post('getUSMLEReport', { data })
  };
};
export const getUSMLEDetailedReport = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_STUDENT_USMLE_DETAILED_REPORT_SUCCESS, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.post('getUSMLEDetailedReport', { params })
  };
};
export const getClinicalMissingGradeReport = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_CLINICAL_MISSING_GRADE_REPORT_SUCCESS, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.post('getClinicalMissingGradeReport', { params })
  };
};

export const stageGpaReportListSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STAGE_GPA_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('searchStageGpaReport', { params })
  };
};

export const getInvoicePromotionalReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_INVOICE_PROMOTIONAL_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getInvoicePromotionalReport', { params })
  };
};

export const getBilledUnbilled = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_BILLED_UNBILLED_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getBilledUnbilled', { params })
  };
};
export const getSemesterInvioceReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SEMESTER_INVOICE_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSemesterInvioceReport', { params })
  };
};

export const getPendingInvoiceSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PENDING_INVOICE_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getPendingInvoiceSearch', { params })
  };
};

export const getStudentInvoicePaymentList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_INVOICE_PAYMENT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('invoicePaymentReport', { params })
  };
};

export const med6LoaStudentLeaveTimeReportSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_MED6LOA_STUDENT_LEAVE_TIME_REPORT, ADD_ACTION_FAILURE],
    promise: (client) => client.get('med6LoaStudentLeaveTimeReport', { params })
  };
};

export const paymentTagReportSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PAYMENT_TAG_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('paymentTagReport', { params })
  };
};

export const paymentTagReportBankAccountList = () => {
  return {
    types: [PAYMENT_TAG_REPORT_BANK_ACCOUNT_REQUESTED, GET_PAYMENT_TAG_REPORT_BANK_ACCOUNT, PAYMENT_TAG_REPORT_BANK_ACCOUNT_FAILURE],
    promise: (client) => client.get('paymentTagReportBankAccount')
  };
};

export const paymentTagReportCreatedByList = () => {
  return {
    types: [PAYMENT_TAG_REPORT_CREATED_BY_REQUESTED, GET_PAYMENT_TAG_REPORT_CREATED_BY, PAYMENT_TAG_REPORT_CREATED_BY_FAILURE],
    promise: (client) => client.get('paymentTagReportCreatedBy')
  };
};
export const adjustmentListSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ADJUSTMENT_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('adjustmentReportList', { params })
  };
};

export const addressReportSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ADDRESS_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('addressReportList', { params })
  };
};

export const balanceReportSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_BALANCE_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('balanceReportList', { params })
  };
};

export const getApplicationStatusReport = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_APPICATION_STATUS_LIST, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.post('getApplicationStatusReport', { params })
  };
};
export const getInvoicePromotionalMismatchreport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_INVOICE_PROMOTIONAL_MISMATCH_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getInvoicePromotionalMismatchreport', { params })
  };
};

export const getTuitionFeeAccepted = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TUITION_FEE_ACCEPTED_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getTuitionFeeAccepted', { params })
  };
};

export const sendEmailPendingInvoice = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('sendEmailPendingInvoice', { data })
  };
};

export const getPaymentTagReportViewList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PAYMENT_TAG_REPORT_VIEW, ADD_ACTION_FAILURE],
    promise: (client) => client.get('paymentTagReportView', { params })
  };
};

export const advancedReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ADVANCED_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('advancedReportStepOne', { params })
  };
};

export const advancedReportListDelete = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, DELETE_ADVANCED_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.del('advancedReportDelete', { params })
  };
};
export const getScholarshipListReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SCHLOARSHIP_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSchloraSipReport', { params })
  };
};

export const getRefundListReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REFUND_REPORT, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getRefundReport', { params })
  };
};

export const getMed6BalanceReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_MED6LOA_BALANCE_REPORT, ADD_ACTION_FAILURE],
    promise: (client) => client.get('med6BalanceReport', { params })
  };
};

export const tutionFeeReportSearch = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_TUTION_FEE_REPORT_LIST, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.get('tutionFeeReportSearch', { params })
  };
};

export const promotionalReportSearch = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_TUTION_FEE_REPORT_LIST, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.get('promotionalReportSearch', { params })
  };
};

export const getAdministrativeInvoiceCountReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ADMINISTRATIVE_INVOICE_REPORT_COUNT, ADD_ACTION_FAILURE],
    promise: (client) => client.get('administrativeInvoiceReportCount', { params })
  };
};

export const getAdministrativeInvoiceDetailsReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ADMINISTRATIVE_INVOICE_REPORT_DETAILS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('administrativeInvoiceReportDetails', { params })
  };
};

export const getScheduledAndAvailableReport = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, GET_SCHEDULED_AND_AVAIABLE_REPORT_SUCCESS, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.post('getScheduledAndAvailableReport', { params })
  };
};

export const getAttendaceDetilsReport = (params) => {
  return {
    types: [GET_STUDENT_WITHDRAWN_REPORT_REQUESTED, STUDENT_ATENDANCE_REPORT_SUCCESS, GET_STUDENT_WITHDRAWN_REPORT_FAILURE],
    promise: (client) => client.get('attendanceDetailsReport', { params })
  };
};

export const getPayentTagGetReport = (tagId) => {
  return {
    types: [ADD_ACTION_REQUESTED, PAYMENT_TAG_ID_REPORT, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`paymentTagIdReport/${tagId}`)
  };
};

export const getBillSummaryBySemesterId = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_BILL_SUMMARY_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getBillSummaryBySemesterId/', { params })
  };
};

export const getSemesterInvoiceDetailsReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SEMESTER_INVOICE_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSemesterInvoiceDetailsReport', { params })
  };
};

export const getPaymentSatgeSummaryReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PAYMENT_STAGE_SUMMARY_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getPaymentSatgeSummaryReport', { params })
  };
};

export const getSemesterInvioceReportDashboard = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SEMESTER_INVOICE_REPORT_DASHBOARD_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSemesterInvioceReportDashboard', { params })
  };
};

export const studentMissingInfoReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_MISSING_INFO_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('missingInfoReport', { params })
  };
};

export const studentSnapshotReportList = (student) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_SNAP_SHOP_REPORT, GET_STUDENT_SNAP_SHOP_REPORT_FAILURE],
    promise: (client) => client.get(`snapshotreportDetails/${student}`)
  };
};

export const getClinicalGraduationStatusReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CLINICAL_GRADUATION_STATUS_REPORT, ADD_ACTION_FAILURE],
    promise: (client) => client.get('clinicalGraduationStatusReport', { params })
  };
};

export const studentRotationEndingWeeksReport = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ROTATION_ENDING_WEEKS_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentRotationEndingWeeksReport', { data })
  };
};

export const getCBSEExamReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDETN_CBSE_EXAM_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getCBSEExamReportList', { params })
  };
};

export const getCompletedClinicalGraduationReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_COMPLETED_CLINICAL_GRADUATION_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('completedClinicalGraduationReport', { params })
  };
};

export const getHospitalReportList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_HOSPITAL_REPORT_SUCCESS, GET_STUDENT_HOSPITAL_REPORT_FAILURE],
    promise: (client) => client.post('getHospitalReportList', { data })
  };
};

export const getImmigrationReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_IMMIGRATION_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getImmigrationReportList', { params })
  };
};

export const getGraduationSurveyReportList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_GRADUATION_SURVEY_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('graduateSurveyReportList', { params })
  };
};
export const getBasicScienceSurveyList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_BASIC_SCIENCE_SURVEY_REPORT_SUCCESS, GET_BASIC_SCIENCE_SURVEY_REPORT_FAILURE],
    promise: (client) => client.get('getBasicScienceSurveyList', { params })
  };
};
export const getCreditBalanceReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CREDIT_BALANCE_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('creditBalanceReportList', { params })
  };
};
export const getBulkNotifyreport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_BULK_NOTIFY_REPORT_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getBulkNotifyreport', { params })
  };
};

export const getClinicalRotationDetailedReport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CLINICAL_ROTATION_DETAILED_REPORT, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getClinicalRotationDetailedReport', { params })
  };
};

export const getMedGradeListExport = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_MED_GRADE_LIST_REPORT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getMedGradeListReportStudent', { params })
  };
};
export const getMedGradeListReportStudent = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_MED_GRADE_LIST_REPORT_STUDENT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getMedGradeListReportStudent', { params })
  };
};
export const getMedGradeListReportStudentDetails = (studentId) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_MED_GRADE_LIST_REPORT_STUDENT_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getMedGradeListReportStudentDetails/${studentId}`)
  };
};

export const getPaymentReportDetails = (params) => {
  return {
    types: [GET_PAYMENT_REPORT_DETAILS_REQUESTED, GET_PAYMENT_REPORT_DETAILS_SUCCESS, GET_PAYMENT_REPORT_DETAILS_FAILURE],
    promise: (client) => client.get('paymentReportDetails', { params })
  };
};

export const employeeBirthDayReport = (params) => {
  return {
    types: [GET_EMPLOYEE_BIRTHDAY_REPORT_REQUESTED, GET_EMPLOYEE_BIRTHDAY_REPORT_SUCCESS, GET_EMPLOYEE_BIRTHDAY_REPORT_FAILURE],
    promise: (client) => client.get('employeeBirthDayReport', { params })
  };
};
