export const OAUTH_REQUESTED = 'login/OAUTH_REQUESTED';
export const OAUTH_SUCCESS = 'login/OAUTH_SUCCESS';
export const OAUTH_FAILURE = 'login/OAUTH_FAILURE';

export const ACCESS_RIGHTS_REQUESTED = 'login/ACCESS_RIGHTS_REQUESTED';
export const ACCESS_RIGHTS_SUCCESS = 'login/ACCESS_RIGHTS_SUCCESS';
export const ACCESS_RIGHTS_FAILURE = 'login/ACCESS_RIGHTS_FAILURE';

export const LOGOUT_REQUEST = 'login/LOGOUT_REQUEST';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  loadingFlag: false,
  loadedFlag: false,
  errorFlag: false,
  accessRights: null,
  userdata: null,
  isUserLogin: false
};

export default (state = initialState, action) => {
  switch (action.type) {
  case OAUTH_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case OAUTH_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      userdata: action.result,
      isUserLogin: true,
    };
  }
  case OAUTH_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case ACCESS_RIGHTS_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loadedFlag: false
    };
  }
  case ACCESS_RIGHTS_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      errorFlag: false,
      loadedFlag: true,
      accessRights: action.result,
    };
  }
  case ACCESS_RIGHTS_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      errorFlag: true,
      loadedFlag: true
    };
  }
  default:
    return state;
  }
};

export const login = (data) => {
  return {
    types: [OAUTH_REQUESTED, OAUTH_SUCCESS, OAUTH_FAILURE],
    promise: (client) => client.post('auth', { data })
  };
};

export const loginAccessRights = (userId) => {
  return {
    types: [ACCESS_RIGHTS_REQUESTED, ACCESS_RIGHTS_SUCCESS, ACCESS_RIGHTS_FAILURE],
    promise: (client) => client.get(`initialAccessRights/${userId}`)
  };
};

export const loginWithApiKey = (key) => {
  return {
    types: [OAUTH_REQUESTED, OAUTH_SUCCESS, OAUTH_FAILURE],
    promise: (client) => client.post(`loginWithApiKey/${key}`)
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
  };
};
