import React, { Fragment, useEffect } from 'react';
import { Field } from 'formik';
import {
  Label,
  Input
} from 'reactstrap';
import ErrorMessage from '../ErrorMessage';

const TextAreaInput = (props) => {
  const {
    field,
    form: { errors, isSubmitting, isValidating },
    label,
    customClass,
    labelCustomClass,
    externalLabel,
    placeholder,
    onClick,
    rows,
    readOnly,
    disabled,
    isRequired
  } = props;

  const keys = Object.keys(errors);
  const ObjectType = keys && (typeof errors[keys[0]] === 'object');
  const data = keys && errors[keys[0]] && ObjectType && Object.keys(errors[keys[0]]);

  useEffect(() => {
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = ObjectType ? `${keys[0]}.${data[0]}` : `${keys[0]}`;
      if (selector === `${field.name}`) {
        document.querySelector(`[name="${field.name}"]`) && document.querySelector(`[name="${field.name}"]`).focus();
      }
    }
  });

  return (
    <>
      <Label for={externalLabel} className={labelCustomClass}>{label}{isRequired && (<span className="text-danger font-medium-3 lh-1">&#42;</span>)}</Label>
      <Input
        {...field}
        onClick={onClick}
        value={field.value || ''}
        type="textarea"
        name={field.name}
        id={field.name}
        rows={rows}
        disabled={disabled}
        customclass={customClass}
        placeholder={placeholder}
        readOnly={readOnly}
        className={customClass}
      />
    </>
  );
};

const TextArea = (props) => {
  const {
    name,
    customClass,
    disabled,
    onClick,
    label,
    labelCustomClass,
    externalLabel,
    placeholder,
    rows,
    readOnly,
    isRequired
  } = props;
  return (
    <div>
      <Field
        type="textarea"
        readOnly={readOnly}
        disabled={disabled}
        customClass={customClass}
        onClick={onClick}
        component={TextAreaInput}
        name={name}
        externalLabel={externalLabel}
        label={label}
        labelCustomClass={labelCustomClass}
        placeholder={placeholder}
        rows={rows}
        isRequired={isRequired}
      />
      <ErrorMessage name={name} component="div" className="field-error text-danger" />
    </div>
  );
};

export default TextArea;
