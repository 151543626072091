export const ADD_OR_UPDATE_REQUESTED = 'settings/ADD_OR_UPDATE_REQUESTED';
export const ADD_OR_UPDATE_SUCCESS = 'settings/ADD_OR_UPDATE_SUCCESS';
export const ADD_OR_UPDATE_FAILURE = 'settings/ADD_OR_UPDATE_FAILURE';

export const GET_USER_PROFILE_SUCCESS = 'settings/GET_USER_PROFILE_SUCCESS';

export const ADD_USER_SIGNATURE_SUCCESS = 'settings/ADD_USER_SIGNATURE_SUCCESS';

export const GET_USER_SIGNATURE_SUCCESS = 'settings/GET_USER_SIGNATURE_SUCCESS';
export const GET_MAILLOG_SUCCESS = 'settings/ARUBA_INCOMING_MAILLOG_SUCCESS';
export const GET_DATA_ENTRY_EXCEPTION_SUCCESS = 'settings/GET_DATA_ENTRY_EXCEPTION_SUCCESS';
export const CLINICAL_MARKS_EXCEPTION_SUCCESS = 'settings/CLINICAL_MARKS_EXCEPTION_SUCCESS';
export const MED_PREMED_MARKS_EXCEPTION_SUCCESS = 'settings/MED_PREMED_MARKS_EXCEPTION_SUCCESS';
export const ROTATION_CLEARANCE_DUE_LIST = 'settings/ROTATION_CLEARANCE_DUE_LIST';
export const ROTATION_CLEARANCE_DUE_COUNT = 'settings/ROTATION_CLEARANCE_DUE_COUNT';

export const PROMOTION_SUMMARY_REPORT = 'settings/PROMOTION_SUMMARY_REPORT';
export const STUDENT_ROTATION_LIST = 'settings/STUDENT_ROTATION_LIST';
export const GET_IN_MAILLOG_SUCCESS = 'settings/GET_IN_MAILLOG_SUCCESS';

export const GET_OUTGOING_MAILLOG_SUCCESS = 'settings/GET_OUTGOING_MAILLOG_SUCCESS';

export const GET_ARUBA_OUT_MAILLOG_SUCCESS = 'settings/GET_ARUBA_OUT_MAILLOG_SUCCESS';

export const GET_ARUBA_IN_MAILLOG_SUCCESS = 'settings/GET_ARUBA_IN_MAILLOG_SUCCESS';

export const GET_PAYMENT_DETAILED_RTEPORT_BY_TYPE_SUCCESS = 'settings/GET_PAYMENT_DETAILED_RTEPORT_BY_TYPE_SUCCESS';

export const GET_STUDENT_COUNT_DETAILED_RTEPORT_BY_TYPE_SUCCESS = 'settings/GET_STUDENT_COUNT_DETAILED_RTEPORT_BY_TYPE_SUCCESS';

export const GET_MED6_LOA_DEADLINE_STUDENT_COUNT = 'settings/GET_MED6_LOA_DEADLINE_STUDENT_COUNT';

export const GET_MED6_LOA_DEADLINE_STUDENT_DETAILS = 'settings/GET_MED6_LOA_DEADLINE_STUDENT_DETAILS';

export const GET_RETURNED_MAIL_LOG = 'settings/GET_RETURNED_MAIL_LOG';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userProfile: null,
  signatures: null,
  maillog: null,
  dataEntryException: null,
  clinicalMarksException: null,
  medPremedMarksException: null,
  rotationClearanceDueCount: null,
  rotationClearanceDueList: null,
  promotionSummary: null,
  studentRotationList: null,
  outgoingList: null,
  incomingList: null,
  arubaOutgoingList: null,
  arubaIncomingList: null,
  paymentReportDetailedReportByType: null,
  studentCountDetailedReport: null,
  med6LoaEndCount: null,
  med6LoaDetailedList: null,
  mailLogForWeekList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_OR_UPDATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case ADD_OR_UPDATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_OR_UPDATE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_USER_PROFILE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      userProfile: action.result && action.result.data
    };
  }
  case ADD_USER_SIGNATURE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case GET_USER_SIGNATURE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      signatures: action && action.result
    };
  }
  case GET_MAILLOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      maillog: action && action.result
    };
  }
  case GET_DATA_ENTRY_EXCEPTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      dataEntryException: action && action.result
    };
  }
  case CLINICAL_MARKS_EXCEPTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalMarksException: action && action.result
    };
  }
  case MED_PREMED_MARKS_EXCEPTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      medPremedMarksException: action && action.result
    };
  }
  case ROTATION_CLEARANCE_DUE_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      rotationClearanceDueList: action && action.result
    };
  }
  case ROTATION_CLEARANCE_DUE_COUNT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      rotationClearanceDueCount: action && action.result
    };
  }
  case PROMOTION_SUMMARY_REPORT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      promotionSummary: action && action.result
    };
  }
  case STUDENT_ROTATION_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentRotationList: action && action.result
    };
  }
  case GET_IN_MAILLOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      incomingList: action && action.result
    };
  }
  case GET_ARUBA_OUT_MAILLOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      arubaOutgoingList: action && action.result
    };
  }
  case GET_ARUBA_IN_MAILLOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      arubaIncomingList: action && action.result
    };
  }

  case GET_OUTGOING_MAILLOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      outgoingList: action && action.result
    };
  }
  case GET_PAYMENT_DETAILED_RTEPORT_BY_TYPE_SUCCESS:
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      paymentReportDetailedReportByType: action && action.result

    };
  case GET_STUDENT_COUNT_DETAILED_RTEPORT_BY_TYPE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentCountDetailedReport: action && action.result
    };
  }

  case GET_MED6_LOA_DEADLINE_STUDENT_COUNT: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      med6LoaEndCount: action.result && action.result.data
    };
  }

  case GET_MED6_LOA_DEADLINE_STUDENT_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      med6LoaDetailedList: action.result && action.result.data
    };
  }
  case GET_RETURNED_MAIL_LOG: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      mailLogForWeekList: action && action.result
    };
  }
  default:
    return state;
  }
};
export const getUserProfile = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_USER_PROFILE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get(`getUserProfile/${data}`)
  };
};

export const updateUserProfile = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('updateUserProfile', { data })
  };
};

export const changeUserPassword = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('changeUserPassword', { data })
  };
};

export const addUserSignature = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_USER_SIGNATURE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addUserSignature', { data })
  };
};

export const getUserSignature = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_USER_SIGNATURE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('getUserSignature')
  };
};

export const getIncomingMaillog = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_IN_MAILLOG_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbMaillog', { params })
  };
};

export const getArubaIncominMaillog = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_ARUBA_IN_MAILLOG_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbMaillog', { params })
  };
};

export const getArubaOutgoingMaillog = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_ARUBA_OUT_MAILLOG_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbMaillog', { params })
  };
};

export const getOutgoingMaillog = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_OUTGOING_MAILLOG_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbMaillog', { params })
  };
};
export const getDataEntryExecption = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_DATA_ENTRY_EXCEPTION_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbDataEntryExecption')
  };
};
export const getClinicalMarksException = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, CLINICAL_MARKS_EXCEPTION_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbClinicalMarksException', { params })
  };
};
export const getMedPremedMarksException = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, MED_PREMED_MARKS_EXCEPTION_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbMedPremedMarksException', { params })
  };
};
export const getRotationClearanceDueCount = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ROTATION_CLEARANCE_DUE_COUNT, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbRotationClearanceDueCount', { params })
  };
};
export const getRotationClearanceDueList = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ROTATION_CLEARANCE_DUE_LIST, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbRotationClearanceDueList', { params })
  };
};
export const getPromotionSummaryReport = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, PROMOTION_SUMMARY_REPORT, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbPromotionSummaryReport')
  };
};

export const getStudentRotationList = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, STUDENT_ROTATION_LIST, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbStudentRotationList', { params })
  };
};

export const getPaymentDetailedReportByType = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_PAYMENT_DETAILED_RTEPORT_BY_TYPE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('getDbpaymentDetailedReport', { params })
  };
};

export const getStudentCountDetailedReportByType = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_STUDENT_COUNT_DETAILED_RTEPORT_BY_TYPE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('getDbStudentCountDetailedReport', { params })
  };
};

export const getMed6LoaDeadlineStudentCount = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_MED6_LOA_DEADLINE_STUDENT_COUNT, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('med6LoaDeadLine')
  };
};

export const getMed6LoaDetailedList = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_MED6_LOA_DEADLINE_STUDENT_DETAILS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('getMed6LoaDetailedLlist', { params })
  };
};

export const getMailLogForWeek = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_RETURNED_MAIL_LOG, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('dbMaillog')
  };
};
