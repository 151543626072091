export const ADD_EMPLOYEE_REQUESTED = 'employee/ADD_EMPLOYEE_REQUESTED';
export const ADD_EMPLOYEE_SUCCESS = 'employee/ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'employee/ADD_EMPLOYEE_FAILURE';
export const GET_EMPLOYEE_LIST_REQUESTED = 'employee/GET_EMPLOYEE_LIST_REQUESTED';
export const GET_EMPLOYEE_LIST_SUCCESS = 'employee/GET_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_LIST_FAILURE = 'employee/GET_EMPLOYEE_LIST_FAILURE';
export const GET_EMPLOYEE_ADDRESS_REQUESTED = 'employee/GET_EMPLOYEE_ADDRESS_REQUESTED';
export const GET_EMPLOYEE_ADDRESS_SUCCESS = 'employee/GET_EMPLOYEE_ADDRESS_SUCCESS';
export const GET_EMPLOYEE_ADDRESS_FAILURE = 'employee/GET_EMPLOYEE_ADDRESS_FAILURE';
export const SELCTED_EMPLOYEE_DATA = 'student/SELCTED_EMPLOYEE_DATA';
export const GET_EMPLOYEE_PROFILE_FAILURE = 'employee/GET_EMPLOYEE_PROFILE_FAILURE';
export const GET_EMPLOYEE_PROFILE_SUCCESS = 'employee/GET_EMPLOYEE_PROFILE_SUCCESS';
export const GET_EMPLOYEE_PROFILE_REQUESTED = 'employee/GET_EMPLOYEE_PROFILE_REQUESTED';
export const GET_DOCUMENT_LIST_FAILURE = 'employee/GET_DOCUMENT_LIST_FAILURE';
export const GET_DOCUMENT_LIST_SUCCESS = 'employee/GET_DOCUMENT_LIST_SUCCESS';
export const GET_DOCUMENT_LIST_REQUESTED = 'employee/GET_DOCUMENT_LIST_REQUESTED';
export const GET_DOCUMENT_TEMPLATES_FAILURE = 'employee/GET_DOCUMENT_TEMPLATES_FAILURE';
export const GET_DOCUMENT_TEMPLATES_SUCCESS = 'employee/GET_DOCUMENT_TEMPLATES_SUCCESS';
export const GET_DOCUMENT_TEMPLATES_REQUESTED = 'employee/GET_DOCUMENT_TEMPLATES_REQUESTED';
export const GET_EMPLOYEE_BANK_DETAILS_FAILURE = 'employee/GET_EMPLOYEE_BANK_DETAILS_FAILURE';
export const GET_EMPLOYEE_BANK_DETAILS_SUCCESS = 'employee/GET_EMPLOYEE_BANK_DETAILS_SUCCESS';
export const GET_EMPLOYEE_BANK_DETAILS_REQUESTED = 'employee/GET_EMPLOYEE_BANK_DETAILS_REQUESTED';
export const GET_EMPLOYEE_HRSECTION_FAILURE = 'employee/GET_EMPLOYEE_HRSECTION_FAILURE';
export const GET_EMPLOYEE_HRSECTION_SUCCESS = 'employee/GET_EMPLOYEE_HRSECTION_SUCCESS';
export const GET_EMPLOYEE_HRSECTION_REQUESTED = 'employee/GET_EMPLOYEE_HRSECTION_REQUESTED';
export const GET_EMPLOYEE_SALARY_FAILURE = 'employee/GET_EMPLOYEE_SALARY_FAILURE';
export const GET_EMPLOYEE_SALARY_SUCCESS = 'employee/GET_EMPLOYEE_SALARY_SUCCESS';
export const GET_EMPLOYEE_SALARY_REQUESTED = 'employee/GET_EMPLOYEE_SALARY_REQUESTED';
export const GET_EMPLOYEE_PASSPORT_FAILURE = 'employee/GET_EMPLOYEE_PASSPORT_FAILURE';
export const GET_EMPLOYEE_PASSPORT_SUCCESS = 'employee/GET_EMPLOYEE_PASSPORT_SUCCESS';
export const GET_EMPLOYEE_PASSPORT_REQUESTED = 'employee/GET_EMPLOYEE_PASSPORT_REQUESTED';
export const GET_EMPLOYEE_SMS_REQUESTED = 'employee/GET_EMPLOYEE_SMS_REQUESTED';
export const GET_EMPLOYEE_SMS_FAILURE = 'employee/GET_EMPLOYEE_SMS_FAILURE';
export const GET_EMPLOYEE_SMS_SUCCESS = 'employee/GET_EMPLOYEE_SMS_SUCCESS';
export const SEND_CRED_MAIL_REQUESTED = 'employee/SEND_CRED_MAIL_REQUESTED';
export const SEND_CRED_MAIL_FAILURE = 'employee/SEND_CRED_MAIL_FAILURE';
export const SEND_CRED_MAIL_SUCCESS = 'employee/SEND_CRED_MAIL_SUCCESS';
export const GET_EMPLOYEE_EMAILS_REQUESTED = 'employee/GET_EMPLOYEE_EMAILS_REQUESTED';
export const GET_EMPLOYEE_EMAILS_FAILURE = 'employee/GET_EMPLOYEE_EMAILS_FAILURE';
export const GET_EMPLOYEE_EMAILS_SUCCESS = 'employee/GET_EMPLOYEE_EMAILS_SUCCESS';

export const GET_BASIC_INFO_REQUESTED = 'employee/GET_BASIC_INFO_REQUESTED';
export const GET_BASIC_INFO_FAILURE = 'employee/GET_BASIC_INFO_FAILURE';
export const GET_BASIC_INFO_SUCCESS = 'employee/GET_BASIC_INFO_SUCCESS';

export const UPLOAD_PHOTO_REQUESTED = 'controlData/UPLOAD_PHOTO_REQUESTED';
export const UPLOAD_PHOTO_SUCCESS = 'controlData/UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'controlData/UPLOAD_PHOTO_FAILURE';

export const GET_TIMETABLE_SCHEDULE_LIST_REQUESTED = 'employee/GET_TIMETABLE_SCHEDULE_LIST_REQUESTED';
export const GET_TIMETABLE_SCHEDULE_LIST_SUCCESS = 'employee/GET_TIMETABLE_SCHEDULE_LIST_SUCCESS';
export const GET_TIMETABLE_SCHEDULE_LIST_FAILURE = 'employee/GET_TIMETABLE_SCHEDULE_LIST_FAILURE';

export const GET_TIMETABLE_DETAILS_REQUESTED = 'employee/GET_TIMETABLE_DETAILS_REQUESTED';
export const GET_TIMETABLE_DETAILS_SUCCESS = 'employee/GET_TIMETABLE_DETAILS_SUCCESS';
export const GET_TIMETABLE_DETAILS_FAILURE = 'employee/GET_TIMETABLE_DETAILS_FAILURE';

export const GET_TIMETABLE_STUDENTS_LIST_REQUESTED = 'employee/GET_TIMETABLE_STUDENTS_LIST_REQUESTED';
export const GET_TIMETABLE_STUDENTS_LIST_SUCCESS = 'employee/GET_TIMETABLE_STUDENTS_LIST_SUCCESS';
export const GET_TIMETABLE_STUDENTS_LIST_FAILURE = 'employee/GET_TIMETABLE_STUDENTS_LIST_FAILURE';

export const GET_EMPLOYEE_NAME_LIST_REQUESTED = 'employee/GET_EMPLOYEE_NAME_LIST_REQUESTED';
export const GET_EMPLOYEE_NAME_LIST_SUCCESS = 'employee/GET_EMPLOYEE_NAME_LIST_SUCCESS';
export const GET_EMPLOYEE_NAME_LIST_FAILURE = 'employee/GET_EMPLOYEE_NAME_LIST_FAILURE';

export const GET_EMPLOYEE_MAIL_ALERT_LIST_REQUESTED = 'employee/GET_EMPLOYEE_MAIL_ALERT_LIST_REQUESTED';
export const GET_EMPLOYEE_MAIL_ALERT_LIST_SUCCESS = 'employee/GET_EMPLOYEE_MAIL_ALERT_LIST_SUCCESS';
export const GET_EMPLOYEE_MAIL_ALERT_LIST_FAILURE = 'employee/GET_EMPLOYEE_MAIL_ALERT_LIST_FAILURE';

export const GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_REQUESTED = 'employee/GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_REQUESTED';
export const GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_SUCCESS = 'employee/GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_SUCCESS';
export const GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_FAILURE = 'employee/GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_FAILURE';

export const GET_EMPLOYEE_TERMS_AND_CONDITIONS_STATUS = 'employee/GET_EMPLOYEE_TERMS_AND_CONDITIONS_STATUS';

export const GET_COMMITEE_DROPDOWN_REQUESTED = 'employee/GET_COMMITEE_DROPDOWN_REQUESTED';
export const GET_COMMITEE_DROPDOWN_SUCCESS = 'employee/GET_COMMITEE_DROPDOWN_SUCCESS';
export const GET_COMMITEE_DROPDOWN_FAILURE = 'employee/GET_COMMITEE_DROPDOWN_FAILURE';

export const COMMITTE_MEETING_BY_ID_REQUESTED = 'employee/COMMITTE_MEETING_BY_ID_REQUESTED';
export const COMMITTE_MEETING_BY_ID_SUCCESS = 'employee/COMMITTE_MEETING_BY_ID_SUCCESS';
export const COMMITTE_MEETING_BY_ID_FAILURE = 'employee/COMMITTE_MEETING_BY_ID_FAILURE';

const COMMITTE_METTING_LIST_REQUESTED = 'ct/COMMITTE_METTING_LIST_REQUESTED';
const COMMITTE_METTING_LIST_SUCCESS = 'ct/COMMITTE_METTING_LIST_SUCCESS';
const COMMITTE_METTING_LIST_FAILURE = 'ct/COMMITTE_METTING_LIST_FAILURE';

export const GET_ACTIVE_FACULTY_LIST = 'employee/GET_ACTIVE_FACULTY_LIST';

export const GET_ACTIVE_STUDENT_FACULTY_LIST = 'employee/GET_ACTIVE_STUDENT_FACULTY_LIST';

export const GET_COMMITEE_LIST = 'employee/GET_COMMITEE_LIST';

export const GET_COMMITEE_FEEDBACK = 'employee/GET_COMMITEE_FEEDBACK';
export const GET_COMMITEE_FEEDBACK_REQUESTED = 'employee/GET_COMMITEE_FEEDBACK_REQUESTED';
export const GET_COMMITEE_FEEDBACK_FAILURE = 'employee/GET_COMMITEE_FEEDBACK_FAILURE';

export const GET_FEEDBACK_DETAILS = 'employee/GET_FEEDBACK_DETAILS';

export const GET_PARTICIPANTS_DETAILS = 'employee/GET_PARTICIPANTS_DETAILS';

export const GET_MEETING_DETAILS = '/employee/GET_MEETING_DETAILS';

export const GET_ATTENDANCE_LIST = '/employee/GET_ATTENDANCE_LIST';

export const GET_MOM_DOCUMENTS_LIST = '/employee/GET_MOM_DOCUMENTS_LIST';

export const GET_MEETING_RECORDING_REQUESTED = 'employee/GET_MEETING_RECORDING_REQUESTED';
export const GET_MEETING_RECORDING = 'employee/GET_MEETING_RECORDING';
export const GET_MEETING_RECORDING_FAILURE = 'employee/GET_MEETING_RECORDING_FAILURE';

export const GET_ACTIVITY_LOG_DETAILS_REQUESTED = 'employee/GET_ACTIVITY_LOG_DETAILS_REQUESTED';
export const GET_ACTIVITY_LOG_DETAILS_SUCCESS = 'employee/GET_ACTIVITY_LOG_DETAILS_SUCCESS';
export const GET_ACTIVITY_LOG_DETAILS_FAILURE = 'employee/GET_ACTIVITY_LOG_DETAILS_FAILURE';

export const GET_MEETING_ACTIVITY_LOG_DETAILS_REQUESTED = 'employee/GET_MEETING_ACTIVITY_LOG_DETAILS_REQUESTED';
export const GET_MEETING_ACTIVITY_LOG_DETAILS_SUCCESS = 'employee/GET_MEETING_ACTIVITY_LOG_DETAILS_SUCCESS';
export const GET_MEETING_ACTIVITY_LOG_DETAILS_FAILURE = 'employee/GET_MEETING_ACTIVITY_LOG_DETAILS_FAILURE';

export const GET_MEETING_STATUS_REPORT_DETAILS_REQUESTED = 'employee/GET_MEETING_STATUS_REPORT_DETAILS_REQUESTED';
export const GET_MEETING_STATUS_REPORT_DETAILS_SUCCESS = 'employee/GET_MEETING_STATUS_REPORT_DETAILS_SUCCESS';
export const GET_MEETING_STATUS_REPORT_DETAILS_FAILURE = 'employee/GET_MEETING_STATUS_REPORT_DETAILS_FAILURE';

export const GET_DOCUSIGN_STATUS_REQUESTED = 'employee/GET_DOCUSIGN_STATUS_REQUESTED';
export const GET_DOCUSIGN_STATUS_SUCCESS = 'employee/GET_DOCUSIGN_STATUS_SUCCESS';
export const GET_DOCUSIGN_STATUS_FAILURE = 'employee/GET_DOCUSIGN_STATUS_FAILURE';

export const GET_COMMITTEE_MEMBERS_REQUESTED = 'employee/GET_COMMITTEE_MEMBERS_REQUESTED';
export const GET_COMMITTEE_MEMBERS_SUCCESS = 'employee/GET_COMMITTEE_MEMBERS_SUCCESS';
export const GET_COMMITTEE_MEMBERS_FAILURE = 'employee/GET_COMMITTEE_MEMBERS_FAILURE';

export const GET_COMMITTEE_STUDENTS_REQUESTED = 'employee/GET_COMMITTEE_STUDENTS_REQUESTED';
export const GET_COMMITTEE_STUDENTS_SUCCESS = 'employee/GET_COMMITTEE_STUDENTS_SUCCESS';
export const GET_COMMITTEE_STUDENTS_FAILURE = 'employee/GET_COMMITTEE_STUDENTS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  committeeLoading: false,
  meetingLoading: false,
  feedbackLoading: false,
  studentLoading: false,
  employeeList: null,
  employeeAddress: {},
  activeEmployeeBasicInfo: null,
  employeeProfile: null,
  documentList: null,
  documentTemplates: null,
  bankDetails: null,
  hrSection: null,
  salaryDetails: { },
  passportList: null,
  smsSection: {},
  emails: null,
  getAttendanceDetails: null,
  timeTableScheduleList: null,
  timeTableDetails: null,
  timeTableStudentsList: null,
  employeeNameList: null,
  mailAlertList: null,
  onDemandAlertList: null,
  employeeTermsAndConditionDetails: null,
  commiteeDropdown: null,
  committeMettingList: null,
  facultyList: null,
  studentFacultyList: null,
  getChairPersonList: null,
  getFeedbackDetails: null,
  getParticipants: null,
  getFeedbackData: null,
  meetingRecording: null,
  getMeetingActivityLogData: null,
  getMeetingStatusReportData: null,
  getDocusignStatusData: null,
  getMembersList: null,
  getStudentsList: null,
  getMeetingDetails: null,
  getMomDocsList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_EMPLOYEE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_EMPLOYEE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ADD_EMPLOYEE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case SEND_CRED_MAIL_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case SEND_CRED_MAIL_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case SEND_CRED_MAIL_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      employeeList: action.result
    };
  }
  case GET_EMPLOYEE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_ADDRESS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      employeeAddress: {},
    };
  }
  case GET_EMPLOYEE_ADDRESS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      employeeAddress: action.result
    };
  }
  case GET_EMPLOYEE_ADDRESS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case GET_EMPLOYEE_PROFILE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_PROFILE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      employeeProfile: action.result
    };
  }
  case GET_EMPLOYEE_PROFILE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_DOCUMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_DOCUMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      documentList: action.result
    };
  }
  case GET_DOCUMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_DOCUMENT_TEMPLATES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_DOCUMENT_TEMPLATES_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      documentTemplates: action.result
    };
  }
  case GET_DOCUMENT_TEMPLATES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_BANK_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_BANK_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      bankDetails: action.result
    };
  }
  case GET_EMPLOYEE_BANK_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_HRSECTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_HRSECTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      hrSection: action.result
    };
  }
  case GET_EMPLOYEE_HRSECTION_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_SALARY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_SALARY_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      salaryDetails: action.result
    };
  }
  case GET_EMPLOYEE_SALARY_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_PASSPORT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_PASSPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      passportList: action.result
    };
  }
  case GET_EMPLOYEE_PASSPORT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_BASIC_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_BASIC_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      activeEmployeeBasicInfo: action.result.employeeDetails,
    };
  }
  case GET_BASIC_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      activeEmployeeBasicInfo: {},
    };
  }
  case UPLOAD_PHOTO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case UPLOAD_PHOTO_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case UPLOAD_PHOTO_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case GET_EMPLOYEE_SMS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_EMPLOYEE_SMS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      smsSection: action.result,
    };
  }
  case GET_EMPLOYEE_SMS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case GET_EMPLOYEE_EMAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      emails: action.result
    };
  }
  case GET_EMPLOYEE_EMAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_EMAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }

  case GET_TIMETABLE_SCHEDULE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_TIMETABLE_SCHEDULE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      timeTableScheduleList: action.result && action.result.data,
    };
  }
  case GET_TIMETABLE_SCHEDULE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }

  case GET_TIMETABLE_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_TIMETABLE_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      timeTableDetails: action.result && action.result.data,
    };
  }
  case GET_TIMETABLE_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }

  case GET_TIMETABLE_STUDENTS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_TIMETABLE_STUDENTS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      timeTableStudentsList: action.result && action.result.data
    };
  }
  case GET_TIMETABLE_STUDENTS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case GET_EMPLOYEE_NAME_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_EMPLOYEE_NAME_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      employeeNameList: action.result && action.result.data
    };
  }
  case GET_EMPLOYEE_NAME_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
    };
  }
  case GET_EMPLOYEE_MAIL_ALERT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_EMPLOYEE_MAIL_ALERT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      mailAlertList: action.result && action.result.data
    };
  }
  case GET_EMPLOYEE_MAIL_ALERT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
    };
  }
  case GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      onDemandAlertList: action.result && action.result.data
    };
  }
  case GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
    };
  }
  case GET_EMPLOYEE_TERMS_AND_CONDITIONS_STATUS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      employeeTermsAndConditionDetails: action.result
    };
  }
  case GET_COMMITEE_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_COMMITEE_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getMomDocsList: null,
      commiteeDropdown: action.result && action.result.data
    };
  }
  case GET_COMMITEE_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
    };
  }
  case COMMITTE_METTING_LIST_REQUESTED: {
    return {
      ...state,
      meetingLoading: true,
      loaded: false,
      error: false,
    };
  }
  case COMMITTE_METTING_LIST_SUCCESS: {
    return {
      ...state,
      meetingLoading: false,
      loaded: true,
      error: false,
      committeMettingList: action.result && action.result.data
    };
  }
  case COMMITTE_METTING_LIST_FAILURE: {
    return {
      ...state,
      meetingLoading: false,
      loaded: false,
      error: true,
      committeMettingList: null,
    };
  }
  case COMMITTE_MEETING_BY_ID_REQUESTED: {
    return {
      ...state,
      committeeLoading: true,
      loaded: false,
    };
  }
  case COMMITTE_MEETING_BY_ID_SUCCESS: {
    return {
      ...state,
      committeeLoading: false,
      loaded: true,
      error: false,
      committeMeetingById: action.result && action.result.data
    };
  }
  case COMMITTE_MEETING_BY_ID_FAILURE: {
    return {
      ...state,
      committeeLoading: false,
      error: true,
      loaded: true,
      committeMeetingById: null,
    };
  }
  case GET_ACTIVE_FACULTY_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      facultyList: action.result && action.result.data
    };
  }
  case GET_ACTIVE_STUDENT_FACULTY_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentFacultyList: action && action.result
    };
  }
  case GET_COMMITEE_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getChairPersonList: action && action.result
    };
  }
  case GET_COMMITEE_FEEDBACK_REQUESTED: {
    return {
      ...state,
      feedbackLoading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_COMMITEE_FEEDBACK: {
    return {
      ...state,
      feedbackLoading: false,
      loaded: true,
      error: false,
      getFeedbackDetails: action && action.result
    };
  }
  case GET_COMMITEE_FEEDBACK_FAILURE: {
    return {
      ...state,
      feedbackLoading: false,
      loaded: false,
      error: true,
      getFeedbackDetails: null,
    };
  }
  case GET_PARTICIPANTS_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getParticipants: action && action.result
    };
  }
  case GET_FEEDBACK_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getFeedbackData: action && action.result
    };
  }

  case GET_MEETING_RECORDING_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }

  case GET_MEETING_RECORDING: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      meetingRecording: action && action.result
    };
  }
  case GET_MEETING_RECORDING_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      meetingRecording: null,
    };
  }
  case GET_ACTIVITY_LOG_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ACTIVITY_LOG_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getActivityLogData: action.result && action.result.data
    };
  }
  case GET_ACTIVITY_LOG_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getActivityLogData: null,
    };
  }
  case GET_MEETING_ACTIVITY_LOG_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MEETING_ACTIVITY_LOG_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getMeetingActivityLogData: action.result && action.result.data
    };
  }
  case GET_MEETING_ACTIVITY_LOG_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getMeetingActivityLogData: null,
    };
  }
  case GET_MEETING_STATUS_REPORT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MEETING_STATUS_REPORT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getMeetingStatusReportData: action.result && action.result.data
    };
  }
  case GET_MEETING_STATUS_REPORT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getMeetingStatusReportData: null,
    };
  }
  case GET_DOCUSIGN_STATUS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_DOCUSIGN_STATUS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getDocusignStatusData: action.result && action.result.data
    };
  }
  case GET_DOCUSIGN_STATUS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getDocusignStatusData: null,
    };
  }
  case GET_COMMITTEE_MEMBERS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_COMMITTEE_MEMBERS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getMembersList: action.result && action.result.data
    };
  }
  case GET_COMMITTEE_MEMBERS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getMembersList: null,
    };
  }
  case GET_COMMITTEE_STUDENTS_REQUESTED: {
    return {
      ...state,
      studentLoading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_COMMITTEE_STUDENTS_SUCCESS: {
    return {
      ...state,
      studentLoading: false,
      loaded: true,
      error: false,
      getStudentsList: action.result && action.result.data
    };
  }
  case GET_COMMITTEE_STUDENTS_FAILURE: {
    return {
      ...state,
      studentLoading: false,
      loaded: false,
      error: true,
      getStudentsList: null,
    };
  }
  case GET_MEETING_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getMeetingDetails: action.result && action.result.data,
    };
  }
  case GET_ATTENDANCE_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getAttendanceDetails: action.result && action.result.data,
    };
  }
  case GET_MOM_DOCUMENTS_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getMomDocsList: action.result && action.result.data,
    };
  }
  default:
    return state;
  }
};

export const addEmployee = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeeAdd', { data, isMultiPart: true })
  };
};

export const getEmployeeList = (params) => {
  return {
    types: [GET_EMPLOYEE_LIST_REQUESTED, GET_EMPLOYEE_LIST_SUCCESS, GET_EMPLOYEE_LIST_FAILURE],
    promise: (client) => client.get(`employeeList/?${params}`)
  };
};

export const getEmployeeAddress = (data) => {
  return {
    types: [GET_EMPLOYEE_ADDRESS_REQUESTED, GET_EMPLOYEE_ADDRESS_SUCCESS, GET_EMPLOYEE_ADDRESS_FAILURE],
    promise: (client) => client.get(`employeeGetAddress/${data}`)
  };
};

export const getEmployeeProfile = (data) => {
  return {
    types: [GET_EMPLOYEE_PROFILE_REQUESTED, GET_EMPLOYEE_PROFILE_SUCCESS, GET_EMPLOYEE_PROFILE_FAILURE],
    promise: (client) => client.get(`employeeGetProfile/${data}`)
  };
};

export const updateEmployeeProfile = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeeUpdateProfile', { data })
  };
};

export const updateEmployeeAddress = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeeUpdateAddress', { data })
  };
};

export const addEmployeeDocument = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeeDocumentAdd', { data, isMultiPart: true })
  };
};

export const deleteEmployeeDocument = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.del(`employeeDocumentDelete/?employeeId=${data.employeeId}&id=${data.id}`)
  };
};

export const getEmployeeDocuments = (data) => {
  return {
    types: [GET_DOCUMENT_LIST_REQUESTED, GET_DOCUMENT_LIST_SUCCESS, GET_DOCUMENT_LIST_FAILURE],
    promise: (client) => client.get('employeeDocumentList', { params: { ...data, isActive: data.isActive || '' } })
  };
};

export const getDocumentTemplates = () => {
  return {
    types: [GET_DOCUMENT_TEMPLATES_REQUESTED, GET_DOCUMENT_TEMPLATES_SUCCESS, GET_DOCUMENT_TEMPLATES_FAILURE],
    promise: (client) => client.get('employeeDocumentTemplates')
  };
};

export const getEmployeeBankDetails = (data) => {
  return {
    types: [GET_EMPLOYEE_BANK_DETAILS_REQUESTED, GET_EMPLOYEE_BANK_DETAILS_SUCCESS, GET_EMPLOYEE_BANK_DETAILS_FAILURE],
    promise: (client) => client.get(`employeeGetBankDetails/${data}`)
  };
};

export const editEmployeeBankDetails = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeeEditBankDetails', { data })
  };
};

export const getEmployeeHRSection = (data) => {
  return {
    types: [GET_EMPLOYEE_HRSECTION_REQUESTED, GET_EMPLOYEE_HRSECTION_SUCCESS, GET_EMPLOYEE_HRSECTION_FAILURE],
    promise: (client) => client.get(`employeeGetHRSection/${data}`)
  };
};

export const editEmployeeHRSection = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeeHRSectionAdd', { data })
  };
};

export const getEmployeeSalary = (data) => {
  return {
    types: [GET_EMPLOYEE_SALARY_REQUESTED, GET_EMPLOYEE_SALARY_SUCCESS, GET_EMPLOYEE_SALARY_FAILURE],
    promise: (client) => client.get(`employeeGetSalary/${data}`)
  };
};

export const editEmployeeSalary = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeeEditSalary', { data })
  };
};

export const getEmployeePassport = (data) => {
  return {
    types: [GET_EMPLOYEE_PASSPORT_REQUESTED, GET_EMPLOYEE_PASSPORT_SUCCESS, GET_EMPLOYEE_PASSPORT_FAILURE],
    promise: (client) => client.get(`employeeGetPassport/?id=${data.employeeID}&isActive=${data.isActive}`)
  };
};

export const addEmployeePassport = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('employeePassportAdd', { data, isMultiPart: true })
  };
};

export const deleteEmployeePassport = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.del(`employeePassportDelete/?id=${data}`)
  };
};

export const getEmployeeBasicInfo = (empId) => {
  return {
    types: [GET_BASIC_INFO_REQUESTED, GET_BASIC_INFO_SUCCESS, GET_BASIC_INFO_FAILURE],
    promise: (client) => client.get(`getEmployeeBasicInfo/${empId}`)
  };
};

export const uploadImage = (data, module) => {
  return {
    types: [UPLOAD_PHOTO_REQUESTED, UPLOAD_PHOTO_SUCCESS, UPLOAD_PHOTO_FAILURE],
    promise: (client) => client.post(`${module}UploadPhoto`, { data, isMultiPart: true }),
  };
};

export const getEmployeeSmsSection = (employeeId) => {
  return {
    types: [GET_EMPLOYEE_SMS_REQUESTED, GET_EMPLOYEE_SMS_SUCCESS, GET_EMPLOYEE_SMS_FAILURE],
    promise: (client) => client.get(`getEmployeeSmsSection/${employeeId}`)
  };
};

export const addEmployeeSmsSection = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('updateEmployeeSmsSection', { data })
  };
};
export const sendOfferLetterMail = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('sendOfferLetterMail', { params: data, data })
  };
};

export const sendCredentialMail = (employeeId) => {
  return {
    types: [SEND_CRED_MAIL_REQUESTED, SEND_CRED_MAIL_SUCCESS, SEND_CRED_MAIL_FAILURE],
    promise: (client) => client.get(`sendCredentialMail/${employeeId}`)
  };
};

export const getEmployeeEmails = (employeeId) => {
  return {
    types: [GET_EMPLOYEE_EMAILS_REQUESTED, GET_EMPLOYEE_EMAILS_SUCCESS, GET_EMPLOYEE_EMAILS_FAILURE],
    promise: (client) => client.get(`getEmployeeEmails/${employeeId}`)
  };
};

export const getTimeTableScheduleList = (facultyId, startDate) => {
  return {
    types: [GET_TIMETABLE_SCHEDULE_LIST_REQUESTED, GET_TIMETABLE_SCHEDULE_LIST_SUCCESS, GET_TIMETABLE_SCHEDULE_LIST_FAILURE],
    promise: (client) => client.get(`getTimeTableScheduleList/${facultyId}/${startDate}`)
  };
};

export const getTimeTableDetails = (slot) => {
  return {
    types: [GET_TIMETABLE_DETAILS_REQUESTED, GET_TIMETABLE_DETAILS_SUCCESS, GET_TIMETABLE_DETAILS_FAILURE],
    promise: (client) => client.get(`getTimeTableDetails/${slot}`)
  };
};

export const getTimeTableStudents = (params) => {
  return {
    types: [GET_TIMETABLE_STUDENTS_LIST_REQUESTED, GET_TIMETABLE_STUDENTS_LIST_SUCCESS, GET_TIMETABLE_STUDENTS_LIST_FAILURE],
    promise: (client) => client.get('getTimeTableStudents', { params })
  };
};

export const updateTimeTable = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('updateTimeTable', { data })
  };
};

export const getEmployeeNameList = () => {
  return {
    types: [GET_EMPLOYEE_NAME_LIST_REQUESTED, GET_EMPLOYEE_NAME_LIST_SUCCESS, GET_EMPLOYEE_NAME_LIST_FAILURE],
    promise: (client) => client.get('employeeNamesList')
  };
};

export const getEmployeeMailAlertList = (empId) => {
  return {
    types: [GET_EMPLOYEE_MAIL_ALERT_LIST_REQUESTED, GET_EMPLOYEE_MAIL_ALERT_LIST_SUCCESS, GET_EMPLOYEE_MAIL_ALERT_LIST_FAILURE],
    promise: (client) => client.get(`mailAlertList/${empId}`)
  };
};

export const getEmployeeOnDemandAlertList = (empId) => {
  return {
    types: [GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_REQUESTED, GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_SUCCESS, GET_COMMITEE_DROPDOWN_FAILURE],
    promise: (client) => client.get(`onDemandAlert/${empId}`)
  };
};

export const updateConfiguration = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('updateConfig', { data })
  };
};

export const getCommiteeDropdown = (params) => {
  return {
    types: [GET_COMMITEE_DROPDOWN_REQUESTED, GET_COMMITEE_DROPDOWN_SUCCESS, GET_EMPLOYEE_ON_DEMAND_ALERT_LIST_FAILURE],
    promise: (client) => client.get('getCommiteeDropdown', { params })
  };
};

export const getEmployeeTermsAndConditionsStatus = (params) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_EMPLOYEE_TERMS_AND_CONDITIONS_STATUS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get(`getEmployeeTermsAndConditionStatus/${params}`)
  };
};
export const searchCommitteMeeting = (params) => {
  return {
    types: [COMMITTE_METTING_LIST_REQUESTED, COMMITTE_METTING_LIST_SUCCESS, COMMITTE_METTING_LIST_FAILURE],
    promise: (client) => client.get('searchCommitteMeeting', { params })
  };
};
export const addCommitteMeeting = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('addCommitteMeeting', { data, isMultiPart: true })
  };
};

export const getCommitteMeeting = (id) => {
  return {
    types: [COMMITTE_MEETING_BY_ID_REQUESTED, COMMITTE_MEETING_BY_ID_SUCCESS, COMMITTE_MEETING_BY_ID_FAILURE],
    promise: (client) => client.get(`getCommitteMeeting/${id}`)
  };
};

export const signMeetingMom = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('verifyMom', { data })
  };
};

export const documentNewDownlaodEmployee = (params) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get('employeeDocumentDownload', { params })
  };
};

export const getActiveFacultyList = () => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_ACTIVE_FACULTY_LIST, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get('getEmployeeNamesList')
  };
};

export const getActiveStudentFacultyList = () => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_ACTIVE_STUDENT_FACULTY_LIST, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get('getStudentNamesList')
  };
};

export const getChairPersons = () => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_COMMITEE_LIST, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get('getChairPersons')
  };
};

export const postFeedback = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('postCommitteeFeedback', { data })
  };
};

export const getFeedback = (params) => {
  return {
    types: [GET_COMMITEE_FEEDBACK_REQUESTED, GET_COMMITEE_FEEDBACK, GET_COMMITEE_FEEDBACK_FAILURE],
    promise: (client) => client.get('getFeedback', { params })
  };
};

export const getParticipantsDetails = (id, data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_PARTICIPANTS_DETAILS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get(`getParticipantsDetails/${id}?Record Id=${data}`)
  };
};

export const updateCommitteeMeeting = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('updateCommitteeMeeting', { data, isMultiPart: true })
  };
};

export const getFeedbackById = (id) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_FEEDBACK_DETAILS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get(`getFeedbackById/${id}`,)
  };
};

export const getMeetingRecording = (id, data) => {
  return {
    types: [GET_MEETING_RECORDING_REQUESTED, GET_MEETING_RECORDING, GET_MEETING_RECORDING_FAILURE],
    promise: (client) => client.get(`getMeetingRecording/${id}?Record Id=${data}`)
  };
};

export const getCommiteeActivityLog = (data) => {
  return {
    types: [GET_ACTIVITY_LOG_DETAILS_REQUESTED, GET_ACTIVITY_LOG_DETAILS_SUCCESS, GET_ACTIVITY_LOG_DETAILS_FAILURE],
    promise: (client) => client.get('getCommiteeActivityLog', { params: data })
  };
};

export const getCommiteeMeetingActivityLog = (data) => {
  return {
    types: [GET_MEETING_ACTIVITY_LOG_DETAILS_REQUESTED, GET_MEETING_ACTIVITY_LOG_DETAILS_SUCCESS, GET_MEETING_ACTIVITY_LOG_DETAILS_FAILURE],
    promise: (client) => client.get('getCommiteeMeetingActivityLog', { params: data })
  };
};

export const getCommiteeMeetingStatusReport = (data) => {
  return {
    types: [GET_MEETING_STATUS_REPORT_DETAILS_REQUESTED, GET_MEETING_STATUS_REPORT_DETAILS_SUCCESS, GET_MEETING_STATUS_REPORT_DETAILS_FAILURE],
    promise: (client) => client.get('commiteeStatusReport', { params: data })
  };
};

export const getDocusignSignStatus = (data) => {
  return {
    types: [GET_DOCUSIGN_STATUS_REQUESTED, GET_DOCUSIGN_STATUS_SUCCESS, GET_DOCUSIGN_STATUS_FAILURE],
    promise: (client) => client.get('getDocusignSignStatus', { params: data })
  };
};

export const getCommiteeMembers = (data) => {
  return {
    types: [GET_COMMITTEE_MEMBERS_REQUESTED, GET_COMMITTEE_MEMBERS_SUCCESS, GET_COMMITTEE_MEMBERS_FAILURE],
    promise: (client) => client.get('getCommiteeMembers', { params: data })
  };
};

export const getCommitteeStudents = () => {
  return {
    types: [GET_COMMITTEE_STUDENTS_REQUESTED, GET_COMMITTEE_STUDENTS_SUCCESS, GET_COMMITTEE_STUDENTS_FAILURE],
    promise: (client) => client.get('getCommitteeStudents')
  };
};

export const getMeetingDetails = (id, data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_MEETING_DETAILS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get(`getMeetingDetails/${id}?Record Id=${data}`)
  };
};

export const getCommitteeAttendanceList = (params) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_ATTENDANCE_LIST, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get('committeeAttendanceList', { params })
  };
};

export const updateCommitteeAttendance = (data) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.post('updateCommitteeAttendance', { data })
  };
};

export const getMomDocumentList = (params) => {
  return {
    types: [ADD_EMPLOYEE_REQUESTED, GET_MOM_DOCUMENTS_LIST, ADD_EMPLOYEE_FAILURE],
    promise: (client) => client.get('getMomDocumentList', { params })
  };
};
