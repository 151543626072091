export const ADD_ACTION_REQUESTED = 'mailLog/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'mailLog/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'mailLog/ADD_ACTION_FAILURE';

export const DEPAERTMENT_DROPDOWN_SUCCESS = 'maillog/DEPAERTMENT_DROPDOWN_SUCCESS';

export const SEARCH_INCOMING_MAILLOG_SUCCESS = 'maillog/SEARCH_INCOMING_MAILLOG_SUCCESS';

export const GET_ENTERED_DROPDOWN_DATA = 'maillog/GET_ENTERED_DROPDOWN_DATA';

export const GET_OUTGOING_MAILLOG_LIST_SEARCH = 'maillog/GET_OUTGOING_MAILLOG_LIST_SEARCH';

export const GET_ARUBA_IN_MAILLOG_LIST_SEARCH = 'maillog/GET_ARUBA_IN_MAILLOG_LIST_SEARCH';

export const GET_ARUBA_IN_MAILLOG_DETAILS_BY_ID = 'maillog/GET_ARUBA_IN_MAILLOG_DETAILS_BY_ID';

export const GET_INCOMING_MAILLOG_DETAILS_BY_ID = 'maillog/GET_INCOMING_MAILLOG_DETAILS_BY_ID';

export const GET_ARUBA_OUT_MAILLOG_DETAILS_BY_ID = 'maillog/GET_ARUBA_OUT_MAILLOG_DETAILS_BY_ID';

export const GET_OUT_MAILLOG_DETAILS_BY_ID = 'maillog/GET_OUT_MAILLOG_DETAILS_BY_ID';

export const GET_ARUBA_OUT_MAILLOG_LIST = 'maillog/GET_ARUBA_OUT_MAILLOG_LIST';

export const GET_OUT_MAIL_TYEP_DETAILS_BY_ID = 'maillog/GET_OUT_MAIL_TYEP_DETAILS_BY_ID';

export const ADD_ACTION_LOADING_REQUESTED = 'maillog/ADD_ACTION_LOADING_REQUESTED';

export const ADD_ACTION_LOADING_FAILURE = 'maillog/ADD_ACTION_LOADING_FAILURE';

export const GET_RETURNED_MAIL_LOG = 'ls/GET_RETURNED_MAIL_LOG';
export const GET_RETURNED_MAIL_LOG_BY_ID = 'ls/GET_RETURNED_MAIL_LOG_BY_ID';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  loadingFlag: false,
  dropdownlist: null,
  enteredByDropdowndata: null,
  searchIncomingMailLog: null,
  getOutgoingLogList: null,
  getIncomingLogList: null,
  getArubaIncomingLogById: null,
  getIncomingLogById: null,
  getArubaIncomingLogList: null,
  getArubaOutgoingLogById: null,
  getArubaOutgoingLogList: null,
  getArubaOutgoingDetails: null,
  returnedMailLogList: null,
  returnedMilLogById: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case DEPAERTMENT_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      dropdownlist: action.result
    };
  }
  case ADD_ACTION_LOADING_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: true,
      error: true
    };
  }
  case ADD_ACTION_LOADING_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true
    };
  }
  case SEARCH_INCOMING_MAILLOG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      searchIncomingMailLog: action.result,
    };
  }
  case GET_ENTERED_DROPDOWN_DATA: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      enteredByDropdowndata: action.result,
    };
  }
  case GET_OUTGOING_MAILLOG_LIST_SEARCH: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getOutgoingLogList: action.result
    };
  }

  case GET_ARUBA_IN_MAILLOG_LIST_SEARCH: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getIncomingLogList: action.result,
    };
  }

  case GET_ARUBA_IN_MAILLOG_DETAILS_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getArubaIncomingLogById: action.result,
    };
  }
  case GET_ARUBA_OUT_MAILLOG_DETAILS_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getArubaOutgoingDetails: action.result,
    };
  }

  case GET_OUT_MAILLOG_DETAILS_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getArubaOutgoingLogById: action.result,
    };
  }

  case GET_ARUBA_OUT_MAILLOG_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getArubaOutgoingLogList: action.result,
    };
  }

  case GET_INCOMING_MAILLOG_DETAILS_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getIncomingLogById: action.result,
    };
  }
  case GET_OUT_MAIL_TYEP_DETAILS_BY_ID: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      getOutgoingTypeDetails: action.result,
    };
  }
  case GET_RETURNED_MAIL_LOG: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      returnedMailLogList: action.result
    };
  }
  case GET_RETURNED_MAIL_LOG_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      returnedMilLogById: action.result,
    };
  }
  default: {
    return {
      ...state
    };
  }
  }
};

export const addIncomingMailLog = (data, params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('incomingMailLog', { data, params })
  };
};
export const departmentDropdown = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, DEPAERTMENT_DROPDOWN_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('departmentDropdown', { params })
  };
};
export const searchIncomingMailLog = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, SEARCH_INCOMING_MAILLOG_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('searchincomingMailLog', { params })
  };
};

export const enteredByDropdown = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ENTERED_DROPDOWN_DATA, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`enteredByDropdown/${data.isAruba}/${data.isIncoming}`)
  };
};

export const addOutgoingMailLog = (data, params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addOutgoingMailLog', { data, isMultiPart: true, params })
  };
};

export const getOutgoingList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_OUTGOING_MAILLOG_LIST_SEARCH, ADD_ACTION_FAILURE],
    promise: (client) => client.get('outMailLogSearch', { params })
  };
};

export const getOutgoingById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_OUT_MAILLOG_DETAILS_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`outMailLogSearch/${id}`)
  };
};

export const addArubaIncomingMailLog = (data, params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('arubaIncomingMailLog', { data, params })
  };
};

export const getArubaIncomingList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ARUBA_IN_MAILLOG_LIST_SEARCH, ADD_ACTION_FAILURE],
    promise: (client) => client.get('arubaIncomingList', { params })
  };
};

export const getArubaIncomingById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ARUBA_IN_MAILLOG_DETAILS_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`arubaIncomingList/${id}`)
  };
};

export const getIncomingById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_INCOMING_MAILLOG_DETAILS_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`searchincomingMailLog/${id}`)
  };
};

export const addArubaOutgoingMailLog = (data, params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('arubaArubaOutingMailLog', { data, isMultiPart: true, params })
  };
};

export const getArubaOutgoingMailLogList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ARUBA_OUT_MAILLOG_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getArubaOutgoing', { params })
  };
};

export const getArubaOutgoingById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ARUBA_OUT_MAILLOG_DETAILS_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getArubaOutgoing/${id}`)
  };
};

export const getTypeOfOutgoing = (params) => {
  return {
    types: [ADD_ACTION_LOADING_REQUESTED, GET_OUT_MAIL_TYEP_DETAILS_BY_ID, ADD_ACTION_LOADING_FAILURE],
    promise: (client) => client.get('typeOfOutgoingDropdown', { params })
  };
};

export const deleteMailLog = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.del(`deleteMailLog/${params.id}/${params.isAruba}/${params.isIncoming}`)
  };
};

export const addReturnedMailLog = (data, params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addReturnedMailLog', { data, isMultiPart: true, params })
  };
};

export const getReturnedList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RETURNED_MAIL_LOG, ADD_ACTION_FAILURE],
    promise: (client) => client.get('returnedMailLogSearch', { params })
  };
};

export const getReturnedMailLogById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RETURNED_MAIL_LOG_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getReturnedMailLogById/${id}`)
  };
};

export const deleteReturnedMailLog = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`deleteReturnedMailLog/${id}`)
  };
};
