export const ADD_OR_UPDATE_REQUESTED = 'attendance/ADD_OR_UPDATE_REQUESTED';
export const ADD_OR_UPDATE_SUCCESS = 'attendance/ADD_OR_UPDATE_SUCCESS';
export const ADD_OR_UPDATE_FAILURE = 'attendance/ADD_OR_UPDATE_FAILURE';

export const GET_PUBLISHED_SCHEDULE_LIST_REQUESTED = 'student/GET_PUBLISHED_SCHEDULE_LIST_REQUESTED';
export const GET_PUBLISHED_SCHEDULE_LIST_SUCCESS = 'student/GET_PUBLISHED_SCHEDULE_LIST_SUCCESS';
export const GET_PUBLISHED_SCHEDULE_LIST_FAILURE = 'student/GET_PUBLISHED_SCHEDULE_LIST_FAILURE';

export const GET_HOLIDAY_LIST_REQUESTED = 'student/GET_HOLIDAY_LIST_REQUESTED';
export const GET_HOLIDAY_LIST_SUCCESS = 'student/GET_HOLIDAY_LIST_SUCCESS';
export const GET_HOLIDAY_LIST_FAILURE = 'student/GET_HOLIDAY_LIST_FAILURE';

export const GET_HOLIDAY_DATA_REQUESTED = 'student/GET_HOLIDAY_DATA_REQUESTED';
export const GET_HOLIDAY_DATA_SUCCESS = 'student/GET_HOLIDAY_DATA_SUCCESS';
export const GET_HOLIDAY_DATA_FAILURE = 'student/GET_HOLIDAY_DATA_FAILURE';

export const GET_SEMESTER_LIST_REQUESTED = 'attendance/GET_SEMESTER_LIST_REQUESTED';
export const GET_SEMESTER_LIST_SUCCESS = 'attendance/GET_SEMESTER_LIST_SUCCESS';
export const GET_SEMESTER_LIST_FAILURE = 'attendance/GET_SEMESTER_LIST_FAILURE';

export const GET_STAGE_LIST_REQUESTED = 'attendance/GET_STAGE_LIST_REQUESTED';
export const GET_STAGE_LIST_SUCCESS = 'attendance/GET_STAGE_LIST_SUCCESS';
export const GET_STAGE_LIST_FAILURE = 'attendance/GET_STAGE_LIST_FAILURE';

export const GET_SCHEDULE_DATE_REQUESTED = 'attendance/GET_SCHEDULE_DATE_REQUESTED';
export const GET_SCHEDULE_DATE_SUCCESS = 'attendance/GET_SCHEDULE_DATE_SUCCESS';
export const GET_SCHEDULE_DATE_FAILURE = 'attendance/GET_SCHEDULE_DATE_FAILURE';

export const GET_FACULTY_LIST_REQUESTED = 'attendance/GET_FACULTY_LIST_REQUESTED';
export const GET_FACULTY_LIST_SUCCESS = 'attendance/GET_FACULTY_LIST_SUCCESS';
export const GET_FACULTY_LIST_FAILURE = 'attendance/GET_FACULTY_LIST_FAILURE';

export const GET_CLASS_SUBJECT_REQUESTED = 'attendance/GET_CLASS_SUBJECT_REQUESTED';
export const GET_CLASS_SUBJECT_SUCCESS = 'attendance/GET_CLASS_SUBJECT_SUCCESS';
export const GET_CLASS_SUBJECT_FAILURE = 'attendance/GET_CLASS_SUBJECT_FAILURE';

export const GET_TOPIC_LIST_REQUESTED = 'attendance/GET_TOPIC_LIST_REQUESTED';
export const GET_TOPIC_LIST_SUCCESS = 'attendance/GET_TOPIC_LIST_SUCCESS';
export const GET_TOPIC_LIST_FAILURE = 'attendance/GET_TOPIC_LIST_FAILURE';

export const GET_WEEKLY_SCHEDULE_LIST_REQUESTED = 'attendance/GET_WEEKLY_SCHEDULE_LIST_REQUESTED';
export const GET_WEEKLY_SCHEDULE_LIST_SUCCESS = 'attendance/GET_WEEKLY_SCHEDULE_LIST_SUCCESS';
export const GET_WEEKLY_SCHEDULE_LIST_FAILURE = 'attendance/GET_WEEKLY_SCHEDULE_LIST_FAILURE';

export const GET_CLASS_NAME_REQUESTED = 'attendance/GET_CLASS_NAME_REQUESTED';
export const GET_CLASS_NAME_SUCCESS = 'attendance/GET_CLASS_NAME_SUCCESS';
export const GET_CLASS_NAME_FAILURE = 'attendance/GET_CLASS_NAME_FAILURE';

export const GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_REQUESTED = 'attendance/GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_REQUESTED';
export const GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_SUCCESS = 'attendance/GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_SUCCESS';
export const GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_FAILURE = 'attendance/GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_FAILURE';

export const GET_STAGES_SCHEDULE_LIST_REQUESTED = 'attendance/GET_STAGES_SCHEDULE_LIST_REQUESTED';
export const GET_STAGES_SCHEDULE_LIST_SUCCESS = 'attendance/GET_STAGES_SCHEDULE_LIST_SUCCESS';
export const GET_STAGES_SCHEDULE_LIST_FAILURE = 'attendance/GET_STAGES_SCHEDULE_LIST_FAILURE';

export const GET_STAGES_SCHEDULE_REQUESTED = 'attendance/GET_STAGES_SCHEDULE_REQUESTED';
export const GET_STAGES_SCHEDULE_SUCCESS = 'attendance/GET_STAGES_SCHEDULE_SUCCESS';
export const GET_STAGES_SCHEDULE_FAILURE = 'attendance/GET_STAGES_SCHEDULE_FAILURE';

export const GET_SLOT_LIST_REQUESTED = 'attendance/GET_SLOT_LIST_REQUESTED';
export const GET_SLOT_LIST_SUCCESS = 'attendance/GET_SLOT_LIST_SUCCESS';
export const GET_SLOT_LIST_FAILURE = 'attendance/GET_SLOT_LIST_FAILURE';

export const GET_SEMESTER_SCHEDULE_LIST_REQUESTED = 'attendance/GET_SEMESTER_SCHEDULE_LIST_REQUESTED';
export const GET_SEMESTER_SCHEDULE_LIST_SUCCESS = 'attendance/GET_SEMESTER_SCHEDULE_LIST_SUCCESS';
export const GET_SEMESTER_SCHEDULE_LIST_FAILURE = 'attendance/GET_SEMESTER_SCHEDULE_LIST_FAILURE';

export const GET_SEMESTER_SCHEDULE_VIEW_LIST_REQUESTED = 'attendance/GET_SEMESTER_SCHEDULE_VIEW_LIST_REQUESTED';
export const GET_SEMESTER_SCHEDULE_VIEW_LIST_SUCCESS = 'attendance/GET_SEMESTER_SCHEDULE_VIEW_LIST_SUCCESS';
export const GET_SEMESTER_SCHEDULE_VIEW_LIST_FAILURE = 'attendance/GET_SEMESTER_SCHEDULE_VIEW_LIST_FAILURE';

export const GET_SEMESTER_SCHEDULE_REQUESTED = 'attendance/GET_SEMESTER_SCHEDULE_REQUESTED';
export const GET_SEMESTER_SCHEDULE_SUCCESS = 'attendance/GET_SEMESTER_SCHEDULE_SUCCESS';
export const GET_SEMESTER_SCHEDULE_FAILURE = 'attendance/GET_SEMESTER_SCHEDULE_FAILURE';

export const GET_SEMESTER_STAGE_LIST_REQUESTED = 'attendance/GET_SEMESTER_STAGE_LIST_REQUESTED';
export const GET_SEMESTER_STAGE_LIST_SUCCESS = 'attendance/GET_SEMESTER_STAGE_LIST_SUCCESS';
export const GET_SEMESTER_STAGE_LIST_FAILURE = 'attendance/GET_SEMESTER_STAGE_LIST_FAILURE';

export const GET_PAST_ATTENDANCE_LIST_REQUESTED = 'attendance/GET_PAST_ATTENDANCE_LIST_REQUESTED';
export const GET_PAST_ATTENDANCE_LIST_SUCCESS = 'attendance/GET_PAST_ATTENDANCE_LIST_SUCCESS';
export const GET_PAST_ATTENDANCE_LIST_FAILURE = 'attendance/GET_PAST_ATTENDANCE_LIST_FAILURE';

export const GET_SEMESTER_STAGE_DAYS_REQUESTED = 'attendance/GET_SEMESTER_STAGE_DAYS_REQUESTED';
export const GET_SEMESTER_STAGE_DAYS_SUCCESS = 'attendance/GET_SEMESTER_STAGE_DAYS_SUCCESS';
export const GET_SEMESTER_STAGE_DAYS_FAILURE = 'attendance/GET_SEMESTER_STAGE_DAYS_FAILURE';
export const WEEKLY_SCHDULE_LIST_EMPTY = 'attendance/WEEKLY_SCHDULE_LIST_EMPTY';

export const GET_WEEKLY_SCHDULE_SEMESTER_DROPDOWN_SUCCESS = 'attendance/GET_WEEKLY_SCHDULE_SEMESTER_DROPDOWN_SUCCESS';

export const GET_TOTAL_CLASS_BY_SEMESTER = 'attendance/GET_TOTAL_CLASS_BY_SEMESTER';

export const GET_INDIVIDUAL_STUDENT_LIST_REPORT_SUCCESS = 'attendance/GET_INDIVIDUAL_STUDENT_LIST_REPORT_SUCCESS';

export const GET_FACULTY_LIST_REPORT_SUCCESS = 'attendance/GET_FACULTY_LIST_REPORT_SUCCESS';

export const GET_MULTI_STUDENT_LIST_REPORT_SUCCESS = 'attendance/GET_MULTI_STUDENT_LIST_REPORT_SUCCESS';

export const GET_ATTENDANCE_CLASSLIST_SUCCESS = 'attendance/GET_ATTENDANCE_CLASSLIST_SUCCESS';

export const GET_ATTENDANCE_REPORT_COURSE_LIST_SUCCESS = 'attendance/GET_ATTENDANCE_REPORT_COURSE_LIST_SUCCESS';

export const GET_ATTENDANCE_REPORT_SUBJECT_LIST_SUCCESS = 'attendance/GET_ATTENDANCE_REPORT_SUBJECT_LIST_SUCCESS';

export const GET_TOTAL_SUBJECT_LIST_SUCCESS = 'attendance/GET_TOTAL_SUBJECT_LIST_SUCCESS';

export const GET_ATTENDANCE_REPORT_TOPICS_LIST_SUCCESS = 'attendance/GET_ATTENDANCE_REPORT_TOPICS_LIST_SUCCESS';

export const GET_TOTAL_TOPIC_LIST_SUCCESS = 'attendance/GET_TOTAL_TOPIC_LIST_SUCCESS';

const GET_STUDENT_TIME_TABLE_LIST_REQUESTED = 'attendance/GET_STUDENT_TIME_TABLE_LIST_REQUESTED';
export const GET_STUDENT_TIME_TABLE_LIST_SUCCESS = 'attendance/GET_STUDENT_TIME_TABLE_LIST_SUCCESS';
const GET_STUDENT_TIME_TABLE_LIST_FAILURE = 'attendance/GET_STUDENT_TIME_TABLE_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  loadingFlag: false,
  loadingAtt: false,
  publishScheduleList: null,
  holidayList: null,
  holidayData: null,
  weeklySchedulelist: null,
  semesterList: [],
  stageList: [],
  scheduleDates: [],
  classSubjectList: [],
  topicList: [],
  classNamesList: [],
  stagesScheduleDaysCount: null,
  stagesScheduleList: null,
  stagesSchedule: null,
  slotList: null,
  semesterScheduleList: null,
  semesterScheduleViewList: null,
  semesterScheduleData: null,
  semesterStageList: null,
  pastAttendanceList: null,
  facultyList: null,
  semesterStageDays: null,
  semesterWeeklyDropdownList: null,
  totalClassSemester: null,
  individualStudent: null,
  facultyByName: null,
  multiStudentReport: null,
  classList: null,
  courseList: null,
  subjectList: null,
  totalSubjectList: null,
  studentTimeTableList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_OR_UPDATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_OR_UPDATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ADD_OR_UPDATE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_PUBLISHED_SCHEDULE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_PUBLISHED_SCHEDULE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      publishScheduleList: action.result && action.result.data
    };
  }

  case GET_PUBLISHED_SCHEDULE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_HOLIDAY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_HOLIDAY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      holidayList: action.result && action.result.data,
    };
  }

  case GET_HOLIDAY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_HOLIDAY_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_HOLIDAY_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      holidayData: action.result && action.result.data,
    };
  }

  case GET_HOLIDAY_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      stagesScheduleDaysCount: action.result && action.result.data
    };
  }
  case GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_STAGES_SCHEDULE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STAGES_SCHEDULE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      stagesScheduleList: action.result && action.result.data
    };
  }
  case GET_STAGES_SCHEDULE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_STAGES_SCHEDULE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STAGES_SCHEDULE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      stagesSchedule: action.result && action.result.data
    };
  }
  case GET_STAGES_SCHEDULE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_SLOT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_SLOT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      slotList: action.result && action.result.data
    };
  }
  case GET_SLOT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_SEMESTER_SCHEDULE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_SEMESTER_SCHEDULE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      semesterScheduleList: action.result
    };
  }
  case GET_SEMESTER_SCHEDULE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_SEMESTER_SCHEDULE_VIEW_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false
    };
  }
  case GET_SEMESTER_SCHEDULE_VIEW_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      error: false,
      loaded: true,
      semesterScheduleViewList: action.result
    };
  }
  case GET_SEMESTER_SCHEDULE_VIEW_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      error: true,
      loaded: true
    };
  }
  case GET_SEMESTER_SCHEDULE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_SEMESTER_SCHEDULE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      semesterScheduleData: action.result
    };
  }
  case GET_SEMESTER_SCHEDULE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_SEMESTER_STAGE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_SEMESTER_STAGE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      semesterStageList: action.result
    };
  }
  case GET_SEMESTER_STAGE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_SEMESTER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_PAST_ATTENDANCE_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false
    };
  }
  case GET_PAST_ATTENDANCE_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      error: false,
      loaded: true,
      pastAttendanceList: action.result
    };
  }
  case GET_PAST_ATTENDANCE_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      error: true,
      loaded: true
    };
  }
  case GET_SEMESTER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterList: action.result && action.result.data
    };
  }
  case GET_SEMESTER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_STAGE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_STAGE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      stageList: action.result && action.result.data
    };
  }
  case GET_STAGE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_SCHEDULE_DATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_SCHEDULE_DATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      scheduleDates: action.result && action.result.data
    };
  }
  case GET_SCHEDULE_DATE_FAILURE: {
    return {
      ...state,
      scheduleDates: null,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_FACULTY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_FACULTY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      facultyList: action.result
    };
  }
  case GET_FACULTY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_SEMESTER_STAGE_DAYS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_SEMESTER_STAGE_DAYS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      semesterStageDays: action.result
    };
  }
  case GET_SEMESTER_STAGE_DAYS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLASS_SUBJECT_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_CLASS_SUBJECT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      classSubjectList: action.result && action.result.data,
    };
  }
  case GET_CLASS_SUBJECT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_TOPIC_LIST_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_TOPIC_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      topicList: action.result && action.result.data
    };
  }
  case GET_TOPIC_LIST_FAILURE: {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: true
    };
  }
  case GET_WEEKLY_SCHEDULE_LIST_REQUESTED: {
    return {
      ...state,
      loadingAtt: true,
    };
  }
  case GET_WEEKLY_SCHEDULE_LIST_SUCCESS: {
    return {
      ...state,
      loadingAtt: false,
      loaded: true,
      weeklySchedulelist: action.result && action.result.data
    };
  }
  case GET_WEEKLY_SCHEDULE_LIST_FAILURE: {
    return {
      ...state,
      loadingAtt: false,
      loaded: true,
      error: true
    };
  }
  case GET_CLASS_NAME_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_CLASS_NAME_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      classNamesList: action.result && action.result.data
    };
  }
  case GET_CLASS_NAME_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case WEEKLY_SCHDULE_LIST_EMPTY: {
    return {
      ...state,
      weeklySchedulelist: action.payload
    };
  }
  case GET_WEEKLY_SCHDULE_SEMESTER_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterWeeklyDropdownList: action.result && action.result.data
    };
  }
  case GET_TOTAL_CLASS_BY_SEMESTER: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      totalClassSemester: action.result && action.result.data
    };
  }

  case GET_INDIVIDUAL_STUDENT_LIST_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      individualStudent: action.result && action.result.data
    };
  }

  case GET_FACULTY_LIST_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      facultyByName: action.result && action.result.data
    };
  }
  case GET_MULTI_STUDENT_LIST_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      multiStudentReport: action.result && action.result.data
    };
  }
  case GET_ATTENDANCE_CLASSLIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      classList: action.result && action.result.data
    };
  }
  case GET_ATTENDANCE_REPORT_COURSE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      courseList: action.result && action.result.data
    };
  }
  case GET_ATTENDANCE_REPORT_SUBJECT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      subjectList: action.result && action.result.data
    };
  }
  case GET_ATTENDANCE_REPORT_TOPICS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      topicsList: action.result && action.result.data
    };
  }
  case GET_TOTAL_SUBJECT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      totalSubjectList: action.result && action.result.data,
    };
  }
  case GET_TOTAL_TOPIC_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      totalTopicList: action.result && action.result.data,
    };
  }

  case GET_STUDENT_TIME_TABLE_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false
    };
  }
  case GET_STUDENT_TIME_TABLE_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      studentTimeTableList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_TIME_TABLE_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true,
      studentTimeTableList: null
    };
  }

  default:
    return state;
  }
};

export const getPublishedScheduleList = () => {
  return {
    types: [GET_PUBLISHED_SCHEDULE_LIST_REQUESTED, GET_PUBLISHED_SCHEDULE_LIST_SUCCESS, GET_PUBLISHED_SCHEDULE_LIST_FAILURE],
    promise: (client) => client.get('publishedScheduledList')
  };
};

export const getHolidayList = (params) => {
  return {
    types: [GET_HOLIDAY_LIST_REQUESTED, GET_HOLIDAY_LIST_SUCCESS, GET_HOLIDAY_LIST_FAILURE],
    promise: (client) => client.get('holidayList', { params })
  };
};

export const addHoliday = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addHoliday', { data })
  };
};

export const getHolidayData = (id) => {
  return {
    types: [GET_HOLIDAY_DATA_REQUESTED, GET_HOLIDAY_DATA_SUCCESS, GET_HOLIDAY_DATA_FAILURE],
    promise: (client) => client.get(`getHolidayData/${id}`)
  };
};

export const getSemesterList = () => {
  return {
    types: [GET_SEMESTER_LIST_REQUESTED, GET_SEMESTER_LIST_SUCCESS, GET_SEMESTER_LIST_FAILURE],
    promise: (client) => client.get('semesterListAttendance')
  };
};

export const getStageList = () => {
  return {
    types: [GET_STAGE_LIST_REQUESTED, GET_STAGE_LIST_SUCCESS, GET_STAGE_LIST_FAILURE],
    promise: (client) => client.get('stageListAttendance')
  };
};

export const getScheduleDateList = (params) => {
  return {
    types: [GET_SCHEDULE_DATE_REQUESTED, GET_SCHEDULE_DATE_SUCCESS, GET_SCHEDULE_DATE_FAILURE],
    promise: (client) => client.get('scheduleDatesList', { params })
  };
};

export const saveWeeklySchdule = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('saveWeeklySchedule', { data })
  };
};

export const publishWeeklySchedule = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('publishWeeklySchedule', { data })
  };
};

export const getClassSubjectList = () => {
  return {
    types: [GET_CLASS_SUBJECT_REQUESTED, GET_CLASS_SUBJECT_SUCCESS, GET_CLASS_SUBJECT_FAILURE],
    promise: (client) => client.get('subjectList')
  };
};

export const getTopicList = () => {
  return {
    types: [GET_TOPIC_LIST_REQUESTED, GET_TOPIC_LIST_SUCCESS, GET_TOPIC_LIST_FAILURE],
    promise: (client) => client.get('topicList')
  };
};

export const getWeeklySchduleList = (params) => {
  return {
    types: [GET_WEEKLY_SCHEDULE_LIST_REQUESTED, GET_WEEKLY_SCHEDULE_LIST_SUCCESS, GET_WEEKLY_SCHEDULE_LIST_FAILURE],
    promise: (client) => client.get('weeklyScheduleList', { params })
  };
};

export const getClassNames = (params) => {
  return {
    types: [GET_CLASS_NAME_REQUESTED, GET_CLASS_NAME_SUCCESS, GET_CLASS_NAME_FAILURE],
    promise: (client) => client.get('getClassesListByStage', { params })
  };
};

export const updateClass = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('classUpdate', { params })
  };
};

export const addUpdateStageSchedule = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addUpdateStageSchedule', { data })
  };
};

export const getStageDaysCountList = () => {
  return {
    types: [GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_REQUESTED, GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_SUCCESS, GET_STAGES_SCHEDULE_DAYS_COUNT_LIST_FAILURE],
    promise: (client) => client.get('getStageDaysCountList')
  };
};

export const getStageScheduleList = (params) => {
  return {
    types: [GET_STAGES_SCHEDULE_LIST_REQUESTED, GET_STAGES_SCHEDULE_LIST_SUCCESS, GET_STAGES_SCHEDULE_LIST_FAILURE],
    promise: (client) => client.get('getStageScheduleList', { params })
  };
};

export const getStageSchedule = (id) => {
  return {
    types: [GET_STAGES_SCHEDULE_REQUESTED, GET_STAGES_SCHEDULE_SUCCESS, GET_STAGES_SCHEDULE_FAILURE],
    promise: (client) => client.get(`getStageSchedule/${id}`,)
  };
};

export const setWeeklySchduleListEmpty = (data) => {
  return (dispatch) => {
    dispatch({
      type: WEEKLY_SCHDULE_LIST_EMPTY,
      payload: data,
    });
  };
};

export const getSlotsList = (stageid) => {
  return {
    types: [GET_SLOT_LIST_REQUESTED, GET_SLOT_LIST_SUCCESS, GET_SLOT_LIST_FAILURE],
    promise: (client) => client.get(`getSlotsList/${stageid}/true`)
  };
};

export const getSemesterScheduleList = (params) => {
  return {
    types: [GET_SEMESTER_SCHEDULE_LIST_REQUESTED, GET_SEMESTER_SCHEDULE_LIST_SUCCESS, GET_SEMESTER_SCHEDULE_LIST_FAILURE],
    promise: (client) => client.get('semesterScheduleList', { params })
  };
};
export const getSemesterScheduleViewList = (params) => {
  return {
    types: [GET_SEMESTER_SCHEDULE_VIEW_LIST_REQUESTED, GET_SEMESTER_SCHEDULE_VIEW_LIST_SUCCESS, GET_SEMESTER_SCHEDULE_VIEW_LIST_FAILURE],
    promise: (client) => client.get('semesterScheduleView', { params })
  };
};
export const getSemesterScheduleById = (semesterScheduleId) => {
  return {
    types: [GET_SEMESTER_SCHEDULE_REQUESTED, GET_SEMESTER_SCHEDULE_SUCCESS, GET_SEMESTER_SCHEDULE_FAILURE],
    promise: (client) => client.get(`semesterScheduleGet/${semesterScheduleId}`)
  };
};
export const addSemesterSchedule = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('semesterScheduleAdd', { data })
  };
};
export const getSemesterScheduleStageList = () => {
  return {
    types: [GET_SEMESTER_STAGE_LIST_REQUESTED, GET_SEMESTER_STAGE_LIST_SUCCESS, GET_SEMESTER_STAGE_LIST_FAILURE],
    promise: (client) => client.get('semesterStageList')
  };
};
export const getPastAttendanceList = (params) => {
  return {
    types: [GET_PAST_ATTENDANCE_LIST_REQUESTED, GET_PAST_ATTENDANCE_LIST_SUCCESS, GET_PAST_ATTENDANCE_LIST_FAILURE],
    promise: (client) => client.get('pastAttendanceList', { params })
  };
};
export const getFacultyList = () => {
  return {
    types: [GET_FACULTY_LIST_REQUESTED, GET_FACULTY_LIST_SUCCESS, GET_FACULTY_LIST_FAILURE],
    promise: (client) => client.get('facultyList')
  };
};

export const getWeeklyFacultyList = () => {
  return {
    types: [GET_FACULTY_LIST_REQUESTED, GET_FACULTY_LIST_SUCCESS, GET_FACULTY_LIST_FAILURE],
    promise: (client) => client.get('weeklyFacultyList')
  };
};

export const addSemesterStageDays = (id) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post(`addSemesterStageDays/${id}`)
  };
};
export const getSemesterStageDays = (id) => {
  return {
    types: [GET_SEMESTER_STAGE_DAYS_REQUESTED, GET_SEMESTER_STAGE_DAYS_SUCCESS, GET_SEMESTER_STAGE_DAYS_FAILURE],
    promise: (client) => client.get(`getSemesterStageDays/${id}`)
  };
};

export const getWeeklySchduleSemesterDropDown = () => {
  return {
    types: [GET_SEMESTER_STAGE_DAYS_REQUESTED, GET_WEEKLY_SCHDULE_SEMESTER_DROPDOWN_SUCCESS, GET_SEMESTER_STAGE_DAYS_FAILURE],
    promise: (client) => client.get('weeklyScheduleSemesterDropDown')
  };
};

export const getTotalClassBySemester = (semester) => {
  return {
    types: [GET_SEMESTER_STAGE_DAYS_REQUESTED, GET_TOTAL_CLASS_BY_SEMESTER, GET_SEMESTER_STAGE_DAYS_FAILURE],
    promise: (client) => client.get(`totalClassAttendace/${semester}`)
  };
};

export const getFacultyReport = (id) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_FACULTY_LIST_REPORT_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get(`getFacultyById/${id}`)
  };
};

export const getIndividualStudentReport = (id) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_INDIVIDUAL_STUDENT_LIST_REPORT_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get(`getIndividualStudent/${id}`)
  };
};

export const getMultiStudentReport = (id, courseId) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_MULTI_STUDENT_LIST_REPORT_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get(`getMultiStudent/${id}/${courseId}`)
  };
};

export const getAttendanceClassList = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_ATTENDANCE_CLASSLIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('attendanceClassList')
  };
};

export const getAttendanceReportsCourseList = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_ATTENDANCE_REPORT_COURSE_LIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('attendaceReportsCourseList')
  };
};

export const getAttendanceReportSubjectList = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_ATTENDANCE_REPORT_SUBJECT_LIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('attendanceReportSubjectList')
  };
};

export const getTotalSubjectList = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_TOTAL_SUBJECT_LIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('getTotalSubjectList', { data })
  };
};

export const getAttendanceReportTopicsList = () => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_ATTENDANCE_REPORT_TOPICS_LIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.get('attendanceReportTopicsList')
  };
};

export const getTotalTopicList = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_TOTAL_TOPIC_LIST_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('getTotalTopicList', { data })
  };
};

export const getStudentTimeTable = (params) => {
  return {
    types: [GET_STUDENT_TIME_TABLE_LIST_REQUESTED, GET_STUDENT_TIME_TABLE_LIST_SUCCESS, GET_STUDENT_TIME_TABLE_LIST_FAILURE],
    promise: (client) => client.get('getStudentTimeTable', { params })
  };
};
