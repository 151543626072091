/* eslint-disable react/no-danger */
import React from 'react';
import {
  Check, ChevronLeft, ChevronRight, File, FilePlus, Star, X
} from 'react-feather';
import Loader from '../@vuexy/spinner/Fallback-spinner';
import {
  Button, Card, CardBody, Col, FormGroup, Modal, ModalBody, ModalHeader, Row
} from 'reactstrap';
import { getTodoListByWeek, updateToDoSatatus } from '../../redux/reducers/todo/todo';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form } from 'formik';
import data from './data';
import TextArea from '../common/formElements/TextAreaFormik';

const NotificationTodoModal = (props) => {
  const isAdminLogin = JSON.parse(localStorage.getItem('adminLogin'));
  const {
    renderProps, onCloseModal, isOpen, setToPrevWeek, setToNextWeek, headerDates, reloadList, exportToExcelTodo, add, setAdd, lastday, setHeaderDatesCurrentWeek
  } = props;
  const todoLength = props.renderProps?.values?.addTodo && String(props.renderProps.values.addTodo).trim().length;
  const onClose = () => {
    renderProps.resetForm({});
    onCloseModal();
  };
  return (
    <>
      {props.loading && <Loader />}
      <Modal isOpen={isOpen} className="modal-danger modal-lg to-modal">
        <ModalHeader toggle={() => onClose()} className="font-weight-bold todo-modal">
          <h5 className="d-flex justify-content-center fs-24 font-weight-bold">To Do

          </h5>
          <div className="d-flex justify-content-end">
            <div title="Export To Excel"><File size={25} className="primary cursor-pointer mr-1" id="export-todo" onClick={() => exportToExcelTodo()} /></div>
            { !add && (
              <div title="Add Task">
                <FilePlus
                  size={25}
                  className="primary cursor-pointer mr-1"
                  onClick={() => { setHeaderDatesCurrentWeek(); setAdd(true); }}
                />
              </div>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col className="col-12 card-header bg-primary mb-2 text-white">
                <div className="d-flex justify-content-between align-items-center">
                  <div title="Previous Week" className="mr-3 cursor-pointer">
                    <ChevronLeft
                      size={25}
                      onClick={() => {
                        setToPrevWeek();
                        setAdd(false);
                      }}
                    />
                  </div>

                  <div className="user-info-title font-weight-bold">
                    { `${headerDates.firstday.toDateString()} To ${headerDates.lastday.toDateString()}` }
                  </div>

                  <div title="Next Week" className="ml-3 cursor-pointer">
                    <ChevronRight
                      size={25}
                      onClick={(e) => {
                        if (moment(headerDates?.lastday).format('MM/DD/YYYY') !== moment(lastday).format('MM/DD/YYYY')) {
                          setToNextWeek();
                        } else {
                          e.preventDefault();
                        }
                      }}
                      id="next-week"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {add
                    && (
                      <Row className="d-flex justify-content-center">
                        <Col className="col-12">
                          <FormGroup>
                            <TextArea
                              name="addTodo"
                              placeholder="Add To Do"
                              type="text"
                              rows={3}
                            />
                            <span style={todoLength > 400 ? { color: 'red' } : { color: 'black' }} className="float right pt-0">{`${todoLength || 0}/400`}</span>
                          </FormGroup>
                        </Col>
                        <Col className="col-12 d-flex justify-content-center mb-2">

                          { !isAdminLogin && (
                            <Button type="submit" className="btnWidth-200 mr-3" color="success">
                              Save
                            </Button>
                          )}
                          <Button type="button" className="btnWidth-200" color="danger" onClick={() => { props.renderProps.resetForm({}); setAdd(false); }}>
                            Cancel
                          </Button>

                        </Col>

                      </Row>
                    )}
          </Form>
          { props.todoList?.data && props.todoList?.data.length > 0 && (
            <Card>
              <CardBody className="todo-list mb-0">
                { props.todoList.data.map((el) => {
                  return (
                    <Row className="d-flex flex-column column-reverse border-bottom mb-1" key={el.id}>

                      <Col sm="12" className="mb-1">

                        <div key={el.id}>
                          <Row className="mx-0">
                            <Col className="col-6">
                              <div className=" d-block justify-content-start mx-0">
                                <div className="user-info-title font-weight-bold mr-1">Description</div>
                                <Link to={`/task/edit/${el?.id}`} onClick={onCloseModal}>
                                  <div className="textUnderline primary cursor-pointer todo-description" dangerouslySetInnerHTML={{ __html: el?.description }} title={el.description} />
                                </Link>
                              </div>
                            </Col>

                            <Col className="d-flex justify-content-between">
                              <div className="d-block d-flex flex-column">
                                <div className="user-info-title font-weight-bold mr-1">Assigned To</div>

                                <div className="d-flex mx-0" title={el.assignTo}>{(el.assignTo && el.assignTo.length > 30 && `${el.assignTo.slice(0, 30)}...`) || el.assignTo}</div>
                              </div>

                              <div style={el.stared === 0 ? { color: '#FFC300' } : { color: 'black' }} className=" d-flex flex-column  align-items-start favourite">
                                <Button
                                  title="Favourite"
                                  type="button"
                                  className="p-0"
                                  color="transparent"
                                  onClick={() => {
                                    props.updateToDoSatatus({
                                      description: el.description, id: el?.id, status: data.STATUS.find((i) => (i.name === el.status))?.id, stared: el.stared === 0 ? 1 : 0
                                    }).then(() => reloadList());
                                  }}
                                >
                                  <Star style={el.stared === 0 ? { color: '#FFC300', fill: '#FFC300' } : { color: 'black' }} size={25} className="cursor-pointer" id="favourite-1" />

                                </Button>
                              </div>
                            </Col>

                          </Row>

                        </div>
                      </Col>
                      <Col>
                        <Row className="d-flex justify-content-around mx-0 mb-1">

                          <Col>
                            <div className="d-block flex-column justify-content-center mx-0">
                              <div className="user-info-title font-weight-bold">Created On</div>
                              <div>{moment(el.createdOn.substring(0, 10)).format('MM/DD/YYYY')}</div>
                            </div>

                          </Col>

                          <Col className="d-flex justify-content-between align-items-end">
                            <div className="d-block  mx-0 ">
                              <div className="user-info-title font-weight-bold">Status</div>
                              <div>{ (el.status === 'Completed' || el.status === 'Cancelled') ? (el?.conbinationStatus) : el.status } </div>
                            </div>
                            { el.status === 'Open'
                        && (
                          <div className="d-flex justify-content-end">
                            <Button
                              title="Complete"
                              type="button"
                              className="mr-1 pa-1 stroke"
                              color="success"
                              onClick={() => {
                                props.updateToDoSatatus({
                                  description: el.description, id: el?.id, status: data.STATUS.find((i) => (i.name === 'Completed'))?.id, stared: el.stared === 0 ? 1 : 0
                                }).then(() => reloadList());
                              }}
                            >
                              <Check size={20} />

                            </Button>

                            <Button
                              title="Cancel"
                              type="button"
                              color="danger"
                              className="pa-1"
                              onClick={() => {
                                props.updateToDoSatatus({
                                  description: el.description, id: el?.id, status: data.STATUS.find((i) => (i.name === 'Cancelled'))?.id, stared: el.stared === 0 ? 1 : 0
                                }).then(() => reloadList());
                              }}
                            >
                              <X size={20} />
                            </Button>
                          </div>
                        )}
                            { el.status === 'In Progress'
                            && (
                              <div className="d-flex justify-content-end">
                                <Button
                                  title="Complete"
                                  className="font-size-bold st pa-1"
                                  type="button"
                                  color="success"
                                  onClick={() => {
                                    props.updateToDoSatatus({
                                      description: el.description, id: el?.id, status: data.STATUS.find((i) => (i.name === 'Completed'))?.id, stared: el.stared === 0 ? 1 : 0
                                    }).then(() => reloadList());
                                  }}
                                >
                                  <Check size={20} />
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </CardBody>
            </Card>
          )}

        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ todoApp: { todo } }) => ({
  loading: todo.loading,
  todoList: todo.todoListByWeek
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getTodoListByWeek,
  updateToDoSatatus

}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationTodoModal));
