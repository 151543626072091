export const CONTROL_TABLE_DATA_REQUESTED = 'controlData/CONTROL_TABLE_DATA_REQUESTED';
export const CONTROL_TABLE_DATA_SUCCESS = 'controlData/CONTROL_TABLE_DATA_SUCCESS';
export const CONTROL_TABLE_DATA_FAILURE = 'controlData/CONTROL_TABLE_DATA_FAILURE';

export const CONTROL_DOC_CATEGORY_REQUESTED = 'controlData/CONTROL_DOC_CATEGORY_REQUESTED';
export const CONTROL_DOC_CATEGORY_SUCCESS = 'controlData/CONTROL_DOC_CATEGORY_SUCCESS';
export const CONTROL_DOC_CATEGORY_FAILURE = 'controlData/CONTROL_DOC_CATEGORY_FAILURE';

export const BANK_ACCOUNT_SUCCESS = 'controlData/BANK_ACCOUNT_SUCCESS';

export const CLASSES_LIST_BY_COURSE_SUCCESS = 'controlData/CLASSES_LIST_BY_COURSE_SUCCESS';

export const CONTROL_CAMPUS_LIST_SUCCESS = 'controlData/CONTROL_CAMPUS_LIST_SUCCESS';

export const CONTROL_PROBLEMS_SUCCESS = 'controlData/CONTROL_PROBLEMS_SUCCESS';

export const CONTROL_COURSE_LIST_SUCCESS = 'controlData/CONTROL_COURSE_LIST_SUCCESS';

export const GET_STAGE_BY_COURSE = 'controlData/GET_STAGE_BY_COURSE';

export const GET_COUNTRY_CODE_REQUESTED = 'controlData/GET_COUNTRY_CODE_REQUESTED';
export const GET_COUNTRY_CODE_SUCCESS = 'controlData/GET_COUNTRY_CODE_SUCCESS';
export const GET_COUNTRY_CODE_FAILURE = 'controlData/GET_COUNTRY_CODE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  loadingFlag: false,
  controlDataList: {},
  documentCategory: [],
  bankAccountList: null,
  classesByCourse: null,
  campusListDropdown: null,
  problemListDropdown: null,
  courseList: null,
  stageByCourse: null,
  countryCodeList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case CONTROL_TABLE_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case CONTROL_TABLE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      controlDataList: action.result,
    };
  }
  case CONTROL_TABLE_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case CONTROL_DOC_CATEGORY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case CONTROL_DOC_CATEGORY_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      documentCategory: action.result,
    };
  }
  case CONTROL_DOC_CATEGORY_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case BANK_ACCOUNT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      bankAccountList: action.result && action.result.data
    };
  }
  case CLASSES_LIST_BY_COURSE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      classesByCourse: action.result && action.result.data
    };
  }
  case CONTROL_CAMPUS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      campusListDropdown: action.result && action.result.data
    };
  }
  case CONTROL_PROBLEMS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      problemListDropdown: action.result
    };
  }
  case CONTROL_COURSE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      courseList: action.result
    };
  }
  case GET_STAGE_BY_COURSE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      stageByCourse: action.result && action.result.data
    };
  }
  case GET_COUNTRY_CODE_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false,
      error: false,
    };
  }
  case GET_COUNTRY_CODE_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      countryCodeList: action.result && action.result.data
    };
  }
  case GET_COUNTRY_CODE_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true,
    };
  }
  default:
    return state;
  }
};

export const getControlTableData = () => {
  const data = [
    'ct_employee_position',
    'ct_employee_status',
    'ct_employers',
    'ct_employee_type',
    'ct_office_location',
    'ct_employee_termination_reasons',
    'ct_salary',
    'ct_salary_type',
    'ct_currency',
    'ct_marital_status'
  ];
  return {
    types: [CONTROL_TABLE_DATA_REQUESTED, CONTROL_TABLE_DATA_SUCCESS, CONTROL_TABLE_DATA_FAILURE],
    promise: (client) => client.post('controlTableData', { data })
  };
};

export const getDocumentCategories = (data) => {
  return {
    types: [CONTROL_DOC_CATEGORY_REQUESTED, CONTROL_DOC_CATEGORY_SUCCESS, CONTROL_DOC_CATEGORY_FAILURE],
    promise: (client) => client.get(`controlDocumentCategory/?type=${data}`)
  };
};

export const getBankAccount = () => {
  return {
    types: [CONTROL_TABLE_DATA_REQUESTED, BANK_ACCOUNT_SUCCESS, CONTROL_TABLE_DATA_FAILURE],
    promise: (client) => client.get('bankAccount')
  };
};

export const getClassesByCourse = (courseId) => {
  return {
    types: [CONTROL_TABLE_DATA_REQUESTED, CLASSES_LIST_BY_COURSE_SUCCESS, CONTROL_TABLE_DATA_FAILURE],
    promise: (client) => client.get(`classByCourseId/${courseId}`)
  };
};

export const getCampusList = () => {
  return {
    types: [CONTROL_TABLE_DATA_REQUESTED, CONTROL_CAMPUS_LIST_SUCCESS, CONTROL_TABLE_DATA_FAILURE],
    promise: (client) => client.get('getCampusListDropdown')
  };
};

export const getProblemsDropDown = () => {
  return {
    types: [CONTROL_TABLE_DATA_REQUESTED, CONTROL_PROBLEMS_SUCCESS, CONTROL_TABLE_DATA_FAILURE],
    promise: (client) => client.get('problemDropDown')
  };
};

export const getCourseDropDown = () => {
  return {
    types: [CONTROL_TABLE_DATA_REQUESTED, CONTROL_COURSE_LIST_SUCCESS, CONTROL_TABLE_DATA_FAILURE],
    promise: (client) => client.get('getCourseDropDown')
  };
};

export const getStageByCourse = (id) => {
  return {
    types: [CONTROL_TABLE_DATA_REQUESTED, GET_STAGE_BY_COURSE, CONTROL_TABLE_DATA_FAILURE],
    promise: (client) => client.get(`getStageByCourseAdmin/${id}`)
  };
};

export const getCountryCodeList = () => {
  return {
    types: [GET_COUNTRY_CODE_REQUESTED, GET_COUNTRY_CODE_SUCCESS, GET_COUNTRY_CODE_FAILURE],
    promise: (client) => client.get('countryCodeList')
  };
};
