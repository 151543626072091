export default {
  LEAD: {
    PROFILE: {
      icon: 'User',
      hasProfile: true,
      children: [
        {
          id: '2000100',
          link: '/lead/details/activity-log',
          icon: 'Book',
          title: 'Activity Log',
          name: 'activityLog',
          isUrlParameter: true
        },
        {
          id: '200010',
          link: '/lead/details/profile',
          icon: 'User',
          title: 'Personal',
          name: 'profile',
          isUrlParameter: true
        },
        {
          id: '200011',
          link: '/lead/details/address',
          icon: 'MapPin',
          title: 'Address',
          name: 'address',
          isUrlParameter: true
        },
        {
          id: '200012',
          link: '/lead/details/semester',
          icon: 'CreditCard',
          title: 'Semester Deferment',
          name: 'semester',
          isUrlParameter: true
        },
        {
          id: '200013',
          link: '/lead/details/document',
          icon: 'File',
          title: 'Documents',
          name: 'document-list',
          isUrlParameter: true
        },
        {
          id: '200022',
          link: '/lead/details/admissionforms',
          icon: 'BookOpen',
          title: 'Admission Forms',
          name: 'admisiion-forms',
          isUrlParameter: true
        },
        {
          id: '200014',
          link: '/lead/details/email',
          icon: 'Mail',
          title: 'Email',
          name: 'email',
          isUrlParameter: true
        },
        {
          id: '200015',
          link: '/lead/details/notes',
          icon: 'Edit2',
          title: 'Notes',
          name: 'notes',
          isUrlParameter: true
        },
        {
          id: '200016',
          link: '/lead/details/phone-notes',
          icon: 'Edit2',
          title: 'Phone Notes',
          name: 'phone-notes',
          isUrlParameter: true
        },
        {
          id: '200017',
          link: '/lead/details/task',
          icon: 'List',
          title: 'Tasks',
          name: 'addtasks',
          isUrlParameter: true
        },
        {
          id: '200018',
          link: '/lead/details/status-change',
          icon: 'UserCheck',
          title: 'Status Change',
          name: 'status-change',
          isUrlParameter: true
        },
        {
          id: '200018777',
          link: '/lead/details/immigration/document',
          icon: 'Layers',
          title: 'Immigration',
          name: 'immigration',
          isUrlParameter: true
        },
        {
          id: '200019',
          link: '/lead/details/estimate',
          icon: 'Sliders',
          title: 'Estimates',
          name: 'estimates',
          isUrlParameter: true
        },
        {
          id: '2000110',
          link: '/lead/details/application/addressTab',
          icon: 'Map',
          title: 'Application',
          name: 'application',
          isUrlParameter: true
        },
        {
          id: '2000111',
          link: '/lead/details/academicrecords',
          icon: 'Calendar',
          title: 'Academic Records',
          name: 'academicrecords',
          isUrlParameter: true
        },
      ]
    },
    UNVERIFIED: {
      children: [
        {
          id: '289892',
          link: '/lead/unverified/email',
          icon: 'Email',
          title: 'Unverified Email',
          name: 'unverifiedEmail'
        },
      ]
    },
    ADMIN: {
      children: [
        // {
        // id:'200031',
        //   link: '/lead/lead-admin',
        //   icon: 'User',
        //   title: 'Lead Merge',
        //   name: 'leadMerge',
        // },
        {
          id: '200032',
          link: '/lead/lead-admin/lead-merge',
          icon: 'Users',
          title: 'Lead Merge',
          name: 'leadMerge'
        },
        {
          id: '200033',
          link: '/lead/lead-admin/duplicate-lead',
          icon: 'Users',
          title: 'Duplicate Leads',
          name: 'duplicateLeads'
        },
        {
          id: '200034',
          link: '/lead/lead-admin/closed-leads',
          icon: 'UserX',
          title: 'Closed Leads',
          name: 'closedLeads'
        },
        {
          id: '200035',
          link: '/lead/lead-admin/email-template',
          icon: 'Mail',
          title: 'Email Templates',
          name: 'emailTemplates'
        },
        {
          id: '200036',
          link: '/lead/lead-admin/bulk-update',
          icon: 'File',
          title: 'Bulk Update',
          name: 'bulkUpdate'
        },
        {
          id: '200037',
          link: '/lead/lead-admin/rep-vacation',
          icon: 'Users',
          title: 'Rep Vacation',
          name: 'repVacation'
        }
      ]
    },
    REPORTS: {
      children: [
        {
          id: '200041',
          link: '/lead/reports/advanced',
          icon: 'File',
          title: 'Advanced',
          name: 'advanced',
        },
        {
          id: '200042',
          link: '/lead/reports/activity-count',
          icon: 'Activity',
          title: 'Daily Activity Count',
          name: 'activityCount'
        },
        {
          id: '200043',
          link: '/lead/reports/task-count',
          icon: 'List',
          title: 'Task Count',
          name: 'taskCount'
        },
        {
          id: '200044',
          link: '/lead/reports/status-wise',
          icon: 'File',
          title: 'Status Wise',
          name: 'statusWise'
        },
        {
          id: '200045',
          link: '/lead/reports/lead-paid',
          icon: 'File',
          title: 'Lead Paid List',
          name: 'paidList',

        },
        {
          id: '200046',
          link: '/lead/reports/interview-scheduled',
          icon: 'File',
          title: 'Interview Scheduled',
          name: 'interview',
        },
        {
          id: '200047',
          link: '/lead/reports/seat-deposit-due',
          icon: 'File',
          title: 'Seat Deposit Due',
          name: 'seat-deposit-due',
        },
        {
          id: '200048',
          link: '/lead/reports/status-conversion',
          icon: 'BookOpen',
          title: 'Status Conversion',
          name: 'statusConversion'
        },
        {
          id: '200049',
          link: '/lead/reports/pending-leads',
          icon: 'Loader',
          title: 'Pending Leads',
          name: 'pendingLeads'
        }
      ]
    },
    TASKS: {
      children: [
        {
          id: '200051',
          link: '/lead/task/search',
          icon: 'List',
          title: "Today's Tasks",
          name: 'todayTask'
        },
        {
          id: '200052',
          link: '/lead/task/all-tasks',
          icon: 'FileText',
          title: 'All Tasks',
          name: 'allTask'
        }
      ]
    },
    NOTIFICATIONS: {
      children: [
        {
          id: '200061',
          link: '/lead/notifications/documents',
          icon: 'File',
          title: 'My Documents',
          name: 'myDocuments'
        },
        {
          id: '200062',
          link: '/lead/notifications/bulk-upload',
          icon: 'Upload',
          title: 'Lead Bulk Upload',
          name: 'leadBulkUpload'
        },
        {
          id: '200063',
          link: '/lead/notifications/fairlist',
          icon: 'List',
          title: 'College Fair',
          name: 'collegeFair'
        },
        {
          id: '200064445',
          link: '/lead/notifications/unassigned-lead',
          icon: 'Users',
          title: 'UnAssigned Leads',
          name: 'unAssignedLeads'
        },
      ]
    }
  },
  NOTIFICATIONS: {
    children: [
      {
        id: '300011',
        link: '/lead/notification/mymail',
        icon: 'Inbox',
        title: 'My Email',
        name: 'myemail'
      },
      {
        id: '300012',
        link: '/lead/notification/mysms',
        icon: 'MessageSquare',
        title: 'My SMS',
        name: 'mysms'
      },
      {
        id: '300013',
        link: '/lead/notification/mychats',
        icon: 'MessageCircle',
        title: 'My Chats',
        name: 'mychats'
      }
    ]
  },
  ADMIN: {
    children: [
      // {
      //   id:'400010',
      //   link: '/admin/dashboard',
      //   icon: 'Clipboard',
      //   title: 'Dashboard',
      //   name: 'dashboard',
      // },
      {
        id: '400011',
        link: '/admin/user',
        icon: 'User',
        title: 'User',
        name: 'user',
        superUserView: true,

      },
      {
        id: '400012',
        link: '/admin/group/list',
        icon: 'Users',
        title: 'Group',
        name: 'group',
        superUserView: false,
      },
      {
        id: '400013',
        link: '/admin/group/access',
        icon: 'Lock',
        title: 'Group Access',
        name: 'groupAccess',
        superUserView: false,
      },
      {
        id: '400014',
        link: '/admin/menu/list',
        icon: 'Book',
        title: 'Menu',
        name: 'menu',
        superUserView: true,
      },
      {
        id: '401111109',
        link: '/admin/menu-access',
        icon: 'Menu',
        title: 'Menu Access',
        name: 'menu-access',
        superUserView: false,
      },
      {
        id: '400015',
        link: '/admin/component/list',
        icon: 'Grid',
        title: 'Component',
        name: 'component',
        superUserView: true,
      },
      {
        id: '400016',
        link: '/admin/banner',
        icon: 'Image',
        title: 'Banner',
        name: 'Banner',
        superUserView: false,
      },
      // {
      //   id: '400017',
      //   link: '/admin/accounting-settings',
      //   icon: 'Settings',
      //   title: 'Accounting Settings',
      //   name: 'accounting-settings'
      // },
      {
        id: '400017',
        link: '/admin/lead-settings',
        icon: 'Settings',
        title: 'Lead Settings',
        name: 'lead-settings',
        superUserView: false,
      },
      {
        id: '401117',
        link: '/admin/accounting-settings',
        icon: 'Settings',
        title: 'Accounting Settings',
        name: 'accounting-settings',
        superUserView: false,
      },
      {
        id: '401118',
        link: '/admin/control-table',
        icon: 'List',
        title: 'Control Table',
        name: 'control-table',
        superUserView: false,
      },
      // {
      //   id: '401119',
      //   link: '/admin/assign-class',
      //   icon: 'BookOpen',
      //   title: 'Assign classes',
      //   name: 'assign-classes',
      //   superUserView: false,
      // },
      {
        id: '40111106',
        link: '/admin/alert/configuration',
        icon: 'Bell',
        title: 'Alert Configuration',
        name: 'alert',
        superUserView: true,
      },
      {
        id: '401111107',
        link: '/admin/todolist',
        icon: 'CheckSquare',
        title: 'Todo List',
        name: 'todo',
        superUserView: false,
      },
      {
        id: '401111108',
        link: '/admin/notify-all',
        icon: 'Bell',
        title: 'Update Student Info',
        name: 'notify-all',
        superUserView: false,
      },
      {
        id: '401111998',
        link: '/admin/on-demand-trigger',
        icon: 'Target',
        title: 'On-Demand Trigger',
        name: 'notify-all',
        superUserView: false,
      }
    ]
  },
  EMPLOYEE: {
    icon: 'User',
    hasProfile: true,
    module: 'employee',
    children: [
      {
        id: '500010',
        link: '/employee/details/profile',
        icon: 'User',
        title: 'Profile',
        name: 'profile',
        isUrlParameter: true
      },
      {
        id: '500011',
        link: '/employee/details/address',
        icon: 'MapPin',
        title: 'Address',
        name: 'Address',
        isUrlParameter: true
      },
      {
        id: '500012',
        link: '/employee/details/document',
        icon: 'File',
        title: 'Document',
        name: 'document',
        isUrlParameter: true
      },
      {
        id: '500013',
        link: '/employee/details/offerletter',
        icon: 'FileText',
        title: 'Offer Letter',
        name: 'offerLetter',
        isUrlParameter: true
      },
      {
        id: '500014',
        link: '/employee/details/references/list',
        icon: 'Book',
        title: 'References',
        name: 'references',
        isUrlParameter: true
      },
      {
        id: '500015',
        link: '/employee/details/benefits',
        icon: 'ArrowUpCircle',
        title: 'Benefit Info',
        name: 'benefitInfo',
        isUrlParameter: true
      },
      {
        id: '500016',
        link: '/employee/details/hrsection',
        icon: 'Users',
        title: 'HR Section',
        name: 'hrSection',
        isUrlParameter: true
      },
      {
        id: '500017',
        link: '/employee/details/bankdetails',
        icon: 'Info',
        title: 'Bank Details',
        name: 'bankDetails',
        isUrlParameter: true
      },
      {
        id: '500018',
        link: '/employee/details/salary',
        icon: 'DollarSign',
        title: 'Salary',
        name: 'salary',
        isUrlParameter: true
      },
      {
        id: '500019',
        link: '/employee/details/smsSection',
        icon: 'File',
        title: 'SMS Section',
        name: 'smsSection',
        isUrlParameter: true
      },
      {
        id: '5000110',
        link: '/employee/details/passportvisa',
        icon: 'Book',
        title: 'Passport/Visas',
        name: 'passport/visas',
        isUrlParameter: true
      }
    ]
  },
  ALERT: {
    children: [
      {
        id: '700011',
        link: '/employee/configuration/mail/alert',
        icon: 'Inbox',
        title: 'Alert Configuration',
        name: 'alertConfiguration'
      },
      {
        id: '700012',
        link: '/employee/configuration/on-demand/alert',
        icon: 'MessageSquare',
        title: 'On Demand Email Alert',
        name: 'onDemand'
      },
    ]
  },
  STUDENT: {
    icon: 'User',
    hasProfile: true,
    module: 'student',
    children: [
      {
        id: '600008',
        link: '/student/details/activity-log',
        icon: 'Book',
        title: 'Activity Log ',
        name: 'activity-log',
        isUrlParameter: true
      },
      {
        id: '600009',
        link: '/student/details/alumni',
        icon: 'User',
        title: 'Alumni ',
        name: 'alumni',
        isUrlParameter: true
      },
      {
        id: '600010',
        link: '/student/details/personal',
        icon: 'User',
        title: 'Personal',
        name: 'personal',
        isUrlParameter: true
      },
      {
        id: '600011',
        link: '/student/details/address',
        icon: 'MapPin',
        title: 'Address',
        name: 'Address',
        isUrlParameter: true
      },
      {
        id: '60089377',
        link: '/student/details/housing',
        icon: 'Home',
        title: 'Housing',
        name: 'housing',
        isUrlParameter: true
      },
      {
        id: '600012',
        link: '/student/details/academicdetails',
        icon: 'File',
        title: 'Academic Details',
        name: 'academic-details',
        isUrlParameter: true
      },
      {
        id: '6009789',
        link: '/student/details/mcat',
        icon: 'File',
        title: 'MCAT',
        name: 'mcat',
        isUrlParameter: true
      },
      {
        id: '600013',
        link: '/student/details/accounting-details',
        icon: 'Layers',
        title: 'Accounting Details',
        name: 'accounting-details',
        isUrlParameter: true
      },
      {
        id: '60002712',
        link: '/student/details/notes',
        icon: 'Edit',
        title: 'Notes',
        name: 'notes',
        isUrlParameter: true
      },
      {
        id: '6000377',
        link: '/student/details/immigration/document',
        icon: 'Layers',
        title: 'Immigration',
        name: 'immigration',
        isUrlParameter: true
      },
      {
        id: '600008123',
        link: '/student/details/billing-educational',
        icon: 'Book',
        title: 'Billing And Educational Info',
        name: 'billing-educational',
        isUrlParameter: true
      },
      {
        id: '600014',
        link: '/student/details/admissionforms',
        icon: 'File',
        title: 'Admission Forms',
        name: 'admissionForms',
        isUrlParameter: true,
        adminCantView: true
      },
      {
        id: '600015',
        link: '/student/details/semester/deferment',
        icon: 'CreditCard',
        title: 'Semester Deferment',
        name: 'semesterDeferment',
        isUrlParameter: true,
        adminCantView: true
      },
      {
        id: '600015777',
        link: '/student/details/courses',
        icon: 'Book',
        title: 'Courses',
        name: 'courses',
        isUrlParameter: true
      },
      {
        id: '600016',
        link: '/student/details/transcript',
        icon: 'File',
        title: 'Transcript',
        name: 'transcript',
        isUrlParameter: true
      },
      {
        id: '600017',
        link: '/student/details/document/studentdocument',
        icon: 'File',
        title: 'Document',
        name: 'document-list',
        isUrlParameter: true
      },
      {
        id: '607689',
        link: '/student/details/docusign',
        icon: 'File',
        title: 'Signed Documents',
        name: 'files',
        isUrlParameter: true
      },
      // {
      //   id: '600120',
      //   link: '/student/details/clinical-document',
      //   icon: 'File',
      //   title: 'Clinical Document',
      //   name: 'clinical-document',
      //   isUrlParameter: true
      // },
      {
        id: '600020',
        link: '/student/details/usmletest',
        icon: 'PenTool',
        title: 'USMLE Test',
        name: 'usmleTest',
        isUrlParameter: true
      },
      // {
      //   id: '600021',
      //   link: '/student/details/leave',
      //   icon: 'Calendar',
      //   title: 'Leave',
      //   name: 'leave',
      //   isUrlParameter: true
      // },
      // {
      //   id: '600121',
      //   link: '/student/details/requets',
      //   icon: 'PhoneCall',
      //   name: 'requets',
      //   title: 'Requests',
      //   isUrlParameter: true,
      // },
      {
        id: '600156',
        link: '/student/details/exams',
        icon: 'FileText',
        title: 'Exams',
        name: 'exams',
        isUrlParameter: true,
      },
      {
        id: '600122',
        link: '/student/details/degree',
        icon: 'Award',
        title: 'Degree',
        name: 'degree',
        isUrlParameter: true,
      },
      {
        id: '600026',
        link: '/student/details/accountingnotes',
        icon: 'Edit',
        title: 'Accounting Notes',
        name: 'accountingNotes',
        isUrlParameter: true
      },
      {
        id: '600086',
        link: '/student/details/queries',
        icon: 'MessageSquare',
        title: 'Request',
        name: 'queries',
        isUrlParameter: true
      },
      {
        id: '600031',
        link: '/student/details/credits-scholarship',
        icon: 'CreditCard',
        title: 'Credits & scholarships',
        name: 'creditsAndScholarships',
        isUrlParameter: true
      },
      {
        id: '600045',
        link: '/student/details/invoice-payments',
        name: 'InvoiceAndPayments',
        icon: 'DollarSign',
        title: 'Invoice & Payments',
        isUrlParameter: true
      },
      {
        id: '60004512',
        link: '/student/details/snap-shot',
        name: 'snap-shot',
        icon: 'File',
        title: 'Snapshot',
        isUrlParameter: true,
        isDownload: true,
        pdfDownload: true
      },
      // {
      //   id:'600112',
      //   link: '/student/details/courses',
      //   icon: 'MessageSquare',
      //   name: 'Queries',
      //   isUrlParameter: true,
      //   title:'Queries',
      // },
      {
        id: '600160',
        title: 'Rotation',
        link: '/student/details/rotation',
        icon: 'RotateCw',
        name: 'rotation',
        isUrlParameter: true,
      },
      {
        id: '600142',
        title: 'Email',
        link: '/student/details/email',
        icon: 'Mail',
        name: 'email',
        isUrlParameter: true,
      },
      {
        id: '600143',
        title: 'Other Details',
        link: '/student/details/other-details',
        icon: 'Book',
        name: 'email',
        isUrlParameter: true,
      },
      {
        id: '600145',
        title: 'Status Change',
        link: '/student/details/status-change',
        icon: 'UserCheck',
        name: 'statusChange',
        isUrlParameter: true,
      },
      {
        id: '600146',
        title: 'Application',
        link: '/student/details/application',
        icon: 'File',
        name: 'application',
        isUrlParameter: true,
      },

      {
        id: '6000176',
        title: 'Library Catalog ',
        link: '/student/details/library-catalog',
        icon: 'File',
        name: 'libraryCatalog',
        isUrlParameter: true,
        isDownload: true,
        fileName: 'Library_Catalog.xls',
        method: 'GET',
        path: 'libraryCatalog'
      },
      // {
      //   id: '600019',
      //   link: '/student/details/clinical-log/view',
      //   icon: 'File',
      //   title: 'Clinical Log',
      //   name: 'clinical-log',
      //   isUrlParameter: true,
      //   adminCantView: true
      // },

      // {
      //   id:'600020',
      //   link: '/alumni/details/calender',
      //   icon: 'Calendar',
      //   title: 'Calendar',
      //   name: 'calendar',
      //   isUrlParameter: true
      // },
      {
        id: '600320',
        link: '/student/details/academicrecords',
        icon: 'Calendar',
        title: 'Academic Records',
        name: 'academicrecords',
        isUrlParameter: true
      },
      //   id:'600420',
      //   link: '/student/details/academic-records2',
      //   icon: 'Calendar',
      //   title: 'Academic Records2,
      //   name: 'academic-records2',
      //   isUrlParameter: true
      // },
      //   id:'600520',
      //   link: '/student/details/academic-records3',
      //   icon: 'Calendar',
      //   title: 'Academic Records3',
      //   name: 'academic-records3',
      //   isUrlParameter: true
      // },
      //   id:'600620',
      //   link: '/student/details/academic-records4',
      //   icon: 'Calendar',
      //   title: 'Academic Records4',
      //   name: 'academic-records4',
      //   isUrlParameter: true
      // },
      {
        id: '600720',
        link: '/student/details/profile/view',
        icon: 'User',
        title: 'Profile',
        name: 'profile',
        isUrlParameter: true
      },
      // {
      //   id:'600820',
      //   link: '/student/details/chats',
      //   icon: 'MessageSquare',
      //   title: 'Chats',
      //   name: 'chats',
      //   isUrlParameter: true
      // },
      {
        id: '600820',
        link: '/student/details/sms-section',
        icon: 'MessageSquare',
        title: 'SMS Section',
        name: 'smsSection',
        isUrlParameter: true
      },
      // {
      //   id: '600029',
      //   link: '/student/details/credits-scholarship',
      //   icon: 'CreditCard',
      //   title: 'Credits & Scholarships',
      //   name: 'creditsAndScholarships',
      //   isUrlParameter: true
      // },
      {
        id: '600433',
        link: '/student/details/graduation',
        icon: 'Book',
        title: 'Graduation Survey',
        name: 'graduation',
        isUrlParameter: true
      },
      {
        id: '600027',
        link: '/student/details/med6notes',
        icon: 'Edit',
        title: 'Academic Advising',
        name: 'med6loanotes',
        isUrlParameter: true
      },
      {
        id: '600028',
        link: '/student/details/parents-details',
        icon: 'User',
        title: 'Billing Contact',
        name: 'billingContact',
        isUrlParameter: true
      },
      {
        id: '600029',
        link: '/student/details/time-table',
        icon: 'Table',
        title: 'Time Table',
        name: 'timeTable',
        isUrlParameter: true
      }
    ]
  },
  ALUMNI: {
    icon: 'User',
    hasProfile: true,
    module: 'alumni',
    children: [
      {
        id: '700001',
        link: '/alumni/details/alumni',
        icon: 'User',
        title: 'Alumni ',
        name: 'alumni',
        isUrlParameter: true
      },
      {
        id: '700002',
        link: '/alumni/details/courses',
        icon: 'Book',
        title: 'Courses',
        name: 'courses',
        isUrlParameter: true
      },
      {
        id: '700003',
        link: '/alumni/details/transcript',
        icon: 'CreditCard',
        title: 'Transcript',
        name: 'transcript',
        isUrlParameter: true
      },
      {
        id: '700004',
        link: '/alumni/details/queries',
        icon: 'Edit3',
        title: 'Queries',
        name: 'queries',
        isUrlParameter: true
      },
      {
        id: '700005',
        link: '/alumni/details/calender',
        icon: 'Calendar',
        title: 'Calendar',
        name: 'calendar',
        isUrlParameter: true
      }
    ]
  },
  Rotation: {
    children: [
      {
        id: '800001',
        link: '/medical/clinicals/assign-rotation/list',
        icon: 'List',
        title: 'Rotation List',
        name: 'list'
      },
      {
        id: '800002',
        link: '/medical/clinicals/assign-rotation/roaster',
        icon: 'User',
        title: 'Student Roster',
        name: 'roaster'
      },
      {
        id: '800003',
        link: '/medical/clinicals/assign-rotation/graph',
        title: 'Rotation Graph',
        icon: 'BarChart2',
        name: 'graph'
      },
      {
        id: '800004',
        link: '/medical/clinicals/assign-rotation/planned',
        title: 'Plan Rotation',
        icon: 'Calendar',
        name: 'planned'
      },
      // {
      //   id: '800004',
      //   link: '/medical/clinicals/assign-rotation/requests',
      //   title: 'Rotation Requests',
      //   icon: 'User',
      //   name: 'request'
      // }
    ]
  },
  REQUESTS: {
    icon: 'User',
    hasProfile: true,
    module: 'request',
    children: [
      {
        id: '900000',
        link: '/medical/request/transcript-request',
        // icon: 'User',
        title: 'Transcript Request',
        name: 'transcriptRequest'
      },
      {
        id: '900001',
        link: '/medical/request/clinical-request',
        // icon: 'User',
        title: 'Clinical Request ',
        name: 'clinicalRequest'
      },
      {
        id: '900002',
        link: '/medical/request/i94letter-request',
        // icon: 'Book',
        title: 'I - 94 Letter',
        name: 'I94Letter'
      },
      // {
      //   id: '900003',
      //   // link: '/alumni/details/transcript',
      //   icon: 'CreditCard',
      //   title: 'Transcript',
      //   name: 'transcript'
      // },
      {
        id: '900004',
        link: '/medical/request/enrollment-request',
        // icon: 'Edit3',
        title: 'Enrollment Request',
        name: 'enrollmentRequest'
      },
      {
        id: '900005',
        link: '/medical/request/mpse-requests',
        // icon: 'Calendar',
        title: 'MSPE Request',
        name: 'mspeRequest'
      },
      {
        id: '900006',
        link: '/medical/request/diploma',
        // icon: 'Book',
        title: 'Replacement Diploma Request',
        name: 'replacementDiplomaRequest'
      },
      {
        id: '900007',
        link: '/medical/request/class-withdrawal',
        // icon: 'CreditCard',
        title: 'Class Withdrawal',
        name: 'classWithdrawal'
      },
      {
        id: '900008',
        link: '/medical/request/program-withdrawal',
        // icon: 'Edit3',
        title: 'Program Withdrawal',
        name: 'programWithdrawal'
      },
      {
        id: '900009',
        link: '/medical/request/absence',
        // icon: 'Calendar',
        title: 'Leave of Absence Request',
        name: 'leaveOfAbsenceRequest',
      },
      {
        id: '900015',
        link: '/medical/request/complaint',
        // icon: 'Calendar',
        title: 'Complaint',
        name: 'complaint',
      },
      {
        id: '900010',
        link: '/medical/request/finance-request',
        // icon: 'User',
        title: 'Student Finance',
        name: 'studentFinance'
      },
      {
        id: '900013',
        link: '/medical/request/premedical-basic',
        // icon: 'Book',
        title: 'Pre-Medical & Basic Science Leave of Absence Request',
        name: 'preMedicalBasicScience'
      },
      {
        id: '900011',
        link: '/medical/request/graduation-application',
        // icon: 'Book',
        title: 'Graduation/Diploma Application',
        name: 'graduationApplication'
      },
      {
        id: '900012',
        link: '/medical/request/appeal-request',
        // icon: 'Edit3',
        title: 'Appeal Request',
        name: 'appeal',
      },
      {
        id: '900019',
        link: '/medical/request/exam-request',
        // icon: 'Edit3',
        title: 'Exams Request',
        name: 'exam-request',
      },
    ]
  },
  ATTENDANCE: {
    children: [
      {
        id: '1000011',
        link: '/medical/attendance/stage-schedule',
        icon: 'Calendar',
        title: 'Stage Schedule',
        name: 'stage-schedule'
      },
      {
        id: '1000012',
        link: '/medical/attendance/semester-schedule',
        icon: 'Calendar',
        title: 'Semester Schedule',
        name: 'semester-schedule'
      },
      {
        id: '1000013',
        link: '/medical/attendance/weekly-schedule',
        icon: 'Calendar',
        title: 'Weekly Schedule',
        name: 'weekly-schedule'
      },
      {
        id: '1000014',
        link: '/medical/attendance/published/schedule',
        icon: 'Calendar',
        title: 'Published Schedules',
        name: 'published-schedules'
      },
      {
        id: '1000015',
        link: '/medical/attendance/time-table',
        icon: 'Calendar',
        title: 'Time Table',
        name: 'time-table'
      },
      {
        id: '1000016',
        link: '/medical/attendance/past-attendance',
        icon: 'Calendar',
        title: 'Past Attendance',
        name: 'past-attendance'
      },
      {
        id: '1000017',
        link: '/medical/attendance/holidays',
        icon: 'Calendar',
        title: 'Holidays',
        name: 'Holidays'
      },
      // {
      //   id: '1000018',
      //   link: '/medical/attendance/user-access',
      //   icon: 'User',
      //   title: 'User Access',
      //   name: 'user-access'
      // },
      {
        id: '1000019',
        link: '/medical/attendance/reports/total',
        icon: 'Calendar',
        title: 'Reports',
        name: 'reports',
        hasChildren: true
      }
    ]
  },
  PRECEPTOR: {
    children: [
      {
        id: '5003210',
        link: '/preceptor/user/profile',
        icon: 'User',
        title: 'Profile',
        name: 'profile',
        isUrlParameter: true
      },
      {
        id: '500021211',
        link: '/preceptor/rotation',
        icon: 'MapPin',
        title: 'Rotation',
        name: 'Rotation',
        isUrlParameter: true
      },
    ]
  },
  MAILLOG: {
    children: [
      {
        id: '425367122',
        link: '/mailLog/incoming',
        icon: 'Mail',
        title: 'Incoming Mail Log',
        name: 'incoming',
      },
      {
        id: '425367123',
        link: '/mailLog/outgoing',
        icon: 'Mail',
        title: 'Outgoing Mail Log',
        name: 'outgoing',
      },
      {
        id: '425367124',
        link: '/mailLog/aruba/incoming',
        icon: 'Mail',
        title: ' Aruba Incoming Mail Log',
        name: 'arubaIncoming',
      },
      {
        id: '425367125',
        link: '/mailLog/aruba/outgoing',
        icon: 'Mail',
        title: 'Aruba Outgoing Mail Log',
        name: 'arubaOutgoing',
      },
      {
        id: '425367126',
        link: '/mailLog/returned',
        icon: 'Mail',
        title: 'Returned Mail Log',
        name: 'returned',
      },
    ]
  },
  USER_PROFILE: {

    children: [
      {
        id: '425367161',
        title: 'Profile',
        name: 'profile',
        icon: 'User',
        link: '/settings/user/profile',
      },
      {
        id: '4253671262',
        title: 'Password',
        name: 'password',
        icon: 'Lock',
        link: '/settings/user/password'
      },
      {
        id: '4253671265',
        title: 'Signature',
        name: 'signature',
        icon: 'Type',
        link: '/settings/user/signature'
      }
    ]
  },
  ACCOUNTING: {
    children: [
      {
        id: 'invoices',
        title: 'Invoices',
        name: 'invoices',
        icon: 'List',
        link: '/accounting/invoices/non-clinical',
        hasChildren: true,
      },
      {
        id: 'scholarship',
        name: 'scholarship',
        title: 'Scholarship',
        type: 'item',
        icon: 'BookOpen',
        link: '/accounting/scholarship',
        hasChildren: true,
      },
      {
        id: 'loa',
        name: 'loa',
        title: 'LOA',
        type: 'item',
        icon: 'ArrowDown',
        link: '/accounting/loa/med6Loa',
        hasChildren: true,
      },
      {
        id: 'revenue',
        name: 'revenue',
        title: 'Revenue',
        type: 'item',
        icon: 'DollarSign',
        link: '/accounting/revenue',
        hasChildren: true,
      },
      // {
      //   id: 'autoPaymentList',
      //   title: 'AutoPayment List',
      //   type: 'item',
      //   icon: <Icon.List size={12} />,
      //   navLink: '/accounting/autoPaymentList',
      //   accessRight: '/accounting/autoPaymentList'
      // },
      // {
      //   id: 'userAccess',
      //   title: 'User Access',
      //   type: 'item',
      //   icon: <Icon.UserCheck size={12} />,
      //   navLink: '/accounting/userAccess',
      //   accessRight: '/accounting/userAccess'
      // },
      {
        id: 'lead',
        name: 'lead',
        title: 'Lead',
        type: 'item',
        icon: 'Users',
        link: '/accounting/lead',
      },
      {
        id: 'unverified-notifications',
        name: 'unverified-notifications',
        title: 'Unverified Payments',
        type: 'item',
        icon: 'List',
        link: '/accounting/unverified-notifications',
      },
      {
        id: 'payment',
        name: 'payment',
        title: 'Payment List',
        type: 'item',
        icon: 'DollarSign',
        link: '/accounting/payments/list',
      },
    ]
  },
  EXAMS: {
    children: [
      {
        id: 'nbme-shelf',
        name: 'nbme-shelf',
        title: 'NBME Shelf',
        type: 'item',
        // icon: 'List',
        link: '/exams/nbme-shelf',
      },
      {
        id: 'nbme-comprehensive',
        name: 'nbme-comprehensive',
        title: 'NBME Comprehensive',
        type: 'item',
        // icon: 'List',
        link: '/exams/nbme-comprehensive',
      },
      {
        id: 'kaplan',
        name: 'kaplan',
        title: 'Kaplan / Other Diagnostics',
        type: 'item',
        // icon: 'List',
        link: '/exams/kaplan',
      }, {
        id: 'retake-exams',
        name: 'retake-exams',
        title: 'Retake Exams',
        type: 'item',
        // icon: 'List',
        link: '/exams/retake-exams',
      },
      {
        id: 'bulk-update',
        name: 'bulk-update',
        title: 'Bulk Update',
        type: 'item',
        // icon: 'List',
        link: '/exams/bulk-update/nbme-system',
      }
    ]
  },
  COMMITTEE: {
    children: [
      {
        id: '42536456676',
        link: '/committee/list',
        icon: 'Users',
        title: 'Committee',
        name: 'committee',
      },
      {
        id: '42536763528',
        link: '/committee/meeting/list',
        icon: 'Video',
        title: 'Committee Meeting',
        name: 'meeting',
      },
      {
        id: '425367653791',
        link: '/committee/meeting/status/report',
        icon: 'File',
        title: 'Committee Status Report',
        name: 'meetingStatusReport',
      },
    ]
  },
  HOUSING: {
    children: [
      {
        id: '42536355',
        link: '/housing/allocation',
        icon: 'Plus',
        title: 'Housing Allocation',
        name: 'allocation',
      },
      {
        id: '425367123',
        link: '/housing/queue-list',
        icon: 'Navigation',
        title: 'Housing Queue',
        name: 'incoming',
      },
      {
        id: '425367122',
        link: '/housing/list',
        icon: 'Home',
        title: 'Housing List',
        name: 'incoming',
      },
      {
        id: '4253543122',
        link: '/housing/master/list',
        icon: 'Home',
        title: 'Housing Master List',
        name: 'incoming',
      },
    ]
  },
};
