import { combineReducers } from 'redux';
import request from './requests';
import statusChange from './statusChange';

const RequestModule = combineReducers({
  request,
  statusChange
});

export default RequestModule;
