/* eslint-disable*/
import mock from '../mock'

export const searchResult = [
  {
    // groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 'student',
        title: 'Student',
        type: 'item',
        icon: 'User',
        link: '/student/list'
      },
      {
        id: 'clinicals',
        title: 'Clinicals',
        type: 'item',
        icon: 'Thermometer',
        link: '/medical/clinicals/hospitals/list'
      },
      {
        id: 'attendance',
        title: 'Attendance',
        type: 'item',
        icon: 'Calendar',
        link: '/medical/attendance/stage-schedule'
      },
      {
        id: 'request',
        title: 'Request',
        type: 'item',
        icon: 'PhoneCall',
        link: '/medical/request/transcript-request'
      },
      {
        id: 'alumniList',
        title: 'AlumniList',
        type: 'item',
        icon: 'Users',
        link: '/medical/alumniList'
      },
      {
        id: 'registrar',
        title: 'Registrar',
        type: 'item',
        icon: 'UserPlus',
        link: '/medical/registrar'
      },
      {
        id: 'invoices',
        title: 'Invoices',
        type: 'item',
        icon: 'List',
        link: '/accounting/invoices/non-clinical'
      },
      {
        id: 'scholarship',
        title: 'Scholarship',
        type: 'item',
        icon: 'BookOpen',
        link: '/accounting/scholarship'
      },
      {
        id: 'loa',
        title: 'LOA',
        type: 'item',
        icon: 'ArrowDown',
        link: '/accounting/loa/med6Loa'
      },
      {
        id: 'revenue',
        title: 'Revenue',
        type: 'item',
        icon: 'DollarSign',
        link: '/accounting/revenue'
      },
      // {
      //   id: 'autoPaymentList',
      //   title: 'AutoPayment List',
      //   type: 'item',
      //   icon: 'List',
      //   link: '/accounting/autoPaymentList'
      // },
      // {
      //   id: 'userAccess',
      //   title: 'User Access',
      //   type: 'item',
      //   icon: 'UserCheck',
      //   link: '/accounting/userAccess'
      // },
      {
        id: 'lead',
        title: 'Accounting',
        type: 'item',
        icon: 'Users',
        link: '/accounting/invoices/non-clinical'
      },
      {
        id: 'employee',
        title: 'Employee',
        type: 'item',
        icon: 'Users',
        link: '/employee/list'
      },
      {
        id: 'lead',
        title: 'Lead',
        type: 'item',
        icon: 'Users',
        link: '/lead/list'
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        icon: 'List',
        link: '/task/list'
      },
      {
        id: 'exams',
        title: 'Exams',
        type: 'item',
        icon: 'Clipboard',
        link: '/exams/nbme-shelf'
      },
      {
        id: 'mail Log',
        title: 'Mail Log',
        type: 'item',
        icon: 'Mail',
        link: '/mailLog/incoming'
      },
      {
        id: 'document',
        title: 'Document',
        type: 'item',
        icon: 'File',
        link: '/document/list'
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'item',
        icon: 'FileText',
        link: '/reports/list'
      },
      {
        id: 'admin',
        title: 'System Admin',
        type: 'item',
        icon: 'User',
        link: '/admin/user'
      },
      {
        id: 'calendar',
        title: 'Calendar',
        type: 'item',
        icon: 'Calendar',
        link: '/calendar'
      },
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'List',
        link: '/dashboard/list'
      },
      {
        id: 'committeeMeeting',
        title: 'Committee Meeting',
        type: 'item',
        icon: 'Mail',
        link: '/committee/list'
      },
      {
        id: 'unverifiedPayments',
        title: 'Unverified Payments',
        type: 'item',
        icon: 'List',
        link: '/accounting/unverified-notifications'
      }
    ]
  }
]

mock.onGet('/api/main-search/data').reply(200, {
  searchResult
})
