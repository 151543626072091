/* eslint-disable no-case-declarations */
export const GET_TASK_LIST_REQUESTED = 'task/GET_TASK_LIST_REQUESTED';
export const GET_TASK_LIST_SUCCESS = 'task/GET_TASK_LIST_SUCCESS';
export const GET_TASK_LIST_FAILURE = 'task/GET_TASK_LIST_FAILURE';

export const GET_TASK_DROPDOWN_ASSIGNED = 'task/GET_TASK_DROPDOWN_ASSIGNED';

export const GET_TASK_DROPDOWN_ASSIGNED_TO = 'task/GET_TASK_DROPDOWN_ASSIGNED_TO';
export const ADD_TASK_LIST_SUCCESS = 'task/ADD_TASK_LIST_SUCCESS';
export const GET_TASK_BY_ID_SUCCESS = 'task/GET_TASK_BY_ID_SUCCESS';
export const UPDATE_TASK_SUCCESS = 'task/UPDATE_TASK_SUCCESS';
export const GET_TASK_SUCCESS = 'task/GET_TASK_SUCCESS';

export const ADD_TODO_REQUESTED = 'todo/ADD_TODO_REQUESTED';
export const ADD_TODO_SUCCESS = 'todo/ADD_TODO_SUCCESS';
export const ADD_TODO_FAILURE = 'todo/ADD_TODO_FALURE';
export const GET_TODO_LIST_BYWEEK_SUCCESS = 'todo/GET_TODO_LIST_BYWEEK_SUCCESS';
export const UPDATE_TODO_SUCCESS = 'todo/UPDATE_TODO_SUCCESS';
export const GET_TODO_LIST_SUCCESS = 'todo/GET_TODO_LIST_SUCCESS';
export const GET_TODO_LIST_ALL_DATA_SUCCESS = 'todo/GET_TODO_LIST_ALL_DATA_SUCCESS';

const initialState = {
  loading: false,
  leaded: false,
  error: false,
  todos: [],
  routeParam: null,
  filteredTodos: [],
  taskList: null,
  taskAssigned: null,
  taskAssignedTo: null,
  taskById: null,
  taskByTaskId: null,
  todoList: null,
  todoListAllData: null,
  todoListByWeek: null
};

const todo = (state = initialState, action) => {
  switch (action.type) {
  case 'GET_TODOS':
    return { ...state, todos: action.todos, routeParam: action.routeParams };
  case 'UPDATE_TODOS':
    let todo = action.todo;
    let updatedState = { ...state.todos, [todo.id]: { ...todo } };
    return { ...state, todos: updatedState };

  case 'COMPLETE_TASK':
    state.todos.find((i) => i.id === action.id).isCompleted = !action.value;
    return { ...state };

  case 'STAR_TASK':
    state.todos.find((i) => i.id === action.id).isStarred = !action.value;
    return { ...state };

  case 'IMPORTANT_TASK':
    state.todos.find((i) => i.id === action.id).isImportant = !action.value;
    return { ...state };

  case 'TRASH_TASK':
    state.todos.find((i) => i.id === action.id).isTrashed = true;
    return { ...state };

  case 'UPDATE_LABEL':
    let taskToUpdate = state.todos.find((i) => i.id === action.id).tags;
    if (!taskToUpdate.includes(action.label)) taskToUpdate.push(action.label);
    else taskToUpdate.splice(taskToUpdate.indexOf(action.label), 1);
    return { ...state };

  case 'UPDATE_TASK':
    let todoToUpdate = state.todos.find((i) => i.id === action.id);
    todoToUpdate.title = action.title;
    todoToUpdate.desc = action.desc;
    return { ...state };

  case 'ADD_TASK':
    return { ...state };

  case 'SEARCH_TASK':
    if (action.val.length) {
      let filteredTodos = state.todos.filter((query) => {
        return (
          query.title.toLowerCase().includes(action.val)
            || query.desc.toLowerCase().includes(action.val)
        );
      });
      return { ...state, filteredTodos };
    }
    return { ...state };

  case 'CHANGE_FILTER':
    state.routeParam = action.filter;
    return { ...state };
  case GET_TASK_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_TASK_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskList: action.result
    };
  }
  case GET_TASK_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_TASK_DROPDOWN_ASSIGNED: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskAssigned: action && action.result
    };
  }
  case GET_TASK_DROPDOWN_ASSIGNED_TO: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskAssignedTo: action && action.result
    };
  }
  case ADD_TASK_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_TASK_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskById: action.result
    };
  }
  case UPDATE_TASK_SUCCESS: {
    return {
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_TASK_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskByTaskId: action.result
    };
  }
  case ADD_TODO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case ADD_TODO_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_TODO_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_TODO_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      todoList: action.result
    };
  }
  case GET_TODO_LIST_ALL_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      todoListAllData: action.result
    };
  }
  case GET_TODO_LIST_BYWEEK_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      todoListByWeek: action.result
    };
  }
  case UPDATE_TODO_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  default:
    return state;
  }
};

export const getTaskList = (params) => {
  return {
    types: [GET_TASK_LIST_REQUESTED, GET_TASK_LIST_SUCCESS, GET_TASK_LIST_FAILURE],
    promise: (client) => client.get('getTaskList', { params })
  };
};
export const getTaskAssigned = () => {
  return {
    types: [GET_TASK_LIST_REQUESTED, GET_TASK_DROPDOWN_ASSIGNED, GET_TASK_LIST_FAILURE],
    promise: (client) => client.get('getTaskAssigned')
  };
};
export const getTaskAssignedTo = () => {
  return {
    types: [GET_TASK_LIST_REQUESTED, GET_TASK_DROPDOWN_ASSIGNED_TO, GET_TASK_LIST_FAILURE],
    promise: (client) => client.get('getTaskAssignedTo')
  };
};
export const addTask = (data) => {
  return {
    types: [GET_TASK_LIST_REQUESTED, ADD_TASK_LIST_SUCCESS, GET_TASK_LIST_FAILURE],
    promise: (client) => client.post('addTask', { data })
  };
};
export const getTaskById = (id) => {
  return {
    types: [GET_TASK_LIST_REQUESTED, GET_TASK_BY_ID_SUCCESS, GET_TASK_LIST_FAILURE],
    promise: (client) => client.get(`getTaskById/${id}`)
  };
};
export const updateTask = (data) => {
  return {
    types: [GET_TASK_LIST_REQUESTED, UPDATE_TASK_SUCCESS, GET_TASK_LIST_FAILURE],
    promise: (client) => client.put('updateTask', { data })
  };
};
export const getTaskByTaskId = (id) => {
  return {
    types: [GET_TASK_LIST_REQUESTED, GET_TASK_SUCCESS, GET_TASK_LIST_FAILURE],
    promise: (client) => client.get(`getTaskByTaskId/${id}`)
  };
};

export const addTodo = (data) => {
  return {
    types: [ADD_TODO_REQUESTED, ADD_TODO_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.post('addTodo', { data })
  };
};
export const getTodoList = (params) => {
  return {
    types: [ADD_TODO_REQUESTED, GET_TODO_LIST_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.get('getTodoList', { params })
  };
};
export const getTodoListAllData = (params) => {
  return {
    types: [ADD_TODO_REQUESTED, GET_TODO_LIST_ALL_DATA_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.get('getTodoList', { params })
  };
};
export const getTodoListByWeek = (params) => {
  return {
    types: [ADD_TODO_REQUESTED, GET_TODO_LIST_BYWEEK_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.get('getTodoListByWeek', { params })
  };
};
export const updateToDoSatatus = (data) => {
  return {
    types: [ADD_TODO_REQUESTED, UPDATE_TODO_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.put('updateSatatus', { data })
  };
};
export default todo;
