export const ADD_ACTION_REQUESTED = 'ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'ADD_ACTION_FAILURE';

export const GET_INVOICE_HEAD_LIST_REQUESTED = 'GET_INVOICE_HEAD_LIST_REQUESTED';
export const GET_NCI_HEAD_LIST_SUCCESS = 'GET_NCI_HEAD_LIST_SUCCESS';
export const GET_INVOICE_HEAD_LIST_FAILURE = 'GET_INVOICE_HEAD_LIST_FAILURE';

export const GET_INVOICE_SEMESTER_REQUESTED = 'GET_INVOICE_SEMESTER_REQUESTED';
export const GET_INVOICE_SEMESTER_SUCCESS = 'GET_INVOICE_SEMESTER_SUCCESS';
export const GET_INVOICE_SEMESTER_FAILURE = 'GET_INVOICE_SEMESTER_FAILURE';

export const GET_NCI_STUDENT_LIST_STEP2_REQUESTED = 'GET_NCI_STUDENT_LIST_STEP2_REQUESTED';
export const GET_NCI_STUDENT_LIST_STEP2_SUCCESS = 'GET_NCI_STUDENT_LIST_STEP2_SUCCESS';
export const GET_NCI_STUDENT_LIST_STEP2_FAILURE = 'GET_INVOICE_SEMESTER_FAILURE';

export const GET_NCI_STUDENT_LIST_STEP1_REQUESTED = 'GET_NCI_STUDENT_LIST_STEP1_REQUESTED';
export const GET_NCI_STUDENT_LIST_STEP1_SUCCESS = 'GET_NCI_STUDENT_LIST_STEP1_SUCCESS';
export const GET_NCI_STUDENT_LIST_STEP1_FAILURE = 'GET_NCI_STUDENT_LIST_STEP1_FAILURE';

export const GET_INVOICE_TITLE_REQUESTED = 'GET_INVOICE_TITLE_REQUESTED';
export const GET_INVOICE_TITLE_SUCCESS = 'GET_INVOICE_TITLE_SUCCESS';
export const GET_INVOICE_TITLE_FAILURE = 'GET_INVOICE_TITLE_FAILURE';

export const GET_NCI_SEARCH_LIST_REQUESTED = 'GET_NCI_SEARCH_LIST_REQUESTED';
export const GET_NCI_SEARCH_LIST_SUCCESS = 'GET_NCI_SEARCH_LIST_SUCCESS';
export const GET_NCI_SEARCH_LIST_FAILURE = 'GET_NCI_SEARCH_LIST_FAILURE';

export const GET_NCI_INVOICE_SEARCH_LIST_REQUESTED = 'GET_NCI_INVOICE_SEARCH_LIST_REQUESTED';
export const GET_NCI_INVOICE_SEARCH_LIST_FAILURE = 'GET_NCI_INVOICE_SEARCH_LIST_FAILURE';

export const GET_DUE_INVOICE_LIST_SUCCESS = 'GET_DUE_INVOICE_LIST_SUCCESS';

export const GET_DUE_STEP2_LIST_SUCCESS = 'GET_DUE_STEP2_LIST_SUCCESS';

export const GET_CI_STUDENT_LIST_STEP1_SUCCESS = 'GET_CI_STUDENT_LIST_STEP1_SUCCESS';

export const GET_CI_STUDENT_LIST_STEP2_SUCCESS = 'GET_CI_STUDENT_LIST_STEP2_SUCCESS';

export const GET_CLINICAL_INVOICE_LIST_REQUESTED = 'GET_CLINICAL_INVOICE_LIST_REQUESTED';
export const GET_CLINICAL_INVOICE_LIST_SUCCESS = 'GET_CLINICAL_INVOICE_LIST_SUCCESS';
export const GET_CLINICAL_INVOICE_LIST_FAILURE = 'GET_CLINICAL_INVOICE_LIST_FAILURE';

export const GET_CI_HEAD_LIST_SUCCESS = 'GET_CI_HEAD_LIST_SUCCESS';

export const GET_CI_SEMESTER_SUCCESS = 'GET_CI_SEMESTER_SUCCESS';

export const GET_HOUSING_INVOICE_TITLE_SUCCESS = 'GET_HOUSING_INVOICE_TITLE_SUCCESS';

export const GET_HOUSING_INVOICE_LIST_SUCCESS = 'GET_HOUSING_INVOICE_LIST_SUCCESS';

export const GET_HOUSING_HEAD_LIST_SUCCESS = 'GET_HOUSING_HEAD_LIST_SUCCESS';

export const GET_HOUSING_INVOICE_LIST_STEP1_SUCCESS = 'GET_HOUSING_INVOICE_LIST_STEP1_SUCCESS';

export const GET_HOUSING_INVOICE_LIST_STEP2_SUCCESS = 'GET_HOUSING_INVOICE_LIST_STEP2_SUCCESS';

export const GET_NCI_ESTIMATES_LIST_STEP1_REQUESTED = 'GET_NCI_ESTIMATES_LIST_STEP1_REQUESTED';
export const GET_NCI_ESTIMATES_LIST_STEP1_SUCCESS = 'GET_NCI_ESTIMATES_LIST_STEP1_SUCCESS';
export const GET_NCI_ESTIMATES_LIST_STEP1_FAILURE = 'GET_NCI_ESTIMATES_LIST_STEP1_FAILURE';

export const GET_STATUS_DROPDOWN_REQUESTED = 'GET_STATUS_DROPDOWN_REQUESTED';
export const GET_STATUS_DROPDOWN_SUCCESS = 'GET_STATUS_DROPDOWN_SUCCESS';
export const GET_STATUS_DROPDOWN_FAILURE = 'GET_STATUS_DROPDOWN_FAILURE';

export const GET_NCI_ESTIMATES_LIST_STEP2_REQUESTED = 'GET_NCI_ESTIMATES_LIST_STEP2_REQUESTED';
export const GET_NCI_ESTIMATES_LIST_STEP2_SUCCESS = 'GET_NCI_ESTIMATES_LIST_STEP2_SUCCESS';
export const GET_NCI_ESTIMATES_LIST_STEP2_FAILURE = 'GET_NCI_ESTIMATES_LIST_STEP2_FAILURE';

export const GET_NCI_ESTIMATES_LIST_REQUESTED = 'GET_NCI_ESTIMATES_LIST_REQUESTED';
export const GET_NCI_ESTIMATES_LIST_SUCCESS = 'GET_NCI_ESTIMATES_LIST_SUCCESS';
export const GET_NCI_ESTIMATES_LIST_FAILURE = 'GET_NCI_ESTIMATES_LIST_FAILURE';

export const GET_WEEKS_PAID_SUCCESS = 'GET_WEEKS_PAID_SUCCESS';

export const GET_PDF_LIST_SUCCESS = 'GET_PDF_LIST_SUCCESS';

export const GET_MED_LOA_CREATE_LIST_REQUESTED = 'lead/GET_MED_LOA_CREATE_LIST_REQUESTED';
export const GET_MED_LOA_CREATE_LIST_SUCCESS = 'lead/GET_MED_LOA_CREATE_LIST_SUCCESS';
export const GET_MED_LOA_CREATE_LIST_FAILURE = 'lead/GET_MED_LOA_CREATE_LIST_FAILURE';

export const GET_MED6_LOA_INVOICE_LIST_REQUESTED = 'lead/GET_MED6_LOA_INVOICE_LIST_REQUESTED';
export const GET_MED6_LOA_INVOICE_LIST_SUCCESS = 'lead/GET_MED6_LOA_INVOICE_LIST_SUCCESS';
export const GET_MED6_LOA_INVOICE_LIST_FAILURE = 'lead/GET_MED6_LOA_INVOICE_LIST_FAILURE';

export const GET_MED_LOA_PAYMENT_LIST_REQUESTED = 'lead/GET_MED_LOA_PAYMENT_LIST_REQUESTED';
export const GET_MED_LOA_PAYMENT_LIST_SUCCESS = 'lead/GET_MED_LOA_PAYMENT_LIST_SUCCESS';
export const GET_MED_LOA_PAYMENT_LIST_FAILURE = 'lead/GET_MED_LOA_PAYMENT_LIST_FAILURE';

export const GET_MED6_LOA_STUDENT_LIST_REQUESTED = 'lead/GET_MED6_LOA_STUDENT_LIST_REQUESTED';
export const GET_MED6_LOA_STUDENT_LIST_SUCCESS = 'lead/GET_MED6_LOA_STUDENT_LIST_SUCCESS';
export const GET_MED6_LOA_STUDENT_LIST_FAILURE = 'lead/GET_MED6_LOA_STUDENT_LIST_FAILURE';

export const CLEAR_STUDENT_LIST_EMPTY = 'CLEAR_STUDENT_LIST_EMPTY';

export const GET_CLINICAL_CREATE_LOA_LIST_REQUESTED = 'lead/GET_CLINICAL_CREATE_LOA_LIST_REQUESTED';
export const GET_CLINICAL_CREATE_LOA_LIST_SUCCESS = 'lead/GET_CLINICAL_CREATE_LOA_LIST_SUCCESS';
export const GET_CLINICAL_CREATE_LOA_LIST_FAILURE = 'lead/GET_CLINICAL_CREATE_LOA_LIST_FAILURE';

export const GET_CLINICAL_LOA_LIST_REQUESTED = 'lead/GET_CLINICAL_LOA_LIST_REQUESTED';
export const GET_CLINICAL_LOA_LIST_SUCCESS = 'lead/GET_CLINICAL_LOA_LIST_SUCCESS';
export const GET_CLINICAL_LOA_LIST_FAILURE = 'lead/GET_CLINICAL_LOA_LIST_FAILURE';

export const GET_MED6_LOA_VERIFY_LIST_SUCCESS = 'lead/GET_MED6_LOA_VERIFY_LIST_SUCCESS';

export const GET_INVOICE_REMINDER_LIST = 'lead/GET_INVOICE_REMINDER_LIST';
export const GET_INVOICE_REMINDER_LIST_BY_SEMESTER = 'lead/GET_INVOICE_REMINDER_LIST_BY_SEMESTER';

export const GET_INVOICE_SEMESTER_CUR_FUT_REQUESTED = 'GET_INVOICE_SEMESTER_CUR_FUT_REQUESTED';
export const GET_INVOICE_SEMESTER_CUR_FUT_SUCCESS = 'GET_INVOICE_SEMESTER_CUR_FUT_SUCCESS';
export const GET_INVOICE_SEMESTER_CUR_FUT_FAILURE = 'GET_INVOICE_SEMESTER_CUR_FUT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  nonCInvoiceHeadList: null,
  invoiceSemesterList: null,
  studentListStep1NCInvoice: null,
  studentListStep2NCInvoice: null,
  nonClinicalInvoiceList: null,
  invoiceTitleList: null,
  dueInvoiceList: null,
  dueInvoiceStep2List: null,
  step1ListClinicalInvoice: null,
  step2ListClinicalInvoice: null,
  clinicalInvoiceList: null,
  clinicalInvoiceHead: null,
  clinicalInvoiceSemester: null,
  housingInvoiceTitle: null,
  housingInvoiceList: null,
  housingInvoiceHeadList: null,
  step1HousingInvoiceList: null,
  step2HousingInvoiceList: null,
  weeksPaid: null,
  pdfdata: null,
  medLoaCreateList: null,
  med6LoaInvoiceList: null,
  medLoaPaymentList: null,
  med6LoaStudentList: null,
  estimatesList: null,
  statusDropdownData: null,
  estimatesStep2List: null,
  nonClinicalEstimatesList: null,
  clinicalLoaList: null,
  clinicalCreateLoaList: null,
  dueStep2InvoiceList: null,
  med6LoaVerifyList: null,
  invoiceRemList: null,
  invoiceRemListBySemester: null,
  currentFutureSemesterList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_INVOICE_HEAD_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NCI_HEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nonCInvoiceHeadList: action.result && action.result.data,
    };
  }

  case GET_INVOICE_HEAD_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_INVOICE_SEMESTER_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_INVOICE_SEMESTER_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      invoiceSemesterList: action.result && action.result.data,
    };
  }

  case GET_INVOICE_SEMESTER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NCI_STUDENT_LIST_STEP1_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NCI_STUDENT_LIST_STEP1_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentListStep1NCInvoice: action.result && action.result.data,
    };
  }

  case GET_NCI_STUDENT_LIST_STEP1_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NCI_STUDENT_LIST_STEP2_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NCI_STUDENT_LIST_STEP2_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentListStep2NCInvoice: action.result && action.result.data,
    };
  }

  case GET_NCI_STUDENT_LIST_STEP2_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_INVOICE_TITLE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_INVOICE_TITLE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      invoiceTitleList: action.result && action.result.data,
    };
  }
  case GET_INVOICE_TITLE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NCI_SEARCH_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NCI_INVOICE_SEARCH_LIST_REQUESTED: {
    return {
      ...state,
      nclLoading: true,
      error: false,
      loaded: false,
      nonClinicalInvoiceList: null,
    };
  }
  case GET_NCI_SEARCH_LIST_SUCCESS: {
    return {
      ...state,
      nclLoading: false,
      error: false,
      loaded: true,
      nonClinicalInvoiceList: action.result && action.result.data,
    };
  }
  case GET_NCI_INVOICE_SEARCH_LIST_FAILURE: {
    return {
      ...state,
      nclLoading: false,
      error: true,
      loaded: false,
      nonClinicalInvoiceList: null,
    };
  }
  case GET_NCI_SEARCH_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_DUE_INVOICE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      dueInvoiceList: action.result && action.result.data,
    };
  }
  // case GET_DUE_INVOICE_LIST_SUCCESS: {
  //   return {
  //     ...state,
  //     loading: false,
  //     error: false,
  //     loaded: true,
  //     dueInvoiceStep2List: action.result && action.result.data,
  //   };
  // }
  case GET_CI_STUDENT_LIST_STEP1_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      step1ListClinicalInvoice: action.result && action.result.data,
    };
  }
  case GET_CI_STUDENT_LIST_STEP2_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      step2ListClinicalInvoice: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_INVOICE_LIST_REQUESTED: {
    return {
      ...state,
      clinicalInvoiceLoading: true,
      error: false,
      loaded: false,
      clinicalInvoiceList: null,
    };
  }
  case GET_CLINICAL_INVOICE_LIST_SUCCESS: {
    return {
      ...state,
      clinicalInvoiceLoading: false,
      error: false,
      loaded: true,
      clinicalInvoiceList: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_INVOICE_LIST_FAILURE: {
    return {
      ...state,
      clinicalInvoiceLoading: false,
      error: true,
      loaded: false,
      clinicalInvoiceList: null,
    };
  }
  case GET_CI_HEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalInvoiceHead: action.result && action.result.data,
    };
  }
  case GET_CI_SEMESTER_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalInvoiceSemester: action.result && action.result.data,
    };
  }
  case GET_HOUSING_INVOICE_TITLE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      housingInvoiceTitle: action.result && action.result.data,
    };
  }
  case GET_HOUSING_INVOICE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      housingInvoiceList: action.result && action.result.data,
    };
  }
  case GET_HOUSING_HEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      housingInvoiceHeadList: action.result && action.result.data,
    };
  }
  case GET_HOUSING_INVOICE_LIST_STEP1_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      step1HousingInvoiceList: action.result && action.result.data,
    };
  }
  case GET_HOUSING_INVOICE_LIST_STEP2_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      step2HousingInvoiceList: action.result && action.result.data,
    };
  }
  case GET_NCI_ESTIMATES_LIST_STEP1_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NCI_ESTIMATES_LIST_STEP1_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      estimatesList: action.result && action.result.data,
    };
  }

  case GET_NCI_ESTIMATES_LIST_STEP1_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STATUS_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_STATUS_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      statusDropdownData: action.result && action.result.data,
    };
  }

  case GET_STATUS_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NCI_ESTIMATES_LIST_STEP2_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NCI_ESTIMATES_LIST_STEP2_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      estimatesStep2List: action.result && action.result.data,
    };
  }

  case GET_NCI_ESTIMATES_LIST_STEP2_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NCI_ESTIMATES_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NCI_ESTIMATES_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nonClinicalEstimatesList: action.result && action.result.data,
    };
  }

  case GET_NCI_ESTIMATES_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_WEEKS_PAID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      weeksPaid: action.result && action.result.data,
    };
  }
  case GET_PDF_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      pdfdata: action.result && action.result.data,
    };
  }

	  case GET_MED_LOA_CREATE_LIST_REQUESTED: {
    return {
		  ...state,
		  loading: true,
		  loaded: false
    };
	  }
	  case GET_MED_LOA_CREATE_LIST_SUCCESS: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true,
		  medLoaCreateList: action.result && action.result.data,
    };
	  }
	  case GET_MED_LOA_CREATE_LIST_FAILURE: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true
    };
	  }

	  case GET_MED6_LOA_INVOICE_LIST_REQUESTED: {
    return {
		  ...state,
		  loading: true,
		  loaded: false
    };
	  }
	  case GET_MED6_LOA_INVOICE_LIST_SUCCESS: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true,
		  med6LoaInvoiceList: action.result && action.result.data,
    };
	  }
	  case GET_MED6_LOA_INVOICE_LIST_FAILURE: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true
    };
	  }

	  case GET_MED_LOA_PAYMENT_LIST_REQUESTED: {
    return {
		  ...state,
		  loading: true,
		  loaded: false
    };
	  }
	  case GET_MED_LOA_PAYMENT_LIST_SUCCESS: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true,
		  medLoaPaymentList: action.result && action.result.data,
    };
	  }
	  case GET_MED_LOA_PAYMENT_LIST_FAILURE: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true
    };
	  }

	  case GET_MED6_LOA_STUDENT_LIST_REQUESTED: {
    return {
		  ...state,
		  loading: true,
		  loaded: false
    };
	  }
	  case GET_MED6_LOA_STUDENT_LIST_SUCCESS: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true,
		  med6LoaStudentList: action.result && action.result.data,
    };
	  }
	  case GET_MED6_LOA_STUDENT_LIST_FAILURE: {
    return {
		  ...state,
		  loading: false,
		  error: false,
		  loaded: true
    };
	  }

  case CLEAR_STUDENT_LIST_EMPTY:
    return {
      ...state,
      step1HousingInvoiceList: action.payload,
      studentListStep1NCInvoice: action.payload,
      step1ListClinicalInvoice: action.payload
    };

  case GET_CLINICAL_CREATE_LOA_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_CLINICAL_CREATE_LOA_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalCreateLoaList: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_CREATE_LOA_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_CLINICAL_LOA_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_CLINICAL_LOA_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalLoaList: action.result && action.result.data,
    };
  }

  case GET_CLINICAL_LOA_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_DUE_STEP2_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      dueStep2InvoiceList: action.result && action.result.data,
    };
  }
  case GET_MED6_LOA_VERIFY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      med6LoaVerifyList: action.result,
    };
  }
  case GET_INVOICE_REMINDER_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      invoiceRemList: action.result
    };
  }
  case GET_INVOICE_REMINDER_LIST_BY_SEMESTER: {
    return {
      ...state,
      loading: false,
      loaded: true,
      invoiceRemListBySemester: action.result
    };
  }

  case GET_INVOICE_SEMESTER_CUR_FUT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICE_SEMESTER_CUR_FUT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      currentFutureSemesterList: action.result && action.result.data,
    };
  }
  case GET_INVOICE_SEMESTER_CUR_FUT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
    };
  }

  default: {
    return {
      ...state
    };
  }
  }
};

export const getNCInvoiceHeadList = () => {
  return {
    types: [GET_INVOICE_HEAD_LIST_REQUESTED, GET_NCI_HEAD_LIST_SUCCESS, GET_INVOICE_HEAD_LIST_FAILURE],
    promise: (client) => client.get('nonCInvoiceHeadList')
  };
};

export const getNCInvoiceSemesterList = () => {
  return {
    types: [GET_INVOICE_SEMESTER_REQUESTED, GET_INVOICE_SEMESTER_SUCCESS, GET_INVOICE_SEMESTER_FAILURE],
    promise: (client) => client.get('invoiceSemester')
  };
};

export const getInvoiceTitleList = () => {
  return {
    types: [GET_INVOICE_TITLE_REQUESTED, GET_INVOICE_TITLE_SUCCESS, GET_INVOICE_TITLE_FAILURE],
    promise: (client) => client.get('invoiceTitle')
  };
};

export const getNCIstudentListForStep1 = (params) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP1_REQUESTED, GET_NCI_STUDENT_LIST_STEP1_SUCCESS, GET_NCI_STUDENT_LIST_STEP1_FAILURE],
    promise: (client) => client.get('studentListForStep1NCI', { params })
  };
};

export const getNCIstudentListForStep2 = (params) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP2_REQUESTED, GET_NCI_STUDENT_LIST_STEP2_SUCCESS, GET_NCI_STUDENT_LIST_STEP2_FAILURE],
    promise: (client) => client.get('studentListForStep2NCI', { params })
  };
};

export const createNCInvoice = (data, invoiceType) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post(`create${invoiceType}Invoice`, { data })
  };
};

export const getNonClinicalInvoiceList = (data) => {
  return {
    types: [GET_NCI_INVOICE_SEARCH_LIST_REQUESTED, GET_NCI_SEARCH_LIST_SUCCESS, GET_NCI_INVOICE_SEARCH_LIST_FAILURE],
    promise: (client) => client.post('searchNonClinicalInvoiceList', { data })
  };
};

export const getDueInvoiceList = (data) => {
  return {
    types: [GET_NCI_SEARCH_LIST_REQUESTED, GET_DUE_INVOICE_LIST_SUCCESS, GET_NCI_SEARCH_LIST_FAILURE],
    promise: (client) => client.post('dueInvoiceList', { data })
  };
};

export const getDueInvoiceStep2List = (data) => {
  return {
    types: [GET_NCI_SEARCH_LIST_REQUESTED, GET_DUE_STEP2_LIST_SUCCESS, GET_NCI_SEARCH_LIST_FAILURE],
    promise: (client) => client.post('dueInvoiceStep2List', { data })
  };
};

export const createDueInvoice = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('createDueInvoice', { data })
  };
};

export const getCIstudentListStep1 = (params) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP1_REQUESTED, GET_CI_STUDENT_LIST_STEP1_SUCCESS, GET_NCI_STUDENT_LIST_STEP1_FAILURE],
    promise: (client) => client.get('studentListStep1CI', { params })
  };
};

export const getCIstudentListStep2 = (params) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP1_REQUESTED, GET_CI_STUDENT_LIST_STEP2_SUCCESS, GET_NCI_STUDENT_LIST_STEP1_FAILURE],
    promise: (client) => client.get('studentListStep2CI', { params })
  };
};

export const createClinicalInvoice = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('createClinicalInvoice', { data })
  };
};

export const getClinicalInvoiceList = (data) => {
  return {
    types: [GET_CLINICAL_INVOICE_LIST_REQUESTED, GET_CLINICAL_INVOICE_LIST_SUCCESS, GET_CLINICAL_INVOICE_LIST_FAILURE],
    promise: (client) => client.post('searchClinicalInvoiceList', { data })
  };
};

export const getClinicalInvoiceHead = (data) => {
  return {
    types: [GET_INVOICE_HEAD_LIST_REQUESTED, GET_CI_HEAD_LIST_SUCCESS, GET_INVOICE_HEAD_LIST_FAILURE],
    promise: (client) => client.get(`clinicalInvoiceHead/${data}`)
  };
};

export const getClinicalInvoiceSemesterList = () => {
  return {
    types: [GET_INVOICE_SEMESTER_REQUESTED, GET_CI_SEMESTER_SUCCESS, GET_INVOICE_SEMESTER_FAILURE],
    promise: (client) => client.get('clinicalInvoiceSemester')
  };
};

export const getHousingInvoiceTitleList = () => {
  return {
    types: [GET_INVOICE_TITLE_REQUESTED, GET_HOUSING_INVOICE_TITLE_SUCCESS, GET_INVOICE_TITLE_FAILURE],
    promise: (client) => client.get('housingInvoiceTitle')
  };
};

export const getHousingInvoiceList = (data) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP1_REQUESTED, GET_HOUSING_INVOICE_LIST_SUCCESS, GET_NCI_STUDENT_LIST_STEP1_FAILURE],
    promise: (client) => client.post('searchHousingInvoiceList', { data })
  };
};

export const getHousingInvoiceHead = () => {
  return {
    types: [GET_INVOICE_HEAD_LIST_REQUESTED, GET_HOUSING_HEAD_LIST_SUCCESS, GET_INVOICE_HEAD_LIST_FAILURE],
    promise: (client) => client.get('housingInvoiceHeadList')
  };
};

export const getHousingInvoiceStep1List = (params) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP1_REQUESTED, GET_HOUSING_INVOICE_LIST_STEP1_SUCCESS, GET_NCI_STUDENT_LIST_STEP1_FAILURE],
    promise: (client) => client.get('step1HousingInvoiceList', { params })
  };
};

export const getHousingInvoiceStep2List = (params) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP1_REQUESTED, GET_HOUSING_INVOICE_LIST_STEP2_SUCCESS, GET_NCI_STUDENT_LIST_STEP1_FAILURE],
    promise: (client) => client.get('step2HousingInvoiceList', { params })
  };
};

export const getEstimateList = (params) => {
  return {
    types: [GET_NCI_ESTIMATES_LIST_STEP1_REQUESTED, GET_NCI_ESTIMATES_LIST_STEP1_SUCCESS, GET_NCI_ESTIMATES_LIST_STEP1_FAILURE],
    promise: (client) => client.get('getEstimateList', { params })
  };
};

export const getStatusDropdown = () => {
  return {
    types: [GET_STATUS_DROPDOWN_REQUESTED, GET_STATUS_DROPDOWN_SUCCESS, GET_STATUS_DROPDOWN_FAILURE],
    promise: (client) => client.get('statusDropdown')
  };
};

export const getEstimateStudentList = (params) => {
  return {
    types: [GET_NCI_ESTIMATES_LIST_STEP2_REQUESTED, GET_NCI_ESTIMATES_LIST_STEP2_SUCCESS, GET_NCI_ESTIMATES_LIST_STEP2_FAILURE],
    promise: (client) => client.get('getEstimateStudentList', { params })
  };
};

export const createNonClinicalEstimate = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('createEstimates', { data })
  };
};

export const getNonClinicalEstimateList = (params) => {
  return {
    types: [GET_NCI_ESTIMATES_LIST_REQUESTED, GET_NCI_ESTIMATES_LIST_SUCCESS, GET_NCI_ESTIMATES_LIST_FAILURE],
    promise: (client) => client.post('estimateList', { params })
  };
};

export const getWeeksPaid = (data) => {
  return {
    types: [GET_INVOICE_HEAD_LIST_REQUESTED, GET_WEEKS_PAID_SUCCESS, GET_INVOICE_HEAD_LIST_FAILURE],
    promise: (client) => client.get(`getWeeksPaid/${data}`)
  };
};

export const getInvoicePdfData = (id, type) => {
  return {
    types: [GET_INVOICE_HEAD_LIST_REQUESTED, GET_PDF_LIST_SUCCESS, GET_INVOICE_HEAD_LIST_FAILURE],
    promise: (client) => client.get(`download${type}InvoicePDF/${id}`)
  };
};

export const getMedLoaCreateList = (params) => {
  return {
    types: [GET_MED_LOA_CREATE_LIST_REQUESTED, GET_MED_LOA_CREATE_LIST_SUCCESS, GET_MED_LOA_CREATE_LIST_FAILURE],
    promise: (client) => client.get('getMedLoaCreateList', { params })
  };
};

export const getMed6loaInvoiceList = (params) => {
  return {
    types: [GET_MED6_LOA_INVOICE_LIST_REQUESTED, GET_MED6_LOA_INVOICE_LIST_SUCCESS, GET_MED6_LOA_INVOICE_LIST_FAILURE],
    promise: (client) => client.get('getMed6loaInvoiceList', { params })
  };
};

export const getMedLoaPaymentList = (params) => {
  return {
    types: [GET_MED_LOA_PAYMENT_LIST_REQUESTED, GET_MED_LOA_PAYMENT_LIST_SUCCESS, GET_MED_LOA_PAYMENT_LIST_FAILURE],
    promise: (client) => client.get('getMedLoaPaymentList', { params })
  };
};

export const getMed6loaStudentList = (params) => {
  return {
    types: [GET_MED6_LOA_STUDENT_LIST_REQUESTED, GET_MED6_LOA_STUDENT_LIST_SUCCESS, GET_MED6_LOA_STUDENT_LIST_FAILURE],
    promise: (client) => client.get('getMed6loaStudentList', { params })
  };
};

export const clearStudentList = (data) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_STUDENT_LIST_EMPTY,
      payload: data,
    });
  };
};

export const invoiceSendEmail = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('nonCInvoicesendEmail', { data })
  };
};

export const saveMed6Loa = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveMed6Loa', { data })
  };
};

export const getClincialCreateLoaList = (params) => {
  return {
    types: [GET_CLINICAL_CREATE_LOA_LIST_REQUESTED, GET_CLINICAL_CREATE_LOA_LIST_SUCCESS, GET_CLINICAL_CREATE_LOA_LIST_FAILURE],
    promise: (client) => client.get('getClincialCreateLoaList', { params })
  };
};

export const getClincialLoaList = (params) => {
  return {
    types: [GET_CLINICAL_LOA_LIST_REQUESTED, GET_CLINICAL_LOA_LIST_SUCCESS, GET_CLINICAL_LOA_LIST_FAILURE],
    promise: (client) => client.get('getClincialLoaList', { params })
  };
};

export const saveClincalLoa = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveClincalLoa', { data })
  };
};

export const verifyAndPayMed6Loa = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('payMed6LOAVerify', { data })
  };
};

export const getMed6LOAVerifyList = (params) => {
  return {
    types: [GET_CLINICAL_LOA_LIST_REQUESTED, GET_MED6_LOA_VERIFY_LIST_SUCCESS, GET_CLINICAL_LOA_LIST_FAILURE],
    promise: (client) => client.post('getMed6LOAVerifyList', { params })
  };
};

export const getInvoiceRemindList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_INVOICE_REMINDER_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getInvoiceRemindList', { params })
  };
};
export const getInvoiceRemindListBySemester = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_INVOICE_REMINDER_LIST_BY_SEMESTER, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getInvoiceRemindListBySemester', { params })
  };
};
export const addInvoiceReminder = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addInvoiceReminder', { data })
  };
};

export const getSpecialIstudentListForStep1 = (params) => {
  return {
    types: [GET_NCI_STUDENT_LIST_STEP1_REQUESTED, GET_NCI_STUDENT_LIST_STEP1_SUCCESS, GET_NCI_STUDENT_LIST_STEP1_FAILURE],
    promise: (client) => client.get('studentListForStep1Special', { params })
  };
};

export const getCurrentFutureSemester = () => {
  return {
    types: [GET_INVOICE_SEMESTER_CUR_FUT_REQUESTED, GET_INVOICE_SEMESTER_CUR_FUT_SUCCESS, GET_INVOICE_SEMESTER_CUR_FUT_FAILURE],
    promise: (client) => client.get('getCurrentFutureSemester')
  };
};
