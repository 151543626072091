export const ACTION_REQUESTED = 'calendar/ACTION_REQUESTED';
export const ACTION_SUCCESS = 'calendar/ACTION_SUCCESS';
export const ACTION_FAILURE = 'calendar/ACTION_FAILURE';

export const GET_EVENT_TYPES_SUCCESS = 'calendar/GET_EVENT_TYPES_SUCCESS';

export const GET_USERS_LIST_SUCCESS = 'calendar/GET_USERS_LIST_SUCCESS';

export const GET_EVENT_LIST_SUCCESS = 'calendar/GET_EVENT_LIST_SUCCESS';

export const GET_EVENT_GROUP_SUCCESS = 'calendar/GET_EVENT_GROUP_SUCCESS';

const HANDLE_SELECTED_EVENT = 'calendar/HANDLE_SELECTED_EVENT';
const HANDLE_SHOW_ADD_EVENT = 'calendar/HANDLE_SHOW_ADD_EVENT';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  showAddEvent: false,
  selectedEvent: { },
  usersList: [],
  eventTypesList: [],
  eventsList: [],
  eventGroup: []
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_EVENT_TYPES_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      eventTypesList: action.result.data
    };
  }
  case GET_USERS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      usersList: action.result.data && action.result.data.map((e) => ({
        label: e.name,
        value: e.id
      }))
    };
  }
  case GET_EVENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      eventsList: action.result.data
    };
  }
  case GET_EVENT_GROUP_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      eventGroup: action.result.data
    };
  }
  case HANDLE_SELECTED_EVENT:
    return {
      ...state,
      loading: false,
      selectedEvent: action.payload,
    };
  case HANDLE_SHOW_ADD_EVENT:
    return {
      ...state,
      loading: false,
      showAddEvent: action.payload,
    };
  default:
    return state;
  }
};

export const setSelectedEvent = (data) => {
  return {
    type: HANDLE_SELECTED_EVENT,
    payload: data,
  };
};

export const setShowAddEvent = (data) => {
  return {
    type: HANDLE_SHOW_ADD_EVENT,
    payload: data,
  };
};

export const setEventsMock = (data) => {
  return {
    type: GET_EVENT_LIST_SUCCESS,
    result: { data }
  };
};

export const addOrUpdateEvent = (data) => {
  return {
    types: [ACTION_REQUESTED, ACTION_SUCCESS, ACTION_FAILURE],
    promise: (client) => client.post('saveCalendarEvent', { data })
  };
};

export const getEventTypes = () => {
  return {
    types: [ACTION_REQUESTED, GET_EVENT_TYPES_SUCCESS, ACTION_FAILURE],
    promise: (client) => client.get('getCalendarEventTypes')
  };
};

export const deleteEvent = (eventId) => {
  return {
    types: [ACTION_REQUESTED, ACTION_SUCCESS, ACTION_FAILURE],
    promise: (client) => client.del(`deletelCaledarEvent/${eventId}`)
  };
};

export const getUsersList = () => {
  return {
    types: [ACTION_REQUESTED, GET_USERS_LIST_SUCCESS, ACTION_FAILURE],
    promise: (client) => client.get('getUsersList')
  };
};

export const getEventsList = (data) => {
  return {
    types: [ACTION_REQUESTED, GET_EVENT_LIST_SUCCESS, ACTION_FAILURE],
    promise: (client) => client.get(`getCalendarEventsList/${data}`)
  };
};

export const getEventGroup = () => {
  return {
    types: [ACTION_REQUESTED, GET_EVENT_GROUP_SUCCESS, ACTION_FAILURE],
    promise: (client) => client.get('getCalendarEventGroup')
  };
};
