/* eslint-disable import/no-cycle */
import { pick } from 'lodash';
import { ROUTE_OPTIONS, APPConfig } from '../../constants';
import { getUserRoleId } from './helperFunction';

const SUPER_ADMIN = APPConfig.SUPER_ADMIN;

const checkAccess = (access) => {
  if (getUserRoleId() === SUPER_ADMIN || getUserRoleId() === APPConfig.SUPER_USER) {
    return {
      delete: true,
      edit: true,
      write: true,
      read: true
    };
  }
  let result = {
    delete: false,
    edit: false,
    write: false,
    read: false
  };
  const components = window.localStorage.getItem('userAccessRights')
    ? JSON.parse(window.localStorage.getItem('userAccessRights')).components
    : [];
  const component = components[access];
  if (component && component <= 15) {
    const accessMode = component.toString(2) || 0; //  get bits
    const bits = accessMode.padStart(4, '0');
    result = {
      delete: parseInt(bits[0], 10) !== 0,
      edit: parseInt(bits[1], 10) !== 0,
      write: parseInt(bits[2], 10) !== 0,
      read: component > 0,
    };
  }
  return result;
};

const getMenuByAccess = (menuName) => {
  const menuAccess = window.localStorage.getItem('userAccessRights')
    ? JSON.parse(window.localStorage.getItem('userAccessRights')).menus
    : [];
  const components = window.localStorage.getItem('userAccessRights')
    ? JSON.parse(window.localStorage.getItem('userAccessRights')).components
    : [];

  if (getUserRoleId() === SUPER_ADMIN || getUserRoleId() === APPConfig.SUPER_USER) {
    if (typeof menuName === 'string' && menuName !== 'ADMIN') {
      return ROUTE_OPTIONS[menuName];
    } if (typeof menuName === 'string' && menuName === 'ADMIN' && getUserRoleId() === SUPER_ADMIN) {
      return { children: ROUTE_OPTIONS[menuName]?.children.filter((x) => !x.superUserView) };
    } if (typeof menuName === 'string' && menuName === 'ADMIN' && getUserRoleId() === APPConfig.SUPER_USER) {
      return ROUTE_OPTIONS[menuName];
    }
    return menuName;
  }
  // const routeOptions = (typeof menuName === 'string') ? ROUTE_OPTIONS[menuName] : menuName;
  const routeOptions = (typeof menuName === 'string') ? menuName === 'ADMIN' && getUserRoleId() === APPConfig.SUPER_USER ? ROUTE_OPTIONS[menuName] : { children: ROUTE_OPTIONS[menuName]?.children.filter((x) => !x.superUserView) } : menuName;
  const sideMenuAccess = {
    ...ROUTE_OPTIONS[menuName],
    children: routeOptions && routeOptions.children && routeOptions.children.filter((e) => {
      return menuAccess[e.link] > 0 || components[e.link] > 0;
    })
  };

  return sideMenuAccess;
};

const getAccessModesFromBit = (bitString) => {
  const bits = bitString.padStart(4, '0');
  return {
    // all: (parseInt(bits[0], 10) !== 0) && (parseInt(bits[1], 10) !== 0) && (parseInt(bits[2], 10) !== 0) && (parseInt(bits[3], 10) !== 0),
    delete: parseInt(bits[0], 10) !== 0,
    edit: parseInt(bits[1], 10) !== 0,
    write: parseInt(bits[2], 10) !== 0,
    read: parseInt(bits[3], 10) !== 0,
    all: parseInt(bits[0], 10) !== 0 && parseInt(bits[1], 10) !== 0 && parseInt(bits[2], 10) !== 0 && parseInt(bits[3], 10) !== 0,
  };
};

const getAccessModesFromBoolean = (access) => {
  const obj = {
    ...access,
    read: access.read || access.write || access.edit || access.delete
  };
  const read = obj.read ? '1' : '0';
  const write = obj.write ? '1' : '0';
  const edit = obj.edit ? '1' : '0';
  const isDelete = obj.delete ? '1' : '0';
  const accessMode = isDelete + edit + write + read;
  return { accessMode: parseInt(accessMode || 0, 2) };
};

const getConstructedMenuAccess = (menus) => {
  const constructedMenus = menus.map((menu) => {
    const components = menu.components.map((comp) => {
      const subComponents = comp.subComponents && comp.subComponents.length > 0 && comp.subComponents.map((subComp) => {
        return {
          ...subComp, ...getAccessModesFromBit(subComp.accessMode.toString(2) || 0)
        };
      });
      const accessMode = comp.accessMode.toString(2); //  get bits
      return {
        ...comp, ...getAccessModesFromBit(accessMode || 0), subComponents
      };
    });

    return { ...menu, components };
  });

  return constructedMenus;
};

const getUpdatedAccessMenusToSubmit = (menus) => {
  const destructuredMenus = menus.map((menu) => {
    const components = menu.components.map((comp) => {
      const subComponents = comp.subComponents && comp.subComponents.length > 0 && comp.subComponents.map((subComp) => {
        const subCompAccessMode = getAccessModesFromBoolean(pick(subComp, ['read', 'write', 'edit', 'delete']));
        return {
          ...subComp, ...subCompAccessMode
        };
      });
      const accessMode = getAccessModesFromBoolean(pick(comp, ['read', 'write', 'edit', 'delete']));
      return {
        ...comp, ...accessMode, subComponents
      };
    });

    return { ...menu, components };
  });
  return destructuredMenus;
};

export {
  checkAccess,
  getMenuByAccess,
  getConstructedMenuAccess,
  getUpdatedAccessMenusToSubmit,
};
