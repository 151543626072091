/* eslint-disable */
import pako from 'pako';
import React from 'react';
import { formatUrl } from '../../helpers/ApiClient';
import { ROUTE_OPTIONS, SIDE_MENU_CHANGE, APPConfig, CONSTANTS } from '../../constants';
import moment from 'moment';
import * as Icon from 'react-feather';
import navigationConfig from './../../../src/configs/navigationConfig'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery';
import { Link } from 'react-router-dom';

export const getUserRoleId = () => {
  const roleId =  window.localStorage.getItem('userData') && JSON.parse(
    window.localStorage.getItem('userData') 
  ).roleId

  return roleId
}

// get logged in user Id
export const getAuthUser = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData') || {})
  const user = userData && userData.userDetail
  return user
}

// get logged in user Id
export const getAuthUserId = () => {
  const localData = window.localStorage.getItem('userData')
  const userData = localData &&  JSON.parse( localData || {})
  const userId = userData && userData.userDetail && userData.userDetail.id
  return userId
}

export const getAuthUserData = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData')) || {}
  return userData
}

export const getTime = dateObj => {
  const date = dateObj.split(':')
  return new Date().setHours(
    Number(date[0]),
    Number(date[1]),
    Number(date[2]) || 0
  )
}

export const getDate = dateObj => {
  const dateFormat = dateObj && moment(dateObj.toString().substring(0, 10)).format("YYYY/MM/DD")
  const date = new Date(dateFormat)
  return date
}

export const dobMaxDate = () => {
  const date = moment().subtract(18, 'years').format('MM-DD-YYYY');
  return date;
}

export const disabledDays = () => {
  return [
    function (date = new Date()) {
      return (date.getDay() > 0);
    }
  ]
}

export const isFirstDayMonth = () => {
  return [
    function (date = new Date()) {
      return date.getDate() > 1
    }
  ]
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const decriptResponse = base64Data => {
  const strData = atob(base64Data)
  // Convert binary string to character-number array
  const charData = strData.split('').map(x => {
    return x.charCodeAt(0)
  })
  // Turn number array into byte-array
  const binData = new Uint8Array(charData)
  // Pako magic
  const data = pako.inflate(binData)
  // Convert gunzipped byteArray back to ascii string:
  // const strDataResult = String.fromCharCode.apply(null, new Uint16Array(data));
  return JSON.parse(new TextDecoder('utf-8').decode(data))
}

export const truncate = (n, len) => {
  const ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase()
  let filename = n.replace(`.${ext}`, '')
  if (filename.length <= len) {
    return n
  }
  filename = filename.substr(0, len) + (n.length > len ? '[...]' : '')
  return `${filename}.${ext}`
}

export const getObjectValue = (cb, defaultValue) => {
  try {
    return cb()
  } catch (e) {
    return defaultValue
  }
}

const download = (blob, filename) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}

export const fileExportFromStream = ({
  method = 'GET',
  path,
  params = {},
  body,
  fileName = 'export.txt',
  setLoader = () =>{}
}) => {
  setLoader(true)
  const formattedUrl = formatUrl(path)
  const constructedUrl = new URL(formattedUrl)
  Object.keys(params).forEach(key =>
    constructedUrl.searchParams.append(key, params[key])
  )

  const fetchParams = {
    method,
    'Content-Type': 'application/json',
    headers: new Headers({
      Authorization: `Bearer ${JSON.parse(window.localStorage.getItem('userData')).access_token
        }`,
      'Content-Type': 'application/json',
    })
  }

  fetchParams.body = JSON.stringify(body)
  fetch(constructedUrl, { ...fetchParams }).then(res => {
    res.blob().then(blob => {
      download(blob, fileName)
      setLoader(false)
    })
  })
}

export const fcmNotifyClient = (body) =>{

      fetch(CONSTANTS.fcmUrl , {
          method: 'POST',
          headers: {
              'Authorization': CONSTANTS.fcmAuthorization,
              'Content-Type': 'application/json'
          },
          body:JSON.stringify(body)
      })
}

export const changeEmployeeSideMenu = details => {
  const jobOffered = details.employeeDetails && details.employeeDetails.isOfferLetterAccepted;
  const status= details.employeeDetails?.statusName === CONSTANTS.JOB_OFFERED;
  const isEmployee = details.employeeDetails.id === getAuthUserData().employeeId;
  let menu = []
  if (!jobOffered && isEmployee && status) {
    menu = ROUTE_OPTIONS.EMPLOYEE.children.filter(data => 
     SIDE_MENU_CHANGE.EMPLOYEE_JOB_OFFER.includes(data.name)
    )
  }
  else{
    menu = ROUTE_OPTIONS.EMPLOYEE.children
  }
  return menu
}

export const changeSideMenu = details => {
  const StatusId = details.status && details.status.leadStatusId
  const programId = details.program && details.program.courseId
  const immigrationRequired = details?.immigrationRequired
  let menu = []
  let menuRequired = ROUTE_OPTIONS.LEAD.PROFILE.children.filter(e => e.name !== "immigration")
  if(immigrationRequired){
    menuRequired = ROUTE_OPTIONS.LEAD.PROFILE.children
  }
  
  if (Number(StatusId) < 3 && Number(programId) !== 81) {
    menu = menuRequired.filter(data => {
      return SIDE_MENU_CHANGE.INITIAL_STUDENTID.includes(data.name)
    })
  }
  if (
    Number(StatusId) > 2 &&
    Number(StatusId) < 6 &&
    Number(programId) !== 81
  ) {
    menu = menuRequired.filter(data => {
      return SIDE_MENU_CHANGE.CREATED_PROFILE_STUDENTID.includes(data.name)
    })
  }
  if (Number(StatusId) > 5 && Number(programId) !== 81) {
    menu = menuRequired.filter(data => {
      return SIDE_MENU_CHANGE.ACCEPTED_STUDENTID.includes(data.name)
    })
  }
  if (Number(StatusId) === 10 && Number(programId) !== 81) {
    menu = menuRequired.filter((data) => {
      return SIDE_MENU_CHANGE.STUDENT_STUDENTID.includes(data.name);
    });
  }
  if (Number(StatusId) < 3 && Number(programId) === 81) {
    menu = menuRequired.filter(data => {
      return SIDE_MENU_CHANGE.ISTANBUL_INITIAL_STUDENTID.includes(data.name)
    })
  }
  if (
    Number(StatusId) > 2 &&
    Number(StatusId) < 6 &&
    Number(programId) === 81
  ) {
    menu = menuRequired.filter(data => {
      return SIDE_MENU_CHANGE.ISTANBUL_CREATED_PROFILE_STUDENTID.includes(data.name)
    })
  }
  if (Number(StatusId) > 5 && Number(programId) === 81) {
    menu = menuRequired.filter(data => {
      return SIDE_MENU_CHANGE.ISTANBUL_ACCEPTED_STUDENTID.includes(data.name)
    })
  }
  return menu
}

export const accesRightSetting = () => {
  const menuAccess = window.localStorage.getItem('userAccessRights') 
    ? JSON.parse(window.localStorage.getItem('userAccessRights')).menus
    : []

  const menuName = window.location.pathname.split('/')[1];
  const menuList = menuName === 'student' ? navigationConfig[1] : navigationConfig.find(e => e.id === menuName);

  if (getUserRoleId() === APPConfig.SUPER_ADMIN || getUserRoleId() === APPConfig.SUPER_USER) {
    return { menuList: (menuList && menuList.children) || [] };
  } else {
    let sideMenuAccess = { menuList: [] }
    if (menuList && menuList.children && menuList.children.length > 0) {
      sideMenuAccess = {
        ...menuList,
        children: menuList && menuList.children && menuList.children.filter(e => {
          return menuAccess[e.accessRight] > 0
        })
      }
      return { menuList: sideMenuAccess.children || [] };
    }
    return sideMenuAccess;
  }
}

export const getDifferenceInWeeks = (date1, date2) => {
  const diffInMs = Math.abs(date2 - date1);

  if (moment(date2).format('MM/DD/YYYY') === moment(date1).format('MM/DD/YYYY')) {
    return 0;
  }
  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24 * 7));
}

export const getSideMenu = (data, type) => {
  const userData = window.localStorage.getItem("userData");
  const datauser = JSON.parse(userData);
  if (datauser.userType !== type) {
    return {
      ...data,
      children: data.children.filter(e => !e.adminCantView)
    }
  }
  return data;
}

export const getStudentSideMenu = (details) => {
  const leadStatus = details && details.leadStatusId;
  const studentStatus = details && details.status;
  const leadId= details && details.leadId;
  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const userType = userData && userData.userType;
  const studentCourseStatus = details && details.currentCourseStatus;
  const passUsmlTest = details && details.usmleTestStatus;
  const studentTypeName = details && details.studentTypeName
  const studentImmigration = details && details.immigrationRequired
  const financialStatus = details && details.financialStatus
  const notifyStatus = details && details.forcePersonalInfoUpdate
  let menuChildren = ROUTE_OPTIONS.STUDENT.children;
  const cohort = details?.cohort;
  if(studentImmigration){
    menuChildren = ROUTE_OPTIONS.STUDENT.children
  }
  else{
    menuChildren = ROUTE_OPTIONS.STUDENT.children.filter(e => e.name !== "immigration").filter(e => e.name !== "semesterDeferment")
  }
  
  if(!leadId){
    menuChildren = menuChildren.filter(e => e.name !== "application")
  }

  if(studentCourseStatus !== "Clinical Medicine"){
    menuChildren = menuChildren.filter(e => e.name !== "snap-shot")
  }

  let menu = [];
  if((financialStatus === "Financially Blocked") && (userType === 'Student')){
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.FINANCIAL_BLOCK.includes(data.name)
    )
  } else if (userType === 'Student' && notifyStatus) {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.NOTIFY_STATUS.includes(data.name)
    )
  }
  else if ((studentStatus === 'Administrative Withdrawn') && userType !== 'Student') {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.STUDENT_WITHDRAWN_ADMIN.includes(data.name)
    )
  }
  else if ((studentStatus === 'Withdrawn' || studentStatus === 'Administrative Withdrawn') && userType === 'Student') {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.WITHDRAWN.includes(data.name)
    )
  }
  else if((leadStatus === CONSTANTS.LEAD_STATUS_FINANCIALLY_CLEARED) && cohort && userType === 'Student'){
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.STUDENT_SIDEMENU.includes(data.name)
    )
  }
  else if ((leadStatus === CONSTANTS.LEAD_STATUS_ACCEPTED) && userType === 'Student') {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.ACCEPTED_STUDENT.includes(data.name)
    )
  } else if ((leadStatus === CONSTANTS.LEAD_STATUS_PAID_SEAT_DEPOSIT || leadStatus === CONSTANTS.LEAD_STATUS_IMMIGRATION_CLEARED || (leadStatus === CONSTANTS.LEAD_STATUS_FINANCIALLY_CLEARED && !cohort)) && userType === 'Student') {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.PAID_FINANCIAL_IMMIGRATION.includes(data.name)
    )
  }
  else if ((studentStatus === 'Lead') && (userType === 'Student')) {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.STUDENT_LEAD.includes(data.name)
    )
  }
  else if (((studentStatus === 'Alumni') || (studentStatus === 'Alumni - FM') || (studentStatus === 'Alumni - MSC')) && (userType === 'Student')) {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.STUDENT_ALUMNI.includes(data.name)
    )
  }
  else if ((leadStatus === CONSTANTS.LEAD_STATUS_STUDENT || !leadStatus || studentStatus === 'transfer' ||
    studentStatus === 'Closed') && userType === 'Student') {
    if (passUsmlTest && (details?.stage === "CompletedClinical")) {
      menu = menuChildren.filter(data =>
        SIDE_MENU_CHANGE.STUDENT_SIDEMENU_GRAD.includes(data.name)
      )
    } else {
      menu = menuChildren.filter(data =>
        SIDE_MENU_CHANGE.STUDENT_SIDEMENU.includes(data.name)
      )
    }
  } else if (userType !== 'Student' && (studentStatus === 'Alumni' || studentTypeName === "Alumni")) {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.ALUMNI.includes(data.name)
    )
  }
  else if (userType === 'Student') {
    if (passUsmlTest && (details?.stage === "CompletedClinical")) {
      menu = menuChildren.filter(data =>
        SIDE_MENU_CHANGE.STUDENT_SIDEMENU_GRAD.includes(data.name)
      )
    } else {
      menu = menuChildren.filter(data =>
        SIDE_MENU_CHANGE.STUDENT_SIDEMENU.includes(data.name)
      )
    }
  }
  else {
    menu = menuChildren.filter(data =>
      SIDE_MENU_CHANGE.STUDENT_NOT_AUTHORIZED.includes(data.name)
    );
  }
  return menu;
}

export const urltoFile = (url, filename, mimeType) => {
  return (fetch(url)
    .then((res) => { return res.arrayBuffer(); })
    .then((buf) => { return new File([buf], filename, { type: mimeType }); })
  );
}

export const arrayBufferToBase64 = (buffer) => {
  let enc = new TextDecoder("utf-8");
  let bytes = new Uint8Array(buffer);
  return enc.decode(bytes);
}

export const webPageToFile = (fileName, elementId, formData) => {

  const input = document.getElementById(elementId);
  const pdf = new jsPDF("p", "mm", [297, 210]);
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      pdf.addImage(imgData, 'JPEG', 4, 0);
      urltoFile(pdf.output('datauristring'), fileName, "application/pdf").then(file => {
        formData.append(`file`, file);
        $(elementId).attr("hidden", true);
      })
    });
}

export const webPageToFileClinicalLog = (fileName, elementId, formData, uploadDaa) => {

  const input = document.getElementById(elementId);
  const pdf = new jsPDF("p", "mm", [297, 210]);
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      pdf.addImage(imgData, 'JPEG', 4, 0);
      urltoFile(pdf.output('datauristring'), fileName, "application/pdf").then(file => {
        formData.append(`file`, file);
        uploadDaa(formData)
        $(elementId).attr("hidden", true);
      })
    });
}

export const getGradeOnMarks = (data) => {
  if (data && Number(data) >= 0 && Number(data) <= 69) {
    return "F"
  } else if (data && Number(data) >= 70 && Number(data) <= 89) {
    return "P"
  // } else if (data && Number(data) >= 80 && Number(data) <= 89) {
  //   return "B"
  } else if (data && Number(data) >= 90 && Number(data) <= 100) {
    return "H"
  }
  else { return null }
}

export const getAnswerText = (answers, data) => {
  switch (answers && answers.answerType) {
    case CONSTANTS.SURVEY_ANSWER_TYPE_TEXT:
    case CONSTANTS.SURVEY_ANSWER_TYPE_DESCRIPTION:
      return ({
        ...answers.answer,
        answerText: ((answers.question.search("Date") !== -1) ? data[answers.questionId] && data[answers.questionId][0] && moment(data[answers.questionId][0]).format("MM-DD-YYYY") : data[answers.questionId]) || null
      })
    case CONSTANTS.SURVEY_ANSWER_TYPE_RADIO_1:
    case CONSTANTS.SURVEY_ANSWER_TYPE_RADIO_7:
    case CONSTANTS.SURVEY_ANSWER_TYPE_RADIO_8:
    case CONSTANTS.SURVEY_ANSWER_TYPE_RADIO_9:
      return (
        data[answers.questionId]
      )
    default:
      return answers.answer
  }
}

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}

export const disableSaturdayDays = () => {
  return [
    function (date = new Date()) {
      return (date.getDay() !== 6);
    }
  ]
}

export const studentTypeIcons = (data) => {
  switch (data) {
    case "On-Campus":
      return <Icon.Layers className='text-danger' size={16} />
    case "On-LOA":
      return <Icon.Layers className='text-warning' size={16} />
    default:
      return <Icon.Layers size={16} />
  }
}

export const getStudentCourseDisabled = (studentStatus) => {
  // return ((studentStatus === CONSTANTS.ADMINISTRATIVE_WITHDRAWN) || (studentStatus === CONSTANTS.TRANSFER) || (studentStatus === CONSTANTS.UNKNOWN) ||
  //   (studentStatus === CONSTANTS.CLOSED) || (studentStatus === CONSTANTS.WITHDRAWN))
  return ((studentStatus === CONSTANTS.UNKNOWN) || (studentStatus === CONSTANTS.CLOSED))
}

export const addingValues = (previous, next) => {
  return Number(previous) + Number(next)
}


//student notification

export const notificationType = (data, id) => {
  switch (data.notificationType) {
      case "DOCUMENT":
          return data.notification;
      case "INVOICE":
         return <Link to={`/student/details/invoice-payments/${id}`} className="d-block">{data.notification}</Link>
      case "INSURANCE":
          return data.notification
      // case "FLAG":
      //     return data.notification
      case "SURVEY":
        if(data.refId)
        return <Link to={`/student/details/courses/clinical-rotation/${data.refId}/${id}`}>{data.notification}</Link> 
        else  
        return <Link to={`/student/details/courses/${id}`}>{data.notification}</Link> ;
      default:
          return data.notification;
  }
}

export const getMonths = () => {
  return [...Array(12).keys()].map(key => {
    const name = new Date(0, key).toLocaleString('en', { month: 'long' })
    const days = new Date(0, key + 1, 0).getDate()
    return ({ id: key+1, name: name, days: days < 29 ? days + 1 : days })
})
}

export  const getDatesInMonth = (month = 31) => {
  return [...Array(month).keys()].map(key => {
      return ({ id: key + 1, name: key + 1 })
  })
}

export const addDaysIncrement = (dates, days) => {
  var date = new Date(dates);
  date.setDate(date.getDate() + days);
  return date;
}

export const exportTableToExcel = (tableID, filename = '') => {
  let downloadLink;
  let dataType = 'application/vnd.ms-excel';
  let tableSelect = document.getElementById(tableID);
  let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
  
  // Specify file name
  filename = filename?filename+'.xls':'list.xls';
  
  // Create download link element
  downloadLink = document.createElement("a");
  
  document.body.appendChild(downloadLink);
  
  if(navigator.msSaveOrOpenBlob){
      var blob = new Blob(['\ufeff', tableHTML], {
          type: dataType
      });
      navigator.msSaveOrOpenBlob( blob, filename);
  }else{
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
  
      // Setting the file name
      downloadLink.download = filename;
      
      //triggering the function
      downloadLink.click();
  }
}

export const amountFormatter = (amount) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedAmount = formatter.format(amount);
  return formattedAmount;
}

export const phoneNumberFormatter = (y) => {
let x = y.toString().replaceAll(/[^0-9]+/g, '');
const number = x && x.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
const code = x && x.length > 10 ? `+${x.slice(0, x.length-10).slice(-4)}` : '';
return `${code} ${number}`;
};

export const getAuthUserSessionId = () => {
  const localData = window.localStorage.getItem('userData')
  const userData = localData && JSON.parse(localData || {})
  const userSessionId = userData && userData.userSessionId
  return userSessionId
}
