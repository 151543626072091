export const ADD_STUDENT_REQUESTED = 'student/ADD_STUDENT_REQUESTED';
export const ADD_STUDENT_SUCCESS = 'student/ADD_STUDENT_SUCCESS';
export const ADD_STUDENT_FAILURE = 'student/ADD_STUDENT_FAILURE';

export const GET_STUDENT_LIST_REQUESTED = 'student/GET_STUDENT_LIST_REQUESTED';
export const GET_STUDENT_LIST_SUCCESS = 'student/GET_STUDENT_LIST_SUCCESS';
export const GET_STUDENT_LIST_FAILURE = 'student/GET_STUDENT_LIST_FAILURE';

export const GET_STUDENT_ADDRESS_REQUESTED = 'student/GET_STUDENT_ADDRESS_REQUESTED';
export const GET_STUDENT_ADDRESS_SUCCESS = 'student/GET_STUDENT_ADDRESS_SUCCESS';
export const GET_STUDENT_ADDRESS_FAILURE = 'student/GET_STUDENT_ADDRESS_FAILURE';

export const SELECTED_STUDENT_DATA = 'student/SELECTED_STUDENT_DATA';

export const GET_STUDENT_ADMISSION_FORM_FAILURE = 'student/GET_STUDENT_ADMISSION_FORM_FAILURE';
export const GET_STUDENT_ADMISSION_FORM_SUCCESS = 'student/GET_STUDENT_ADMISSION_FORM_SUCCESS';
export const GET_STUDENT_ADMISSION_FORM_REQUESTED = 'student/GET_STUDENT_ADMISSION_FORM_REQUESTED';

export const GET_STUDENT_PROFILE_FAILURE = 'student/GET_STUDENT_PROFILE_FAILURE';
export const GET_STUDENT_PROFILE_SUCCESS = 'student/GET_STUDENT_PROFILE_SUCCESS';
export const GET_STUDENT_PROFILE_REQUESTED = 'student/GET_STUDENT_PROFILE_REQUESTED';

export const STUDENT_PERSONAL_DETAILS_FAILURE = 'student/STUDENT_PERSONAL_DETAILS_FAILURE';
export const STUDENT_PERSONAL_DETAILS_SUCCESS = 'student/STUDENT_PERSONAL_DETAILS_SUCCESS';
export const STUDENT_PERSONAL_DETAILS_REQUESTED = 'student/STUDENT_PERSONAL_DETAILS_REQUESTED';

export const STUDENT_DOCUMENT_LIST_FAILURE = 'student/STUDENT_DOCUMENT_LIST_FAILURE';
export const STUDENT_DOCUMENT_LIST_SUCCESS = 'student/STUDENT_DOCUMENT_LIST_SUCCESS';
export const STUDENT_DOCUMENT_LIST_REQUESTED = 'student/STUDENT_DOCUMENT_LIST_REQUESTED';

export const STUDENT_NOTES_LIST_FAILURE = 'student/STUDENT_NOTES_LIST_FAILURE';
export const STUDENT_NOTES_LIST_SUCCESS = 'student/STUDENT_NOTES_LIST_SUCCESS';
export const STUDENT_NOTES_LIST_REQUESTED = 'student/STUDENT_NOTES_LIST_REQUESTED';

export const STUDENT_PROFILE_UPDATE_FAILURE = 'student/STUDENT_PROFILE_UPDATE_FAILURE';
export const STUDENT_PROFILE_UPDATE_SUCCESS = 'student/STUDENT_PROFILE_UPDATE_SUCCESS';
export const STUDENT_PROFILE_UPDATE_REQUESTED = 'student/STUDENT_PROFILE_UPDATE_REQUESTED';

export const STUDENT_PROFILE_HEADER_FAILURE = 'student/STUDENT_PROFILE_HEADER_FAILURE';
export const STUDENT_PROFILE_HEADER_SUCCESS = 'student/STUDENT_PROFILE_HEADER_SUCCESS';
export const STUDENT_PROFILE_HEADER_REQUESTED = 'student/STUDENT_PROFILE_HEADER_REQUESTED';

export const STUDENT_ADD_FAILURE = 'student/STUDENT_ADD_FAILURE';
export const STUDENT_ADD_SUCCESS = 'student/STUDENT_ADD_SUCCESS';
export const STUDENT_ADD_REQUESTED = 'student/STUDENT_ADD_REQUESTED';

export const GET_STUDENT_APPLICANT_REQUESTED = 'student/GET_STUDENT_APPLICANT_REQUESTED';
export const GET_STUDENT_APPLICANT_SUCCESS = 'student/GET_STUDENT_APPLICANT_SUCCESS';
export const GET_STUDENT_APPLICANT_FAILURE = 'student/GET_STUDENT_APPLICANT_FAILURE';

export const GET_STUDENT_ATTENDING_REQUESTED = 'student/GET_STUDENT_ATTENDING_REQUESTED';
export const GET_STUDENT_ATTENDING_SUCCESS = 'student/GET_STUDENT_ATTENDING_SUCCESS';
export const GET_STUDENT_ATTENDING_FAILURE = 'student/GET_STUDENT_ATTENDING_FAILURE';

export const GET_STUDENT_NBME_LIST_REQUESTED = 'student/GET_STUDENT_NBME_LIST_REQUESTED';
export const GET_STUDENT_NBME_LIST_SUCCESS = 'student/GET_STUDENT_NBME_LIST_SUCCESS';
export const GET_STUDENT_NBME_LIST_FAILURE = 'student/GET_STUDENT_NBME_LIST_FAILURE';

export const GET_STUDENT_KAPLAN_LIST_REQUESTED = 'student/GET_STUDENT_KAPLAN_LIST_REQUESTED';
export const GET_STUDENT_KAPLAN_LIST_SUCCESS = 'student/GET_STUDENT_KAPLAN_LIST_SUCCESS';
export const GET_STUDENT_KAPLAN_LIST_FAILURE = 'student/GET_STUDENT_KAPLAN_LIST_FAILURE';

export const GET_DISCIPLINE_LIST_REQUESTED = 'student/GET_DISCIPLINE_LIST_REQUESTED';
export const GET_DISCIPLINE_LIST_SUCCESS = 'student/GET_DISCIPLINE_LIST_SUCCESS';
export const GET_DISCIPLINE_LIST_FAILURE = 'student/GET_DISCIPLINE_LIST_FAILURE';

export const GET_NBME_SCORE_REQUESTED = 'student/GET_NBME_SCORE_REQUESTED';
export const GET_NBME_SCORE_SUCCESS = 'student/GET_NBME_SCORE_SUCCESS';
export const GET_NBME_SCORE_FAILURE = 'student/GET_NBME_SCORE_FAILURE';

export const GET_KAPLAN_SCORE_REQUESTED = 'student/GET_KAPLAN_SCORE_REQUESTED';
export const GET_KAPLAN_SCORE_SUCCESS = 'student/GET_KAPLAN_SCORE_SUCCESS';
export const GET_KAPLAN_SCORE_FAILURE = 'student/GET_KAPLAN_SCORE_FAILURE';

export const GET_NBME_ATTEMPTS_REQUESTED = 'student/GET_NBME_ATTEMPTS_REQUESTED';
export const GET_NBME_ATTEMPTS_SUCCESS = 'student/GET_NBME_ATTEMPTS_SUCCESS';
export const GET_NBME_ATTEMPTS_FAILURE = 'student/GET_NBME_ATTEMPTS_FAILURE';

export const GET_STUDENT_NOTES_LIST_REQUESTED = 'student/GET_STUDENT_NOTES_LIST_REQUESTED';
export const GET_STUDENT_NOTES_LIST_SUCCESS = 'student/GET_STUDENT_NOTES_LIST_SUCCESS';
export const GET_STUDENT_NOTES_LIST_FAILURE = 'student/GET_STUDENT_NOTES_LIST_FAILURE';

export const GET_STUDENT_NOTES_BY_ID_REQUESTED = 'student/GET_STUDENT_NOTES_BY_ID_REQUESTED';
export const GET_STUDENT_NOTES_BY_ID_SUCCESS = 'student/GET_STUDENT_NOTES_BY_ID_SUCCESS';
export const GET_STUDENT_NOTES_BY_ID_FAILURE = 'student/GET_STUDENT_NOTES_BY_ID_FAILURE';

export const STUDENT_DOCUMENT_FOLDER_REQUESTED = 'student/STUDENT_DOCUMENT_FOLDER_REQUESTED';
export const STUDENT_DOCUMENT_FOLDER_SUCCESS = 'student/STUDENT_DOCUMENT_FOLDER_SUCCESS';
export const STUDENT_DOCUMENT_FOLDER_FAILURE = 'student/STUDENT_DOCUMENT_FOLDER_FAILURE';

export const STUDENT_DOCUMENTS_LIST_REQUESTED = 'student/STUDENT_DOCUMENTS_LIST_REQUESTED';
export const STUDENT_DOCUMENTS_LIST_SUCCESS = 'student/STUDENT_DOCUMENTS_LIST_SUCCESS';
export const STUDENT_DOCUMENTS_LIST_FAILURE = 'student/STUDENT_DOCUMENTS_LIST_FAILURE';

export const DOCUMENT_FETCH_REQUESTED = 'student/DOCUMENT_FETCH_REQUESTED';
export const DOCUMENT_FETCH_SUCCESS = 'student/DOCUMENT_FETCH_SUCCESS';
export const DOCUMENT_FETCH_FAILURE = 'student/DOCUMENT_FETCH_FAILURE';

export const GET_STUDENT_DOC_FILE_REQUESTED = 'student/GET_STUDENT_DOC_FILE_REQUESTED';
export const GET_STUDENT_DOC_FILE_SUCCESS = 'student/GET_STUDENT_DOC_FILE_SUCCESS';
export const GET_STUDENT_DOC_FILE_FAILURE = 'student/GET_STUDENT_DOC_FILE_FAILURE';

export const GET_STUDENT_TYPES_REQUESTED = 'student/GET_STUDENT_TYPES_REQUESTED';
export const GET_STUDENT_TYPES_SUCCESS = 'student/GET_STUDENT_TYPES_SUCCESS';
export const GET_STUDENT_TYPES_FAILURE = 'student/GET_STUDENT_TYPES_FAILURE';

export const GET_STUDENT_STATUS_LIST_REQUESTED = 'student/GET_STUDENT_STATUS_LIST_REQUESTED';
export const GET_STUDENT_STATUS_LIST_SUCCESS = 'student/GET_STUDENT_STATUS_LIST_SUCCESS';
export const GET_STUDENT_STATUS_LIST_FAILURE = 'student/GET_STUDENT_STATUS_LIST_FAILURE';

export const GET_COURSE_STATUS_LIST_REQUESTED = 'student/GET_COURSE_STATUS_LIST_REQUESTED';
export const GET_COURSE_STATUS_LIST_SUCCESS = 'student/GET_COURSE_STATUS_LIST_SUCCESS';
export const GET_COURSE_STATUS_LIST_FAILURE = 'student/GET_COURSE_STATUS_LIST_FAILURE';

export const GET_STUDENT_COURSE_LIST_REQUESTED = 'student/GET_STUDENT_COURSE_LIST_REQUESTED';
export const GET_STUDENT_COURSE_LIST_SUCCESS = 'student/GET_STUDENT_COURSE_LIST_SUCCESS';
export const GET_STUDENT_COURSE_LIST_FAILURE = 'student/GET_STUDENT_COURSE_LIST_FAILURE';

export const GET_USER_TYPE_SUCCESS = 'student/GET_USER_TYPE_SUCCESS';

export const GET_ROTATION_LIST_REQUESTED = 'student/GET_ROTATION_LIST_REQUESTED';
export const GET_ROTATION_LIST_SUCCESS = 'student/GET_ROTATION_LIST_SUCCESS';
export const GET_ROTATION_LIST_FAILURE = 'student/GET_ROTATION_LIST_FAILURE';

export const GET_STUDENT_ROTATION_LIST_REQUESTED = 'student/GET_ROTATION_STUDENT_LIST_REQUESTED';
export const GET_STUDENT_ROTATION_LIST_SUCCESS = 'student/GET_STUDENT_ROTATION_LIST_SUCCESS';
export const GET_STUDENT_ROTATION_LIST_FAILURE = 'student/GET_STUDENT_ROTATION_LIST_FAILURE';

export const GET_PRECEPTOR_LIST_REQUESTED = 'student/GET_PRECEPTOR_LIST_REQUESTED';
export const GET_PRECEPTOR_LIST_SUCCESS = 'student/GET_PRECEPTOR_LIST_SUCCESS';
export const GET_PRECEPTOR_LIST_FAILURE = 'student/GET_PRECEPTOR_LIST_FAILURE';

export const CLERICAL_INFO_REQUESTED = 'student/CLERICAL_INFO_REQUESTED';
export const CLERICAL_INFO_SUCCESS = 'student/CLERICAL_INFO_SUCCESS';
export const CLERICAL_INFO_FAILURE = 'student/CLERICAL_INFO_FAILURE';

export const CLINICAL_LOG_VERIFICATION_INFO_REQUESTED = 'student/CLINICAL_LOG_VERIFICATION_INFO_REQUESTED';
export const CLINICAL_LOG_VERIFICATION_INFO_SUCCESS = 'student/CLINICAL_LOG_VERIFICATION_INFO_SUCCESS';
export const CLINICAL_LOG_VERIFICATION_INFO_FAILURE = 'student/CLINICAL_LOG_VERIFICATION_INFO_FAILURE';

export const GET_CLINICAL_LOG_PROBLEM_OPTIONS_REQUESTED = 'student/GET_CLINICAL_LOG_PROBLEM_OPTIONS_REQUESTED';
export const GET_CLINICAL_LOG_PROBLEM_OPTIONS_SUCCESS = 'student/GET_CLINICAL_LOG_PROBLEM_OPTIONS_SUCCESS';
export const GET_CLINICAL_LOG_PROBLEM_OPTIONS_FAILURE = 'student/GET_CLINICAL_LOG_PROBLEM_OPTIONS_FAILURE';

export const GET_CLINICAL_PROCEDURES_REQUESTED = 'student/GET_CLINICAL_PROCEDURES_REQUESTED';
export const GET_CLINICAL_PROCEDURES_SUCCESS = 'student/GET_CLINICAL_PROCEDURES_SUCCESS';
export const GET_CLINICAL_PROCEDURES_FAILURE = 'student/GET_CLINICAL_PROCEDURES_FAILURE';

export const GET_ROTATION_DATES_REQUESTED = 'student/GET_ROTATION_DATES_REQUESTED';
export const GET_ROTATION_DATES_SUCCESS = 'student/GET_ROTATION_DATES_SUCCESS';
export const GET_ROTATION_DATES_FAILURE = 'student/GET_ROTATION_DATES_FAILURE';

export const CLINICAL_LOG_ACADEMIC_LIST_REQUESTED = 'student/CLINICAL_LOG_ACADEMIC_LIST_REQUESTED';
export const CLINICAL_LOG_ACADEMIC_LIST_SUCCESS = 'student/CLINICAL_LOG_ACADEMIC_LIST_SUCCESS';
export const CLINICAL_LOG_ACADEMIC_LIST_FAILURE = 'student/CLINICAL_LOG_ACADEMIC_LIST_FAILURE';
export const ALUMNI_RESIDENCY_YEAR_LIST_REQUESTED = 'student/ALUMNI_RESIDENCY_YEAR_LIST_REQUESTED';
export const ALUMNI_RESIDENCY_YEAR_LIST_SUCCESS = 'student/ALUMNI_RESIDENCY_YEAR_LIST_SUCCESS';
export const ALUMNI_RESIDENCY_YEAR_LIST_FAILURE = 'student/ALUMNI_RESIDENCY_YEAR_LIST_FAILURE';

export const ALUMNI_SPECIALTY_LIST_REQUESTED = 'student/ALUMNI_SPECIALTY_LIST_REQUESTED';
export const ALUMNI_SPECIALTY_LIST_SUCCESS = 'student/ALUMNI_SPECIALTY_LIST_SUCCESS';
export const ALUMNI_SPECIALTY_LIST_FAILURE = 'student/ALUMNI_SPECIALTY_LIST_FAILURE';

export const GET_ALUMNI_JOB_BY_ID_REQUESTED = 'student/GET_ALUMNI_JOB_BY_ID_REQUESTED';
export const GET_ALUMNI_JOB_BY_ID_SUCCESS = 'student/GET_ALUMNI_JOB_BY_ID_SUCCESS';
export const GET_ALUMNI_JOB_BY_ID_FAILURE = 'student/GET_ALUMNI_JOB_BY_ID_FAILURE';

export const GET_ALUMNI_PERSONAL_LIST_BY_ID_REQUESTED = 'student/GET_ALUMNI_PERSONAL_LIST_BY_ID_REQUESTED';
export const GET_ALUMNI_PERSONAL_LIST_BY_ID_SUCCESS = 'student/GET_ALUMNI_PERSONAL_LIST_BY_ID_SUCCESS';
export const GET_ALUMNI_PERSONAL_LIST_BY_ID_FAILURE = 'student/GET_ALUMNI_PERSONAL_LIST_BY_ID_FAILURE';

export const GET_ALUMNI_JOB_LIST_BY_ID_REQUESTED = 'student/GET_ALUMNI_JOB_LIST_BY_ID_REQUESTED';
export const GET_ALUMNI_JOB_LIST_BY_ID_SUCCESS = 'student/GET_ALUMNI_JOB_LIST_BY_ID_SUCCESS';
export const GET_ALUMNI_JOB_LIST_BY_ID_FAILURE = 'student/GET_ALUMNI_JOB_LIST_BY_ID_FAILURE';

export const GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_REQUESTED = 'student/GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_REQUESTED';
export const GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_SUCCESS = 'student/GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_SUCCESS';
export const GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_FAILURE = 'student/GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_FAILURE';

export const GET_ALUMNI_OTHER_DEGREE_BY_ID_REQUESTED = 'student/GET_ALUMNI_OTHER_DEGREE_BY_ID_REQUESTED';
export const GET_ALUMNI_OTHER_DEGREE_BY_ID_SUCCESS = 'student/GET_ALUMNI_OTHER_DEGREE_BY_ID_SUCCESS';
export const GET_ALUMNI_OTHER_DEGREE_BY_ID_FAILURE = 'student/GET_ALUMNI_OTHER_DEGREE_BY_ID_FAILURE';

export const GET_STUDENT_COURSE_REQUESTED = 'student/GET_STUDENT_COURSE_REQUESTED';
export const GET_STUDENT_COURSE_SUCCESS = 'student/GET_STUDENT_COURSE_SUCCESS';
export const GET_STUDENT_COURSE_FAILURE = 'student/GET_STUDENT_COURSE_FAILURE';

export const GET_STUDENT_COURSE_DROPDOWN_REQUESTED = 'student/GET_STUDENT_COURSE_DROPDOWN_REQUESTED';
export const GET_STUDENT_COURSE_DROPDOWN_SUCCESS = 'student/GET_STUDENT_COURSE_DROPDOWN_SUCCESS';
export const GET_STUDENT_COURSE_DROPDOWN_FAILURE = 'student/GET_STUDENT_COURSE_DROPDOWN_FAILURE';

export const GET_STUDENT_COURSE_DETAILS_REQUESTED = 'student/GET_STUDENT_COURSE_DETAILS_REQUESTED';
export const GET_STUDENT_COURSE_DETAILS_SUCCESS = 'student/GET_STUDENT_COURSE_DETAILS_SUCCESS';
export const GET_STUDENT_COURSE_DETAILS_FAILURE = 'student/GET_STUDENT_COURSE_DETAILS_FAILURE';

export const GET_CLINICAL_ROTATION_LIST_REQUESTED = 'student/GET_CLINICAL_ROTATION_LIST_REQUESTED';
export const GET_CLINICAL_ROTATION_LIST_SUCCESS = 'student/GET_CLINICAL_ROTATION_LIST_SUCCESS';
export const GET_CLINICAL_ROTATION_LIST_FAILURE = 'student/GET_CLINICAL_ROTATION_LIST_FAILURE';

export const GET_RESIDENCY_PROGRAM_LIST_REQUESTED = 'student/GET_RESIDENCY_PROGRAM_LIST_REQUESTED';
export const GET_RESIDENCY_PROGRAM_LIST_SUCCESS = 'student/GET_RESIDENCY_PROGRAM_LIST_SUCCESS';
export const GET_RESIDENCY_PROGRAM_LIST_FAILURE = 'student/GET_RESIDENCY_PROGRAM_LIST_FAILURE';

export const GET_RESIDENCY_PROGRAM_REQUESTED = 'student/GET_RESIDENCY_PROGRAM_REQUESTED';
export const GET_RESIDENCY_PROGRAM_SUCCESS = 'student/GET_RESIDENCY_PROGRAM_SUCCESS';
export const GET_RESIDENCY_PROGRAM_FAILURE = 'student/GET_RESIDENCY_PROGRAM_FAILURE';

export const GET_RESIDENCY_SPECIALITY_LIST_REQUESTED = 'student/GET_RESIDENCY_SPECIALITY_LIST_REQUESTED';
export const GET_RESIDENCY_SPECIALITY_LIST_SUCCESS = 'student/GET_RESIDENCY_SPECIALITY_LIST_SUCCESS';
export const GET_RESIDENCY_SPECIALITY_LIST_FAILURE = 'student/GET_RESIDENCY_SPECIALITY_LIST_FAILURE';

export const GET_RESIDENCY_PROGRAM_TYPE_REQUESTED = 'student/GET_RESIDENCY_PROGRAM_TYPE_REQUESTED';
export const GET_RESIDENCY_PROGRAM_TYPE_SUCCESS = 'student/GET_RESIDENCY_PROGRAM_TYPE_SUCCESS';
export const GET_RESIDENCY_PROGRAM_TYPE_FAILURE = 'student/GET_RESIDENCY_PROGRAM_TYPE_FAILURE';

export const GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_REQUESTED = 'student/GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_REQUESTED';
export const GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_SUCCESS = 'student/GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_SUCCESS';
export const GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_FAILURE = 'student/GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_FAILURE';

export const GET_CLINICAL_LOG_DOWNLOAD_INFO_REQUESTED = 'student/GET_CLINICAL_LOG_DOWNLOAD_INFO_REQUESTED';
export const GET_CLINICAL_LOG_DOWNLOAD_INFO_SUCCESS = 'student/GET_CLINICAL_LOG_DOWNLOAD_INFO_SUCCESS';
export const GET_CLINICAL_LOG_DOWNLOAD_INFO_FAILURE = 'student/GET_CLINICAL_LOG_DOWNLOAD_INFO_FAILURE';

export const GET_ROTATION_DROPDOWN_REQUESTED = 'student/GET_ROTATION_DROPDOWN_REQUESTED';
export const GET_ROTATION_DROPDOWN_SUCCESS = 'student/GET_ROTATION_DROPDOWN_SUCCESS';
export const GET_ROTATION_DROPDOWN_FAILURE = 'student/GET_ROTATION_DROPDOWN_FAILURE';

export const GET_ROTATION_GRADETYPE_REQUESTED = 'student/GET_ROTATION_GRADETYPE_REQUESTED';
export const GET_ROTATION_GRADETYPE_SUCCESS = 'student/GET_ROTATION_GRADETYPE_SUCCESS';
export const GET_ROTATION_GRADETYPE_FAILURE = 'student/GET_ROTATION_GRADETYPE_FAILURE';

export const GET_ROTATION_GRADE_STATUS_REQUESTED = 'student/GET_ROTATION_GRADE_STATUS_REQUESTED';
export const GET_ROTATION_GRADE_STATUS_SUCCESS = 'student/GET_ROTATION_GRADE_STATUS_SUCCESS';
export const GET_ROTATION_GRADE_STATUS_FAILURE = 'student/GET_ROTATION_GRADE_STATUS_FAILURE';

export const GET_ROTATION_HOSPITAL_REQUESTED = 'student/GET_ROTATION_HOSPITAL_REQUESTED';
export const GET_ROTATION_HOSPITAL_SUCCESS = 'student/GET_ROTATION_HOSPITAL_SUCCESS';
export const GET_ROTATION_HOSPITAL_FAILURE = 'student/GET_ROTATION_HOSPITAL_FAILURE';

export const GET_ROTATION_PRECEPTOR_REQUESTED = 'student/GET_ROTATION_PRECEPTOR_REQUESTED';
export const GET_ROTATION_PRECEPTOR_SUCCESS = 'student/GET_ROTATION_PRECEPTOR_SUCCESS';
export const GET_ROTATION_PRECEPTOR_FAILURE = 'student/GET_ROTATION_PRECEPTOR_FAILURE';

export const GET_STUDENT_HOSPITAL_REQUESTED = 'student/GET_STUDENT_HOSPITAL_REQUESTED';
export const GET_STUDENT_HOSPITAL_SUCCESS = 'student/GET_STUDENT_HOSPITAL_SUCCESS';
export const GET_STUDENT_HOSPITAL_FAILURE = 'student/GET_STUDENT_HOSPITAL_FAILURE';

export const GET_CLINICAL_TRANSFER_LIST_REQUESTED = 'student/GET_CLINICAL_TRANSFER_LIST_REQUESTED';
export const GET_CLINICAL_TRANSFER_LIST_SUCCESS = 'student/GET_CLINICAL_TRANSFER_LIST_SUCCESS';
export const GET_CLINICAL_TRANSFER_LIST_FAILURE = 'student/GET_CLINICAL_TRANSFER_LIST_FAILURE';

export const GET_CLINICAL_TRANSFER_DATA_REQUESTED = 'student/GET_CLINICAL_TRANSFER_DATA_REQUESTED';
export const GET_CLINICAL_TRANSFER_DATA_SUCCESS = 'student/GET_CLINICAL_TRANSFER_DATA_SUCCESS';
export const GET_CLINICAL_TRANSFER_DATA_FAILURE = 'student/GET_CLINICAL_TRANSFER_DATA_FAILURE';

export const GET_CLINICAL_ROTATION_DATA_REQUESTED = 'student/GET_CLINICAL_ROTATION_DATA_REQUESTED';
export const GET_CLINICAL_ROTATION_DATA_SUCCESS = 'student/GET_CLINICAL_ROTATION_DATA_SUCCESS';
export const GET_CLINICAL_ROTATION_DATA_FAILURE = 'student/GET_CLINICAL_ROTATION_DATA_FAILURE';

export const GET_CLERICAL_LIST_REQUESTED = 'student/GET_CLERICAL_LIST_REQUESTED';
export const GET_CLERICAL_LIST_SUCCESS = 'student/GET_CLERICAL_LIST_SUCCESS';
export const GET_CLERICAL_LIST_FAILURE = 'student/GET_CLERICAL_LIST_FAILURE';

export const GET_STAGE_DROPDOWN_REQUESTED = 'student/GET_STAGE_DROPDOWN_REQUESTED';
export const GET_STAGE_DROPDOWN_SUCCESS = 'student/GET_STAGE_DROPDOWN_SUCCESS';
export const GET_STAGE_DROPDOWN_FAILURE = 'student/GET_STAGE_DROPDOWN_FAILURE';

export const GET_CLASS_BY_STAGE_REQUESTED = 'student/GET_CLASS_BY_STAGE_REQUESTED';
export const GET_CLASS_BY_STAGE_SUCCESS = 'student/GET_CLASS_BY_STAGE_SUCCESS';
export const GET_CLASS_BY_STAGE_FAILURE = 'student/GET_CLASS_BY_STAGE_FAILURE';

export const GET_GRADE_TYPE_LIST_REQUESTED = 'student/GET_GRADE_TYPE_LIST_REQUESTED';
export const GET_GRADE_TYPE_LIST_SUCCESS = 'student/GET_GRADE_TYPE_LIST_SUCCESS';
export const GET_GRADE_TYPE_LIST_FAILURE = 'student/GET_GRADE_TYPE_LIST_FAILURE';

export const GET_GRADE_POINT_STATUS_REQUESTED = 'student/GET_GRADE_POINT_STATUS_REQUESTED';
export const GET_GRADE_POINT_STATUS_SUCCESS = 'student/GET_GRADE_POINT_STATUS_SUCCESS';
export const GET_GRADE_POINT_STATUS_FAILURE = 'student/GET_GRADE_POINT_STATUS_FAILURE';

export const GET_NONCLINICAL_CLASSES_REQUESTED = 'student/GET_NONCLINICAL_CLASSES_REQUESTED';
export const GET_NONCLINICAL_CLASSES_SUCCESS = 'student/GET_NONCLINICAL_CLASSES_SUCCESS';
export const GET_NONCLINICAL_CLASSES_FAILURE = 'student/GET_NONCLINICAL_CLASSES_FAILURE';

export const GET_NONCLINICAL_STAGES_REQUESTED = 'student/GET_NONCLINICAL_STAGES_REQUESTED';
export const GET_NONCLINICAL_STAGES_SUCCESS = 'student/GET_NONCLINICAL_STAGES_SUCCESS';
export const GET_NONCLINICAL_STAGES_FAILURE = 'student/GET_NONCLINICAL_STAGES_FAILURE';

export const GET_NONCLINICAL_STAGE_SEMESTER_REQUESTED = 'student/GET_NONCLINICAL_STAGE_SEMESTER_REQUESTED';
export const GET_NONCLINICAL_STAGE_SEMESTER_SUCCESS = 'student/GET_NONCLINICAL_STAGE_SEMESTER_SUCCESS';
export const GET_NONCLINICAL_STAGE_SEMESTER_FAILURE = 'student/GET_NONCLINICAL_STAGE_SEMESTER_FAILURE';

export const GET_NONCLINICAL_ACADEMIC_STANDING_REQUESTED = 'student/GET_NONCLINICAL_ACADEMIC_STANDING_REQUESTED';
export const GET_NONCLINICAL_ACADEMIC_STANDING_SUCCESS = 'student/GET_NONCLINICAL_ACADEMIC_STANDING_SUCCESS';
export const GET_NONCLINICAL_ACADEMIC_STANDING_FAILURE = 'student/GET_NONCLINICAL_ACADEMIC_STANDING_FAILURE';

export const GET_ACADEMIC_STANDING_DROPDOWN_REQUESTED = 'student/GET_ACADEMIC_STANDING_DROPDOWN_REQUESTED';
export const GET_ACADEMIC_STANDING_DROPDOWN_SUCCESS = 'student/GET_ACADEMIC_STANDING_DROPDOWN_SUCCESS';
export const GET_ACADEMIC_STANDING_DROPDOWN_FAILURE = 'student/GET_ACADEMIC_STANDING_DROPDOWN_FAILURE';

export const GET_NONCLINICAL_TRANSFER_REQUESTED = 'student/GET_NONCLINICAL_TRANSFER_REQUESTED';
export const GET_NONCLINICAL_TRANSFER_SUCCESS = 'student/GET_NONCLINICAL_TRANSFER_SUCCESS';
export const GET_NONCLINICAL_TRANSFER_FAILURE = 'student/GET_NONCLINICAL_TRANSFER_FAILURE';

export const GET_NONCLINICAL_TRANSFER_LIST_REQUESTED = 'student/GET_NONCLINICAL_TRANSFER_LIST_REQUESTED';
export const GET_NONCLINICAL_TRANSFER_LIST_SUCCESS = 'student/GET_NONCLINICAL_TRANSFER_LIST_SUCCESS';
export const GET_NONCLINICAL_TRANSFER_LIST_FAILURE = 'student/GET_NONCLINICAL_TRANSFER_LIST_FAILURE';

export const GET_ACADEMIC_STANDING_LIST_BY_ID_REQUESTED = 'student/GET_ACADEMIC_STANDING_LIST_BY_ID_REQUESTED';
export const GET_ACADEMIC_STANDING_LIST_BY_ID_SUCCESS = 'student/GET_ACADEMIC_STANDING_LIST_BY_ID_SUCCESS';
export const GET_ACADEMIC_STANDING_LIST_BY_ID_FAILURE = 'student/GET_ACADEMIC_STANDING_LIST_BY_ID_FAILURE';

export const GET_NC_SPECIAL_STAGE_REQUESTED = 'student/GET_NC_SPECIAL_STAGE_REQUESTED';
export const GET_NC_SPECIAL_STAGE_SUCCESS = 'student/GET_NC_SPECIAL_STAGE_SUCCESS';
export const GET_NC_SPECIAL_STAGE_FAILURE = 'student/GET_NC_SPECIAL_STAGE_FAILURE';

export const GET_NONCLINICAL_CLASS_DETAILS_REQUESTED = 'student/GET_NONCLINICAL_CLASS_DETAILS_REQUESTED';
export const GET_NONCLINICAL_CLASS_DETAILS_SUCCESS = 'student/GET_NONCLINICAL_CLASS_DETAILS_SUCCESS';
export const GET_NONCLINICAL_CLASS_DETAILS_FAILURE = 'student/GET_NONCLINICAL_CLASS_DETAILS_FAILURE';

export const STUDENT_USMLE_TEST_LIST_REQUESTED = 'student/STUDENT_USMLE_TEST_LIST_REQUESTED';
export const STUDENT_USMLE_TEST_LIST_SUCCESS = 'student/STUDENT_USMLE_TEST_LIST_SUCCESS';
export const STUDENT_USMLE_TEST_LIST_FAILURE = 'student/STUDENT_USMLE_TEST_LIST_FAILURE';

export const USMLE_TEST_NO_OF_ATTEMPTS_REQUESTED = 'student/USMLE_TEST_NO_OF_ATTEMPTS_REQUESTED';
export const USMLE_TEST_NO_OF_ATTEMPTS_SUCESS = 'student/USMLE_TEST_NO_OF_ATTEMPTS_SUCESS';
export const USMLE_TEST_NO_OF_ATTEMPTS_FAILURE = 'student/USMLE_TEST_NO_OF_ATTEMPTS_FAILURE';

export const GET_USMLETEST_BY_ID_REQUESTED = 'student/GET_USMLETEST_BY_ID_REQUESTED';
export const GET_USMLETEST_BY_ID_SUCCESS = 'student/GET_USMLETEST_BY_ID_SUCCESS';
export const GET_USMLETEST_BY_ID_FAILURE = 'student/GET_USMLETEST_BY_ID_FAILURE';

export const STUDENT_LEAVE_LIST_REQUESTED = 'student/STUDENT_LEAVE_LIST_REQUESTED';
export const STUDENT_LEAVE_LIST_SUCCESS = 'student/STUDENT_LEAVE_LIST_SUCCESS';
export const STUDENT_LEAVE_LIST_FAILURE = 'student/STUDENT_LEAVE_LIST_FAILURE';

export const STUDENT_LEAVE_REASON_LIST_REQUESTED = 'student/STUDENT_LEAVE_REASON_LIST_REQUESTED';
export const STUDENT_LEAVE_REASON_LIST_SUCCESS = 'student/STUDENT_LEAVE_REASON_LIST_SUCCESS';
export const STUDENT_LEAVE_REASON_LIST_FAILURE = 'student/STUDENT_LEAVE_REASON_LIST_FAILURE';

export const GET_STUDENT_LEAVE_REQUESTED = 'student/GET_STUDENT_LEAVE_REQUESTED';
export const GET_STUDENT_LEAVE_SUCCESS = 'student/GET_STUDENT_LEAVE_SUCCESS';
export const GET_STUDENT_LEAVE_FAILURE = 'student/GET_STUDENT_LEAVE_FAILURE';

export const GET_CLINICAL_DOC_LIST_BY_ID_REQUESTED = 'student/GET_CLINICAL_DOC_LIST_BY_ID_REQUESTED';
export const GET_CLINICAL_DOC_LIST_BY_ID_SUCCESS = 'student/GET_CLINICAL_DOC_LIST_BY_ID_SUCCESS';
export const GET_CLINICAL_DOC_LIST_BY_ID_FAILURE = 'student/GET_CLINICAL_DOC_LIST_BY_ID_FAILURE';

export const GET_CLINICAL_DOC_TYPE_LIST_BY_ID_REQUESTED = 'student/GET_CLINICAL_DOC_TYPE_LIST_BY_ID_REQUESTED';
export const GET_CLINICAL_DOC_TYPE_LIST_BY_ID_SUCCESS = 'student/GET_CLINICAL_DOC_TYPE_LIST_BY_ID_SUCCESS';
export const GET_CLINICAL_DOC_TYPE_LIST_BY_ID_FAILURE = 'student/GET_CLINICAL_DOC_TYPE_LIST_BY_ID_FAILURE';

export const GET_CLINICAL_DOCUMENT_REQUESTED = 'student/GET_CLINICAL_DOCUMENT_REQUESTED';
export const GET_CLINICAL_DOCUMENT_SUCCESS = 'student/GET_CLINICAL_DOCUMENT_SUCCESS';
export const GET_CLINICAL_DOCUMENT_FAILURE = 'student/GET_CLINICAL_DOCUMENT_FAILURE';

export const GET_STAGE_STATUS_DETAILS_REQUESTED = 'student/GET_STAGE_STATUS_DETAILS_REQUESTED';
export const GET_STAGE_STATUS_DETAILS_SUCCESS = 'student/GET_STAGE_STATUS_DETAILS_SUCCESS';
export const GET_STAGE_STATUS_DETAILS_FAILURE = 'student/GET_STAGE_STATUS_DETAILS_FAILURE';

export const GET_ALUMNI_MATCH_INFO_REQUESTED = 'student/GET_ALUMNI_MATCH_INFO_REQUESTED';
export const GET_ALUMNI_MATCH_INFO_SUCCESS = 'student/GET_ALUMNI_MATCH_INFO_SUCCESS';
export const GET_ALUMNI_MATCH_INFO_FAILURE = 'student/GET_ALUMNI_MATCH_INFO_FAILURE';

export const GET_PROGRAM_WITHDRAWAL_LIST_REQUESTED = 'student/GET_PROGRAM_WITHDRAWAL_LIST_REQUESTED';
export const GET_PROGRAM_WITHDRAWAL_LIST_SUCCESS = 'student/GET_PROGRAM_WITHDRAWAL_LIST_SUCCESS';
export const GET_PROGRAM_WITHDRAWAL_LIST_FAILURE = 'student/GET_PROGRAM_WITHDRAWAL_LIST_FAILURE';

export const GET_PROGRAM_WITHDRAWAL_DATA_REQUESTED = 'student/GET_PROGRAM_WITHDRAWAL_DATA_REQUESTED';
export const GET_PROGRAM_WITHDRAWAL_DATA_SUCCESS = 'student/GET_PROGRAM_WITHDRAWAL_DATA_SUCCESS';
export const GET_PROGRAM_WITHDRAWAL_DATA_FAILURE = 'student/GET_PROGRAM_WITHDRAWAL_DATA_FAILURE';

export const GET_TRANSFER_CLASS_DROPDOWN_REQUESTED = 'student/GET_TRANSFER_CLASS_DROPDOWN_REQUESTED';
export const GET_TRANSFER_CLASS_DROPDOWN_SUCCESS = 'student/GET_TRANSFER_CLASS_DROPDOWN_SUCCESS';
export const GET_TRANSFER_CLASS_DROPDOWN_FAILURE = 'student/GET_TRANSFER_CLASS_DROPDOWN_FAILURE';

export const GET_REPLACEMENT_DIPLOMA_LIST_REQUESTED = 'student/GET_REPLACEMENT_DIPLOMA_LIST_REQUESTED';
export const GET_REPLACEMENT_DIPLOMA_LIST_SUCCESS = 'student/GET_REPLACEMENT_DIPLOMA_LIST_SUCCESS';
export const GET_REPLACEMENT_DIPLOMA_LIST_FAILURE = 'student/GET_REPLACEMENT_DIPLOMA_LIST_FAILURE';

export const GET_REPLACEMENT_DIPLOMA_REQUESTED = 'student/GET_REPLACEMENT_DIPLOMA_REQUESTED';
export const GET_REPLACEMENT_DIPLOMA_SUCCESS = 'student/GET_REPLACEMENT_DIPLOMA_SUCCESS';
export const GET_REPLACEMENT_DIPLOMA_FAILURE = 'student/GET_REPLACEMENT_DIPLOMA_FAILURE';

export const ADD_ACTION_REQUESTED = 'student/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'student/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'student/ADD_ACTION_FAILURE';

export const GET_ROTATION_DROPDOWN_WEEKS_REQUESTED = 'student/GET_ROTATION_DROPDOWN_WEEKS_REQUESTED';
export const GET_ROTATION_DROPDOWN_WEEKS_SUCCESS = 'student/GET_ROTATION_DROPDOWN_WEEKS_SUCCESS';
export const GET_ROTATION_DROPDOWN_WEEKS_FAILURE = 'student/GET_ROTATION_DROPDOWN_WEEKS_FAILURE';

export const GET_I94_LETTER_REQUESTED = 'student/GET_I94_LETTER_REQUESTED';
export const GET_I94_LETTER_SUCCESS = 'student/GET_I94_LETTER_SUCCESS';
export const GET_I94_LETTER_FAILURE = 'student/GET_I94_LETTER_FAILURE';

export const GET_ENROLLMENT_REQUEST_LIST_REQUESTED = 'student/GET_ENROLLMENT_REQUEST_LIST_REQUESTED';
export const GET_ENROLLMENT_REQUEST_LIST_SUCCESS = 'student/GET_ENROLLMENT_REQUEST_LIST_SUCCESS';
export const GET_ENROLLMENT_REQUEST_LIST_FAILURE = 'student/GET_ENROLLMENT_REQUEST_LIST_FAILURE';

export const GET_ENROLLMENT_REQUEST_REQUESTED = 'student/GET_ENROLLMENT_REQUEST_REQUESTED';
export const GET_ENROLLMENT_REQUEST_SUCCESS = 'student/GET_ENROLLMENT_REQUEST_SUCCESS';
export const GET_ENROLLMENT_REQUEST_FAILURE = 'student/GET_ENROLLMENT_REQUEST_FAILURE';

export const GET_GRADUATION_REQUEST_LIST_REQUESTED = 'student/GET_GRADUATION_REQUEST_LIST_REQUESTED';
export const GET_GRADUATION_REQUEST_LIST_SUCCESS = 'student/GET_GRADUATION_REQUEST_LIST_SUCCESS';
export const GET_GRADUATION_REQUEST_LIST_FAILURE = 'student/GET_GRADUATION_REQUEST_LIST_FAILURE';

export const GET_GRADUATION_REQUEST_REQUESTED = 'student/GET_GRADUATION_REQUEST_REQUESTED';
export const GET_GRADUATION_REQUEST_SUCCESS = 'student/GET_GRADUATION_REQUEST_SUCCESS';
export const GET_GRADUATION_REQUEST_FAILURE = 'student/GET_GRADUATION_REQUEST_FAILURE';

export const GET_PRE_MED_LOA_REQUEST_LIST_REQUESTED = 'student/GET_PRE_MED_LOA_REQUEST_LIST_REQUESTED';
export const GET_PRE_MED_LOA_REQUEST_LIST_SUCCESS = 'student/GET_PRE_MED_LOA_REQUEST_LIST_SUCCESS';
export const GET_PRE_MED_LOA_REQUEST_LIST_FAILURE = 'student/GET_PRE_MED_LOA_REQUEST_LIST_FAILURE';

export const GET_PRE_MED_LOA_REQUEST_REQUESTED = 'student/GET_PRE_MED_LOA_REQUEST_REQUESTED';
export const GET_PRE_MED_LOA_REQUEST_SUCCESS = 'student/GET_PRE_MED_LOA_REQUEST_SUCCESS';
export const GET_PRE_MED_LOA_REQUEST_FAILURE = 'student/GET_PRE_MED_LOA_REQUEST_FAILURE';

export const GET_CLASS_WITHDRAWAL_LIST_REQUESTED = 'student/GET_CLASS_WITHDRAWAL_LIST_REQUESTED';
export const GET_CLASS_WITHDRAWAL_LIST_SUCCESS = 'student/GET_CLASS_WITHDRAWAL_LIST_SUCCESS';
export const GET_CLASS_WITHDRAWAL_LIST_FAILURE = 'student/GET_CLASS_WITHDRAWAL_LIST_FAILURE';

export const GET_ENROLLMENT_LIST_REQUESTED = 'student/GET_ENROLLMENT_LIST_REQUESTED';
export const GET_ENROLLMENT_LIST_SUCCESS = 'student/GET_ENROLLMENT_LIST_SUCCESS';
export const GET_ENROLLMENT_LIST_FAILURE = 'student/GET_ENROLLMENT_LIST_FAILURE';

export const GET_FINANCE_LIST_REQUESTED = 'student/GET_FINANCE_LIST_REQUESTED';
export const GET_FINANCE_LIST_SUCCESS = 'student/GET_FINANCE_LIST_SUCCESS';
export const GET_FINANCE_LIST_FAILURE = 'student/GET_FINANCE_LIST_FAILURE';

export const GET_REPLACEMENT_LIST_REQUESTED = 'student/GET_REPLACEMENT_LIST_REQUESTED';
export const GET_REPLACEMENT_LIST_SUCCESS = 'student/GET_REPLACEMENT_LIST_SUCCESS';
export const GET_REPLACEMENT_LIST_FAILURE = 'student/GET_REPLACEMENT_LIST_FAILURE';

export const GET_GRADUATION_LIST_REQUESTED = 'student/GET_GRADUATION_LIST_REQUESTED';
export const GET_GRADUATION_LIST_SUCCESS = 'student/GET_GRADUATION_LIST_SUCCESS';
export const GET_GRADUATION_LIST_FAILURE = 'student/GET_GRADUATION_LIST_FAILURE';

export const GET_ABSENCE_LIST_REQUESTED = 'student/GET_ABSENCE_LIST_REQUESTED';
export const GET_ABSENCE_LIST_SUCCESS = 'student/GET_ABSENCE_LIST_SUCCESS';
export const GET_ABSENCE_LIST_FAILURE = 'student/GET_ABSENCE_LIST_FAILURE';

export const GET_ABSENCE_LIST_DATA_SUCCESS = 'student/GET_ABSENCE_LIST_DATA_SUCCESS';

export const GET_COMPLAINT_LIST_REQUESTED = 'student/GET_COMPLAINT_LIST_REQUESTED';
export const GET_COMPLAINT_LIST_SUCCESS = 'student/GET_COMPLAINT_LIST_SUCCESS';
export const GET_COMPLAINT_LIST_FAILURE = 'student/GET_COMPLAINT_LIST_FAILURE';

export const GET_MSPE_LIST_REQUESTED = 'student/GET_MSPE_LIST_REQUESTED';
export const GET_MSPE_LIST_SUCCESS = 'student/GET_MSPE_LIST_SUCCESS';
export const GET_MSPE_LIST_FAILURE = 'student/GET_MSPE_LIST_FAILURE';

export const GET_GRADUATION_DATA_REQUESTED = 'student/GET_GRADUATION_DATA_REQUESTED';
export const GET_GRADUATION_DATA_SUCCESS = 'student/GET_GRADUATION_DATA_SUCCESS';
export const GET_GRADUATION_DATA_FAILURE = 'student/GET_GRADUATION_DATA_FAILURE';

export const GET_MSPE_DATA_REQUESTED = 'student/GET_MSPE_DATA_REQUESTED';
export const GET_MSPE_DATA_SUCCESS = 'student/GET_MSPE_DATA_SUCCESS';
export const GET_MSPE_DATA_FAILURE = 'student/GET_MSPE_DATA_FAILURE';

export const GET_ABSENCE_DATA_REQUESTED = 'student/GET_ABSENCE_DATA_REQUESTED';
export const GET_ABSENCE_DATA_SUCCESS = 'student/GET_ABSENCE_DATA_SUCCESS';
export const GET_ABSENCE_DATA_FAILURE = 'student/GET_ABSENCE_DATA_FAILURE';

export const GET_QUERY_DATA_REQUESTED = 'student/GET_QUERY_DATA_REQUESTED';
export const GET_QUERY_DATA_SUCCESS = 'student/GET_QUERY_DATA_SUCCESS';
export const GET_QUERY_DATA_FAILURE = 'student/GET_QUERY_DATA_FAILURE';

export const GET_FINANCE_DATA_REQUESTED = 'student/GET_FINANCE_DATA_REQUESTED';
export const GET_FINANCE_DATA_SUCCESS = 'student/GET_FINANCE_DATA_SUCCESS';
export const GET_FINANCE_DATA_FAILURE = 'student/GET_FINANCE_DATA_FAILURE';

export const GET_NUMBER_LIST_REQUESTED = 'student/GET_NUMBER_LIST_REQUESTED';
export const GET_NUMBER_LIST_SUCCESS = 'student/GET_NUMBER_LIST_SUCCESS';
export const GET_NUMBER_LIST_FAILURE = 'student/GET_NUMBER_LIST_FAILURE';

export const GET_NUMBER_DATA_REQUESTED = 'student/GET_NUMBER_DATA_REQUESTED';
export const GET_NUMBER_DATA_SUCCESS = 'student/GET_NUMBER_DATA_SUCCESS';
export const GET_NUMBER_DATA_FAILURE = 'student/GET_NUMBER_DATA_FAILURE';

export const GET_COMPLAINT_DATA_REQUESTED = 'student/GET_COMPLAINT_DATA_REQUESTED';
export const GET_COMPLAINT_DATA_SUCCESS = 'student/GET_COMPLAINT_DATA_SUCCESS';
export const GET_COMPLAINT_DATA_FAILURE = 'student/GET_COMPLAINT_DATA_FAILURE';

export const GET_REPLACEMENT_DATA_REQUESTED = 'student/GET_REPLACEMENT_DATA_REQUESTED';
export const GET_REPLACEMENT_DATA_SUCCESS = 'student/GET_REPLACEMENT_DATA_SUCCESS';
export const GET_REPLACEMENT_DATA_FAILURE = 'student/GET_REPLACEMENT_DATA_FAILURE';

export const GET_ENROLLMENT_DATA_REQUESTED = 'student/GET_ENROLLMENT_DATA_REQUESTED';
export const GET_ENROLLMENT_DATA_SUCCESS = 'student/GET_ENROLLMENT_DATA_SUCCESS';
export const GET_ENROLLMENT_DATA_FAILURE = 'student/GET_ENROLLMENT_DATA_FAILURE';

export const GET_CLASS_WITHDRAWAL_DATA_REQUESTED = 'student/GET_CLASS_WITHDRAWAL_DATA_REQUESTED';
export const GET_CLASS_WITHDRAWAL_DATA_SUCCESS = 'student/GET_CLASS_WITHDRAWAL_DATA_SUCCESS';
export const GET_CLASS_WITHDRAWAL_DATA_FAILURE = 'student/GET_CLASS_WITHDRAWAL_DATA_FAILURE';

export const GET_EVALUATION_FORM_REQUESTED = 'student/GET_EVALUATION_FORM_REQUESTED';
export const GET_EVALUATION_FORM_SUCCESS = 'student/GET_EVALUATION_FORM_SUCCESS';
export const GET_EVALUATION_FORM_FAILURE = 'student/GET_EVALUATION_FORM_FAILURE';

export const GET_SURVEY_DETAILS_REQUESTED = 'student/GET_SURVEY_DETAILS_REQUESTED';
export const GET_SURVEY_DETAILS_SUCCESS = 'student/GET_SURVEY_DETAILS_SUCCESS';
export const GET_SURVEY_DETAILS_FAILURE = 'student/GET_SURVEY_DETAILS_FAILURE';

export const GET_LEAVE_ABSENCE_LIST_REQUESTED = 'student/GET_LEAVE_ABSENCE_LIST_REQUESTED';
export const GET_LEAVE_ABSENCE_LIST_SUCCESS = 'student/GET_LEAVE_ABSENCE_LIST_SUCCESS';
export const GET_LEAVE_ABSENCE_LIST_FAILURE = 'student/GET_LEAVE_ABSENCE_LIST_FAILURE';

export const GET_LEAVE_ABSENCE_REQUESTED = 'student/GET_LEAVE_ABSENCE_REQUESTED';
export const GET_LEAVE_ABSENCE_SUCCESS = 'student/GET_LEAVE_ABSENCE_SUCCESS';
export const GET_LEAVE_ABSENCE_FAILURE = 'student/GET_LEAVE_ABSENCE_FAILURE';

export const GET_I94_REQUEST_FORM_LIST_STUDENT_REQUESTED = 'student/GET_I94_REQUEST_FORM_LIST_STUDENT_REQUESTED';
export const GET_I94_REQUEST_FORM_LIST_STUDENT_SUCCESS = 'student/GET_I94_REQUEST_FORM_LIST_STUDENT_SUCCESS';
export const GET_I94_REQUEST_FORM_LIST_STUDENT_FAILURE = 'student/GET_I94_REQUEST_FORM_LIST_STUDENT_FAILURE';

export const GET_I94_REQUEST_FORM_REQUESTED = 'student/GET_I94_REQUEST_FORM_REQUESTED';
export const GET_I94_REQUEST_FORM_SUCCESS = 'student/GET_I94_REQUEST_FORM_SUCCESS';
export const GET_I94_REQUEST_FORM_FAILURE = 'student/GET_I94_REQUEST_FORM_FAILURE';

export const GET_GENERAL_QUERY_LIST_REQUESTED = 'student/GET_GENERAL_QUERY_LIST_REQUESTED';
export const GET_GENERAL_QUERY_LIST_SUCCESS = 'student/GET_GENERAL_QUERY_LIST_SUCCESS';
export const GET_GENERAL_QUERY_LIST_FAILURE = 'student/GET_GENERAL_QUERY_LIST_FAILURE';

export const GET_GENERAL_QUERY_REQUESTED = 'student/GET_GENERAL_QUERY_REQUESTED';
export const GET_GENERAL_QUERY_SUCCESS = 'student/GET_GENERAL_QUERY_SUCCESS';
export const GET_GENERAL_QUERY_FAILURE = 'student/GET_GENERAL_QUERY_FAILURE';

export const GET_I94_LETTER_REQUEST_LIST_REQUESTED = 'student/GET_I94_LETTER_REQUEST_LIST_REQUESTED';
export const GET_I94_LETTER_REQUEST_LIST_SUCCESS = 'student/GET_I94_LETTER_REQUEST_LIST_SUCCESS';
export const GET_I94_LETTER_REQUEST_LIST_FAILURE = 'student/GET_I94_LETTER_REQUEST_LIST_FAILURE';

export const GET_CLINICAL_REQUEST_LIST_REQUESTED = 'student/GET_CLINICAL_REQUEST_LIST_REQUESTED';
export const GET_CLINICAL_REQUEST_LIST_SUCCESS = 'student/GET_CLINICAL_REQUEST_LIST_SUCCESS';
export const GET_CLINICAL_REQUEST_LIST_FAILURE = 'student/GET_CLINICAL_REQUEST_LIST_FAILURE';
export const GET_CLINICAL_REQUEST_PDF_LIST_SUCCESS = 'student/GET_CLINICAL_REQUEST_PDF_LIST_SUCCESS';

export const GET_CLINICAL_REQUEST_REQUESTED = 'student/GET_CLINICAL_REQUEST_REQUESTED';
export const GET_CLINICAL_REQUEST_SUCCESS = 'student/GET_CLINICAL_REQUEST_SUCCESS';
export const GET_CLINICAL_REQUEST_FAILURE = 'student/GET_CLINICAL_REQUEST_FAILURE';

export const GET_COMPLAINT_REQUEST_LIST_REQUESTED = 'student/GET_COMPLAINT_REQUEST_LIST_REQUESTED';
export const GET_COMPLAINT_REQUEST_LIST_SUCCESS = 'student/GET_COMPLAINT_REQUEST_LIST_SUCCESS';
export const GET_COMPLAINT_REQUEST_LIST_FAILURE = 'student/GET_COMPLAINT_REQUEST_LIST_FAILURE';

export const GET_COMPLAINT_REQUEST_REQUESTED = 'student/GET_COMPLAINT_REQUEST_REQUESTED';
export const GET_COMPLAINT_REQUEST_SUCCESS = 'student/GET_COMPLAINT_REQUEST_SUCCESS';
export const GET_COMPLAINT_REQUEST_FAILURE = 'student/GET_COMPLAINT_REQUEST_FAILURE';

export const GET_CLINICAL_REQUEST_BASIC_REQUESTED = 'student/GET_CLINICAL_REQUEST_BASIC_REQUESTED';
export const GET_CLINICAL_REQUEST_BASIC_SUCCESS = 'student/GET_CLINICAL_REQUEST_BASIC_SUCCESS';
export const GET_CLINICAL_REQUEST_BASIC_FAILURE = 'student/GET_CLINICAL_REQUEST_BASIC_FAILURE';

export const GET_STUDENT_COURSES_STATUS_REQUESTED = 'student/GET_STUDENT_COURSES_STATUS_REQUESTED';
export const GET_STUDENT_COURSES_STATUS_SUCCESS = 'student/GET_STUDENT_COURSES_STATUS_SUCCESS';
export const GET_STUDENT_COURSES_STATUS_FAILURE = 'student/GET_STUDENT_COURSES_STATUS_FAILURE';

export const GET_STUDENT_REQUEST_LIST_REQUESTED = 'student/GET_STUDENT_REQUEST_LIST_REQUESTED';
export const GET_STUDENT_REQUEST_LIST_SUCCESS = 'student/GET_STUDENT_REQUEST_LIST_SUCCESS';
export const GET_STUDENT_REQUEST_LIST_FAILURE = 'student/GET_STUDENT_REQUEST_LIST_FAILURE';

export const GET_STUDENT_CLINICAL_MEDICINE_LIST_REQUESTED = 'student/GET_STUDENT_CLINICAL_MEDICINE_LIST_REQUESTED';
export const GET_STUDENT_CLINICAL_MEDICINE_LIST_SUCCESS = 'student/GET_STUDENT_CLINICAL_MEDICINE_LIST_SUCCESS';
export const GET_STUDENT_CLINICAL_MEDICINE_LIST_FAILURE = 'student/GET_STUDENT_CLINICAL_MEDICINE_LIST_FAILURE';

export const GET_STUDENT_CLINICAL_ROTATION_LIST_REQUESTED = 'student/GET_STUDENT_CLINICAL_ROTATION_LIST_REQUESTED';
export const GET_STUDENT_CLINICAL_ROTATION_LIST_SUCCESS = 'student/GET_STUDENT_CLINICAL_ROTATION_LIST_SUCCESS';
export const GET_STUDENT_CLINICAL_ROTATION_LIST_FAILURE = 'student/GET_STUDENT_CLINICAL_ROTATION_LIST_FAILURE';

export const GET_STUDENT_GPA_LIST_REQUESTED = 'student/GET_STUDENT_GPA_LIST_REQUESTED';
export const GET_STUDENT_GPA_LIST_SUCCESS = 'student/GET_STUDENT_GPA_LIST_SUCCESS';
export const GET_STUDENT_GPA_LIST_FAILURE = 'student/GET_STUDENT_GPA_LIST_FAILURE';

export const GET_STUDENT_MED_LIST_REQUESTED = 'student/GET_STUDENT_MED_LIST_REQUESTED';
export const GET_STUDENT_MED_LIST_SUCCESS = 'student/GET_STUDENT_MED_LIST_SUCCESS';
export const GET_STUDENT_MED_LIST_FAILURE = 'student/GET_STUDENT_MED_LIST_FAILURE';

// export const GET_STUDENT_PAYMENT_ONE_LIST_REQUESTED = 'student/GET_STUDENT_PAYMENT_ONE_LIST_REQUESTED';
// export const GET_STUDENT_PAYMENT_ONE_LIST_SUCCESS = 'student/GET_STUDENT_PAYMENT_ONE_LIST_SUCCESS';
// export const GET_STUDENT_PAYMENT_ONE_LIST_FAILURE = 'student/GET_STUDENT_PAYMENT_ONE_LIST_FAILURE';

// export const GET_STUDENT_PAYMENT_TWO_LIST_REQUESTED = 'student/GET_STUDENT_PAYMENT_TWO_LIST_REQUESTED';
// export const GET_STUDENT_PAYMENT_TWO_LIST_SUCCESS = 'student/GET_STUDENT_PAYMENT_TWO_LIST_SUCCESS';
// export const GET_STUDENT_PAYMENT_TWO_LIST_FAILURE = 'student/GET_STUDENT_PAYMENT_TWO_LIST_FAILURE';

export const GET_STUDENT_PREMED_LIST_REQUESTED = 'student/GET_STUDENT_PREMED_LIST_REQUESTED';
export const GET_STUDENT_PREMED_LIST_SUCCESS = 'student/GET_STUDENT_PREMED_LIST_SUCCESS';
export const GET_STUDENT_PREMED_LIST_FAILURE = 'student/GET_STUDENT_PREMED_LIST_FAILURE';

export const GET_APPLICANT_COURSE_DROPDOWN_REQUESTED = 'student/GET_APPLICANT_COURSE_DROPDOWN_REQUESTED';
export const GET_APPLICANT_COURSE_DROPDOWN_SUCCESS = 'student/GET_APPLICANT_COURSE_DROPDOWN_SUCCESS';
export const GET_APPLICANT_COURSE_DROPDOWN_FAILURE = 'student/GET_APPLICANT_COURSE_DROPDOWN_FAILURE';

export const GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_REQUESTED = 'student/GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_REQUESTED';
export const GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_SUCCESS = 'student/GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_SUCCESS';
export const GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_FAILURE = 'student/GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_FAILURE';

export const GET_APPLICANT_ROTATION_DROPDOWN_REQUESTED = 'student/GET_APPLICANT_ROTATION_DROPDOWN_REQUESTED';
export const GET_APPLICANT_ROTATION_DROPDOWN_SUCCESS = 'student/GET_APPLICANT_ROTATION_DROPDOWN_SUCCESS';
export const GET_APPLICANT_ROTATION_DROPDOWN_FAILURE = 'student/GET_APPLICANT_ROTATION_DROPDOWN_FAILURE';

export const GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_REQUESTED = 'student/GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_REQUESTED';
export const GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_SUCCESS = 'student/GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_SUCCESS';
export const GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_FAILURE = 'student/GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_FAILURE';

export const GET_ACADEMIC_RECORDS1_LIST_REQUESTED = 'student/GET_ACADEMIC_RECORDS1_LIST_REQUESTED';
export const GET_ACADEMIC_RECORDS1_LIST_SUCCESS = 'student/GET_ACADEMIC_RECORDS1_LIST_SUCCESS';
export const GET_ACADEMIC_RECORDS1_LIST_FAILURE = 'student/GET_ACADEMIC_RECORDS1_LIST_FAILURE';

export const GET_ACADEMIC_RECORDS2_LIST_REQUESTED = 'student/GET_ACADEMIC_RECORDS2_LIST_REQUESTED';
export const GET_ACADEMIC_RECORDS2_LIST_SUCCESS = 'student/GET_ACADEMIC_RECORDS2_LIST_SUCCESS';
export const GET_ACADEMIC_RECORDS2_LIST_FAILURE = 'student/GET_ACADEMIC_RECORDS2_LIST_FAILURE';

export const GET_ACADEMIC_RECORDS3_LIST_REQUESTED = 'student/GET_ACADEMIC_RECORDS3_LIST_REQUESTED';
export const GET_ACADEMIC_RECORDS3_LIST_SUCCESS = 'student/GET_ACADEMIC_RECORDS3_LIST_SUCCESS';
export const GET_ACADEMIC_RECORDS3_LIST_FAILURE = 'student/GET_ACADEMIC_RECORDS3_LIST_FAILURE';

export const GET_ACADEMIC_RECORDS4_LIST_REQUESTED = 'student/GET_ACADEMIC_RECORDS4_LIST_REQUESTED';
export const GET_ACADEMIC_RECORDS4_LIST_SUCCESS = 'student/GET_ACADEMIC_RECORDS4_LIST_SUCCESS';
export const GET_ACADEMIC_RECORDS4_LIST_FAILURE = 'student/GET_ACADEMIC_RECORDS4_LIST_FAILURE';

export const GET_STUDENT_TRANSCRIPT_LIST_REQUESTED = 'student/GET_STUDENT_TRANSCRIPT_LIST_REQUESTED';
export const GET_STUDENT_TRANSCRIPT_LIST_SUCCESS = 'student/GET_STUDENT_TRANSCRIPT_LIST_SUCCESS';
export const GET_STUDENT_TRANSCRIPT_LIST_FAILURE = 'student/GET_STUDENT_TRANSCRIPT_LIST_FAILURE';

export const GET_STUDENT_TRANSCRIPT_DETAILS_REQUESTED = 'student/GET_STUDENT_TRANSCRIPT_DETAILS_REQUESTED';
export const GET_STUDENT_TRANSCRIPT_DETAILS_SUCCESS = 'student/GET_STUDENT_TRANSCRIPT_DETAILS_SUCCESS';
export const GET_STUDENT_TRANSCRIPT_DETAILS_FAILURE = 'student/GET_STUDENT_TRANSCRIPT_DETAILS_FAILURE';

export const GET_STUDENT_REASON_REQUEST_REQUESTED = 'student/GET_STUDENT_REASON_REQUEST_REQUESTED';
export const GET_STUDENT_REASON_REQUEST_SUCCESS = 'student/GET_STUDENT_REASON_REQUEST_SUCCESS';
export const GET_STUDENT_REASON_REQUEST_FAILURE = 'student/GET_STUDENT_REASON_REQUEST_FAILURE';

export const GET_FEE_NAME_DROPDOWN_REQUESTED = 'student/GET_FEE_NAME_DROPDOWN_REQUESTED';
export const GET_FEE_NAME_DROPDOWN_SUCCESS = 'student/GET_FEE_NAME_DROPDOWN_SUCCESS';
export const GET_FEE_NAME_DROPDOWN_FAILURE = 'student/GET_FEE_NAME_DROPDOWN_FAILURE';

export const GET_EXAM_NAME_DROPDOWN_REQUESTED = 'student/GET_EXAM_NAME_DROPDOWN_REQUESTED';
export const GET_EXAM_NAME_DROPDOWN_SUCCESS = 'student/GET_EXAM_NAME_DROPDOWN_SUCCESS';
export const GET_EXAM_NAME_DROPDOWN_FAILURE = 'student/GET_EXAM_NAME_DROPDOWN_FAILURE';

export const GET_SHIPPING_OPTIONS_REQUESTED = 'student/GET_SHIPPING_OPTIONS_REQUESTED';
export const GET_SHIPPING_OPTIONS_SUCCESS = 'student/GET_SHIPPING_OPTIONS_SUCCESS';
export const GET_SHIPPING_OPTIONS_FAILURE = 'student/GET_SHIPPING_OPTIONS_FAILURE';

export const GET_COURSE_TRANSCRIPT_DATA_REQUESTED = 'student/GET_COURSE_TRANSCRIPT_DATA_REQUESTED';
export const GET_COURSE_TRANSCRIPT_DATA_SUCCESS = 'student/GET_COURSE_TRANSCRIPT_DATA_SUCCESS';
export const GET_COURSE_TRANSCRIPT_DATA_FAILURE = 'student/GET_COURSE_TRANSCRIPT_DATA_FAILURE';

export const GET_CLINICAL_ROSTER_LIST_REQUESTED = 'student/GET_CLINICAL_ROSTER_LIST_REQUESTED';
export const GET_CLINICAL_ROSTER_LIST_SUCCESS = 'student/GET_CLINICAL_ROSTER_LIST_SUCCESS';
export const GET_CLINICAL_ROSTER_LIST_FAILURE = 'student/GET_CLINICAL_ROSTER_LIST_FAILURE';

export const GET_THIRD_PARTY_LIST_REQUESTED = 'student/GET_THIRD_PARTY_LIST_REQUESTED';
export const GET_THIRD_PARTY_LIST_SUCCESS = 'student/GET_THIRD_PARTY_LIST_SUCCESS';
export const GET_THIRD_PARTY_LIST_FAILURE = 'student/GET_THIRD_PARTY_LIST_FAILURE';

export const GET_THIRD_PARTY_REQUESTED = 'student/GET_THIRD_PARTY_REQUESTED';
export const GET_THIRD_PARTY_SUCCESS = 'student/GET_THIRD_PARTY_SUCCESS';
export const GET_THIRD_PARTY_FAILURE = 'student/GET_THIRD_PARTY_FAILURE';

export const GET_STATUS_CHANAGE_LIST_REQUESTED = 'student/GET_STATUS_CHANAGE_LIST_REQUESTED';
export const GET_STATUS_CHANAGE_LIST_SUCCESS = 'student/GET_STATUS_CHANAGE_LIST_SUCCESS';
export const GET_STATUS_CHANAGE_LIST_FAILURE = 'student/GET_STATUS_CHANAGE_LIST_FAILURE';

export const GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_REQUESTED = 'student/GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_REQUESTED';
export const GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_SUCCESS = 'student/GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_SUCCESS';
export const GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_FAILURE = 'student/GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_FAILURE';

export const GET_GRADUATION_SURVEY_FORM_REQUESTED = 'student/GET_GRADUATION_SURVEY_FORM_REQUESTED';
export const GET_GRADUATION_SURVEY_FORM_SUCCESS = 'student/GET_GRADUATION_SURVEY_FORM_SUCCESS';
export const GET_GRADUATION_SURVEY_FORM_FAILURE = 'student/GET_GRADUATION_SURVEY_FORM_FAILURE';

export const GET_GRADUATION_SURVEY_LIST_REQUESTED = 'student/GET_GRADUATION_SURVEY_LIST_REQUESTED';
export const GET_GRADUATION_SURVEY_LIST_SUCCESS = 'student/GET_GRADUATION_SURVEY_LIST_SUCCESS';
export const GET_GRADUATION_SURVEY_LIST_FAILURE = 'student/GET_GRADUATION_SURVEY_LIST_FAILURE';

export const GET_OTHER_DETAILS_REQUESTED = 'student/GET_OTHER_DETAILS_REQUESTED';
export const GET_OTHER_DETAILS_SUCCESS = 'student/GET_OTHER_DETAILS_SUCCESS';
export const GET_OTHER_DETAILS_FAILURE = 'student/GET_OTHER_DETAILS_FAILURE';

export const GET_DEGREE_REQUESTED = 'student/GET_DEGREE_REQUESTED';
export const GET_DEGREE_SUCCESS = 'student/GET_DEGREE_SUCCESS';
export const GET_DEGREE_FAILURE = 'student/GET_DEGREE_FAILURE';

export const GET_ESTIMATED_VALUE_REQUESTED = 'student/GET_ESTIMATED_VALUE_REQUESTED';
export const GET_ESTIMATED_VALUE_SUCCESS = 'student/GET_ESTIMATED_VALUE_SUCCESS';
export const GET_ESTIMATED_VALUE_FAILURE = 'student/GET_ESTIMATED_VALUE_FAILURE';

export const GET_ACCOUNTING_INFO_REQUESTED = 'student/GET_ACCOUNTING_INFO_REQUESTED';
export const GET_ACCOUNTING_INFO_SUCCESS = 'student/GET_ACCOUNTING_INFO_SUCCESS';
export const GET_ACCOUNTING_INFO_FAILURE = 'student/GET_ACCOUNTING_INFO_FAILURE';

export const GET_ACCOUNTING_OTHER_INFO_REQUESTED = 'student/GET_ACCOUNTING_OTHER_INFO_REQUESTED';
export const GET_ACCOUNTING_OTHER_INFO_SUCCESS = 'student/GET_ACCOUNTING_OTHER_INFO_SUCCESS';
export const GET_ACCOUNTING_OTHER_INFO_FAILURE = 'student/GET_ACCOUNTING_OTHER_INFO_FAILURE';

export const GET_MALPRACTICE_INSURANCE_LIST_REQUESTED = 'student/GET_MALPRACTICE_INSURANCE_LIST_REQUESTED';
export const GET_MALPRACTICE_INSURANCE_LIST_SUCCESS = 'student/GET_MALPRACTICE_INSURANCE_LIST_SUCCESS';
export const GET_MALPRACTICE_INSURANCE_LIST_FAILURE = 'student/GET_MALPRACTICE_INSURANCE_LIST_FAILURE';

export const GET_MALPRACTICE_INSURANCE_BY_ID_REQUESTED = 'student/GET_MALPRACTICE_INSURANCE_BY_ID_REQUESTED';
export const GET_MALPRACTICE_INSURANCE_BY_ID_SUCCESS = 'student/GET_MALPRACTICE_INSURANCE_BY_ID_SUCCESS';
export const GET_MALPRACTICE_INSURANCE_BY_ID_FAILURE = 'student/GET_MALPRACTICE_INSURANCE_BY_ID_FAILURE';

export const GET_ALUMINI_LIST_REQUESTED = 'student/GET_ALUMINI_LIST_REQUESTED';
export const GET_ALUMINI_LIST_SUCCESS = 'student/GET_ALUMINI_LIST_SUCCESS';
export const GET_ALUMINI_LIST_FAILURE = 'student/GET_ALUMINI_LIST_FAILURE';

export const ADD_ALUMNI_EMAIL_REQUESTED = 'student/ADD_ALUMNI_EMAIL_REQUESTED';
export const ADD_ALUMNI_EMAIL_SUCCESS = 'student/ADD_ALUMNI_EMAIL_SUCCESS';
export const ADD_ALUMNI_EMAIL_FAILURE = 'student/ADD_ALUMNI_EMAIL_FAILURE';

export const GET_DEGREE_LIST_REQUESTED = 'student/GET_DEGREE_LIST_REQUESTED';
export const GET_DEGREE_LIST_SUCCESS = 'student/GET_DEGREE_LIST_SUCCESS';
export const GET_DEGREE_LIST_FAILURE = 'student/GET_DEGREE_LIST_FAILURE';

export const STUDENT_ACTIVITY_LOG_REQUESTED = 'student/STUDENT_ACTIVITY_LOG_REQUESTED';
export const STUDENT_ACTIVITY_LOG_SUCCESS = 'student/STUDENT_ACTIVITY_LOG_SUCCESS';
export const STUDENT_ACTIVITY_LOG_FAILURE = 'student/STUDENT_ACTIVITY_LOG_FAILURE';

export const GET_NEXT_STAGE_CLASSES_REQUESTED = 'student/GET_NEXT_STAGE_CLASSES_REQUESTED';
export const GET_NEXT_STAGE_CLASSES_SUCCESS = 'student/GET_NEXT_STAGE_CLASSES_SUCCESS';
export const GET_NEXT_STAGE_CLASSES_FAILURE = 'student/GET_NEXT_STAGE_CLASSES_FAILURE';

export const TRANSCRIPT_LIST_STUDENT_SUCCESS = 'student/TRANSCRIPT_LIST_STUDENT_SUCCESS';
export const GET_TRANSCRIPT_FULL_DATA_SUCCESS = 'student/GET_TRANSCRIPT_FULL_DATA_SUCCESS';

export const REFUND_TYPE_DROPDOWN_REQUESTED = 'student/REFUND_TYPE_DROPDOWN_REQUESTED';
export const REFUND_TYPE_DROPDOWN_SUCCESS = 'student/REFUND_TYPE_DROPDOWN_SUCCESS';
export const REFUND_TYPE_DROPDOWN_FAILURE = 'student/REFUND_TYPE_DROPDOWN_FAILURE';

export const CREDIT_LIST_REQUESTED = 'student/CREDIT_LIST_REQUESTED';
export const CREDIT_LIST_SUCCESS = 'student/CREDIT_LIST_SUCCESS';
export const CREDIT_LIST_FAILURE = 'student/CREDIT_LIST_FAILURE';

export const CREDIT_LIST_DELETED_REQUESTED = 'student/CREDIT_LIST_DELETED_REQUESTED';
export const CREDIT_LIST_DELETED_SUCCESS = 'student/CREDIT_LIST_DELETED_SUCCESS';
export const CREDIT_LIST_DELETED_FAILURE = 'student/CREDIT_LIST_DELETED_FAILURE';

export const CREDIT_DETAILS_REQUESTED = 'student/CREDIT_DETAILS_REQUESTED';
export const CREDIT_DETAILS_SUCCESS = 'student/CREDIT_DETAILS_SUCCESS';
export const CREDIT_DETAILS_FAILURE = 'student/CREDIT_DETAILS_FAILURE';

export const HOLDING_CREDIT_DETAILS_REQUESTED = 'student/HOLDING_CREDIT_DETAILS_REQUESTED';
export const HOLDING_CREDIT_DETAILS_SUCCESS = 'student/HOLDING_CREDIT_DETAILS_SUCCESS';
export const HOLDING_CREDIT_DETAILS_FAILURE = 'student/HOLDING_CREDIT_DETAILS_FAILURE';

export const PAYMENT_TYPE_DROPDOWN_REQUESTED = 'student/PAYMENT_TYPE_DROPDOWN_REQUESTED';
export const PAYMENT_TYPE_DROPDOWN_SUCCESS = 'student/PAYMENT_TYPE_DROPDOWN_SUCCESS';
export const PAYMENT_TYPE_DROPDOWN_FAILURE = 'student/PAYMENT_TYPE_DROPDOWN_FAILURE';

export const INVOICE_DETAILS_REQUESTED = 'student/INVOICE_DETAILS_REQUESTED';
export const INVOICE_DETAILS_SUCCESS = 'student/INVOICE_DETAILS_SUCCESS';
export const INVOICE_DETAILS_FAILURE = 'student/INVOICE_DETAILS_FAILURE';

export const NO_INVOICE_DETAILS_REQUESTED = 'student/NO_INVOICE_DETAILS_REQUESTED';
export const NO_INVOICE_DETAILS_SUCCESS = 'student/NO_INVOICE_DETAILS_SUCCESS';
export const NO_INVOICE_DETAILS_FAILURE = 'student/NO_INVOICE_DETAILS_FAILURE';

export const DOCUSIGN_FORM_REQUESTED = 'student/DOCUSIGN_FORM_REQUESTED';
export const DOCUSIGN_FORM_SUCCESS = 'student/DOCUSIGN_FORM_SUCCESS';
export const DOCUSIGN_FORM_FAILURE = 'student/DOCUSIGN_FORM_FAILURE';

export const GET_INVOICEP_CREDIT_DETAILS_REQUESTED = 'student/GET_INVOICEP_CREDIT_DETAILS_REQUESTED';
export const GET_INVOICEP_CREDIT_DETAILS_SUCCESS = 'student/GET_INVOICEP_CREDIT_DETAILS_SUCCESS';
export const GET_INVOICEP_CREDIT_DETAILS_FAILURE = 'student/GET_INVOICEP_CREDIT_DETAILS_FAILURE';

export const GET_INVOICEP_LIST_REQUESTED = 'student/GET_INVOICEP_LIST_REQUESTED';
export const GET_INVOICEP_LIST_SUCCESS = 'student/GET_INVOICEP_LIST_SUCCESS';
export const GET_INVOICEP_LIST_FAILURE = 'student/GET_INVOICEP_LIST_FAILURE';

export const GET_INVOICEP_DETAILS_REQUESTED = 'student/GET_INVOICEP_DETAILS_REQUESTED';
export const GET_INVOICEP_DETAILS_SUCCESS = 'student/GET_INVOICEP_DETAILS_SUCCESS';
export const GET_INVOICEP_DETAILS_FAILURE = 'student/GET_INVOICEP_DETAILS_FAILURE';

export const GET_CANCELLED_INVOICEP_LIST_REQUESTED = 'student/GET_CANCELLED_INVOICEP_LIST_REQUESTED';
export const GET_CANCELLED_INVOICEP_LIST_SUCCESS = 'student/GET_CANCELLED_INVOICEP_LIST_SUCCESS';
export const GET_CANCELLED_INVOICEP_LIST_FAILURE = 'student/GET_CANCELLED_INVOICEP_LIST_FAILURE';

export const GET_ACCOUNT_LEDGER_LIST_REQUESTED = 'student/GET_ACCOUNT_LEDGER_LIST_REQUESTED';
export const GET_ACCOUNT_LEDGER_LIST_SUCCESS = 'student/GET_ACCOUNT_LEDGER_LIST_SUCCESS';
export const GET_ACCOUNT_LEDGER_LIST_FAILURE = 'student/GET_ACCOUNT_LEDGER_LIST_FAILURE';

export const GET_INVOICEP_TYPE_REQUESTED = 'student/GET_INVOICEP_TYPE_REQUESTED';
export const GET_INVOICEP_TYPE_SUCCESS = 'student/GET_INVOICEP_TYPE_SUCCESS';
export const GET_INVOICEP_TYPE_FAILURE = 'student/GET_INVOICEP_TYPE_FAILURE';

export const GET_INVOICEP_SCHOLARSHIP_LIST_REQUESTED = 'student/GET_INVOICEP_SCHOLARSHIP_LIST_REQUESTED';
export const GET_INVOICEP_SCHOLARSHIP_LIST_SUCCESS = 'student/GET_INVOICEP_SCHOLARSHIP_LIST_SUCCESS';
export const GET_INVOICEP_SCHOLARSHIP_LIST_FAILURE = 'student/GET_INVOICEP_SCHOLARSHIP_LIST_FAILURE';

export const GET_INVOICEP_PAYMENT_LIST_REQUESTED = 'student/GET_INVOICEP_PAYMENT_LIST_REQUESTED';
export const GET_INVOICEP_PAYMENT_LIST_SUCCESS = 'student/GET_INVOICEP_PAYMENT_LIST_SUCCESS';
export const GET_INVOICEP_PAYMENT_LIST_FAILURE = 'student/GET_INVOICEP_PAYMENT_LIST_FAILURE';

export const GET_INVOICEP_HEAD_REQUESTED = 'student/GET_INVOICEP_HEAD_REQUESTED';
export const GET_INVOICEP_HEAD_SUCCESS = 'student/GET_INVOICEP_HEAD_SUCCESS';
export const GET_INVOICEP_HEAD_FAILURE = 'student/GET_INVOICEP_HEAD_FAILURE';

export const GET_INVOICEP_AMOUNT_REQUESTED = 'student/GET_INVOICEP_AMOUNT_REQUESTED';
export const GET_INVOICEP_AMOUNT_SUCCESS = 'student/GET_INVOICEP_AMOUNT_SUCCESS';
export const GET_INVOICEP_AMOUNT_FAILURE = 'student/GET_INVOICEP_AMOUNT_FAILURE';

export const GET_INVOICEP_ESTIMATE_TYPE_REQUESTED = 'student/GET_INVOICEP_ESTIMATE_TYPE_REQUESTED';
export const GET_INVOICEP_ESTIMATE_TYPE_SUCCESS = 'student/GET_INVOICEP_ESTIMATE_TYPE_SUCCESS';
export const GET_INVOICEP_ESTIMATE_TYPE_FAILURE = 'student/GET_INVOICEP_ESTIMATE_TYPE_FAILURE';

export const GET_INVOICEP_ESTIMATE_REQUESTED = 'student/GET_INVOICEP_ESTIMATE_REQUESTED';
export const GET_INVOICEP_ESTIMATE_SUCCESS = 'student/GET_INVOICEP_ESTIMATE_SUCCESS';
export const GET_INVOICEP_ESTIMATE_FAILURE = 'student/GET_INVOICEP_ESTIMATE_FAILURE';

export const GET_INVOICEP_PAY_PAYMENT_LIST_REQUESTED = 'student/GET_INVOICEP_PAY_PAYMENT_LIST_REQUESTED';
export const GET_INVOICEP_PAY_PAYMENT_LIST_SUCCESS = 'student/GET_INVOICEP_PAY_PAYMENT_LIST_SUCCESS';
export const GET_INVOICEP_PAY_PAYMENT_LIST_FAILURE = 'student/GET_INVOICEP_PAY_PAYMENT_LIST_FAILURE';

export const GET_ESTIMATE_COURSE_REQUESTED = 'student/GET_ESTIMATE_COURSE_REQUESTED';
export const GET_ESTIMATE_COURSE_SUCCESS = 'student/GET_ESTIMATE_COURSE_SUCCESS';
export const GET_ESTIMATE_COURSE_FAILURE = 'student/GET_ESTIMATE_COURSE_FAILURE';

export const GET_ESTIMATE_BY_ID_REQUESTED = 'student/GET_ESTIMATE_BY_ID_REQUESTED';
export const GET_ESTIMATE_BY_ID_SUCCESS = 'student/GET_ESTIMATE_BY_ID_SUCCESS';
export const GET_ESTIMATE_BY_ID_FAILURE = 'student/GET_ESTIMATE_BY_ID_FAILURE';

export const GET_INVOICEP_PAYMENT_DETAILS_REQUESTED = 'student/GET_INVOICEP_PAYMENT_DETAILS_REQUESTED';
export const GET_INVOICEP_PAYMENT_DETAILS_SUCCESS = 'student/GET_INVOICEP_PAYMENT_DETAILS_SUCCESS';
export const GET_INVOICEP_PAYMENT_DETAILS_FAILURE = 'student/GET_INVOICEP_PAYMENT_DETAILS_FAILURE';

export const GET_ESTIMATE_PAYMENT_DETAILS_REQUESTED = 'student/GET_ESTIMATE_PAYMENT_DETAILS_REQUESTED';
export const GET_ESTIMATE_PAYMENT_DETAILS_SUCCESS = 'student/GET_ESTIMATE_PAYMENT_DETAILS_SUCCESS';
export const GET_ESTIMATE_PAYMENT_DETAILS_FAILURE = 'student/GET_ESTIMATE_PAYMENT_DETAILS_FAILURE';

export const GET_PAY_ESTIMATE_LIST_REQUESTED = 'student/GET_PAY_ESTIMATE_LIST_REQUESTED';
export const GET_PAY_ESTIMATE_LIST_SUCCESS = 'student/GET_PAY_ESTIMATE_LIST_SUCCESS';
export const GET_PAY_ESTIMATE_LIST_FAILURE = 'student/GET_PAY_ESTIMATE_LIST_FAILURE';

export const GET_STUDENT_ROTATION_CONTRO_LIST_REQUESTED = 'student/GET_STUDENT_ROTATION_CONTRO_LIST_REQUESTED';
export const GET_STUDENT_ROTATION_CONTRO_LIST_SUCCESS = 'student/GET_STUDENT_ROTATION_CONTRO_LIST_SUCCESS';
export const GET_STUDENT_ROTATION_CONTRO_LIST_FAILURE = 'student/GET_STUDENT_ROTATION_CONTRO_LIST_FAILURE';

export const GET_PAYMENT_STATEMENT_REQUESTED = 'student/GET_PAYMENT_STATEMENT_REQUESTED';
export const GET_PAYMENT_STATEMENT_SUCCESS = 'student/GET_PAYMENT_STATEMENT_SUCCESS';
export const GET_PAYMENT_STATEMENT_FAILURE = 'student/GET_PAYMENT_STATEMENT_FAILURE';

export const GET_OLD_PAYMENTS_REQUESTED = 'student/GET_OLD_PAYMENTS_REQUESTED';
export const GET_OLD_PAYMENTS_SUCCESS = 'student/GET_OLD_PAYMENTS_SUCCESS';
export const GET_OLD_PAYMENTS_FAILURE = 'student/GET_OLD_PAYMENTS_FAILURE';

export const GET_ROTATION_PAYMENTS_INFO_REQUESTED = 'student/GET_ROTATION_PAYMENTS_INFO_REQUESTED';
export const GET_ROTATION_PAYMENTS_INFO_SUCCESS = 'student/GET_ROTATION_PAYMENTS_INFO_SUCCESS';
export const GET_ROTATION_PAYMENTS_INFO_FAILURE = 'student/GET_ROTATION_PAYMENTS_INFO_FAILURE';

export const GET_FCM_DEVICE_DETAILS_REQUESTED = 'student/GET_FCM_DEVICE_DETAILS_REQUESTED';
export const GET_FCM_DEVICE_DETAILS_SUCCESS = 'student/GET_FCM_DEVICE_DETAILS_SUCCESS';
export const GET_FCM_DEVICE_DETAILS_FAILURE = 'student/GET_FCM_DEVICE_DETAILS_FAILURE';

export const GET_APPEAL_LIST_DETAILS_REQUESTED = 'student/GET_APPEAL_LIST_DETAILS_REQUESTED';
export const GET_APPEAL_LIST_DETAILS_SUCCESS = 'student/GET_APPEAL_LIST_DETAILS_SUCCESS';
export const GET_APPEAL_LIST_DETAILS_FAILURE = 'student/GET_APPEAL_LIST_DETAILS_FAILURE';

export const GET_APPEAL_DETAILS_DETAILS_REQUESTED = 'student/GET_APPEAL_DETAILS_DETAILS_REQUESTED';
export const GET_APPEAL_DETAILS_DETAILS_SUCCESS = 'student/GET_APPEAL_DETAILS_DETAILS_SUCCESS';
export const GET_APPEAL_DETAILS_DETAILS_FAILURE = 'student/GET_APPEAL_DETAILS_DETAILS_FAILURE';

export const GET_STUDENT_LOAN_BY_ID_SUCCESS = 'student/GET_STUDENT_LOAN_BY_ID_SUCCESS';

export const GET_STUDENT_LOAN_LIST_BY_SID_SUCCESS = 'student/GET_STUDENT_LOAN_LIST_BY_SID_SUCCESS';

export const GET_STUDENT_PLAN_ROTATION_LIST_REQUESTED = 'student/GET_STUDENT_PLAN_ROTATION_LIST_REQUESTED';
export const GET_STUDENT_PLAN_ROTATION_LIST_SUCCESS = 'student/GET_STUDENT_PLAN_ROTATION_LIST_SUCCESS';
export const GET_STUDENT_PLAN_ROTATION_LIST_FAILURE = 'student/GET_STUDENT_PLAN_ROTATION_LIST_FAILURE';

export const GET_STUDENT_PLAN_ROTATION_REQUESTED = 'student/GET_STUDENT_PLAN_ROTATION_REQUESTED';
export const GET_STUDENT_PLAN_ROTATION_SUCCESS = 'student/GET_STUDENT_PLAN_ROTATION_SUCCESS';
export const GET_STUDENT_PLAN_ROTATION_FAILURE = 'student/GET_STUDENT_PLAN_ROTATION_FAILURE';

export const GET_STUDENT_NOTIFICATIONS_LIST = 'student/GET_STUDENT_NOTIFICATIONS_LIST';

export const GET_STUDENT_RC_DETAILS_SUCCESS = 'student/GET_STUDENT_RC_DETAILS_SUCCESS';

export const GET_STUDENT_SMS_EMAIL_SUCCESS = 'student/GET_STUDENT_SMS_EMAIL_SUCCESS';

export const GET_STUDENT_SMS_DETAILS_SUCCESS = 'student/GET_STUDENT_SMS_DETAILS_SUCCESS';

export const GET_PROBLEM_LIST_REQUESTED = 'student/GET_PROBLEM_LIST_REQUESTED';
export const GET_PROBLEM_LIST_SUCCESS = 'student/GET_PROBLEM_LIST_SUCCESS';
export const GET_PROBLEM_LIST_FAILURE = 'student/GET_PROBLEM_LIST_FAILURE';

export const GET_PROBLEM_DATA_SUCCESS = 'student/GET_PROBLEM_DATA_SUCCESS';

export const GET_OPTION_DATA_SUCCESS = 'student/GET_OPTION_DATA_SUCCESS';

export const GET_OPTION_LIST_REQUESTED = 'student/GET_OPTION_LIST_REQUESTED';
export const GET_OPTION_LIST_SUCCESS = 'student/GET_OPTION_LIST_SUCCESS';
export const GET_OPTION_LIST_FAILURE = 'student/GET_OPTION_LIST_FAILURE';

export const GET_PRECEPTOR_DATA_REQUESTED = 'student/GET_PRECEPTOR_DATA_REQUESTED';
export const GET_PRECEPTOR_DATA_SUCCESS = 'student/GET_PRECEPTOR_DATA_SUCCESS';
export const GET_PRECEPTOR_DATA_FAILURE = 'student/GET_PRECEPTOR_DATA_FAILURE';

export const GET_CASE_REPORT_DETAILS_SUCCESS = 'student/GET_CASE_REPORT_DETAILS_SUCCESS';

export const STUDENT_EMAIL_VIEW_REQUESTED = 'student/STUDENT_EMAIL_VIEW_REQUESTED';
export const STUDENT_EMAIL_VIEW_SUCCESS = 'student/STUDENT_EMAIL_VIEW_SUCCESS';
export const STUDENT_EMAIL_VIEW_FAILURE = 'student/STUDENT_EMAIL_VIEW_FAILURE';

export const GET_PRECEPTOR_ROTATION_LIST = 'student/GET_PRECEPTOR_ROTATION_LIST';
export const GET_PRECEPTOR_ROTATION_DETAILS = 'student/GET_PRECEPTOR_ROTATION_DETAILS';
export const GET_PRECEPTOR_USER_DETAILS = 'student/GET_PRECEPTOR_USER_DETAILS';

export const STUDENT_EMAIL_SEND_REQUESTED = 'student/STUDENT_EMAIL_SEND_REQUESTED';
export const STUDENT_EMAIL_SEND_SUCCESS = 'student/STUDENT_EMAIL_SEND_SUCCESS';
export const STUDENT_EMAIL_SEND_FAILURE = 'student/STUDENT_EMAIL_SEND_FAILURE';

export const GET_STUDENT_EMAIL_SUCCESS = 'student/GET_STUDENT_EMAIL_SUCCESS';

export const SELECTED_FILTER_DATA = 'student/SELECTED_FILTER_DATA';

export const GET_STUDENT_DETAILS_SUCCESS = 'student/GET_STUDENT_DETAILS_SUCCESS';

export const GET_FORCE_NOTIFICATION_SUCCESS = 'student/GET_FORCE_NOTIFICATION_SUCCESS';

export const GET_PARENT_DETAILS_SUCCESS = 'student/GET_PARENT_DETAILS_SUCCESS';

export const GET_PARENT_DETAILS_LIST_SUCCESS = 'student/GET_PARENT_DETAILS_LIST_SUCCESS';

export const UPDATE_CREATED_ON_SUCCESS = 'student/UPDATE_CREDIT_CREATED_ON_SUCCESS';

export const UPDATE_INVOICE_DATE_REQUESTED = 'student/UPDATE_INVOICE_DATE_REQUESTED';
export const UPDATE_INVOICE_DATE_SUCCESS = 'student/UPDATE_INVOICE_DATE_SUCCESS';
export const UPDATE_INVOICE_DATE_FAILURE = 'student/UPDATE_INVOICE_DATE_FAILURE';

export const GET_IMMIGRATION_DETAILS_SUCCESS = 'student/GET_IMMIGRATION_DETAILS_SUCCESS';

export const GET_SURVEY_RESPONSE_DATA_BY_ID = 'student/GET_SURVEY_RESPONSE_DATA_BY_ID';

export const GET_STUDENT_SMS_NOTIFICATION = 'student/GET_STUDENT_SMS_NOTIFICATION';
export const POST_STUDENT_SMS_NOTIFICATION = 'student/POST_STUDENT_SMS_NOTIFICATION';

export const GET_STUDENT_EDUCATIONAL_ADMISSION_INFO = 'student/GET_STUDENT_EDUCATIONAL_ADMISSION_INFO';

export const GET_STUDENT_BILLING_ADMISSION_INFO = 'student/GET_STUDENT_BILLING_ADMISSION_INFO';

export const GET_CLINICAL_LOGS_INFO = 'student/GET_CLINICAL_LOGS_INFO';

export const GET_STUDENT_BILLING_ADMISSION_LIST = 'student/GET_STUDENT_BILLING_ADMISSION_LIST';

export const GET_SEMESTER_DEFERMENT_DATA = 'student/GET_SEMESTER_DEFERMENT_DATA';

export const GET_TUTORIAL_VIDEO_LIST = 'student/GET_TUTORIAL_VIDEO_LIST';

export const GET_CLINICALS_PDF_LOGS_INFO = 'student/GET_CLINICALS_PDF_LOGS_INFO';

export const GET_STUDENT_NOTIFYLIST_SUCCESS = 'student/GET_STUDENT_NOTIFYLIST_SUCCESS';
export const GET_STUDENT_NOTIFYLIST_REQUEST = 'student/GET_STUDENT_NOTIFYLIST_REQUEST';
export const GET_STUDENT_NOTIFYLIST_FAILURE = 'student/GET_STUDENT_NOTIFYLIST_FAILURE';

export const GET_NO_INVOICE_PAYMENT_LIST_REQUESTED = 'student/GET_NO_INVOICE_PAYMENT_LIST_REQUESTED';
export const GET_NO_INVOICE_PAYMENT_LIST_SUCCESS = 'student/GET_NO_INVOICE_PAYMENT_LIST_SUCCESS';
export const GET_NO_INVOICE_PAYMENT_LIST_FAILURE = 'student/GET_NO_INVOICE_PAYMENT_LIST_FAILURE';

export const GET_STUDENT_MED6_LOA_SUCCESS = 'student/GET_STUDENT_MED6_LOA_SUCCESS';
export const GET_NBME_NO_OF_ATTEMPTS = 'student/GET_NBME_NO_OF_ATTEMPTS';

export const GET_CREDIT_DETAILS_SCHOLARSHIP_VIEW = 'student/GET_CREDIT_DETAILS_SCHOLARSHIP_VIEW';
export const GET_CREDIT_DETAILS_CREDIT_VIEW = 'student/GET_CREDIT_DETAILS_CREDIT_VIEW';
export const GET_CREDIT_DETAILS_NO_INVOICE_VIEW = 'student/GET_CREDIT_DETAILS_NO_INVOICE_VIEW';
export const UPDATE_CREDIT_DETAILS_INVOICE_REFERENCE_NUMBER = 'student/UPDATE_CREDIT_DETAILS_INVOICE_REFERENCE_NUMBER';

export const GET_STAGE_DROPDOWN_FOR_CREDIT_REPORT_SUCCESS = 'student/GET_STAGE_DROPDOWN_FOR_CREDIT_REPORT_SUCCESS';

export const GET_PLANED_ROTATIONS_HOSPITALS_SUCCESS = 'student/GET_PLANED_ROTATIONS_HOSPITALS_SUCCESS';

export const GET_STUDENT_PROFILE_PIC_REQUESTED = 'student/GET_STUDENT_PROFILE_PIC_REQUESTED';
export const GET_STUDENT_PROFILE_PIC_BINARY_SUCCESS = 'student/GET_STUDENT_PROFILE_PIC_BINARY_SUCCESS';
export const GET_STUDENT_PROFILE_PIC_BINARY_FAILURE = 'student/GET_STUDENT_PROFILE_PIC_BINARY_FAILURE';

export const GET_EXAM_TYPE_SUB_SUCCESS = 'student/GET_EXAM_TYPE_SUB_SUCCESS';
export const GET_EXAM_TYPE_SUB_FAILURE = 'student/GET_EXAM_TYPE_SUB_FAILURE';

export const DOCUSIGN_DOCS_REQUESTED = 'student/DOCUSIGN_DOCS_REQUESTED';
export const DOCUSIGN_DOCS_SUCCESS = 'student/DOCUSIGN_DOCS_SUCCESS';
export const DOCUSIGN_DOCS_FAILURE = 'student/DOCUSIGN_DOCS_FAILURE';

export const CREDIT_LIST_DETAILS_REQUESTED = 'student/CREDIT_LIST_DETAILS_REQUESTED';
export const CREDIT_LIST_DETAILS_SUCCESS = 'student/CREDIT_LIST_DETAILS_SUCCESS';
export const CREDIT_LIST_DETAILS_FAILURE = 'student/CREDIT_LIST_DETAILS_FAILURE';

export const GET_ALL_CLASS_LIST_REQUESTED = 'student/GET_ALL_CLASS_LIST_REQUESTED';
export const GET_ALL_CLASS_LIST_SUCCESS = 'student/GET_ALL_CLASS_LIST_SUCCESS';
export const GET_ALL_CLASS_LIST_FAILURE = 'student/GET_ALL_CLASS_LIST_FAILURE';

export const MCAT_LIST_REQUESTED = 'student/MCAT_LIST_REQUESTED';
export const MCAT_LIST_SUCCESS = 'student/MCAT_LIST_SUCCESS';
export const MCAT_LIST_FAILURE = 'student/MCAT_LIST_FAILURE';

export const MCAT_DETAILS_REQUESTED = 'student/MCAT_DETAILS_REQUESTED';
export const MCAT_DETAILS_SUCCESS = 'student/MCAT_DETAILS_SUCCESS';
export const MCAT_DETAILS_FAILURE = 'student/MCAT_DETAILS_FAILURE';

const initialState = {
  profileLoading: false,
  loadingFlag: false,
  loading: false,
  appealLoading: false,
  loaded: false,
  error: false,
  StudentList: null,
  studentAddress: null,
  activeStudentBasicInfo: null,
  studentProfile: null,
  studentAdmissionForm: null,
  studentPersonalDetails: null,
  documentList: null,
  notesList: null,
  studentProfileHeader: null,
  studentList: null,
  StudentAddress: {},
  studentAcademicApplicant: null,
  studentAcademicAttending: null,
  studentAcademicNBMEList: null,
  studentAcademicKaplanList: null,
  disciplineList: null,
  numberOfAttempts: null,
  nbmeScoreData: null,
  kaplanScoreData: null,
  studentNoteListResponse: null,
  getStudentNotesById: null,
  studentTypeList: null,
  studentCourseStatusList: null,
  studentStatusList: null,
  studentCourseList: null,
  userType: null,
  studentRotationList: null,
  preceptorList: null,
  clericalInfo: {},
  clinicalLogVerificationInfo: {},
  clinicalLogProblemOptions: null,
  clinicalProceduresDropdown: [],
  rotationDates: null,
  clinicalLogAcademicList: [],
  alumniResidencyYearList: null,
  alumniSpecialtyList: null,
  getAlumniJobById: null,
  alumniPersonalListById: null,
  alumniJobListById: null,
  getAlumniOtherDegreeById: null,
  alumniOtherDegreeListById: null,
  studentCourse: null,
  studentCourseDetails: null,
  residencyProgramList: null,
  residencyProgram: null,
  residencySpecialityList: null,
  residencyProgramType: null,
  academicInfoClinicallogById: {},
  downloadClinicalLogData: {},
  rotationDropdown: null,
  rotationGradeType: null,
  rotationStatus: null,
  rotationHospital: null,
  rotationPreceptor: null,
  studentHospital: null,
  clinicalTransferList: null,
  clinicalTransferData: null,
  clinicalRotationData: null,
  clericalInfoList: null,
  clericalListData: {},
  stageDropdownList: null,
  classListByStage: null,
  gradeTypeList: null,
  gradePointStatus: null,
  nonClinicalClassesList: null,
  nonClinicalStagesList: null,
  nonClinicalStageAndSemester: null,
  nonClinicalAcademicStanding: null,
  academicStandingDropdown: null,
  transferListNonClinical: null,
  transferNonClinical: null,
  academicStatndingList: null,
  nonClinicalClassDetails: null,
  specialStageDropdown: null,
  usmleTestList: null,
  usmleNoOfAttempts: null,
  getUsmleTestById: null,
  studentLeaveList: null,
  studentLeaveReasonList: null,
  getStudentLeave: null,
  studentClinicalDocList: null,
  studentClinicalDocTypeList: null,
  studentClinicalDoc: null,
  stageStatus: null,
  alumniMatchInfo: null,
  programWithdrawalList: null,
  programWithdrawalData: null,
  transferClassDropdown: null,
  replacementDiplomaList: null,
  replacementDiploma: null,
  rotationDropdownWeeks: null,
  i94LetterById: null,
  enrollmentRequestList: null,
  enrollmentRequestById: null,
  graduationrequestList: null,
  graduationrequestById: null,
  preMedLoaRequestList: null,
  preMedLoaRequestById: null,
  classWithdrawalList: null,
  enrollmentList: null,
  enrollmentData: null,
  classWithdrawalData: null,
  evaluationFormData: null,
  surveyDetailsList: null,
  replacementList: null,
  replacementData: null,
  graduationList: null,
  graduationData: null,
  absenceList: null,
  absenceData: null,
  complaintList: null,
  complaintData: null,
  queryData: null,
  numbers: null,
  numberList: null,
  mspeData: null,
  financeList: null,
  financeData: null,
  mspeList: null,
  leaveAbsenceList: null,
  leaveAbsence: null,
  i94FormListStudent: null,
  i94Form: null,
  generalQueryList: null,
  generalQuery: null,
  i94LetterRequestList: null,
  clinicalRequestList: null,
  clinicalRequestById: null,
  cLinicalRequestList: null,
  complaintRequestL: null,
  clinicalBasicInfoRequest: null,
  complaintRequestList: null,
  studentCourseStatus: null,
  rotationList: null,
  rotationListElective: null,
  studentRequestList: null,
  clinicalMedicineList: null,
  clinicalRotationList: null,
  gpaList: null,
  medClassList: null,
  // paymentOneList: null,
  // paymentTwoList: null,
  preMedList: null,
  applicantCourseDropdownList: [],
  applicantMedicalCourseDropdownList: [],
  applicantRotationDropdownList: [],
  standardizedTestList: [],
  academicRecords1List: null,
  academicRecords2List: null,
  academicRecords3List: null,
  academicRecords4List: null,
  studentTranscriptList: null,
  studentTranscriptDetails: null,
  studentRequestReasonList: null,
  feeNameDropDown: null,
  examNameDropDown: null,
  shippingOptions: null,
  transcriptCourseData: null,
  clinicalRosterList: null,
  thirdPartyList: null,
  thirdParty: null,
  statusChangeList: null,
  statusChangeActivityLog: null,
  otherDetails: null,
  degreeStudentList: null,
  estimatedValue: null,
  accountingInfo: null,
  accountingOtherInfo: null,
  malPracticeInsurance: null,
  malPracticeInsuranceById: null,
  alumniList: null,
  graduationSurveyDetailsList: null,
  studentGraduationSurveyList: null,
  degreeList: null,
  activityLog: null,
  getNextStageClassList: null,
  getIndividualTranscriptList: null,
  getTranscriptStudentList: null,
  getTranscriptFulltudentList: null,
  creditList: null,
  creditDetails: null,
  holdingCreditList: null,
  paymentType: null,
  invoiceDetails: null,
  docuSignForm: null,
  studentInvoicePCreditDetails: null,
  studentInvoicePList: null,
  studentInvoicePDetails: null,
  studentCancelledInvoicePList: null,
  accountingLedgerList: null,
  invoicePType: null,
  invoicePScholarship: null,
  invoicePayment: null,
  invoiceHead: null,
  getInvoiceAmount: null,
  estimateTypeList: null,
  seatDepositList: null,
  payPaymentList: null,
  estimateCourse: null,
  getEstimateById: null,
  getPaymentDetails: null,
  getEstimatePaymentDetails: null,
  payEstimateList: null,
  studentRotationControlList: null,
  noInvoiceDetails: null,
  getPaymentStatement: null,
  getOldPayments: null,
  rotationPaymentInfo: null,
  fcmDeviceToken: null,
  appealList: null,
  appealDetails: null,
  studentLoanById: {},
  studentLoanListByStudentId: null,
  studentPlanRotationList: null,
  studentPlanRotation: null,
  problemList: null,
  problemData: null,
  optionList: null,
  optionData: null,
  preceptorData: null,
  smsEmailList: null,
  smsDetails: null,
  studentNotifications: null,
  preceptorUserDetails: null,
  studentEmailView: null,
  email: null,
  selectedFilter: null,
  rcDetails: null,
  caseDetails: null,
  preceptorRotationList: null,
  preceptorRotationDetails: null,
  studentDetailsAsView: null,
  forceNotification: null,
  parentDetails: null,
  parentDetailsList: null,
  immigrationDetails: null,
  surveyResponseById: null,
  studentSmsNotificationList: null,
  studentEducationalAdmissions: null,
  studentBillingAdmissions: null,
  studentBillingList: null,
  clinicalLogPdfDetails: null,
  defermentDetails: null,
  tutorialVideoList: null,
  clinicalLogsRotationPdfDetails: null,
  studentListNotify: null,
  noInvoiceList: null,
  med6LoaDatesData: null,
  nbmeNoOfAttempts: null,
  creditDetailsCreditView: null,
  creditDetailsScholarshipView: null,
  creditDetailsNoInvoiceView: null,
  stageDropDownForCreditReport: null,
  plannedRotationHospitals: null,
  clinicalRequestPdfList: null,
  profilePicBinary: null,
  profilePicLoader: false,
  examTypeSub: null,
  docusignDocsList: null,
  creditListDeleted: null,
  invoiceDeletedDetails: null,
  allClassList: null,
  getMcatDetails: null,
  mcatList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case SELECTED_FILTER_DATA: {
    return {
      ...state,
      selectedFilter: action.payload
    };
  }
  case ADD_STUDENT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_STUDENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ADD_STUDENT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false
    };
  }
  case GET_STUDENT_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      error: false,
      loaded: true,
      studentList: action.result
    };
  }
  case GET_STUDENT_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_ADDRESS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_STUDENT_ADDRESS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentAddress: action.result && action.result.data,
    };
  }
  case GET_STUDENT_ADDRESS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case GET_STUDENT_ADMISSION_FORM_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_STUDENT_ADMISSION_FORM_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentAdmissionForm: action.result && action.result.data
    };
  }
  case GET_STUDENT_ADMISSION_FORM_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case STUDENT_PERSONAL_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_PERSONAL_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentPersonalDetails: action.result
    };
  }
  case STUDENT_PERSONAL_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }

  case STUDENT_DOCUMENTS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_DOCUMENTS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      documentList: action.result && action.result.data
    };
  }
  case STUDENT_DOCUMENTS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case STUDENT_NOTES_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_NOTES_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      notesList: action.result && action.result.data
    };
  }
  case STUDENT_NOTES_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case STUDENT_PROFILE_UPDATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_PROFILE_UPDATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case STUDENT_PROFILE_UPDATE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case STUDENT_PROFILE_HEADER_REQUESTED: {
    return {
      ...state,
      // loading: true,
      profileLoading: true,
      loaded: false,
      error: false,
    };
  }
  case STUDENT_PROFILE_HEADER_SUCCESS: {
    return {
      ...state,
      // loading: false,
      profileLoading: false,
      error: false,
      loaded: true,
      studentProfileHeader: action.result && action.result.data,
    };
  }
  case STUDENT_PROFILE_HEADER_FAILURE: {
    return {
      ...state,
      // loading: false,
      profileLoading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_APPLICANT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_STUDENT_APPLICANT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentAcademicApplicant: action.result && action.result.data
    };
  }
  case GET_STUDENT_APPLICANT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_ATTENDING_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_STUDENT_ATTENDING_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentAcademicAttending: action.result && action.result.data
    };
  }
  case GET_STUDENT_ATTENDING_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_NBME_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_STUDENT_NBME_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentAcademicNBMEList: action.result && action.result.data
    };
  }
  case GET_STUDENT_NBME_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_KAPLAN_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_STUDENT_KAPLAN_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentAcademicKaplanList: action.result && action.result.data
    };
  }
  case GET_STUDENT_KAPLAN_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_DISCIPLINE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_DISCIPLINE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      disciplineList: action.result && action.result.data
    };
  }
  case GET_DISCIPLINE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_NBME_SCORE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_NBME_SCORE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nbmeScoreData: action.result && action.result.data
    };
  }
  case GET_NBME_SCORE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }

  case STUDENT_ADD_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_ADD_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }

  case STUDENT_ADD_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_KAPLAN_SCORE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_KAPLAN_SCORE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      kaplanScoreData: action.result && action.result.data
    };
  }
  case GET_KAPLAN_SCORE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_NBME_ATTEMPTS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_NBME_ATTEMPTS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      numberOfAttempts: action.result && action.result.data
    };
  }
  case GET_NBME_ATTEMPTS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_NOTES_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_STUDENT_NOTES_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentNoteListResponse: action.result,
    };
  }
  case GET_STUDENT_NOTES_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_STUDENT_NOTES_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_STUDENT_NOTES_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getStudentNotesById: action.result,
    };
  }
  case GET_STUDENT_NOTES_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_TYPES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case STUDENT_DOCUMENT_FOLDER_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_DOCUMENT_FOLDER_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      folderType: action.result,
    };
  }
  case STUDENT_DOCUMENT_FOLDER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case STUDENT_DOCUMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STUDENT_DOCUMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      docList: action.result,
    };
  }
  case STUDENT_DOCUMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case DOCUMENT_FETCH_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case DOCUMENT_FETCH_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      docById: action.result,
    };
  }
  case DOCUMENT_FETCH_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_TYPES_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentTypeList: action.result,
    };
  }
  case GET_STUDENT_TYPES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_COURSE_STATUS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_COURSE_STATUS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentCourseStatusList: action.result,
    };
  }
  case GET_COURSE_STATUS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_DOC_FILE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_DOC_FILE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentDocuments: action.result
    };
  }
  case GET_STUDENT_DOC_FILE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_STATUS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_STATUS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentStatusList: action.result,
    };
  }
  case GET_STUDENT_STATUS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_PROFILE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_PROFILE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentProfile: action.result && action.result.data
    };
  }
  case GET_STUDENT_PROFILE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_STUDENT_COURSE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_COURSE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentCourseList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_COURSE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_COURSE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_STUDENT_COURSE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentCourse: action.result && action.result.data,
    };
  }

  case GET_STUDENT_COURSE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_COURSE_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_STUDENT_COURSE_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentCourseDropdown: action.result && action.result.data,
    };
  }

  case GET_STUDENT_COURSE_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_RESIDENCY_PROGRAM_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_RESIDENCY_PROGRAM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      residencyProgramList: action.result && action.result.data,
    };
  }
  case GET_RESIDENCY_PROGRAM_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_COURSE_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_COURSE_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentCourseDetails: action.result && action.result.data,
    };
  }
  case GET_STUDENT_COURSE_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLINICAL_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_CLINICAL_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      cinicalRotationList: action.result && action.result.data,
    };
  }

  case GET_CLINICAL_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
    };
  }

  case GET_RESIDENCY_PROGRAM_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_RESIDENCY_PROGRAM_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      residencyProgram: action.result && action.result.data,
    };
  }
  case GET_RESIDENCY_PROGRAM_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_RESIDENCY_SPECIALITY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_RESIDENCY_SPECIALITY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      residencySpecialityList: action.result && action.result.data,
    };
  }
  case GET_RESIDENCY_SPECIALITY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_RESIDENCY_PROGRAM_TYPE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_RESIDENCY_PROGRAM_TYPE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      residencyProgramType: action.result && action.result.data,
    };
  }

  case GET_RESIDENCY_PROGRAM_TYPE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_ROTATION_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_ROTATION_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationDropdown: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_LOG_PROBLEM_OPTIONS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_CLINICAL_LOG_PROBLEM_OPTIONS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalLogProblemOptions: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_LOG_PROBLEM_OPTIONS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentRotationList: action.result && action.result.data,
    };
  }
  case GET_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_PRECEPTOR_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_PRECEPTOR_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      preceptorList: action.result && action.result.data,
    };
  }
  case GET_PRECEPTOR_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case CLERICAL_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLERICAL_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clericalInfo: action.result && action.result.data,
    };
  }
  case CLERICAL_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case CLINICAL_LOG_VERIFICATION_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLINICAL_LOG_VERIFICATION_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalLogVerificationInfo: action.result && action.result.data,
    };
  }
  case CLINICAL_LOG_VERIFICATION_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLINICAL_PROCEDURES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_CLINICAL_PROCEDURES_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalProceduresDropdown: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_PROCEDURES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ROTATION_DATES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ROTATION_DATES_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationDates: action.result && action.result.data,
    };
  }
  case GET_ROTATION_DATES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case CLINICAL_LOG_ACADEMIC_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CLINICAL_LOG_ACADEMIC_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalLogAcademicList: action.result && action.result.data,
    };
  }
  case CLINICAL_LOG_ACADEMIC_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_USER_TYPE_SUCCESS: {
    return {
      ...state,
      userType: action.payload
    };
  }
  case GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      academicInfoClinicallogById: action.result && action.result.data
    };
  }
  case GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_CLINICAL_LOG_DOWNLOAD_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_CLINICAL_LOG_DOWNLOAD_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      downloadClinicalLogData: action.result && action.result.data
    };
  }
  case GET_CLINICAL_LOG_DOWNLOAD_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_ROTATION_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ROTATION_GRADETYPE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_ROTATION_GRADETYPE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationGradeType: action.result && action.result.data,
    };
  }

  case GET_ROTATION_GRADETYPE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_ROTATION_GRADE_STATUS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_ROTATION_GRADE_STATUS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationStatus: action.result && action.result.data,
    };
  }

  case GET_ROTATION_GRADE_STATUS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ROTATION_HOSPITAL_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_ROTATION_HOSPITAL_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationHospital: action.result && action.result.data,
    };
  }

  case GET_ROTATION_HOSPITAL_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_ROTATION_PRECEPTOR_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_ROTATION_PRECEPTOR_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationPreceptor: action.result && action.result.data,
    };
  }

  case GET_ROTATION_PRECEPTOR_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STAGE_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_STAGE_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      stageDropdownList: action.result && action.result.data,
    };
  }

  case GET_STAGE_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLASS_BY_STAGE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_CLASS_BY_STAGE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      classListByStage: action.result && action.result.data,
    };
  }

  case GET_CLASS_BY_STAGE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_GRADE_TYPE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_GRADE_TYPE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      gradeTypeList: action.result && action.result.data,
    };
  }

  case GET_GRADE_TYPE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_GRADE_POINT_STATUS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_GRADE_POINT_STATUS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      gradePointStatus: action.result && action.result.data,
    };
  }

  case GET_GRADE_POINT_STATUS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NONCLINICAL_CLASSES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_NONCLINICAL_CLASSES_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nonClinicalClassesList: action.result && action.result.data,
    };
  }

  case GET_NONCLINICAL_CLASSES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NONCLINICAL_STAGES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_NONCLINICAL_STAGES_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nonClinicalStagesList: action.result && action.result.data,
    };
  }

  case GET_NONCLINICAL_STAGES_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NONCLINICAL_STAGE_SEMESTER_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_NONCLINICAL_STAGE_SEMESTER_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nonClinicalStageAndSemester: action.result && action.result.data,
    };
  }

  case GET_NONCLINICAL_STAGE_SEMESTER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NONCLINICAL_ACADEMIC_STANDING_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_NONCLINICAL_ACADEMIC_STANDING_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nonClinicalAcademicStanding: action.result && action.result.data,
    };
  }
  case GET_NONCLINICAL_ACADEMIC_STANDING_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ACADEMIC_STANDING_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ACADEMIC_STANDING_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      academicStandingDropdown: action.result && action.result.data,
    };
  }
  case GET_ACADEMIC_STANDING_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NONCLINICAL_TRANSFER_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_NONCLINICAL_TRANSFER_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      transferNonClinical: action.result && action.result.data,
    };
  }
  case GET_NONCLINICAL_TRANSFER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NONCLINICAL_TRANSFER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_NONCLINICAL_TRANSFER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      transferListNonClinical: action.result && action.result.data,
    };
  }
  case GET_NONCLINICAL_TRANSFER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_CLERICAL_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_CLERICAL_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clericalListData: action.result && action.result.data,
    };
  }

  case GET_CLERICAL_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case ALUMNI_RESIDENCY_YEAR_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case ALUMNI_RESIDENCY_YEAR_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      alumniResidencyYearList: action.result,
    };
  }
  case ALUMNI_RESIDENCY_YEAR_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case ALUMNI_SPECIALTY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ALUMNI_JOB_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getAlumniJobById: action.result,
    };
  }
  case GET_ALUMNI_JOB_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ALUMNI_PERSONAL_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_ALUMNI_JOB_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_ALUMNI_JOB_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_ALUMNI_JOB_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      alumniJobListById: action.result,
    };
  }
  case GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      alumniOtherDegreeListById: action.result,
    };
  }
  case GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ALUMNI_OTHER_DEGREE_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_ALUMNI_OTHER_DEGREE_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getAlumniOtherDegreeById: action.result,
    };
  }
  case GET_ALUMNI_OTHER_DEGREE_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case ALUMNI_SPECIALTY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      alumniSpecialtyList: action.result,
    };
  }
  case ALUMNI_SPECIALTY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ALUMNI_JOB_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_ALUMNI_PERSONAL_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      alumniPersonalListById: action.result,
    };
  }
  case GET_ACADEMIC_STANDING_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_ACADEMIC_STANDING_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      academicStatndingList: action.result,
    };
  }
  case GET_ACADEMIC_STANDING_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NC_SPECIAL_STAGE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_NC_SPECIAL_STAGE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      specialStageDropdown: action.result && action.result.data,
    };
  }
  case GET_NC_SPECIAL_STAGE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NONCLINICAL_CLASS_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false

    };
  }
  case GET_NONCLINICAL_CLASS_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nonClinicalClassDetails: action.result && action.result.data,
    };
  }
  case GET_NONCLINICAL_CLASS_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_HOSPITAL_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_STUDENT_HOSPITAL_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentHospital: action.result && action.result.data,
    };
  }

  case GET_STUDENT_HOSPITAL_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLINICAL_TRANSFER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_CLINICAL_TRANSFER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalTransferList: action.result && action.result.data,
    };
  }

  case GET_CLINICAL_TRANSFER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLINICAL_TRANSFER_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_CLINICAL_TRANSFER_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalTransferData: action.result && action.result.data,
    };
  }

  case GET_CLINICAL_TRANSFER_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLINICAL_ROTATION_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_CLINICAL_ROTATION_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalRotationData: action.result && action.result.data,
    };
  }

  case GET_CLINICAL_ROTATION_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case STUDENT_USMLE_TEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case STUDENT_USMLE_TEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      usmleTestList: action.result
    };
  }
  case STUDENT_USMLE_TEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case USMLE_TEST_NO_OF_ATTEMPTS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case USMLE_TEST_NO_OF_ATTEMPTS_SUCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      usmleNoOfAttempts: action.result
    };
  }
  case USMLE_TEST_NO_OF_ATTEMPTS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_USMLETEST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_USMLETEST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getUsmleTestById: action.result
    };
  }
  case GET_USMLETEST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case STUDENT_LEAVE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case STUDENT_LEAVE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentLeaveList: action.result
    };
  }
  case STUDENT_LEAVE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_STUDENT_LEAVE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_STUDENT_LEAVE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getStudentLeave: action.result
    };
  }
  case GET_STUDENT_LEAVE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case STUDENT_LEAVE_REASON_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case STUDENT_LEAVE_REASON_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentLeaveReasonList: action.result
    };
  }
  case STUDENT_LEAVE_REASON_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }

  case GET_CLINICAL_DOC_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_CLINICAL_DOC_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentClinicalDocList: action.result
    };
  }
  case GET_CLINICAL_DOC_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_CLINICAL_DOC_TYPE_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_CLINICAL_DOC_TYPE_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentClinicalDocTypeList: action.result
    };
  }
  case GET_CLINICAL_DOC_TYPE_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_CLINICAL_DOCUMENT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_CLINICAL_DOCUMENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentClinicalDoc: action.result
    };
  }
  case GET_CLINICAL_DOCUMENT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_STAGE_STATUS_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STAGE_STATUS_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      stageStatus: action.result && action.result.data,
    };
  }
  case GET_STAGE_STATUS_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ALUMNI_MATCH_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ALUMNI_MATCH_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      alumniMatchInfo: action.result,
    };
  }
  case GET_ALUMNI_MATCH_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }

  case GET_PROGRAM_WITHDRAWAL_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_PROGRAM_WITHDRAWAL_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      programWithdrawalList: action.result && action.result.data,
    };
  }
  case GET_PROGRAM_WITHDRAWAL_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_PROGRAM_WITHDRAWAL_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_PROGRAM_WITHDRAWAL_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      programWithdrawalData: action.result && action.result.data,
    };
  }
  case GET_PROGRAM_WITHDRAWAL_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_TRANSFER_CLASS_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_TRANSFER_CLASS_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      transferClassDropdown: action.result && action.result.data,
    };
  }
  case GET_TRANSFER_CLASS_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_REPLACEMENT_DIPLOMA_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_REPLACEMENT_DIPLOMA_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      replacementDiplomaList: action.result && action.result.data
    };
  }
  case GET_REPLACEMENT_DIPLOMA_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_SURVEY_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_SURVEY_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      surveyDetailsList: action.result && action.result.data
    };
  }
  case GET_SURVEY_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_REPLACEMENT_DIPLOMA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_REPLACEMENT_DIPLOMA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      replacementDiploma: action.result && action.result.data
    };
  }
  case GET_REPLACEMENT_DIPLOMA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      classWithdrawalList: action.result && action.result.data,
    };
  }
  case GET_CLASS_WITHDRAWAL_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ENROLLMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ENROLLMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      enrollmentList: action.result && action.result.data,
    };
  }
  case GET_ENROLLMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_FINANCE_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_FINANCE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      financeList: action.result && action.result.data,
    };
  }
  case GET_FINANCE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_GRADUATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_GRADUATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      graduationList: action.result && action.result.data,
    };
  }
  case GET_GRADUATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ABSENCE_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ABSENCE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      absenceList: action.result && action.result.data,
    };
  }

  case GET_ABSENCE_LIST_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      absenceListData: action.result && action.result.data,
    };
  }

  case GET_ABSENCE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_COMPLAINT_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_COMPLAINT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      complaintList: action.result && action.result.data,
    };
  }
  case GET_COMPLAINT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_GRADUATION_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_GRADUATION_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      graduationData: action.result && action.result.data,
    };
  }
  case GET_GRADUATION_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ABSENCE_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ABSENCE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      absenceData: action.result && action.result.data,
    };
  }
  case GET_ABSENCE_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_QUERY_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_QUERY_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      queryData: action.result && action.result.data,
    };
  }
  case GET_QUERY_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_FINANCE_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_FINANCE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      financeData: action.result && action.result.data,
    };
  }
  case GET_FINANCE_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_COMPLAINT_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_COMPLAINT_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      complaintData: action.result && action.result.data,
    };
  }
  case GET_COMPLAINT_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_MSPE_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_MSPE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      mspeData: action.result && action.result.data,
    };
  }
  case GET_MSPE_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ENROLLMENT_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ENROLLMENT_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      enrollmentData: action.result && action.result.data,
    };
  }
  case GET_ENROLLMENT_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_CLASS_WITHDRAWAL_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_CLASS_WITHDRAWAL_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      classWithdrawalData: action.result && action.result.data,
    };
  }
  case GET_CLASS_WITHDRAWAL_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_EVALUATION_FORM_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_EVALUATION_FORM_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      evaluationFormData: action.result && action.result.data
    };
  }
  case GET_EVALUATION_FORM_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_REPLACEMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      error: true,
      loaded: false
    };
  }
  case GET_REPLACEMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      replacementList: action.result && action.result.data,
    };
  }
  case GET_REPLACEMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_REPLACEMENT_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_REPLACEMENT_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      replacementData: action.result && action.result.data,
    };
  }
  case GET_REPLACEMENT_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_NUMBER_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_NUMBER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      numbers: action.result && action.result.data,
    };
  }
  case GET_NUMBER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_MSPE_LIST_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_MSPE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      mspeList: action.result && action.result.data,
    };
  }
  case GET_MSPE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }

  case GET_NUMBER_DATA_REQUESTED: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_NUMBER_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      numberList: action.result && action.result.data,
    };
  }
  case GET_NUMBER_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_LEAVE_ABSENCE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      error: true,
      loaded: false
    };
  }
  case GET_LEAVE_ABSENCE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      leaveAbsenceList: action.result && action.result.data,
    };
  }

  case GET_ROTATION_DROPDOWN_WEEKS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_ROTATION_DROPDOWN_WEEKS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationDropdownWeeks: action.result && action.result.data,
    };
  }

  case GET_ROTATION_DROPDOWN_WEEKS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_LEAVE_ABSENCE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_LEAVE_ABSENCE_REQUESTED: {
    return {
      ...state,
      loading: true,
      error: true,
      loaded: false
    };
  }
  case GET_LEAVE_ABSENCE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      leaveAbsence: action.result && action.result.data,
    };
  }
  case GET_LEAVE_ABSENCE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_I94_REQUEST_FORM_LIST_STUDENT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_I94_REQUEST_FORM_LIST_STUDENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      i94FormListStudent: action.result && action.result.data,
    };
  }
  case GET_I94_REQUEST_FORM_LIST_STUDENT_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_I94_REQUEST_FORM_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_I94_REQUEST_FORM_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      i94Form: action.result && action.result.data,
    };
  }
  case GET_I94_REQUEST_FORM_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_GENERAL_QUERY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_GENERAL_QUERY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      generalQueryList: action.result && action.result.data,
    };
  }
  case GET_GENERAL_QUERY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_GENERAL_QUERY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_GENERAL_QUERY_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      generalQuery: action.result && action.result.data,
    };
  }
  case GET_GENERAL_QUERY_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_I94_LETTER_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_I94_LETTER_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      i94LetterById: action.result && action.result.data,
    };
  }
  case GET_I94_LETTER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: false
    };
  }
  case GET_ENROLLMENT_REQUEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_ENROLLMENT_REQUEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      enrollmentRequestList: action.result
    };
  }
  case GET_ENROLLMENT_REQUEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_ENROLLMENT_REQUEST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_ENROLLMENT_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      enrollmentRequestById: action.result
    };
  }
  case GET_ENROLLMENT_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_GRADUATION_REQUEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_GRADUATION_REQUEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      graduationrequestList: action.result
    };
  }
  case GET_GRADUATION_REQUEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_GRADUATION_REQUEST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_GRADUATION_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      graduationrequestById: action.result
    };
  }
  case GET_GRADUATION_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_PRE_MED_LOA_REQUEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_PRE_MED_LOA_REQUEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      preMedLoaRequestList: action.result
    };
  }
  case GET_PRE_MED_LOA_REQUEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_PRE_MED_LOA_REQUEST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_PRE_MED_LOA_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      preMedLoaRequestById: action.result
    };
  }
  case GET_PRE_MED_LOA_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_I94_LETTER_REQUEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_I94_LETTER_REQUEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      i94LetterRequestList: action.result
    };
  }
  case GET_I94_LETTER_REQUEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_CLINICAL_REQUEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_CLINICAL_REQUEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalRequestList: action.result
    };
  }
  case GET_CLINICAL_REQUEST_PDF_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      error: false,
      loaded: true,
      clinicalRequestPdfList: action.result
    };
  }
  case GET_CLINICAL_REQUEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_CLINICAL_REQUEST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_CLINICAL_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalRequestById: action.result
    };
  }
  case GET_CLINICAL_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }

  case GET_COMPLAINT_REQUEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_COMPLAINT_REQUEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      complaintRequestList: action.result.data
    };
  }

  case GET_COMPLAINT_REQUEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_COMPLAINT_REQUEST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_COMPLAINT_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      complaintRequest: action.result.data
    };
  }

  case GET_COMPLAINT_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CLINICAL_REQUEST_BASIC_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_CLINICAL_REQUEST_BASIC_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      clinicalBasicInfoRequest: action.result
    };
  }

  case GET_CLINICAL_REQUEST_BASIC_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_STUDENT_COURSES_STATUS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_STUDENT_COURSES_STATUS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      studentCourseStatus: action.result.data
    };
  }

  case GET_STUDENT_COURSES_STATUS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }

  case GET_STUDENT_REQUEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_STUDENT_REQUEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentRequestList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_REQUEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_CLINICAL_ROSTER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_CLINICAL_ROSTER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      clinicalRosterList: action.result && action.result.data,
    };
  }
  case GET_CLINICAL_ROSTER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_THIRD_PARTY_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_THIRD_PARTY_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      thirdPartyList: action.result && action.result.data,
    };
  }
  case GET_THIRD_PARTY_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_THIRD_PARTY_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_THIRD_PARTY_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      thirdParty: action.result && action.result.data,
    };
  }
  case GET_THIRD_PARTY_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_STATUS_CHANAGE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }

  case GET_STATUS_CHANAGE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      statusChangeList: action.result && action.result.data,
    };
  }

  case GET_STATUS_CHANAGE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }

  case GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      statusChangeActivityLog: action.result && action.result.data,
    };
  }

  case GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_APPLICANT_COURSE_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_APPLICANT_COURSE_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      applicantCourseDropdownList: action.result && action.result.data,
    };
  }
  case GET_APPLICANT_COURSE_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_CLINICAL_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      applicantMedicalCourseDropdownList: action.result && action.result.data,
    };
  }
  case GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_APPLICANT_ROTATION_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_APPLICANT_ROTATION_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      applicantRotationDropdownList: action.result && action.result.data,
    };
  }
  case GET_APPLICANT_ROTATION_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      standardizedTestList: action.result && action.result.data,
    };
  }
  case GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ACADEMIC_RECORDS1_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_ACADEMIC_RECORDS1_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      academicRecords1List: action.result && action.result.data,
    };
  }
  case GET_ACADEMIC_RECORDS1_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ACADEMIC_RECORDS2_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_ACADEMIC_RECORDS2_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      academicRecords2List: action.result && action.result.data,
    };
  }
  case GET_ACADEMIC_RECORDS2_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ACADEMIC_RECORDS3_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_ACADEMIC_RECORDS3_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      academicRecords3List: action.result && action.result.data,
    };
  }
  case GET_ACADEMIC_RECORDS3_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ACADEMIC_RECORDS4_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_ACADEMIC_RECORDS4_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      academicRecords4List: action.result && action.result.data,
    };
  }
  case GET_ACADEMIC_RECORDS4_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_TRANSCRIPT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_STUDENT_TRANSCRIPT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentTranscriptList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_TRANSCRIPT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_TRANSCRIPT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_STUDENT_TRANSCRIPT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentTranscriptDetails: action.result && action.result.data,
    };
  }
  case GET_STUDENT_TRANSCRIPT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_REASON_REQUEST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_STUDENT_REASON_REQUEST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentRequestReasonList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_REASON_REQUEST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_EXAM_NAME_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_EXAM_NAME_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      examNameDropDown: action.result && action.result.data,
    };
  }
  case GET_EXAM_NAME_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_FEE_NAME_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_FEE_NAME_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      feeNameDropDown: action.result && action.result.data,
    };
  }
  case GET_FEE_NAME_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_SHIPPING_OPTIONS_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_SHIPPING_OPTIONS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      shippingOptions: action.result && action.result.data,
    };
  }
  case GET_SHIPPING_OPTIONS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_COURSE_TRANSCRIPT_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_COURSE_TRANSCRIPT_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      transcriptCourseData: action.result && action.result.data,
    };
  }
  case GET_COURSE_TRANSCRIPT_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_GRADUATION_SURVEY_FORM_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_GRADUATION_SURVEY_FORM_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      graduationSurveyDetailsList: action.result && action.result.data
    };
  }
  case GET_GRADUATION_SURVEY_FORM_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_GRADUATION_SURVEY_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
    };
  }
  case GET_GRADUATION_SURVEY_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      studentGraduationSurveyList: action.result && action.result.data
    };
  }
  case GET_GRADUATION_SURVEY_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_CLINICAL_MEDICINE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_CLINICAL_MEDICINE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalMedicineList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_CLINICAL_MEDICINE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_CLINICAL_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalRotationList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_CLINICAL_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_STUDENT_GPA_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_GPA_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      gpaList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_GPA_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_MED_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_MED_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      medClassList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_MED_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  // case GET_STUDENT_PAYMENT_ONE_LIST_REQUESTED: {
  //   return {
  //     ...state,
  //     loading: true,
  //     loaded: false,
  //     error: false,
  //   }
  // }
  // case GET_STUDENT_PAYMENT_ONE_LIST_SUCCESS: {
  //   return {
  //     ...state,
  //     loading: false,
  //     loaded: true,
  //     error: false,
  //     paymentOneList: action.result && action.result.data,
  //   }
  // }
  // case GET_STUDENT_PAYMENT_ONE_LIST_FAILURE: {
  //   return {
  //     ...state,
  //     loading: false,
  //     loaded: true,
  //     error: true
  //   }
  // }
  // case GET_STUDENT_PAYMENT_TWO_LIST_REQUESTED: {
  //   return {
  //     ...state,
  //     loading: true,
  //     loaded: false,
  //     error: false,
  //   }
  // }
  // case GET_STUDENT_PAYMENT_TWO_LIST_SUCCESS: {
  //   return {
  //     ...state,
  //     loading: false,
  //     loaded: true,
  //     error: false,
  //     paymentTwoList: action.result && action.result.data,
  //   }
  // }
  // case GET_STUDENT_PAYMENT_TWO_LIST_FAILURE: {
  //   return {
  //     ...state,
  //     loading: false,
  //     loaded: true,
  //     error: true
  //   }
  // }
  case GET_STUDENT_PREMED_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_PREMED_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      preMedList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_PREMED_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_OTHER_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_OTHER_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      otherDetails: action.result && action.result.data,
    };
  }
  case GET_OTHER_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_DEGREE_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }

  case GET_DEGREE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      degreeStudentList: action.result && action.result.data,
    };
  }
  case GET_DEGREE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_ESTIMATED_VALUE_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }

  case GET_ESTIMATED_VALUE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      estimatedValue: action.result && action.result.data,
    };
  }

  case GET_ESTIMATED_VALUE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_MALPRACTICE_INSURANCE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MALPRACTICE_INSURANCE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      malPracticeInsurance: action.result && action.result.data,
    };
  }
  case GET_ACCOUNTING_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ACCOUNTING_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      accountingInfo: action.result && action.result.data,
    };
  }
  case GET_ACCOUNTING_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ACCOUNTING_OTHER_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ACCOUNTING_OTHER_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      accountingOtherInfo: action.result && action.result.data,
    };
  }
  case GET_ACCOUNTING_OTHER_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_MALPRACTICE_INSURANCE_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MALPRACTICE_INSURANCE_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      malPracticeInsuranceById: action.result && action.result.data,
    };
  }
  case GET_MALPRACTICE_INSURANCE_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case STUDENT_ACTIVITY_LOG_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false,
      error: false,
    };
  }
  case STUDENT_ACTIVITY_LOG_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      activityLog: action.result
    };
  }
  case STUDENT_ACTIVITY_LOG_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true
    };
  }

  case GET_ALUMINI_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
    };
  }
  case GET_ALUMINI_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      alumniList: action.result && action.result.data,
    };
  }
  case ADD_ALUMNI_EMAIL_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case ADD_ALUMNI_EMAIL_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case ADD_ALUMNI_EMAIL_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      alumniEmail: action.result && action.result.data,
    };
  }
  case GET_ALUMINI_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true
    };
  }
  case GET_DEGREE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_DEGREE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      degreeList: action.result && action.result.data,
    };
  }
  case GET_DEGREE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_NEXT_STAGE_CLASSES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_NEXT_STAGE_CLASSES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getNextStageClassList: action.result && action.result.data,
    };
  }
  case GET_NEXT_STAGE_CLASSES_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case REFUND_TYPE_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case REFUND_TYPE_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      refundTypeOptions: action.result && action.result.data,
    };
  }
  case REFUND_TYPE_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case CREDIT_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
    };
  }
  case CREDIT_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      creditList: action.result && action.result.data,
    };
  }
  case CREDIT_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true
    };
  }
  case CREDIT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case CREDIT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      creditDetails: action.result && action.result.data,
    };
  }
  case CREDIT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case HOLDING_CREDIT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case HOLDING_CREDIT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      holdingCreditList: action.result && action.result.data,
    };
  }
  case HOLDING_CREDIT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case PAYMENT_TYPE_DROPDOWN_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case PAYMENT_TYPE_DROPDOWN_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      paymentType: action.result && action.result.data,
    };
  }
  case PAYMENT_TYPE_DROPDOWN_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case INVOICE_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case INVOICE_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      invoiceDetails: action.result && action.result.data,
    };
  }
  case INVOICE_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case NO_INVOICE_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case NO_INVOICE_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      noInvoiceDetails: action.result && action.result.data,
    };
  }
  case NO_INVOICE_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case DOCUSIGN_FORM_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case DOCUSIGN_FORM_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      // docuSignForm : action.result && action.result.data,
    };
  }
  case DOCUSIGN_FORM_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case TRANSCRIPT_LIST_STUDENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getTranscriptStudentList: action.result && action.result.data
    };
  }
  case GET_TRANSCRIPT_FULL_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getTranscriptFulltudentList: action.result && action.result.data
    };
  }
  case GET_INVOICEP_CREDIT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_CREDIT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentInvoicePCreditDetails: action.result,
    };
  }
  case GET_INVOICEP_CREDIT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentInvoicePList: action.result,
    };
  }
  case GET_INVOICEP_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentInvoicePDetails: action.result,
    };
  }
  case GET_INVOICEP_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_CANCELLED_INVOICEP_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_CANCELLED_INVOICEP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentCancelledInvoicePList: action.result,
    };
  }
  case GET_CANCELLED_INVOICEP_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ACCOUNT_LEDGER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ACCOUNT_LEDGER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      accountingLedgerList: action.result,
    };
  }
  case GET_ACCOUNT_LEDGER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_TYPE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_TYPE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      invoicePType: action.result,
    };
  }
  case GET_INVOICEP_TYPE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_SCHOLARSHIP_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_SCHOLARSHIP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      invoicePScholarship: action.result,
    };
  }
  case GET_INVOICEP_SCHOLARSHIP_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_PAYMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_PAYMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      invoicePayment: action.result,
    };
  }
  case GET_INVOICEP_PAYMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_HEAD_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_HEAD_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      invoiceHead: action.result,
    };
  }
  case GET_INVOICEP_HEAD_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_AMOUNT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_AMOUNT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getInvoiceAmount: action.result,
    };
  }
  case GET_INVOICEP_AMOUNT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_ESTIMATE_TYPE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_ESTIMATE_TYPE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      estimateTypeList: action.result,
    };
  }
  case GET_INVOICEP_ESTIMATE_TYPE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_ESTIMATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_ESTIMATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      seatDepositList: action.result,
    };
  }
  case GET_INVOICEP_ESTIMATE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_PAY_PAYMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_PAY_PAYMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      payPaymentList: action.result,
    };
  }
  case GET_INVOICEP_PAY_PAYMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ESTIMATE_COURSE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ESTIMATE_COURSE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      estimateCourse: action.result,
    };
  }
  case GET_ESTIMATE_COURSE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ESTIMATE_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ESTIMATE_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getEstimateById: action.result,
    };
  }
  case GET_ESTIMATE_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_INVOICEP_PAYMENT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INVOICEP_PAYMENT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getPaymentDetails: action.result,
    };
  }
  case GET_INVOICEP_PAYMENT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_ESTIMATE_PAYMENT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ESTIMATE_PAYMENT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getEstimatePaymentDetails: action.result,
    };
  }
  case GET_ESTIMATE_PAYMENT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PAY_ESTIMATE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_PAY_ESTIMATE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      payEstimateList: action.result,
    };
  }
  case GET_PAY_ESTIMATE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_STUDENT_ROTATION_CONTRO_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_STUDENT_ROTATION_CONTRO_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentRotationControlList: action.result && action.result.data,
    };
  }
  case GET_STUDENT_ROTATION_CONTRO_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PAYMENT_STATEMENT_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_PAYMENT_STATEMENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getPaymentStatement: action.result,
    };
  }
  case GET_PAYMENT_STATEMENT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_OLD_PAYMENTS_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_OLD_PAYMENTS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getOldPayments: action.result,
    };
  }
  case GET_OLD_PAYMENTS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }

  case GET_ROTATION_PAYMENTS_INFO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_ROTATION_PAYMENTS_INFO_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      rotationPaymentInfo: action.result && action.result.data
    };
  }
  case GET_ROTATION_PAYMENTS_INFO_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }

  case GET_FCM_DEVICE_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_FCM_DEVICE_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      fcmDeviceToken: action.result && action.result.data
    };
  }
  case GET_FCM_DEVICE_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }

  case GET_APPEAL_LIST_DETAILS_REQUESTED: {
    return {
      ...state,
      appealLoading: true,
      loaded: false
    };
  }
  case GET_APPEAL_LIST_DETAILS_SUCCESS: {
    return {
      ...state,
      appealLoading: false,
      error: false,
      loaded: true,
      appealList: action.result && action.result.data
    };
  }
  case GET_APPEAL_LIST_DETAILS_FAILURE: {
    return {
      ...state,
      appealLoading: false,
      error: false,
      loaded: true
    };
  }

  case GET_APPEAL_DETAILS_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_APPEAL_DETAILS_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      appealDetails: action.result && action.result.data
    };
  }
  case GET_APPEAL_DETAILS_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }

  case GET_STUDENT_LOAN_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentLoanById: action.result && action.result.data
    };
  }
  case GET_STUDENT_LOAN_LIST_BY_SID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentLoanListByStudentId: action.result && action.result.data
    };
  }
  case GET_STUDENT_PLAN_ROTATION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_STUDENT_PLAN_ROTATION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentPlanRotationList: action.result && action.result.data
    };
  }
  case GET_STUDENT_PLAN_ROTATION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_PLAN_ROTATION_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_STUDENT_PLAN_ROTATION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentPlanRotation: action.result && action.result.data
    };
  }
  case GET_STUDENT_PLAN_ROTATION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PROBLEM_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_PROBLEM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      problemList: action.result && action.result.data
    };
  }
  case GET_PROBLEM_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PROBLEM_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      problemData: action.result && action.result.data
    };
  }
  case GET_STUDENT_NOTIFICATIONS_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentNotifications: action.result && action.result.data,
    };
  }

  case GET_STUDENT_SMS_EMAIL_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      smsEmailList: action.result
    };
  }

  case GET_STUDENT_SMS_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      smsDetails: action.result
    };
  }

  case GET_OPTION_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_OPTION_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      optionList: action.result && action.result.data
    };
  }
  case GET_OPTION_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_PRECEPTOR_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_PRECEPTOR_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      preceptorData: action.result && action.result.data
    };
  }
  case GET_PRECEPTOR_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_OPTION_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      optionData: action.result && action.result.data
    };
  }
  case GET_STUDENT_RC_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      rcDetails: action.result
    };
  }
  case GET_CASE_REPORT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      caseDetails: action.result && action.result.data
    };
  }
  case GET_PRECEPTOR_ROTATION_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      preceptorRotationList: action.result
    };
  }
  case GET_PRECEPTOR_ROTATION_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      preceptorRotationDetails: action.result
    };
  }
  case GET_PRECEPTOR_USER_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      preceptorUserDetails: action.result
    };
  }
  case STUDENT_EMAIL_VIEW_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case STUDENT_EMAIL_VIEW_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentEmailView: action.result
    };
  }
  case STUDENT_EMAIL_VIEW_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_STUDENT_EMAIL_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      email: action.result
    };
  }
  case GET_FORCE_NOTIFICATION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      forceNotification: action.result
    };
  }

  case GET_STUDENT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      studentDetailsAsView: action.result && action.result.data
    };
  }

  case GET_PARENT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      parentDetails: action.result && action.result.data
    };
  }
  case GET_PARENT_DETAILS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      parentDetailsList: action.result && action.result.data
    };
  }
  case UPDATE_CREATED_ON_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case UPDATE_INVOICE_DATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case UPDATE_INVOICE_DATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case UPDATE_INVOICE_DATE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_SURVEY_RESPONSE_DATA_BY_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      surveyResponseById: action.result?.data
    };
  }

  case GET_IMMIGRATION_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      immigrationDetails: action.result?.data,
      error: false,
    };
  }
  case GET_STUDENT_SMS_NOTIFICATION: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      studentSmsNotificationList: action.result && action.result.data
    };
  }
  case POST_STUDENT_SMS_NOTIFICATION: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
    };
  }
  case GET_STUDENT_EDUCATIONAL_ADMISSION_INFO: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentEducationalAdmissions: action.result && action.result.data
    };
  }
  case GET_STUDENT_BILLING_ADMISSION_INFO: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentBillingAdmissions: action.result && action.result.data
    };
  }
  case GET_STUDENT_BILLING_ADMISSION_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentBillingList: action.result && action.result.data
    };
  }
  case GET_CLINICAL_LOGS_INFO: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalLogPdfDetails: action.result && action.result.data
    };
  }
  case GET_SEMESTER_DEFERMENT_DATA: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      defermentDetails: action.result && action.result.data
    };
  }
  case GET_TUTORIAL_VIDEO_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      tutorialVideoList: action.result && action.result.data
    };
  }
  case GET_CLINICALS_PDF_LOGS_INFO: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      clinicalLogsRotationPdfDetails: action.result && action.result.data
    };
  }
  case GET_STUDENT_NOTIFYLIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      error: true,
      loaded: true
    };
  }
  case GET_STUDENT_NOTIFYLIST_REQUEST: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false,
      error: false
    };
  }
  case GET_STUDENT_NOTIFYLIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      studentListNotify: action.result && action.result.data
    };
  }
  case GET_NO_INVOICE_PAYMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_NO_INVOICE_PAYMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      noInvoiceList: action.result
    };
  }
  case GET_NO_INVOICE_PAYMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_STUDENT_MED6_LOA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      med6LoaDatesData: action.result?.data
    };
  }
  case GET_NBME_NO_OF_ATTEMPTS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      nbmeNoOfAttempts: action.result && action.result.data
    };
  }
  case GET_CREDIT_DETAILS_CREDIT_VIEW: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      creditDetailsCreditView: action?.result?.data
    };
  }
  case GET_CREDIT_DETAILS_SCHOLARSHIP_VIEW: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      creditDetailsScholarshipView: action?.result?.data
    };
  }
  case GET_CREDIT_DETAILS_NO_INVOICE_VIEW: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      creditDetailsNoInvoiceView: action?.result?.data
    };
  }
  case UPDATE_CREDIT_DETAILS_INVOICE_REFERENCE_NUMBER: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_STAGE_DROPDOWN_FOR_CREDIT_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      stageDropDownForCreditReport: action.result && action.result.data,
    };
  }
  case GET_PLANED_ROTATIONS_HOSPITALS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      plannedRotationHospitals: action.result && action.result.data,
    };
  }
  case GET_STUDENT_PROFILE_PIC_REQUESTED: {
    return {
      ...state,
      profilePicLoader: true,
      loaded: false,
      error: false
    };
  }
  case GET_STUDENT_PROFILE_PIC_BINARY_SUCCESS: {
    return {
      ...state,
      profilePicLoader: false,
      loaded: true,
      error: false,
      profilePicBinary: action.result && action.result.data,
    };
  }
  case GET_STUDENT_PROFILE_PIC_BINARY_FAILURE: {
    return {
      ...state,
      profilePicLoader: false,
      loaded: true,
      error: true,
    };
  }
  case GET_EXAM_TYPE_SUB_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      examTypeSub: action.result && action.result.data,
    };
  }
  case GET_EXAM_TYPE_SUB_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      examtypeSub: null
    };
  }

  case DOCUSIGN_DOCS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case DOCUSIGN_DOCS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      docusignDocsList: action.result && action.result.data,
    };
  }

  case DOCUSIGN_DOCS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true,
      docusignDocsList: null,
    };
  }
  case CREDIT_LIST_DELETED_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
    };
  }
  case CREDIT_LIST_DELETED_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      creditListDeleted: action.result && action.result.data,
    };
  }
  case CREDIT_LIST_DELETED_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true,
      creditListDeleted: null
    };
  }
  case CREDIT_LIST_DETAILS_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
    };
  }
  case CREDIT_LIST_DETAILS_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      invoiceDeletedDetails: action.result && action.result.data,
    };
  }
  case CREDIT_LIST_DETAILS_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true,
      invoiceDeletedDetails: null
    };
  }
  case GET_ALL_CLASS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case GET_ALL_CLASS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      allClassList: action.result && action.result.data,
    };
  }
  case GET_ALL_CLASS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      allClassList: null
    };
  }
  case MCAT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case MCAT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      mcatList: action.result && action.result.data,
    };
  }
  case MCAT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      mcatList: null
    };
  }
  case MCAT_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
    };
  }
  case MCAT_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      getMcatDetails: action.result && action.result.data,
    };
  }
  case MCAT_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      getMcatDetails: null
    };
  }

  default:
    return state;
  }
};

export const addStudent = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('StudentAdd', { data, isMultiPart: true })
  };
};

export const getStudentList = (params, data) => {
  return {
    types: [GET_STUDENT_LIST_REQUESTED, GET_STUDENT_LIST_SUCCESS, GET_STUDENT_LIST_FAILURE],
    promise: (client) => client.post('studentList', { params, data })
  };
};

export const getStudentAddress = (data) => {
  return {
    types: [GET_STUDENT_ADDRESS_REQUESTED, GET_STUDENT_ADDRESS_SUCCESS, GET_STUDENT_ADDRESS_FAILURE],
    promise: (client) => client.get(`studentGetAddress/${data}`)
  };
};

export const getStudentProfile = (data) => {
  return {
    types: [GET_STUDENT_PROFILE_REQUESTED, GET_STUDENT_PROFILE_SUCCESS, GET_STUDENT_PROFILE_FAILURE],
    promise: (client) => client.get(`studentProfile/{id}?id=${data}`)
  };
};

export const getStudentAdmissionForm = (data) => {
  return {
    types: [GET_STUDENT_ADMISSION_FORM_REQUESTED, GET_STUDENT_ADMISSION_FORM_SUCCESS, GET_STUDENT_ADMISSION_FORM_FAILURE],
    promise: (client) => client.get(`studentAdmissionForms/${data}`)
  };
};

export const updateStudentProfile = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('StudentUpdateProfile', { data })
  };
};

export const updateStudentAddress = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('StudentUpdateAddress', { data })
  };
};

export const addAdmissionContactInfo = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionContact', { data })
  };
};

export const addAdmissionDisclosure = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionDisclosure', { data })
  };
};

export const addAdmissionLicensure = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionLicensure', { data })
  };
};

export const addAdmissionMemorandum = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionMemorandum', { data })
  };
};

export const addAdmissionPaySeat = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionPay', { data })
  };
};

export const addAdmissionStatement = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionStatement', { data })
  };
};

export const addAdmissionTransferCredit = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionTransferCredit', { data })
  };
};

export const addAdmissionWhereYouHeard = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAdmissionWhereYouHeard', { params })
  };
};

export const studentFormVerify = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentFormVerify', { params })
  };
};

export const studentFormSendCode = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentFormSendCode', { params })
  };
};

export const getStudentPersonalDetails = (sid) => {
  return {
    types: [STUDENT_PERSONAL_DETAILS_REQUESTED, STUDENT_PERSONAL_DETAILS_SUCCESS, STUDENT_PERSONAL_DETAILS_FAILURE],
    promise: (client) => client.get(`studentPersonal/${sid}`)
  };
};

export const getStudentDocumentList = (id, active) => {
  return {
    types: [STUDENT_DOCUMENTS_LIST_REQUESTED, STUDENT_DOCUMENTS_LIST_SUCCESS, STUDENT_DOCUMENTS_LIST_FAILURE],
    promise: (client) => client.get(`getDocumentList/${id}/${active}`)
  };
};

export const getNotesList = (data) => {
  return {
    types: [STUDENT_NOTES_LIST_REQUESTED, STUDENT_NOTES_LIST_SUCCESS, STUDENT_NOTES_LIST_FAILURE],
    promise: (client) => client.get('getNotesList/', { params: data })
  };
};

export const updateProfile = (data) => {
  return {
    types: [STUDENT_PROFILE_UPDATE_REQUESTED, STUDENT_PROFILE_UPDATE_SUCCESS, STUDENT_PROFILE_UPDATE_FAILURE],
    promise: (client) => client.post('updateStudentProfile', { data, isMultiPart: true })

  };
};

export const getStudentProfileHeader = (id) => {
  return {
    types: [STUDENT_PROFILE_HEADER_REQUESTED, STUDENT_PROFILE_HEADER_SUCCESS, STUDENT_PROFILE_HEADER_FAILURE],
    promise: (client) => client.get(`studentProfileHeader/${id}`)

  };
};

export const studentAdd = (data) => {
  return {
    types: [STUDENT_ADD_REQUESTED, STUDENT_ADD_SUCCESS, STUDENT_ADD_FAILURE],
    promise: (client) => client.post('studentAdd', { data, isMultiPart: true })

  };
};

export const getStudentAcademicApplicant = (data) => {
  return {
    types: [GET_STUDENT_APPLICANT_REQUESTED, GET_STUDENT_APPLICANT_SUCCESS, GET_STUDENT_APPLICANT_FAILURE],
    promise: (client) => client.get(`getStudentAcademicApplicant/${data}`)
  };
};

export const updateStudentAcademicApplicant = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateStudentAcademicApplicant', { data })
  };
};

export const delStudentAcademicApplicant = (sid) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`delStudentAcademicApplicant/${sid}`)
  };
};

export const getStudentAcademicAttending = (data) => {
  return {
    types: [GET_STUDENT_ATTENDING_REQUESTED, GET_STUDENT_ATTENDING_SUCCESS, GET_STUDENT_ATTENDING_FAILURE],
    promise: (client) => client.get(`studentAcademicAttending/${data}`)
  };
};

export const updateStudentAcademicAttending = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateStudentAcademicAttending', { data })
  };
};

export const delStudentAcademicAttending = (sid) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`studentAcademicAttending/${sid}`)
  };
};

export const getStudentAcademicNBMEList = (data) => {
  return {
    types: [GET_STUDENT_NBME_LIST_REQUESTED, GET_STUDENT_NBME_LIST_SUCCESS, GET_STUDENT_NBME_LIST_FAILURE],
    promise: (client) => client.get(`studentAcademicNBMEList/${data}`)
  };
};

export const getStudentAcademicKaplanList = (data) => {
  return {
    types: [GET_STUDENT_KAPLAN_LIST_REQUESTED, GET_STUDENT_KAPLAN_LIST_SUCCESS, GET_STUDENT_KAPLAN_LIST_FAILURE],
    promise: (client) => client.get(`studentAcademicKaplanList/${data}`)
  };
};

export const getDisciplineList = (data) => {
  return {
    types: [GET_DISCIPLINE_LIST_REQUESTED, GET_DISCIPLINE_LIST_SUCCESS, GET_DISCIPLINE_LIST_FAILURE],
    promise: (client) => client.get(`disciplineList/${data}`)
  };
};

export const addStudentNbmeScore = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addStudentNbmeScore', { data, isMultiPart: true })
  };
};

export const addStudentKaplanScore = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addStudentKaplanScore', { data })
  };
};

export const getNbmeScoreDetails = (data) => {
  return {
    types: [GET_NBME_SCORE_REQUESTED, GET_NBME_SCORE_SUCCESS, GET_NBME_SCORE_FAILURE],
    promise: (client) => client.get(`getNbmeScoreDetails/${data.id}/${data.type}`)
  };
};

export const getKaplanScoreDetails = (data) => {
  return {
    types: [GET_KAPLAN_SCORE_REQUESTED, GET_KAPLAN_SCORE_SUCCESS, GET_KAPLAN_SCORE_FAILURE],
    promise: (client) => client.get(`getKaplanScoreDetails/${data}`)
  };
};

export const getAttemptsNumber = (sid, disciplineId) => {
  return {
    types: [GET_NBME_ATTEMPTS_REQUESTED, GET_NBME_ATTEMPTS_SUCCESS, GET_NBME_ATTEMPTS_FAILURE],
    promise: (client) => client.get(`nbmeAttemptsNumber/${sid}/${disciplineId}`)
  };
};
export const studentAddOrUpdateAddress = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentAddOrUpdateAddress', { data })
  };
};

export const studentNoteAdd = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentNote', { data })
  };
};

export const getStudentNotesList = (params) => {
  return {
    types: [GET_STUDENT_NOTES_LIST_REQUESTED, GET_STUDENT_NOTES_LIST_SUCCESS, GET_STUDENT_NOTES_LIST_FAILURE],
    promise: (client) => client.get('studentNotesList/', { params })
  };
};
export const getStudentNotesById = (noteId) => {
  return {
    types: [GET_STUDENT_NOTES_BY_ID_REQUESTED, GET_STUDENT_NOTES_BY_ID_SUCCESS, GET_STUDENT_NOTES_BY_ID_FAILURE],
    promise: (client) => client.get(`studentNoteDeleteorGet/${noteId}`)
  };
};

export const studentNoteDelete = (id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`studentNoteDeleteorGet/${id}`)
  };
};

export const FolderTypeList = () => {
  return {
    types: [STUDENT_DOCUMENT_FOLDER_REQUESTED, STUDENT_DOCUMENT_FOLDER_SUCCESS, STUDENT_DOCUMENT_FOLDER_FAILURE],
    promise: (client) => client.get('documentFolderTypeList')
  };
};

export const documentList = (data) => {
  return {
    types: [STUDENT_DOCUMENT_LIST_REQUESTED, STUDENT_DOCUMENT_LIST_SUCCESS, STUDENT_DOCUMENT_LIST_FAILURE],
    promise: (client) => client.get(`studentDocumentList/${data.studentId}/${data.inActive}`)
  };
};

export const documentbyId = (id) => {
  return {
    types: [DOCUMENT_FETCH_REQUESTED, DOCUMENT_FETCH_SUCCESS, DOCUMENT_FETCH_FAILURE],
    promise: (client) => client.get(`studentDocbyId/${id}`)
  };
};

export const documentUpload = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentDocumentSave', { data, isMultiPart: true })
  };
};

export const documentUpdate = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentDocumentUpdate', { data, isMultiPart: true })
  };
};

export const documentDelete = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post(`studentDocumentdelete/?docId=${data.docId}`, { data })
  };
};

export const getStudentDocuments = (params) => {
  return {
    types: [GET_STUDENT_DOC_FILE_REQUESTED, GET_STUDENT_DOC_FILE_SUCCESS, GET_STUDENT_DOC_FILE_FAILURE],
    promise: (client) => client.get('studentDocumentSendEmail', { params })
  };
};

export const sendEmail = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentsendEmail', { data, isMultiPart: true })
  };
};

export const getStudentTypes = () => {
  return {
    types: [GET_STUDENT_TYPES_REQUESTED, GET_STUDENT_TYPES_SUCCESS, GET_STUDENT_TYPES_FAILURE],
    promise: (client) => client.get('getStudentTypes')
  };
};

export const getStudentCourseStatusList = () => {
  return {
    types: [GET_COURSE_STATUS_LIST_REQUESTED, GET_COURSE_STATUS_LIST_SUCCESS, GET_COURSE_STATUS_LIST_FAILURE],
    promise: (client) => client.get('getStudentCourseStatusList')
  };
};

export const getStudentStatusList = () => {
  return {
    types: [GET_STUDENT_STATUS_LIST_REQUESTED, GET_STUDENT_STATUS_LIST_SUCCESS, GET_STUDENT_STATUS_LIST_FAILURE],
    promise: (client) => client.get('getStudentStatusList')
  };
};

export const getStudentCourseList = (id) => {
  return {
    types: [GET_STUDENT_COURSE_LIST_REQUESTED, GET_STUDENT_COURSE_LIST_SUCCESS, GET_STUDENT_COURSE_LIST_FAILURE],
    promise: (client) => client.get(`studentCourseList/${id}`)
  };
};

export const getUsertype = () => {
  const data = window.localStorage.getItem('userData');
  return (dispatch) => {
    dispatch({
      type: GET_USER_TYPE_SUCCESS,
      payload: JSON.parse(data),
    });
  };
};

export const saveVerification = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('clinicalLogSaveVerification', { data })
  };
};

export const getPreceptorList = (id) => {
  return {
    types: [GET_PRECEPTOR_LIST_REQUESTED, GET_PRECEPTOR_LIST_SUCCESS, GET_PRECEPTOR_LIST_FAILURE],
    promise: (client) => client.get(`preceptors/${id}`)
  };
};

export const getClericalInfo = (data) => {
  return {
    types: [CLERICAL_INFO_REQUESTED, CLERICAL_INFO_SUCCESS, CLERICAL_INFO_FAILURE],
    promise: (client) => client.get(`clericalInfo/${data.clinicalId}/${data.rotationDate}`)
  };
};

export const getClinicalLogVerificationInfo = (clinicalId) => {
  return {
    types: [CLINICAL_LOG_VERIFICATION_INFO_REQUESTED, CLINICAL_LOG_VERIFICATION_INFO_SUCCESS, CLINICAL_LOG_VERIFICATION_INFO_FAILURE],
    promise: (client) => client.get(`getVerificationInfo/${clinicalId}`)
  };
};

export const saveclericalinfo = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('saveclericalinfo', { data })
  };
};

export const getClinicalLogProblemOption = (id) => {
  return {
    types: [GET_CLINICAL_LOG_PROBLEM_OPTIONS_REQUESTED, GET_CLINICAL_LOG_PROBLEM_OPTIONS_SUCCESS, GET_CLINICAL_LOG_PROBLEM_OPTIONS_FAILURE],
    promise: (client) => client.get(`getClinicalLogProblemOption/${id}`)
  };
};

export const getClinicalLogProceduresDropDown = () => {
  return {
    types: [GET_CLINICAL_PROCEDURES_REQUESTED, GET_CLINICAL_PROCEDURES_SUCCESS, GET_CLINICAL_PROCEDURES_FAILURE],
    promise: (client) => client.get('clinicalProcedures')
  };
};

export const getRotationDates = (id) => {
  return {
    types: [GET_ROTATION_DATES_REQUESTED, GET_ROTATION_DATES_SUCCESS, GET_ROTATION_DATES_FAILURE],
    promise: (client) => client.get(`rotationdates/${id}`)
  };
};

export const saveClinicalLogAcademicInfo = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('clinicalLogAcademicInfo', { data })
  };
};

export const getClinicalLogAcademicList = (data) => {
  return {
    types: [CLINICAL_LOG_ACADEMIC_LIST_REQUESTED, CLINICAL_LOG_ACADEMIC_LIST_SUCCESS, CLINICAL_LOG_ACADEMIC_LIST_FAILURE],
    promise: (client) => client.get(`clinicalLogRAcademicList/${data.clinicalId}/${data.rotationDate}`)
  };
};
export const alumniPersonalInfoAdd = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('alumniPersonalInfoAdd', { data })
  };
};

export const alumniPersonalListById = (studentId) => {
  return {
    types: [GET_ALUMNI_PERSONAL_LIST_BY_ID_REQUESTED, GET_ALUMNI_PERSONAL_LIST_BY_ID_SUCCESS, GET_ALUMNI_PERSONAL_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`alumniPersonalInfoList/${studentId}`)
  };
};

export const alumniResidencyYearList = () => {
  return {
    types: [ALUMNI_RESIDENCY_YEAR_LIST_REQUESTED, ALUMNI_RESIDENCY_YEAR_LIST_SUCCESS, ALUMNI_RESIDENCY_YEAR_LIST_FAILURE],
    promise: (client) => client.get('alumniJobResidencyYear')
  };
};

export const alumniSpecialtyList = () => {
  return {
    types: [ALUMNI_SPECIALTY_LIST_REQUESTED, ALUMNI_SPECIALTY_LIST_SUCCESS, ALUMNI_SPECIALTY_LIST_FAILURE],
    promise: (client) => client.get('alumniJobSpecialty')
  };
};

export const alumniJobInfoAdd = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('alumniJobInfoAdd', { data })
  };
};

export const getAlumniJobInfoById = (jobId) => {
  return {
    types: [GET_ALUMNI_JOB_BY_ID_REQUESTED, GET_ALUMNI_JOB_BY_ID_SUCCESS, GET_ALUMNI_JOB_BY_ID_FAILURE],
    promise: (client) => client.get(`alumniJobInfoById/${jobId}`)
  };
};

export const alumniJobListById = (studentId) => {
  return {
    types: [GET_ALUMNI_JOB_LIST_BY_ID_REQUESTED, GET_ALUMNI_JOB_LIST_BY_ID_SUCCESS, GET_ALUMNI_JOB_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`alumniJobInfoList/${studentId}`)
  };
};

export const alumniOtherDegreeAdd = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('alumniOtherDegreeAddOrUpdate', { data })
  };
};

export const getAlumniOtherDegreeById = (id) => {
  return {
    types: [GET_ALUMNI_OTHER_DEGREE_BY_ID_REQUESTED, GET_ALUMNI_OTHER_DEGREE_BY_ID_SUCCESS, GET_ALUMNI_OTHER_DEGREE_BY_ID_FAILURE],
    promise: (client) => client.get(`alumniOtherDegreeById/${id}`)
  };
};

export const alumniOtherDegreeListById = (studentId) => {
  return {
    types: [GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_REQUESTED, GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_SUCCESS, GET_ALUMNI_OTHER_DEGREE_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`alumniOtherDegreeList/${studentId}`)
  };
};

export const alumniMatchInfoAdd = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('alumniMatchInfoAdd', { data })
  };
};

export const getStudentCourse = (id, isAccounting) => {
  return {
    types: [GET_STUDENT_COURSE_REQUESTED, GET_STUDENT_COURSE_SUCCESS, GET_STUDENT_COURSE_FAILURE],
    promise: (client) => client.get(`studentCourse/${id}/${isAccounting}`)
  };
};

export const getStudentCourseDropdown = (id, courseType, courseId) => {
  return {
    types: [GET_STUDENT_COURSE_DROPDOWN_REQUESTED, GET_STUDENT_COURSE_DROPDOWN_SUCCESS, GET_STUDENT_COURSE_DROPDOWN_FAILURE],
    promise: (client) => client.get(`courseDropdown/?courseId=${courseId}&courseType=${courseType}&studentId=${id}`)
  };
};

export const addStudentCourse = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addStudentCourse', { data })
  };
};

export const getStudentCourseDetails = (id) => {
  return {
    types: [GET_STUDENT_COURSE_DETAILS_REQUESTED, GET_STUDENT_COURSE_DETAILS_SUCCESS, GET_STUDENT_COURSE_DETAILS_FAILURE],
    promise: (client) => client.get(`getStudentCourseDetails/${id}`)
  };
};

export const getClinicalRotationList = (id) => {
  return {
    types: [GET_CLINICAL_ROTATION_LIST_REQUESTED, GET_CLINICAL_ROTATION_LIST_SUCCESS, GET_CLINICAL_ROTATION_LIST_FAILURE],
    promise: (client) => client.get(`clinicalRotationList/${id}`)
  };
};

export const getResidencyProgramList = (id) => {
  return {
    types: [GET_RESIDENCY_PROGRAM_LIST_REQUESTED, GET_RESIDENCY_PROGRAM_LIST_SUCCESS, GET_RESIDENCY_PROGRAM_LIST_FAILURE],
    promise: (client) => client.get(`studentResidencyProgramList/${id}`)
  };
};

export const getResidencyProgram = (id) => {
  return {
    types: [GET_RESIDENCY_PROGRAM_REQUESTED, GET_RESIDENCY_PROGRAM_SUCCESS, GET_RESIDENCY_PROGRAM_FAILURE],
    promise: (client) => client.get(`getStudentResidencyProgram/${id}`)
  };
};

export const addResidencyProgram = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addStudentResidencyProgram', { data })
  };
};

export const getResidencySpecialityList = () => {
  return {
    types: [GET_RESIDENCY_SPECIALITY_LIST_REQUESTED, GET_RESIDENCY_SPECIALITY_LIST_SUCCESS, GET_RESIDENCY_SPECIALITY_LIST_FAILURE],
    promise: (client) => client.get('studentResidencySpecialityList')
  };
};

export const getResidencyProgramType = () => {
  return {
    types: [GET_RESIDENCY_PROGRAM_TYPE_REQUESTED, GET_RESIDENCY_PROGRAM_TYPE_SUCCESS, GET_RESIDENCY_PROGRAM_TYPE_FAILURE],
    promise: (client) => client.get('studentResidencyProgramType')
  };
};

export const getClinicalLogAcademicInfoById = (id) => {
  return {
    types: [GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_REQUESTED, GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_SUCCESS, GET_CLINICAL_LOG_ACADEMIC_INFO_BY_ID_FAILURE],
    promise: (client) => client.get(`clinicalLogAcademicData/${id}`)
  };
};

export const getDownloadClinicalLoginfo = (clinicalId) => {
  return {
    types: [GET_CLINICAL_LOG_DOWNLOAD_INFO_REQUESTED, GET_CLINICAL_LOG_DOWNLOAD_INFO_SUCCESS, GET_CLINICAL_LOG_DOWNLOAD_INFO_FAILURE],
    promise: (client) => client.get(`getDownloadClinicalLoginfo/${clinicalId}`)
  };
};
export const getRotationDropdown = (type) => {
  return {
    types: [GET_ROTATION_DROPDOWN_REQUESTED, GET_ROTATION_DROPDOWN_SUCCESS, GET_ROTATION_DROPDOWN_FAILURE],
    promise: (client) => client.get(`rotationDropdown/${type}`)
  };
};

export const getRotationGraderType = () => {
  return {
    types: [GET_ROTATION_GRADETYPE_REQUESTED, GET_ROTATION_GRADETYPE_SUCCESS, GET_ROTATION_GRADETYPE_FAILURE],
    promise: (client) => client.get('rotationGradeType')
  };
};

export const getRotationStatus = () => {
  return {
    types: [GET_ROTATION_GRADE_STATUS_REQUESTED, GET_ROTATION_GRADE_STATUS_SUCCESS, GET_ROTATION_GRADE_STATUS_FAILURE],
    promise: (client) => client.get('rotationStatus')
  };
};

export const getRotationHospital = (params) => {
  return {
    types: [GET_ROTATION_HOSPITAL_REQUESTED, GET_ROTATION_HOSPITAL_SUCCESS, GET_ROTATION_HOSPITAL_FAILURE],
    promise: (client) => client.get('rotationHospital', { params })
  };
};

export const getRotationPreceptor = (hospitalId) => {
  return {
    types: [GET_ROTATION_PRECEPTOR_REQUESTED, GET_ROTATION_PRECEPTOR_SUCCESS, GET_ROTATION_PRECEPTOR_FAILURE],
    promise: (client) => client.get(`rotationPreceptor/${hospitalId}`)
  };
};

export const addRotation = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addRotation', { data })
  };
};

export const getStudentHospital = () => {
  return {
    types: [GET_STUDENT_HOSPITAL_REQUESTED, GET_STUDENT_HOSPITAL_SUCCESS, GET_STUDENT_HOSPITAL_FAILURE],
    promise: (client) => client.get('studentHopital')
  };
};

export const studentRotationDelete = (id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`rotationDelete/${id}`)
  };
};

export const addClinicalTransfer = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addClinicalTransfer', { data })
  };
};

export const getClinicalTransferList = (studentCourseId) => {
  return {
    types: [GET_CLINICAL_TRANSFER_LIST_REQUESTED, GET_CLINICAL_TRANSFER_LIST_SUCCESS, GET_CLINICAL_TRANSFER_LIST_FAILURE],
    promise: (client) => client.get(`clinicalTransferList/${studentCourseId}`)
  };
};

export const clinicalTransferDelete = (id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`clinicalTransferDelete/${id}`)
  };
};

export const getClinicalTransferData = (transferId) => {
  return {
    types: [GET_CLINICAL_TRANSFER_DATA_REQUESTED, GET_CLINICAL_TRANSFER_DATA_SUCCESS, GET_CLINICAL_TRANSFER_DATA_FAILURE],
    promise: (client) => client.get(`getTransferData/${transferId}`)
  };
};

export const courseDelete = (userId, id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`deleteStudentCourse/${id}/${userId}`)
  };
};

export const getClinicalRotationData = (id, type, rotationId) => {
  return {
    types: [GET_CLINICAL_ROTATION_DATA_REQUESTED, GET_CLINICAL_ROTATION_DATA_SUCCESS, GET_CLINICAL_ROTATION_DATA_FAILURE],
    promise: (client) => client.get(`editRotation/${id}/${type}/${rotationId}`)
  };
};

export const getClericalListAdmin = (clinicalId) => {
  return {
    types: [GET_CLERICAL_LIST_REQUESTED, GET_CLERICAL_LIST_SUCCESS, GET_CLERICAL_LIST_FAILURE],
    promise: (client) => client.get(`clericalInfoListadmin/${clinicalId}`)
  };
};

export const addNonClinicalCourseClasses = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addNonClinicalCourseClasses', { data })
  };
};

export const getStageDropdown = (courseId) => {
  return {
    types: [GET_STAGE_DROPDOWN_REQUESTED, GET_STAGE_DROPDOWN_SUCCESS, GET_STAGE_DROPDOWN_FAILURE],
    promise: (client) => client.get(`stageDropdownList/${courseId}`)
  };
};

export const getClassesListByStage = (stageId) => {
  return {
    types: [GET_CLASS_BY_STAGE_REQUESTED, GET_CLASS_BY_STAGE_SUCCESS, GET_CLASS_BY_STAGE_FAILURE],
    promise: (client) => client.get(`getClassesListByStage/${stageId ? `?stageId=${stageId}` : ''}`)
  };
};

export const getGradeTypeList = () => {
  return {
    types: [GET_GRADE_TYPE_LIST_REQUESTED, GET_GRADE_TYPE_LIST_SUCCESS, GET_GRADE_TYPE_LIST_FAILURE],
    promise: (client) => client.get('gradeTypeList')
  };
};

export const getGradePointStatusDropdown = () => {
  return {
    types: [GET_GRADE_POINT_STATUS_REQUESTED, GET_GRADE_POINT_STATUS_SUCCESS, GET_GRADE_POINT_STATUS_FAILURE],
    promise: (client) => client.get('gradePointStatusDropdown')
  };
};

export const getNonClinicalClassesList = (data) => {
  return {
    types: [GET_NONCLINICAL_CLASSES_REQUESTED, GET_NONCLINICAL_CLASSES_SUCCESS, GET_NONCLINICAL_CLASSES_FAILURE],
    promise: (client) => client.get(`nonClinicalClasses/${data}`)
  };
};

export const getNonClinicalStagesList = (data) => {
  return {
    types: [GET_NONCLINICAL_STAGES_REQUESTED, GET_NONCLINICAL_STAGES_SUCCESS, GET_NONCLINICAL_STAGES_FAILURE],
    promise: (client) => client.get(`nonClinicalStages/${data}`)
  };
};

export const getNonClinicalStageAndSemester = (data) => {
  return {
    types: [GET_NONCLINICAL_STAGE_SEMESTER_REQUESTED, GET_NONCLINICAL_STAGE_SEMESTER_SUCCESS, GET_NONCLINICAL_STAGE_SEMESTER_FAILURE],
    promise: (client) => client.get(`studentStageSemester/${data}`)
  };
};

export const registerNonClinicalStage = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('registerStudentStage', { data })
  };
};

export const promoteNonClinicalStage = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('promoteStudentStage', { data })
  };
};

export const repeatNonClinicalStage = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('repeatStudentStage', { data })
  };
};

export const specialNonClinicalStage = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('specialStudentStage', { data })
  };
};

export const getNonClinicalAcademicStanding = (data) => {
  return {
    types: [GET_NONCLINICAL_ACADEMIC_STANDING_REQUESTED, GET_NONCLINICAL_ACADEMIC_STANDING_SUCCESS, GET_NONCLINICAL_ACADEMIC_STANDING_FAILURE],
    promise: (client) => client.get(`nonClinicalAcademicStanding/${data}`)
  };
};

export const getAcademicStandingDropdown = () => {
  return {
    types: [GET_ACADEMIC_STANDING_DROPDOWN_REQUESTED, GET_ACADEMIC_STANDING_DROPDOWN_SUCCESS, GET_ACADEMIC_STANDING_DROPDOWN_FAILURE],
    promise: (client) => client.get('academicStandingDropdown')
  };
};

export const updateNonClinicalAcademicStanding = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateNonClinicalAcademicStanding', { data })
  };
};

export const deleteNonClinicalTransfer = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`deleteNonClinicalTransfer/${data}`)
  };
};

export const getNonClinicalTransfer = (data) => {
  return {
    types: [GET_NONCLINICAL_TRANSFER_REQUESTED, GET_NONCLINICAL_TRANSFER_SUCCESS, GET_NONCLINICAL_TRANSFER_FAILURE],
    promise: (client) => client.get(`getNonClinicalTransfer/${data}`)
  };
};

export const getNonClinicalTransferList = (data) => {
  return {
    types: [GET_NONCLINICAL_TRANSFER_LIST_REQUESTED, GET_NONCLINICAL_TRANSFER_LIST_SUCCESS, GET_NONCLINICAL_TRANSFER_LIST_FAILURE],
    promise: (client) => client.get(`nonClinicalTransferList/${data}`)
  };
};

export const updateNonClinicalTransfer = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateNonClinicalTransfer', { data })
  };
};
export const getAcademicStandingListById = (studentId) => {
  return {
    types: [GET_ACADEMIC_STANDING_LIST_BY_ID_REQUESTED, GET_ACADEMIC_STANDING_LIST_BY_ID_SUCCESS, GET_ACADEMIC_STANDING_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`academicStanding/${studentId}`)
  };
};

export const updateClinicalAcademicStanding = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateClinicalAcademicStanding', { data })
  };
};

export const deleteNonClinicalClasses = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`deleteNonClinicalClasses/${data}`)
  };
};

export const getSpecialStageDropdown = () => {
  return {
    types: [GET_NC_SPECIAL_STAGE_REQUESTED, GET_NC_SPECIAL_STAGE_SUCCESS, GET_NC_SPECIAL_STAGE_FAILURE],
    promise: (client) => client.get('getSpecialStageDropdown')
  };
};

export const getNonClinicalClassDetails = (data) => {
  return {
    types: [GET_NONCLINICAL_CLASS_DETAILS_REQUESTED, GET_NONCLINICAL_CLASS_DETAILS_SUCCESS, GET_NONCLINICAL_CLASS_DETAILS_FAILURE],
    promise: (client) => client.get(`nonClinicalClassDetails/${data}`)
  };
};

export const getUsmleTestListById = (studentId) => {
  return {
    types: [STUDENT_USMLE_TEST_LIST_REQUESTED, STUDENT_USMLE_TEST_LIST_SUCCESS, STUDENT_USMLE_TEST_LIST_FAILURE],
    promise: (client) => client.get(`usmleTestList/${studentId}`)
  };
};

export const getUsmleTestNoOfAttempts = (studentId, testName) => {
  return {
    types: [USMLE_TEST_NO_OF_ATTEMPTS_REQUESTED, USMLE_TEST_NO_OF_ATTEMPTS_SUCESS, USMLE_TEST_NO_OF_ATTEMPTS_FAILURE],
    promise: (client) => client.get(`noOfAttempts/${studentId}/${testName}`)
  };
};

export const addUSMLETest = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addUsmleTest', { data, isMultiPart: true })
  };
};

export const usmleTestDelete = (id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`deleteUsmleTest/${id}`)
  };
};

export const getUsmleTestById = (id) => {
  return {
    types: [GET_USMLETEST_BY_ID_REQUESTED, GET_USMLETEST_BY_ID_SUCCESS, GET_USMLETEST_BY_ID_FAILURE],
    promise: (client) => client.get(`usmleTestById/${id}`)
  };
};
export const editUsmleTestLoaDate = (studentid, loaeffectivefrom) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post(`usmleTestLoaEdit/${studentid}/${loaeffectivefrom}`)
  };
};

export const studentLeaveList = (id) => {
  return {
    types: [STUDENT_LEAVE_LIST_REQUESTED, STUDENT_LEAVE_LIST_SUCCESS, STUDENT_LEAVE_LIST_FAILURE],
    promise: (client) => client.get(`studentLeaveList/${id}`)
  };
};

export const studentLeaveReasonList = () => {
  return {
    types: [STUDENT_LEAVE_REASON_LIST_REQUESTED, STUDENT_LEAVE_REASON_LIST_SUCCESS, STUDENT_LEAVE_REASON_LIST_FAILURE],
    promise: (client) => client.get('studentLeaveReason')
  };
};

export const getStudentLeave = (id) => {
  return {
    types: [GET_STUDENT_LEAVE_REQUESTED, GET_STUDENT_LEAVE_SUCCESS, GET_STUDENT_LEAVE_FAILURE],
    promise: (client) => client.get(`getstudentLeave/${id}`)
  };
};

export const addStudentLeave = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentLeaveAdd', { data })
  };
};

export const deleteStudentLeave = (id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`studentLeaveDelete/${id}`)
  };
};

export const studentClinicalDocList = (id) => {
  return {
    types: [GET_CLINICAL_DOC_LIST_BY_ID_REQUESTED, GET_CLINICAL_DOC_LIST_BY_ID_SUCCESS, GET_CLINICAL_DOC_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`studentClinicalDocList/${id}`)
  };
};

export const studentClinicalDocTypeList = () => {
  return {
    types: [GET_CLINICAL_DOC_TYPE_LIST_BY_ID_REQUESTED, GET_CLINICAL_DOC_TYPE_LIST_BY_ID_SUCCESS, GET_CLINICAL_DOC_TYPE_LIST_BY_ID_FAILURE],
    promise: (client) => client.get('studentClinicalType')
  };
};

export const addStudentClinicalDoc = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentClinicalDocAdd', { data, isMultiPart: true })
  };
};

export const clinicalDocumentById = (id) => {
  return {
    types: [GET_CLINICAL_DOCUMENT_REQUESTED, GET_CLINICAL_DOCUMENT_SUCCESS, GET_CLINICAL_DOCUMENT_FAILURE],
    promise: (client) => client.get(`studentClinicalDoc/${id}`)
  };
};

export const deleteStudentClinicalDoc = (id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`studentClinicalDoc/${id}`)
  };
};

export const getStageStatus = () => {
  return {
    types: [GET_STAGE_STATUS_DETAILS_REQUESTED, GET_STAGE_STATUS_DETAILS_SUCCESS, GET_STAGE_STATUS_DETAILS_FAILURE],
    promise: (client) => client.get('stageStatus')
  };
};

export const getAlumniMatchInfoExist = (id) => {
  return {
    types: [GET_ALUMNI_MATCH_INFO_REQUESTED, GET_ALUMNI_MATCH_INFO_SUCCESS, GET_ALUMNI_MATCH_INFO_FAILURE],
    promise: (client) => client.get(`alumniMatchInfoExist/${id}`)
  };
};

export const getProgramWithdrawalList = (id) => {
  return {
    types: [GET_PROGRAM_WITHDRAWAL_LIST_REQUESTED, GET_PROGRAM_WITHDRAWAL_LIST_SUCCESS, GET_PROGRAM_WITHDRAWAL_LIST_FAILURE],
    promise: (client) => client.get(`getProgramWithdrawalList/${id}`)
  };
};

export const addProgramWithDrawal = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('programWithdrawalAdd', { data })
  };
};

export const getProgramWithdrawalData = (id) => {
  return {
    types: [GET_PROGRAM_WITHDRAWAL_DATA_REQUESTED, GET_PROGRAM_WITHDRAWAL_DATA_SUCCESS, GET_PROGRAM_WITHDRAWAL_DATA_FAILURE],
    promise: (client) => client.get(`getProgramWithdrawalData/${id}`)
  };
};

export const getTransferClassDropdown = (data) => {
  return {
    types: [GET_TRANSFER_CLASS_DROPDOWN_REQUESTED, GET_TRANSFER_CLASS_DROPDOWN_SUCCESS, GET_TRANSFER_CLASS_DROPDOWN_FAILURE],
    promise: (client) => client.get(`transferClassDropdown/${data}`)
  };
};

export const getReplacementDiplomaList = (params) => {
  return {
    types: [GET_REPLACEMENT_DIPLOMA_LIST_REQUESTED, GET_REPLACEMENT_DIPLOMA_LIST_SUCCESS, GET_REPLACEMENT_DIPLOMA_LIST_FAILURE],
    promise: (client) => client.get('getReplacementDiplomaList', { params })
  };
};

export const getReplacementDiploma = (dipID) => {
  return {
    types: [GET_REPLACEMENT_DIPLOMA_REQUESTED, GET_REPLACEMENT_DIPLOMA_SUCCESS, GET_REPLACEMENT_DIPLOMA_FAILURE],
    promise: (client) => client.get(`getReplacementDiploma/${dipID}`)
  };
};

export const addUpdatereplacement = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdatereplacement', { data })
  };
};

export const getRotationDropdownWeeks = (type) => {
  return {
    types: [GET_ROTATION_DROPDOWN_WEEKS_REQUESTED, GET_ROTATION_DROPDOWN_WEEKS_SUCCESS, GET_ROTATION_DROPDOWN_WEEKS_FAILURE],
    promise: (client) => client.get(`rotationWithWeeks/${type}`)
  };
};

export const getI94Letter = (letterId) => {
  return {
    types: [GET_I94_LETTER_REQUESTED, GET_I94_LETTER_SUCCESS, GET_I94_LETTER_FAILURE],
    promise: (client) => client.get(`getI94Letter/${letterId}`)
  };
};

export const updateI94Letter = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateI94Letter', { data })
  };
};

export const getEnrollmentRequestList = (params) => {
  return {
    types: [GET_ENROLLMENT_REQUEST_LIST_REQUESTED, GET_ENROLLMENT_REQUEST_LIST_SUCCESS, GET_ENROLLMENT_REQUEST_LIST_FAILURE],
    promise: (client) => client.get('getEnrollmentList', { params })
  };
};

export const getEnrollmentRequestById = (id) => {
  return {
    types: [GET_ENROLLMENT_REQUEST_REQUESTED, GET_ENROLLMENT_REQUEST_SUCCESS, GET_ENROLLMENT_REQUEST_FAILURE],
    promise: (client) => client.get(`getEnrollmentRequest/${id}`)
  };
};

export const updateEnrollmentRequest = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateEnrollmentRequest', { data, isMultiPart: true })
  };
};

export const getGraduationRequestList = (params) => {
  return {
    types: [GET_GRADUATION_REQUEST_LIST_REQUESTED, GET_GRADUATION_REQUEST_LIST_SUCCESS, GET_GRADUATION_REQUEST_LIST_FAILURE],
    promise: (client) => client.get('getGraduationRequestList', { params })
  };
};

export const getGraduationRequestById = (id) => {
  return {
    types: [GET_GRADUATION_REQUEST_REQUESTED, GET_GRADUATION_REQUEST_SUCCESS, GET_GRADUATION_REQUEST_FAILURE],
    promise: (client) => client.get(`getGraduationRequest/${id}`)
  };
};

export const updateGraduationRequest = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updateGraduationrequest', { data })
  };
};

export const getPreMedLoaRequestList = (params) => {
  return {
    types: [GET_PRE_MED_LOA_REQUEST_LIST_REQUESTED, GET_PRE_MED_LOA_REQUEST_LIST_SUCCESS, GET_PRE_MED_LOA_REQUEST_LIST_FAILURE],
    promise: (client) => client.get('getPreMedLoaList', { params })
  };
};

export const getPreMedRequestById = (id) => {
  return {
    types: [GET_PRE_MED_LOA_REQUEST_REQUESTED, GET_PRE_MED_LOA_REQUEST_SUCCESS, GET_PRE_MED_LOA_REQUEST_FAILURE],
    promise: (client) => client.get(`getPreMedLoaRequest/${id}`)
  };
};

export const updatePreMedRequest = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updatePreMedLoaRequest', { data })
  };
};

export const getClassWithdrawalList = (id) => {
  return {
    types: [GET_CLASS_WITHDRAWAL_LIST_REQUESTED, GET_CLASS_WITHDRAWAL_LIST_SUCCESS, GET_CLASS_WITHDRAWAL_LIST_FAILURE],
    promise: (client) => client.get(`getClassWithdrawalList/${id}`)
  };
};

export const getClassWithdrawalData = (id) => {
  return {
    types: [GET_CLASS_WITHDRAWAL_DATA_REQUESTED, GET_CLASS_WITHDRAWAL_DATA_SUCCESS, GET_CLASS_WITHDRAWAL_DATA_FAILURE],
    promise: (client) => client.get(`getClassWithdrawalData/${id}`)
  };
};

export const getEnrollmentList = (id) => {
  return {
    types: [GET_ENROLLMENT_LIST_REQUESTED, GET_ENROLLMENT_LIST_SUCCESS, GET_ENROLLMENT_LIST_FAILURE],
    promise: (client) => client.get(`enrollmentRequestList/${id}`)
  };
};

export const getGraduationList = (id) => {
  return {
    types: [GET_GRADUATION_LIST_REQUESTED, GET_GRADUATION_LIST_SUCCESS, GET_GRADUATION_LIST_FAILURE],
    promise: (client) => client.get(`getGraduationList/${id}`)
  };
};

export const getReplacementList = (id) => {
  return {
    types: [GET_REPLACEMENT_LIST_REQUESTED, GET_REPLACEMENT_LIST_SUCCESS, GET_REPLACEMENT_LIST_FAILURE],
    promise: (client) => client.get(`replacementDiplomaList/${id}`)
  };
};

export const getAbsenceList = (id, premed) => {
  return {
    types: [GET_ABSENCE_LIST_REQUESTED, GET_ABSENCE_LIST_SUCCESS, GET_ABSENCE_LIST_FAILURE],
    promise: (client) => client.get(`leaveOfAbsenceList/${id}?premedAndBsicScience=${premed}`)
  };
};

export const getAbsenceListData = (id, premed) => {
  return {
    types: [GET_ABSENCE_LIST_REQUESTED, GET_ABSENCE_LIST_DATA_SUCCESS, GET_ABSENCE_LIST_FAILURE],
    promise: (client) => client.get(`leaveOfAbsenceList/${id}?premedAndBsicScience=${premed}`)
  };
};

export const getFinanceList = (id) => {
  return {
    types: [GET_FINANCE_LIST_REQUESTED, GET_FINANCE_LIST_SUCCESS, GET_FINANCE_LIST_FAILURE],
    promise: (client) => client.get(`financeList/?studentId=${id}`)
  };
};

export const getMspeList = (id) => {
  return {
    types: [GET_MSPE_LIST_REQUESTED, GET_MSPE_LIST_SUCCESS, GET_MSPE_LIST_FAILURE],
    promise: (client) => client.get(`mspeQueryList/${id}`)
  };
};

export const getComplaintList = (id) => {
  return {
    types: [GET_COMPLAINT_LIST_REQUESTED, GET_COMPLAINT_LIST_SUCCESS, GET_COMPLAINT_LIST_FAILURE],
    promise: (client) => client.get(`complaintsList/?studentId=${id}`)
  };
};

export const getEnrollmentData = (id) => {
  return {
    types: [GET_ENROLLMENT_DATA_REQUESTED, GET_ENROLLMENT_DATA_SUCCESS, GET_ENROLLMENT_DATA_FAILURE],
    promise: (client) => client.get(`enrollmentRequestData/${id}`)
  };
};

export const getGraduationData = (id) => {
  return {
    types: [GET_GRADUATION_DATA_REQUESTED, GET_GRADUATION_DATA_SUCCESS, GET_GRADUATION_DATA_FAILURE],
    promise: (client) => client.get(`getGraduationFormData/${id}`)
  };
};

export const getReplacementData = (id) => {
  return {
    types: [GET_REPLACEMENT_DATA_REQUESTED, GET_REPLACEMENT_DATA_SUCCESS, GET_REPLACEMENT_DATA_FAILURE],
    promise: (client) => client.get(`getReplacementDiplomaData/${id}`)
  };
};

export const getAbsenceData = (id) => {
  return {
    types: [GET_ABSENCE_DATA_REQUESTED, GET_ABSENCE_DATA_SUCCESS, GET_ABSENCE_DATA_FAILURE],
    promise: (client) => client.get(`getleaveOfAbsence/${id}`)
  };
};
export const getFinanceData = (sid, id) => {
  return {
    types: [GET_FINANCE_DATA_REQUESTED, GET_FINANCE_DATA_SUCCESS, GET_FINANCE_DATA_FAILURE],
    promise: (client) => client.get(`getFinanceData/${sid}/${id}`)
  };
};

export const getQueryExistenceData = (id) => {
  return {
    types: [GET_QUERY_DATA_REQUESTED, GET_QUERY_DATA_SUCCESS, GET_QUERY_DATA_FAILURE],
    promise: (client) => client.get(`queryExistence/${id}`)
  };
};

export const getComplaintData = (sid, id) => {
  return {
    types: [GET_COMPLAINT_DATA_REQUESTED, GET_COMPLAINT_DATA_SUCCESS, GET_COMPLAINT_DATA_FAILURE],
    promise: (client) => client.get(`getComplaintData/${sid}/${id}`)
  };
};

export const getMspeData = (id) => {
  return {
    types: [GET_MSPE_DATA_REQUESTED, GET_MSPE_DATA_SUCCESS, GET_MSPE_DATA_FAILURE],
    promise: (client) => client.get(`getMspedata/${id}`)
  };
};

export const addReplacementDiploma = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('replacementDiplomaAdd', { data })
  };
};

export const addClassWithDrawal = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('getClassWithdrawalAdd', { data })
  };
};

export const addEnrollmentRequest = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('enrollmentRequestAdd', { data, isMultiPart: true })
  };
};

export const getEvaluationForm = (params) => {
  return {
    types: [GET_EVALUATION_FORM_REQUESTED, GET_EVALUATION_FORM_SUCCESS, GET_EVALUATION_FORM_FAILURE],
    promise: (client) => client.get('courseEvalationForm', { params })
  };
};

export const getNumbers = (start, end) => {
  return {
    types: [GET_NUMBER_LIST_REQUESTED, GET_NUMBER_LIST_SUCCESS, GET_NUMBER_LIST_FAILURE],
    promise: (client) => client.get(`semesterYear/${start}/${end}`)
  };
};

export const getNumberList = (start, end) => {
  return {
    types: [GET_NUMBER_DATA_REQUESTED, GET_NUMBER_DATA_SUCCESS, GET_NUMBER_DATA_FAILURE],
    promise: (client) => client.get(`semesterYear/${start}/${end}`)
  };
};

export const addLeaveOfAbsence = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('leaveOfAbsenceAdd', { data })
  };
};

export const addEvaluationForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addCourseEvaluationForm', { data })
  };
};

export const uploadEvaluationSurveyForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('uploadFileEvaluation', { data, isMultiPart: true })
  };
};

export const addSurveyForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addSurveyFormCourse', { data })
  };
};

export const getSurveyDetails = (params) => {
  return {
    types: [GET_SURVEY_DETAILS_REQUESTED, GET_SURVEY_DETAILS_SUCCESS, GET_SURVEY_DETAILS_FAILURE],
    promise: (client) => client.get('getSurveyDetails', { params })
  };
};

export const addGraduationForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('graduationFormAdd', { data })
  };
};

export const addMspeForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('mspeAdd', { data, isMultiPart: true })
  };
};

export const addComplaintForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('complaintAdd', { data, isMultiPart: true })
  };
};

export const addFinanceForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('financeAdd', { data, isMultiPart: true })
  };
};

export const getLeaveAbsenceList = (params) => {
  return {
    types: [GET_LEAVE_ABSENCE_LIST_REQUESTED, GET_LEAVE_ABSENCE_LIST_SUCCESS, GET_LEAVE_ABSENCE_LIST_FAILURE],
    promise: (client) => client.get('getLeaveAbsenceList', { params })
  };
};

export const getLeaveAbsence = (id) => {
  return {
    types: [GET_LEAVE_ABSENCE_REQUESTED, GET_LEAVE_ABSENCE_SUCCESS, GET_LEAVE_ABSENCE_FAILURE],
    promise: (client) => client.get(`getLeaveAbsence/${id}`)
  };
};

export const addUpadteLeaveAbsence = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpadteLeaveAbsence', { data })
  };
};

export const getI94RequestFormListStudent = (studentId) => {
  return {
    types: [GET_I94_REQUEST_FORM_LIST_STUDENT_REQUESTED, GET_I94_REQUEST_FORM_LIST_STUDENT_SUCCESS, GET_I94_REQUEST_FORM_LIST_STUDENT_FAILURE],
    promise: (client) => client.get(`getI94RequestFormListStudent/${studentId}`)
  };
};

export const getI94RequestForm = (id) => {
  return {
    types: [GET_I94_REQUEST_FORM_REQUESTED, GET_I94_REQUEST_FORM_SUCCESS, GET_I94_REQUEST_FORM_FAILURE],
    promise: (client) => client.get(`getI94RequestForm/${id}`)
  };
};

export const addUpdateI94RequestForm = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateI94RequestForm', { data })
  };
};

export const getGeneralQueryList = (studentId) => {
  return {
    types: [GET_GENERAL_QUERY_LIST_REQUESTED, GET_GENERAL_QUERY_LIST_SUCCESS, GET_GENERAL_QUERY_LIST_FAILURE],
    promise: (client) => client.get(`getGeneralQueryList/${studentId}`)
  };
};

export const getGeneralQuery = (queryId) => {
  return {
    types: [GET_GENERAL_QUERY_REQUESTED, GET_GENERAL_QUERY_SUCCESS, GET_GENERAL_QUERY_FAILURE],
    promise: (client) => client.get(`getGeneralQuery/${queryId}`)
  };
};

export const addUpdateGeneralQuery = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateGeneralQuery', { data })
  };
};

export const getI94LetterRequestList = (params) => {
  return {
    types: [GET_I94_LETTER_REQUEST_LIST_REQUESTED, GET_I94_LETTER_REQUEST_LIST_SUCCESS, GET_I94_LETTER_REQUEST_LIST_FAILURE],
    promise: (client) => client.get('getI94LetterList', { params })
  };
};

export const getClinicalRequestList = (params) => {
  return {
    types: [GET_CLINICAL_REQUEST_LIST_REQUESTED, GET_CLINICAL_REQUEST_LIST_SUCCESS, GET_CLINICAL_REQUEST_LIST_FAILURE],
    promise: (client) => client.get('getClinicalRequestList', { params })
  };
};

export const getClinicalRequestPdfList = (params) => {
  return {
    types: [GET_STUDENT_LIST_REQUESTED, GET_CLINICAL_REQUEST_PDF_LIST_SUCCESS, GET_STUDENT_LIST_FAILURE],
    promise: (client) => client.get('getClinicalRequestList', { params })
  };
};

export const getClinicalRequestById = (params) => {
  return {
    types: [GET_CLINICAL_REQUEST_REQUESTED, GET_CLINICAL_REQUEST_SUCCESS, GET_CLINICAL_REQUEST_FAILURE],
    promise: (client) => client.get('getClinicalRequest', { params })
  };
};

export const updateClinicalRequest = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateClinicalRequest', { data, isMultiPart: true })
  };
};

export const getComplaintRequestList = (params) => {
  return {
    types: [GET_COMPLAINT_REQUEST_LIST_REQUESTED, GET_COMPLAINT_REQUEST_LIST_SUCCESS, GET_COMPLAINT_REQUEST_LIST_FAILURE],
    promise: (client) => client.get('getComplaintRequestList', { params })
  };
};

export const getComplaintRequest = (sID, cId) => {
  return {
    types: [GET_COMPLAINT_REQUEST_REQUESTED, GET_COMPLAINT_REQUEST_SUCCESS, GET_COMPLAINT_REQUEST_FAILURE],
    promise: (client) => client.get(`getComplaintRequest/${sID}/${cId}`)
  };
};

export const addUpdateComplaint = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateComplaint', { data, isMultiPart: true })
  };
};

export const getClinicalReuestBasicInfo = (params) => {
  return {
    types: [GET_CLINICAL_REQUEST_BASIC_REQUESTED, GET_CLINICAL_REQUEST_BASIC_SUCCESS, GET_CLINICAL_REQUEST_BASIC_FAILURE],
    promise: (client) => client.get('clinicalRequestBasicCard', { params })
  };
};

export const savePolicyGuide = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post(`savePolicyGuide/${id}`)
  };
};

export const getStudentCourseStatus = (params) => {
  return {
    types: [GET_STUDENT_COURSES_STATUS_REQUESTED, GET_STUDENT_COURSES_STATUS_SUCCESS, GET_STUDENT_COURSES_STATUS_FAILURE],
    promise: (client) => client.get('studentCoursestatus', { params })
  };
};

export const getStudentRotationList = (studentId) => {
  return {
    types: [GET_STUDENT_ROTATION_LIST_REQUESTED, GET_STUDENT_ROTATION_LIST_SUCCESS, GET_STUDENT_ROTATION_LIST_FAILURE],
    promise: (client) => client.get(`studentRotationList/${studentId}`)
  };
};

export const getRotationList = (studentId) => {
  return {
    types: [GET_ROTATION_LIST_REQUESTED, GET_ROTATION_LIST_SUCCESS, GET_ROTATION_LIST_FAILURE],
    promise: (client) => client.get(`getRotationList/${studentId}`)
  };
};

export const addUpdateStudentRotation = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateStudentRotation', { data, isMultiPart: true })
  };
};

export const cancelClinicalRequestRotation = (requestId, userId) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`cancelClinicalRequestRotation/${requestId}/${userId}`)
  };
};

export const getStudentRequestList = (studentId) => {
  return {
    types: [GET_STUDENT_REQUEST_LIST_REQUESTED, GET_STUDENT_REQUEST_LIST_SUCCESS, GET_STUDENT_REQUEST_LIST_FAILURE],
    promise: (client) => client.get(`studentDetailsRequestList/${studentId}`)
  };
};

export const saveStudentRequest = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('addStudentDetailsRequest', { data })
  };
};

export const getClinicalMedicineList = (studentId) => {
  return {
    types: [GET_STUDENT_CLINICAL_MEDICINE_LIST_REQUESTED, GET_STUDENT_CLINICAL_MEDICINE_LIST_SUCCESS, GET_STUDENT_CLINICAL_MEDICINE_LIST_FAILURE],
    promise: (client) => client.get(`getClinicalMedicineList/${studentId}`)
  };
};
export const getProfileClinicalRotationList = (studentId) => {
  return {
    types: [GET_STUDENT_CLINICAL_ROTATION_LIST_REQUESTED, GET_STUDENT_CLINICAL_ROTATION_LIST_SUCCESS, GET_STUDENT_CLINICAL_ROTATION_LIST_FAILURE],
    promise: (client) => client.get(`getClinicalRotationList/${studentId}`)
  };
};
export const getGPAList = (studentId) => {
  return {
    types: [GET_STUDENT_GPA_LIST_REQUESTED, GET_STUDENT_GPA_LIST_SUCCESS, GET_STUDENT_GPA_LIST_FAILURE],
    promise: (client) => client.get(`getGPAList/${studentId}`)
  };
};
export const getMedClassList = (studentId) => {
  return {
    types: [GET_STUDENT_MED_LIST_REQUESTED, GET_STUDENT_MED_LIST_SUCCESS, GET_STUDENT_MED_LIST_FAILURE],
    promise: (client) => client.get(`getMedClassList/${studentId}`)
  };
};
// export const getPaymentOneList = (studentId) => {
//   return {
//     types: [GET_STUDENT_PAYMENT_ONE_LIST_REQUESTED, GET_STUDENT_PAYMENT_ONE_LIST_SUCCESS, GET_STUDENT_PAYMENT_ONE_LIST_FAILURE],
//     promise: client => client.get(`getPaymentOneList/${studentId}`)
//   }
// }
// export const getPaymentTwoList = (studentId) => {
//   return {
//     types: [GET_STUDENT_PAYMENT_TWO_LIST_REQUESTED, GET_STUDENT_PAYMENT_TWO_LIST_SUCCESS, GET_STUDENT_PAYMENT_TWO_LIST_FAILURE],
//     promise: client => client.get(`getPaymentTwoList/${studentId}`)
//   }
// }
export const getPremedClassList = (studentId) => {
  return {
    types: [GET_STUDENT_PREMED_LIST_REQUESTED, GET_STUDENT_PREMED_LIST_SUCCESS, GET_STUDENT_PREMED_LIST_FAILURE],
    promise: (client) => client.get(`getPremedClassList/${studentId}`)
  };
};

export const getApplicantCourseDropDown = () => {
  return {
    types: [GET_APPLICANT_COURSE_DROPDOWN_REQUESTED, GET_APPLICANT_COURSE_DROPDOWN_SUCCESS, GET_APPLICANT_COURSE_DROPDOWN_FAILURE],
    promise: (client) => client.get('applicantCourse')
  };
};

export const getApplicantMedicalCourseDropDown = () => {
  return {
    types: [GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_REQUESTED, GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_SUCCESS, GET_APPLICANT_MEDICAL_COURSE_DROPDOWN_FAILURE],
    promise: (client) => client.get('applicantmedicalcourse')
  };
};

export const getApplicantRotationsDropDown = () => {
  return {
    types: [GET_APPLICANT_ROTATION_DROPDOWN_REQUESTED, GET_APPLICANT_ROTATION_DROPDOWN_SUCCESS, GET_APPLICANT_ROTATION_DROPDOWN_FAILURE],
    promise: (client) => client.get('applicantrotations')
  };
};

export const getApplicantStandardizedTestDropDown = () => {
  return {
    types: [GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_REQUESTED, GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_SUCCESS, GET_APPLICANT_STANDARDIZED_TEST_DROPDOWN_FAILURE],
    promise: (client) => client.get('applicantStandardizedTestDropDown')
  };
};

export const getAcademicRecords1List = (studentId) => {
  return {
    types: [GET_ACADEMIC_RECORDS1_LIST_REQUESTED, GET_ACADEMIC_RECORDS1_LIST_SUCCESS, GET_ACADEMIC_RECORDS1_LIST_FAILURE],
    promise: (client) => client.get(`academicRecords1List/${studentId}`)
  };
};

export const saveAcademicRecords1 = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('saveAcademicRecords1', { data })
  };
};

export const getAcademicRecords2List = (studentId) => {
  return {
    types: [GET_ACADEMIC_RECORDS2_LIST_REQUESTED, GET_ACADEMIC_RECORDS2_LIST_SUCCESS, GET_ACADEMIC_RECORDS2_LIST_FAILURE],
    promise: (client) => client.get(`academicRecords2List/${studentId}`)
  };
};

export const saveAcademicRecords2 = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('saveAcademicRecords2', { data })
  };
};

export const getAcademicRecords3List = (studentId) => {
  return {
    types: [GET_ACADEMIC_RECORDS3_LIST_REQUESTED, GET_ACADEMIC_RECORDS3_LIST_SUCCESS, GET_ACADEMIC_RECORDS3_LIST_FAILURE],
    promise: (client) => client.get(`academicRecords3List/${studentId}`)
  };
};

export const saveAcademicRecords3 = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('saveAcademicRecords3', { data })
  };
};

export const getAcademicRecords4List = (studentId) => {
  return {
    types: [GET_ACADEMIC_RECORDS4_LIST_REQUESTED, GET_ACADEMIC_RECORDS4_LIST_SUCCESS, GET_ACADEMIC_RECORDS4_LIST_FAILURE],
    promise: (client) => client.get(`academicRecords4List/${studentId}`)
  };
};

export const saveAcademicRecords4 = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('saveAcademicRecords4', { data })
  };
};

export const getRequestTranscriptList = (id) => {
  return {
    types: [GET_STUDENT_TRANSCRIPT_LIST_REQUESTED, GET_STUDENT_TRANSCRIPT_LIST_SUCCESS, GET_STUDENT_TRANSCRIPT_LIST_FAILURE],
    promise: (client) => client.get(`getRequestedTransciptList/${id}`)
  };
};

export const getStudentTranscriptDetails = (transcriptId) => {
  return {
    types: [GET_STUDENT_TRANSCRIPT_DETAILS_REQUESTED, GET_STUDENT_TRANSCRIPT_DETAILS_SUCCESS, GET_STUDENT_TRANSCRIPT_DETAILS_FAILURE],
    promise: (client) => client.get(`getRequestedTransciptDataById/${transcriptId}`)
  };
};

export const saveStudentTranscript = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('saveStudentTranscript', { data })
  };
};

export const studentReasonDropdown = () => {
  return {
    types: [GET_STUDENT_REASON_REQUEST_REQUESTED, GET_STUDENT_REASON_REQUEST_SUCCESS, GET_STUDENT_REASON_REQUEST_FAILURE],
    promise: (client) => client.get('requestReason')
  };
};

export const getExamNameDropDown = (studentId) => {
  return {
    types: [GET_EXAM_NAME_DROPDOWN_REQUESTED, GET_EXAM_NAME_DROPDOWN_SUCCESS, GET_EXAM_NAME_DROPDOWN_FAILURE],
    promise: (client) => client.get(`examNameDropDown/${studentId}`)
  };
};

export const getFeeNameDropDown = (studentId) => {
  return {
    types: [GET_FEE_NAME_DROPDOWN_REQUESTED, GET_FEE_NAME_DROPDOWN_SUCCESS, GET_FEE_NAME_DROPDOWN_FAILURE],
    promise: (client) => client.get(`feeNameDropDown/${studentId}`)
  };
};

export const getShippingOptions = (countryId) => {
  return {
    types: [GET_SHIPPING_OPTIONS_REQUESTED, GET_SHIPPING_OPTIONS_SUCCESS, GET_SHIPPING_OPTIONS_FAILURE],
    promise: (client) => client.get(`shippingOptions/${countryId}`)
  };
};

export const getCourseTranscriptList = (courseId) => {
  return {
    types: [GET_COURSE_TRANSCRIPT_DATA_REQUESTED, GET_COURSE_TRANSCRIPT_DATA_SUCCESS, GET_COURSE_TRANSCRIPT_DATA_FAILURE],
    promise: (client) => client.get(`viewTranscriptCourse/${courseId}`)
  };
};
export const getClinicalRosterList = (params) => {
  return {
    types: [GET_CLINICAL_ROSTER_LIST_REQUESTED, GET_CLINICAL_ROSTER_LIST_SUCCESS, GET_CLINICAL_ROSTER_LIST_FAILURE],
    promise: (client) => client.get('getClinicalRosterList', { params })
  };
};

export const getThirdPartyList = (params) => {
  return {
    types: [GET_THIRD_PARTY_LIST_REQUESTED, GET_THIRD_PARTY_LIST_SUCCESS, GET_THIRD_PARTY_LIST_FAILURE],
    promise: (client) => client.get('getThirdPartyList', { params })
  };
};

export const getThirdParty = (id) => {
  return {
    types: [GET_THIRD_PARTY_REQUESTED, GET_THIRD_PARTY_SUCCESS, GET_THIRD_PARTY_FAILURE],
    promise: (client) => client.get(`getThirdParty/${id}`, { id })
  };
};

export const addOrEditThirdParty = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addOrEditThirdParty', { data })
  };
};

export const getStatusChangeList = (studentId) => {
  return {
    types: [GET_STATUS_CHANAGE_LIST_REQUESTED, GET_STATUS_CHANAGE_LIST_SUCCESS, GET_STATUS_CHANAGE_LIST_FAILURE],
    promise: (client) => client.get(`getStatusChangeList/${studentId}`)
  };
};

export const getStatusChangeActivityLogList = (studentId) => {
  return {
    types: [GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_REQUESTED, GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_SUCCESS, GET_STATUS_CHANAGE_ACTIVITY_LOG_LIST_FAILURE],
    promise: (client) => client.get(`getStatusChangeActivityLogList/${studentId}`)
  };
};

export const updateStatusChange = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateStatusChange', { data })
  };
};

export const addUpdateOtherDeatils = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateOtherDeatils', { data })
  };
};

export const getOtherDetails = (studentId) => {
  return {
    types: [GET_OTHER_DETAILS_REQUESTED, GET_OTHER_DETAILS_SUCCESS, GET_OTHER_DETAILS_FAILURE],
    promise: (client) => client.get(`getOtherDetails/${studentId}`)
  };
};

export const addOrUpdateStudentDegree = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addOrUpdateStudentDegree', { data })
  };
};

export const getStudentDegreeListByStudentId = (studentId) => {
  return {
    types: [GET_DEGREE_REQUESTED, GET_DEGREE_SUCCESS, GET_DEGREE_FAILURE],
    promise: (client) => client.get(`getStudentDegreeListByStudentId/${studentId}`)
  };
};

export const getStudentEstimationValue = (studentId) => {
  return {
    types: [GET_ESTIMATED_VALUE_REQUESTED, GET_ESTIMATED_VALUE_SUCCESS, GET_ESTIMATED_VALUE_FAILURE],
    promise: (client) => client.get(`getStudentEstimationValue/${studentId}`)
  };
};

export const addPaySeatDeposit = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addPaySeatDeposit', { data })
  };
};

export const getStudentAccountingInfo = (studentId) => {
  return {
    types: [GET_ACCOUNTING_INFO_REQUESTED, GET_ACCOUNTING_INFO_SUCCESS, GET_ACCOUNTING_INFO_FAILURE],
    promise: (client) => client.get(`getStudentAccountingInfo/${studentId}`)
  };
};

export const getStudentAccountingMoreInfo = (studentId) => {
  return {
    types: [GET_ACCOUNTING_OTHER_INFO_REQUESTED, GET_ACCOUNTING_OTHER_INFO_SUCCESS, GET_ACCOUNTING_OTHER_INFO_FAILURE],
    promise: (client) => client.get(`getStudentAccountingMoreInfo/${studentId}`)
  };
};

export const updateStudentAccountingInfo = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateStudentAccountingInfo', { data })
  };
};

export const updateStudentAccountingOtherInfo = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateStudentAccountingOtherInfo', { data })
  };
};

export const malPracticeInsuranceList = (studentId) => {
  return {
    types: [GET_MALPRACTICE_INSURANCE_LIST_REQUESTED, GET_MALPRACTICE_INSURANCE_LIST_SUCCESS, GET_MALPRACTICE_INSURANCE_LIST_FAILURE],
    promise: (client) => client.get(`malPracticeInsuranceList/${studentId}`)
  };
};
export const addMalPracticeInsurance = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addMalPracticeInsurance', { data })
  };
};
export const getMalPracticeInusrance = (insuranceId) => {
  return {
    types: [GET_MALPRACTICE_INSURANCE_BY_ID_REQUESTED, GET_MALPRACTICE_INSURANCE_BY_ID_SUCCESS, GET_MALPRACTICE_INSURANCE_BY_ID_FAILURE],
    promise: (client) => client.get(`getMalPracticeInusrance/${insuranceId}`)
  };
};

export const addGraduationSurveyForm = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('submitGraduationSurvey', { data })
  };
};

export const getGraduationSurveyDetails = (params) => {
  return {
    types: [GET_GRADUATION_SURVEY_FORM_REQUESTED, GET_GRADUATION_SURVEY_FORM_SUCCESS, GET_GRADUATION_SURVEY_FORM_FAILURE],
    promise: (client) => client.get('graduationSurveyData', { params })
  };
};

export const getGraduationSurveyList = (params) => {
  return {
    types: [GET_GRADUATION_SURVEY_LIST_REQUESTED, GET_GRADUATION_SURVEY_LIST_SUCCESS, GET_GRADUATION_SURVEY_LIST_FAILURE],
    promise: (client) => client.get('graduationSurveyList', { params })
  };
};

export const submitAgreeCourse = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('studentCourseAgreeNextClass', { params })
  };
};
export const getAlumniList = (params) => {
  return {
    types: [GET_ALUMINI_LIST_REQUESTED, GET_ALUMINI_LIST_SUCCESS, GET_ALUMINI_LIST_FAILURE],
    promise: (client) => client.get('getAlumniList', { params })
  };
};

export const alumniSendEmail = (emailStr) => {
  return {
    types: [ADD_ALUMNI_EMAIL_REQUESTED, ADD_ALUMNI_EMAIL_SUCCESS, ADD_ALUMNI_EMAIL_FAILURE],
    promise: (client) => client.post(`alumniSendEmail/${emailStr}`)
  };
};

export const getDegreeList = () => {
  return {
    types: [GET_DEGREE_LIST_REQUESTED, GET_DEGREE_LIST_SUCCESS, GET_DEGREE_LIST_FAILURE],
    promise: (client) => client.get('getDegreeList')
  };
};

export const studentActivityLog = (data) => {
  return {
    types: [STUDENT_ACTIVITY_LOG_REQUESTED, STUDENT_ACTIVITY_LOG_SUCCESS, STUDENT_ACTIVITY_LOG_FAILURE],
    promise: (client) => client.get('studentActivityLog', { params: data })
  };
};

export const getNextStageClassesList = (studentId) => {
  return {
    types: [GET_NEXT_STAGE_CLASSES_REQUESTED, GET_NEXT_STAGE_CLASSES_SUCCESS, GET_NEXT_STAGE_CLASSES_FAILURE],
    promise: (client) => client.get(`nextStageClasseslist/${studentId}`)
  };
};

export const getStudentTranscriptList = (studentId) => {
  return {
    types: [GET_NEXT_STAGE_CLASSES_REQUESTED, TRANSCRIPT_LIST_STUDENT_SUCCESS, GET_NEXT_STAGE_CLASSES_FAILURE],
    promise: (client) => client.get(`transcriptPdfList/${studentId}`)
  };
};

export const getTranscriptFullRecords = (data) => {
  return {
    types: [GET_NEXT_STAGE_CLASSES_REQUESTED, GET_TRANSCRIPT_FULL_DATA_SUCCESS, GET_NEXT_STAGE_CLASSES_FAILURE],
    promise: (client) => client.post('transciptFullPdf', { data })
  };
};

export const creditsRefund = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('creditRefund', { data })
  };
};
export const holdingCreditsRefund = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('holdingCreditsRefund', { data })
  };
};

export const getRefundTypeDropdown = () => {
  return {
    types: [REFUND_TYPE_DROPDOWN_REQUESTED, REFUND_TYPE_DROPDOWN_SUCCESS, REFUND_TYPE_DROPDOWN_FAILURE],
    promise: (client) => client.get('refundType')
  };
};

export const getCreditsList = (id) => {
  return {
    types: [CREDIT_LIST_REQUESTED, CREDIT_LIST_SUCCESS, CREDIT_LIST_FAILURE],
    promise: (client) => client.get(`creditsList/${id}`)
  };
};

export const getCreditDetails = (id, paymentId) => {
  return {
    types: [CREDIT_DETAILS_REQUESTED, CREDIT_DETAILS_SUCCESS, CREDIT_DETAILS_FAILURE],
    promise: (client) => client.get(`creditsDetails/${id}/${paymentId}`)
  };
};

export const getScholarshipViewDetails = (id, paymentId) => {
  return {
    types: [INVOICE_DETAILS_REQUESTED, INVOICE_DETAILS_SUCCESS, INVOICE_DETAILS_FAILURE],
    promise: (client) => client.get(`invoiceScholarShipView/${id}/${paymentId}`)
  };
};

export const getnoInVoiceViewViewDetails = (id, paymentId) => {
  return {
    types: [NO_INVOICE_DETAILS_REQUESTED, NO_INVOICE_DETAILS_SUCCESS, NO_INVOICE_DETAILS_FAILURE],
    promise: (client) => client.get(`noInVoiceView/${id}/${paymentId}`)
  };
};

export const creditDelete = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('creditDelete', { data })
  };
};

export const getHoldingCreditDetails = (id) => {
  return {
    types: [HOLDING_CREDIT_DETAILS_REQUESTED, HOLDING_CREDIT_DETAILS_SUCCESS, HOLDING_CREDIT_DETAILS_FAILURE],
    promise: (client) => client.get(`holdingCreditList/${id}`)
  };
};

export const getPaymentDropdown = () => {
  return {
    types: [PAYMENT_TYPE_DROPDOWN_REQUESTED, PAYMENT_TYPE_DROPDOWN_SUCCESS, PAYMENT_TYPE_DROPDOWN_FAILURE],
    promise: (client) => client.get('directPaymentOptions')
  };
};

export const directPayment = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('directPayment', { data, isMultiPart: true })
  };
};

export const cardPayment = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('creditPayment', { data })
  };
};

export const docuSignForm = (data) => {
  return {
    types: [DOCUSIGN_FORM_REQUESTED, DOCUSIGN_FORM_SUCCESS, DOCUSIGN_FORM_FAILURE],
    promise: (client) => client.post('docusignForm/', { data })
  };
};

export const getDocuSignDocument = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('graduationDocument', { data })
  };
};

export const scholarShipUpdateReferenceNumber = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post(`updateRefNumber/?paymentId=${data.paymentId}&referenceNumber=${data.referenceNumber}&studentId=${data.studentId}`)
  };
};

export const getStudentCreditDetails = (studentId) => {
  return {
    types: [GET_INVOICEP_CREDIT_DETAILS_REQUESTED, GET_INVOICEP_CREDIT_DETAILS_SUCCESS, GET_INVOICEP_CREDIT_DETAILS_FAILURE],
    promise: (client) => client.get(`getStudentCreditDetails/${studentId}`)
  };
};

export const getStudentInvoiceList = (params) => {
  return {
    types: [GET_INVOICEP_LIST_REQUESTED, GET_INVOICEP_LIST_SUCCESS, GET_INVOICEP_LIST_FAILURE],
    promise: (client) => client.get('getStudentInvoiceList', { params })
  };
};

export const getInvoiceDetails = (invoiceId) => {
  return {
    types: [GET_INVOICEP_DETAILS_REQUESTED, GET_INVOICEP_DETAILS_SUCCESS, GET_INVOICEP_DETAILS_FAILURE],
    promise: (client) => client.get(`getInvoiceDetails/${invoiceId}`)
  };
};

export const getCancelledInvoiceList = (params) => {
  return {
    types: [GET_CANCELLED_INVOICEP_LIST_REQUESTED, GET_CANCELLED_INVOICEP_LIST_SUCCESS, GET_CANCELLED_INVOICEP_LIST_FAILURE],
    promise: (client) => client.get(`getCancelledInvoiceList/${params?.studentId}`, { params })
  };
};

export const updateInvoiceDueDate = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateInvoiceDueDate', { data })
  };
};

export const updateInvoiceStage = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateInvoiceStage', { data })
  };
};

export const getAccountingLedgerList = (studentId) => {
  return {
    types: [GET_ACCOUNT_LEDGER_LIST_REQUESTED, GET_ACCOUNT_LEDGER_LIST_SUCCESS, GET_ACCOUNT_LEDGER_LIST_FAILURE],
    promise: (client) => client.get(`getAccountingLedgerList/${studentId}`)
  };
};

export const cancelInvoice = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('cancelInvoice', { data })
  };
};

export const updateFinancialCleared = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateFinancialCleared', { data })
  };
};

export const getInvoiceType = (studentId) => {
  return {
    types: [GET_INVOICEP_TYPE_REQUESTED, GET_INVOICEP_TYPE_SUCCESS, GET_INVOICEP_TYPE_FAILURE],
    promise: (client) => client.get(`getInvoiceType/${studentId}`)
  };
};

export const getScholarshipList = (params) => {
  return {
    types: [GET_INVOICEP_SCHOLARSHIP_LIST_REQUESTED, GET_INVOICEP_SCHOLARSHIP_LIST_SUCCESS, GET_INVOICEP_SCHOLARSHIP_LIST_FAILURE],
    promise: (client) => client.get('getScholarshipList', { params })
  };
};

export const getPaymentList = (params) => {
  return {
    types: [GET_INVOICEP_PAYMENT_LIST_REQUESTED, GET_INVOICEP_PAYMENT_LIST_SUCCESS, GET_INVOICEP_PAYMENT_LIST_FAILURE],
    promise: (client) => client.get('getPaymentList', { params })
  };
};

export const getInvoiceHead = (invoiceType) => {
  return {
    types: [GET_INVOICEP_HEAD_REQUESTED, GET_INVOICEP_HEAD_SUCCESS, GET_INVOICEP_HEAD_FAILURE],
    promise: (client) => client.get(`getInvoiceHead/${invoiceType}`)
  };
};

export const createInvoice = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('createInvoice', { data })
  };
};

export const getInvoiceAmount = (params) => {
  return {
    types: [GET_INVOICEP_AMOUNT_REQUESTED, GET_INVOICEP_AMOUNT_SUCCESS, GET_INVOICEP_AMOUNT_FAILURE],
    promise: (client) => client.get('getInvoiceAmount', { params })
  };
};

export const getEstimateTypeList = () => {
  return {
    types: [GET_INVOICEP_ESTIMATE_TYPE_REQUESTED, GET_INVOICEP_ESTIMATE_TYPE_SUCCESS, GET_INVOICEP_ESTIMATE_TYPE_FAILURE],
    promise: (client) => client.get('getEstimateTypeList')
  };
};

export const getStudentSeatDeposits = (studentId) => {
  return {
    types: [GET_INVOICEP_ESTIMATE_REQUESTED, GET_INVOICEP_ESTIMATE_SUCCESS, GET_INVOICEP_ESTIMATE_FAILURE],
    promise: (client) => client.get(`getStudentSeatDeposits/${studentId}`)
  };
};

export const createEstimate = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('createEstimate', { data })
  };
};

export const getPayPaymentList = (studentId) => {
  return {
    types: [GET_INVOICEP_PAY_PAYMENT_LIST_REQUESTED, GET_INVOICEP_PAY_PAYMENT_LIST_SUCCESS, GET_INVOICEP_PAY_PAYMENT_LIST_FAILURE],
    promise: (client) => client.get(`getPayPaymentList/${studentId}`)
  };
};
export const payInvoice = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('payInvoice', { data })
  };
};
export const getEstimateCourse = (studentId) => {
  return {
    types: [GET_ESTIMATE_COURSE_REQUESTED, GET_ESTIMATE_COURSE_SUCCESS, GET_ESTIMATE_COURSE_FAILURE],
    promise: (client) => client.get(`getEstimateCourse/${studentId}`)
  };
};
export const getEstimateById = (estimateId) => {
  return {
    types: [GET_ESTIMATE_BY_ID_REQUESTED, GET_ESTIMATE_BY_ID_SUCCESS, GET_ESTIMATE_BY_ID_FAILURE],
    promise: (client) => client.get(`getEstimatesById/${estimateId}`)
  };
};
export const getPaymentDetails = (params) => {
  return {
    types: [GET_INVOICEP_PAYMENT_DETAILS_REQUESTED, GET_INVOICEP_PAYMENT_DETAILS_SUCCESS, GET_INVOICEP_PAYMENT_DETAILS_FAILURE],
    promise: (client) => client.get('getPaymentDetails', { params })
  };
};
export const cancelPayment = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('cancelPayment', { data })
  };
};
export const getEstimatePaymentDetails = (params) => {
  return {
    types: [GET_ESTIMATE_PAYMENT_DETAILS_REQUESTED, GET_ESTIMATE_PAYMENT_DETAILS_SUCCESS, GET_ESTIMATE_PAYMENT_DETAILS_FAILURE],
    promise: (client) => client.get('getEstimatePaymentDetails', { params })
  };
};
export const payEstimate = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('payEstimate', { data })
  };
};
export const financialStatusChange = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('financialStatusChange', { params })
  };
};
export const getPayEstimateList = (params) => {
  return {
    types: [GET_PAY_ESTIMATE_LIST_REQUESTED, GET_PAY_ESTIMATE_LIST_SUCCESS, GET_PAY_ESTIMATE_LIST_FAILURE],
    promise: (client) => client.get('getPayEstimateList', { params })
  };
};
export const updatePartialPay = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('updatePartialPay', { data })
  };
};
export const cancelEstimate = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('cancelEstimate', { data })
  };
};

export const sendStudentEmail = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('sendStudentEmail', { data, isMultiPart: true })
  };
};

export const studentRotationControllerList = (rotationId) => {
  return {
    types: [GET_STUDENT_ROTATION_CONTRO_LIST_REQUESTED, GET_STUDENT_ROTATION_CONTRO_LIST_SUCCESS, GET_STUDENT_ROTATION_CONTRO_LIST_FAILURE],
    promise: (client) => client.get(`studentRotationControllerList/${rotationId}`)
  };
};

export const getPaymentStatement = (studentId) => {
  return {
    types: [GET_PAYMENT_STATEMENT_REQUESTED, GET_PAYMENT_STATEMENT_SUCCESS, GET_PAYMENT_STATEMENT_FAILURE],
    promise: (client) => client.get(`getPaymentStatement/${studentId}`)
  };
};

export const getOldPayments = (params) => {
  return {
    types: [GET_OLD_PAYMENTS_REQUESTED, GET_OLD_PAYMENTS_SUCCESS, GET_OLD_PAYMENTS_FAILURE],
    promise: (client) => client.get('getOldPayments', { params })
  };
};
export const rotationPayNow = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('rotationPayNow', { data })
  };
};
export const getRotationPaymentInfo = (studentId) => {
  return {
    types: [GET_ROTATION_PAYMENTS_INFO_REQUESTED, GET_ROTATION_PAYMENTS_INFO_SUCCESS, GET_ROTATION_PAYMENTS_INFO_FAILURE],
    promise: (client) => client.get(`getRotationPaymentInfo/${studentId}`)
  };
};
export const deleteDegreeById = (id) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del(`deleteDegreeById/${id}`)
  };
};

export const getFcmGetDeviceDetails = (params) => {
  return {
    types: [GET_FCM_DEVICE_DETAILS_REQUESTED, GET_FCM_DEVICE_DETAILS_SUCCESS, GET_FCM_DEVICE_DETAILS_FAILURE],
    promise: (client) => client.get('getFcmGetDeviceDetails', { params })
  };
};

export const addAppeal = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('appealAdd', { data, isMultiPart: true })
  };
};

export const approveAppeal = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('approveAppeal', { params })
  };
};

export const acceptDisclosure = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('acceptDisclosure', { params })
  };
};

export const getAppealRequestList = (params) => {
  return {
    types: [GET_APPEAL_LIST_DETAILS_REQUESTED, GET_APPEAL_LIST_DETAILS_SUCCESS, GET_APPEAL_LIST_DETAILS_FAILURE],
    promise: (client) => client.get('appealList', { params })
  };
};

export const getAppealDetails = (id, isAdmin) => {
  return {
    types: [GET_APPEAL_DETAILS_DETAILS_REQUESTED, GET_APPEAL_DETAILS_DETAILS_SUCCESS, GET_APPEAL_DETAILS_DETAILS_FAILURE],
    promise: (client) => client.get(`getAppealDetails/${id}/${isAdmin}`)
  };
};

export const saveFinancialAid = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentLoanFinancialAid', { data })
  };
};

export const getStudentLoanById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_LOAN_BY_ID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getStudentLoanById/${id}`)
  };
};

export const getStudentLoanList = (studentId) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_LOAN_LIST_BY_SID_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getStudentLoanList/${studentId}`)
  };
};

export const deleteStudentLoan = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.del(`deletStudentLoanById/${id}`)
  };
};

export const getStudentPlanRotationList = (studentId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_LIST_REQUESTED, GET_STUDENT_PLAN_ROTATION_LIST_SUCCESS, GET_STUDENT_PLAN_ROTATION_LIST_FAILURE],
    promise: (client) => client.get(`getStudentPlanRotationList/${studentId}`)
  };
};

export const getStudentPlanRotationById = (rotationDetailsId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_STUDENT_PLAN_ROTATION_SUCCESS, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.get(`getStudentPlanRotationById/${rotationDetailsId}`)
  };
};

export const acceptStudentPlanRotation = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('acceptStudentPlanRotation', { params })
  };
};

export const getProblemList = (rotationId) => {
  return {
    types: [GET_PROBLEM_LIST_REQUESTED, GET_PROBLEM_LIST_SUCCESS, GET_PROBLEM_LIST_FAILURE],
    promise: (client) => client.get(`problemList/${rotationId}`)
  };
};

export const getProblemsData = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PROBLEM_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('problemSearch', { data })
  };
};

export const getOptionList = (problemId) => {
  return {
    types: [GET_OPTION_LIST_REQUESTED, GET_OPTION_LIST_SUCCESS, GET_OPTION_LIST_FAILURE],
    promise: (client) => client.get(`optionList/${problemId}`)
  };
};

export const getOptionsData = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_OPTION_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('optionSearch', { data })
  };
};

export const getPreceptorData = (hospitalId) => {
  return {
    types: [GET_PRECEPTOR_DATA_REQUESTED, GET_PRECEPTOR_DATA_SUCCESS, GET_PRECEPTOR_DATA_FAILURE],
    promise: (client) => client.get(`getPreceptor/${hospitalId}`)
  };
};

export const getStudentNotifications = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_NOTIFICATIONS_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`studentNotification/${id}`)
  };
};
export const addSetUpRecurring = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addSetUpRecurring', { data })
  };
};

export const getRCDetails = (studentId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_STUDENT_RC_DETAILS_SUCCESS, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.get(`getRCDetails/${studentId}`)
  };
};

export const deleteRCDetails = (params) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.del('deleteRCDetails', { params })
  };
};

export const studentSmsEmailList = (studentId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_STUDENT_SMS_EMAIL_SUCCESS, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.get(`studentSmsEmailList/${studentId}`)
  };
};
export const getStudentSmsDetails = (studentId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_STUDENT_SMS_DETAILS_SUCCESS, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.get(`getStudentSmsDetails/${studentId}`)
  };
};

export const updateStudentSMS = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateStudentSMS', { data })
  };
};
export const sendSMSCredentials = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('sendSMSCredentials', { data })
  };
};

export const getCaseReportDetails = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CASE_REPORT_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('reportsDetails', { data })
  };
};

export const getStudentEmailView = (studentId) => {
  return {
    types: [STUDENT_EMAIL_VIEW_REQUESTED, STUDENT_EMAIL_VIEW_SUCCESS, STUDENT_EMAIL_VIEW_FAILURE],
    promise: (client) => client.get(`getStudentEmailView/${studentId}`)
  };
};

export const postStudentEmail = (data) => {
  return {
    types: [STUDENT_ADD_REQUESTED, STUDENT_ADD_SUCCESS, STUDENT_ADD_FAILURE],
    promise: (client) => client.post('postStudentEmail/', { data, isMultiPart: true })

  };
};

export const getStudentEmail = (id) => {
  return {
    types: [STUDENT_PERSONAL_DETAILS_REQUESTED, GET_STUDENT_EMAIL_SUCCESS, STUDENT_PERSONAL_DETAILS_FAILURE],
    promise: (client) => client.get(`getStudentEmail/${id}`)
  };
};

export const addEvaluation = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addEvaluation', { data })
  };
};
export const getPreceptorRotationList = (data) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_PRECEPTOR_ROTATION_LIST, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.post('getPreceptorRotationList', { data })
  };
};

export const getPreceptorRotationDetails = (rotationDetailsId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_PRECEPTOR_ROTATION_DETAILS, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.get(`getPreceptorRotationDetails/${rotationDetailsId}`)
  };
};

export const getSurveyRedirectResponse = (studentId, surveyType) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getRedirectUrlForSurvey/${studentId}/${surveyType}`)
  };
};

export const getEvaluationRedirectResponse = (studentId, surveyType, rid, userId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getRedirectUrlForSurvey/${rid}/${surveyType}/${studentId}/${userId}`)
  };
};

export const saveGraduationAlchemer = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveGraduationAlchmer', { params })
  };
};

export const selectedFilterData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_FILTER_DATA,
      payload: data,
    });
  };
};

export const getPreceptorDetails = (userId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_PRECEPTOR_USER_DETAILS, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.get(`getPreceptorDetails/${userId}`)
  };
};

export const sendStudentMailList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('sendStudentMail', { data, isMultiPart: true })
  };
};

export const forceNotification = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('forceNotification', { data })
  };
};

export const getForceNotification = (userId) => {
  return {
    types: [GET_STUDENT_PLAN_ROTATION_REQUESTED, GET_FORCE_NOTIFICATION_SUCCESS, GET_STUDENT_PLAN_ROTATION_FAILURE],
    promise: (client) => client.get(`getForceNotification/${userId}`)
  };
};

export const getResponseUrl = (type, rotationId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getResponseUrl/${rotationId}/${type}`)
  };
};

export const getPreceptorEvalResponseUrl = (type, rotationId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getPrecEvalResponseUrl/${rotationId}/${type}`)
  };
};

export const getNCResponseUrl = (type, classId, studentId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getNCResponseUrl/${classId}/${type}/${studentId}`)
  };
};

export const getNCSubmitData = (type, classId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getNCSubmitedResponseUrl/${classId}/${type}`)
  };
};

export const getViewAsStudent = (sid) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getViewAsStudent/${sid}`)
  };
};

export const getParentDetail = (studentId) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PARENT_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getParentDetail/${studentId}`)
  };
};

export const getParentDetailList = (studentId) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_PARENT_DETAILS_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getParentDetailList/${studentId}`)
  };
};

export const addUpdateParentDetail = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateParentDetail', { data })
  };
};

export const updateCreditCreatedOn = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, UPDATE_CREATED_ON_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateCreditCreatedOn', { params })
  };
};
export const updateScholarshipCreatedOn = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, UPDATE_CREATED_ON_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateScholarshipCreatedOn', { params })
  };
};
export const updateNoInvoiceCreatedOn = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, UPDATE_CREATED_ON_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateNoInvoiceCreatedOn', { params })
  };
};
export const updateInvoiceDate = (params) => {
  return {
    types: [UPDATE_INVOICE_DATE_REQUESTED, UPDATE_INVOICE_DATE_SUCCESS, UPDATE_INVOICE_DATE_FAILURE],
    promise: (client) => client.post('updateInvoiceDate', { params })
  };
};

export const getPreceptorResponseUrl = (surveyType, rid, sid) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getPreceptorList/${rid}/${surveyType}/${sid}`)
  };
};

export const updateTermAndConditions = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateTermAndConditions', { params })
  };
};

export const addImmigration = (data, params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addImmigration', { params, data, isMultiPart: true })
  };
};

export const getImmigrationDetails = (sid) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_IMMIGRATION_DETAILS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getImmigrationDetails/${sid}`)
  };
};

export const getPreceptorFdEvaluation = (rid, surveyType) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getRotationFeedbackUrl/${rid}/${surveyType}`)
  };
};

export const getSurveyById = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SURVEY_RESPONSE_DATA_BY_ID, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`approveFeedback/${id}`)
  };
};

export const approveFeedBackSurvey = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('approveFeedback', { data })
  };
};

export const getFeedBackResponseUrl = (type, rotationId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getFeedbackResponseUrl/${type}/${rotationId}`)
  };
};

export const getStudentSmsNotification = (studentId) => {
  return {
    types: [GET_GRADUATION_SURVEY_LIST_REQUESTED, GET_STUDENT_SMS_NOTIFICATION, GET_GRADUATION_SURVEY_LIST_FAILURE],
    promise: (client) => client.get(`getStudentSmsNotification/${studentId}`)
  };
};

export const postStudentSmsNotification = (data) => {
  return {
    types: [GET_GRADUATION_SURVEY_LIST_REQUESTED, POST_STUDENT_SMS_NOTIFICATION, GET_GRADUATION_SURVEY_LIST_FAILURE],
    promise: (client) => client.post('postStudentSmsNotification', { data })
  };
};

export const multiDocumentUpload = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('documentMultiple', { data, isMultiPart: true })
  };
};

export const educationStudentAdmission = (studentId, type) => {
  return {
    types: [ADD_STUDENT_REQUESTED, GET_STUDENT_EDUCATIONAL_ADMISSION_INFO, ADD_STUDENT_FAILURE],
    promise: (client) => client.get(`educationStudentAdmission/${studentId}/${type}`)
  };
};

export const billingStudentAdmission = (studentId, type) => {
  return {
    types: [ADD_STUDENT_REQUESTED, GET_STUDENT_BILLING_ADMISSION_INFO, ADD_STUDENT_FAILURE],
    promise: (client) => client.get(`educationStudentAdmission/${studentId}/${type}`)
  };
};

export const deleteStudentAdmission = (infoId) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post(`deleteEducationalInfo/${infoId}`)
  };
};

export const getBillingInfo = (studentId) => {
  return {
    types: [ADD_STUDENT_REQUESTED, GET_STUDENT_BILLING_ADMISSION_LIST, ADD_STUDENT_FAILURE],
    promise: (client) => client.get(`getBillingInfo/${studentId}`)
  };
};

export const uploadClinicalLogPdf = (data) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.post('clinicalLogUpload', { data, isMultiPart: true })
  };
};

export const saveSurveyResponseEval = (rotationId, type, isClinical) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.get(`surveyResponsePending/${rotationId}/${type}/${isClinical}`)
  };
};

export const saveSurveyGraduationPending = (studentId) => {
  return {
    types: [ADD_STUDENT_REQUESTED, ADD_STUDENT_SUCCESS, ADD_STUDENT_FAILURE],
    promise: (client) => client.get(`graduationSurveyPending/${studentId}`)
  };
};

export const clinicalLogsPdf = (clinicalId, date) => {
  return {
    types: [ADD_STUDENT_REQUESTED, GET_CLINICAL_LOGS_INFO, ADD_STUDENT_FAILURE],
    promise: (client) => client.get(`clinicalLogsPdf/${clinicalId}/${date}`)
  };
};

export const semesterDefermentLog = (leadId) => {
  return {
    types: [ADD_STUDENT_REQUESTED, GET_SEMESTER_DEFERMENT_DATA, ADD_STUDENT_FAILURE],
    promise: (client) => client.get(`getDefermentLogs/${leadId}`)
  };
};

export const getTutorialVideoList = (isStudent) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_TUTORIAL_VIDEO_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getTutorialVideoList/${isStudent}`)
  };
};

export const documentNewDownlaodStudent = (docId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`studentDocumentDownload/${docId}`)
  };
};

export const deleteInvoiceItems = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('deleteStudentInvoiceItem', { params })
  };
};

export const clinicalLogsVerificationPdf = (clinicalId) => {
  return {
    types: [GET_STUDENT_LIST_REQUESTED, GET_CLINICALS_PDF_LOGS_INFO, GET_STUDENT_LIST_FAILURE],
    promise: (client) => client.get(`getDownloadExportClinicalLoginfo/${clinicalId}`)
  };
};

export const notifyAll = (params, data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('notifyAll', { params, data })
  };
};
export const getStudentListNotify = (params) => {
  return {
    types: [GET_STUDENT_NOTIFYLIST_REQUEST, GET_STUDENT_NOTIFYLIST_SUCCESS, GET_STUDENT_NOTIFYLIST_FAILURE],
    promise: (client) => client.get('getStudentListNotify', { params })
  };
};
export const getNoInvoicePaymentList = (params) => {
  return {
    types: [GET_NO_INVOICE_PAYMENT_LIST_REQUESTED, GET_NO_INVOICE_PAYMENT_LIST_SUCCESS, GET_NO_INVOICE_PAYMENT_LIST_FAILURE],
    promise: (client) => client.get('getNoInvoicePaymentList', { params })
  };
};

export const getStudentMed6Loadate = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_STUDENT_MED6_LOA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`studentMed6Loadate/${id}`)
  };
};

export const updateMED6LOADates = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentMed6LoadateUpdate', { data })
  };
};

export const verifyImmigrationDocuments = (docId) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`verifyImmigrationDocuments/${docId}`)
  };
};

export const getNbmeNoOfAttempts = (studentID) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NBME_NO_OF_ATTEMPTS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getNbmeNoOfAttempts/${studentID}`)
  };
};

export const getCreditDetailsCreditView = (studentId, viewTypeId) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CREDIT_DETAILS_CREDIT_VIEW, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`creditDetailsCreditView/${studentId}/${viewTypeId}`)
  };
};

export const getCreditDetailsScholarshipView = (studentId, viewTypeId) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CREDIT_DETAILS_SCHOLARSHIP_VIEW, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`creditDetailsScholarShipView/${studentId}/${viewTypeId}`)
  };
};

export const getCreditDetailsNoInvoiceView = (studentId, viewTypeId) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CREDIT_DETAILS_NO_INVOICE_VIEW, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`creditDetailsNoInvoiceView/${studentId}/${viewTypeId}`)
  };
};

export const updateCreditReferenceNumber = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, UPDATE_CREDIT_DETAILS_INVOICE_REFERENCE_NUMBER, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateCreditDetailsRefNumber', { params })
  };
};

export const getSpecialStageDropdownForCreditReport = () => {
  return {
    types: [GET_NC_SPECIAL_STAGE_REQUESTED, GET_STAGE_DROPDOWN_FOR_CREDIT_REPORT_SUCCESS, GET_NC_SPECIAL_STAGE_FAILURE],
    promise: (client) => client.get('getSpecialStageDropdownForCreditReport')
  };
};

export const getPlanedRotationsHospitals = (data) => {
  return {
    types: [GET_ROTATION_HOSPITAL_REQUESTED, GET_PLANED_ROTATIONS_HOSPITALS_SUCCESS, GET_ROTATION_HOSPITAL_FAILURE],
    promise: (client) => client.post('plannedRotationsHopitals', { data })
  };
};

export const getStudentProfilePicBinary = (id) => {
  return {
    types: [GET_STUDENT_PROFILE_PIC_REQUESTED, GET_STUDENT_PROFILE_PIC_BINARY_SUCCESS, GET_STUDENT_PROFILE_PIC_BINARY_FAILURE],
    promise: (client) => client.get(`getStudentProfilePicBinary/${id}`)
  };
};

export const getExamTypeSub = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_EXAM_TYPE_SUB_SUCCESS, GET_EXAM_TYPE_SUB_FAILURE],
    promise: (client) => client.get('getExamTypeSub', { params })
  };
};

export const getDocusignDocumentList = (id) => {
  return {
    types: [DOCUSIGN_DOCS_REQUESTED, DOCUSIGN_DOCS_SUCCESS, DOCUSIGN_DOCS_FAILURE],
    promise: (client) => client.get(`docusignDocs/${id}`)
  };
};

export const getCreditScholarshipDeletedList = (id) => {
  return {
    types: [CREDIT_LIST_DELETED_REQUESTED, CREDIT_LIST_DELETED_SUCCESS, CREDIT_LIST_DELETED_FAILURE],
    promise: (client) => client.get(`getCreditScholarshipDeletedList/${id}`)
  };
};

export const creditScholarshipDeleted = (viewType, studentId, paymentId) => {
  return {
    types: [CREDIT_LIST_DETAILS_REQUESTED, CREDIT_LIST_DETAILS_SUCCESS, CREDIT_LIST_DETAILS_FAILURE],
    promise: (client) => client.get(`creditScholarshipDeleted/${viewType}/${studentId}/${paymentId}`)
  };
};

export const getAllClassList = () => {
  return {
    types: [GET_ALL_CLASS_LIST_REQUESTED, GET_ALL_CLASS_LIST_SUCCESS, GET_ALL_CLASS_LIST_FAILURE],
    promise: (client) => client.get('getAllClassList')
  };
};

export const studentMcatList = (id) => {
  return {
    types: [MCAT_LIST_REQUESTED, MCAT_LIST_SUCCESS, MCAT_LIST_FAILURE],
    promise: (client) => client.get(`studentMcatList/${id}`)
  };
};

export const studentMcatSave = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('studentMcatSave', { data, isMultiPart: true })
  };
};

export const getMcatDetails = (id) => {
  return {
    types: [MCAT_DETAILS_REQUESTED, MCAT_DETAILS_SUCCESS, MCAT_DETAILS_FAILURE],
    promise: (client) => client.get(`getMcatDetails/${id}`)
  };
};

export const cancelAppeal = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post(`cancelAppeal/${id}`)
  };
};

export const sendDocuSignEmail = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`sendDocuSignEmail/${id}`)
  };
};
