export const ADD_ACTION_REQUESTED = 'exams/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'exams/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'exams/ADD_ACTION_FAILURE';

export const EXAMLIST_SEARCH_SUCCESS = 'exams/EXAMLIST_SEARCH_SUCCESS';

export const GET_KAPLAN_LIST_BY_SEMESTER_ID = 'exams/GET_KAPLAN_LIST_BY_SEMESTER_ID';

export const GET_KAPLAN_LIST_BY_FILE = 'exams/GET_KAPLAN_LIST_BY_FILE';

export const GET_KAPLAN_SEARCH_LIST = 'exams/GET_KAPLAN_SEARCH_LIST';
export const GET_NBME_SHELF_REPORT_REQUESTED = 'exams/GET_NBME_SHELF_REPORT_REQUESTED';
export const GET_NBME_SHELF_REPORT_SUCCESS = 'exams/GET_NBME_SHELF_REPORT_SUCCESS';
export const GET_NBME_SHELF_REPORT_FAILURE = 'exams/GET_NBME_SHELF_REPORT_FAILURE';
export const GET_NBME_SHELF_DETAILED_REPORT_SUCCESS = 'exams/GET_NBME_SHELF_DETAILED_REPORT_SUCCESS';

export const GET_NBME_LIST_BY_FILE = 'exams/GET_NBME_LIST_BY_FILE';

export const GET_NBMECOMP_SEARCH_LIST = 'exams/GET_NBMECOMP_SEARCH_LIST';
export const GET_NBMECOMP_EXAM_LIST = 'exams/GET_NBMECOMP_EXAM_LIST';
export const GET_NBMECOMP_DETAILED_SEARCH_LIST = 'exams/GET_NBMECOMP_DETAILED_SEARCH_LIST';
export const GET_NBMECOMP_LIST_BY_FILE = 'exams/GET_NBMECOMP_LIST_BY_FILE';

export const GET_RETAKE_EXAM_DROPDOWN = 'exams/GET_RETAKE_EXAM_DROPDOWN';
export const GET_RETAKE_ROTATION_EXAM_DROPDOWN = 'exams/GET_RETAKE_ROTATION_EXAM_DROPDOWN';
export const GET_RETAKE_EXAM_STUDENT_LIST = 'exams/GET_RETAKE_EXAM_STUDENT_LIST';
export const RETAKE_EXAM_STUDENT_LIST_FAILURE = 'exams/RETAKE_EXAM_STUDENT_LIST_FAILURE';
export const RETAKE_EXAM_STUDENT_LIST_REQUESTED = 'exams/RETAKE_EXAM_STUDENT_LIST_REQUESTED';
export const GET_RETAKE_EXAM_REQUESTED_STUDENT_LIST = 'exams/GET_RETAKE_EXAM_REQUESTED_STUDENT_LIST';
export const GET_RETAKE_EXAM_CLINICAL_ROTATION_LIST = 'exams/GET_RETAKE_EXAM_CLINICAL_ROTATION_LIST';

export const EXAM_REQUEST_DETAILS = 'exams/EXAM_REQUEST_DETAILS';
export const ADD_PAID_EXAM_REQUESTED = 'exams/ADD_PAID_EXAM_REQUESTED';
export const ADD_PAID_EXAM_SUCCESS = 'exams/ADD_PAID_EXAM_SUCCESS';
export const ADD_PAID_EXAM_FAILURE = 'exams/ADD_PAID_EXAM_FAILURE';

export const GET_BULK_UPDATE_LIST_REQUESTED = 'exams/GET_BULK_UPDATE_LIST_REQUESTED';
export const GET_BULK_UPDATE_LIST_SUCCESS = 'exams/GET_BULK_UPDATE_LIST_SUCCESS';
export const GET_BULK_UPDATE_LIST_FAILURE = 'exams/GET_BULK_UPDATE_LIST_FAILURE';

export const GET_COMPREHENSIVE_BULK_UPDATE_LIST_REQUESTED = 'exams/GET_COMPREHENSIVE_BULK_UPDATE_LIST_REQUESTED';
export const GET_COMPREHENSIVE_BULK_UPDATE_LIST_SUCCESS = 'exams/GET_COMPREHENSIVE_BULK_UPDATE_LIST_SUCCESS';
export const GET_COMPREHENSIVE_BULK_UPDATE_LIST_FAILURE = 'exams/GET_COMPREHENSIVE_BULK_UPDATE_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  kaplanListById: null,
  kaplanListByFile: null,
  kaplanSearchList: null,
  shelfExamsList: null,
  nbmeReport: null,
  nbmeDetailedReport: null,
  nbmeListByFile: null,
  nbmeCompSearchList: null,
  nbmeCompExamList: null,
  nbmeCompDetailedSearchList: null,
  nbmeCompListByFile: null,
  retakeExamDropDownList: null,
  retakeExamRotationDropDownList: null,
  retakeExamStudentList: null,
  studentListLoading: false,
  retakeExamRequestedStudentsList: null,
  retakeExamClinicalRotationList: null,
  requestExamDetails: null,
  loadingPaidExam: false,
  getBulkUpdateListData: null,
  getComprehensiveListData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_KAPLAN_LIST_BY_SEMESTER_ID: {
    return {
      ...state,
      loading: false,
      loaded: true,
      kaplanListById: action.result && action.result.data
    };
  }
  case GET_KAPLAN_LIST_BY_FILE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      kaplanListByFile: action.result && action.result.data
    };
  }
  case GET_KAPLAN_SEARCH_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      kaplanSearchList: action.result && action.result.data
    };
  }
  case GET_NBMECOMP_SEARCH_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      nbmeCompSearchList: action.result && action.result.data
    };
  }
  case GET_NBMECOMP_EXAM_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      nbmeCompExamList: action.result && action.result.data
    };
  }
  case GET_NBMECOMP_DETAILED_SEARCH_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      nbmeCompDetailedSearchList: action.result && action.result.data
    };
  }
  case EXAMLIST_SEARCH_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      shelfExamsList: action.result && action.result.data
    };
  }
  case GET_NBME_SHELF_REPORT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_NBME_SHELF_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      nbmeReport: action.result
    };
  }
  case GET_NBME_SHELF_REPORT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_NBME_SHELF_DETAILED_REPORT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      nbmeDetailedReport: action.result
    };
  }
  case GET_NBME_LIST_BY_FILE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      nbmeListByFile: action.result && action.result.data
    };
  }
  case GET_NBMECOMP_LIST_BY_FILE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      nbmeCompListByFile: action.result && action.result.data
    };
  }
  case GET_RETAKE_EXAM_DROPDOWN: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      retakeExamDropDownList: action.result && action.result.data
    };
  }
  case GET_RETAKE_ROTATION_EXAM_DROPDOWN: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      retakeExamRotationDropDownList: action.result && action.result.data
    };
  }
  case GET_RETAKE_EXAM_STUDENT_LIST: {
    return {
      ...state,
      studentListLoading: false,
      loaded: true,
      error: false,
      retakeExamStudentList: action.result && action.result.data
    };
  }
  case RETAKE_EXAM_STUDENT_LIST_REQUESTED: {
    return {
      ...state,
      studentListLoading: true,
      loaded: false,
      error: false
    };
  }
  case RETAKE_EXAM_STUDENT_LIST_FAILURE: {
    return {
      ...state,
      studentListLoading: false,
      loaded: true,
      error: true
    };
  }
  case GET_RETAKE_EXAM_REQUESTED_STUDENT_LIST: {
    return {
      ...state,
      studentListLoading: false,
      loaded: true,
      error: false,
      retakeExamRequestedStudentsList: action.result && action.result.data
    };
  }
  case GET_RETAKE_EXAM_CLINICAL_ROTATION_LIST: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      retakeExamClinicalRotationList: action.result && action.result.data
    };
  }
  case EXAM_REQUEST_DETAILS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      requestExamDetails: action.result && action.result.data
    };
  }

  case ADD_PAID_EXAM_REQUESTED: {
    return {
      ...state,
      loadingPaidExam: true,
      loaded: false,
    };
  }
  case ADD_PAID_EXAM_SUCCESS: {
    return {
      ...state,
      loadingPaidExam: false,
      loaded: true,
      error: false
    };
  }
  case ADD_PAID_EXAM_FAILURE: {
    return {
      ...state,
      loadingPaidExam: false,
      loaded: true,
      error: true
    };
  }
  case GET_BULK_UPDATE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_BULK_UPDATE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getBulkUpdateListData: action.result?.data
    };
  }
  case GET_BULK_UPDATE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      getBulkUpdateListData: null,
    };
  }
  case GET_COMPREHENSIVE_BULK_UPDATE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_COMPREHENSIVE_BULK_UPDATE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getComprehensiveListData: action.result,
    };
  }
  case GET_COMPREHENSIVE_BULK_UPDATE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getComprehensiveListData: null,
    };
  }
  default: {
    return {
      ...state
    };
  }
  }
};

export const getKaplanListBySemesterId = (params) => {
  return {
    types: [
      ADD_ACTION_REQUESTED,
      GET_KAPLAN_LIST_BY_SEMESTER_ID,
      ADD_ACTION_FAILURE
    ],
    promise: (client) => client.get('getKaplanListBySemesterId', { params })
  };
};

export const uploadFileByKaplan = (data, semester) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_KAPLAN_LIST_BY_FILE, ADD_ACTION_FAILURE],
    promise: (client) => client.post('sendUploadDFile', {
      data,
      params: semester,
      isMultiPart: true
    })
  };
};

export const addNbmeShelf = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addNbmeShelf', { data })
  };
};
export const uploadFileByNbme = (data, semester) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NBME_LIST_BY_FILE, ADD_ACTION_FAILURE],
    promise: (client) => client.post('uploadFileByNbme', {
      data,
      param: semester,
      isMultiPart: true
    })
  };
};

export const addKaplanDiagonistic = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addKaplanDiagonistic', { data })
  };
};

export const getKaplanSearch = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_KAPLAN_SEARCH_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getKaplanSearch', { params })
  };
};

export const getShelfExamsList = () => {
  return {
    types: [ADD_ACTION_REQUESTED, EXAMLIST_SEARCH_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getShelfExamsList/shelf')
  };
};

export const getNbmeShelfReport = (params) => {
  return {
    types: [
      GET_NBME_SHELF_REPORT_REQUESTED,
      GET_NBME_SHELF_REPORT_SUCCESS,
      GET_NBME_SHELF_REPORT_FAILURE
    ],
    promise: (client) => client.get('getNbmeShelfReport', { params })
  };
};
export const getNbmeShelfDetailedReport = (params) => {
  return {
    types: [
      GET_NBME_SHELF_REPORT_REQUESTED,
      GET_NBME_SHELF_DETAILED_REPORT_SUCCESS,
      GET_NBME_SHELF_REPORT_FAILURE
    ],
    promise: (client) => client.get('getNbmeShelfDetailedReport', { params })
  };
};

export const getNbmeCompSearch = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NBMECOMP_SEARCH_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.post('nbmeCompReport', { data })
  };
};

export const getNbmeExamList = (disciplineType) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NBMECOMP_EXAM_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`nbmeCompExamList/${disciplineType}`)
  };
};

export const getNbmeCompDetailedSearch = (data) => {
  return {
    types: [
      ADD_ACTION_REQUESTED,
      GET_NBMECOMP_DETAILED_SEARCH_LIST,
      ADD_ACTION_FAILURE
    ],
    promise: (client) => client.post('nbmeCompDetailedReport', { data })
  };
};

export const uploadNbmeCompFile = (data, semester) => {
  return {
    types: [
      ADD_ACTION_REQUESTED,
      GET_NBMECOMP_LIST_BY_FILE,
      ADD_ACTION_FAILURE
    ],
    promise: (client) => client.post('nbmeCompUploadFile', {
      data,
      params: semester,
      isMultiPart: true
    })
  };
};

export const addNbmeCompFile = (data, semester) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addNbmeCompFile', {
      data,
      params: semester,
      isMultiPart: true
    })
  };
};

export const getRetakeExamDropdownList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RETAKE_EXAM_DROPDOWN, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getRetakeExamDropdown', { params })
  };
};
export const getRetakeRotationExamDropdown = (status) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RETAKE_ROTATION_EXAM_DROPDOWN, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getRetakeRotationExamDropdown/${status}`)
  };
};

export const getClinicalRotationDropDownList = (status) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_RETAKE_EXAM_CLINICAL_ROTATION_LIST, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getRetakeExamClinicalRotationList/${status}`)
  };
};

export const getRetakeExamStudentList = (params) => {
  return {
    types: [RETAKE_EXAM_STUDENT_LIST_REQUESTED, GET_RETAKE_EXAM_STUDENT_LIST, RETAKE_EXAM_STUDENT_LIST_FAILURE],
    promise: (client) => client.get('getRetakeExamStudentList', { params })
  };
};
export const getRetakeExamRequestedStudentsList = (params) => {
  return {
    types: [RETAKE_EXAM_STUDENT_LIST_REQUESTED, GET_RETAKE_EXAM_REQUESTED_STUDENT_LIST, RETAKE_EXAM_STUDENT_LIST_FAILURE],
    promise: (client) => client.get('getRetakeExamRequestedStudentsList', { params })
  };
};

export const postRetakeExam = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('postRetakeExamStudentDetails', { data })
  };
};

export const addRetakeExamByStudent = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('getRetakeExamByStudent', { data })
  };
};

export const getExamRequestDetails = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, EXAM_REQUEST_DETAILS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getExamRequestDetails/${id}`)
  };
};

export const schduleExamRequest = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('schduleExamRequest', { params })
  };
};

export const paidExamUpdate = (data) => {
  return {
    types: [ADD_PAID_EXAM_REQUESTED, ADD_PAID_EXAM_SUCCESS, ADD_PAID_EXAM_FAILURE],
    promise: (client) => client.post('paidExamUpdate', { data })
  };
};

export const completeRetakeExamRequest = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('retakExamComplete', { data, isMultiPart: true })
  };
};

export const systemAddDocument = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('systemAddDocument', { data, isMultiPart: true })
  };
};

export const systemDocumentUpload = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('systemDocumentUpload', { data, isMultiPart: true })
  };
};

export const rotationAddDocument = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('rotationAddDocument', { data, isMultiPart: true })
  };
};

export const rotationUploadDocument = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('rotationUploadDocument', { data, isMultiPart: true })
  };
};

export const comprehensiveAddDocument = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('comprehensiveAddDocument', { data, isMultiPart: true })
  };
};

export const comprehensiveUploadDocument = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('comprehensiveUploadDocument', { data, isMultiPart: true })
  };
};

export const getBulkUpdateList = (params) => {
  return {
    types: [GET_BULK_UPDATE_LIST_REQUESTED, GET_BULK_UPDATE_LIST_SUCCESS, GET_BULK_UPDATE_LIST_FAILURE],
    promise: (client) => client.get('getBulkUpdateList', { params })
  };
};

export const comprehensiveBulkUploadList = (params) => {
  return {
    types: [GET_COMPREHENSIVE_BULK_UPDATE_LIST_REQUESTED, GET_COMPREHENSIVE_BULK_UPDATE_LIST_SUCCESS, GET_COMPREHENSIVE_BULK_UPDATE_LIST_FAILURE],
    promise: (client) => client.get('comprehensiveBulkUploadList', { params })
  };
};
