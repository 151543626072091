import { combineReducers } from 'redux';
import accessManagement from './accessManagement';
import controlTable from './controlTable';
import leadSettings from './leadSettings';

const adminReducer = combineReducers({
  accessManagement,
  controlTable,
  leadSettings
});

export default adminReducer;
