import { combineReducers } from 'redux';
import lead from './lead';
import student from './student';
import sideMenuChange from './sideMenuChange';

const leadReducer = combineReducers({
  lead,
  sideMenuChange,
  student,
});

export default leadReducer;
