export const ADD_ACTION_REQUESTED = 'accounting/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'accounting/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'accounting/ADD_ACTION_FAILURE';

export const GET_SCHOLARSHIP_LIST_REQUESTED = 'accounting/GET_SCHOLARSHIP_LIST_REQUESTED';
export const GET_SCHOLARSHIP_LIST_SUCCESS = 'accounting/GET_SCHOLARSHIP_LIST_SUCCESS';
export const GET_SCHOLARSHIP_LIST_FAILURE = 'accounting/GET_SCHOLARSHIP_LIST_FAILURE';

export const GET_CREATE_SCHOLARSHIP_LIST_REQUESTED = 'accounting/GET_CREATE_SCHOLARSHIP_LIST_REQUESTED';
export const GET_CREATE_SCHOLARSHIP_LIST_SUCCESS = 'accounting/GET_CREATE_SCHOLARSHIP_LIST_SUCCESS';
export const GET_CREATE_SCHOLARSHIP_LIST_FAILURE = 'accounting/GET_CREATE_SCHOLARSHIP_LIST_FAILURE';

export const GET_ADD_SCHOLARSHIP_LIST_REQUESTED = 'accounting/GET_ADD_SCHOLARSHIP_LIST_REQUESTED';
export const GET_ADD_SCHOLARSHIP_LIST_SUCCESS = 'accounting/GET_ADD_SCHOLARSHIP_LIST_SUCCESS';
export const GET_ADD_SCHOLARSHIP_LIST_FAILURE = 'accounting/GET_CREATE_SCHOLARSHIP_LIST_FAILURE';

export const GET_COURSE_REQUESTED = 'accounting/GET_COURSE_REQUESTED';
export const GET_COURSE_SUCCESS = 'accounting/GET_COURSE_SUCCESS';
export const GET_COURSE_FAILURE = 'accounting/GET_COURSE_FAILURE';

export const GET_REASON_DROPDOWN_LIST_REQUESTED = 'accounting/GET_REASON_DROPDOWN_LIST_REQUESTED';
export const GET_REASON_DROPDOWN_LIST_SUCCESS = 'accounting/GET_REASON_DROPDOWN_LIST_SUCCESS';
export const GET_REASON_DROPDOWN_LIST_FAILURE = 'accounting/GET_CREATE_SCHOLARSHIP_LIST_FAILURE';

export const GET_NEXT_STAGE_LIST_REQUESTED = 'accounting/GET_NEXT_STAGE_LIST_REQUESTED';
export const GET_NEXT_STAGE_LIST_SUCCESS = 'accounting/GET_NEXT_STAGE_LIST_SUCCESS';
export const GET_NEXT_STAGE_LIST_FAILURE = 'accounting/GET_NEXT_STAGE_LIST_FAILURE';

export const GET_PAYMENT_LIST_REQUESTED = 'accounting/GET_PAYMENT_LIST_REQUESTED';
export const GET_PAYMENT_LIST_SUCCESS = 'accounting/GET_PAYMENT_LIST_SUCCESS';
export const GET_PAYMENT_LIST_FAILURE = 'accounting/GET_PAYMENT_LIST_FAILURE';

export const GET_REVENUE_LIST_SUCCESS = 'accouting/GET_REVENUE_LIST_SUCCESS';

export const GET_CLINICAL_REVENUE_LIST_SUCCESS = 'accounting/GET_CLINICAL_REVENUE_LIST_SUCCESS';

export const GET_REVENUE_CLOSER_DATES_SUCCESS = 'accounting/GET_REVENUE_CLOSER_DATES_SUCCESS';

export const GET_NON_CLINICAL_REVENUE_LIST_SUCCESS = 'accounting/GET_NON_CLINICAL_REVENUE_LIST_SUCCESS';

export const GET_REVENUE_VERIFIED_DATES_SUCCESS = 'accounting/GET_REVENUE_VERIFIED_DATES_SUCCESS';

export const ACCEPTED_LEAD_LIST_SUCCESS = 'accounting/ACCEPTED_LEAD_LIST_SUCCESS';

export const UNVERIFIED_PAYMENT_LIST_SUCCESS = 'accounting/UNVERIFIED_PAYMENT_LIST_SUCCESS';

export const STEP1_UNVERIFIED_SHOW = 'accounting/STEP1_UNVERIFIED_SHOW';

export const STEP1_SELECTED_DATA = 'accounting/STEP1_SELECTED_DATA';

export const GET_NOTIFICATIONS_COUNT_SUCCESS = 'accounting/GET_NOTIFICATIONS_COUNT_SUCCESS';

export const ADD_ACTION_LOADING_FAILURE = 'accounting/ADD_ACTION_LOADING_FAILURE';

export const ADD_ACTION_LOADING_REQUESTED = 'accounting/ADD_ACTION_LOADING_REQUESTED';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  loadingFlag: false,
  scholarshipList: null,
  createScholarshipList: null,
  courseData: null,
  addScholarshipList: null,
  reason: null,
  nextStage: null,
  revenueList: null,
  clinicalRevenueList: null,
  closerDateslist: null,
  nonClinicalRevenueList: null,
  verifiedDatesRevenue: null,
  acceptedLeadList: null,
  unverifiedPaymentList: null,
  showUnverifiedStep2: false,
  showUnverifiedSelectedList: [],
  notificationList: null,
  getPaymentsData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_SCHOLARSHIP_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_SCHOLARSHIP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      scholarshipList: action.result && action.result.data,
    };
  }

  case GET_SCHOLARSHIP_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_CREATE_SCHOLARSHIP_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_CREATE_SCHOLARSHIP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      createScholarshipList: action.result && action.result.data,
    };
  }

  case GET_CREATE_SCHOLARSHIP_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_COURSE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_COURSE_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      courseData: action.result && action.result.data,
    };
  }

  case GET_COURSE_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_ADD_SCHOLARSHIP_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_ADD_SCHOLARSHIP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      addScholarshipList: action.result && action.result.data,
    };
  }

  case GET_ADD_SCHOLARSHIP_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_REASON_DROPDOWN_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_REASON_DROPDOWN_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      reason: action.result && action.result.data,
    };
  }

  case GET_REASON_DROPDOWN_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_NEXT_STAGE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,

    };
  }
  case GET_NEXT_STAGE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      nextStage: action.result && action.result.data,
    };
  }

  case GET_NEXT_STAGE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  case GET_REVENUE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      revenueList: action.result && action.result.data
    };
  }
  case GET_CLINICAL_REVENUE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      clinicalRevenueList: action.result && action.result.data
    };
  }
  case GET_REVENUE_CLOSER_DATES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      closerDateslist: action.result && action.result.data
    };
  }
  case GET_NON_CLINICAL_REVENUE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      nonClinicalRevenueList: action.result && action.result.data
    };
  }
  case GET_REVENUE_VERIFIED_DATES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      verifiedDatesRevenue: action.result && action.result.data
    };
  }
  case ACCEPTED_LEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      acceptedLeadList: action.result && action.result.data
    };
  }
  case UNVERIFIED_PAYMENT_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: false,
      unverifiedPaymentList: action.result && action.result.data
    };
  }
  case STEP1_UNVERIFIED_SHOW: {
    return {
      ...state,
      showUnverifiedStep2: action.payload
    };
  }

  case STEP1_SELECTED_DATA: {
    return {
      ...state,
      showUnverifiedSelectedList: action.payload
    };
  }
  case GET_NOTIFICATIONS_COUNT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      notificationList: action.result && action.result.data
    };
  }
  case ADD_ACTION_LOADING_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false,
    };
  }
  case ADD_ACTION_LOADING_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      loaded: true,
      error: true,
    };
  }
  case GET_PAYMENT_LIST_REQUESTED: {
    return {
      ...state,
      loadingFlag: true,
      loaded: false,
      error: false,

    };
  }
  case GET_PAYMENT_LIST_SUCCESS: {
    return {
      ...state,
      loadingFlag: false,
      error: false,
      loaded: true,
      getPaymentsData: action.result && action.result.data,
    };
  }

  case GET_PAYMENT_LIST_FAILURE: {
    return {
      ...state,
      loadingFlag: false,
      error: true,
      loaded: true,
      getPaymentsData: null,
    };
  }
  default: {
    return {
      ...state
    };
  }
  }
};

export const getScholarshipList = (params) => {
  return {
    types: [GET_SCHOLARSHIP_LIST_REQUESTED, GET_SCHOLARSHIP_LIST_SUCCESS, GET_SCHOLARSHIP_LIST_FAILURE],
    promise: (client) => client.get('scholarshipList', { params })
  };
};

export const getCreateScholarshipList = (params) => {
  return {
    types: [GET_CREATE_SCHOLARSHIP_LIST_REQUESTED, GET_CREATE_SCHOLARSHIP_LIST_SUCCESS, GET_CREATE_SCHOLARSHIP_LIST_FAILURE],
    promise: (client) => client.get('createScholarShipList', { params })
  };
};

export const getCourse = () => {
  return {
    types: [GET_COURSE_REQUESTED, GET_COURSE_SUCCESS, GET_COURSE_FAILURE],
    promise: (client) => client.get('accountingCourse')
  };
};

export const addScholarshipList = (params) => {
  return {
    types: [GET_ADD_SCHOLARSHIP_LIST_REQUESTED, GET_ADD_SCHOLARSHIP_LIST_SUCCESS, GET_ADD_SCHOLARSHIP_LIST_FAILURE],
    promise: (client) => client.get('addScholarshipList', { params })
  };
};

export const getReason = (id, type) => {
  return {
    types: [GET_REASON_DROPDOWN_LIST_REQUESTED, GET_REASON_DROPDOWN_LIST_SUCCESS, GET_REASON_DROPDOWN_LIST_FAILURE],
    promise: (client) => client.get(`reasonDropdown/${id}/${type}`)
  };
};

export const getNextStage = (id) => {
  return {
    types: [GET_NEXT_STAGE_LIST_REQUESTED, GET_NEXT_STAGE_LIST_SUCCESS, GET_NEXT_STAGE_LIST_FAILURE],
    promise: (client) => client.get(`nextStage/${id}`)
  };
};

export const createScholarship = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('createScholarship', { data })
  };
};

export const getAccoutingRevenueList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REVENUE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`revenueList/${params.courseType}`)
  };
};

export const getCinicalRevenueList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_CLINICAL_REVENUE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('clinicalRevenueAccounting', { params })
  };
};

export const getRevenueCloserDates = (courseType) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REVENUE_CLOSER_DATES_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`revenueCloserDates/${courseType}`)
  };
};

export const getNonClinicalRevenue = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NON_CLINICAL_REVENUE_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('nonClinicalRevenue', { params })
  };
};

export const getVerifiedDatesRevenue = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_REVENUE_VERIFIED_DATES_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('verifiedDatesRevenue', { params })
  };
};

export const verifyAndCloseRevenue = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('verifyandCloseRevenue', { params })
  };
};

export const getAcceptedLeadList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ACCEPTED_LEAD_LIST_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('acceptedLeadList', { params })
  };
};

export const getUnverifiedList = (data) => {
  return {
    types: [ADD_ACTION_LOADING_REQUESTED, UNVERIFIED_PAYMENT_LIST_SUCCESS, ADD_ACTION_LOADING_FAILURE],
    promise: (client) => client.post('unverifiedPaymentList', { data })
  };
};

export const setStep1UnverifiedSelectedList = (data) => {
  return (dispatch) => {
    dispatch({
      type: STEP1_SELECTED_DATA,
      payload: data,
    });
  };
};

export const step1ShowUnverified = (data) => {
  return (dispatch) => {
    dispatch({
      type: STEP1_UNVERIFIED_SHOW,
      payload: data,
    });
  };
};

export const updateUnverifiedPaymentList = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('updateUnverifiedPaymentList', { data, isMultiPart: true })
  };
};

export const getNottificationCount = () => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_NOTIFICATIONS_COUNT_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('notificationsCount')
  };
};
export const addScholarhipInBulk = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addScholarhipInBulk', { data, isMultiPart: true })
  };
};

export const getPaymentsList = (params) => {
  return {
    types: [GET_PAYMENT_LIST_REQUESTED, GET_PAYMENT_LIST_SUCCESS, GET_PAYMENT_LIST_FAILURE],
    promise: (client) => client.get('getPaymentsList', { params })
  };
};
