import React from 'react';
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap';
import axios from 'axios';
import * as Icon from 'react-feather';
import classnames from 'classnames';
import Autocomplete from '../../../components/@vuexy/autoComplete/AutoCompleteComponent';
import { history } from '../../../history';
import { Link } from 'react-router-dom';
import { getAuthUserId, getAuthUserSessionId, getUserRoleId } from '../../../utility/helpers/helperFunction';
import { APPConfig } from '../../../constants';
import NotificationPayent from '../../../components/LandingPage/Header/NotiicationsPayment';
import { bindActionCreators } from 'redux';
import { postUserLogout } from '../../../redux/reducers/admin/leadSettings';
import { connect } from 'react-redux';
import Loader from '../../../components/@vuexy/spinner/Fallback-spinner';
import TodoTaskList from './todoTask';

const UserDropdown = (props) => {
  // const { logout, isAuthenticated } = useAuth0()
  const userId = getAuthUserId();
  const sessionId = getAuthUserSessionId();
  return (
    <DropdownMenu right>
      {props.loading && <Loader />}

      <DropdownItem
        tag="a"
        href="/settings/user/profile"
        onClick={() => {
          history.push('/settings/user/profile');
        }}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="/login"
        onClick={(e) => {
          e.preventDefault();

          props.postUserLogout({ sessionId, userId }).then(() => {
            window.localStorage.clear();

            history.push('/login');
          }).catch(() => {
            window.localStorage.clear();
            history.push('/login');
          });
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>

    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    // langDropdown: false,
    todoModalOpen: false,
    // shoppingCart: [
    // {
    //   id: 1,
    //   name:
    //     "Apple - Apple Watch Series 1 42mm Space Gray Aluminum Case Black Sport Band - Space Gray Aluminum",
    //   desc:
    //     "Durable, lightweight aluminum cases in silver, space gray, gold, and rose gold. Sport Band in a variety of colors. All the features of the original Apple Watch, plus a new dual-core processor for faster performance. All models run watchOS 3. Requires an iPhone 5 or later.",
    //   price: "$299",
    //   img: require("../../../assets/img/pages/eCommerce/4.png"),
    //   width: 75
    // },
    // {
    //   id: 2,
    //   name:
    //     "Apple - Macbook® (Latest Model) - 12' Display - Intel Core M5 - 8GB Memory - 512GB Flash Storage Space Gray",
    //   desc:
    //     "MacBook delivers a full-size experience in the lightest and most compact Mac notebook ever. With a full-size keyboard, force-sensing trackpad, 12-inch Retina display,1 sixth-generation Intel Core M processor, multifunctional USB-C port, and now up to 10 hours of battery life,2 MacBook features big thinking in an impossibly compact form.",
    //   price: "$1599.99",
    //   img: require("../../../assets/img/pages/eCommerce/dell-inspirion.jpg"),
    //   width: 100,
    //   imgClass: "mt-1 pl-50"
    // },
    // {
    //   id: 3,
    //   name: "Sony - PlayStation 4 Pro Console",
    //   desc:
    //     "PS4 Pro Dynamic 4K Gaming & 4K Entertainment* PS4 Pro gets you closer to your game. Heighten your experiences. Enrich your adventures. Let the super-charged PS4 Pro lead the way.** GREATNESS AWAITS",
    //   price: "$399.99",
    //   img: require("../../../assets/img/pages/eCommerce/7.png"),
    //   width: 88
    // },
    // {
    //   id: 4,
    //   name:
    //     "Beats by Dr. Dre - Geek Squad Certified Refurbished Beats Studio Wireless On-Ear Headphones - Red",
    //   desc:
    //     "Rock out to your favorite songs with these Beats by Dr. Dre Beats Studio Wireless GS-MH8K2AM/A headphones that feature a Beats Acoustic Engine and DSP software for enhanced clarity. ANC (Adaptive Noise Cancellation) allows you to focus on your tunes.",
    //   price: "$379.99",
    //   img: require("../../../assets/img/pages/eCommerce/10.png"),
    //   width: 75
    // },
    // {
    //   id: 5,
    //   name:
    //     "Sony - 75' Class (74.5' diag) - LED - 2160p - Smart - 3D - 4K Ultra HD TV with High Dynamic Range - Black",
    //   desc:
    //     "This Sony 4K HDR TV boasts 4K technology for vibrant hues. Its X940D series features a bold 75-inch screen and slim design. Wires remain hidden, and the unit is easily wall mounted. This television has a 4K Processor X1 and 4K X-Reality PRO for crisp video. This Sony 4K HDR TV is easy to control via voice commands.",
    //   price: "$4499.99",
    //   img: require("../../../assets/img/pages/eCommerce/sony-75class-tv.jpg"),
    //   width: 100,
    //   imgClass: "mt-1 pl-50"
    // },
    // {
    //   id: 6,
    //   name:
    //     "Nikon - D810 DSLR Camera with AF-S NIKKOR 24-120mm f/4G ED VR Zoom Lens - Black",
    //   desc:
    //     "Shoot arresting photos and 1080p high-definition videos with this Nikon D810 DSLR camera, which features a 36.3-megapixel CMOS sensor and a powerful EXPEED 4 processor for clear, detailed images. The AF-S NIKKOR 24-120mm lens offers shooting versatility. Memory card sold separately.",
    //   price: "$4099.99",
    //   img: require("../../../assets/img/pages/eCommerce/canon-camera.jpg"),
    //   width: 70,
    //   imgClass: "mt-1 pl-50"
    // }
    // ],
    suggestions: []
  }

  componentDidMount() {
    axios.get('/api/main-search/data').then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState((prev) => ({
      navbarSearch: !prev.navbarSearch
    }));
  }

  // removeItem = (id) => {
  //   let cart = this.state.shoppingCart;

  //   let updatedCart = cart.filter((i) => i.id !== id);

  //   this.setState({
  //     shoppingCart: updatedCart
  //   });
  // }

  // handleLangDropdown = () =>
  //   this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    // const renderCartItems = this.state.shoppingCart.map(item => {
    //   return (
    //     <div className="cart-item" key={item.id}>
    //       <Media
    //         className="p-0"
    //         onClick={() => history.push("/ecommerce/product-detail")}
    //       >
    //         <Media className="text-center pr-0 mr-1" left>
    //           <img
    //             className={`${
    //               item.imgClass
    //                 ? item.imgClass + " cart-item-img"
    //                 : "cart-item-img"
    //             }`}
    //             src={item.img}
    //             width={item.width}
    //             alt="Cart Item"
    //           />
    //         </Media>
    //         <Media className="overflow-hidden pr-1 py-1 pl-0" body>
    //           <span className="item-title text-truncate text-bold-500 d-block mb-50">
    //             {item.name}
    //           </span>
    //           <span className="item-desc font-small-2 text-truncate d-block">
    //             {item.desc}
    //           </span>
    //           <div className="d-flex justify-content-between align-items-center mt-1">
    //             <span className="align-middle d-block">1 x {item.price}</span>
    //             <Icon.X
    //               className="danger"
    //               size={15}
    //               onClick={e => {
    //                 e.stopPropagation()
    //                 this.removeItem(item.id)
    //               }}
    //             />
    //           </div>
    //         </Media>
    //       </Media>
    //     </div>
    //   )
    // })
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right d-flex align-items-center">
        {/* <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en" : "English",
              "de" : "German",
              "fr" : "French",
              "pt" : "Portuguese"
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={this.state.langDropdown}
                toggle={this.handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <ReactCountryFlag
                  className="country-flag"
                    countryCode={
                      context.state.locale === "en"
                        ? "us"
                        : context.state.locale
                    }
                    svg
                  />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langArr[context.state.locale]}
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("fr")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="fr" svg />
                    <span className="ml-1">French</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("de")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="de" svg />
                    <span className="ml-1">German</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("pt")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="pt" svg />
                    <span className="ml-1">Portuguese</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer> */}
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <NotificationPayent />

        </UncontrolledDropdown>
        {(getUserRoleId() === APPConfig.SUPER_ADMIN || getUserRoleId() === APPConfig.SUPER_USER) && (
          <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
            <NavLink className="nav-link-search">
              <Icon.Search size={21} data-tour="search" id="searchHeader" />
              <UncontrolledTooltip placement="top" target="searchHeader">Search</UncontrolledTooltip>

            </NavLink>
            <div
              className={classnames('search-input', {
                open: this.state.navbarSearch,
                'd-none': this.state.navbarSearch === false
              })}
            >
              <div className="search-input-icon">
                <Icon.Search size={17} className="primary" />
              </div>
              <Autocomplete
                className="form-control"
                suggestions={this.state.suggestions}
                filterKey="title"
                filterHeaderKey="groupTitle"
                grouped
                placeholder="Explore Xusom..."
                autoFocus
                clearInput={this.state.navbarSearch}
                externalClick={() => {
                  this.setState({ navbarSearch: false });
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 27 || e.keyCode === 13) {
                    this.setState({
                      navbarSearch: false
                    });
                    this.props.handleAppOverlay('');
                  }
                }}
                customRender={(
                  item,
                  i,
                  filteredData,
                  activeSuggestion,
                  onSuggestionItemClick,
                  onSuggestionItemHover
                ) => {
                  const IconTag = Icon[item.icon ? item.icon : 'X'];
                  return (
                    <li
                      className={classnames('suggestion-item', {
                        active: filteredData.indexOf(item) === activeSuggestion
                      })}
                      key={i}
                      onClick={(e) => onSuggestionItemClick(item.link, e)}
                      onMouseEnter={() => onSuggestionItemHover(filteredData.indexOf(item))}
                    >
                      <div
                        className={classnames({
                          'd-flex justify-content-between align-items-center':
                            item.file || item.img
                        })}
                      >
                        <div className="item-container d-flex">
                          {item.icon ? (
                            <IconTag size={17} />
                          ) : item.file ? (
                            <img
                              src={item.file}
                              height="36"
                              width="28"
                              alt={item.title}
                            />
                          ) : item.img ? (
                            <img
                              className="rounded-circle mt-25"
                              src={item.img}
                              height="28"
                              width="28"
                              alt={item.title}
                            />
                          ) : null}
                          <div className="item-info ml-1">
                            <p className="align-middle mb-0">{item.title}</p>
                            {item.by || item.email ? (
                              <small className="text-muted">
                                {item.by
                                  ? item.by
                                  : item.email
                                    ? item.email
                                    : null}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        {item.size || item.date ? (
                          <div className="meta-container">
                            <small className="text-muted">
                              {item.size
                                ? item.size
                                : item.date
                                  ? item.date
                                  : null}
                            </small>
                          </div>
                        ) : null}
                      </div>
                    </li>
                  );
                }}
                onSuggestionsShown={(userInput) => {
                  if (this.state.navbarSearch) {
                    this.props.handleAppOverlay(userInput);
                  }
                }}
              />
              <div className="search-input-close">
                <Icon.X
                  size={24}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      navbarSearch: false
                    });
                    this.props.handleAppOverlay('');
                  }}
                />
              </div>
            </div>
          </NavItem>
        )}

        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item cursor-pointer"
        >
          <span type="button" className="nav-link" onClick={() => this.setState({ todoModalOpen: true })}>
            <Icon.CheckCircle size={20} data-tour="calendar" id="todoTaskList" />
            <UncontrolledTooltip placement="top" target="todoTaskList">Todo Task</UncontrolledTooltip>
          </span>
        </UncontrolledDropdown>
        {/* <UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'>
          <DropdownToggle tag='a' className='nav-link position-relative'>
            <Icon.ShoppingCart size={21} />
            {this.state.shoppingCart.length > 0 ? (
              <Badge pill color='primary' className='badge-up'>
                {this.state.shoppingCart.length}
              </Badge>
            ) : null}
          </DropdownToggle>
          <DropdownMenu
            tag='ul'
            right
            className={`dropdown-menu-media dropdown-cart ${
              this.state.shoppingCart.length === 0 ? 'empty-cart' : ''
            }`}>
            <li
              className={`dropdown-menu-header ${
                this.state.shoppingCart.length === 0 ? 'd-none' : 'd-block'
              }`}>
              <div className='dropdown-header m-0'>
                <h3 className='white'>
                  {this.state.shoppingCart.length} Items
                </h3>
                <span className='notification-title'>In Your Cart</span>
              </div>
            </li>
            <PerfectScrollbar
              className='media-list overflow-hidden position-relative'
              options={{
                wheelPropagation: false
              }}>
              {renderCartItems}
            </PerfectScrollbar>
            <li
              className={`dropdown-menu-footer border-top ${
                this.state.shoppingCart.length === 0 ? 'd-none' : 'd-block'
              }`}>
              <div
                className=<UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'>
          <DropdownToggle tag='a' className='nav-link position-relative'>
            <Icon.ShoppingCart size={21} />
            {this.state.shoppingCart.length > 0 ? (
              <Badge pill color='primary' className='badge-up'>
                {this.state.shoppingCart.length}
              </Badge>
            ) : null}
          </DropdownToggle>
          <DropdownMenu
            tag='ul'
            right
            className={`dropdown-menu-media dropdown-cart ${
              this.state.shoppingCart.length === 0 ? 'empty-cart' : ''
            }`}>
            <li
              className={`dropdown-menu-header ${
                this.state.shoppingCart.length === 0 ? 'd-none' : 'd-block'
              }`}>
              <div className='dropdown-header m-0'>
                <h3 className='white'>
                  {this.state.shoppingCart.length} Items
                </h3>
                <span className='notification-title'>In Your Cart</span>
              </div>
            </li>
            <PerfectScrollbar
              className='media-list overflow-hidden position-relative'
              options={{
                wheelPropagation: false
              }}>
              {renderCartItems}
            </PerfectScrollbar>
            <li
              className={`dropdown-menu-footer border-top ${
                this.state.shoppingCart.length === 0 ? 'd-none' : 'd-block'
              }`}>
              <div
                className='dropdown-item p-1 text-center text-primary'
                onClick={() => history.push('/ecommerce/checkout')}>
                <Icon.ShoppingCart size={15} />
                <span className='align-middle text-bold-600 ml-50'>
                  Checkout
                </span>
              </div>
            </li>
            <li
              className={`empty-cart ${
                this.state.shoppingCart.length > 0 ? 'd-none' : 'd-block'
              } p-2`}>
              Your Cart Is Empty
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>'dropdown-item p-1 text-center text-primary'
                onClick={() => history.push('/ecommerce/checkout')}>
                <Icon.ShoppingCart size={15} />
                <span className='align-middle text-bold-600 ml-50'>
                  Checkout
                </span>
              </div>
            </li>
            <li
              className={`empty-cart ${
                this.state.shoppingCart.length > 0 ? 'd-none' : 'd-block'
              } p-2`}>
              Your Cart Is Empty
            </li>
          </DropdownMenu>
        </UncontrolledDropdown> */}

        {/* <UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'>
          <NavLink className='nav-link-home'>
          <Link className='nav-link' to='/landing-page'>
            <Icon.Home size={21} data-tour='Home' />
          </Link>
          </NavLink>
        </UncontrolledDropdown> */}
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item cursor-pointer"
        >
          <Link className="nav-link" to="/calendar">
            <Icon.Calendar size={20} data-tour="calendar" id="calendarHeader" />
            <UncontrolledTooltip placement="top" target="calendarHeader">Calendar</UncontrolledTooltip>
          </Link>
        </UncontrolledDropdown>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <a className="nav-link" target="_blank" href="https://mail.google.com/" rel="noopener noreferrer">
            {/* <img className="img-fluid" src={mail} style={{ width: '21px' }} alt="Mail Logo" /> */}
            <Icon.Mail size={20} id="sendMailHeader" data-tour="gmail" />
            <UncontrolledTooltip placement="top" target="sendMailHeader">Inbox/Email</UncontrolledTooltip>
          </a>
        </UncontrolledDropdown>
        {/* <UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'>
          <DropdownToggle tag='a' className='nav-link nav-link-label'>
            <Icon.Bell size={21} />
            <Badge pill color='primary' className='badge-up'>
              {' '}
              5{' '}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag='ul' right className='dropdown-menu-media'>
            <li className='dropdown-menu-header'>
              <div className='dropdown-header mt-0'>
                <h3 className='text-white'>5 New</h3>
                <span className='notification-title'>App Notifications</span>
              </div>
            </li> */}
        {/* <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.PlusSquare
                      className="font-medium-5 primary"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="primary media-heading" tag="h6">
                      You have new order!
                    </Media>
                    <p className="notification-text">
                      Are your going to meet me tonight?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      9 hours ago
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.DownloadCloud
                      className="font-medium-5 success"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="success media-heading" tag="h6">
                      99% Server load
                    </Media>
                    <p className="notification-text">
                      You got new order of goods?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      5 hours ago
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.AlertTriangle
                      className="font-medium-5 danger"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="danger media-heading" tag="h6">
                      Warning Notification
                    </Media>
                    <p className="notification-text">
                      Server has used 99% of CPU
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Today
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.CheckCircle
                      className="font-medium-5 info"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="info media-heading" tag="h6">
                      Complete the task
                    </Media>
                    <p className="notification-text">
                      One of your task is pending.
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Last week
                    </time>
                  </small>
                </Media>
              </div>
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.File className="font-medium-5 warning" size={21} />
                  </Media>
                  <Media body>
                    <Media heading className="warning media-heading" tag="h6">
                      Generate monthly report
                    </Media>
                    <p className="notification-text">
                      Reminder to generate monthly report
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      Last month
                    </time>
                  </small>
                </Media>
              </div>
            </PerfectScrollbar> */}
        {/* <li className='dropdown-menu-footer'>
              <DropdownItem tag='a' className='p-1 text-center'>
                <span className='align-middle'>Read all notifications</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown> */}
        <UncontrolledDropdown
          tag="li"
          className="dropdown-user nav-item d-flex"
        >
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600 m-0">
                {this.props.userName}
              </span>
              {/* <span className="user-status">Available</span> */}
            </div>
            {/* <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span> */}
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
        <TodoTaskList todoModalOpen={this.state.todoModalOpen} setTodoModalOpen={(val) => this.setState({ todoModalOpen: val })} />
      </ul>
    );
  }
}

const mapStateToProps = ({ adminModule: { leadSettings } }) => ({
  loading: leadSettings.loading
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  postUserLogout
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
