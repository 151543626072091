export const SET_EMPLOYEE_SIDE_MENU = 'sideMenuChange/SET_EMPLOYEE_SIDE_MENU';

const initialValues = {
  loading: false,
  loaded: false,
  error: false,
  employeeSideMenu: null,
};

export default (state = initialValues, action) => {
  switch (action.type) {
  case SET_EMPLOYEE_SIDE_MENU:
    return {
      ...state,
      employeeSideMenu: action.payload,
    };
  default:
    return state;
  }
};

export const setEmployeeSideMenu = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_EMPLOYEE_SIDE_MENU,
      payload: data || {},
    });
  };
};
