import { getAuthUserId } from '../../../utility/helpers/helperFunction';

export const GET_GROUP_LIST_REQUESTED = 'admin/GET_GROUP_LIST_REQUESTED';
export const GET_GROUP_LIST_SUCCESS = 'admin/GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAILURE = 'admin/GET_GROUP_LIST_FAILURE';

export const ADD_ACTION_REQUESTED = 'admin/ADD_ACTION_REQUESTED';
export const ADD_ACTION_SUCCESS = 'admin/ADD_ACTION_SUCCESS';
export const ADD_ACTION_FAILURE = 'admin/ADD_ACTION_FAILURE';

export const UPDATE_ACTION_REQUESTED = 'admin/UPDATE_ACTION_REQUESTED';
export const UPDATE_ACTION_SUCCESS = 'admin/UPDATE_ACTION_SUCCESS';
export const UPDATE_ACTION_FAILURE = 'admin/UPDATE_ACTION_FAILURE';

export const DELETE_ACTION_REQUESTED = 'admin/DELETE_ACTION_REQUESTED';
export const DELETE_ACTION_SUCCESS = 'admin/DELETE_ACTION_SUCCESS';
export const DELETE_ACTION_FAILURE = 'admin/DELETE_ACTION_FAILURE';

export const GET_GROUP_HIERARCHY_REQUESTED = 'admin/GET_GROUP_HIERARCHY_REQUESTED';
export const GET_GROUP_HIERARCHY_SUCCESS = 'admin/GET_GROUP_HIERARCHY_SUCCESS';
export const GET_GROUP_HIERARCHY_FAILURE = 'admin/GET_GROUP_HIERARCHY_FAILURE';

export const GET_COMPONENTS_LIST_REQUESTED = 'admin/GET_COMPONENTS_LIST_REQUESTED';
export const GET_COMPONENTS_LIST_SUCCESS = 'admin/GET_COMPONENTS_LIST_SUCCESS';
export const GET_COMPONENTS_LIST_FAILURE = 'admin/GET_COMPONENTS_LIST_FAILURE';

export const GET_COMPONENTS_DATA_SUCCESS = 'admin/GET_COMPONENTS_DATA_SUCCESS';

export const GET_MENU_LIST_REQUESTED = 'admin/GET_MENU_LIST_REQUESTED';
export const GET_MENU_LIST_SUCCESS = 'admin/GET_MENU_LIST_SUCCESS';
export const GET_MENU_LIST_FAILURE = 'admin/GET_MENU_LIST_FAILURE';

export const GET_ACCESS_RIGHTS_REQUESTED = 'admin/GET_ACCESS_RIGHTS_REQUESTED';
export const GET_ACCESS_RIGHTS_SUCCESS = 'admin/GET_ACCESS_RIGHTS_SUCCESS';
export const GET_ACCESS_RIGHTS_FAILURE = 'admin/GET_ACCESS_RIGHTS_FAILURE';

export const GET_USER_DETAILS_REQUESTED = 'admin/GET_USER_DETAILS_REQUESTED';
export const GET_USER_DETAILS_SUCCESS = 'admin/GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'admin/GET_USER_DETAILS_FAILURE';

export const GET_USER_LIST_REQUESTED = 'admin/GET_USER_LIST_REQUESTED';
export const GET_USER_LIST_SUCCESS = 'admin/GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'admin/GET_USER_LIST_FAILURE';

export const GET_USER_ACCESS_CONFIG_REQUESTED = 'admin/GET_USER_ACCESS_CONFIG_REQUESTED';
export const GET_USER_ACCESS_CONFIG_SUCCESS = 'admin/GET_USER_ACCESS_CONFIG_SUCCESS';
export const GET_USER_ACCESS_CONFIG_FAILURE = 'admin/GET_USER_ACCESS_CONFIG_FAILURE';

export const GET_SUPERVISOR_REQUESTED = 'admin/GET_SUPERVISOR_REQUESTED';
export const GET_SUPERVISOR_SUCCESS = 'admin/GET_SUPERVISOR_SUCCESS';
export const GET_SUPERVISOR_FAILURE = 'admin/GET_SUPERVISOR_FAILURE';

export const GET_USER_NAMES_REQUESTED = 'admin/GET_USER_NAMES_REQUESTED';
export const GET_USER_NAMES_SUCCESS = 'admin/GET_USER_NAMES_SUCCESS';
export const GET_USER_NAMES_FAILURE = 'admin/GET_USER_NAMES_FAILURE';

export const GET_BANNER_LIST_REQUESTED = 'admin/GET_BANNER_LIST_REQUESTED';
export const GET_BANNER_LIST_SUCCESS = 'admin/GET_BANNER_LIST_SUCCESS';
export const GET_BANNER_LIST_FAILURE = 'admin/GET_Banner_LIST_FAILURE';

export const GET_BANNER_HOME_LIST_REQUESTED = 'admin/GET_BANNER_HOME_LIST_REQUESTED';
export const GET_BANNER_HOME_LIST_SUCCESS = 'admin/GET_BANNER_HOME_LIST_SUCCESS';
export const GET_BANNER_HOME_LIST_FAILURE = 'admin/GET_Banner_HOME_LIST_FAILURE';

export const GET_SELECTED_ACCOUNTING_LIST_SUCCESS = 'admin/GET_SELECTED_ACCOUNTING_LIST_SUCCESS';
export const SET_SELECTED_STEP2 = 'admin/SET_SELECTED_STEP2';
export const GET_SELECTED_FINANCIAL_LIST_SUCCESS = 'admin/GET_SELECTED_FINANCIAL_LIST_SUCCESS';
export const SET_SELECTED_FINANCIAL_STEP2 = 'admin/SET_SELECTED_FINANCIAL_STEP2';
export const GET_SELECTED_INVOICE_LIST_SUCCESS = 'admin/GET_SELECTED_INVOICE_LIST_SUCCESS';
export const SET_SELECTED_INVOICE_STEP2 = 'admin/SET_SELECTED_INVOICE_STEP2';

export const GET_IMMIGRATION_FEE_LIST_REQUESTED = 'admin/GET_IMMIGRATION_FEE_LIST_REQUESTED';
export const GET_IMMIGRATION_FEE_LIST_SUCCESS = 'admin/GET_IMMIGRATION_FEE_LIST_SUCCESS';
export const GET_IMMIGRATION_FEE_LIST_FAILURE = 'admin/GET_IMMIGRATION_FEE_LIST_FAILURE';

export const GET_IMMIGRATION_FEE_LIST_BY_ID_REQUESTED = 'admin/GET_IMMIGRATION_FEE_LIST_BY_ID_REQUESTED';
export const GET_IMMIGRATION_FEE_LIST_BY_ID_SUCCESS = 'admin/GET_IMMIGRATION_FEE_LIST_BY_ID_SUCCESS';
export const GET_IMMIGRATION_FEE_LIST_BY_ID_FAILURE = 'admin/GET_IMMIGRATION_FEE_LIST_BY_ID_FAILURE';

export const GET_BULK_ACCOUNTING_LIST_REQUESTED = 'admin/GET_BULK_ACCOUNTING_LIST_REQUESTED';
export const GET_BULK_ACCOUNTING_LIST_SUCCESS = 'admin/GET_BULK_ACCOUNTING_LIST_SUCCESS';
export const GET_BULK_ACCOUNTING_LIST_FAILURE = 'admin/GET_BULK_ACCOUNTING_LIST_FAILURE';

export const GET_BULK_UPDATE_INVOICE_LIST_REQUESTED = 'admin/GET_BULK_UPDATE_INVOICE_LIST_REQUESTED';
export const GET_BULK_UPDATE_INVOICE_LIST_SUCCESS = 'admin/GET_BULK_UPDATE_INVOICE_LIST_SUCCESS';
export const GET_BULK_UPDATE_INVOICE_LIST_FAILURE = 'admin/GET_BULK_UPDATE_INVOICE_LIST_FAILURE';

export const GET_INSTALLMENT_END_DATE_SEMESTER_REQUESTED = 'admin/GET_INSTALLMENT_END_DATE_SEMESTER_REQUESTED';
export const GET_INSTALLMENT_END_DATE_SEMESTER_SUCCESS = 'admin/GET_INSTALLMENT_END_DATE_SEMESTER_SUCCESS';
export const GET_INSTALLMENT_END_DATE_SEMESTER_FAILURE = 'admin/GET_INSTALLMENT_END_DATE_SEMESTER_FAILURE';

export const ADD_INSTALLMENT_END_DATE_REQUESTED = 'admin/ADD_INSTALLMENT_END_DATE_REQUESTED';
export const ADD_INSTALLMENT_END_DATE_SUCCESS = 'admin/ADD_INSTALLMENT_END_DATE_SUCCESS';
export const ADD_INSTALLMENT_END_DATE_FAILURE = 'admin/ADD_INSTALLMENT_END_DATE_FAILURE';

export const GET_ACCOUNTING_HEAD_LIST_REQUESTED = 'admin/GET_ACCOUNTING_HEAD_LIST_REQUESTED';
export const GET_ACCOUNTING_HEAD_LIST_SUCCESS = 'admin/GET_ACCOUNTING_HEAD_LIST_SUCCESS';
export const GET_ACCOUNTING_HEAD_LIST_FAILURE = 'admin/GET_ACCOUNTING_HEAD_LIST_FAILURE';

export const GET_ACCOUNTING_HEAD_REQUESTED = 'admin/GET_ACCOUNTING_HEAD_REQUESTED';
export const GET_ACCOUNTING_HEAD_SUCCESS = 'admin/GET_ACCOUNTING_HEAD_SUCCESS';
export const GET_ACCOUNTING_HEAD_FAILURE = 'admin/GET_ACCOUNTING_HEAD_FAILURE';

export const GET_MASTER_TYPE_REQUESTED = 'admin/GET_MASTER_TYPE_REQUESTED';
export const GET_MASTER_TYPE_SUCCESS = 'admin/GET_MASTER_TYPE_SUCCESS';
export const GET_MASTER_TYPE_FAILURE = 'admin/GET_MASTER_TYPE_FAILURE';

export const GET_MASTER_VALUES_LIST_REQUESTED = 'admin/GET_MASTER_VALUES_LIST_REQUESTED';
export const GET_MASTER_VALUES_LIST_SUCCESS = 'admin/GET_MASTER_VALUES_LIST_SUCCESS';
export const GET_MASTER_VALUES_LIST_FAILURE = 'admin/GET_MASTER_VALUES_LIST_FAILURE';

export const GET_MASTER_VALUES_REQUESTED = 'admin/GET_MASTER_VALUES_REQUESTED';
export const GET_MASTER_VALUES_SUCCESS = 'admin/GET_MASTER_VALUES_SUCCESS';
export const GET_MASTER_VALUES_FAILURE = 'admin/GET_MASTER_VALUES_FAILURE';

export const GET_FEE_HEAD_LIST_REQUESTED = 'admin/GET_FEE_HEAD_LIST_REQUESTED';
export const GET_FEE_HEAD_LIST_SUCCESS = 'admin/GET_FEE_HEAD_LIST_SUCCESS';
export const GET_FEE_HEAD_LIST_FAILURE = 'admin/GET_FEE_HEAD_LIST_FAILURE';

export const GET_FEE_HEAD_REQUESTED = 'admin/GET_FEE_HEAD_REQUESTED';
export const GET_FEE_HEAD_SUCCESS = 'admin/GET_FEE_HEAD_SUCCESS';
export const GET_FEE_HEAD_FAILURE = 'admin/GET_FEE_HEAD_FAILURE';

export const GET_REVENUE_SETTINGS_REQUESTED = 'admin/GET_REVENUE_SETTINGS_REQUESTED';
export const GET_REVENUE_SETTINGS_SUCCESS = 'admin/GET_REVENUE_SETTINGS_SUCCESS';
export const GET_REVENUE_SETTINGS_FAILURE = 'admin/GET_REVENUE_SETTINGS_FAILURE';

export const GET_FEE_GROUP_HEAD_LIST_REQUESTED = 'admin/GET_FEE_GROUP_HEAD_LIST_REQUESTED';
export const GET_FEE_GROUP_HEAD_LIST_SUCCESS = 'admin/GET_FEE_GROUP_HEAD_LIST_SUCCESS';
export const GET_FEE_GROUP_HEAD_LIST_FAILURE = 'admin/GET_FEE_GROUP_HEAD_LIST_FAILURE';

export const GET_FEE_GROUP_HEAD_REQUESTED = 'admin/GET_FEE_GROUP_HEAD_REQUESTED';
export const GET_FEE_GROUP_HEAD_SUCCESS = 'admin/GET_FEE_GROUP_HEAD_SUCCESS';
export const GET_FEE_GROUP_HEAD_FAILURE = 'admin/GET_FEE_GROUP_HEAD_FAILURE';

export const GET_FEE_GROUP_NAME_LIST_REQUESTED = 'admin/GET_FEE_GROUP_NAME_LIST_REQUESTED';
export const GET_FEE_GROUP_NAME_LIST_SUCCESS = 'admin/GET_FEE_GROUP_NAME_LIST_SUCCESS';
export const GET_FEE_GROUP_NAME_LIST_FAILURE = 'admin/GET_FEE_GROUP_NAME_LIST_FAILURE';

export const GET_INST_END_DATE_SEM_DATA_REQUESTED = 'admin/GET_INST_END_DATE_SEM_DATA_REQUESTED';
export const GET_INST_END_DATE_SEM_DATA_SUCCESS = 'admin/GET_INST_END_DATE_SEM_DATA_SUCCESS';
export const GET_INST_END_DATE_SEM_DATA_FAILURE = 'admin/GET_INST_END_DATE_SEM_DATA_FAILURE';

export const GET_LEAD_SETTING_DAYS_REQUESTED = 'admin/GET_LEAD_SETTING_DAYS_REQUESTED';
export const GET_LEAD_SETTING_DAYS_SUCCESS = 'admin/GET_LEAD_SETTING_DAYS_SUCCESS';
export const GET_LEAD_SETTING_DAYS_FAILURE = 'admin/GET_LEAD_SETTING_DAYS_FAILURE';

export const GET_LEAD_SETTING_TIMESLOT_REQUESTED = 'admin/GET_LEAD_SETTING_TIMESLOT_REQUESTED';
export const GET_LEAD_SETTING_TIMESLOT_SUCCESS = 'admin/GET_LEAD_SETTING_TIMESLOT_SUCCESS';
export const GET_LEAD_SETTING_TIMESLOT_FAILURE = 'admin/GET_LEAD_SETTING_TIMESLOT_FAILURE';

export const GET_LEAD_SETTING_TIMESLOT_LIST_REQUESTED = 'admin/GET_LEAD_SETTING_TIMESLOT_LIST_REQUESTED';
export const GET_LEAD_SETTING_TIMESLOT_LIST_SUCCESS = 'admin/GET_LEAD_SETTING_TIMESLOT_LIST_SUCCESS';
export const GET_LEAD_SETTING_TIMESLOT_LIST_FAILURE = 'admin/GET_LEAD_SETTING_TIMESLOT_LIST_FAILURE';

export const GET_LEAD_SETTING_TIMESLOT_BY_ID_REQUESTED = 'admin/GET_LEAD_SETTING_TIMESLOT_BY_ID_REQUESTED';
export const GET_LEAD_SETTING_TIMESLOT_BY_ID_SUCCESS = 'admin/GET_LEAD_SETTING_TIMESLOT_BY_ID_SUCCESS';
export const GET_LEAD_SETTING_TIMESLOT_BY_ID_FAILURE = 'admin/GET_LEAD_SETTING_TIMESLOT_BY_ID_FAILURE';
export const GET_FEE_SETTINGS_INSURANCE_REQUESTED = 'admin/GET_FEE_SETTINGS_INSURANCE_REQUESTED';
export const GET_FEE_SETTINGS_INSURANCE_SUCCESS = 'admin/GET_FEE_SETTINGS_INSURANCE_SUCCESS';
export const GET_FEE_SETTINGS_INSURANCE_FAILURE = 'admin/GET_FEE_SETTINGS_INSURANCE_FAILURE';

export const GET_FEE_SETTINGS_REQUESTED = 'admin/GET_FEE_SETTINGS_REQUESTED';
export const GET_FEE_SETTINGS_SUCCESS = 'admin/GET_FEE_SETTINGS_SUCCESS';
export const GET_FEE_SETTINGS_FAILURE = 'admin/GET_FEE_SETTINGS_FAILURE';

export const GET_FEE_HEAD_DATA_REQUESTED = 'admin/GET_FEE_HEAD_DATA_REQUESTED';
export const GET_FEE_HEAD_DATA_SUCCESS = 'admin/GET_FEE_HEAD_DATA_SUCCESS';
export const GET_FEE_HEAD_DATA_FAILURE = 'admin/GET_FEE_HEAD_DATA_FAILURE';

export const GET_SEMESTER_VALUE = 'admin/GET_SEMESTER_VALUE';

export const GET_ASSIGNED_CLASSES = 'admin/GET_ASSIGNED_CLASSES';
export const GET_ASSIGNED_CLASSES_BY_ID = 'admin/GET_ASSIGNED_CLASSES_BY_ID';

export const GET_SEMESTER_TYPE_MONTH_SUCCESS = 'admin/GET_SEMESTER_TYPE_MONTH_SUCCESS';

export const GET_LEAD_CURRENT_SEMESTER_SUCCESS = 'admin/GET_LEAD_CURRENT_SEMESTER_SUCCESS';

export const GET_ALERT_CONFIG_SUCCESS = 'admin/GET_ALERT_CONFIG_SUCCESS';

export const GET_ALERT_CONFIG_DATA_SUCCESS = 'admin/GET_ALERT_CONFIG_DATA_SUCCESS';

export const GET_FACULTY_BY_GROUP = 'admin/GET_FACULTY_BY_GROUP';

export const GET_MENU_ACCESS_SUCCESS = 'admin/GET_MENU_ACCESS_SUCCESS';

export const TRIGGER_ATRIUM_REQUESTED = 'admin/TRIGGER_ATRIUM_REQUESTED';
export const TRIGGER_ATRIUM_SUCCESS = 'admin/TRIGGER_ATRIUM_SUCCESS';
export const TRIGGER_ATRIUM_FAILURE = 'admin/TRIGGER_ATRIUM_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  groupsList: [],
  componentsList: [],
  groupHierarchy: null,
  menuList: [],
  groupAccessRights: null,
  userAccessDetails: null,
  userList: [],
  userAccessConfig: null,
  supervisorList: [],
  userNames: null,
  bannerList: null,
  bannerHomeList: null,
  selectedAccountingList: [],
  accountingStep2: false,
  financialStep2: false,
  selectedFinancialList: [],
  semesterList: null,
  accountingHeadList: null,
  accountingHead: null,
  masterType: null,
  masterValuesList: null,
  masterValues: null,
  feeHeadList: null,
  feeHead: null,
  revenueSettingData: null,
  feeGroupHeadList: null,
  feeGroupHead: null,
  feeGroupNameList: null,
  selectedInvoiceList: [],
  invoiceStep2: false,
  immigrationList: null,
  immigrationById: null,
  bulkAccountingList: null,
  bulkInvoiceList: null,
  installmentEndDateData: null,
  leadSettingFacultyDays: null,
  leadSettingFacultyTimeSlot: null,
  leadSettingFacultyTimeSlotList: null,
  leadSettingFacultyTimeSlotById: null,
  feeSettingsInsurance: null,
  feeSettings: null,
  semesterData: null,
  feeHeadData: null,
  assignedClassesList: null,
  assignedClassById: null,
  semesterTypeMonthList: null,
  leadCurrentSemester: null,
  alertConfig: null,
  configData: null,
  facultyByGroup: null,
  menuAccessList: null,
  atriumData: null,
  atriumLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case GET_GROUP_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_GROUP_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      groupsList: action.result,
    };
  }
  case GET_GROUP_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      groupsList: [],
    };
  }
  case GET_MENU_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_MENU_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      menuList: action.result,
    };
  }
  case GET_MENU_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      menuList: [],
    };
  }
  case ADD_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case ADD_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case UPDATE_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case UPDATE_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case UPDATE_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case DELETE_ACTION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case DELETE_ACTION_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case DELETE_ACTION_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_GROUP_HIERARCHY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_GROUP_HIERARCHY_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      groupHierarchy: action.result
    };
  }
  case GET_GROUP_HIERARCHY_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      groupHierarchy: null,
    };
  }
  case GET_COMPONENTS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_COMPONENTS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      componentsList: action.result,
    };
  }
  case GET_COMPONENTS_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      componentData: action.result,
    };
  }
  case GET_COMPONENTS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      componentsList: []
    };
  }
  case GET_ACCESS_RIGHTS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_ACCESS_RIGHTS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      groupAccessRights: action.result,
    };
  }
  case GET_ACCESS_RIGHTS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      groupAccessRights: null
    };
  }
  case GET_USER_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_USER_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      userAccessDetails: action.result,
    };
  }
  case GET_USER_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_USER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_USER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      userList: action.result,
    };
  }
  case GET_USER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      userList: []
    };
  }
  case GET_USER_ACCESS_CONFIG_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_USER_ACCESS_CONFIG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      userAccessConfig: action.result,
    };
  }
  case GET_USER_ACCESS_CONFIG_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      userAccessConfig: null
    };
  }
  case GET_SUPERVISOR_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_SUPERVISOR_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      supervisorList: action.result,
    };
  }
  case GET_SUPERVISOR_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      supervisorList: null
    };
  }
  case GET_USER_NAMES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_USER_NAMES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      userNames: action.result
    };
  }
  case GET_USER_NAMES_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_BANNER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_BANNER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      bannerList: action.result && action.result.data
    };
  }
  case GET_BANNER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_BANNER_HOME_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_BANNER_HOME_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      bannerHomeList: action.result && action.result.data
    };
  }
  case GET_BANNER_HOME_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_SELECTED_ACCOUNTING_LIST_SUCCESS: {
    return {
      ...state,
      selectedAccountingList: action.payload
    };
  }
  case SET_SELECTED_STEP2: {
    return {
      ...state,
      accountingStep2: action.payload
    };
  }
  case GET_SELECTED_FINANCIAL_LIST_SUCCESS: {
    return {
      ...state,
      selectedFinancialList: action.payload
    };
  }
  case SET_SELECTED_FINANCIAL_STEP2: {
    return {
      ...state,
      financialStep2: action.payload
    };
  }

  case GET_INSTALLMENT_END_DATE_SEMESTER_REQUESTED: {
    return {
		  ...state,
		  loading: true,
		  loaded: false,
		  error: false,
    };
  }
	  case GET_INSTALLMENT_END_DATE_SEMESTER_SUCCESS: {
    return {
		  ...state,
		  loading: false,
		  loaded: true,
		  error: false,
		  semesterList: action.result && action.result.data
    };
  }
  case GET_INSTALLMENT_END_DATE_SEMESTER_FAILURE: {
    return {
		  ...state,
		  loading: false,
		  loaded: true,
		  error: true,
    };
	  }

  case GET_ACCOUNTING_HEAD_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ACCOUNTING_HEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      accountingHeadList: action.result && action.result.data
    };
  }
  case GET_ACCOUNTING_HEAD_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_ACCOUNTING_HEAD_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ACCOUNTING_HEAD_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      accountingHead: action.result && action.result.data
    };
  }
  case GET_ACCOUNTING_HEAD_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_MASTER_TYPE_REQUESTED: {
    return {
		  ...state,
		  loading: true,
		  loaded: false,
		  error: false,
    };
	  }
  case GET_MASTER_TYPE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      masterType: action.result && action.result.data
    };
  }

  case GET_MASTER_TYPE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_MASTER_VALUES_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MASTER_VALUES_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      masterValuesList: action.result && action.result.data
    };
  }

  case GET_MASTER_VALUES_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_MASTER_VALUES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MASTER_VALUES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      masterValues: action.result && action.result.data
    };
  }
  case GET_MASTER_VALUES_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_FEE_HEAD_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_FEE_HEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeHeadList: action.result && action.result.data
    };
  }

  case GET_FEE_HEAD_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_FEE_HEAD_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_FEE_HEAD_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeHead: action.result && action.result.data
    };
  }

  case GET_FEE_HEAD_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_REVENUE_SETTINGS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_REVENUE_SETTINGS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      revenueSettingData: action.result && action.result.data
    };
  }

  case GET_REVENUE_SETTINGS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_FEE_GROUP_HEAD_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_FEE_GROUP_HEAD_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeGroupHeadList: action.result && action.result.data
    };
  }
  case GET_FEE_GROUP_HEAD_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_FEE_GROUP_HEAD_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_FEE_GROUP_HEAD_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeGroupHead: action.result && action.result.data
    };
  }
  case GET_FEE_GROUP_HEAD_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_FEE_GROUP_NAME_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }

  case GET_FEE_GROUP_NAME_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeGroupNameList: action.result && action.result.data
    };
  }
  case GET_FEE_GROUP_NAME_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_SELECTED_INVOICE_LIST_SUCCESS: {
    return {
      ...state,
      selectedInvoiceList: action.payload
    };
  }
  case SET_SELECTED_INVOICE_STEP2: {
    return {
      ...state,
      invoiceStep2: action.payload
    };
  }
  case GET_IMMIGRATION_FEE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_IMMIGRATION_FEE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      immigrationList: action.result && action.result.data
    };
  }
  case GET_IMMIGRATION_FEE_LIST_FAILURE: {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: true
    };
  }
  case GET_IMMIGRATION_FEE_LIST_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_IMMIGRATION_FEE_LIST_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      immigrationById: action.result && action.result.data
    };
  }
  case GET_IMMIGRATION_FEE_LIST_BY_ID_FAILURE: {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: true
    };
  }
  case GET_BULK_ACCOUNTING_LIST_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_BULK_ACCOUNTING_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      bulkAccountingList: action.result && action.result.data
    };
  }
  case GET_BULK_ACCOUNTING_LIST_FAILURE: {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: true
    };
  }
  case GET_BULK_UPDATE_INVOICE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_BULK_UPDATE_INVOICE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      bulkInvoiceList: action.result && action.result.data
    };
  }
  case GET_BULK_UPDATE_INVOICE_LIST_FAILURE: {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: true
    };
  }

  case GET_INST_END_DATE_SEM_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_INST_END_DATE_SEM_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      installmentEndDateData: action.result && action.result.data
    };
  }
  case GET_INST_END_DATE_SEM_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
      menuList: [],
    };
  }
  case GET_LEAD_SETTING_DAYS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_LEAD_SETTING_DAYS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      leadSettingFacultyDays: action.result
    };
  }
  case GET_LEAD_SETTING_DAYS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      leadSettingFacultyTimeSlot: action.result
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      leadSettingFacultyTimeSlotList: action.result
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      leadSettingFacultyTimeSlotById: action.result
    };
  }
  case GET_LEAD_SETTING_TIMESLOT_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }

  case GET_FEE_SETTINGS_INSURANCE_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_FEE_SETTINGS_INSURANCE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeSettingsInsurance: action.result && action.result.data
    };
  }
  case GET_FEE_SETTINGS_INSURANCE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_FEE_SETTINGS_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_FEE_SETTINGS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeSettings: action.result && action.result.data
    };
  }
  case GET_FEE_SETTINGS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_FEE_HEAD_DATA_REQUESTED: {
    return {
      ...state,
      loading: true
    };
  }
  case GET_FEE_HEAD_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      feeHeadData: action.result && action.result.data
    };
  }
  case GET_FEE_HEAD_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_SEMESTER_VALUE: {
    return {
      ...state,
      semesterData: action.payload,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_ASSIGNED_CLASSES: {
    return {
      ...state,
      assignedClassesList: action.result,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_ASSIGNED_CLASSES_BY_ID: {
    return {
      ...state,
      assignedClassById: action.result,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_SEMESTER_TYPE_MONTH_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      semesterTypeMonthList: action.result && action.result,
    };
  }
  case GET_LEAD_CURRENT_SEMESTER_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      leadCurrentSemester: action.result && action.result,
    };
  }
  case GET_ALERT_CONFIG_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      alertConfig: action.result && action.result.data,
    };
  }
  case GET_ALERT_CONFIG_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      configData: action.result && action.result.data,
    };
  }
  case GET_FACULTY_BY_GROUP: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      facultyByGroup: action.result,
    };
  }
  case GET_MENU_ACCESS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      menuAccessList: action && action.result,
      error: false
    };
  }
  case TRIGGER_ATRIUM_REQUESTED: {
    return {
      ...state,
      atriumLoading: true
    };
  }
  case TRIGGER_ATRIUM_SUCCESS: {
    return {
      ...state,
      atriumLoading: false,
      loaded: true,
      atriumData: action && action?.result && action.result?.data,
      error: false
    };
  }
  case TRIGGER_ATRIUM_FAILURE: {
    return {
      ...state,
      atriumData: null,
      atriumLoading: false,
      loaded: true,
      error: true,
    };
  }

  default: {
    return {
      ...state
    };
  }
  }
};

export const getAccessGroupsList = () => {
  return {
    types: [GET_GROUP_LIST_REQUESTED, GET_GROUP_LIST_SUCCESS, GET_GROUP_LIST_FAILURE],
    promise: (client) => client.get('getAccessGroupsList')
  };
};

export const addGroup = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addAccessGroup', { data })
  };
};

export const updateGroup = (data) => {
  return {
    types: [UPDATE_ACTION_REQUESTED, UPDATE_ACTION_SUCCESS, UPDATE_ACTION_FAILURE],
    promise: (client) => client.put('updateAccessGroup', { data })
  };
};

export const deleteGroup = (groupId, userId = 1) => {
  return {
    types: [DELETE_ACTION_REQUESTED, DELETE_ACTION_SUCCESS, DELETE_ACTION_FAILURE],
    promise: (client) => client.del(`deleteAccessGroup/?groupId=${groupId}&userId=${userId}`)
  };
};

export const getAccessGroupsHierarchy = () => {
  return {
    types: [GET_GROUP_HIERARCHY_REQUESTED, GET_GROUP_HIERARCHY_SUCCESS, GET_GROUP_HIERARCHY_FAILURE],
    promise: (client) => client.get('getAccessGroupsHierarchy')
  };
};

export const getComponentsList = (params) => {
  return {
    types: [GET_COMPONENTS_LIST_REQUESTED, GET_COMPONENTS_LIST_SUCCESS, GET_COMPONENTS_LIST_FAILURE],
    promise: (client) => client.get('getComponentsList', { params })
  };
};

export const getComponentData = (params) => {
  return {
    types: [GET_COMPONENTS_LIST_REQUESTED, GET_COMPONENTS_DATA_SUCCESS, GET_COMPONENTS_LIST_FAILURE],
    promise: (client) => client.get('getComponentsList', { params })
  };
};

export const getGroupAccessRights = (params) => {
  return {
    types: [GET_ACCESS_RIGHTS_REQUESTED, GET_ACCESS_RIGHTS_SUCCESS, GET_ACCESS_RIGHTS_FAILURE],
    promise: (client) => client.get('getAccessRights', { params: { ...params, userId: getAuthUserId() } })
  };
};

export const updateAccessRights = (data) => {
  return {
    types: [UPDATE_ACTION_REQUESTED, UPDATE_ACTION_SUCCESS, UPDATE_ACTION_FAILURE],
    promise: (client) => client.post('updateAccessRights', { data })
  };
};

export const addComponent = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addComponent', { data })
  };
};

export const updateComponent = (data) => {
  return {
    types: [UPDATE_ACTION_REQUESTED, UPDATE_ACTION_SUCCESS, UPDATE_ACTION_FAILURE],
    promise: (client) => client.put('updateComponent', { data })
  };
};

export const deleteComponent = (componentId, userId = 1) => {
  return {
    types: [DELETE_ACTION_REQUESTED, DELETE_ACTION_SUCCESS, DELETE_ACTION_FAILURE],
    promise: (client) => client.del(`deleteComponent/?componentId=${componentId}&userId=${userId}`)
  };
};

export const getMenuList = () => {
  return {
    types: [GET_MENU_LIST_REQUESTED, GET_MENU_LIST_SUCCESS, GET_MENU_LIST_FAILURE],
    promise: (client) => client.get('getMenuList')
  };
};

export const addMenu = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addMenu', { data })
  };
};

export const updateMenu = (data) => {
  return {
    types: [UPDATE_ACTION_REQUESTED, UPDATE_ACTION_SUCCESS, UPDATE_ACTION_FAILURE],
    promise: (client) => client.post('updateMenu', { data })
  };
};

export const deleteMenu = (menuId, userId = 1) => {
  return {
    types: [DELETE_ACTION_REQUESTED, DELETE_ACTION_SUCCESS, DELETE_ACTION_FAILURE],
    promise: (client) => client.del(`deleteMenu/?menuId=${menuId}&userId=${userId}`)
  };
};

export const updateUserRights = (data) => {
  return {
    types: [UPDATE_ACTION_REQUESTED, UPDATE_ACTION_SUCCESS, UPDATE_ACTION_FAILURE],
    promise: (client) => client.post('updateUserRights', { data })
  };
};

export const getUserAccessDetails = (userId) => {
  return {
    types: [GET_USER_DETAILS_REQUESTED, GET_USER_DETAILS_SUCCESS, GET_USER_DETAILS_FAILURE],
    promise: (client) => client.get(`getUserAccessDetails/?userId=${userId}`)
  };
};

export const getUserList = (params) => {
  return {
    types: [GET_USER_LIST_REQUESTED, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE],
    promise: (client) => client.get('getUserList', { params })
  };
};

export const deleteUser = (userDetailsId, loggedInUserId) => {
  return {
    types: [DELETE_ACTION_REQUESTED, DELETE_ACTION_SUCCESS, DELETE_ACTION_FAILURE],
    promise: (client) => client.del(`deleteUser/?userDetailsId=${userDetailsId}&loggedInUserId=${loggedInUserId}`)
  };
};

export const getUserAccessConfig = (groupId) => {
  return {
    types: [GET_USER_ACCESS_CONFIG_REQUESTED, GET_USER_ACCESS_CONFIG_SUCCESS, GET_USER_ACCESS_CONFIG_FAILURE],
    promise: (client) => client.get(`getUserAccessConfig/?groupId=${groupId}&userId=${getAuthUserId()}`)
  };
};

export const getSupervisorList = (groupId) => {
  return {
    types: [GET_SUPERVISOR_REQUESTED, GET_SUPERVISOR_SUCCESS, GET_SUPERVISOR_FAILURE],
    promise: (client) => client.get(`getSupervisorList/?groupId=${groupId}`)
  };
};

export const uploadData = (data, uri) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post(`adminUploadXl/${uri}`, { data, isMultiPart: true })
  };
};

export const getUserNames = () => {
  return {
    types: [GET_USER_NAMES_REQUESTED, GET_USER_NAMES_SUCCESS, GET_USER_NAMES_FAILURE],
    promise: (client) => client.get('userNames')
  };
};

export const getBannerList = (params) => {
  return {
    types: [GET_BANNER_LIST_REQUESTED, GET_BANNER_LIST_SUCCESS, GET_BANNER_LIST_FAILURE],
    promise: (client) => client.get('getBannerList', { params })
  };
};

export const addBanner = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addBanner', { data, isMultiPart: true })
  };
};

export const toggleStatusBanner = (status, id) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post(`toggleStatusBanner/?bannerId=${id}&status=${status}`)
  };
};

export const getBannerHomePage = () => {
  return {
    types: [GET_BANNER_HOME_LIST_REQUESTED, GET_BANNER_HOME_LIST_SUCCESS, GET_BANNER_HOME_LIST_FAILURE],
    promise: (client) => client.get('getBannerHomePage')
  };
};

export const setSelectedAccountingList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_SELECTED_ACCOUNTING_LIST_SUCCESS,
      payload: data,
    });
  };
};

export const showAccountingStep2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_STEP2,
      payload: data
    });
  };
};

export const setSelectedFinancialList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_SELECTED_FINANCIAL_LIST_SUCCESS,
      payload: data
    });
  };
};

export const showFinancialStep2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_FINANCIAL_STEP2,
      payload: data
    });
  };
};

export const getSemesterList = () => {
  return {
	  types: [GET_INSTALLMENT_END_DATE_SEMESTER_REQUESTED, GET_INSTALLMENT_END_DATE_SEMESTER_SUCCESS, GET_INSTALLMENT_END_DATE_SEMESTER_FAILURE],
	  promise: (client) => client.get('getSemesterList')
  };
};

export const addUpdateInstallmentEndDate = (data) => {
  return {
	  types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
	  promise: (client) => client.post('addUpdateInstallmentEndDate', { data })
  };
};

export const getAccountHeadList = () => {
  return {
	  types: [GET_ACCOUNTING_HEAD_LIST_REQUESTED, GET_ACCOUNTING_HEAD_LIST_SUCCESS, GET_ACCOUNTING_HEAD_LIST_FAILURE],
	  promise: (client) => client.get('getAccountHeadList')
  };
};

export const getAccountHead = (accountingHeadId) => {
  return {
	  types: [GET_ACCOUNTING_HEAD_REQUESTED, GET_ACCOUNTING_HEAD_SUCCESS, GET_ACCOUNTING_HEAD_FAILURE],
	  promise: (client) => client.get(`getAccountHead/${accountingHeadId}`)
  };
};

export const addUpdateAccountHead = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateAccountHead', { data })
  };
};

export const getMasterType = () => {
  return {
	  types: [GET_MASTER_TYPE_REQUESTED, GET_MASTER_TYPE_SUCCESS, GET_MASTER_TYPE_FAILURE],
	  promise: (client) => client.get('getMasterType')
  };
};

export const getMasterValuesList = (masterType) => {
  return {
	  types: [GET_MASTER_VALUES_LIST_REQUESTED, GET_MASTER_VALUES_LIST_SUCCESS, GET_MASTER_VALUES_LIST_FAILURE],
	  promise: (client) => client.get(`getMasterValuesList/${masterType}`)
  };
};

export const getMasterValues = (masterValueId) => {
  return {
	  types: [GET_MASTER_VALUES_REQUESTED, GET_MASTER_VALUES_SUCCESS, GET_MASTER_VALUES_FAILURE],
	  promise: (client) => client.get(`getMasterValues/${masterValueId}`)
  };
};

export const addUpdateMasterValues = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateMasterValues', { data })
  };
};

export const getFeeHeadList = (params) => {
  return {
	  types: [GET_FEE_HEAD_LIST_REQUESTED, GET_FEE_HEAD_LIST_SUCCESS, GET_FEE_HEAD_LIST_FAILURE],
	  promise: (client) => client.get('getFeeHeadList', { params })
  };
};

export const getFeeHead = (feeHeadId) => {
  return {
	  types: [GET_FEE_HEAD_REQUESTED, GET_FEE_HEAD_SUCCESS, GET_FEE_HEAD_FAILURE],
	  promise: (client) => client.get(`getFeeHead/${feeHeadId}`)
  };
};

export const addUpdateFeeHead = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateFeeHead', { data })
  };
};

export const getRevenueSettings = () => {
  return {
	  types: [GET_REVENUE_SETTINGS_REQUESTED, GET_REVENUE_SETTINGS_SUCCESS, GET_REVENUE_SETTINGS_FAILURE],
	  promise: (client) => client.get('getRevenueSettings')
  };
};

export const saveRevenueSettings = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveRevenueSettings', { data })
  };
};

export const addUpdateFreeGroupHead = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addUpdateFreeGroupHead', { data })
  };
};

export const getFeeGroupHeadList = (params) => {
  return {
	  types: [GET_FEE_GROUP_HEAD_LIST_REQUESTED, GET_FEE_GROUP_HEAD_LIST_SUCCESS, GET_FEE_GROUP_HEAD_LIST_FAILURE],
	  promise: (client) => client.get('getFeeGroupHeadList', { params })
  };
};

export const getFeeGroupHead = (feeGroupHeadId) => {
  return {
	  types: [GET_FEE_GROUP_HEAD_REQUESTED, GET_FEE_GROUP_HEAD_SUCCESS, GET_FEE_GROUP_HEAD_FAILURE],
	  promise: (client) => client.get(`getFeeGroupHead/${feeGroupHeadId}`)
  };
};

export const getFreeGroupNameList = () => {
  return {
	  types: [GET_FEE_GROUP_NAME_LIST_REQUESTED, GET_FEE_GROUP_NAME_LIST_SUCCESS, GET_FEE_GROUP_NAME_LIST_FAILURE],
	  promise: (client) => client.get('getFreeGroupNameList')
  };
};

export const setSelectedInvoiceList = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_SELECTED_INVOICE_LIST_SUCCESS,
      payload: data
    });
  };
};

export const showInvoiceStep2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_INVOICE_STEP2,
      payload: data
    });
  };
};

export const getImmigrationFeeList = (countryId) => {
  return {
    types: [GET_IMMIGRATION_FEE_LIST_REQUESTED, GET_IMMIGRATION_FEE_LIST_SUCCESS, GET_IMMIGRATION_FEE_LIST_FAILURE],
    promise: (client) => client.get(`immigrationFeeList/${countryId}`)
  };
};

export const getImmigrationListById = (id) => {
  return {
    types: [GET_IMMIGRATION_FEE_LIST_BY_ID_REQUESTED, GET_IMMIGRATION_FEE_LIST_BY_ID_SUCCESS, GET_IMMIGRATION_FEE_LIST_BY_ID_FAILURE],
    promise: (client) => client.get(`getImmigrtaionListById/${id}`)
  };
};

export const saveImmigrationFee = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveImmigration', { data })
  };
};

export const bulkUpdateAccounting = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('bulkUpdateAccounting', { params })
  };
};
export const getBulkAccountingList = (params) => {
  return {
    types: [GET_BULK_ACCOUNTING_LIST_REQUESTED, GET_BULK_ACCOUNTING_LIST_SUCCESS, GET_BULK_ACCOUNTING_LIST_FAILURE],
    promise: (client) => client.get('bulkAccountingList', { params })
  };
};

export const BulkUpdateFinancialStatus = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('bulkUpdateFinancial', { params })
  };
};

export const getInvoiceBulkUpdate = (params) => {
  return {
    types: [GET_BULK_UPDATE_INVOICE_LIST_REQUESTED, GET_BULK_UPDATE_INVOICE_LIST_SUCCESS, GET_BULK_UPDATE_INVOICE_LIST_FAILURE],
    promise: (client) => client.get('bulkInvoiceList', { params })
  };
};

export const BulkUpdateInvoiceStatus = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('bulUpdateInvoiceStatus', { params })
  };
};

export const getInstallmentEndDateSemesterData = (semesterId) => {
  return {
    types: [GET_INST_END_DATE_SEM_DATA_REQUESTED, GET_INST_END_DATE_SEM_DATA_SUCCESS, GET_INST_END_DATE_SEM_DATA_FAILURE],
    promise: (client) => client.get(`getInstallmentEndDateSemesterData/${semesterId}`,)
  };
};

export const getLeadSettingDays = () => {
  return {
    types: [GET_LEAD_SETTING_DAYS_REQUESTED, GET_LEAD_SETTING_DAYS_SUCCESS, GET_LEAD_SETTING_DAYS_FAILURE],
    promise: (client) => client.get('getLeadSettingDays')
  };
};

export const getLeadSettingSlots = () => {
  return {
    types: [GET_LEAD_SETTING_TIMESLOT_REQUESTED, GET_LEAD_SETTING_TIMESLOT_SUCCESS, GET_LEAD_SETTING_TIMESLOT_FAILURE],
    promise: (client) => client.get('getLeadSettingSlots')
  };
};

export const getFacultyTimeSlotById = (facultySlotId) => {
  return {
    types: [GET_LEAD_SETTING_TIMESLOT_BY_ID_REQUESTED, GET_LEAD_SETTING_TIMESLOT_BY_ID_SUCCESS, GET_LEAD_SETTING_TIMESLOT_BY_ID_FAILURE],
    promise: (client) => client.get(`getFacultyTimeSlotById/${facultySlotId}`)
  };
};

export const getFacultyTimeSlotList = (params) => {
  return {
    types: [GET_LEAD_SETTING_TIMESLOT_LIST_REQUESTED, GET_LEAD_SETTING_TIMESLOT_LIST_SUCCESS, GET_LEAD_SETTING_TIMESLOT_LIST_FAILURE],
    promise: (client) => client.get('getFacultyTimeSlotList', { params })
  };
};

export const addFacultyTimeSlot = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addFacultyTimeSlot', { data })
  };
};

export const updateFacultyTimeSlot = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('updateFacultyTimeSlot', { data })
  };
};

export const deleteFacultyTimeSlot = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.del('deleteFacultyTimeSlot', { data })
  };
};
export const getFeeSettingsInsurance = (semesterId) => {
  return {
    types: [GET_FEE_SETTINGS_INSURANCE_REQUESTED, GET_FEE_SETTINGS_INSURANCE_SUCCESS, GET_FEE_SETTINGS_INSURANCE_FAILURE],
    promise: (client) => client.get(`feeSettingsInsuranceget/${semesterId}`)
  };
};

export const feeSettingsInsuranceSave = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('feeSettingsInsuranceget', { data })
  };
};

export const feeSettingsList = (id) => {
  return {
    types: [GET_FEE_SETTINGS_REQUESTED, GET_FEE_SETTINGS_SUCCESS, GET_FEE_SETTINGS_FAILURE],
    promise: (client) => client.get(`feeSettingsget/${id}`)
  };
};

export const feeSettingsPost = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('feeSettingsget', { data })
  };
};

export const setSemeserValue = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_SEMESTER_VALUE,
      payload: data,
    });
  };
};

export const feeSettingsHistoryList = (params) => {
  return {
    types: [GET_FEE_SETTINGS_REQUESTED, GET_FEE_SETTINGS_SUCCESS, GET_FEE_SETTINGS_FAILURE],
    promise: (client) => client.get('feeSettingsHistory', { params })
  };
};

export const feeSettingsHistoryPost = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('feeSettingsHistory', { data })
  };
};

export const feeHeadDropdown = () => {
  return {
    types: [GET_FEE_HEAD_DATA_REQUESTED, GET_FEE_HEAD_DATA_SUCCESS, GET_FEE_HEAD_DATA_FAILURE],
    promise: (client) => client.get('feeHeadDropdown')
  };
};

export const getAssignedClasList = (params) => {
  return {
    types: [GET_FEE_SETTINGS_REQUESTED, GET_ASSIGNED_CLASSES, GET_FEE_SETTINGS_FAILURE],
    promise: (client) => client.get('getAssignedClass', { params })
  };
};

export const addAssignClass = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addAssignClass', { data })
  };
};

export const getAssignedClassById = (id) => {
  return {
    types: [GET_FEE_SETTINGS_REQUESTED, GET_ASSIGNED_CLASSES_BY_ID, GET_FEE_SETTINGS_FAILURE],
    promise: (client) => client.get(`getAssignedClassById/${id}`)
  };
};

export const updateAssignClass = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('updateAssignClass', { data })
  };
};

export const deleteAssignedClass = (facultyClassesId) => {
  return {
    types: [DELETE_ACTION_REQUESTED, DELETE_ACTION_SUCCESS, DELETE_ACTION_FAILURE],
    promise: (client) => client.del(`deleteAssignedClass/?facultyClassesId=${facultyClassesId }`)
  };
};
export const getSemesterTypeMonth = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_SEMESTER_TYPE_MONTH_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getSemesterTypeMonth', { params })
  };
};

export const updateLeadCurrentSemister = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.put('updateLeadCurrentSemister', { data })
  };
};

export const getLeadCurrentSemester = () => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_LEAD_CURRENT_SEMESTER_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getLeadCurrentSemester')
  };
};

export const getConfigList = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ALERT_CONFIG_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getConfigList', { params })
  };
};

export const addAlertConfig = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('addAlertConfig', { data })
  };
};

export const getConfigData = (id) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_ALERT_CONFIG_DATA_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get(`getConfigData/${id}`)
  };
};

export const getFacultyByGroup = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_FACULTY_BY_GROUP, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getFacultyByGroup', { params })
  };
};

export const getMenuAccess = (params) => {
  return {
    types: [ADD_ACTION_REQUESTED, GET_MENU_ACCESS_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.get('getMenuAccess', { params })
  };
};

export const saveUpdateMenuAccess = (data) => {
  return {
    types: [ADD_ACTION_REQUESTED, ADD_ACTION_SUCCESS, ADD_ACTION_FAILURE],
    promise: (client) => client.post('saveUpdateMenuAccess', { data })
  };
};

export const triggerAtrium = () => {
  return {
    types: [TRIGGER_ATRIUM_REQUESTED, TRIGGER_ATRIUM_SUCCESS, TRIGGER_ATRIUM_FAILURE],
    promise: (client) => client.get('triggerAtrium')
  };
};
