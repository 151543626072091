/* eslint-disable */

import superagent from 'superagent';
import { APIConfig } from '../constants/index';
import { toast } from "react-toastify";
import { history } from '../history';
// import { userInfo } from '@utility';

const methods = ['get', 'post', 'put', 'patch', 'del'];
const HOSTNAME = APIConfig.hostname;
const ENDPOINTS = APIConfig.endpoints;

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

function Intercept() {
  const callbacks = Array.prototype.slice.call(...arguments);
  return function (req) {
    const callback = req.callback;
    req.callback = (err, res) => {
      callbacks.forEach(function (e) {
        e.call(req, err, res);
      });
      callback.call(req, err, res);
    };
  };
}

const AuthIntercept = Intercept((err, res) => {
  if (res && (res.status === 401 || res.status === 10006)) {
    // route to login
    window.localStorage.clear();
    window.localStorage.setItem('showLogoutNoty', true);
    window.location.pathname = `/login`;
    // window.localStorage.removeItem('location_history');
    // window.history.pushState('', '', `${APP_CONFIG.BASE_URL}/login`);
  }
  else if(!res || res.status == 404){
    window.location.href = `${APP_CONFIG.BASE_URL}/notfound`;
  }
});

export const formatUrl = (path) => {
  let mappedEndpoint = ENDPOINTS[path];
  if (path.indexOf('/') !== -1) {
    mappedEndpoint = '';
    const splitPathArray = path.split('/');
    mappedEndpoint += ENDPOINTS[splitPathArray[0]]+'/';
    splitPathArray.shift();
    mappedEndpoint += splitPathArray.join('/')
  }
  let hostName = HOSTNAME;
  if (mappedEndpoint.includes('/account/login')) {
    hostName = '/consumer';
  } else if (mappedEndpoint.includes('/json')) {
    hostName = HOSTNAMEFORLOCATION;
  } else {
    let apiConfigKey=mappedEndpoint.split('/')[1].replace('/', '')
    hostName = APIConfig[apiConfigKey]
    mappedEndpoint=mappedEndpoint.replace('/'+apiConfigKey, '')
  }
  
  const adjustedPath = mappedEndpoint[0] !== '/' ? hostName + '/' + mappedEndpoint : hostName + mappedEndpoint;
  // console.log('hostName', hostName, adjustedPath)
  return adjustedPath;
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(method => {
      this[method] = (path, { params, data, headers = {}, files, fields, progress, isMultiPart } = {}) =>
        new Promise((resolve, reject) => {
          headers['Accept'] = 'application/json;charset=utf-8';
          headers['Access-Control-Allow-Origin'] = '*';
          // headers['Access-Control-Allow-Origin']='http//52.24.53.5:8080/footsteps';
          // headers['Access-Control-Allow-Methods']='GET, POST, OPTIONS'; 
          // headers['Access-Control-Allow-Headers']='Content-Type, Authorization';
          //headers['Access-Control-Allow-Credentials']='true';
          // headers['Origin']='http://52.24.53.5:8080';
          let request = superagent[method](formatUrl(path))
            .withCredentials(true)
            .use(AuthIntercept)
           .set(headers);
           
          if (params) {
            request.query(params);
          }

          // request.query(DEFAULT_PARAMS);

          if (request.url && (request.url.indexOf('/account/login') == -1)){
            headers['Authorization'] = `Bearer ${JSON.parse(window.localStorage.getItem('userData')) && JSON.parse(window.localStorage.getItem('userData')).access_token}`;
          }

          if (headers) {
            request.set(headers);
          }

          if (this.token) {
            request.set('Authorization', `Bearer ${this.token}`);
          }

          if (files) {
            console.log('in files');
            headers['Content-Type'] = 'applicatoStringtion/x-www-form-urlencoded';
            request.set(headers);
            // request.send(files);
            // files.forEach(file => request.attach(file.key, file.value));
          }

          if (fields) {
            fields.forEach(item => request.field(item.key, item.value));
          //  request.send(data);
          }
          const requrl= request.url.split('?');
          if (requrl[0].indexOf('download') != -1) {
            request.responseType('arraybuffer');
          }
          if (data) {
            // if (path.indexOf('loginAuth') !== -1) {
            //   // headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //   headers['Content-Type'] = 'application/json';
            // } else 
            if(!isMultiPart) {
              headers['Content-Type'] = 'application/json';
            } else {
              // headers['Content-Type'] = '';
            }
            request.set(headers);
            request.send(data);
          }

          if (progress !== undefined && progress !== null) {
            request.on('progress', (e) => {
              store.dispatch({
                type: 'progressLoader/SET_PROGRESS_VALUE',
                payload: e.loaded,
                size: progress.loadSize
              })
            });
          }

          request.end((err, response = {}) => {
            if (err) {
              if ((err.crossDomain || !err.status)) {
                window.localStorage.clear();
                toast.error('Login expired', { toastId: 'Login expired' })
                history.push('/login')
              }
              else if((response.body && response.body?.error === "invalid_token")){
                window.localStorage.clear();
                toast.error('Login expired', { toastId: 'Login expired' })
                history.push('/login')
              }
              reject(response.body || (response.text && JSON.parse(decodeURIComponent(response.text))) || ( err && err.length > 0 && !err?.includes('Access-Control-Allow-Origin') ? err : ''));
            } else {
              const requrl=request.url.split('?');
              if (requrl[0].indexOf('download') != -1) {
                resolve(response);
              } else if (response.text != '') {
                if(path.indexOf('auth') !== -1){
                    resolve(JSON.parse(decodeURIComponent(response.text)));
                } else {
                  if (IsJsonString(response.text)) {
                    resolve(JSON.parse(response.text));
                  }
                  else {
                    resolve((response.text));
                  }
                }
              } else {
                resolve();
              }
            }
          });
        });
    });
  }

  empty() {}
}
