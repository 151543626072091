export default {
  LOGIN_SUCCESS: 'Succesfully Logged In',
  EMP_REFERENCE_ADD_SUCCESS: 'Reference added successfully',
  EMP_REFERENCE_UPDATE_SUCCESS: 'Reference updated successfully',
  ACCESS_GROUP_ADD_SUCCESS: 'Group created successfully',
  ACCESS_GROUP_DELETE_SUCCESS: 'Group deleted successfully',
  ACCESS_GROUP_UPDATE_SUCCESS: 'Group details updated successfully',
  COMPONENT_ADD_SUCCESS: 'Component created successfully',
  COMPONENT_DELETE_SUCCESS: 'Component deleted successfully',
  COMPONENT_UPDATE_SUCCESS: 'Component updated successfully',
  MENU_ADD_SUCCESS: 'Menu created successfully',
  MENU_DELETE_SUCCESS: 'Menu deleted successfully',
  MENU_UPDATE_SUCCESS: 'Menu updated successfully',
  LEAD_STATUS_CHANGE_SUCCESS: 'Lead Status Changed successfully',
  EMP_OFFERLETTER_ADD_SUCCESS: 'Offer Letter added successfully',
  EMP_OFFER_UPDATE_SUCCESS: 'Offer Letter updated successfully',
  EMP_DOCUMENT_ADD_SUCCESS: 'Document added successfully',
  EMP_DOCUMENT_UPDATE_SUCCESS: 'Document updated successfully',
  EMP_BANK_DETAILS_SUCCESS: 'Employee Bank Details Updated successfully',
  EMP_HR_SUCCESS: 'HR Section updated successfully',
  EMP_SALARY_SUCCESS: 'Salary updated successfully',
  LEAD_DOCUMENT_UPDATE_SUCCESS: 'Document updated succesfully',
  LEAD_DOCUMENT_ADD_SUCCESS: 'Document uploaded succesfully',
  EMP_OFFER_DELETE_SUCCESS: 'Offer letter deleted successfully',
  LEAD_ADD_SUCCESS: 'Lead added successfully',
  LEAD_ADDRESS_SUCCESS: 'Lead address updated successfully',
  LEAD_NOTES_ADD: 'Lead Notes Added Successfully',
  LEAD_NOTES_UPDATE: 'Lead Notes Updated Successfully',
  LEAD_PHONE_NOTES_ADD: 'Lead Phone Notes Added Successfully',
  LEAD_PHONE_NOTES_UPDATE: 'Lead Phone Notes Updated Successfully',
  ACCESS_RIGHTS_UPDATE_SUCCESS: 'Access Rights updated successfully',
  EMP_DOC_DELETE_SUCCESS: 'Document deleted successfully',
  SEMESTER_DEFERMENT_SUCCESS: 'Semester deferred successfully',
  LEAD_EMAIL_SUCCESS: 'Email sent',
  EMP_PROFILE_SUCCESS: 'Employee Profile updated successfully',
  EMP_PASSPORT_UPDATE_SUCCESS: 'Passport/Visa updated successfully',
  EMP_PASSPORT_ADD_SUCCESS: 'Passport/Visa added successfully',
  LEAD_EMAIL_TEMPLATE_ADD_SUCCESS: 'Email Template Added Successfully',
  LEAD_EMAIL_TEMPLATE_UPDATE_SUCCESS: 'Email Template Updated Successfully',
  LEAD_BULK_UPLOAD_SUCCESS: 'Bulk Lead Uploaded Successfully',
  LEAD_BACKGROUND_SAVE_SUCCESS: 'Background Details saved successfully',
  LEAD_ADDITIONAL_QUESTIONS_SAVE_SUCCESS: 'Additonal Questions saved successfully',
  LEAD_APPLICATION_SUBMIT: 'Application submitted successfully',
  ACADEMIC_DETAILS_SAVE_SUCCESS: 'Academic Details submitted successfully',
  ACADEMIC_DETAILS_UPDATE_SUCCESS: 'Academic Details updated successfully',
  LEAD_REOPEN_MESSAGE: 'Lead Profile Reopened Sucessfully',
  TASK_ASSIGN_MESSAGE: 'Task Assigned Successfully',
  TASK_UPDATE_MESSAGE: 'Task Updated Successfully',
  FAIR_UPDATE_MESSAGE: 'Fair Updated Successfully',
  FAIR_ADD_MESSAGE: 'Fair Added Successfully',
  LEAD_ESTIMATE_ADD: 'Estimate Added Sucessfully',
  LEAD_BULK_UPDATE_SUCCESS: 'Lead Updated Successfully',
  LEAD_BULK_DELETE_SUCCESS: 'Lead Deleted Successfully',
  DUPLICATE_LEAD_MERGE: 'Leads Merged Successfully',
  EVALUATION_SUCCESS: 'Evaluation form added successfully',
  LEAD_MORE_INFO_UPDATE_SUCCESS: 'Lead Course updated successfully',
  LEAD_REP_VACATION_SUCCESS: 'Lead Rep assigned successfully',
  USER_ACCESS_UPDATE_SUCCESS: 'New user has been created and the credentials has been sent to user email',
  LEAD_REP_VACATION_REVERT_SUCCESS: 'Lead Rep reverted successfully',
  STUDENT_ADDRESS_SUCCESS: 'Address saved successfully!',
  STUDENT_NOTE_UPDATE_SUCCESS: 'Notes updated successfully!',
  STUDENT_NOTE_ADD_SUCCESS: 'Notes added successfully!',
  STUDENT_NOTE_DELETE_SUCCESS: 'Notes deleted successfully!',
  STUDENT_ACCOUNTING_NOTE_UPDATE_SUCCESS: 'Accounting notes updated successfully!',
  STUDENT_ACCOUNTING_NOTE_ADD_SUCCESS: 'Accounting notes added successfully!',
  STUDENT_ACCOUNTING_NOTE_DELETE_SUCCESS: 'Accounting notes deleted successfully!',
  STUDENT_MED6_NOTE_UPDATE_SUCCESS: 'Med6 LOA notes updated successfully!',
  STUDENT_MED6_NOTE_ADD_SUCCESS: 'Med6 LOA notes added successfully!',
  STUDENT_MED6_NOTE_DELETE_SUCCESS: 'Med6 LOA notes deleted successfully!',
  HOSPITAL_ADDED_SUCCESS: 'Hospital added succesfully!',
  HOSPITAL_UPDATED_SUCCESS: 'Hospital updated succesfully!',
  ACADEMIC_RECORDS_1_2_4_SUCCESS: 'Applicant Academic details updated successfully',
  STATUS_CHANGE_ADD_SUCCESS: 'Notes added successfully!',
  STATUS_CHANGE_UPDATE_SUCCESS: 'Status updated successfully!',

};
