/* eslint-disable */
// LeadModule
export const LEAD_STATUS_REQUESTED = 'lead/LEAD_STATUS_REQUESTED';
export const LEAD_STATUS_SUCCESS = 'lead/LEAD_STATUS_SUCCESS';
export const LEAD_STATUS_FAILURE = 'lead/LEAD_STATUS_FAILURE';

export const CAMPUS_LIST_REQUESTED = 'lead/CAMPUS_LIST_REQUESTED';
export const CAMPUS_LIST_SUCCESS = 'lead/CAMPUS_LIST_SUCCESS';
export const CAMPUS_LIST_FAILURE = 'lead/CAMPUS_LIST_FAILURE';

export const SEMESTER_YEAR_REQUESTED = 'lead/SEMESTER_YEAR_REQUESTED';
export const SEMESTER_YEAR_SUCCESS = 'lead/SEMESTER_YEAR_SUCCESS';
export const SEMESTER_YEAR_FAILURE = 'lead/SEMESTER_YEAR_FAILURE';

export const PROGRAM_LIST_REQUESTED = 'lead/PROGRAM_LIST_REQUESTED';
export const PROGRAM_LIST_SUCCESS = 'lead/PROGRAM_LIST_SUCCESS';
export const PROGRAM_LIST_FAILURE = 'lead/PROGRAM_LIST_FAILURE';

export const SEMESTER_LIST_REQUESTED = 'lead/SEMESTER_LIST_REQUESTED';
export const SEMESTER_LIST_SUCCESS = 'lead/SEMESTER_LIST_SUCCESS';
export const SEMESTER_LIST_FAILURE = 'lead/SEMESTER_LIST_FAILURE';

export const SEMESTER_YEAR_LIST_REQUESTED = 'lead/SEMESTER_YEAR_LIST_REQUESTED';
export const SEMESTER_YEAR_LIST_SUCCESS = 'lead/SEMESTER_YEAR_LIST_SUCCESS';
export const SEMESTER_YEAR_LIST_FAILURE = 'lead/SEMESTER_YEAR_LIST_FAILURE';

export const LEAD_PROFILE_REQUESTED = 'lead/LEAD_PROFILE_REQUESTED';
export const LEAD_PROFILE_SUCCESS = 'lead/LEAD_PROFILE_SUCCESS';
export const LEAD_PROFILE_FAILURE = 'lead/LEAD_PROFILE_FAILURE';

export const BIRTH_COUNTRY_REQUESTED = 'lead/BIRTH_COUNTRY_REQUESTED';
export const BIRTH_COUNTRY_SUCCESS = 'lead/BIRTH_COUNTRY_SUCCESS';
export const BIRTH_COUNTRY_FAILURE = 'lead/BIRTH_COUNTRY_FAILURE';

export const SOURCE_LIST_REQUESTED = 'lead/SOURCE_LIST_REQUESTED';
export const SOURCE_LIST_SUCCESS = 'lead/SOURCE_LIST_SUCCESS';
export const SOURCE_LIST_FAILURE = 'lead/SOURCE_LIST_FAILURE';

export const LEAD_ADD_REQUESTED = 'lead/LEAD_ADD_REQUESTED';
export const LEAD_ADD_SUCCESS = 'lead/LEAD_ADD_SUCCESS';
export const LEAD_ADD_FAILURE = 'lead/LEAD_ADD_FAILURE';

export const GET_LEAD_LIST_REQUESTED = 'employee/GET_LEAD_LIST_REQUESTED';
export const GET_LEAD_LIST_SUCCESS = 'employee/GET_LEAD_LIST_SUCCESS';
export const GET_LEAD_LIST_FAILURE = 'employee/GET_LEAD_LIST_FAILURE';

export const LEAD_EMAIL_DATA = '/lead/LEAD_EMAIL_DATA';

export const LEAD_STATUS_DROPDOWN_REQUESTED = 'lead/LEAD_STATUS_DROPDOWN_REQUESTED';
export const LEAD_STATUS_DROPDOWN_SUCCESS = 'lead/LEAD_STATUS_DROPDOWN_SUCCESS';
export const LEAD_STATUS_DROPDOWN_FAILURE = 'lead/LEAD_STATUS_DROPDOWN_FAILURE';

export const UPDATE_LEAD_STATUS_REQUESTED = 'lead/UPDATE_LEAD_STATUS_REQUESTED';
export const UPDATE_LEAD_STATUS_SUCCESS = 'lead/UPDATE_LEAD_STATUS_SUCCESS';
export const UPDATE_LEAD_STATUS_FAILURE = 'lead/UPDATE_LEAD_STATUS_FAILURE';

export const LEAD_TEMPLATE_DROPDOWN_REQUESTED = 'lead/LEAD_TEMPLATE_DROPDOWN_REQUESTED';
export const LEAD_TEMPLATE_DROPDOWN_SUCCESS = 'lead/LEAD_TEMPLATE_DROPDOWN_SUCCESS';
export const LEAD_TEMPLATE_DROPDOWN_FAILURE = 'lead/LEAD_TEMPLATE_DROPDOWN_FAILURE';

export const LEAD_EMAIL_TEMPLATE_REQUESTED = 'lead/LEAD_EMAIL_TEMPLATE_REQUESTED';
export const LEAD_EMAIL_TEMPLATE_SUCCESS = 'lead/LEAD_EMAIL_TEMPLATE_SUCCESS';
export const LEAD_EMAIL_TEMPLATE_FAILURE = 'lead/LEAD_EMAIL_TEMPLATE_FAILURE';

export const LEAD_EMAIL_LIST_REQUESTED = 'lead/LEAD_EMAIL_LIST_REQUESTED';
export const LEAD_EMAIL_LIST_SUCCESS = 'lead/LEAD_EMAIL_LIST_SUCCESS';
export const LEAD_EMAIL_LIST_FAILURE = 'lead/LEAD_EMAIL_LIST_FAILURE';

export const LEAD_EMAIL_SEND_REQUESTED = 'lead/LEAD_EMAIL_SEND_REQUESTED';
export const LEAD_EMAIL_SEND_SUCCESS = 'lead/LEAD_EMAIL_SEND_SUCCESS';
export const LEAD_EMAIL_SEND_FAILURE = 'lead/LEAD_EMAIL_SEND_FAILURE';

export const LEAD_EMAIL_VIEW_REQUESTED = 'lead/LEAD_EMAIL_VIEW_REQUESTED';
export const LEAD_EMAIL_VIEW_SUCCESS = 'lead/LEAD_EMAIL_VIEW_SUCCESS';
export const LEAD_EMAIL_VIEW_FAILURE = 'lead/LEAD_EMAIL_VIEW_FAILURE';

export const GET_LEAD_ADDRESS_REQUESTED = 'lead/GET_LEAD_ADDRESS_REQUESTED';
export const GET_LEAD_ADDRESS_SUCCESS = 'lead/GET_LEAD_ADDRESS_SUCCESS';
export const GET_LEAD_ADDRESS_FAILURE = 'lead/GET_LEAD_ADDRESS_FAILURE';

export const ADD_LEAD_ADDRESS_REQUESTED = 'lead/ADD_LEAD_ADDRESS_REQUESTED';
export const ADD_LEAD_ADDRESS_SUCCESS = 'lead/ADD_LEAD_ADDRESS_SUCCESS';
export const ADD_LEAD_ADDRESS_FAILURE = 'lead/ADD_LEAD_ADDRESS_FAILURE';

export const UPDATE_LEAD_PROFILE_REQUESTED = 'lead/UPDATE_LEAD_PROFILE_REQUESTED';
export const UPDATE_LEAD_PROFILE_SUCCESS = 'lead/UPDATE_LEAD_PROFILE_SUCCESS';
export const UPDATE_LEAD_PROFILE_FAILURE = 'lead/UPDATE_LEAD_PROFILE_FAILURE';

export const SUBJECT_LIST_REQUESTED = 'lead/SUBJECT_LIST_REQUESTED';
export const SUBJECT_LIST_SUCCESS = 'lead/SUBJECT_LIST_SUCCESS';
export const SUBJECT_LIST_FAILURE = 'lead/SUBJECT_LIST_FAILURE';

export const LEAD_TASK_ADD_REQUESTED = 'lead/LEAD_TASK_ADD_REQUESTED';
export const LEAD_TASK_ADD_SUCCESS = 'lead/LEAD_TASK_ADD_SUCCESS';
export const LEAD_TASK_ADD_FAILURE = 'lead/LEAD_TASK_ADD_FAILURE';

export const GET_LEAD_TASK_LIST_REQUESTED = 'lead/LEAD_TASK_LIST_REQUESTED';
export const GET_LEAD_TASK_LIST_SUCCESS = 'lead/LEAD_TASK_LIST_SUCCESS';
export const GET_LEAD_TASK_LIST_FAILURE = 'lead/LEAD_TASK_LIST_FAILURE';

export const UPDATE_LEAD_TASK_REQUESTED = 'lead/UPDATE_LEAD_TASK_REQUESTED';
export const UPDATE_LEAD_TASK_SUCCESS = 'lead/UPDATE_LEAD_TASK_SUCCESS';
export const UPDATE_LEAD_TASK_FAILURE = 'lead/UPDATE_LEAD_TASK_FAILURE';

export const SEMESTER_DEFERMENT_REQUESTED = 'lead/SEMESTER_DEFERMENT_REQUESTED';
export const SEMESTER_DEFERMENT_SUCCESS = 'lead/SEMESTER_DEFERMENT_SUCCESS';
export const SEMESTER_DEFERMENT_FAILURE = 'lead/SEMESTER_DEFERMENT_FAILURE';

export const LEAD_TASK_SEARCH_REQUESTED = 'lead/LEAD_TASK_SEARCH_REQUESTED';
export const LEAD_TASK_SEARCH_SUCCESS = 'lead/LEAD_TASK_SEARCH_SUCCESS';
export const LEAD_TASK_SEARCH_FAILURE = 'lead/LEAD_TASK_SEARCH_FAILURE';

export const STATE_LIST_REQUESTED = 'lead/STATE_LIST_REQUESTED';
export const STATE_LIST_SUCCESS = 'lead/STATE_LIST_SUCCESS';
export const STATE_LIST_FAILURE = 'lead/STATE_LIST_FAILURE';

export const STATE_CURRENT_LIST_REQUESTED = 'lead/STATE_CURRENT_LIST_REQUESTED';
export const STATE_CURRENT_LIST_SUCCESS = 'lead/STATE_CURRENT_LIST_SUCCESS';
export const STATE_CURRENT_LIST_FAILURE = 'lead/STATE_CURRENT_LIST_FAILURE';

export const LEAD_COURSE_LIST_REQUESTED = 'lead/LEAD_COURSE_LIST_REQUESTED';
export const LEAD_COURSE_LIST_SUCCESS = 'lead/LEAD_COURSE_LIST_SUCCESS';
export const LEAD_COURSE_LIST_FAILURE = 'lead/LEAD_COURSE_LIST_FAILURE';

export const LEAD_STAGE_REQUESTED = 'lead/LEAD_STAGE_REQUESTED';
export const LEAD_STAGE_SUCCESS = 'lead/LEAD_STAGE_SUCCESS';
export const LEAD_STAGE_FAILURE = 'lead/LEAD_STAGE_FAILURE';

export const LEAD_DEGREE_LIST_REQUESTED = 'lead/LEAD_DEGREE_LIST_REQUESTED';
export const LEAD_DEGREE_LIST_SUCCESS = 'lead/LEAD_DEGREE_LIST_SUCCESS';
export const LEAD_DEGREE_LIST_FAILURE = 'lead/LEAD_DEGREE_LIST_FAILURE';

export const LEAD_SCHOOL_LIST_REQUESTED = 'lead/LEAD_SCHOOL_LIST_REQUESTED';
export const LEAD_SCHOOL_LIST_SUCCESS = 'lead/LEAD_SCHOOL_LIST_SUCCESS';
export const LEAD_SCHOOL_LIST_FAILURE = 'lead/LEAD_SCHOOL_LIST_FAILURE';

export const LEAD_DOCUMENT_LIST_REQUESTED = 'lead/LEAD_DOCUMENT_LIST_REQUESTED';
export const LEAD_DOCUMENT_LIST_SUCCESS = 'lead/LEAD_DOCUMENT_LIST_SUCCESS';
export const LEAD_DOCUMENT_LIST_FAILURE = 'lead/LEAD_DOCUMENT_LIST_FAILURE';

export const LEAD_DOCUMENT_UPLOAD_REQUESTED = 'lead/LEAD_DOCUMENT_UPLOAD_REQUESTED';
export const LEAD_DOCUMENT_UPLOAD_SUCCESS = 'lead/LEAD_DOCUMENT_UPLOAD_SUCCESS';
export const LEAD_DOCUMENT_UPLOAD_FAILURE = 'lead/LEAD_DOCUMENT_UPLOAD_FAILURE';

export const DOCUMENT_LIST_REQUESTED = 'lead/DOCUMENT_LIST_REQUESTED';
export const DOCUMENT_LIST_SUCCESS = 'lead/DOCUMENT_LIST_SUCCESS';
export const DOCUMENT_LIST_FAILURE = 'lead/DOCUMENT_LIST_FAILURE';

export const DOCUMENT_FETCH_REQUESTED = 'lead/DOCUMENT_FETCH_REQUESTED';
export const DOCUMENT_FETCH_SUCCESS = 'lead/DOCUMENT_FETCH_SUCCESS';
export const DOCUMENT_FETCH_FAILURE = 'lead/DOCUMENT_FETCH_FAILURE';

export const DOCUMENT_DELETE_REQUESTED = 'lead/DOCUMENT_DELETE_REQUESTED';
export const DOCUMENT_DELETE_SUCCESS = 'lead/DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_FAILURE = 'lead/DOCUMENT_DELETE_FAILURE';

export const LEAD_NOTE_ADD_REQUESTED = 'lead/LEAD_NOTE_ADD_REQUESTED';
export const LEAD_NOTE_ADD_SUCCESS = 'lead/LEAD_NOTE_ADD_SUCCESS';
export const LEAD_NOTE_ADD_FAILURE = 'lead/LEAD_NOTE_ADD_FAILURE';

export const GET_LEAD_NOTES_LIST_REQUESTED = 'lead/GET_LEAD_NOTES_LIST_REQUESTED';
export const GET_LEAD_NOTES_LIST_SUCCESS = 'lead/GET_LEAD_NOTES_LIST_SUCCESS';
export const GET_LEAD_NOTES_LIST_FAILURE = 'lead/GET_LEAD_NOTES_LIST_FAILURE';

export const GET_NOTES_BY_ID_REQUESTED = 'lead/GET_NOTES_BY_ID_REQUESTED';
export const GET_NOTES_BY_ID_SUCCESS = 'lead/GET_NOTES_BY_ID_SUCCESS';
export const GET_NOTES_BY_ID_FAILURE = 'lead/GET_NOTES_BY_ID_FAILURE';

export const SEMESTER_LIST_FROM_COURSEID_REQUESTED = 'lead/SEMESTER_LIST_FROM_COURSEID_REQUESTED';
export const SEMESTER_LIST_FROM_COURSEID_SUCCESS = 'lead/SEMESTER_LIST_FROM_COURSEID_SUCCESS';
export const SEMESTER_LIST_FROM_COURSEID_FAILURE = 'lead/SEMESTER_LIST_FROM_COURSEID_FAILURE';

export const TASK_SEARCH_REQUESTED = 'lead/TASK_SEARCH_REQUESTED';
export const TASK_SEARCH_SUCCESS = 'lead/TASK_SEARCH_SUCCESS';
export const TASK_SEARCH_FAILURE = 'lead/TASK_SEARCH_FAILURE';

export const EXPORT_LEAD_REQUESTED = 'lead/EXPORT_LEAD_REQUESTED';
export const EXPORT_LEAD_SUCCESS = 'lead/EXPORT_LEAD_SUCCESS';
export const EXPORT_LEAD_FAILURE = 'lead/EXPORT_LEAD_FAILURE';

export const GET_LEAD_COLLEGE_FAIR_REQUESTED = 'lead/GET_LEAD_COLLEGE_FAIR_REQUESTED';
export const GET_LEAD_COLLEGE_FAIR_SUCCESS = 'lead/GET_LEAD_COLLEGE_FAIR_SUCCESS';
export const GET_LEAD_COLLEGE_FAIR_FAILURE = 'lead/GET_LEAD_COLLEGE_FAIR_FAILURE';

export const LEAD_SEARCH_REQUESTED = 'lead/LEAD_SEARCH_REQUESTED';
export const LEAD_SEARCH_SUCCESS = 'lead/LEAD_SEARCH_SUCCESS';
export const LEAD_SEARCH_FAILURE = 'lead/LEAD_SEARCH_FAILURE';

export const TASK_SEARCH_ID_REQUESTED = 'lead/TASK_SEARCH_ID_REQUESTED';
export const TASK_SEARCH_ID_SUCCESS = 'lead/TASK_SEARCH_ID_SUCCESS';
export const TASK_SEARCH_ID_FAILURE = 'lead/TASK_SEARCH_ID_FAILURE';

export const APPLICATION_BACKGROUND_SAVE_REQUESTED = 'lead/APPLICATION_BACKGROUND_SAVE_REQUESTED';
export const APPLICATION_BACKGROUND_SAVE_SUCCESS = 'lead/APPLICATION_BACKGROUND_SAVE_SUCCESS';
export const APPLICATION_BACKGROUND_SAVE_FAILURE = 'lead/APPLICATION_BACKGROUND_SAVE_FAILURE';

export const APPLICATION_BACKGROUND_GET_REQUESTED = 'lead/APPLICATION_BACKGROUND_GET_REQUESTED';
export const APPLICATION_BACKGROUND_GET_SUCCESS = 'lead/APPLICATION_BACKGROUND_GET_SUCCESS';
export const APPLICATION_BACKGROUND_GET_FAILURE = 'lead/APPLICATION_BACKGROUND_GET_FAILURE';

export const ADDITIONAL_QUESTIONS_GET_REQUESTED = 'lead/ADDITIONAL_QUESTIONS_GET_REQUESTED';
export const ADDITIONAL_QUESTIONS_GET_SUCCESS = 'lead/ADDITIONAL_QUESTIONS_GET_SUCCESS';
export const ADDITIONAL_QUESTIONS_GET_FAILURE = 'lead/ADDITIONAL_QUESTIONS_GET_FAILURE';

export const SUBMIT_APPLICATION_GET_REQUESTED = 'lead/SUBMIT_APPLICATION_GET_REQUESTED';
export const SUBMIT_APPLICATION_GET_SUCCESS = 'lead/SUBMIT_APPLICATION_GET_SUCCESS';
export const SUBMIT_APPLICATION_GET_FAILURE = 'lead/SUBMIT_APPLICATION_GET_FAILURE';

export const LEAD_TEST_GET_REQUESTED = 'lead/LEAD_TEST_GET_REQUESTED';
export const LEAD_TEST_GET_SUCCESS = 'lead/LEAD_TEST_GET_SUCCESS';
export const LEAD_TEST_GET_FAILURE = 'lead/LEAD_TEST_GET_FAILURE';

export const ACADEMIC_LIST_GET_REQUESTED = 'lead/ACADEMIC_LIST_GET_REQUESTED';
export const ACADEMIC_LIST_GET_SUCCESS = 'lead/ACADEMIC_LIST_GET_SUCCESS';
export const ACADEMIC_LIST_GET_FAILURE = 'lead/ACADEMIC_LIST_GET_FAILURE';

export const MONTH_LIST_GET_REQUESTED = 'lead/MONTH_LIST_GET_REQUESTED';
export const MONTH_LIST_GET_SUCCESS = 'lead/MONTH_LIST_GET_SUCCESS';
export const MONTH_LIST_GET_FAILURE = 'lead/MONTH_LIST_GET_FAILURE';

export const GET_EMPLOYEE_BY_TYPE_LIST_REQUESTED = 'lead/GET_EMPLOYEE_BY_TYPE_LIST_REQUESTED';
export const GET_EMPLOYEE_BY_TYPE_LIST_SUCCESS = 'lead/GET_EMPLOYEE_BY_TYPE_LIST_SUCCESS';
export const GET_EMPLOYEE_BY_TYPE_LIST_FAILURE = 'lead/GET_EMPLOYEE_BY_TYPE_LIST_FAILURE';

export const GET_BULK_UPLOAD_LIST_REQUESTED = 'lead/GET_BULK_UPLOAD_LIST_REQUESTED';
export const GET_BULK_UPLOAD_LIST_SUCEESS = 'lead/GET_BULK_UPLOAD_LIST_SUCEESS';
export const GET_BULK_UPLOAD_LIST_FAILURE = 'lead/GET_BULK_UPLOAD_LIST_FAILURE';

export const FAIR_LIST_REQUESTED = 'lead/FAIR_LIST_REQUESTED';
export const FAIR_LIST_SUCCESS = 'lead/FAIR_LIST_SUCCESS';
export const FAIR_LIST_FAILURE = 'lead/FAIR_LIST_FAILURE';

export const VIEW_LEAD_BY_FAIR_REQUESTED = 'lead/VIEW_LEAD_BY_FAIR_REQUESTED';
export const VIEW_LEAD_BY_FAIR_SUCCESS = 'lead/VIEW_LEAD_BY_FAIR_SUCCESS';
export const VIEW_LEAD_BY_FAIR_FAILURE = 'lead/VIEW_LEAD_BY_FAIR_FAILURE';

export const GET_APPLICATION_DOWNLOAD_REQUESTED = 'lead/GET_APPLICATION_DOWNLOAD_REQUESTED';
export const GET_APPLICATION_DOWNLOAD_SUCCESS = 'lead/GET_APPLICATION_DOWNLOAD_SUCCESS';
export const GET_APPLICATION_DOWNLOAD_FAILURE = 'lead/GET_APPLICATION_DOWNLOAD_FAILURE';

export const TEMPLATE_SEARCH_REQUESTED = 'lead/TEMPLATE_SEARCH_REQUESTED';
export const TEMPLATE_SEARCH_SUCCESS = 'lead/TEMPLATE_SEARCH_SUCCESS';
export const TEMPLATE_SEARCH_FAILURE = 'lead/TEMPLATE_SEARCH_FAILURE';

export const ADD_OR_UPDATE_REQUESTED = 'lead/ADD_OR_UPDATE_REQUESTED';
export const ADD_OR_UPDATE_SUCCESS = 'lead/ADD_OR_UPDATE_SUCCESS';
export const ADD_OR_UPDATE_FAILURE = 'lead/ADD_OR_UPDATE_FAILURE';

export const GET_ESTIMATE_LIST_BY_LEADID_REQUESTED = 'lead/GET_ESTIMATE_LIST_BY_LEADID_REQUESTED';
export const GET_ESTIMATE_LIST_BY_LEADID_SUCCESS = 'lead/GET_ESTIMATE_LIST_BY_LEADID_SUCCESS';
export const GET_ESTIMATE_LIST_BY_LEADID_FAILURE = 'lead/GET_ESTIMATE_LIST_BY_LEADID_FAILURE';

export const GET_ESTIMATE_BY_ID_REQUESTED = 'lead/GET_ESTIMATE_BY_ID_REQUESTED';
export const GET_ESTIMATE_BY_ID_SUCCESS = 'lead/GET_ESTIMATE_BY_ID_SUCCESS';
export const GET_ESTIMATE_BY_ID_FAILURE = 'lead/GET_ESTIMATE_BY_ID_FAILURE';

export const BULK_DELETE_REQUESTED = 'lead/BULK_DELETE_REQUESTED';
export const BULK_DELETE_SUCCESS = 'lead/BULK_DELETE_SUCCESS';
export const BULK_DELETE_FAILURE = 'lead/BULK_DELETE_FAILURE';

export const SEARCH_DUPLICATE_LEAD_REQUESTED = 'lead/SEARCH_DUPLICATE_LEAD_REQUESTED';
export const SEARCH_DUPLICATE_LEAD_SUCCESS = 'lead/SEARCH_DUPLICATE_LEAD_SUCCESS';
export const SEARCH_DUPLICATE_LEAD_FAILURE = 'lead/SEARCH_DUPLICATE_LEAD_FAILURE';

export const DUPLICATE_LEAD_COUNT_REQUESTED = 'lead/DUPLICATE_LEAD_COUNT_REQUESTED';
export const DUPLICATE_LEAD_COUNT_SUCCESS = 'lead/DUPLICATE_LEAD_COUNT_SUCCESS';
export const DUPLICATE_LEAD_COUNT_FAILURE = 'lead/DUPLICATE_LEAD_COUNT_FAILURE';

export const DUPLICATE_COUNT_REQUESTED = 'lead/DUPLICATE_COUNT_REQUESTED';
export const DUPLICATE_COUNT_SUCCESS = 'lead/DUPLICATE_COUNT_SUCCESS';
export const DUPLICATE_COUNT_FAILURE = 'lead/DUPLICATE_COUNT_FAILURE';

export const SELECTED_LEAD_MERGE_SUCCESS = 'lead/SELECTED_LEAD_MERGE_SUCCESS';

export const DOCUMENTS_DATA_REQUESTED = 'lead/DOCUMENTS_DATA_REQUESTED';
export const DOCUMENTS_DATA_SUCCESS = 'lead/DOCUMENTS_DATA_SUCCESS';
export const DOCUMENTS_DATA_FAILURE = 'lead/DOCUMENTS_DATA_FAILURE';

export const DUPLICATE_LEAD_SEARCH_REQUESTED = 'lead/DUPLICATE_LEAD_SEARCH_REQUESTED';
export const DUPLICATE_LEAD_SEARCH_SUCCESS = 'lead/DUPLICATE_LEAD_SEARCH_SUCCESS';
export const DUPLICATE_LEAD_SEARCH_FAILURE = 'lead/DUPLICATE_LEAD_SEARCH_FAILURE';

export const LEAD_ACTIVITY_LOGS_REQUESTED = 'lead/LEAD_ACTIVITY_LOGS_REQUESTED';
export const LEAD_ACTIVITY_LOGS_SUCCESS = 'lead/LEAD_ACTIVITY_LOGS_SUCCESS';
export const LEAD_ACTIVITY_LOGS_FAILURE = 'lead/LEAD_ACTIVITY_LOGS_FAILURE';

export const LEAD_EVAL_INFO_REQUESTED = 'lead/LEAD_EVAL_INFO_REQUESTED';
export const LEAD_EVAL_INFO_SUCCESS = 'lead/LEAD_EVAL_INFO_SUCCESS';
export const LEAD_EVAL_INFO_FAILURE = 'lead/LEAD_EVAL_INFO_FAILURE';

export const LEAD_VACATION_LIST_REQUESTED = 'lead/LEAD_VACATION_LIST_REQUESTED';
export const LEAD_VACATION_LIST_SUCCESS = 'lead/LEAD_VACATION_LIST_SUCCESS';
export const LEAD_VACATION_LIST_FAILURE = 'lead/LEAD_VACATION_LIST_FAILURE';

export const LEAD_ACTIVITY_COUNT_LIST_REQUESTED = 'lead/LEAD_ACTIVITY_COUNT_LIST_REQUESTED';
export const LEAD_ACTIVITY_COUNT_LIST_SUCCESS = 'lead/LEAD_ACTIVITY_COUNT_LIST_SUCCESS';
export const LEAD_ACTIVITY_COUNT_LIST_FAILURE = 'lead/LEAD_ACTIVITY_COUNT_LIST_FAILURE';

export const LEAD_ACTIVITY_COUNT_LEAD_LIST_REQUESTED = 'lead/LEAD_ACTIVITY_COUNT_LEAD_LIST_REQUESTED';
export const LEAD_ACTIVITY_COUNT_LEAD_LIST_SUCCESS = 'lead/LEAD_ACTIVITY_COUNT_LEAD_LIST_SUCCESS';
export const LEAD_ACTIVITY_COUNT_LEAD_LIST_FAILURE = 'lead/LEAD_ACTIVITY_COUNT_LEAD_LIST_FAILURE';

export const LEAD_TASK_COUNT_LIST_REQUESTED = 'lead/LEAD_TASK_COUNT_LIST_REQUESTED';
export const LEAD_TASK_COUNT_LIST_SUCCESS = 'lead/LEAD_TASK_COUNT_LIST_SUCCESS';
export const LEAD_TASK_COUNT_LIST_FAILURE = 'lead/LEAD_TASK_COUNT_LIST_FAILURE';


export const STATUS_CONVERSION_LIST_REQUESTED = 'lead/STATUS_CONVERSION_LIST_REQUESTED';
export const STATUS_CONVERSION_LIST_SUCCESS = 'lead/STATUS_CONVERSION_LIST_SUCCESS';
export const STATUS_CONVERSION_LIST_FAILURE = 'lead/STATUS_CONVERSION_LIST_FAILURE';

export const PENDING_LEADS_REQUESTED = 'lead/PENDING_LEADS_REQUESTED';
export const PENDING_LEADS_SUCCESS = 'lead/PENDING_LEADS_SUCCESS';
export const PENDING_LEADS_FAILURE = 'lead/PENDING_LEADS_FAILURE';

export const STATUS_WISE_COUNT_REQUESTED = 'lead/STATUS_WISE_COUNT_REQUESTED';
export const STATUS_WISE_COUNT_SUCCESS = 'lead/STATUS_WISE_COUNT_SUCCESS';
export const STATUS_WISE_COUNT_FAILURE = 'lead/STATUS_WISE_COUNT_FAILURE';

export const STATUS_WISE_LEAD_REP_REQUESTED = 'lead/STATUS_WISE_LEAD_REP_REQUESTED';
export const STATUS_WISE_LEAD_REP_SUCCESS = 'lead/STATUS_WISE_LEAD_REP_SUCCESS';
export const STATUS_WISE_LEAD_REP_FAILURE = 'lead/STATUS_WISE_LEAD_REP_FAILURE';

export const LEAD_LIST_BY_STATUS_REQUESTED = 'lead/LEAD_LIST_BY_STATUS_REQUESTED';
export const LEAD_LIST_BY_STATUS_SUCCESS = 'lead/LEAD_LIST_BY_STATUS_SUCCESS';
export const LEAD_LIST_BY_STATUS_FAILURE = 'lead/LEAD_LIST_BY_STATUS_FAILURE';

export const LEAD_LIST_BY_REP_STATUS_REQUESTED = 'lead/LEAD_LIST_BY_REP_STATUS_REQUESTED';
export const LEAD_LIST_BY_REP_STATUS_SUCCESS = 'lead/LEAD_LIST_BY_REP_STATUS_SUCCESS';
export const LEAD_LIST_BY_REP_STATUS_FAILURE = 'lead/LEAD_LIST_BY_REP_STATUS_FAILURE';

export const GET_REGISTRAR_LIST_REQUESTED = 'lead/GET_REGISTRAR_LIST_REQUESTED';
export const GET_REGISTRAR_LIST_SUCCESS = 'lead/GET_REGISTRAR_LIST_SUCCESS';
export const GET_REGISTRAR_LIST_FAILURE = 'lead/GET_REGISTRAR_LIST_FAILURE';

export const SEND_DECISION_REQUESTED = 'lead/SEND_DECISION_REQUESTED';
export const SEND_DECISION_SUCCESS = 'lead/SEND_DECISION_SUCCESS';
export const SEND_DECISION_FAILURE = 'lead/SEND_DECISION_FAILURE';

export const FACULTY_TIMESLOT_REQUESTED = 'lead/FACULTY_TIMESLOT_REQUESTED';
export const FACULTY_TIMESLOT_SUCCESS = 'lead/FACULTY_TIMESLOT_SUCCESS';
export const FACULTY_TIMESLOT_FAILURE = 'lead/FACULTY_TIMESLOT_FAILURE';

export const GET_ACCEPTANCE_DATA_REQUESTED = 'lead/GET_ACCEPTANCE_DATA_REQUESTED';
export const GET_ACCEPTANCE_DATA_SUCCESS = 'lead/GET_ACCEPTANCE_DATA_SUCCESS';
export const GET_ACCEPTANCE_DATA_FAILURE = 'lead/GET_ACCEPTANCE_DATA_FAILURE';

export const GET_COHORT_LIST_REQUESTED = 'lead/GET_COHORT_LIST_REQUESTED';
export const GET_COHORT_LIST_SUCCESS = 'lead/GET_COHORT_LIST_SUCCESS';
export const GET_COHORT_LIST_FAILURE = 'lead/GET_COHORT_LIST_FAILURE';

export const GET_NEXT_SEMESTER_REQUESTED = 'lead/GET_NEXT_SEMESTER_REQUESTED';
export const GET_NEXT_SEMESTER_SUCCESS = 'lead/GET_NEXT_SEMESTER_SUCCESS';
export const GET_NEXT_SEMESTER_FAILURE = 'lead/GET_NEXT_SEMESTER_FAILURE';

export const GET_LEAD_PAID_LIST_SUCCESS = "lead/GET_LEAD_PAID_LIST_SUCCESS";
export const GET_LEAD_STATUS_DATA_REQUESTED = 'lead/GET_LEAD_STATUS_DATA_REQUESTED';
export const GET_LEAD_STATUS_DATA_SUCCESS = 'lead/GET_LEAD_STATUS_DATA_SUCCESS';
export const GET_LEAD_STATUS_DATA_FAILURE = 'lead/GET_LEAD_STATUS_DATA_FAILURE';

export const GET_ADVANCE_SEARCH_LIST_SUCCESS ='lead/GET_ADVANCE_SEARCH_LIST_SUCCESS';

export const GET_REPORT_SEAT_DEPOSIT = 'lead/GET_REPORT_SEAT_DEPOSIT';

export const GET_INTERVIEW_SCHEDULED_SUCCESS = 'lead/GET_INTERVIEW_SCHEDULED_SUCCESS';

export const EXAMLIST_SEARCH_SUCCESS ='lead/EXAMLIST_SEARCH_SUCCESS';

export const GET_PAID_LIST_REPORT_SUCCESS='lead/GET_PAID_LIST_REPORT_SUCCESS';
export const GET_PAID_LIST_REPORT_FAILURE='lead/GET_PAID_LIST_REPORT_FAILURE';
export const GET_PAID_LIST_REPORT_REQUESTED='lead/GET_PAID_LIST_REPORT_REQUESTED';

export const GET_APPLICANT_DETAILS_SUCCESS='lead/GET_APPLICANT_DETAILS_SUCCESS';
export const GET_APPLICANT_DETAILS_FAILURE='lead/GET_APPLICANT_DETAILS_FAILURE';
export const GET_APPLICANT_DETAILS_REQUESTED='lead/GET_APPLICANT_DETAILS_REQUESTED';

export const POST_TERMS_AND_CONDITIONS_REQUESTED = 'lead/POST_TERMS_AND_CONDITIONS_REQUESTED' 
export const POST_TERMS_AND_CONDITIONS_SUCCESS = 'lead/POST_TERMS_AND_CONDITIONS_SUCCESS'
export const POST_TERMS_AND_CONDITIONS_FAILURE = 'lead/POST_TERMS_AND_CONDITIONS_FAILURE'

export const GET_LEAD_DASHBOARD_CHART_DATA = 'lead/GET_LEAD_DASHBOARD_CHART_DATA'
export const LEAD_DASHBOARD_STATUS_COUNT_REQUESTED = 'lead/LEAD_DASHBOARD_STATUS_COUNT_REQUESTED'
export const GET_LEAD_DASHBOARD_STATUS_COUNT = 'lead/GET_LEAD_DASHBOARD_STATUS_COUNT'
export const LEAD_DASHBOARD_STATUS_COUNT_FAILURE = 'lead/LEAD_DASHBOARD_STATUS_COUNT_FAILURE'
export const PAID_SEAT_DEPOSIT_REQUESTED = 'lead/PAID_SEAT_DEPOSIT_REQUESTED'
export const LEAD_DASHBOARD_PAID_SEAT_DEPOSIT_COUNT = 'lead/LEAD_DASHBOARD_PAID_SEAT_DEPOSIT_COUNT'
export const PAID_SEAT_DEPOSIT_FAILURE = 'lead/PAID_SEAT_DEPOSIT_FAILURE'
export const INTERVIEW_SCHEDULE_COUNT_REQUESTED = 'lead/INTERVIEW_SCHEDULE_COUNT_REQUESTED'
export const INTERVIEW_SCHEDULED_COUNT_REPORT = 'lead/INTERVIEW_SCHEDULED_COUNT_REPORT'
export const INTERVIEW_SCHEDULE_COUNT_FAILURE = 'lead/INTERVIEW_SCHEDULE_COUNT_FAILURE'
export const UPCOMING_TASK_REPORT_REQUESTED = 'lead/UPCOMING_TASK_REPORT_REQUESTED'
export const UPCOMING_TASK_REPORT = 'lead/UPCOMING_TASK_REPORT'
export const UPCOMING_TASK_REPORT_FAILURE = 'lead/UPCOMING_TASK_REPORT_FAILURE'

export const GET_ADVANCE_SEARCH_LIST_REQUESTED ='lead/GET_ADVANCE_SEARCH_LIST_REQUESTED';
export const GET_ADVANCE_SEARCH_LIST_FAILURE ='lead/GET_ADVANCE_SEARCH_LIST_FAILURE';
export const LEAD_ACTIVITY_COUNT_NEW_LEAD_LIST_SUCCESS = 'lead/LEAD_ACTIVITY_COUNT_NEW_LEAD_LIST_SUCCESS';

export const EDUCATIONAL_STATE_LIST_SUCCESS ='lead/EDUCATIONAL_STATE_LIST_SUCCESS';
export const BILLING_STATE_LIST_SUCCESS ='lead/BILLING_STATE_LIST_SUCCESS';

export const GET_ACTIVE_LEADS_SUCCESS = 'lead/GET_ACTIVE_LEADS_SUCCESS';

export const GET_UNASSIGNED_LEADS_SUCCESS = 'lead/GET_UNASSIGNED_LEADS_SUCCESS';

export const GET_SEMESTER_START_DATE_BY_SEMESTER = 'student/GET_SEMESTER_START_DATE_BY_SEMESTER'

export const GET_UNVERIFIED_EMAIL_LIST_REQUESTED = 'lead/GET_UNVERIFIED_EMAIL_LIST_REQUESTED';
export const GET_UNVERIFIED_EMAIL_LIST_SUCCESS = 'lead/GET_UNVERIFIED_EMAIL_LIST_SUCCESS';
export const GET_UNVERIFIED_EMAIL_LIST_FAILURE = 'lead/GET_UNVERIFIED_EMAIL_LIST_FAILURE';

export const GET_UNVERIFIED_EMAIL_REQUESTED = 'lead/GET_UNVERIFIED_EMAIL_REQUESTED';
export const GET_UNVERIFIED_EMAIL_SUCCESS = 'lead/GET_UNVERIFIED_EMAIL_SUCCESS';
export const GET_UNVERIFIED_EMAIL_FAILURE = 'lead/GET_UNVERIFIED_EMAIL_FAILURE';

export const MARK_EMAIL_READ_REQUESTED = 'lead/MARK_EMAIL_READ_REQUESTED';
export const MARK_EMAIL_READ_SUCCESS = 'lead/MARK_EMAIL_READ_SUCCESS';
export const MARK_EMAIL_READ_FAILURE ='lead/MARK_EMAIL_READ_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  profileLoading: false,
  loadingFinanceCleared: false,
  statusCountLoading : false,
  paidSeatDepositLoading : false,
  interviewScheduledLoading : false,
  upcomingTaskReportLoading: false,
  leadStatusdata: {},
  campusListdata: {},
  semesterYearData: {},
  semesterYearList:[],
  programList: {},
  semesterList: {},
  leadProfile: {},
  birthCountries: {},
  sourceList: {},
  leadList: {},
  selectedMail: null,
  leadStatusDropdown: {},
  leadStatusUpdate: {},
  leadAddedResponse: null,
  leadEmailTemplate: {},
  leadEmailTemplateDropdown: {},
  leadEmailList: {},
  leadEmailView: {},
  emailAttachment: {},
  leadAddress: {},
  subjectList: [],
  leadTaskList: {},
  semesterDeferment: {},
  leadTaskSearchResponse: null,
  getCourse: {},
  getStage: {},
  getDegree: {},
  getSchool: {},
  docType: {},
  docList: {},
  docById: {},
  exportLeadList: {},
  emailTemplateList: {},
  collegeFairList: {},
  applicationBackGround: {},
  additionalQuestions: null,
  submitApplicationData: null,
  leadTestData: null,
  academicList: null,
  monthList: null,
  employeeByTypeList: [],
  bulkUploadList: {},
  searchListResponse: null,
  taskSearchByIdResponse: null,
  fairList: null,
  viewLeadByFair: null,
  applicationDownloadData: null,
  leadEstimateList: {},
  duplicateLeadList: null,
  duplicateLeadCount: {},
  duplicateCount: null,
  selectedLeadMerge: null,
  documentsData: null,
  duplicateLeadSearch: null,
  activityList: null,
  getEvalInfo: null,
  repVacationList: null,
  activityCountList: null,
  activityCountLeadList: null,
  reportTaskList: null,
  statusConversionList: null,
  pendingLeadsList: null,
  statusWiseCountList: null,
  statusWiseLeadRepList: null,
  LeadListByRepAndStatus: null,
  leadListByStatus: null,
  financiallyClearedList: null,
  sendDecision: null,
  facultyTimeSlot: null,
  getAcceptanceLetter: null,
  cohortList: [],
  nextSemesterList: null,
  leadPaidList:null,
  statusListData: null,
  advanceReportList: null,
  leadReportSeatDeposit: null,
  leadReportInterviewSchduledList: null,
  paidReportList:null,
  applicantDetails:null,
  chartData : null,
  statusCountData : null,
  paidSeatCountData : null,
  interviewScheduledReportData : null,
  upcomingTaskReportData : null,
  billingStateList: null,
  educationalStateList: null,
  activityCountNewLeadList : null,
  activeLeadList: null,
  unAssignedLeadList:null,
  semsterStartDatebySemester: null,
  getUnverifiedEmailList: null,
  getUnverifiedEmail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LEAD_EMAIL_DATA: {
      return {
        ...state,
        selectedMail: action.payload
      };
    }
    case LEAD_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadStatusdata: action.result,
      };
    }
    case LEAD_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case CAMPUS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case CAMPUS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        campusListdata: action.result,
      };
    }
    case CAMPUS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SEMESTER_YEAR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEMESTER_YEAR_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        semesterYearData: action.result,
      };
    }
    case SEMESTER_YEAR_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        semesterYearData: null,
      };
    }
    case PROGRAM_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PROGRAM_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        programList: action.result,
      };
    }
    case PROGRAM_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SEMESTER_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEMESTER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        semesterList: action.result,
      };
    }
    case SEMESTER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SEMESTER_YEAR_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEMESTER_YEAR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        semesterYearList: action.result && action.result.data,
      };
    }
    case SEMESTER_YEAR_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_PROFILE_REQUESTED: {
      return {
        ...state,
        profileLoading: true,
        loaded: false
      };
    }
    case LEAD_PROFILE_SUCCESS: {
      return {
        ...state,
        profileLoading: false,
        error: false,
        loaded: true,
        leadProfile: action.result,
      };
    }
    case LEAD_PROFILE_FAILURE: {
      return {
        ...state,
        profileLoading: false,
        error: false,
        loaded: true
      };
    }
    case BIRTH_COUNTRY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case BIRTH_COUNTRY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        birthCountries: action.result,
      };
    }
    case BIRTH_COUNTRY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SOURCE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SOURCE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        sourceList: action.result,
      };
    }
    case SOURCE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_ADD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadAddedResponse: action.result,
      };
    }
    case LEAD_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_LEAD_LIST_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loadedFlag: false
      };
    }
    case GET_LEAD_LIST_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        error: false,
        loadedFlag: true,
        leadList: action.result
      };
    }
    case GET_LEAD_LIST_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        error: false,
        loadedFlag: true,
        leadPaidList:null
      };
    }
    case LEAD_STATUS_DROPDOWN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_STATUS_DROPDOWN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadStatusDropdownData: action.result,
      };
    }
    case LEAD_STATUS_DROPDOWN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_LEAD_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_LEAD_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadStatusUpdate: action.result,
      };
    }
    case UPDATE_LEAD_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_LEAD_PROFILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_LEAD_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_LEAD_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case GET_LEAD_ADDRESS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_LEAD_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadAddress: action.result,
      };
    }
    case GET_LEAD_ADDRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadEmailTemplate: action.result,
      };
    }
    case LEAD_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SUBJECT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SUBJECT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        subjectList: action.result.data,
      };
    }
    case SUBJECT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case ADD_LEAD_ADDRESS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_LEAD_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadAddress: action.result,
      };
    }
    case ADD_LEAD_ADDRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_TASK_ADD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_TASK_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadAddedResponse: action.result,
      };
    }
    case LEAD_TASK_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case LEAD_TEMPLATE_DROPDOWN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_TEMPLATE_DROPDOWN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadEmailTemplateDropdown: action.result,
      };
    }
    case LEAD_TEMPLATE_DROPDOWN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_EMAIL_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_EMAIL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadEmailList: action.result,
      };
    }
    case LEAD_EMAIL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_LEAD_TASK_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_LEAD_TASK_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadTaskList: action.result.data
      };
    }
    case GET_LEAD_TASK_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_LEAD_TASK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_LEAD_TASK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_LEAD_TASK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case LEAD_EMAIL_SEND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_EMAIL_SEND_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LEAD_EMAIL_SEND_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_EMAIL_VIEW_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_EMAIL_VIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadEmailView: action.result,
      };
    }
    case LEAD_EMAIL_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case SEMESTER_DEFERMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEMESTER_DEFERMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        semesterDeferment: action.result,
      };
    }
    case SEMESTER_DEFERMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_TASK_SEARCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_TASK_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadTaskSearchResponse: action.result,
      };
    }
    case LEAD_TASK_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case STATE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case STATE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        stateList: action.result,
      };
    }
    case STATE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case STATE_CURRENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case STATE_CURRENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        currentStateList: action.result,
      };
    }
    case STATE_CURRENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_COURSE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_COURSE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_COURSE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        getCourse: action.result,
      };
    }
    case LEAD_STAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_STAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        getStage: action.result,
      };
    }
    case LEAD_STAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_DEGREE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_DEGREE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        getDegree: action.result,
      };
    }
    case LEAD_DEGREE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_SCHOOL_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_SCHOOL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        getSchool: action.result,
      };
    }
    case LEAD_SCHOOL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_DOCUMENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_DOCUMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        docType: action.result,
      };
    }
    case LEAD_DOCUMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_DOCUMENT_UPLOAD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_DOCUMENT_UPLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LEAD_DOCUMENT_UPLOAD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DOCUMENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DOCUMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        docList: action.result,
      };
    }
    case DOCUMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DOCUMENT_FETCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DOCUMENT_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        docById: action.result,
      };
    }
    case DOCUMENT_FETCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DOCUMENT_DELETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DOCUMENT_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DOCUMENT_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SEMESTER_LIST_FROM_COURSEID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SEMESTER_LIST_FROM_COURSEID_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        semesterListFromCourseIdResponse: action.result,
      };
    }
    case SEMESTER_LIST_FROM_COURSEID_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_NOTE_ADD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_NOTE_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadNoteAddResponse: action.result,
      };
    }
    case LEAD_NOTE_ADD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case GET_LEAD_NOTES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_LEAD_NOTES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadNoteListResponse: action.result,
      };
    }
    case GET_LEAD_NOTES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case GET_NOTES_BY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_NOTES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        getLeadNotesByIdResponse: action.result,
      };
    }
    case GET_NOTES_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case EXPORT_LEAD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case EXPORT_LEAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        exportLeadListResponse: action.result,
      };
    }
    case EXPORT_LEAD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_LEAD_COLLEGE_FAIR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_LEAD_COLLEGE_FAIR_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        collegeFairList: action.result,
      };
    }
    case GET_LEAD_COLLEGE_FAIR_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case TASK_SEARCH_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false
      };
    }
    case TASK_SEARCH_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        error: false,
        loaded: true,
        searchListResponse: action.result,
      };
    }
    case TASK_SEARCH_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        error: true,
        loaded: true
      };
    }
    case TASK_SEARCH_ID_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false,
        error: false,
      };
    }
    case TASK_SEARCH_ID_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: false,
        taskSearchByIdResponse: action.result
      };
    }
    case TASK_SEARCH_ID_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: true
      };
    }

    case APPLICATION_BACKGROUND_GET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case APPLICATION_BACKGROUND_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        applicationBackGround: action.result,
      };
    }
    case APPLICATION_BACKGROUND_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SUBMIT_APPLICATION_GET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SUBMIT_APPLICATION_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        submitApplicationData: action.result,
      };
    }
    case SUBMIT_APPLICATION_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case ADDITIONAL_QUESTIONS_GET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ADDITIONAL_QUESTIONS_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        additionalQuestions: action.result,
      };
    }
    case ADDITIONAL_QUESTIONS_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_EMPLOYEE_BY_TYPE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_EMPLOYEE_BY_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        employeeByTypeList: action.result && action.result.data
      };
    }
    case GET_EMPLOYEE_BY_TYPE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case ADD_OR_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ADD_OR_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_OR_UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case LEAD_TEST_GET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LEAD_TEST_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadTestData: action.result,
      };
    }
    case LEAD_TEST_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case ACADEMIC_LIST_GET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ACADEMIC_LIST_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        academicList: action.result,
      };
    }
    case ACADEMIC_LIST_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case MONTH_LIST_GET_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case MONTH_LIST_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        monthList: action.result,
      };
    }
    case MONTH_LIST_GET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_BULK_UPLOAD_LIST_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loadedFlag: false
      };
    }
    case GET_BULK_UPLOAD_LIST_SUCEESS: {
      return {
        ...state,
        loadingFlag: false,
        error: false,
        loadedFlag: true,
        bulkUploadList: action.result
      };
    }
    case GET_BULK_UPLOAD_LIST_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        error: false,
        loadedFlag: true
      };
    }

    case FAIR_LIST_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false,
        error: false,
      };
    }
    case FAIR_LIST_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: false,
        fairList: action.result,
      };
    }
    case FAIR_LIST_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: true,
      };
    }
    case VIEW_LEAD_BY_FAIR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case VIEW_LEAD_BY_FAIR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        viewLeadByFair: action.result
      };
    }
    case VIEW_LEAD_BY_FAIR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_APPLICATION_DOWNLOAD_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_APPLICATION_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        applicationDownloadData: action.result
      };
    }
    case GET_APPLICATION_DOWNLOAD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case TEMPLATE_SEARCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case TEMPLATE_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        searchListResponse: action.result,
      };
    }
    case TEMPLATE_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }

    case GET_ESTIMATE_LIST_BY_LEADID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_ESTIMATE_LIST_BY_LEADID_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leadEstimateList: action.result
      };
    }
    case GET_ESTIMATE_LIST_BY_LEADID_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case GET_ESTIMATE_BY_ID_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false
      };
    }
    case GET_ESTIMATE_BY_ID_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        error: false,
        loadedFlag: true,
        leadEstimateByIdData: action.result
      };
    }
    case GET_ESTIMATE_BY_ID_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        error: false,
        loadedFlag: true
      };
    }
    case BULK_DELETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case BULK_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case BULK_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SEARCH_DUPLICATE_LEAD_REQUESTED: {
      return {
        ...state,
        loading: true,
        error: false,
        loaded: false
      };
    }
    case SEARCH_DUPLICATE_LEAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        duplicateLeadList: action.result,

      };
    }
    case SEARCH_DUPLICATE_LEAD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case DUPLICATE_LEAD_COUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case DUPLICATE_LEAD_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        duplicateLeadCount: action.result
      };
    }
    case DUPLICATE_LEAD_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DUPLICATE_COUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case DUPLICATE_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        duplicateCount: action.result
      };
    }
    case DUPLICATE_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SELECTED_LEAD_MERGE_SUCCESS: {
      return {
        ...state,
        selectedLeadMerge: action.payload
      };
    }
    case DOCUMENTS_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: true
      };
    }
    case DOCUMENTS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        documentsData: action.result
      };
    }
    case DOCUMENTS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DUPLICATE_LEAD_SEARCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case DUPLICATE_LEAD_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        duplicateLeadSearch: action.result,
      };
    }
    case DUPLICATE_LEAD_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case LEAD_ACTIVITY_LOGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case LEAD_ACTIVITY_LOGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        activityList: action.result
      };
    }
    case LEAD_ACTIVITY_LOGS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case LEAD_EVAL_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case LEAD_EVAL_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getEvalInfo: action.result
      };
    }
    case LEAD_EVAL_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case LEAD_VACATION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case LEAD_VACATION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        repVacationList: action.result
      };
    }
    case LEAD_VACATION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case LEAD_ACTIVITY_COUNT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case LEAD_ACTIVITY_COUNT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        activityCountList: action.result
      };
    }
    case LEAD_ACTIVITY_COUNT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case LEAD_ACTIVITY_COUNT_LEAD_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case LEAD_ACTIVITY_COUNT_LEAD_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        activityCountLeadList: action.result
      };
    }
    case LEAD_ACTIVITY_COUNT_LEAD_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case LEAD_TASK_COUNT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case LEAD_TASK_COUNT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        reportTaskList: action.result
      };
    }
    case LEAD_TASK_COUNT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case STATUS_CONVERSION_LIST_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false,
        error: false
      };
    }
    case STATUS_CONVERSION_LIST_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: false,
        statusConversionList: action.result,
      };
    }
    case STATUS_CONVERSION_LIST_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: true
      };
    }
    case PENDING_LEADS_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false,
        error: false,
      };
    }
    case PENDING_LEADS_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: false,
        pendingLeadsList: action.result
      };
    }
    case PENDING_LEADS_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: true
      };
    }
    case STATUS_WISE_COUNT_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false,
        error: false,
      };
    }
    case STATUS_WISE_COUNT_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: false,
        statusWiseCountList: action.result
      };
    }
    case STATUS_WISE_COUNT_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: true
      };
    }
    case STATUS_WISE_LEAD_REP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case STATUS_WISE_LEAD_REP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        statusWiseLeadRepList: action.result,
      };
    }
    case STATUS_WISE_LEAD_REP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case LEAD_LIST_BY_REP_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case LEAD_LIST_BY_REP_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        statusWiseLeadRepList: action.result,
      };
    }
    case LEAD_LIST_BY_REP_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case LEAD_LIST_BY_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case LEAD_LIST_BY_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        statusWiseLeadRepList: action.result,
      };
    }
    case LEAD_LIST_BY_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case GET_REGISTRAR_LIST_REQUESTED: {
      return {
        ...state,
        loadingFinanceCleared: true,
        loaded: false,
        error: false
      };
    }
    case GET_REGISTRAR_LIST_SUCCESS: {
      return {
        ...state,
        loadingFinanceCleared: false,
        loaded: true,
        error: false,
        financiallyClearedList: action.result && action.result.data,
      };
    }
    case GET_REGISTRAR_LIST_FAILURE: {
      return {
        ...state,
        loadingFinanceCleared: false,
        loaded: true,
        error: true
      };
    }
    case SEND_DECISION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case SEND_DECISION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sendDecision: action.result,
      };
    }
    case SEND_DECISION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case FACULTY_TIMESLOT_REQUESTED:{
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      }
    }
    case FACULTY_TIMESLOT_SUCCESS:{
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        facultyTimeSlot: action.result,
      }
    }
    case FACULTY_TIMESLOT_FAILURE:{
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      }
    }
    case GET_ACCEPTANCE_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      }
    }
    case GET_ACCEPTANCE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getAcceptanceLetter: action.result
      }
    }
    case GET_ACCEPTANCE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      }
    }
    case GET_COHORT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case GET_COHORT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        cohortList: action.result && action.result.data
      }
    }
    case GET_COHORT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      }
    }
    case GET_NEXT_SEMESTER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case GET_NEXT_SEMESTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        nextSemesterList: action.result && action.result.data
      }
    }
    case GET_NEXT_SEMESTER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      }
    }
    case GET_LEAD_PAID_LIST_SUCCESS:{
      return{
        ...state,
        loadingFlag:false,
        loadedFlag:true,
        leadPaidList: action.result
      }
    }
    case GET_LEAD_STATUS_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case GET_LEAD_STATUS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        statusListData: action.result && action.result.data
      }
    }
    case GET_LEAD_STATUS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      }
    }
    case GET_ADVANCE_SEARCH_LIST_REQUESTED: {
      return {
        ...state,
        loadingFlag: true,
        loaded: false,
        error: false,
      }
    }
    case GET_ADVANCE_SEARCH_LIST_FAILURE: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: true,
      }
    }
    case GET_ADVANCE_SEARCH_LIST_SUCCESS: {
      return {
        ...state,
        loadingFlag: false,
        loaded: true,
        error: false,
        advanceReportList: action.result && action.result.data
      }
    }
    case GET_REPORT_SEAT_DEPOSIT: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        leadReportSeatDeposit: action.result && action.result.data
      }
    }
    case GET_INTERVIEW_SCHEDULED_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        leadReportInterviewSchduledList: action.result && action.result.data,
      }
    }
    case GET_PAID_LIST_REPORT_REQUESTED:{
      return{
        ...state,
        loading:true,
        loaded:false,
        error:false
      }
    }
    case GET_PAID_LIST_REPORT_SUCCESS:{
      return{
        ...state,
        loading:false,
        loaded:true,
        error:false,
        paidReportList:action.result
      }
    }
    case GET_PAID_LIST_REPORT_FAILURE:{
      return{
        ...state,
        loading:false,
        loaded:true,
        error:true
      }
    }
    case GET_APPLICANT_DETAILS_REQUESTED:{
      return{
        ...state,
        loading:true,
        loaded:false,
        error:false
      }
    }
    case GET_APPLICANT_DETAILS_SUCCESS:{
      return{
        ...state,
        loading:false,
        loaded:true,
        error:false,
        applicantDetails:action.result && action.result.data,
      }
    }
    case GET_APPLICANT_DETAILS_FAILURE:{
      return{
        ...state,
        loading:false,
        loaded:true,
        error:true
      }
    }
    case POST_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false
      }
    }
    case POST_TERMS_AND_CONDITIONS_REQUESTED : {
      return {
        ...state, 
        loading : true,
        loaded: false,
        error : false,
      }
    }
    case POST_TERMS_AND_CONDITIONS_FAILURE : {
      return {
        ...state,
        loading : false,
        loaded: true,
        error : true
      }
    }
    case GET_LEAD_DASHBOARD_CHART_DATA : {
      return {
        ...state,
        profileLoading: false,
        loaded: true,
        error: false,
        chartData : action.result && action.result.data
      }
    }
    case LEAD_DASHBOARD_STATUS_COUNT_REQUESTED : {
      return {
        ...state,
        statusCountLoading : true,
        loaded: false,
        error : false
      }
    }
    case GET_LEAD_DASHBOARD_STATUS_COUNT : {
      return {
        ...state,
        statusCountLoading : false,
        loaded: true,
        error : false,
        statusCountData : action.result && action.result.data
      }
    }
    case LEAD_DASHBOARD_STATUS_COUNT_FAILURE : {
      return {
        ...state,
        statusCountLoading: false,
        loaded: true,
        error : true
      }
    }
    case PAID_SEAT_DEPOSIT_REQUESTED : {
      return {
        ...state,
        paidSeatDepositLoading : true,
        loaded: false,
        error : false
      }
    }
    case LEAD_DASHBOARD_PAID_SEAT_DEPOSIT_COUNT : {
      return {
        ...state,
        paidSeatDepositLoading : false,
        loaded: true,
        error : false,
        paidSeatCountData : action.result && action.result.data
      }
    }
    case PAID_SEAT_DEPOSIT_FAILURE : {
      return {
        ...state,
        paidSeatDepositLoading: false,
        loaded: true,
        error : true
      }
    }
    case INTERVIEW_SCHEDULE_COUNT_REQUESTED : {
      return {
        ...state,
        interviewScheduledLoading : true,
        loaded: false,
        error : false
      }
    }
    case INTERVIEW_SCHEDULED_COUNT_REPORT : {
      return {
        ...state,
        interviewScheduledLoading : false,
        loaded: true,
        error : false,
        interviewScheduledReportData : action.result && action.result.data
      }
    }
    case INTERVIEW_SCHEDULE_COUNT_FAILURE : {
      return {
        ...state,
        interviewScheduledLoading: false,
        loaded: true,
        error : true
      }
    }
    case UPCOMING_TASK_REPORT_REQUESTED : {
      return {
        ...state,
        upcomingTaskReportLoading : true,
        loaded: false,
        error : false
      }
    }
    case UPCOMING_TASK_REPORT : {
      return {
        ...state,
        upcomingTaskReportLoading : false,
        loaded: true,
        error : false,
        upcomingTaskReportData : action.result && action.result.data
      }
    }
    case UPCOMING_TASK_REPORT_FAILURE : {
      return {
        ...state,
        upcomingTaskReportLoading: false,
        loaded: true,
        error : true
      }
    }
    case BILLING_STATE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        billingStateList: action.result?.data,
      }
    }
    case EDUCATIONAL_STATE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        educationalStateList: action.result?.data,
      }
    }
    case LEAD_ACTIVITY_COUNT_NEW_LEAD_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        activityCountNewLeadList: action.result
      };
    }
    case GET_ACTIVE_LEADS_SUCCESS:{
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        activeLeadList: action.result
      };
    }
    case GET_UNASSIGNED_LEADS_SUCCESS:{
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        unAssignedLeadList: action.result
      };
    }
    case GET_SEMESTER_START_DATE_BY_SEMESTER: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        semsterStartDatebySemester: action?.result?.data
      }
    }
    case GET_UNVERIFIED_EMAIL_LIST_REQUESTED : {
      return {
        ...state,
        loading : true,
        loaded: false,
        error : false,
      }
    }
    case GET_UNVERIFIED_EMAIL_LIST_SUCCESS : {
      return {
        ...state,
        loading : false,
        loaded: true,
        error: false,
        getUnverifiedEmailList: action.result?.data,
      }
    }
    case GET_UNVERIFIED_EMAIL_LIST_FAILURE : {
      return {
        ...state,
        loading : false,
        loaded: false,
        error: true,
        getUnverifiedEmailList: null,
      }
    }
    case GET_UNVERIFIED_EMAIL_REQUESTED : {
      return {
        ...state,
        loading : true,
        loaded: false,
        error : false,
      }
    }
    case GET_UNVERIFIED_EMAIL_SUCCESS : {
      return {
        ...state,
        loading : false,
        loaded: true,
        error: false,
        getUnverifiedEmail: action.result?.data,
      }
    }
    case GET_UNVERIFIED_EMAIL_FAILURE : {
      return {
        ...state,
        loading : false,
        loaded: false,
        error: true,
        getUnverifiedEmail: null,
      }
    }
    case MARK_EMAIL_READ_REQUESTED : {
      return {
        ...state,
        loading : true,
        loaded: false,
        error : false,
      }
    }
    case MARK_EMAIL_READ_SUCCESS : {
      return {
        ...state,
        loading : false,
        loaded: true,
        error: false,
      }
    }
    case MARK_EMAIL_READ_FAILURE : {
      return {
        ...state,
        loading : false,
        loaded: false,
        error: true,
      }
    }
    default:
      return state;
  }
};

export const getFairById = (data) => {
  return {
    types: [VIEW_LEAD_BY_FAIR_REQUESTED, VIEW_LEAD_BY_FAIR_SUCCESS, VIEW_LEAD_BY_FAIR_FAILURE],
    promise: client => client.get(`viewLeadByFairId/${data}`)
  };
};

export const addFairList = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('addFairList', { data })
  };
};

export const updateFairList = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('updateFairList', { data })
  };
};

export const getFairList = (data) => {
  const params = {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    ...data.param
  };
  return {
    types: [FAIR_LIST_REQUESTED, FAIR_LIST_SUCCESS, FAIR_LIST_FAILURE],
    promise: client => client.get('fairList', { params })
  };
};

export const getTaskById = (data) => {
  const params = {
    id: data
  };
  return {
    types: [TASK_SEARCH_ID_REQUESTED, TASK_SEARCH_ID_SUCCESS, TASK_SEARCH_ID_FAILURE],
    promise: client => client.get(`leadTaskById/${data}`, { params })
  };
};

export const taskSearchData = (data) => {
  const params = {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    ...data.param,
  };
  return {
    types: [TASK_SEARCH_REQUESTED, TASK_SEARCH_SUCCESS, TASK_SEARCH_FAILURE],
    promise: client => client.get('leadTaskSearch', { params })
  };
};

export const leadStatusData = (statusType) => {
  return {
    types: [LEAD_STATUS_REQUESTED, LEAD_STATUS_SUCCESS, LEAD_STATUS_FAILURE],
    promise: client => client.get(`leadStatusList/${statusType}`)
  };
};

export const campusListData = (campusId) => {
  return {
    types: [CAMPUS_LIST_REQUESTED, CAMPUS_LIST_SUCCESS, CAMPUS_LIST_FAILURE],
    promise: client => client.get(`campusList/${campusId}`)
  };
};
export const semesterYearData = (data) => {
  return {
    types: [SEMESTER_YEAR_REQUESTED, SEMESTER_YEAR_SUCCESS, SEMESTER_YEAR_FAILURE],
    promise: client => client.get(`semesterYear/${data.startYear}/${data.endYear}`)
  };
};
export const programList = (id) => {
  return {
    types: [PROGRAM_LIST_REQUESTED, PROGRAM_LIST_SUCCESS, PROGRAM_LIST_FAILURE],
    promise: client => client.get(`programList/${id}`)
  };
};
export const semesterList = (id) => {
  return {
    types: [SEMESTER_LIST_REQUESTED, SEMESTER_LIST_SUCCESS, SEMESTER_LIST_FAILURE],
    promise: client => client.get(`semesterList/${id}`)
  };
};

export const getSemesterYearList = (startYear, endYear) => {
  return {
    types: [SEMESTER_YEAR_LIST_REQUESTED, SEMESTER_YEAR_LIST_SUCCESS, SEMESTER_YEAR_LIST_FAILURE],
    promise: client => client.get(`semesterYearList/${startYear}/${endYear}`)
  };
};

export const getLeadProfile = (id) => {
  return {
    types: [LEAD_PROFILE_REQUESTED, LEAD_PROFILE_SUCCESS, LEAD_PROFILE_FAILURE],
    promise: client => client.get(`leadProfile/${id}`)
  };
};

export const getBirthCountries = () => {
  return {
    types: [BIRTH_COUNTRY_REQUESTED, BIRTH_COUNTRY_SUCCESS, BIRTH_COUNTRY_FAILURE],
    promise: client => client.get('birthCountries')
  };
};

export const getSourceList = () => {
  return {
    types: [SOURCE_LIST_REQUESTED, SOURCE_LIST_SUCCESS, SOURCE_LIST_FAILURE],
    promise: client => client.get('sourceList')
  };
};

export const leadAdd = (data) => {
  return {
    types: [LEAD_ADD_REQUESTED, LEAD_ADD_SUCCESS, LEAD_ADD_FAILURE],
    promise: client => client.post('addLead', { data })
  };
};

export const getLeadList = (params) => {
  return {
    types: [GET_LEAD_LIST_REQUESTED, GET_LEAD_LIST_SUCCESS, GET_LEAD_LIST_FAILURE],
    promise: client => client.get('leadList', { params })
  };
};

export const getStatusDropdown = (data) => {
  return {
    types: [LEAD_STATUS_DROPDOWN_REQUESTED, LEAD_STATUS_DROPDOWN_SUCCESS, LEAD_STATUS_DROPDOWN_FAILURE],
    promise: client => client.get(`leadStatusDropdownData/${data.statusId}/${data.id}`)
  };
};
export const leadStatusUpdate = (data) => {
  return {
    types: [UPDATE_LEAD_STATUS_REQUESTED, UPDATE_LEAD_STATUS_SUCCESS, UPDATE_LEAD_STATUS_FAILURE],
    promise: client => client.post('leadStatusUpdate', { data, isMultiPart: true })
  };
};
export const leadEmailTemplateDropdown = (statusType) => {
  return {
    types: [LEAD_TEMPLATE_DROPDOWN_REQUESTED, LEAD_TEMPLATE_DROPDOWN_SUCCESS, LEAD_TEMPLATE_DROPDOWN_FAILURE],
    promise: client => client.get(`leadEmailTemplate/${statusType}`)
  };
};
export const leadEmailTemplate = (tempId) => {
  return {
    types: [LEAD_EMAIL_TEMPLATE_REQUESTED, LEAD_EMAIL_TEMPLATE_SUCCESS, LEAD_EMAIL_TEMPLATE_FAILURE],
    promise: client => client.get(`leadTemplate/${tempId}`)
  };
};
export const leadEmailList = (leadId,params) => {
  return {
    types: [LEAD_EMAIL_LIST_REQUESTED, LEAD_EMAIL_LIST_SUCCESS, LEAD_EMAIL_LIST_FAILURE],
    promise: client => client.get(`emailList/${leadId}`, {params})
  };
};
export const sendEmail = (data) => {
  return {
    types: [LEAD_EMAIL_SEND_REQUESTED, LEAD_EMAIL_SEND_SUCCESS, LEAD_EMAIL_SEND_FAILURE],
    promise: client => client.post('sendEmail', { data, isMultiPart: true })
  };
};
export const leadEmailView = (leadId) => {
  return {
    types: [LEAD_EMAIL_VIEW_REQUESTED, LEAD_EMAIL_VIEW_SUCCESS, LEAD_EMAIL_VIEW_FAILURE],
    promise: client => client.get(`mailView/${leadId}`)
  };
};
export const selectedMail = (data) => {
  return (dispatch) => {
    dispatch({
      type: LEAD_EMAIL_DATA,
      payload: data,
    });
  };
};

export const getLeadAddress = (leadId) => {
  return {
    types: [GET_LEAD_ADDRESS_REQUESTED, GET_LEAD_ADDRESS_SUCCESS, GET_LEAD_ADDRESS_FAILURE],
    promise: client => client.get(`leadAddress/${leadId}`)
  };
};
export const addOrUpdateLeadAddress = (data) => {
  return {
    types: [ADD_LEAD_ADDRESS_REQUESTED, ADD_LEAD_ADDRESS_SUCCESS, ADD_LEAD_ADDRESS_FAILURE],
    promise: client => client.post('leadAddressUpdate', { data })
  };
};
export const updateLeadProfile = (data) => {
  return {
    types: [UPDATE_LEAD_PROFILE_REQUESTED, UPDATE_LEAD_PROFILE_SUCCESS, UPDATE_LEAD_PROFILE_FAILURE],
    promise: client => client.post('updateLeadProfile', { data })
  };
};
export const getSubjectList = () => {
  return {
    types: [SUBJECT_LIST_REQUESTED, SUBJECT_LIST_SUCCESS, SUBJECT_LIST_FAILURE],
    promise: client => client.get('subjectTaskList')
  };
};

export const addLeadTask = (data) => {
  return {
    types: [LEAD_TASK_ADD_REQUESTED, LEAD_TASK_ADD_SUCCESS, LEAD_TASK_ADD_FAILURE],
    promise: client => client.post('saveTask', { data, isMultiPart: true })
  };
};
export const getLeadTaskList = (data) => {
  const param = {
    pageNo: data.pageNo,
    pageSize: data.pageSize
  };
  return {
    types: [GET_LEAD_TASK_LIST_REQUESTED, GET_LEAD_TASK_LIST_SUCCESS, GET_LEAD_TASK_LIST_FAILURE],
    promise: client => client.get(`leadTaskList/${data.leadId}`, { params: param })
  };
};
export const updateLeadTask = (data) => {
  return {
    types: [UPDATE_LEAD_TASK_REQUESTED, UPDATE_LEAD_TASK_SUCCESS, UPDATE_LEAD_TASK_FAILURE],
    promise: client => client.post('updateLeadTask', { data })
  };
};
export const semesterDeferment = (data, leadId) => {
  return {
    types: [SEMESTER_DEFERMENT_REQUESTED, SEMESTER_DEFERMENT_SUCCESS, SEMESTER_DEFERMENT_FAILURE],
    promise: client => client.post(`semesterDeferment/${leadId}`, { data, isMultiPart: true })
  };
};

export const getLeadTaskSearch = (data) => {
  const params = {
    ...data.param
  };
  return {
    types: [LEAD_TASK_ADD_REQUESTED, LEAD_TASK_SEARCH_SUCCESS, LEAD_TASK_ADD_FAILURE],
    promise: client => client.get(`taskSearch/${data.pageNo}/${data.pageSize}`, { params })
  };
};

export const getPermanentStateList = (countryId) => {
  return {
    types: [STATE_LIST_REQUESTED, STATE_LIST_SUCCESS, STATE_LIST_FAILURE],
    promise: client => client.get(`stateList/${countryId}`)
  };
};
export const getCourse = () => {
  return {
    types: [LEAD_COURSE_LIST_REQUESTED, LEAD_COURSE_LIST_SUCCESS, LEAD_COURSE_LIST_FAILURE],
    promise: client => client.get('courseList')
  };
};
export const getStage = (courseId) => {
  return {
    types: [LEAD_STAGE_REQUESTED, LEAD_STAGE_SUCCESS, LEAD_STAGE_FAILURE],
    promise: client => client.get(`stageList/${courseId}`)
  };
};
export const getHighestDegree = () => {
  return {
    types: [LEAD_DEGREE_LIST_REQUESTED, LEAD_DEGREE_LIST_SUCCESS, LEAD_DEGREE_LIST_FAILURE],
    promise: client => client.get('preMedHighestdegree')
  };
};
export const getSchool = () => {
  return {
    types: [LEAD_SCHOOL_LIST_REQUESTED, LEAD_SCHOOL_LIST_SUCCESS, LEAD_SCHOOL_LIST_FAILURE],
    promise: client => client.get('getSchool')
  };
};
export const docTypeList = () => {
  return {
    types: [LEAD_DOCUMENT_LIST_REQUESTED, LEAD_DOCUMENT_LIST_SUCCESS, LEAD_DOCUMENT_LIST_FAILURE],
    promise: client => client.get('docTypeList')
  };
};
export const documentUpload = (data) => {
  return {
    types: [LEAD_DOCUMENT_UPLOAD_REQUESTED, LEAD_DOCUMENT_UPLOAD_SUCCESS, LEAD_DOCUMENT_UPLOAD_FAILURE],
    promise: client => client.post('documentUpload', { data, isMultiPart: true })
  };
};
export const documentList = (data) => {
  return {
    types: [DOCUMENT_LIST_REQUESTED, DOCUMENT_LIST_SUCCESS, DOCUMENT_LIST_FAILURE],
    promise: client => client.get(`documentList/${data.leadId}/${data.inActive}`)
  };
};
export const documentUpdate = (data) => {
  return {
    types: [LEAD_DOCUMENT_UPLOAD_REQUESTED, LEAD_DOCUMENT_UPLOAD_SUCCESS, LEAD_DOCUMENT_UPLOAD_FAILURE],
    promise: client => client.post('documentUpdate', { data, isMultiPart: true })
  };
};
export const documentbyId = (id) => {
  return {
    types: [DOCUMENT_FETCH_REQUESTED, DOCUMENT_FETCH_SUCCESS, DOCUMENT_FETCH_FAILURE],
    promise: client => client.get(`getDocbyId/${id}`)
  };
};
export const documentDelete = (data) => {
  return {
    types: [DOCUMENT_DELETE_REQUESTED, DOCUMENT_DELETE_SUCCESS, DOCUMENT_DELETE_FAILURE],
    promise: client => client.post(`deleteDocument/?docId=${data.docId}`, { data })
  };
};

export const getCurrentStateList = (countryId) => {
  return {
    types: [STATE_CURRENT_LIST_REQUESTED, STATE_CURRENT_LIST_SUCCESS, STATE_CURRENT_LIST_FAILURE],
    promise: client => client.get(`stateList/${countryId}`)
  };
};

export const leadNoteAdd = (data) => {
  return {
    types: [LEAD_NOTE_ADD_REQUESTED, LEAD_NOTE_ADD_SUCCESS, LEAD_NOTE_ADD_FAILURE],
    promise: client => client.post('addLeadNote', { data })
  };
};
export const getLeadNotesList = (params) => {
  return {
    types: [GET_LEAD_NOTES_LIST_REQUESTED, GET_LEAD_NOTES_LIST_SUCCESS, GET_LEAD_NOTES_LIST_FAILURE],
    promise: client => client.get('listLeadNotes', { params })
  };
};
export const getLeadNotesById = (noteId) => {
  return {
    types: [GET_NOTES_BY_ID_REQUESTED, GET_NOTES_BY_ID_SUCCESS, GET_NOTES_BY_ID_FAILURE],
    promise: client => client.get(`getNoteById/${noteId}`)
  };
};

export const getSemesterListFromCourseId = (courseId) => {
  return {
    types: [SEMESTER_LIST_FROM_COURSEID_REQUESTED, SEMESTER_LIST_FROM_COURSEID_SUCCESS, SEMESTER_LIST_FROM_COURSEID_FAILURE],
    promise: client => client.get(`semseterListFromCourseId/${courseId}`)
  };
};
export const addLeadEmailTemplate = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('addLeadEmailTemplate', { data })
  };
};
export const updateLeadEmailTemplate = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('updateLeadEmailTemplate', { data })
  };
};
export const getCollegeFairList = () => {
  return {
    types: [GET_LEAD_COLLEGE_FAIR_REQUESTED, GET_LEAD_COLLEGE_FAIR_SUCCESS, GET_LEAD_COLLEGE_FAIR_FAILURE],
    promise: client => client.get('leadCollegeFairList')
  };
};
export const leadBulkUpload = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('uploadBulkLead', { data, isMultiPart: true })
  };
};
export const saveApplicationBackgroundDetails = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('applicationBackGroundSave', { data, isMultiPart: true })
  };
};

export const getApplicationBackgroundDetails = (leadId) => {
  return {
    types: [APPLICATION_BACKGROUND_GET_REQUESTED, APPLICATION_BACKGROUND_GET_SUCCESS, APPLICATION_BACKGROUND_GET_FAILURE],
    promise: client => client.get(`getApplicationBackground/${leadId}`)
  };
};
export const saveAdditionalQuestions = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('saveAdditionalQuestions', { data })
  };
};

export const getAdditionalQuestions = (leadId) => {
  return {
    types: [ADDITIONAL_QUESTIONS_GET_REQUESTED, ADDITIONAL_QUESTIONS_GET_SUCCESS, ADDITIONAL_QUESTIONS_GET_FAILURE],
    promise: client => client.get(`getAdditionalQuestions/${leadId}`)
  };
};

export const submitApplication = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('submitApplicationData', { data })
  };
};

export const getApplicationData = (leadId) => {
  return {
    types: [SUBMIT_APPLICATION_GET_REQUESTED, SUBMIT_APPLICATION_GET_SUCCESS, SUBMIT_APPLICATION_GET_FAILURE],
    promise: client => client.get(`getApplicationData/${leadId}`)
  };
};
export const saveAcademics = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('saveAcademics', { data, isMultiPart: true })
  };
};
export const getleadTestData = () => {
  return {
    types: [LEAD_TEST_GET_REQUESTED, LEAD_TEST_GET_SUCCESS, LEAD_TEST_GET_FAILURE],
    promise: client => client.get('getleadTestData')
  };
};
export const getAcademicsList = (leadId) => {
  return {
    types: [ACADEMIC_LIST_GET_REQUESTED, ACADEMIC_LIST_GET_SUCCESS, ACADEMIC_LIST_GET_FAILURE],
    promise: client => client.get(`getAcademicsList/${leadId}`)
  };
};
export const getMonthsList = () => {
  return {
    types: [MONTH_LIST_GET_REQUESTED, MONTH_LIST_GET_SUCCESS, MONTH_LIST_GET_FAILURE],
    promise: client => client.get('monthList')
  };
};
export const getEmployeeByType = () => {
  return {
    types: [GET_EMPLOYEE_BY_TYPE_LIST_REQUESTED, GET_EMPLOYEE_BY_TYPE_LIST_SUCCESS, GET_EMPLOYEE_BY_TYPE_LIST_FAILURE],
    promise: client => client.get(`getEmployeeByType`)
  };
};
export const getBulkUploadList = () => {
  return {
    types: [GET_BULK_UPLOAD_LIST_REQUESTED, GET_BULK_UPLOAD_LIST_SUCEESS, GET_EMPLOYEE_BY_TYPE_LIST_FAILURE],
    promise: client => client.get('getBulkUploadList')
  };
};
export const closedLeadUpdate = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post(`closedLeadUpdate/${data.leadId}`, { data })
  };
};

export const getApplicationPdfData = (leadId) => {
  return {
    types: [GET_APPLICATION_DOWNLOAD_REQUESTED, GET_APPLICATION_DOWNLOAD_SUCCESS, GET_APPLICATION_DOWNLOAD_FAILURE],
    promise: client => client.get(`getApplicationPdfContent/${leadId}`)
  };
};

export const templateSearchData = (data) => {
  const params = {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    ...data.param
  };
  return {
    types: [TEMPLATE_SEARCH_REQUESTED, TEMPLATE_SEARCH_SUCCESS, TEMPLATE_SEARCH_FAILURE],
    promise: client => client.get('emailTemplateSearch', { params })
  };
};

export const leadEstimateList = (leadId) => {
  return {
    types: [GET_ESTIMATE_LIST_BY_LEADID_REQUESTED, GET_ESTIMATE_LIST_BY_LEADID_SUCCESS, GET_ESTIMATE_LIST_BY_LEADID_FAILURE],
    promise: client => client.get(`leadEstimateList/${leadId}`)
  };
};

export const addEstimate = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('addEstimate', { data })
  };
};

export const getEstimateById = (estimateId) => {
  return {
    types: [GET_ESTIMATE_BY_ID_REQUESTED, GET_ESTIMATE_BY_ID_SUCCESS, GET_ESTIMATE_BY_ID_FAILURE],
    promise: client => client.get(`getEstimateById/${estimateId}`)
  };
};
export const leadBulkUpdate = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('leadBulkUpdate', { data })
  };
};
export const leadBulkDelete = (data) => {
  return {
    types: [BULK_DELETE_REQUESTED, BULK_DELETE_SUCCESS, BULK_DELETE_FAILURE],
    promise: client => client.post('leadBulkDelete', { data })
  };
};

export const getDuplicateLead = (data) => {
  return {
    types: [SEARCH_DUPLICATE_LEAD_REQUESTED, SEARCH_DUPLICATE_LEAD_SUCCESS, SEARCH_DUPLICATE_LEAD_FAILURE],
    promise: client => client.get('searchLeadMerge', { params: data }),
  };
};

export const getDuplicateLeadCount = () => {
  return {
    types: [DUPLICATE_LEAD_COUNT_REQUESTED, DUPLICATE_LEAD_COUNT_SUCCESS, DUPLICATE_LEAD_COUNT_FAILURE],
    promise: client => client.get('getDuplicateLead')
  };
};

export const getDuplicateCount = (email) => {
  return {
    types: [DUPLICATE_COUNT_REQUESTED, DUPLICATE_COUNT_SUCCESS, DUPLICATE_COUNT_FAILURE],
    promise: client => client.get(`getDuplicateCount/${email}`)
  };
};

export const getleadMerge = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('leadMerge', { data })
  };
};

export const selectedLeadMerge = (data) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_LEAD_MERGE_SUCCESS,
      payload: data,
    });
  };
};

export const updateMoreInfo = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('updateMoreInfo', { data })
  };
};

export const getMyDocumentsData = (params) => {
  return {
    types: [DOCUMENTS_DATA_REQUESTED, DOCUMENTS_DATA_SUCCESS, DOCUMENTS_DATA_FAILURE],
    promise: client => client.get('myDocuments',{params})
  };
};

export const getDuplicateLeadSearch = (data) => {
  return {
    types: [DUPLICATE_LEAD_SEARCH_REQUESTED, DUPLICATE_LEAD_SEARCH_SUCCESS, DUPLICATE_LEAD_SEARCH_FAILURE],
    promise: client => client.get('duplicateLeadSearch', { params: data })
  };
};

export const getActivityLogs = (data) => {
  return {
    types: [LEAD_ACTIVITY_LOGS_REQUESTED, LEAD_ACTIVITY_LOGS_SUCCESS, LEAD_ACTIVITY_LOGS_FAILURE],
    promise: client => client.get('leadActivityLogs', { params: data })
  };
};

export const getEvalInfo = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('saveLeadInterviewEvalInfo', { data })
  };
};

export const getLeadInterviewEvalInfo = (data) => {
  return {
    types: [LEAD_EVAL_INFO_REQUESTED, LEAD_EVAL_INFO_SUCCESS, LEAD_EVAL_INFO_FAILURE],
    promise: client => client.get('getLeadInterviewEvalInfo', { params: data })
  };
};

export const leadRepAssign = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('leadRepAssign', { data })
  };
};
export const repVacationList = () => {
  return {
    types: [LEAD_VACATION_LIST_REQUESTED, LEAD_VACATION_LIST_SUCCESS, LEAD_VACATION_LIST_FAILURE],
    promise: client => client.get('leadRepVacationList')
  };
};

export const dailyActivityCountList = (params) => {
  return {
    types: [LEAD_ACTIVITY_COUNT_LIST_REQUESTED, LEAD_ACTIVITY_COUNT_LIST_SUCCESS, LEAD_ACTIVITY_COUNT_LIST_FAILURE],
    promise: client => client.get('dailyActivityCount',{params})
  };
};

export const dailyActivityCountLeadList = (assignedToName) => {
  return {
    types: [LEAD_ACTIVITY_COUNT_LEAD_LIST_REQUESTED, LEAD_ACTIVITY_COUNT_LEAD_LIST_SUCCESS, LEAD_ACTIVITY_COUNT_LEAD_LIST_FAILURE],
    promise: client => client.get(`dailyCountLeadList/?assignedToName=${assignedToName}`)
  };
};

export const reportsTaskCount = () => {
  return {
    types: [LEAD_TASK_COUNT_LIST_REQUESTED, LEAD_TASK_COUNT_LIST_SUCCESS, LEAD_TASK_COUNT_LIST_FAILURE],
    promise: client => client.get('reportsTaskCount')
  };
};

export const getStatusConversionList = (data) => {
  return {
    types: [STATUS_CONVERSION_LIST_REQUESTED, STATUS_CONVERSION_LIST_SUCCESS, STATUS_CONVERSION_LIST_FAILURE],
    promise: client => client.get('statusConversionData', { params: data })
  };
};

export const getPendingLeads = (data) => {
  const param = {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    ...data.param,
  };
  return {
    types: [PENDING_LEADS_REQUESTED, PENDING_LEADS_SUCCESS, PENDING_LEADS_FAILURE],
    promise: client => client.get('pendingLeadList', { params: param })
  };
};

export const getStatusWiseCount = (data) => {
  return {
    types: [STATUS_WISE_COUNT_REQUESTED, STATUS_WISE_COUNT_SUCCESS, STATUS_WISE_COUNT_FAILURE],
    promise: client => client.get('statusWise', { params: data })
  };
};

export const getStatusWiseLeadByrep = (data) => {
  return {
    types: [STATUS_WISE_LEAD_REP_REQUESTED, STATUS_WISE_LEAD_REP_SUCCESS, STATUS_WISE_LEAD_REP_FAILURE],
    promise: client => client.get('statusWiseLeadByRep', { params: data })
  };
};

export const getLeadListByRepAndStatus = (data) => {
  return {
    types: [LEAD_LIST_BY_REP_STATUS_REQUESTED, LEAD_LIST_BY_REP_STATUS_SUCCESS, LEAD_LIST_BY_REP_STATUS_FAILURE],
    promise: client => client.get('LeadListByRepAndStatuss', { params: data })
  };
};


export const getLeadListByStatus = (data) => {
  return {
    types: [LEAD_LIST_BY_STATUS_REQUESTED, LEAD_LIST_BY_STATUS_SUCCESS, LEAD_LIST_BY_STATUS_FAILURE],
    promise: client => client.get('leadListByStatus', { params: data })
  };
};

export const bulkUpdateLeadToClose = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('bulkCloseLeads', { data })
  };
};

export const getFinanciallyClearedList = (params) => {
  return {
    types: [GET_REGISTRAR_LIST_REQUESTED, GET_REGISTRAR_LIST_SUCCESS, GET_REGISTRAR_LIST_FAILURE],
    promise: client => client.get('leadList', { params })
  };
};

export const migrateToStudent = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('migrateToStudent', { data })
  };
};

export const sendDecision = (data) => {
  return {
    types: [SEND_DECISION_REQUESTED, SEND_DECISION_SUCCESS, SEND_DECISION_FAILURE],
    promise: client => client.get(`sendDecision/${data}`)
  };
};

export const getFacultyTimeSlotByDate = (date) => {
  return {
    types: [FACULTY_TIMESLOT_REQUESTED, FACULTY_TIMESLOT_SUCCESS, FACULTY_TIMESLOT_FAILURE],
    promise: client => client.get(`facultyTimeSlot/${date}`)
  };
}

export const leadProfileUpload = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('leadUploadImage', {data, isMultiPart: true})
  }
}

export const getAcceptanceLetter = (leadId) => {
  return {
    types: [GET_ACCEPTANCE_DATA_REQUESTED, GET_ACCEPTANCE_DATA_SUCCESS, GET_ACCEPTANCE_DATA_FAILURE],
    promise: client => client.get(`acceptanceFilePreview/${leadId}`)
  }
}

export const getCohort = (data) => {
  return {
    types: [GET_COHORT_LIST_REQUESTED, GET_COHORT_LIST_SUCCESS, GET_COHORT_LIST_FAILURE],
    promise: client => client.get(`semesterYear/${data.startYear}/${data.endYear}`)
  }
}

export const getNextSemesterList = () => {
  return {
    types: [GET_NEXT_SEMESTER_REQUESTED, GET_NEXT_SEMESTER_SUCCESS, GET_NEXT_SEMESTER_FAILURE],
    promise: client => client.get(`nextSemester`)
  }
}

export const getLeadStatusList = () => {
  return {
    types: [GET_LEAD_STATUS_DATA_REQUESTED, GET_LEAD_STATUS_DATA_SUCCESS, GET_LEAD_STATUS_DATA_FAILURE],
    promise: client => client.get(`getLeadStatus`)
  }
}

export const getAdvanceReportList = (data) => {
  return {
    types: [GET_ADVANCE_SEARCH_LIST_REQUESTED, GET_ADVANCE_SEARCH_LIST_SUCCESS, GET_ADVANCE_SEARCH_LIST_FAILURE],
    promise: client => client.post('leadReportsAdvancedSearch', {data})
  }
}

export const getSeatDepositDueDate = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_REPORT_SEAT_DEPOSIT , ADD_OR_UPDATE_FAILURE],
    promise: client => client.get('leadReportSeatDeposit', {params})
  }
}

export const getLeadPaidList = (data) => {
  return {
    types : [GET_LEAD_LIST_REQUESTED,GET_LEAD_PAID_LIST_SUCCESS,GET_LEAD_LIST_FAILURE],
    promise: client=>client.post(`getLeadPaidList/`,{data})
  }
}

export const getLeadInterviewSchduled = (data) => {
return {
  types: [ADD_OR_UPDATE_REQUESTED, GET_INTERVIEW_SCHEDULED_SUCCESS, ADD_OR_UPDATE_FAILURE],
  promise: client => client.post('interviewSchduledLeadReports', {data})
}
}


export const getPaidReportList=(data)=>{
  return{
    types:[GET_PAID_LIST_REPORT_REQUESTED,GET_PAID_LIST_REPORT_SUCCESS,GET_PAID_LIST_REPORT_FAILURE],
    promise: client=>client.post('getPaidListReport', {data})
  }
}

export const getAddressApplicantDetails = (id) => {
  return{
    types:[GET_APPLICANT_DETAILS_REQUESTED,GET_APPLICANT_DETAILS_SUCCESS,GET_APPLICANT_DETAILS_FAILURE],
    promise:client=> client.get(`getAddressApplicantDetails/${id}`)
  }
}

export const postTermsAndConditions = (data) => {
  return {
    types : [POST_TERMS_AND_CONDITIONS_REQUESTED, POST_TERMS_AND_CONDITIONS_SUCCESS, POST_TERMS_AND_CONDITIONS_FAILURE],
    promise : client => client.post(`postTermsAndConditions`, {data, isMultiPart: true})
  }
}

export const getLeadDashboardChartData = (params) => {
  return {
    types : [LEAD_PROFILE_REQUESTED, GET_LEAD_DASHBOARD_CHART_DATA, LEAD_PROFILE_FAILURE ],
    promise : client => client.get(`getDashboardChart`, {params})
  }
}

export const getLeadDashboardStatusCount = (params) => {
  return {
    types : [LEAD_DASHBOARD_STATUS_COUNT_REQUESTED, GET_LEAD_DASHBOARD_STATUS_COUNT, LEAD_DASHBOARD_STATUS_COUNT_FAILURE],
    promise : client => client.get('getDashBoardLeadStatusCount', {params})
  }
}

export const getPaidSeatDepositCount = (params) => {
  return {
    types : [PAID_SEAT_DEPOSIT_REQUESTED, LEAD_DASHBOARD_PAID_SEAT_DEPOSIT_COUNT, PAID_SEAT_DEPOSIT_FAILURE],
    promise : client => client.get('leadDashBoardPaidSeatDepositCount', {params})
  }
}

export const getLeadInterviewScheduledCountReport = (params) => {
  return {
    types : [INTERVIEW_SCHEDULE_COUNT_REQUESTED, INTERVIEW_SCHEDULED_COUNT_REPORT, INTERVIEW_SCHEDULE_COUNT_FAILURE],
    promise : client => client.get('leadDashboardInterviewScheduleCount', {params})
  }
}

export const getUpcomingTaskReport = (params) => {
  return {
    types : [UPCOMING_TASK_REPORT_REQUESTED, UPCOMING_TASK_REPORT, UPCOMING_TASK_REPORT_FAILURE],
    promise : client => client.get('leadDashboardUpcomingTaskReport', {params})
  }
}

export const dailyActivityCountNewLeadList = (params) => {
  return {
    types: [LEAD_ACTIVITY_COUNT_LEAD_LIST_REQUESTED, LEAD_ACTIVITY_COUNT_NEW_LEAD_LIST_SUCCESS, LEAD_ACTIVITY_COUNT_LEAD_LIST_FAILURE],
    promise: client => client.get('dailyCountLeadNewList',{params})
  };
};
export const saveEducationalBillingContactInfo = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('billingContactInfoEducational', {data})
  }
} 

export const getBillingStateList = (countryId) => {
  return {
    types: [STATE_LIST_REQUESTED, BILLING_STATE_LIST_SUCCESS, STATE_LIST_FAILURE],
    promise: client => client.get(`stateList/${countryId}`)
  };
};

export const getEducationalStateList = (countryId) => {
  return {
    types: [STATE_LIST_REQUESTED, EDUCATIONAL_STATE_LIST_SUCCESS, STATE_LIST_FAILURE],
    promise: client => client.get(`stateList/${countryId}`)
  };
};

export const getActiveLeadsList =() => {
  return{
    types:[LEAD_STATUS_REQUESTED, GET_ACTIVE_LEADS_SUCCESS, LEAD_STATUS_FAILURE],
    promise: client=> client.get('getActiveLeadsList')
  }
}

export const documentNewDownlaodLead =(docId) => {
  return {
    types: [LEAD_ADD_REQUESTED, LEAD_ADD_SUCCESS,LEAD_ADD_FAILURE],
    promise: client => client.get(`documentDownload/${docId}`)
  }
}

export const leadBulkDownload =(docId) => {
  return {
    types: [LEAD_ADD_REQUESTED, LEAD_ADD_SUCCESS,LEAD_ADD_FAILURE],
    promise: client => client.get(`bulkUploadedDocumentDownload/${docId}`)
  }
}


export const leadUnassignedLeads =(params) => {
  return {
    types: [LEAD_ADD_REQUESTED, GET_UNASSIGNED_LEADS_SUCCESS,LEAD_ADD_FAILURE],
    promise: client => client.get(`unAssignedLeads`, {params})
  }
}

export const getSemesterStartDateBySemester = (name) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, GET_SEMESTER_START_DATE_BY_SEMESTER, ADD_OR_UPDATE_FAILURE],
    promise: client => client.get(`getSemesterDates/${name}`)
  }
}

export const unverifiedEmailList =(params) => {
  return {
    types: [GET_UNVERIFIED_EMAIL_LIST_REQUESTED, GET_UNVERIFIED_EMAIL_LIST_SUCCESS, GET_UNVERIFIED_EMAIL_LIST_FAILURE],
    promise: client => client.get(`unverifiedEmailList`, {params})
  }
}

export const getUnverifiedEmailDetails =(id) => {
  return {
    types: [GET_UNVERIFIED_EMAIL_REQUESTED, GET_UNVERIFIED_EMAIL_SUCCESS, GET_UNVERIFIED_EMAIL_FAILURE],
    promise: client => client.get(`getUnverifiedEmail/${id}`)
  }
}

export const updateUnverifiedEmail = (id) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post(`updateUnverifiedEmail/${id}`)
  }
} 

export const convertToLeadFromEmail = (data) => {
  return {
    types:[ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('convertToLeadFromEmail', {data})
  }
}

export const discardUnverifiedEmail = (id) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post(`discardUnverifiedEmail/${id}`)
  }
} 

export const mergeUnverifiedEmail = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: client => client.post('mergeUnverifiedEmail', { params })
  }
} 

export const markAsRead = (params) => {
  return {
    types: [MARK_EMAIL_READ_REQUESTED, MARK_EMAIL_READ_SUCCESS, MARK_EMAIL_READ_FAILURE],
    promise: client => client.get('markAsRead', {params})
  }
} 