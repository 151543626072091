export default {
  STATUS: [
    { id: 'Active', name: 'Active', value: true, },
    { id: 'InActive', name: 'InActive', value: false }
  ],
  ROOM_STATUS: [
    { id: 'Occupied', name: 'Occupied' },
    { id: 'Available', name: 'Available' },
    { id: 'Under Maintainance', name: 'Under Maintainance' },
  ],
  ROOM_WING: [
    { id: 'East Wing', name: 'East Wing' },
    { id: 'West Wing', name: 'West Wing' },
  ],
  ROOM_FACE: [
    { id: 'Pool-side', name: 'Pool-side' },
    { id: 'Back-side', name: 'Back-side' },
  ],
  TYPE: [
    {
      id: 'room', name: 'Room'
    }, {
      id: 'suite', name: 'Suite'
    }
  ],
  FEE_TYPE: [
    {
      id: 'meal', name: 'Meal Fee', value: true,
    }, {
      id: 'room', name: 'Room Fee', value: false,
    },
    {
      id: 'dailyRoom', name: 'Daily Room Fee', value: false,
    }
  ],
  ACCOMODATION_TYPE: [
    { id: 0, name: 'Room', value: false, },
    { id: 1, name: 'Suite', value: true, },
  ],
  MEAL_PLAN: [
    { id: 0, name: 0 },
    { id: 1, name: 1 },
    { id: 2, name: 2 },
    { id: 3, name: 3 },
  ],
  INVOICE_STATUS: [
    { id: 'CR', name: 'Open' },
    { id: 'PP', name: 'Partial Paid' },
    { id: 'FP', name: 'Full Paid' },
    { id: 'CN', name: 'Cancelled' },
  ],
  FINANCIAL_STATUS: [
    {
      id: 1, name: 'Financially Cleared'
    },
    {
      id: 2, name: 'Financially Hold'
    },
    {
      id: 3, name: 'Financially Blocked'
    },

  ],
  HOUSING_CONTRACT_STATUS: [
    {
      id: 1, name: 'Contract Not sent'
    },
    {
      id: 2, name: 'Contract Sent'
    },
    {
      id: 3, name: 'Contract Signed'
    },
  ],
  HOUSING_ROOM_STATUS: [
    {
      id: true, name: 'Room Assigned'
    },
    {
      id: false, name: 'Room Not Assigned'
    },

  ]
};
