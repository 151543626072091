import { combineReducers } from 'redux';
import employee from './employee';
import benefitsReferences from './benefitsAndReferences';
import sideMenu from './sideMenu';

const employeeReducer = combineReducers({
  employee,
  benefitsReferences,
  sideMenu,
});

export default employeeReducer;
