export const ADD_OR_UPDATE_REQUESTED = 'housing/ADD_OR_UPDATE_REQUESTED';
export const ADD_OR_UPDATE_SUCCESS = 'housing/ADD_OR_UPDATE_SUCCESS';
export const ADD_OR_UPDATE_FAILURE = 'housing/ADD_OR_UPDATE_FAILURE';

export const GET_SUITE_LIST_REQUESTED = 'housing/GET_SUITE_LIST_REQUESTED';
export const GET_SUITE_LIST_SUCCESS = 'housing/GET_SUITE_LIST_SUCCESS';
export const GET_SUITE_LIST_FAILURE = 'housing/GET_SUITE_LIST_FAILURE';

export const GET_SUITE_DATA_REQUESTED = 'housing/GET_SUITE_DATA_REQUESTED';
export const GET_SUITE_DATA_SUCCESS = 'housing/GET_SUITE_DATA_SUCCESS';
export const GET_SUITE_DATA_FAILURE = 'housing/GET_SUITE_DATA_FAILURE';

export const GET_MEAL_PLAN_LIST_REQUESTED = 'housing/GET_MEAL_PLAN_LIST_REQUESTED';
export const GET_MEAL_PLAN_LIST_SUCCESS = 'housing/GET_MEAL_PLAN_LIST_SUCCESS';
export const GET_MEAL_PLAN_LIST_FAILURE = 'housing/GET_MEAL_PLAN_LIST_FAILURE';

export const GET_MEAL_PLAN_BY_ID_REQUESTED = 'housing/GET_MEAL_PLAN_BY_ID_REQUESTED';
export const GET_MEAL_PLAN_BY_ID_SUCCESS = 'housing/GET_MEAL_PLAN_BY_ID_SUCCESS';
export const GET_MEAL_PLAN_BY_ID_FAILURE = 'housing/GET_MEAL_PLAN_BY_ID_FAILURE';

export const GET_FEES_DATA_REQUESTED = 'housing/GET_FEES_DATA_REQUESTED';
export const GET_FEES_DATA_SUCCESS = 'housing/GET_FEES_DATA_SUCCESS';
export const GET_FEES_DATA_FAILURE = 'housing/GET_FEES_DATA_FAILURE';

export const GET_FEES_DATA_HISTORY_REQUESTED = 'housing/GET_FEES_DATA_HISTORY_REQUESTED';
export const GET_FEES_DATA_HISTORY_SUCCESS = 'housing/GET_FEES_DATA_HISTORY_SUCCESS';
export const GET_FEES_DATA_HISTORY_FAILURE = 'housing/GET_FEES_DATA_HISTORY_FAILURE';

export const GET_HOUSING_LIST_REQUESTED = 'hosuing/GET_HOUSING_LIST_REQUESTED';
export const GET_HOUSING_LIST_SUCCESS = 'hosuing/GET_HOUSING_LIST_SUCCESS';
export const GET_HOUSING_LIST_FAILURE = 'hosuing/GET_HOUSING_LIST_FAILURE';

export const GET_HOUSING_STUDENT_LIST_REQUESTED = 'hosuing/GET_HOUSING_STUDENT_LIST_REQUESTED';
export const GET_HOUSING_STUDENT_LIST_SUCCESS = 'hosuing/GET_HOUSING_STUDENT_LIST_SUCCESS';
export const GET_HOUSING_STUDENT_LIST_FAILURE = 'hosuing/GET_HOUSING_STUDENT_LIST_FAILURE';

export const GET_SUIT_ROOM_LIST_REQUESTED = 'hosuing/GET_SUIT_ROOM_LIST_REQUESTED';
export const GET_SUIT_ROOM_LIST_SUCCESS = 'hosuing/GET_SUIT_ROOM_LIST_SUCCESS';
export const GET_SUIT_ROOM_LIST_FAILURE = 'hosuing/GET_SUIT_ROOM_LIST_FAILURE';

export const GET_ENTIRE_SUIT_ROOM_LIST_REQUESTED = 'hosuing/GET_ENTIRE_SUIT_ROOM_LIST_REQUESTED';
export const GET_ENTIRE_SUIT_ROOM_LIST_SUCCESS = 'hosuing/GET_ENTIRE_SUIT_ROOM_LIST_SUCCESS';
export const GET_ENTIRE_SUIT_ROOM_LIST_FAILURE = 'hosuing/GET_ENTIRE_SUIT_ROOM_LIST_FAILURE';

export const GET_HOUSING_INVOICE_AMOUNT_REQUESTED = 'hosuing/GET_HOUSING_INVOICE_AMOUNT_REQUESTED';
export const GET_HOUSING_INVOICE_AMOUNT_SUCCESS = 'hosuing/GET_HOUSING_INVOICE_AMOUNT_SUCCESS';
export const GET_HOUSING_INVOICE_AMOUNT_FAILURE = 'hosuing/GET_HOUSING_INVOICE_AMOUNT_FAILURE';

export const GET_SUITE_OR_ROOM_DETAILS_REQUESTED = 'hosuing/GET_SUITE_OR_ROOM_DETAILS_REQUESTED';
export const GET_SUITE_OR_ROOM_DETAILS_SUCCESS = 'hosuing/GET_SUITE_OR_ROOM_DETAILS_SUCCESS';
export const GET_SUITE_OR_ROOM_DETAILS_FAILURE = 'hosuing/GET_SUITE_OR_ROOM_DETAILS_FAILURE';

export const GET_SUITE_OR_ROOM_DETAILS_BY_ID_REQUESTED = 'hosuing/GET_SUITE_OR_ROOM_DETAILS_BY_ID_REQUESTED';
export const GET_SUITE_OR_ROOM_DETAILS_BY_ID_SUCCESS = 'hosuing/GET_SUITE_OR_ROOM_DETAILS_BY_ID_SUCCESS';
export const GET_SUITE_OR_ROOM_DETAILS_BY_ID_FAILURE = 'hosuing/GET_SUITE_OR_ROOM_DETAILS_BY_ID_FAILURE';

export const GET_STUDENT_HOUSING_DETAILS_REQUESTED = 'housing/GET_STUDENT_HOUSING_DETAILS_REQUESTED';
export const GET_STUDENT_HOUSING_DETAILS_SUCCESS = 'housing/GET_STUDENT_HOUSING_DETAILS_SUCCESS';
export const GET_STUDENT_HOUSING_DETAILS_FAILURE = 'housing/GET_STUDENT_HOUSING_DETAILS_FAILURE';

export const GET_STUDENT_HOUSING_DETAIL_REQUESTED = 'housing/GET_STUDENT_HOUSING_DETAIL_REQUESTED';
export const GET_STUDENT_HOUSING_DETAIL_SUCCESS = 'housing/GET_STUDENT_HOUSING_DETAIL_SUCCESS';
export const GET_STUDENT_HOUSING_DETAIL_FAILURE = 'housing/GET_STUDENT_HOUSING_DETAIL_FAILURE';

export const GET_HOUSING_QUEUE_LIST_REQUESTED = 'housing/GET_HOUSING_QUEUE_LIST_REQUESTED';
export const GET_HOUSING_QUEUE_LIST_SUCCESS = 'housing/GET_HOUSING_QUEUE_LIST_SUCCESS';
export const GET_HOUSING_QUEUE_LIST_FAILURE = 'housing/GET_HOUSING_QUEUE_LIST_FAILURE';

export const GET_BULK_ASSIGN_ALL_STUDENT_REQUESTED = 'housing/GET_BULK_ASSIGN_ALL_STUDENT_REQUESTED';
export const GET_BULK_ASSIGN_ALL_STUDENT_SUCCESS = 'housing/GET_BULK_ASSIGN_ALL_STUDENT_SUCCESS';
export const GET_BULK_ASSIGN_ALL_STUDENT_FAILURE = 'housing/GET_BULK_ASSIGN_ALL_STUDENT_FAILURE';

export const GET_HOUSING_MASTER_LIST_REQUESTED = 'housing/GET_HOUSING_MASTER_LIST_REQUESTED';
export const GET_HOUSING_MASTER_LIST_SUCCESS = 'housing/GET_HOUSING_MASTER_LIST_SUCCESS';
export const GET_HOUSING_MASTER_LIST_FAILURE = 'housing/GET_HOUSING_MASTER_LIST_FAILURE';

export const GET_ROOM_SUITE_LIST_REQUESTED = 'housing/GET_ROOM_SUITE_LIST_REQUESTED';
export const GET_ROOM_SUITE_LIST_SUCCESS = 'housing/GET_ROOM_SUITE_LIST_SUCCESS';
export const GET_ROOM_SUITE_LIST_FAILURE = 'housing/GET_ROOM_SUITE_LIST_FAILURE';

export const GET_MOVING_DATES_LIST_REQUESTED = 'housing/GET_MOVING_DATES_LIST_REQUESTED';
export const GET_MOVING_DATES_LIST_SUCCESS = 'housing/GET_MOVING_DATES_LIST_SUCCESS';
export const GET_MOVING_DATES_LIST_FAILURE = 'housing/GET_MOVING_DATES_FAILURE';

export const GET_MOVING_DATES_BY_ID_REQUESTED = 'housing/GET_MOVING_DATES_BY_ID_REQUESTED';
export const GET_MOVING_DATES_BY_ID_SUCCESS = 'housing/GET_MOVING_DATES_BY_ID_SUCCESS';
export const GET_MOVING_DATES_BY_ID_FAILURE = 'housing/GET_MOVING_DATES_BY_ID_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getSuiteList: null,
  getSuiteData: null,
  getPlanList: null,
  getMealPlanById: null,
  getFeesData: null,
  getFeesDataHistory: null,
  studentHousingList: null,
  suitRoomList: null,
  eniteSuiteRoomList: null,
  housingInvoiceAmount: null,
  housingList: null,
  suiteOrRoomDetails: null,
  suiteOrRoomDetailsById: null,
  getStudentHousingDetails: null,
  getStudentHousingDetail: null,
  housingQueueList: null,
  getBulkAssignAllStudentList: null,
  housingMasterList: null,
  roomSuiteQueueList: null,
  movingDatesList: null,
  movingDatebyId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_OR_UPDATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case ADD_OR_UPDATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_OR_UPDATE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  case GET_SUITE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_SUITE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getSuiteList: action.result?.data
    };
  }
  case GET_SUITE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getSuiteList: null,
    };
  }
  case GET_SUITE_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_SUITE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getSuiteData: action.result?.data
    };
  }
  case GET_SUITE_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getSuiteData: null,
    };
  }
  case GET_MEAL_PLAN_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MEAL_PLAN_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getPlanList: action.result?.data
    };
  }
  case GET_MEAL_PLAN_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getPlanList: null,
    };
  }
  case GET_MEAL_PLAN_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MEAL_PLAN_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getMealPlanById: action.result?.data
    };
  }
  case GET_MEAL_PLAN_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getMealPlanById: null,
    };
  }
  case GET_FEES_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_FEES_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getFeesData: action.result?.data
    };
  }
  case GET_FEES_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getFeesData: null,
    };
  }
  case GET_HOUSING_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOUSING_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      housingList: action.result?.data
    };
  }
  case GET_HOUSING_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      housingList: null
    };
  }
  case GET_FEES_DATA_HISTORY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_FEES_DATA_HISTORY_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getFeesDataHistory: action.result?.data
    };
  }
  case GET_FEES_DATA_HISTORY_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getFeesDataHistory: null
    };
  }
  case GET_SUIT_ROOM_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_SUIT_ROOM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      suitRoomList: action.result?.data
    };
  }
  case GET_SUIT_ROOM_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      suitRoomList: null,
    };
  }
  case GET_ENTIRE_SUIT_ROOM_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ENTIRE_SUIT_ROOM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      eniteSuiteRoomList: action.result?.data
    };
  }
  case GET_ENTIRE_SUIT_ROOM_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      eniteSuiteRoomList: null,
    };
  }

  case GET_HOUSING_INVOICE_AMOUNT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOUSING_INVOICE_AMOUNT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      housingInvoiceAmount: action.result?.data
    };
  }
  case GET_HOUSING_INVOICE_AMOUNT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      housingInvoiceAmount: null,
    };
  }
  case GET_SUITE_OR_ROOM_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_SUITE_OR_ROOM_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      suiteOrRoomDetails: action.result?.data
    };
  }
  case GET_SUITE_OR_ROOM_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      suiteOrRoomDetails: null,
    };
  }
  case GET_HOUSING_STUDENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOUSING_STUDENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      studentHousingList: action.result?.data
    };
  }
  case GET_HOUSING_STUDENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      studentHousingList: null,
    };
  }
  case GET_SUITE_OR_ROOM_DETAILS_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_SUITE_OR_ROOM_DETAILS_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      suiteOrRoomDetailsById: action.result?.data
    };
  }
  case GET_SUITE_OR_ROOM_DETAILS_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      suiteOrRoomDetailsById: null,
    };
  }
  case GET_STUDENT_HOUSING_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_HOUSING_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getStudentHousingDetails: action?.result?.data,
    };
  }
  case GET_STUDENT_HOUSING_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getStudentHousingDetails: null,
    };
  }
  case GET_STUDENT_HOUSING_DETAIL_REQUESTED: {
    return {
      ...state,
      housingLoading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_STUDENT_HOUSING_DETAIL_SUCCESS: {
    return {
      ...state,
      housingLoading: false,
      loaded: true,
      error: false,
      getStudentHousingDetail: action?.result?.data,
    };
  }
  case GET_STUDENT_HOUSING_DETAIL_FAILURE: {
    return {
      ...state,
      housingLoading: false,
      loaded: false,
      error: true,
      getStudentHousingDetail: null,
    };
  }

  case GET_HOUSING_QUEUE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOUSING_QUEUE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      housingQueueList: action?.result?.data,
    };
  }
  case GET_HOUSING_QUEUE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      housingQueueList: null,
    };
  }
  case GET_BULK_ASSIGN_ALL_STUDENT_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_BULK_ASSIGN_ALL_STUDENT_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getBulkAssignAllStudentList: action?.result?.data,
    };
  }
  case GET_BULK_ASSIGN_ALL_STUDENT_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getBulkAssignAllStudentList: null,
    };
  }
  case GET_HOUSING_MASTER_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_HOUSING_MASTER_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      housingMasterList: action.result?.data
    };
  }
  case GET_HOUSING_MASTER_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      housingMasterList: null
    };
  }
  case GET_ROOM_SUITE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_ROOM_SUITE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      roomSuiteQueueList: action.result?.data
    };
  }
  case GET_ROOM_SUITE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      roomSuiteQueueList: null
    };
  }
  case GET_MOVING_DATES_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MOVING_DATES_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      movingDatesList: action.result?.data
    };
  }
  case GET_MOVING_DATES_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      movingDatesList: null
    };
  }
  case GET_MOVING_DATES_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_MOVING_DATES_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      movingDatebyId: action.result?.data
    };
  }
  case GET_MOVING_DATES_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      movingDatebyId: null
    };
  }
  default:
    return state;
  }
};

export const addSuite = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addSuites', { params })
  };
};

export const getSuitesList = (params) => {
  return {
    types: [GET_SUITE_LIST_REQUESTED, GET_SUITE_LIST_SUCCESS, GET_SUITE_LIST_FAILURE],
    promise: (client) => client.get('suitesList', { params })
  };
};

export const updateSuites = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('updateSuites', { params })
  };
};

export const getSuiteData = (id) => {
  return {
    types: [GET_SUITE_DATA_REQUESTED, GET_SUITE_DATA_SUCCESS, GET_SUITE_DATA_FAILURE],
    promise: (client) => client.get(`getSuiteData/${id}`)
  };
};

export const getMealPlanList = (params) => {
  return {
    types: [GET_MEAL_PLAN_LIST_REQUESTED, GET_MEAL_PLAN_LIST_SUCCESS, GET_MEAL_PLAN_LIST_FAILURE],
    promise: (client) => client.get('getMealPlanList', { params })
  };
};

export const addMealPlan = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addMealPlan', { params })
  };
};

export const getMealPlan = (id) => {
  return {
    types: [GET_MEAL_PLAN_BY_ID_REQUESTED, GET_MEAL_PLAN_BY_ID_SUCCESS, GET_MEAL_PLAN_BY_ID_FAILURE],
    promise: (client) => client.get(`getMealPlanDetails/${id}`)
  };
};

export const addMealPlanFees = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('mealPlanFees', { data })
  };
};

export const addRoomFees = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('roomFeesAdd', { data })
  };
};

export const getFeesData = (params) => {
  return {
    types: [GET_FEES_DATA_REQUESTED, GET_FEES_DATA_SUCCESS, GET_FEES_DATA_FAILURE],
    promise: (client) => client.get('getFeesData', { params })
  };
};

export const getFeeSettingHistory = (params) => {
  return {
    types: [GET_FEES_DATA_HISTORY_REQUESTED, GET_FEES_DATA_HISTORY_SUCCESS, GET_FEES_DATA_HISTORY_FAILURE],
    promise: (client) => client.get('getFeeSettingHistory', { params })
  };
};

export const assignSuite = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('assignSuite', { data })
  };
};

export const deleteSuite = (deleteId) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post(`deleteSuite/?assignedId=${deleteId}`)
  };
};

export const searchHousingList = (params, data) => {
  return {
    types: [GET_HOUSING_LIST_REQUESTED, GET_HOUSING_LIST_SUCCESS, GET_HOUSING_LIST_FAILURE],
    promise: (client) => client.post('searchHousingList', { params, data })
  };
};

export const getStudentList = () => {
  return {
    types: [GET_HOUSING_STUDENT_LIST_REQUESTED, GET_HOUSING_STUDENT_LIST_SUCCESS, GET_HOUSING_STUDENT_LIST_FAILURE],
    promise: (client) => client.get('getStudentList')
  };
};
export const getSuitAndRoom = (params) => {
  return {
    types: [GET_SUIT_ROOM_LIST_REQUESTED, GET_SUIT_ROOM_LIST_SUCCESS, GET_SUIT_ROOM_LIST_FAILURE],
    promise: (client) => client.get('getSuitAndRoom', { params })
  };
};

export const getHousingInvoiceAmount = (params) => {
  return {
    types: [GET_HOUSING_INVOICE_AMOUNT_REQUESTED, GET_HOUSING_INVOICE_AMOUNT_SUCCESS, GET_HOUSING_INVOICE_AMOUNT_FAILURE],
    promise: (client) => client.get('getHousingInvoiceAmount', { params })
  };
};

export const getSuiteOrRoomDetails = (suiteOrRoomId) => {
  return {
    types: [GET_SUITE_OR_ROOM_DETAILS_REQUESTED, GET_SUITE_OR_ROOM_DETAILS_SUCCESS, GET_SUITE_OR_ROOM_DETAILS_FAILURE],
    promise: (client) => client.get(`getSuiteOrRoomDetails/${suiteOrRoomId}`)
  };
};

export const getSuiteOrMealbyId = (params) => {
  return {
    types: [GET_SUITE_OR_ROOM_DETAILS_BY_ID_REQUESTED, GET_SUITE_OR_ROOM_DETAILS_BY_ID_SUCCESS, GET_SUITE_OR_ROOM_DETAILS_BY_ID_FAILURE],
    promise: (client) => client.get('getSuiteOrMealbyId', { params })
  };
};

export const bulkSuiteAssign = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('bulkSuiteAssign', { data })
  };
};

export const getEntireSuitAndRoom = (params) => {
  return {
    types: [GET_ENTIRE_SUIT_ROOM_LIST_REQUESTED, GET_ENTIRE_SUIT_ROOM_LIST_SUCCESS, GET_ENTIRE_SUIT_ROOM_LIST_FAILURE],
    promise: (client) => client.get('getEntireSuitAndRoom', { params })
  };
};

export const getStudentHousingDetails = (id, list) => {
  return {
    types: [GET_STUDENT_HOUSING_DETAILS_REQUESTED, GET_STUDENT_HOUSING_DETAILS_SUCCESS, GET_STUDENT_HOUSING_DETAILS_FAILURE],
    promise: (client) => client.get(`getStudentHousingDetails/${id}/${list}`)
  };
};

export const getStudentHousingDetail = (id, list) => {
  return {
    types: [GET_STUDENT_HOUSING_DETAIL_REQUESTED, GET_STUDENT_HOUSING_DETAIL_SUCCESS, GET_STUDENT_HOUSING_DETAIL_FAILURE],
    promise: (client) => client.get(`getStudentHousingDetails/${id}/${list}`)
  };
};

export const getQueue = (params, data) => {
  return {
    types: [GET_HOUSING_QUEUE_LIST_REQUESTED, GET_HOUSING_QUEUE_LIST_SUCCESS, GET_HOUSING_QUEUE_LIST_FAILURE],
    promise: (client) => client.post('getQueue', { params, data })
  };
};
export const assignQueue = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('assignQueue', { params })
  };
};
export const deleteQueue = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('deleteQueue', { params })
  };
};

export const sendDocusignContract = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('sendDocusignContract', { params })
  };
};

export const housingAllocateSearch = (params, data) => {
  return {
    types: [GET_HOUSING_LIST_REQUESTED, GET_HOUSING_LIST_SUCCESS, GET_HOUSING_LIST_FAILURE],
    promise: (client) => client.post('housingAllocateSearch', { params, data })
  };
};

export const addHousingAllocate = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addHousingAllocate', { data })
  };
};

export const deleteAllocation = (deleteId) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post(`deleteHousingAllocation/?allocatedId=${deleteId}`)
  };
};

export const assignRoomorSuite = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('assignRoomorSuite', { data })
  };
};

export const getBulkAssignAllStudentsList = (params) => {
  return {
    types: [GET_BULK_ASSIGN_ALL_STUDENT_REQUESTED, GET_BULK_ASSIGN_ALL_STUDENT_SUCCESS, GET_BULK_ASSIGN_ALL_STUDENT_FAILURE],
    promise: (client) => client.get('getBulkAssignAllStudentsList', { params })
  };
};

export const updatehousingAllocation = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('updatehousingAllocation', { data })
  };
};

export const getHousingMasterList = (params, data) => {
  return {
    types: [GET_HOUSING_MASTER_LIST_REQUESTED, GET_HOUSING_MASTER_LIST_SUCCESS, GET_HOUSING_MASTER_LIST_FAILURE],
    promise: (client) => client.post('housingMasterList', { params, data })
  };
};

export const getHousingQueueRoomSuite = (params) => {
  return {
    types: [GET_ROOM_SUITE_LIST_REQUESTED, GET_ROOM_SUITE_LIST_SUCCESS, GET_ROOM_SUITE_LIST_FAILURE],
    promise: (client) => client.get('housingQueueRoomSuite', { params })
  };
};

export const getMovingDatesCTList = (params) => {
  return {
    types: [GET_MOVING_DATES_LIST_REQUESTED, GET_MOVING_DATES_LIST_SUCCESS, GET_MOVING_DATES_LIST_FAILURE],
    promise: (client) => client.get('movingDatesCTList', { params })
  };
};

export const addUpdateMovingDates = (params) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('addUpdateMovingDates', { params })
  };
};

export const getMovingDatesById = (params) => {
  return {
    types: [GET_MOVING_DATES_BY_ID_REQUESTED, GET_MOVING_DATES_BY_ID_SUCCESS, GET_MOVING_DATES_BY_ID_FAILURE],
    promise: (client) => client.get('getDatesById', { params })
  };
};
