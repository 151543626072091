export default {
  PASSWORD: 'Please enter valid password',
  INVALID_LOGIN: 'Invalid login, try again',
  EMP_REFERENCE_ADD_FAILURE: 'Failed to add reference',
  EMP_REFERENCE_UPDATE_FAILURE: 'Failed to update reference',
  ACCESS_GROUP_ADD_FAILURE: 'Failed to create group',
  ACCESS_GROUP_UPDATE_FAILURE: 'Failed to update group',
  ACCESS_GROUP_DELETE_FAILURE: 'Failed to delete group',
  COMPONENT_ADD_FAILURE: 'Failed to create component',
  COMPONENT_UPDATE_FAILURE: 'Failed to update component',
  COMPONENT_DELETE_FAILURE: 'Failed to delete component',
  MENU_ADD_FAILURE: 'Failed to create menu',
  MENU_UPDATE_FAILURE: 'Failed to update menu',
  MENU_DELETE_FAILURE: 'Failed to delete menu',
  EMP_ADD_FAILURE: 'Failed to add employee. Please try again!',
  EMP_GET_LIST_FAILURE: 'Failed to fetch Employee, Try again!',
  CONTROL_TABLE_FAILURE: 'Something went Wrong!',
  LEAD_GET_LIST_FAILURE: 'Failed to fetch Lead, Try again!',
  LEAD_ADD_ERROR: 'Failed to add Lead, Try again!',
  EMP_BANK_DETAILS_FAILURE: 'Failed to Update Employee Bank Details, try again',
  EMP_HR_FAILURE: 'Failed to Update Employee HR Section, try again',
  LEAD_UPDATE_ERROR: 'Failed to update Lead, Try again!',
  LEAD_TASK_ADD_ERROR: 'Failed to add Lead Task, Try again!',
  LEAD_TASK_SEARCH_ERROR: 'Failed to search Lead Task, Try again!',
  STATUS_NOT_FOUND: 'Status not found',
  INVALID_STATUS: 'Invalid status',
  LEAD_ADDRESS_FAILURE: 'Failed to Update Lead Address, try again',
  LEAD_TASK_SEARCH_FAILURE: 'Failed to fetch details, try again',
  ACCESS_RIGHTS_UPDATE_FAILURE: 'Failed to update Access Rights',
  FAIR_ADD_ERROR: 'Failed to add Fair, Try again!',
  TASK_SEARCH_FAILURE: 'Failed to search Tasks, Try again!',
  FAIR_LIST_ERROR: 'Failed to fetch Fair, Try again!',
  EMAIL_TEMPLATE_GET_LIST_FAILURE: 'Failed to fetch Email Templates, Try again!',
  EMAIL_TEMPLATE_ADD_ERROR: 'Failed to add Email Template, Try again!',
  LEAD_BULK_UPDATE_FAILURE: 'Failed to update Bulk Lead, Try again!',
  LEAD_BULK_DELETE_FAILURE: 'Failed to delete Bulk Lead, Try again!',
  GET_DOCUMENT_FAILED: 'Failed to fetch data, Try again!',
  DUPLICATE_LEAD_COUNT: 'Failed to fetch Leads, Try again!',
  DUPLICATE_LEADS_MERGE: 'Failed to merge the Leads. Try again!',
  EVALUATION_FAILED: 'Failed to submit the form. Try again!',
  STATUS_WISE_LEAD: 'Failed to fetch Lead Status Wise Report. Try again!',
  STUDENT_ADDRESS_FAILURE: 'Failed to save the address. Try again!',
  STUDENT_NOTE_ADD_FAILURE: 'Failed to add note. Try again!',
  STUDENT_NOTE_UPDATE_FAILURE: 'Failed to update note. Try again!',
  NOTE_DELETE_FAILURE: 'Failed to delete note. Try again!',
  STUDENT_ACCOUNTING_NOTE_ADD_FAILURE: 'Failed to add accounting note. Try again!',
  STUDENT_ACCOUNTING_NOTE_UPDATE_FAILURE: 'Failed to update accounting note. Try again!',
  STUDENT_MED6_NOTE_ADD_FAILURE: 'Failed to add Med6 LOA note. Try again!',
  STUDENT_MED6_NOTE_UPDATE_FAILURE: 'Failed to update Med6 LOA note. Try again!',
  FAILED_VERIFICATION: 'Failed to update info.Try again!',
  CODE_VERIFICATION: 'Please Enter Verification Code, Verification code is required',
  CLASS_VALIDATION: 'Please select at least one class',
  ACADEMIC_RECORDS_1_2_4_FAILURE: 'Failed to save Applicant Academic details, Please try again!',

  STATUS_CHANGE_ADD_FAILURE: 'Failed to change status. Try again!',
  STATUS_CHANGE_UPDATE_FAILURE: 'Failed to change status. Try again!',
};
