/* eslint-disable import/no-cycle */
import React from 'react';
import * as Icon from 'react-feather';
import { ROUTE_OPTIONS } from '../constants';
import appConfig from '../constants/appConfig';
import { getMenuByAccess } from '../utility/helpers/accessRightHelper';
import { getAuthUserData, getUserRoleId } from '../utility/helpers/helperFunction';

const navigationConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    adminView: true,
    type: 'item',
    icon: <Icon.List size={40} />,
    navLink: '/dashboard/list',
    accessRight: '/dashboard/list',
    text: 'Operations keeps the lights on, strategy provides a light at the end of the tunnel, but project management is the train engine that moves the organisation forward.'
  },
  {
    id: 'medical',
    title: 'Student',
    type: 'collapse',
    adminView: true,
    icon: <Icon.User size={40} />,
    accessRight: '/medical',
    text: 'Primarily a person enrolled in an educational institution and who is under learning with goals of acquiring knowledge, developing professions.',
    children: [
      {
        id: 'student',
        title: 'All Students',
        type: 'item',
        icon: <Icon.User size={12} />,
        navLink:
          getAuthUserData() && getAuthUserData().userType === 'Student' || getAuthUserData().userType === 'Parent'
            ? `/student/details/personal/${
                getAuthUserData() && getAuthUserData().studentId
              }`
            : '/student/list',
        accessRight: '/student'
      },
      {
        id: 'clinicals',
        title: 'Clinicals',
        type: 'item',
        icon: <Icon.Thermometer size={12} />,
        navLink:
          getAuthUserData() && getAuthUserData().userType === 'Preceptor'
            ? '/medical/clinicals/preceptors/list'
            : '/medical/clinicals/assign-rotation/list',
        accessRight:
          getAuthUserData() && getAuthUserData().userType === 'Preceptor'
            ? '/medical/clinicals/preceptors'
            : '/medical/clinicals'
      },
      {
        id: 'attendance',
        title: 'Attendance',
        type: 'item',
        icon: <Icon.Calendar size={12} />,
        navLink: getMenuByAccess(ROUTE_OPTIONS.ATTENDANCE)?.children[0]?.link || '/medical/attendance/stage-schedule',
        accessRight: '/medical/attendance'
      },
      {
        id: 'request',
        title: 'Request',
        type: 'item',
        icon: <Icon.PhoneCall size={12} />,
        navLink: getMenuByAccess(ROUTE_OPTIONS.REQUESTS)?.children[0]?.link || '/medical/request/transcript-request',
        accessRight: '/medical/request'
      },
      {
        id: 'alumniList',
        title: 'Alumni List',
        type: 'item',
        icon: <Icon.Users size={12} />,
        navLink: '/medical/alumniList',
        accessRight: '/medical/alumniList'
      },
      {
        id: 'registrar',
        title: 'Registrar',
        type: 'item',
        icon: <Icon.User size={12} />,
        navLink: '/medical/registrar',
        accessRight: '/medical/registrar'
      }
    ]
  },
  {
    id: 'lead',
    title: 'Lead',
    type: 'item',
    adminView: true,
    icon: <Icon.Users size={40} />,
    navLink: '/lead/list',
    accessRight: '/lead',
    text: 'If your actions inspire others to dream more, learn more, do more and become more, you are a leader.'
  },
  {
    id: 'employee',
    title: 'Employee',
    type: 'item',
    adminView: true,
    icon: <Icon.Users size={40} />,
    navLink: getAuthUserData() && getAuthUserData().userType === 'Employee' ? getAuthUserData() && getAuthUserData().isOfferLetterAccepted
      ? `${getMenuByAccess(ROUTE_OPTIONS.EMPLOYEE)?.children[0]?.link}/${getAuthUserData().employeeId}`
      : `/employee/details/offerletter/${getAuthUserData().employeeId}` : '/employee/list',
    accessRight: '/employee',
    text: 'Employees who bring innovative ideas and suggestions forward that will impact positively are an asset to an organisation.'
  },
  {
    id: 'accounting',
    title: 'Accounting',
    type: 'collapse',
    adminView: true,
    icon: <Icon.Layers size={40} />,
    navLink: getMenuByAccess(ROUTE_OPTIONS.ACCOUNTING)?.children[0]?.link,
    accessRight: '/accounting',
    text: 'Accounting refers to the systematic, detailed and successful recording of financial transactions of a business / organisation.',
    // children: [
    //   {
    //     id: 'invoices',
    //     title: 'Invoices',
    //     type: 'item',
    //     icon: <Icon.List size={12} />,
    //     navLink: '/accounting/invoices/non-clinical',
    //     accessRight: '/accounting/invoices'
    //   },
    //   {
    //     id: 'scholarship',
    //     title: 'Scholarship',
    //     type: 'item',
    //     icon: <Icon.BookOpen size={12} />,
    //     navLink: '/accounting/scholarship',
    //     accessRight: '/accounting/scholarship'
    //   },
    //   {
    //     id: 'loa',
    //     title: 'LOA',
    //     type: 'item',
    //     icon: <Icon.ArrowDown size={12} />,
    //     navLink: '/accounting/loa/med6Loa',
    //     accessRight: '/accounting/loa'
    //   },
    //   {
    //     id: 'revenue',
    //     title: 'Revenue',
    //     type: 'item',
    //     icon: <Icon.DollarSign size={12} />,
    //     navLink: '/accounting/revenue',
    //     accessRight: '/accounting/revenue'
    //   },
    // {
    //   id: 'autoPaymentList',
    //   title: 'AutoPayment List',
    //   type: 'item',
    //   icon: <Icon.List size={12} />,
    //   navLink: '/accounting/autoPaymentList',
    //   accessRight: '/accounting/autoPaymentList'
    // },
    // {
    //   id: 'userAccess',
    //   title: 'User Access',
    //   type: 'item',
    //   icon: <Icon.UserCheck size={12} />,
    //   navLink: '/accounting/userAccess',
    //   accessRight: '/accounting/userAccess'
    // },
    //   {
    //     id: 'lead',
    //     title: 'Lead',
    //     type: 'item',
    //     icon: <Icon.Users size={12} />,
    //     navLink: '/accounting/lead',
    //     accessRight: '/accounting/lead'
    //   },
    //   {
    //     id: 'unverified-notifications',
    //     title: 'Unverified Payments',
    //     type: 'item',
    //     icon: <Icon.List size={12} />,
    //     navLink: '/accounting/unverified-notifications',
    //     accessRight: '/accounting/unverified-notifications'
    //   },
    // ]
  },
  {
    id: 'housing',
    title: 'Housing',
    type: 'item',
    adminView: true,
    icon: <Icon.Users size={40} />,
    navLink: getMenuByAccess(ROUTE_OPTIONS.HOUSING)?.children[0]?.link || '/housing/allocation',
    accessRight: '/housing',
    text: 'Room and meal plan allotment to students based on their preference'
  },
  {
    id: 'commitee',
    title: 'Committee Meeting',
    type: 'item',
    adminView: true,
    icon: <Icon.Mail size={40} />,
    navLink: getMenuByAccess(ROUTE_OPTIONS.COMMITTEE)?.children[0]?.link || '/committee/list',
    accessRight: '/committee',
    text: 'A committee meeting is a formal gathering of subgroups within an organisation. The meetings are a forum for the exchange of ideas so that a committee can complete its set of tasks.'
  },
  // {
  //   id: 'task',
  //   title: 'Task',
  //   type: 'item',
  //   icon: <Icon.List size={40} />,
  //   navLink: '/task/list',
  //   accessRight: '/task/list',
  //   text: 'Operations keeps the lights on, strategy provides a light at the end of the tunnel, but project management is the train engine that moves the organisation forward.'
  // },
  {
    id: 'exams',
    title: 'Exams',
    type: 'item',
    adminView: true,
    icon: <Icon.Clipboard size={40} />,
    navLink: getMenuByAccess(ROUTE_OPTIONS.EXAMS)?.children[0]?.link,
    accessRight: '/exams',
    text: 'There are no secrets to success. It is the result of preparation, hard work and learning from failure.',
    // children: [
    //   {
    //     id: 'nbme-shelf',
    //     title: 'NBME Shelf',
    //     type: 'item',
    //     icon: <Icon.List size={12} />,
    //     navLink: '/exams/nbme-shelf',
    //     accessRight: '/exams/nbme-shelf'
    //   },
    //   {
    //     id: 'nbme-comprehensive',
    //     title: 'NBME Comprehensive',
    //     type: 'item',
    //     icon: <Icon.List size={12} />,
    //     navLink: '/exams/nbme-comprehensive',
    //     accessRight: '/exams/nbme-comprehensive'
    //   },
    //   {
    //     id: 'kaplan',
    //     title: 'Kaplan Diagnostic',
    //     type: 'item',
    //     icon: <Icon.List size={12} />,
    //     navLink: '/exams/kaplan',
    //     accessRight: '/exams/kaplan'
    //   },{
    //     id: 'retake-exams',
    //     title : 'Retake Exams',
    //     type : 'item',
    //     icon: <Icon.List size = {12}/>,
    //     navLink : '/exams/retake-exams',
    //     accessRight: '/exams/retake-exams',
    //   }
    // ]
  },
  // {
  //   id: 'mailLog',
  //   title: 'MailLog',
  //   type: 'item',
  //   icon: <Icon.Mail size={40} />,
  //   navLink: '/mailLog/list',
  //   accessRight: '/mailLog/list',
  //   text: 'Outgoing and incoming emails or messages can be permanently saved without creating unnecessary paperwork.'
  // },
  // {
  //   id: 'document',
  //   title: 'Document',
  //   type: 'item',
  //   adminView: true,
  //   icon: <Icon.File size={40} />,
  //   navLink: '/documents',
  //   accessRight: '/documents',
  //   text: 'In general, a document is a record or the capturing of some event / files or thing so that the information will not be lost.'
  // },
  {
    id: 'reports',
    title: 'Reports',
    type: 'item',
    adminView: true,
    icon: <Icon.FileText size={40} />,
    navLink: '/reports/list',
    accessRight: '/reports/list',
    text: 'A report is a document that presents information in an organised format for a specific audience and purpose.'
  },
  // {
  //   id: 'settings',
  //   title: 'Settings',
  //   type: 'item',
  //   adminView: true,
  //   icon: <Icon.Settings size={40} />,
  //   navLink: '/settings/user/profile',
  //   accessRight: '/settings/user',
  //   text: 'Settings is where a user can modify his/her personal details',
  //   children: [
  //     {
  //       id: 'profile',
  //       title: 'Profile',
  //       type: 'item',
  //       icon: <Icon.User size={12} />,
  //       navLink: '/settings/user/profile',
  //       accessRight: '/settings/user/profile'
  //     },
  //     {
  //       id: 'password',
  //       title: 'Password',
  //       type: 'item',
  //       icon: <Icon.Lock size={12} />,
  //       navLink: '/settings/user/password',
  //       accessRight: '/settings/user/password'
  //     }
  //     // {
  //     //   id: 'userProfile',
  //     //   title: 'Profile',
  //     //   type: 'item',
  //     //   icon: <Icon.User size={12} />,
  //     //   navLink: '/settings/user/profile',
  //     //   accessRight: '/settings/user/profile'
  //     // },
  //     // {
  //     //   id: 'userProfile',
  //     //   title: 'Profile',
  //     //   type: 'item',
  //     //   icon: <Icon.User size={12} />,
  //     //   navLink: '/settings/user/profile',
  //     //   accessRight: '/settings/user/profile'
  //     // },
  //     // {
  //     //   id: 'userProfile',
  //     //   title: 'Profile',
  //     //   type: 'item',
  //     //   icon: <Icon.User size={12} />,
  //     //   navLink: '/settings/user/profile',
  //     //   accessRight: '/settings/user/profile'
  //     // },
  //   ]
  // },
  // {
  //   id: 'turkey',
  //   title: 'Turkey',
  //   type: 'item',
  //   icon: <Icon.Globe size={40} />,
  //   navLink: '/turkey/list',
  //   accessRight: '/turkey/list',
  //   text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Facilis optio excepturi libero quae asperiores, temporibus quibusdam exercitationem earum!'
  // },
  // {
  //   id: 'preceptor',
  //   title: 'Preceptor',
  //   type: 'item',
  //   adminView: false,
  //   icon: <Icon.Users size={40} />,
  //   navLink: '/preceptor/user/profile',
  //   accessRight: '/preceptor',
  //   text: 'Preceptor who bring innovative ideas and suggestions forward that will impact positively are an asset to an organisation.',

  // },
  {
    id: 'mailLog',
    title: 'Mail Log',
    type: 'item',
    adminView: true,
    icon: <Icon.Mail size={40} />,
    navLink: getMenuByAccess(ROUTE_OPTIONS.MAILLOG)?.children[0]?.link || '/mailLog/incoming',
    accessRight: '/mailLog',
    text: 'The logs created contain information on each email like Received Date, Received From, Department, Entered by and Entered On etc.'
  },
  {
    id: 'calendar',
    title: 'Calendar',
    type: 'item',
    adminView: true,
    icon: <Icon.Calendar size={40} />,
    navLink: '/calendar',
    accessRight: '/calendar',
    text: 'A calendar is a system of organising days. This is done by giving names to periods of time, typically days, weeks, months and years.'
  },
  {
    id: 'task',
    title: 'Task',
    type: 'item',
    adminView: true,
    icon: <Icon.List size={40} />,
    navLink: '/task/list',
    accessRight: '/task/list',
    text: 'A task manager is to monitor all the tasks assigned and to configure tasks and to add new tasks.'
  },
  {
    id: 'admin',
    title: 'System Admin',
    type: 'item',
    adminView: true,
    icon: <Icon.Settings size={40} />,
    navLink: getUserRoleId() === appConfig.SUPER_USER ? '/admin/user' : getUserRoleId() === appConfig.SUPER_ADMIN ? '/admin/group/list' : getMenuByAccess(ROUTE_OPTIONS.ADMIN)?.children[0]?.link,
    accessRight: '/admin',
    text: 'A system administrator, or sysadmin, is a person who is responsible for the upkeep, configuration, and reliable operation of computer systems.'
  },
];

export default navigationConfig;
