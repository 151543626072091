export const GET_EMP_REFERENCES_REQUESTED = 'employee/GET_EMP_REFERENCES_REQUESTED';
export const GET_EMP_REFERENCES_SUCCESS = 'employee/GET_EMP_REFERENCES_SUCCESS';
export const GET_EMP_REFERENCES_FAILURE = 'employee/GET_EMP_REFERENCES_FAILURE';

export const GET_EMP_REFERENCES_BY_ID_SUCCESS = 'employee/GET_EMP_REFERENCES_BY_ID_SUCCESS';

export const ADD_EMP_REFERENCE_REQUESTED = 'employee/ADD_EMP_REFERENCE_REQUESTED';
export const ADD_EMP_REFERENCE_SUCCESS = 'employee/ADD_EMP_REFERENCE_SUCCESS';
export const ADD_EMP_REFERENCE_FAILURE = 'employee/ADD_EMP_REFERENCE_FAILURE';

export const UPDATE_EMP_REFERENCE_REQUESTED = 'employee/UPDATE_EMP_REFERENCE_REQUESTED';
export const UPDATE_EMP_REFERENCE_SUCCESS = 'employee/UPDATE_EMP_REFERENCE_SUCCESS';
export const UPDATE_EMP_REFERENCE_FAILURE = 'employee/UPDATE_EMP_REFERENCE_FAILURE';

export const UPDATE_EMPLOYEE_BENEFITS_REQUESTED = 'employee/UPDATE_EMPLOYEE_BENEFITS_REQUESTED';
export const UPDATE_EMPLOYEE_BENEFITS_SUCCESS = 'employee/UPDATE_EMPLOYEE_BENEFITS_SUCCESS';
export const UPDATE_EMPLOYEE_BENEFITS_FAILURE = 'employee/UPDATE_EMPLOYEE_BENEFITS_FAILURE';

export const GET_EMPLOYEE_BENEFITS_REQUESTED = 'employee/GET_EMPLOYEE_BENEFITS_REQUESTED';
export const GET_EMPLOYEE_BENEFITS_SUCCESS = 'employee/GET_EMPLOYEE_BENEFITS_SUCCESS';
export const GET_EMPLOYEE_BENEFITS_FAILURE = 'employee/GET_EMPLOYEE_BENEFITS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  employeeReferences: [],
  employeeBenefitsDetails: null,
  referenceById: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case GET_EMP_REFERENCES_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case GET_EMP_REFERENCES_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      employeeReferences: action.result,
    };
  }
  case GET_EMP_REFERENCES_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case ADD_EMP_REFERENCE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case ADD_EMP_REFERENCE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_EMP_REFERENCE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case UPDATE_EMP_REFERENCE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }
  case UPDATE_EMP_REFERENCE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case UPDATE_EMP_REFERENCE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case UPDATE_EMPLOYEE_BENEFITS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case UPDATE_EMPLOYEE_BENEFITS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case UPDATE_EMPLOYEE_BENEFITS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case GET_EMPLOYEE_BENEFITS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_EMPLOYEE_BENEFITS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      employeeBenefitsDetails: action.result
    };
  }
  case GET_EMPLOYEE_BENEFITS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      employeeBenefitsDetails: null,
    };
  }
  case GET_EMP_REFERENCES_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      referenceById: action.result,
    };
  }
  default: {
    return {
      ...state
    };
  }
  }
};

export const getEmployeeReferences = (employeeId) => {
  return {
    types: [GET_EMP_REFERENCES_REQUESTED, GET_EMP_REFERENCES_SUCCESS, GET_EMP_REFERENCES_FAILURE],
    promise: (client) => client.get(`getEmployeeReferences/${employeeId}`)
  };
};

export const getEmployeeReferenceById = (referenceId) => {
  return {
    types: [GET_EMP_REFERENCES_REQUESTED, GET_EMP_REFERENCES_BY_ID_SUCCESS, GET_EMP_REFERENCES_FAILURE],
    promise: (client) => client.get(`getReferenceById/${referenceId}`)
  };
};

export const addEmployeeReference = (data) => {
  return {
    types: [ADD_EMP_REFERENCE_REQUESTED, ADD_EMP_REFERENCE_SUCCESS, ADD_EMP_REFERENCE_FAILURE],
    promise: (client) => client.post('addEmployeReference', { data })
  };
};

export const updateEmployeeReference = (data) => {
  return {
    types: [UPDATE_EMP_REFERENCE_REQUESTED, UPDATE_EMP_REFERENCE_SUCCESS, UPDATE_EMP_REFERENCE_FAILURE],
    promise: (client) => client.post('updateEmployeReference', { data })
  };
};

export const updateEmployeeBenefits = (data) => {
  return {
    types: [UPDATE_EMPLOYEE_BENEFITS_REQUESTED, UPDATE_EMPLOYEE_BENEFITS_SUCCESS, UPDATE_EMPLOYEE_BENEFITS_FAILURE],
    promise: (client) => client.post('employeeUpdateBenefits', { data })
  };
};

export const getEmployeeBenefits = (empId) => {
  return {
    types: [GET_EMPLOYEE_BENEFITS_REQUESTED, GET_EMPLOYEE_BENEFITS_SUCCESS, GET_EMPLOYEE_BENEFITS_FAILURE],
    promise: (client) => client.get(`employeeGetBenefits/${empId}`)
  };
};
