export default {
  STATUS: [
    {
      id: 1, name: 'Active', value: true, data: 1
    },
    {
      id: 0, name: 'Inactive', value: false, data: 0
    }
  ],
  PAGE_SIZE: [
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' }
  ],
  SALUTATION: [
    { id: 'Mr', name: 'Mr' },
    { id: 'Miss', name: 'Miss' },
    { id: 'Mrs', name: 'Mrs' },
    { id: 'Dr', name: 'Dr' },
    { id: 'Prof', name: 'prof' },
  ],
  STATUS_ROTATION: [
    { id: 'Approved', name: 'Approved' },
    { id: 'Cancel', name: 'Cancelled' },
    { id: 'Finished', name: 'Finished' },
    { id: 'Waiting for Approval', name: 'Waiting for Approval' }
  ],
  POSITION_TYPE: [
    { id: 'Academic', name: 'Academic' },
    { id: 'Attending', name: 'Attending' },
    { id: 'Private Practice', name: 'Private Practice' },
    { id: 'Residency', name: 'Residency' },
    { id: 'Other', name: 'Other' },
  ],
  DEGREE_LIST: [
    { id: 'Bachelors', name: 'Bachelors' },
    { id: 'Masters', name: 'Masters' },
    { id: 'Ph.D', name: 'Ph.D' },
    { id: 'Other', name: 'Other' },
  ],
  YES_NO: [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No ' }
  ],
  RADIO_YES_NO: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ],
  TYPE_MATCHED: [
    { id: 'Match', name: 'Match' },
    { id: 'Soap', name: 'Soap' }
  ],
  MATCH_ATTEMPTS: [
    { id: '1', name: '1' }, { id: '2', name: '2' },
    { id: '3', name: '3' }, { id: '4', name: '4' },
    { id: '5', name: '5' }, { id: '6', name: '6' },
    { id: '7', name: '7' }, { id: '8', name: '8' },
    { id: '9', name: '9' }, { id: '10', name: '10' }
  ],
  MONTHS: [
    { id: 1, name: 1 }, { id: 2, name: 2 },
    { id: 3, name: 3 }, { id: 4, name: 4 },
    { id: 5, name: 5 }, { id: 6, name: 6 },
    { id: 7, name: 7 }, { id: 8, name: 8 },
    { id: 9, name: 9 }, { id: 10, name: 10 },
    { id: 11, name: 11 }, { id: 12, name: 12 },
  ],
  YEARS: [
    { id: 2021, name: 2021 }, { id: 2022, name: 2022 },
    { id: 2023, name: 2023 }, { id: 2024, name: 2024 },
    { id: 2025, name: 2025 }, { id: 2026, name: 2026 },
    { id: 2027, name: 2027 }, { id: 2028, name: 2028 },
    { id: 2029, name: 2029 }, { id: 2030, name: 2030 },
    { id: 2031, name: 2031 }, { id: 2032, name: 2032 },
    { id: 2033, name: 2033 }, { id: 2034, name: 2034 },
    { id: 2035, name: 2035 }, { id: 2036, name: 2036 },
  ],

  PAYMENT_METHOD: [
    {
      label: 'Immediate process by Debit/Credit Card',
      value: 'CARD'
    },
    {
      label: 'Pay by Wire transfer/Paper check',
      value: 'OTHER'
    }
  ],
  STUDENT_INVOICE_STATUS: [
    { id: 1, name: 'Financially Cleared' },
    { id: 2, name: 'Financially Hold' },
    { id: 3, name: 'Financially Block' }
  ],
  INVOICE_STATUS: [
    { id: 'CR', name: 'Open' },
    { id: 'PP', name: 'Partial Paid' },
    { id: 'FP', name: 'Full Paid' },
  ],
  COURSE: [
    { id: 1, name: 'Non-Clinical' },
    { id: 2, name: 'Clinical' },
  ],
  TRANSCRIPT_STATUS: [
    {
      id: 'Created',
      name: 'Created'
    },
    {
      id: 'Paid',
      name: 'Paid'
    },
    {
      id: 'Under Review',
      name: 'Under Review'
    },
    {
      id: 'Verified',
      name: 'Verified'
    },
    {
      id: 'Complete',
      name: 'Complete'
    },
    {
      id: 'Cancelled',
      name: 'Cancelled'
    },
  ],
  rotationType: [
    {
      id: 'Core',
      name: 'Core'
    },
    {
      id: 'Elective',
      name: 'Elective'
    }
  ],
  STATUS_OPEN: [{
    id: 1,
    name: 'Open'
  },
  {
    id: 2,
    name: 'Completed'
  },
  {
    id: 3,
    name: 'Cancelled'
  }],
  ACCEPTANCE: [
    {
      id: true,
      name: 'Accepted',
    },
    {
      id: false,
      name: 'Not Accepted'
    }],
  PROMOTION: [
    {
      id: 'Promote',
      name: 'Promote'
    },
    {
      id: 'Special',
      name: 'Special'
    },
    {
      id: 'Repeat',
      name: 'Repeat'
    },
    {
      id: 'New Student',
      name: 'New Student',
    }
  ],

  FINANCIAL_STATUS: [
    {
      id: 1,
      name: 'Clear'
    },
    {
      id: 2,
      name: 'Hold'
    },
  ],
  transaction_type: [
    {
      id: 'Debit',
      name: 'Debit',
    },
    {
      id: 'Credit',
      name: 'Credit',
    },
  ],
  refund_type: [
    {
      id: 'Cheque',
      name: 'Cheque'
    },
    {
      id: 'directDeposit',
      name: 'Direct Deposit'
    },
    {
      id: 'Other',
      name: 'Other'
    }
  ],
  CLINICAL_COURSE: [
    {
      id: '0D77E3F4-358D-40C2-957A-3DDCEE31D6A5',
      name: 'Clinical Medicine'
    },
    {
      id: '51E45657-6FB5-49A7-A1EA-2D34FDB72067',
      name: 'MDFP Cliinical Postings'
    }
  ],
  GRADE_TYPE: [
    {
      id: 'marks',
      name: 'Marks',
      value: 1
    },
    {
      id: 'status',
      name: 'Status',
      value: 2
    },
  ],
  COURSE_TYPE: [
    { id: 1, name: 'Non-Clinical' },
    { id: 2, name: 'Clinical' }
  ],
  MINUTE: [
    {
      id: 0, name: 0
    },
    {
      id: 30, name: 30,
    }
  ],
  ALERT_TYPE: [
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' }
  ],
  EXAMS_STATUS: [
    { name: 'Pending', id: 'Pending', value: 0 },
    { name: 'Paid', id: 'Paid', value: 1 },
    { name: 'Verified', id: 'Verified', value: 2 },
    { name: 'Scheduled', id: 'Scheduled', value: 3 },
    { id: 'Cancelled', name: 'Cancelled', value: 4 },
    { name: 'Completed', id: 'Completed', value: 5 },
  ],
  EXAM_TYPE: [
    {
      id: 'CBSE',
      name: 'CBSE'
    }, {
      name: 'CCSE',
      id: 'CCSE',
    }
  ],
  DAYS: [
    {
      id: 'Sunday',
      name: 'Sunday'
    },
    {
      name: 'Monday',
      id: 'Monday',
    },
    {
      id: 'Tuesday',
      name: 'Tuesday'
    },
    {
      name: 'Wednesday',
      id: 'Wednesday',
    },
    {
      id: 'Thursday',
      name: 'Thursday'
    },
    {
      name: 'Friday',
      id: 'Friday',
    },
    {
      id: 'Saturday',
      name: 'Saturday'
    }
  ],
  ROTATION_STATUS: [
    {
      id: 'Requested',
      name: 'Requested'
    },
    {
      id: 'Pending',
      name: 'Pending'
    },
    {
      id: 'Accepted',
      name: 'Accepted'
    },
    {
      id: 'Scheduled',
      name: 'Scheduled'
    },
    {
      id: 'Declined',
      name: 'Declined'
    },
    {
      id: 'Canceled',
      name: 'Canceled'
    }
  ],
  PASS_OR_FAIL: [
    {
      id: 'FAIL',
      name: 'Fail'
    }, {
      name: 'Pass',
      id: 'PASS',
    }
  ],
  // Immigration docment options
  IMMIGRATION_DOCTYPE_OPTIONS: [
    {
      id: 'Birth Certificate or Birth Affadavit',
      name: 'Birth Certificate or Birth Affadavit'
    },
    {
      id: 'Police Clearance',
      name: 'Police Clearance'
    },
    {
      id: 'Passport copy (cover to cover)',
      name: 'Passport copy (cover to cover)'
    },
    {
      id: '6 Passport Photos',
      name: '6 Passport Photos'
    },
    {
      id: 'Marriage Certificate',
      name: 'Marriage Certificate'
    },
    {
      id: 'Divorce Certificate',
      name: 'Divorce Certificate'
    },
    {
      id: 'Name Change Certificate',
      name: 'Name Change Certificate'
    },
    {
      id: 'Citizenship Documentation',
      name: 'Citizenship Documentation'
    },
    {
      id: 'Other',
      name: 'Other'
    },
  ],
  TRANSCRIPT_TYPE: [
    {
      id: 'High School',
      name: 'High School',
    },
    {
      id: 'Undergraduate',
      name: 'Undergraduate',
    },
    {
      id: 'Medical School',
      name: 'Medical School',
    },
    {
      id: 'Graduate (non-medical school)',
      name: 'Graduate (non-medical school)',
    }
  ],
  SURVEY_STATUS: [
    {
      id: 'All',
      name: 'All'
    },
    {
      id: 1,
      name: 'Open',
      label: 'Open',
      value: 'Open'
    }, {
      id: 0,
      name: 'Close',
      label: 'Close',
      value: 'Close'
    }
  ],
  requestRotationStatus: [
    { id: 'Requested', name: 'Requested' },
    { id: 'Pending', name: 'Pending' }
  ],
  rotationTypeNew: [
    {
      id: 1,
      name: 'Core'
    },
    {
      id: 2,
      name: 'Elective'
    }
  ],
};
