/* eslint-disable */
export default {
  // The URL we're connecting to
  hostname: process.env.REACT_APP_API_SERVER_HOST || 'https://sms.xusom.com/xusomauth',
    employee: process.env.REACT_APP_EMPLOYEE_API_HOST || 'https://sms.xusom.com/xusomemployee',
    lead: process.env.REACT_APP_LEAD_API_HOST || 'https://sms.xusom.com/xusomlead',
    admin: process.env.REACT_APP_EMPLOYEE_API_HOST || 'https://sms.xusom.com/xusomadmin',
    account: process.env.REACT_APP_LEAD_API_HOST || 'https://sms.xusom.com/xusomlead',
    webhook: process.env.REACT_APP_LEAD_API_HOST || 'https://sms.xusom.com/xusomlead',
    dashBoard : process.env.REACT_APP_LEAD_API_HOST || 'https://sms.xusom.com/xusomlead',
    housing: process.env.REACT_APP_LEAD_API_HOST || 'https://sms.xusom.com/xusomhousing',
  // Map shortnames to the actual endpoints, so that we can use them in the middleware

  endpoints: {
    auth: '/account/login',
    initialAccessRights: '/admin/user/access-rights',
    studentDetails: '/lead/getDetails',
    employeeAdd: '/employee/add',
    employeeList: '/employee/getlist',
    employeeGetAddress: '/employee/getaddress',
    employeeGetProfile: '/employee/getprofile',
    employeeUpdateProfile: '/employee/updateprofile',
    employeeUpdateAddress: '/employee/addaddress',
    employeeGetBenefits: '/employee/profile/benefits/get',
    employeeUpdateBenefits: '/employee/profile/benefits/update',
    getEmployeeReferences: '/employee/profile/references/get',
    getReferenceById: '/employee/profile/references/get/id',
    addEmployeReference: '/employee/profile/reference/save',
    updateEmployeReference: '/employee/profile/reference/update',
    employeeExport: '/employee/exportlist',
    controlTableData: '/employee/common/ct_data',
    employeeDocumentList: '/employee/employee_document/employee_documents',
    controlDocumentCategory: '/employee/common/ct_employee_documents',
    employeeDocumentAdd: '/employee/employee_document/employee_document_add',
    employeeDocumentDelete: '/employee/employee_document/employee_document_delete',
    employeeDocumentDownload: '/employee/employee_document/employee_document_save',
    employeeDocumentTemplates: '/employee/employee_document/document_templates',
    sendOfferLetterMail: '/employee/employee_document/sendOfferLetterMail',
    employeeGetBankDetails: '/employee/getbankdetails',
    employeeEditBankDetails: '/employee/updatebankdetails',
    employeeGetSalary: '/employee/getsalary',
    employeeEditSalary: '/employee/updatesalary',
    employeeGetPassport: '/employee/get/passport',
    employeePassportAdd: '/employee/update/passport',
    employeePassportDelete: '/employee/delete/passport',
    employeePassportDownload: '/employee/file/passport',
    employeeGetHRSection: '/employee/get/hr',
    employeeHRSectionAdd: '/employee/update/hr',
    getEmployeeBasicInfo: '/employee/get/details',
    getEmployeeSmsSection: '/employee/get/smsSection',
    updateEmployeeSmsSection: '/employee/edit/smsSection',
    sendCredentialMail: '/employee/sendCredMail',
    getEmployeeEmails: '/employee/getEmails',
    employeeBirthDayReportExportToExcel: '/employee/report/birthday/list/export',
    getEmployeeSampleDocument: '/employee/employee_document/sampleform_template_download',
    // employeeUploadPhoto: '/employee/upload/photo',
    employeeUploadPhoto: '/employee/profile/photo',
    leadStatusList: '/lead/utility/status',
    campusList: '/lead/utility/campus',
    semesterYear: '/lead/utility/numbers',
    semesterYearList: '/lead/utility/semesterYear',
    programList: '/lead/utility/program',
    semesterList: '/lead/utility/semester',
    birthCountries: '/lead/utility/country',
    sourceList: '/lead/utility/source',
    addLead: '/lead/save',
    leadList: '/lead/search',
    leadProfile: '/lead/get',
    leadStatusDropdownData: '/lead/nextstatus',
    leadStatusUpdate: '/lead/update/status',
    leadEmailTemplate: '/lead/utility/emailtemplate',
    leadTemplate: '/lead/email/content',
    emailList: '/lead/email/get/list',
    sendEmail: '/lead/email/send',
    mailView: '/lead/email/get',
    downloadAttachment: '/lead/email/attachment',
    leadAddress: '/lead/get/adress',
    leadAddressUpdate: '/lead/update/address',
    updateLeadProfile: '/lead/update',
    subjectTaskList: '/lead/utility/subject',
    saveTask: '/lead/task/add',
    leadTaskList: '/lead/task/get',
    updateLeadTask: '/lead/task/update',
    semesterDeferment: '/lead/deferment/semester',
    taskSearch: '/lead/task/search',
    stateList: '/lead/utility/state',
    courseList: '/lead/utility/course',
    stageList: '/lead/utility/stage',
    preMedHighestdegree: '/lead/utility/highestdegree',
    getSchool: '/lead/utility/school',
    docTypeList: '/lead/utility/doctype',
    documentUpload: '/lead/document/upload',
    documentList: '/lead/document/docList',
    documentUpdate: '/lead/document/update',
    getDocbyId: '/lead/document/get',
    documentDownload: '/lead/document/save',
    deleteDocument: '/lead/document/delete',
    addLeadNote: '/lead/notes/save',
    listLeadNotes: '/lead/notes/list',
    getNoteById: '/lead/notes',
    exportLeadListExcel: '/lead/export',
    semseterListFromCourseId: '/lead/utility/semesterbycourse',
    getMenuList: '/admin/menu/list',
    addMenu: '/admin/menu/create',
    updateMenu: '/admin/menu/update',
    deleteMenu: '/admin/menu/delete',
    getAccessGroupsList: '/admin/group/list?isActive=true&isPublic=true',
    getAccessGroupsHierarchy: '/admin/group/groups_hierarchy?isActive=true&isPublic=true',
    addAccessGroup: '/admin/group/create',
    updateAccessGroup: '/admin/group/update',
    deleteAccessGroup: '/admin/group/delete',
    getComponentsList: '/admin/component/list',
    addComponent: '/admin/component/create',
    updateComponent: '/admin/component/update',
    deleteComponent: '/admin/component/delete',
    getAccessRights: '/admin/group_access/list',
    updateAccessRights: '/admin/group_access/create',
    addLeadEmailTemplate: '/lead/email/template/add',
    updateLeadEmailTemplate: '/lead/email/template/update',
    leadEmailTemplateList: '/lead/email/template/list',
    leadCollegeFairList: '/lead/utility/collegefair',
    uploadBulkLead: '/lead/notification/bulk/lead',
    unAssignedLeads: '/lead/get/unAssingedLead',
    leadTaskSearch: '/lead/task/search',
    leadTaskById: '/lead/task',
    applicationBackGroundSave: '/lead/application/leadbackground/immigration/save',
    getApplicationBackground: '/lead/application/leadbackground/immigration',
    getAdditionalQuestions: '/lead/application/additionalquestion',
    saveAdditionalQuestions: '/lead/application/additionalquestion/save',
    getApplicationData: '/lead/application/submitapplication',
    submitApplicationData: '/lead/application/submitapplication',
    saveAcademics: '/lead/application/new/academics/save',
    getleadTestData: '/lead/utility/leadtest',
    getAcademicsList: '/lead/application/new/academics',
    monthList: '/lead/utility/months',
    fairList: '/lead/fair/search',
    addFairList: '/lead/fair/add',
    updateFairList: '/lead/fair/update',
    viewLeadByFairId: '/lead/fair/list',
    // getEmployeeByType: '/employee/list/bytype',
    getEmployeeByType: '/employee/admission-rep/list',
    closedLeadUpdate: '/lead/closedLeadsUpdate',
    leadByFairId: '/lead/fair/export',
    getBulkUploadList: '/lead/notification/importedFiles',
    bulkUploadedDocumentDownload: '/lead/notification/importedFile/get',
    bulkSampleFileDownload: '/lead/notification/leadimport/samplefile',
    getApplicationPdfContent: '/lead/application/pdf',
    emailTemplateSearch: '/lead/email/template/search',
    updateUserRights: '/admin/user/create',
    getUserAccessDetails: '/admin/user/getUserAccessRights',
    getUserList: '/admin/user/list',
    deleteUser: '/admin/user/delete',
    getUserAccessConfig: '/admin/user/configList',
    getSupervisorList: '/admin/user/getSupervisor',
    leadEstimateList: '/lead/estimate/get',
    addEstimate: '/lead/estimate/save',
    getEstimateById: '/lead/estimate',
    leadBulkUpdate: '/lead/bulk/update',
    leadBulkDelete: '/lead/bulk/delete',
    searchLeadMerge: '/lead/admin/duplicate/lead/search',
    getDuplicateLead: '/lead/admin/duplicate/lead/count',
    getDuplicateCount: '/lead/admin/duplicate/lead',
    leadMerge: '/lead/admin/merge',
    updateMoreInfo: '/lead/moreinfo/update',
    myDocuments: '/lead/notification/unverifiedDocList',
    duplicateLeadSearch: '/lead/admin/duplicate/lead/search/count',
    leadActivityLogs: '/lead/get/activitylog',
    saveLeadInterviewEvalInfo: '/lead/leadInteriewEval',
    getLeadInterviewEvalInfo: '/lead/leadInterviewEval',
    leadRepAssign: '/lead/admin/leadRepVacation',
    leadRepVacationList: '/lead/admin/repVacation',
    statusConversionData: '/lead/report/statusWiseconversion',
    pendingLeadList: '/lead/report/pendinglead',
    statusWise: '/lead/report/statusWisecount',
    statusWiseLeadByRep: '/lead/report/leadlist',
    exportpendingLeadList: '/lead/report/exportpendinglead',
    exportStatusWiseData: '/lead/report/exportStatusWiseData',
    LeadListByRepAndStatuss: '/lead/report/leadlist/statuswise',
    leadListByStatus: '/lead/report/leadlist/status',
    exportAssignedToLeadData: '/lead/report/exportAssignedToLeadData',
    dailyActivityCount: '/lead/report/dailyActivityCount',
    dailyActivityCountExportExcel: '/lead/report/dailyActivityCount/exportToExcel',
    dailyCountLeadList: '/lead/report/leadlist',
    reportsTaskCount: '/lead/report/taskcount',
    activityExcelExport: '/lead/report/exportDailyActivity',
    taskCountExcelExport: '/lead/report/exportTaskCount',
    reportExportExcel: '/lead/report/exportAssignedToLeadData',
    bulkCloseLeads: '/lead/bulk/close',
    // financiallyCleared: '/lead/search/financiallycleared',
    migrateToStudent: '/lead/migrateToStudent',
    sendDecision: '/lead/sendDecision',
    forcePasswordChange: '/lead/account/passwordchange',
    facultyTimeSlot: '/lead/utility/facultytimeslot',
    leadUploadImage: '/lead/document/upload',
    adminUploadXl: '/admin/file-upload',
    userNames: '/admin/user/usernames',
    studentAdmissionForms: '/lead/student/admissionfrom',
    studentAdmissionContact: '/lead/student/admissionfrom/conatctinfo',
    studentAdmissionDisclosure: '/lead/student/admissionfrom/disclosureConsent',
    studentAdmissionLicensure: '/lead/student/admissionfrom/licensurenotice',
    studentAdmissionMemorandum: '/lead/student/admissionfrom/memorandum',
    studentAdmissionPay: '/lead/student/admissionfrom/payseatdeposit',
    studentAdmissionStatement: '/lead/student/admissionfrom/statement',
    studentProfile: '/lead/student/admissionfrom/student',
    studentAdmissionTransferCredit: '/lead/student/admissionfrom/tranfercredit',
    studentAdmissionWhereYouHeard: '/lead/student/admissionfrom/whereyouheard',
    studentFormVerify: '/lead/student/formverification/verify',
    studentFormSendCode: '/lead/student/formverification/send/code',
    studentPersonal: '/lead/student',
    studentProfileHeader: '/lead/student/profileheader',
    studentGetAddress: '/lead/student/address',
    getDocumentList: '/lead/student/document/docList',
    downloadDocument: '/lead/student/document/download',
    getNotesList: '/lead/student/notes/list',
    updateStudentProfile: '/lead/student/update',
    studentAdd: '/lead/student/save',
    clinicalDocumentList: '/lead/student/hospital/documentList',
    downloadClinicalDocument: '/lead/student/hospital/document/download',
    clinicalDocumentAddHospitals: '/lead/student/hospital/document/save',
    documentFolderTypeList: '/lead/student/utility/folder',
    studentDocumentList: '/lead/student/document/docListLdSt',
    studentDocbyId: '/lead/student/document/get',
    studentDocumentSave: '/lead/student/document/upload',
    studentDocumentUpdate: '/lead/student/document/update',
    studentDocumentDownload: '/lead/student/document/save',
    studentDocumentdelete: '/lead/student/document/delete',
    studentDocumentSendEmail: '/lead/student/document/download/multiple',
    getStudentAcademicApplicant: '/lead/student/academicDetails/applicantid',
    updateStudentAcademicApplicant: '/lead/student/academicDetails/addapplicant',
    delStudentAcademicApplicant: '/lead/student/academicDetails/applicantid',
    studentAcademicAttending: '/lead/student/academicDetails/attendingdetails',
    updateStudentAcademicAttending: '/lead/student/academicDetails/addattendingdetails',
    studentAcademicNBMEList: '/lead/student/academicDetails/nbmelist',
    addStudentAcademicNBME: '/lead/student/academicDetails/addnbme',
    studentAcademicKaplanList: '/lead/student/academicDetails/kaplanlist',
    disciplineList: '/lead/utility/discipline',
    addStudentNbmeScore: '/lead/student/academicDetails/addnbme',
    addStudentKaplanScore: '/lead/student/academicDetails/addkaplan',
    getNbmeScoreDetails: '/lead/student/academicDetails/nbme',
    getKaplanScoreDetails: '/lead/student/academicDetails/kaplan',
    nbmeAttemptsNumber: '/lead/student/academicDetails/nbmeattempts',
    studentAddOrUpdateAddress: '/lead/student/save/address',
    studentNote: '/lead/student/notes/save',
    studentNotesList: '/lead/student/notes/list',
    studentNoteDeleteorGet: '/lead/student/notes',
    studentsendEmail: '/lead/student/email/senddocument',
    saveHospital: '/lead/student/hospital/save',
    hospitalList: '/lead/student/hospital/list',
    hospitalDataById: '/lead/student/hospital/get',
    avaliableRotations: '/lead/student/hospital/ct_available_rotations',
    thirdParty: '/lead/student/hospital/third_party_list',
    getStudentTypes: '/lead/student/utility/studenttype',
    getStudentCourseStatusList: '/lead/student/utility/coursestatus',
    getStudentStatusList: '/lead/student/utility/studentstatus',
    studentList: '/lead/student/search',
    studentCourseList: '/lead/student/course',
    studentRotationList: '/lead/clinicallog/rotations/list',
    clinicalLogSaveVerification: '/lead/clinicallog/verificationinfo',
    preceptors: '/lead/student/utility/preceptors',
    clericalInfo: '/lead/clinicallog/clericalinfo',
    saveclericalinfo: '/lead/clinicallog/clericalinfo',
    getVerificationInfo: '/lead/clinicallog/verificationinfo',
    getClinicalLogProblemOption: '/lead/clinicallog/clinicallog/questionnaire',
    clinicalProcedures: '/lead/clinicallog/clinialprocedures',
    rotationdates: '/lead/clinicallog/rotationdates',
    clinicalLogAcademicInfo: '/lead/clinicallog/clinicalacademicinfo',
    clinicalLogRAcademicList: '/lead/clinicallog/academicinfo/list',
    alumniPersonalInfoAdd: '/lead/student/alumni/addupdate/address',
    alumniPersonalInfoList: '/lead/student/alumni/get/list/address',
    alumniJobResidencyYear: '/lead/student/utility/alumniresidency',
    alumniJobSpecialty: '/lead/student/utility/alumnispeciality',
    alumniJobInfoAdd: '/lead/student/alumni/addupdate/jobinfo',
    alumniJobInfoById: '/lead/student/alumni/get/jobinfo',
    alumniJobInfoList: '/lead/student/alumni/get/list/jobinfo',
    alumniOtherDegreeAddOrUpdate: '/lead/student/alumni/addupdate/otherdegree',
    alumniOtherDegreeById: '/lead/student/alumni/get/otherdegree',
    alumniOtherDegreeList: '/lead/student/alumni/get/list/otherdegree',
    alumniMatchInfoAdd: '/lead/student/alumni/addupdate/matchinfo',
    studentCourse: '/lead/student/utility/course',
    courseDropdown: '/lead/student/course/course-dropdown',
    addStudentCourse: '/lead/student/course',
    getStudentCourseDetails: '/lead/student/course/get',
    clinicalRotationList: '/lead/student/course/rotation/list',
    studentResidencyProgramList: '/lead/student/course/residencyprogram/list',
    addStudentResidencyProgram: '/lead/student/course/residencyprogram/add',
    getStudentResidencyProgram: '/lead/student/course/residencyprogram',
    studentResidencySpecialityList: '/lead/student/utility/residencyspeciality',
    studentResidencyProgramType: '/lead/student/utility/residencyprogramType',
    clinicalLogAcademicData: '/lead/clinicallog/academicinfoexp',
    clinicalLogEvaluationDownload: '/lead/clinicallog/downloadevalform',
    getDownloadClinicalLoginfo: '/lead/clinicallog/clinicallogpdf',
    rotationDropdown: '/lead/student/course/rotation',
    rotationGradeType: '/lead/student/utility/gradepoint',
    rotationStatus: '/lead/student/utility/gradepointtitle',
    rotationHospital: '/lead/student/utility/hospitalrotation',
    rotationPreceptor: '/lead/student/utility/preceptors',
    rotationExportToExcel: '/lead/student/course/rotation/download',
    addRotation: '/lead/student/course/rotation',
    studentHopital: '/lead/student/utility/hospital',
    rotationDelete: '/lead/student/course/rotation',
    addClinicalTransfer: '/lead/student/course/rotation/transfer/add',
    clinicalTransferList: '/lead/student/course/rotation/transfer/list/get',
    clinicalTransferDelete: '/lead/student/course/rotation/transfer/delete',
    getTransferData: '/lead/student/course/rotation/transfer/get',
    deleteStudentCourse: '/lead/student/course',
    editRotation: '/lead/student/course/rotation',
    clericalInfoListadmin: '/lead/clinicallog/rotationdatesinfo',
    addNonClinicalCourseClasses: '/lead/student/nonClinicalCourse/add/classes',
    stageDropdownList: '/lead/student/utility/stage-list',
    getClassesListByStage: '/lead/student/utility/classes-list',
    gradeTypeList: '/lead/student/utility/gradepoint',
    gradePointStatusDropdown: '/lead/student/utility/gradepointtitle',
    nonClinicalClasses: '/lead/student/nonClinicalCourse/classes/list',
    nonClinicalStages: '/lead/student/nonClinicalCourse/stage/list',
    studentStageSemester: '/lead/student/nonClinicalCourse/stage',
    registerStudentStage: '/lead/student/nonClinicalCourse/stage/register',
    promoteStudentStage: '/lead/student/nonClinicalCourse/stage/promote',
    repeatStudentStage: '/lead/student/nonClinicalCourse/stage/repeat',
    specialStudentStage: '/lead/student/nonClinicalCourse/stage/special',
    nonClinicalAcademicStanding: '/lead/student/nonClinicalCourse/academicStandings',
    academicStandingDropdown: '/lead/student/utility/academicStandings/list',
    updateNonClinicalAcademicStanding: '/lead/student/nonClinicalCourse/academicStandings/add',
    getNonClinicalTransfer: '/lead/student/nonClinicalCourse/transfer/get',
    nonClinicalTransferList: '/lead/student/nonClinicalCourse/transfer/get/list',
    deleteNonClinicalTransfer: '/lead/student/nonClinicalCourse/transfer/delete',
    updateNonClinicalTransfer: '/lead/student/nonClinicalCourse/transfer/addupdate',
    deleteNonClinicalClasses: '/lead/student/nonClinicalCourse/classes',
    academicStanding: '/lead/student/course/academicStandings',
    academicStandingDropDown: '/lead/student/utility/academicStandings/list',
    getSpecialStageDropdown: '/lead/student/utility/special/stagelist',
    nonClinicalClassDetails: '/lead/student/nonClinicalCourse/classes/get',
    transferClassDropdown: '/lead/student/nonClinicalCourse/classList',
    usmleTestList: '/lead/test/usmle-list',
    noOfAttempts: '/lead/test/usmleattempts',
    addUsmleTest: '/lead/test/usmle/add',
    usmleTestById: '/lead/test/usmle',
    deleteUsmleTest: '/lead/test/usmle',
    usmleTestLoaEdit: '/lead/test/editloadate',
    clinicalHospitalList: '/lead/student/hospitalList',
    clinicalsRotationList: '/lead/clinical/assign-rotation/list',
    exportStudentListExcel: '/lead/student/list/export',
    exportStudentListEmailExcel: '/lead/student/list/exportnameemail',
    exportStudentListAddress: '/lead/student/list/exportaddress',
    studentLeaveList: '/lead/student/leaves/list',
    studentLeaveReason: '/lead/student/utility/loa',
    getstudentLeave: '/lead/student/leaves/get',
    studentLeaveAdd: '/lead/student/leaves/save',
    studentLeaveDelete: '/lead/student/leaves/delete',
    clinicalsClinicalLog: '/lead/student/clinicals/clinicallog/search',
    rotationListActive: '/lead/student/utility/rotation-list',
    clinicalPreceptorList: '/lead/student/preceptor/list',
    clinicalPreceptorAdd: '/lead/student/preceptor/save',
    studentClinicalDocList: '/lead/student/clinical-document/clinical-document-list',
    studentClinicalDocAdd: '/lead/student/clinical-document/clinicaldocument',
    studentClinicalType: '/lead/student/clinical-document/clinical-document-types',
    studentClinicalDoc: '/lead/student/clinical-document',
    stageStatus: '/lead/student/utility/stagestatus',
    clinicalStudentList: '/lead/clinical/assign-rotation/student/list',
    pricePerWeekHospi: '/lead/clinical/assign-rotation/hospital-ppw',
    clinicalAssignRotation: '/lead/clinical/assign-rotation',
    getReplyToMails: '/lead/clinical/assign-rotation/reply-to-mails',
    rotationWithWeeks: '/lead/clinical/assign-rotation/rotation/list',
    preceptorDataById: '/lead/student/preceptor/get',
    updateClinicalAcademicStanding: '/lead/student/course/academicStandings/add',
    alumniMatchInfoExist: '/lead/student/alumni/get/matchinfo',
    getAddressApplicantDetails: '/lead/appliant/profile',
    studentMcatList: '/lead/application/student/mcat/list',
    studentMcatSave: '/lead/application/student/mcat/save',
    getMcatDetails: '/lead/application/student/mcat',
    sendStudentMail: '/lead/student/email/sendMailStude',
    // QUERIES -------------------------------------------------------
    getProgramWithdrawalList: '/lead/student/query/programWithdrawalform/list/get',
    programWithdrawalAdd: '/lead/student/query/programWithdrawalform/addupdate',
    getProgramWithdrawalData: '/lead/student/query/programWithdrawalform/get',
    getClassWithdrawalList: '/lead/student/query/class-withdrawal-list',
    getClassWithdrawalAdd: '/lead/student/query/class-withdrawal/addupdate',
    getClassWithdrawalData: '/lead/student/query/class-withdrawal',
    enrollmentRequestAdd: '/lead/student/query/enrollment-request/addupdate',
    enrollmentRequestList: '/lead/student/query/enrollment-request-list',
    enrollmentRequestData: '/lead/student/query/enrollment-request',
    replacementDiplomaList: '/lead/student/query/replacment-request-list',
    replacementDiplomaAdd: '/lead/student/query/replacment-request/addupdate',
    getReplacementDiplomaData: '/lead/student/query/replacment-request',
    graduationFormAdd: '/lead/student/query/graduationform/addupdate',
    getGraduationList: '/lead/student/query/graduationform/list/get',
    getGraduationFormData: '/lead/student/query/graduationform/get',
    leaveOfAbsenceList: '/lead/student/query/leaveofabsence/list/get',
    leaveOfAbsenceAdd: '/lead/student/query/leaveofabsence/addupdate',
    getleaveOfAbsence: '/lead/student/query/leaveofabsence/get',
    complaintsList: '/lead/student/query/complaint/list',
    getComplaintData: '/lead/student/query/complaint',
    complaintAdd: '/lead/student/query/complaint',
    queryExistence: '/lead/student/query/existence',
    mspeQueryList: '/lead/student/query/mspeform-list',
    mspeAdd: '/lead/student/query/mspeform/addupdate',
    getMspedata: '/lead/student/query/mspeform',
    financeList: '/lead/student/query/finance-request/list',
    getFinanceData: '/lead/student/query/finance-request',
    financeAdd: '/lead/student/query/finance-request',
    appealAdd: '/lead/appeal',
    cancelAppeal: '/lead/appeal/cancelAppeal',
    sendDocuSignEmail: '/lead/appeal/sendDocuSignEmail',
    approveAppeal: '/lead/appeal/approve-conditions',
    acceptDisclosure: '/lead/appeal/accept-disclosure',
    appealList: '/lead/appeal/list',
    getAppealDetails: '/lead/appeal/get',
    appealExport: '/lead/appeal/list/exportToExcel',
    financeRequestLogs: '/lead/student/request/finance/request/logs',

    getStudentLoanById: '/lead/studentloan/get',
    getStudentLoanList: '/lead/studentloan/list',
    deletStudentLoanById: '/lead/studentloan/delete',
    nextSemester: '/lead/utility/getNextSemester',
    courseEvalationForm: '/lead/student/course/evaluation-form',
    acceptanceFilePreview: '/lead/document/acceptanceletter/download',
    programWithdrawalUpdate: '/lead/student/query/programWithdrawalform/addupdate',
    programWithdrawalById: '/lead/student/query/programWithdrawalform/get',
    classWithdrawalList: '/lead/student/request/classWithDrawal/list',
    mspeList: '/lead/student/request/mspeRequest/list',
    addCourseEvaluationForm: '/lead/student/course/evaluation-form',
    classWithdrawalById: '/lead/student/query/class-withdrawal',
    addClassWithdrawalById: '/lead/student/query/class-withdrawal/addupdate',
    programWithdrawalList: '/lead/student/request/programWithdrawal/list',
    studentFinanceList: '/lead/student/request/finance/list',
    uploadFileEvaluation: '/lead/student/course/evaluation-form/upload-file',
    studentFinanceListbyId: '/lead/student/query/finance-request',
    addSurveyFormCourse: '/lead/student/course/clinical-survey',
    getSurveyDetails: '/lead/student/course/clinical-survey',
    getMSPEListById: '/lead/student/query/mspeform',
    addUpdateMSPE: '/lead/student/query/mspeform/addupdate',
    studentFinanceAdd: '/lead/student/query/finance-request',
    studentFinanceDownload: '/lead/student/query/finance-request/download',
    // calendar API's
    saveCalendarEvent: '/lead/calendar/event/save',
    getCalendarEventTypes: '/lead/calendar/event/types',
    deletelCaledarEvent: '/lead/calendar/event/delete',
    getUsersList: '/lead/student/utility/users/list',
    getCalendarEventsList: '/lead/calendar/event/list',
    getCalendarEventGroup: '/lead/calendar/event/userTypeList',

    // banner API's
    getBannerList: '/lead/banners/list',
    addBanner: '/lead/banners/save',
    toggleStatusBanner: '/lead/banners/updateStatus',
    getBannerHomePage: '/lead/banners/banner-list',

    // request APi's
    getReplacementDiplomaList: '/lead/student/request/replacementDiploma/list',
    getReplacementDiploma: '/lead/student/query/replacment-request',
    addUpdatereplacement: '/lead/student/query/replacment-request/addupdate',

    getLeaveAbsenceList: '/lead/student/request/leaveAbsence/list',
    getLeaveAbsence: '/lead/student/query/leaveofabsence/get',
    addUpadteLeaveAbsence: '/lead/student/query/leaveofabsence/addupdate',

    forgotPassword: '/lead/account/forgotpassword',
    resetPassword: '/lead/account/resetpassword',

    addUpdateI94RequestForm: '/lead/student/query/i94request-form/addupdate',
    getI94RequestForm: '/lead/student/query/i94request-form',
    getI94RequestFormListStudent: '/lead/student/query/i94request-form-list',

    getGeneralQueryList: '/lead/student/query/query/list',
    getGeneralQuery: '/lead/student/query/query',
    addUpdateGeneralQuery: '/lead/student/query/query',

    // requests
    getI94LetterList: '/lead/student/request/i94request-form/search',
    getI94Letter: '/lead/student/query/i94request-form',
    updateI94Letter: '/lead/student/query/i94request-form/addupdate',
    getEnrollmentList: '/lead/student/request/enrollmentRequest/list',
    getEnrollmentRequest: '/lead/student/query/enrollment-request',
    updateEnrollmentRequest: '/lead/student/query/enrollment-request/addupdate',
    getGraduationRequestList: '/lead/student/request/graduationDiploma/list',
    getGraduationRequest: '/lead/student/query/graduationform/get',
    updateGraduationrequest: '/lead/student/query/graduationform/addupdate',
    getPreMedLoaList: '/lead/student/request/preMedicalLOA/list',
    getPreMedLoaRequest: '/lead/student/query/leaveofabsence/get',
    updatePreMedLoaRequest: '/lead/student/query/leaveofabsence/addupdate',
    getClinicalRequestList: '/lead/student/request/clinical-request/search',
    getClinicalRequest: '/lead/student/request/clinical-request',
    updateClinicalRequest: '/lead/student/request/rotation/update',
    clinicalRequestExport: '/lead/student/request/clinical-request/export',
    getComplaintRequestList: '/lead/student/request/complaint/list',
    getComplaintRequest: '/lead/student/query/complaint',
    addUpdateComplaint: '/lead/student/query/complaint',

    getSemesterList: '/admin/accounting/setting/installment-end-date/semester',
    getInstallmentEndDateSemesterData: '/admin/accounting/setting/installment-end-date',
    addUpdateInstallmentEndDate: '/admin/accounting/setting/installment-end-date/save',
    getMasterType: '/admin/accounting/setting/master-values/master-type',
    getMasterValuesList: '/admin/accounting/setting/master-values/list',
    getMasterValues: '/admin/accounting/setting/master-values',
    addUpdateMasterValues: '/admin/accounting/setting/master-values/save',
    getAccountHeadList: '/admin/accounting/setting/accounting-head/list',
    getAccountHead: '/admin/accounting/setting/accounting-head',
    addUpdateAccountHead: '/admin/accounting/setting/accounting-head/save',
    getFeeHeadList: '/admin/accounting/setting/fee-head/list',
    getFeeHead: '/admin/accounting/setting/fee-head/get',
    addUpdateFeeHead: '/admin/accounting/setting/fee-head/save',
    getFeeGroupHeadList: '/admin/accounting/setting/fee-group-head/list',
    getFeeGroupHead: '/admin/accounting/setting/fee-group-head/get',
    addUpdateFreeGroupHead: '/admin/accounting/setting/fee-group-head/save',
    getFreeGroupNameList: '/admin/accounting/setting/accountingfeegroup/list',
    getRevenueSettings: '/admin/accounting/setting/revenue-settings/get',
    saveRevenueSettings: '/admin/accounting/setting/revenue-settings/save',
    immigrationFeeList: '/admin/accounting/setting/immigrationfee/list',
    getImmigrtaionListById: '/admin/accounting/setting/immigrationfee/get',
    saveImmigration: '/admin/accounting/setting/immigrationfee/addUpdate',
    bulkAccountingList: '/admin/accounting/setting/bulkupdate/list',
    bulkUpdateAccounting: '/admin/accounting/setting/bulkupdate/updateaccountingstatus',
    bulkUpdateFinancial: '/admin/accounting/setting/bulkupdate/updatefinancialstatus',
    bulkInvoiceList: '/admin/accounting/setting/bulkupdate/invoicefinancialstatus',
    bulUpdateInvoiceStatus: '/admin/accounting/setting/bulkupdate/updateinvoicestatus',

    // Applicant CT
    getApplicantSemester: '/lead/account/applicant/semesterYear/list',
    addApplicantSemYear: '/lead/account/applicant/semesterYear/add',
    deleteApplicantSemYear: '/lead/account/applicant/semesterYear/delete',

    publishedScheduledList: '/employee/attendace/publishedschedule/publishedScheduleList',
    addHoliday: '/employee/attendance/holiday/add',
    holidayList: '/employee/attendance/holiday/list',
    getHolidayData: '/employee/attendance/holiday/get',
    semesterListAttendance: '/lead/attendance/semester/list',
    stageListAttendance: '/employee/attendance/stage-list',
    saveWeeklySchedule: '/employee/attendance/weeklySchedule/addUpdate',
    publishWeeklySchedule: '/employee/attendance/weeklySchedule/publish',
    weeklyFacultyList: '/employee/attendace/publishedschedule/weeklyFacultyScheduleList',
    subjectList: '/employee/attendace/publishedschedule/classsubjects',
    topicList: '/employee/attendace/publishedschedule/topics',
    weeklyScheduleList: '/employee/attendance/weeklySchedule/get',
    scheduleDatesList: '/employee/attendance/weeklySchedule/date',
    classUpdate: '/employee/attendance/weeklySchedule/updateClass',

    addUpdateStageSchedule: '/employee/attendance/stageschedule/addUpdate',
    getStageDaysCountList: '/employee/attendance/stageschedule/stagedayscount',
    getSlotsList: '/lead/student/utility/classes',
    getStageScheduleList: '/employee/attendance/stageschedule/list/',
    getStageSchedule: '/employee/attendance/stageschedule',

    semesterScheduleList: '/employee/attendance/semesterShedule/list',
    semesterScheduleView: '/employee/attendance/semesterSchedule/view',
    semesterScheduleGet: '/employee/attendance/semesterSchedule/get',
    semesterScheduleAdd: '/employee/attendance/semesterShedule/add',
    semesterStageList: '/employee/attendance/stage-list',
    pastAttendanceList: '/employee/attendance/pastattendance/search',
    facultyList: '/employee/attendace/publishedschedule/completeFacultyList',
    addSemesterStageDays: '/employee/attendance/semesterShedule/addStageDays',
    getSemesterStageDays: '/employee/attendance/weeklySchedule/getStageDays',
    clinicalRequestBasicCard: '/lead/student/course/course-status',

    downloadHandBook: '/lead/clinicallog/downloadhandbook',
    downloadRotationBook: '/lead/clinicallog/downloadsyllabus',
    savePolicyGuide: '/lead/clinicallog/clinicalrotationagree',
    studentCoursestatus: '/lead/student/course/course-status',

    getRotationList: '/lead/student/rotation/list',
    addUpdateStudentRotation: '/lead/student/rotation/request-rotation',

    getClinicalRoaster: '/lead/clinical/roster',
    updateClinicalRoaster: '/lead/clinical/roster/update-roster',
    cancelClinicalRequestRotation: '/lead/student/request/rotation/cancel',
    getClinicalAssignRotation: '/lead/clinical/assign-rotation',

    studentDetailsRequestList: '/lead/student/request/accounting/list',
    addStudentDetailsRequest: '/lead/student/request/accounting/add',
    applicantCourse: '/lead/academicrecords/applicantcourse',
    applicantmedicalcourse: '/lead/academicrecords/applicantmedicalcourse',
    applicantrotations: '/lead/academicrecords/applicantrotations',
    applicantStandardizedTestDropDown: '/lead/academicrecords/applicantstandardizedtest',

    academicRecords1List: '/lead/academicrecords/academicRecords1',
    saveAcademicRecords1: '/lead/academicrecords/academicrecords1',

    academicRecords2List: '/lead/academicrecords/academicRecords2',
    saveAcademicRecords2: '/lead/academicrecords/academicrecords2',

    academicRecords3List: '/lead/academicrecords/academicRecords3',
    saveAcademicRecords3: '/lead/academicrecords/academicrecords3',

    academicRecords4List: '/lead/academicrecords/academicRecords4',
    saveAcademicRecords4: '/lead/academicrecords/academicrecords4',
    getRequestedTransciptDataById: '/lead/student/trascript/get',
    getRequestedTransciptList: '/lead/student/trascript/get/list',
    saveStudentTranscript: '/lead/student/trascript/addorupdate',
    requestReason: '/lead/student/request/requestReason/list',
    examNameDropDown: '/lead/student/request/examName/list',
    feeNameDropDown: '/lead/student/request/feeName/list',
    shippingOptions: '/lead/student/trascript/shippingoptions',
    viewTranscriptCourse: '/lead/student/trascript/export/pdf/individual',
    assignRotationGraph: '/lead/clinical/assign-rotation/graph',
    assignRotationGraphExport: '/lead/clinical/assign-rotation/graph-export',
    studentRequestList: '/lead/student/request/list',
    addStudentRequest: '/lead/student/request/add',
    getClinicalRosterList: '/lead/clinical/roster/search',
    exportClinicalRosterList: '/lead/clinical/roster/exportRosterList',

    getThirdPartyList: '/lead/student/thirdParty/list',
    getThirdParty: '/lead/student/thirdParty/get',
    addOrEditThirdParty: '/lead/student/thirdParty/save',

    getStatusChangeList: '/lead/student/statusChange/statusList',
    getStatusChangeActivityLogList: '/lead/student/statusChange/activityLog/list',
    updateStatusChange: '/lead/student/statusChange/update',
    getStudentAccountingInfo: '/lead/student/accountinginfo/get',
    updateStudentAccountingInfo: '/lead/student/accountinginfo/update',
    getStudentAccountingMoreInfo: '/lead/student/accountingotherinfo/get',
    updateStudentAccountingOtherInfo: '/lead/student/accountingotherinfo/update',
    malPracticeInsuranceList: '/lead/student/malpracticeinsurance/malpracticeinsurance/list',
    addMalPracticeInsurance: '/lead/student/malpracticeinsurance/malpracticeinsurance/add',
    getMalPracticeInusrance: '/lead/student/malpracticeinsurance/get',

    getClinicalMedicineList: '/lead/student/profile/clinicalMedicine/list',
    getClinicalRotationList: '/lead/student/profile/clinicalRotation/list',
    getGPAList: '/lead/student/profile/gpa/list',
    getMedClassList: '/lead/student/profile/med/list',
    getPaymentOneList: '/lead/student/profile/paymentOne/list',
    getPaymentTwoList: '/lead/student/profile/paymentTwo/list',
    getPremedClassList: '/lead/student/profile/premed/list',

    addUpdateOtherDeatils: '/lead/student/otherdetailes/add',
    getOtherDetails: '/lead/student/otherdetailes/get',
    getDegreeList: '/lead/student/utility/degree-list',

    addOrUpdateStudentDegree: '/lead/student/degree/addorupdate',
    getStudentDegreeListByStudentId: '/lead/student/degree/degree/list',

    addPaySeatDeposit: '/lead/student/admissionfrom/payseatdeposit',
    getStudentEstimationValue: '/lead/student/admissionfrom/estimatevalue',

    getTranscriptRequestList: '/lead/student/request/transcript/list',
    payTranscriptRequest: '/lead/student/trascript/pay',
    rejectTranscriptRequest: '/lead/student/trascript/reject',
    getTranscriptRequestById: '/lead/student/trascript/get',
    graduationSurveyData: '/lead/student/course/graduation-survey',
    graduationSurveyList: '/lead/student/course/graduation-survey/list',
    submitGraduationSurvey: '/lead/student/course/graduation-form',

    // Credits and Scholarship
    creditRefund: '/lead/student/creditandscholarship/creditRefund',
    refundType: '/lead/student/creditandscholarship/refundPaymentTypes',
    creditsList: '/lead/student/creditandscholarship/creditScholarshipList',
    creditsDetails: '/lead/student/creditandscholarship/creditView',
    creditDelete: '/lead/student/creditandscholarship/deleteCredit',
    holdingCreditsRefund: '/lead/student/creditandscholarship/holdingAccountRefund',
    holdingCreditList: '/lead/student/creditandscholarship/holdingAccountList',
    directPaymentOptions: '/lead/student/creditandscholarship/directPaymentTypes',
    directPayment: '/lead/student/creditandscholarship/makeDirectPayment',
    creditPayment: '/lead/student/creditandscholarship/makeCardPayment',
    invoiceScholarShipView: '/lead/accounting/scholarshipView',
    noInVoiceView: '/lead/student/creditandscholarship/noInvoicePaymentView',
    updateRefNumber: '/lead/student/creditandscholarship/upadteReferenceNumber',

    // Accounting
    scholarshipList: '/lead/accounting/scholarship/search',
    createScholarShipList: '/lead/accounting/createscholarship/search',
    accountingCourse: '/lead/accounting/course',
    addScholarshipList: '/lead/accounting/createscholarshipstudentlist',
    reasonDropdown: '/lead/accounting/scholarshipelgiblity',
    nextStage: '/lead/accounting/currentAndNextStage',
    createScholarship: '/lead/accounting/createscholarship',
    studentCourseAgreeNextClass: '/lead/student/course/accept-classes',
    scholarhipSampleDocument: '/lead/accounting/scholarhip-add/sample/download',
    addScholarhipInBulk: '/lead/accounting/scholarhip-add/bulk',
    getPaymentsList: '/lead/accounting/payment/search',

    // Non-Clinical Invoices
    createNonClinicalInvoice: '/lead/student/nonclinical/invoice/create-invoice',
    nonCInvoiceHeadList: '/lead/student/nonclinical/invoice/invoiceheadlist',
    invoiceSemester: '/lead/student/nonclinical/invoice/invoicesemester',
    studentListForStep2NCI: '/lead/student/nonclinical/invoice/step2list',
    studentListForStep1NCI: '/lead/student/nonclinical/invoice/studentlist',
    studentListForStep1Special: '/lead/student/nonclinical/invoice/studentlistspecial',
    searchNonClinicalInvoiceList: '/lead/student/nonclinical/invoice/invoice-search',
    invoiceTitle: '/lead/student/nonclinical/invoice/invoice-title',
    dueInvoiceList: '/lead/student/nonclinical/invoice/pendinginvoice',
    dueInvoiceStep2List: '/lead/student/nonclinical/invoice/pendinginvoice/step2list',
    createDueInvoice: '/lead/student/nonclinical/invoice/pendinginvoice/create/',
    exportNonClinicalInvoice: '/lead/student/nonclinical/invoice/exportlist',
    downloadNCInvoicePDF: '/lead/student/nonclinical/invoice/invoice',
    getEstimateList: '/lead/student/nonclinical/invoice/estimates/studentlist',
    statusDropdown: '/lead/student/nonclinical/invoice/estimates/status',
    getEstimateStudentList: '/lead/student/nonclinical/invoice/estimates/step2list',
    createEstimates: '/lead/student/nonclinical/invoice/estimates/create-estimate',
    estimateList: '/lead/student/nonclinical/invoice/estimates/search',
    nonCInvoicesendEmail: '/lead/student/nonclinical/invoice/sendMail',

    // Clinical Invoices
    createClinicalInvoice: '/lead/student/clinical/invoice/create-invoice',
    studentListStep2CI: '/lead/student/clinical/invoice/step2list',
    studentListStep1CI: '/lead/student/clinical/invoice/studentlist',
    searchClinicalInvoiceList: '/lead/student/clinical/invoice/invoice-search',
    clinicalInvoiceHead: '/lead/student/clinical/invoice/invoiceheadlist',
    downloadClinicalInvoicePDF: '/lead/student/clinical/invoice/invoice',
    exportClinicalInvoice: '/lead/student/clinical/invoice/exportlist',
    clinicalInvoiceSemester: '/lead/student/clinical/invoice/semester',
    getWeeksPaid: '/lead/student/clinical/invoice/weeks',

    // Housing Invoices
    createHousingInvoice: '/lead/student/housing/invoice/create-invoice',
    exportHousingInvoice: '/lead/student/housing/invoice/exportlist',
    searchHousingInvoiceList: '/lead/student/housing/invoice/invoice-search',
    housingInvoiceTitle: '/lead/student/housing/invoice/invoice-title',
    housingInvoiceHeadList: '/lead/student/housing/invoice/invoiceheadlist',
    downloadHousingInvoicePDF: '/lead/student/housing/invoice/invoice',
    step2HousingInvoiceList: '/lead/student/housing/invoice/step2list',
    step1HousingInvoiceList: '/lead/student/housing/invoice/studentlist',

    getAlumniList: '/lead/student/alumni/get/list',
    alumniExportExcel: '/lead/student/alumni/list/export',
    alumniSendEmail: '/lead/student/alumni/sendMail',

    transcriptRequestExcelExport: '/lead/student/request/transcript-request/export',
    studentActivityLog: '/lead/student/get/activitylog',

    nextStageClasseslist: '/lead/student/nonClinicalCourse/get/class_list',
    libraryCatalog: '/lead/student/libraryCatalog/download',

    // Docusign
    docusignForm: '/lead/docusign/envelope/sign',
    docusignDownload: '/lead/docusign/envelope/document/download',
    graduationDocument: '/lead/docusign/envelope',

    // admin
    getLeadSettingDays: '/admin/leadSettings/days',
    getLeadSettingSlots: '/admin/leadSettings/timeSlots',
    getFacultyTimeSlotById: '/admin/leadSettings/getFacultyTimeSlot',
    getFacultyTimeSlotList: '/admin/leadSettings/timeSlotSearch',
    deleteFacultyTimeSlot: '/admin/leadSettings/deleteFacultyTimeSlot',
    addFacultyTimeSlot: '/admin/leadSettings/addFacultyTimeSlot',
    updateFacultyTimeSlot: '/admin/leadSettings/updateFacultyTimeSlot',
    getTimeTableScheduleList: '/employee/time-table/schedule-list',
    getTimeTableDetails: '/employee/time-table/schedule',
    getTimeTableStudents: '/employee/time-table/schedule/students',
    updateTimeTable: '/employee/time-table/schedule/save',

    feeSettingsInsuranceget: '/admin/accounting/setting/fees-settings/insurance',
    feeSettingsget: '/admin/accounting/setting/fees-settings',
    feeSettingsHistory: '/admin/accounting/setting/fees-settings/history/',
    transcriptPdfList: '/lead/student/trascript/get/pdflist',
    transciptFullPdf: '/lead/student/trascript/export/pdf/full',

    getAccountingLedgerList: '/lead/student/account/ledger/list',
    // invoicePaymnets
    getStudentCreditDetails: '/lead/student/invoice-paymnet/credit-details',
    getStudentInvoiceList: '/lead/student/invoice-paymnet/invoicelist',
    getInvoiceDetails: '/lead/student/invoice-paymnet/invoice',
    getCancelledInvoiceList: '/lead/student/invoice-paymnet/invoice/cancelledlist',
    updateInvoiceDueDate: '/lead/student/invoice-paymnet/invoice/updateduedate',
    updateInvoiceStage: '/lead/student/invoice-paymnet/invoice/updatesatge',
    cancelInvoice: '/lead/student/invoice-paymnet/invoice/cancel',
    updateFinancialCleared: '/lead/student/invoice-paymnet/invoice/updatefinancialcleared',
    getInvoiceType: '/lead/student/invoice-paymnet/invoicetype',
    getScholarshipList: '/lead/student/invoice-paymnet/scholarshipDetailsList',
    getPaymentList: '/lead/student/invoice-paymnet/studentPaymentDetails',
    getInvoiceHead: '/lead/student/invoice-paymnet/invoiceHeadList',
    createInvoice: '/lead/student/invoice-paymnet/createinvoice',
    getInvoiceAmount: '/lead/student/invoice-paymnet/invoiceAmount',
    getEstimateTypeList: '/lead/student/invoice-paymnet/seatdeposits/estimatetype/list',
    createEstimate: '/lead/student/invoice-paymnet/seatdeposits/create',
    getStudentSeatDeposits: '/lead/student/invoice-paymnet/seatdepositslist',
    getPayPaymentList: '/lead/student/invoice-paymnet/payment/invoicelist',
    payInvoice: '/lead/student/invoice-paymnet/payinvoice',
    getEstimateCourse: '/lead/student/invoice-paymnet/estimate/course',
    getEstimatesById: '/lead/student/invoice-paymnet/seatdeposits',
    getPaymentDetails: '/lead/student/invoice-paymnet/get/payment-details',
    cancelPayment: '/lead/student/invoice-paymnet/payment/cancel',
    // getEstimatePaymentDetails:'/lead/student/invoice-paymnet/seatdeposit/get/payment-details',
    payEstimate: '/lead/student/invoice-paymnet/seatdeposits/payestimate',
    financialStatusChange: '/lead/student/invoice-paymnet/payment/changestatus',
    getPayEstimateList: '/lead/student/invoice-paymnet/seatdeposit/payement-list',
    updatePartialPay: '/lead/student/invoice-paymnet/invoice/allowPartialpay',
    cancelEstimate: '/lead/student/invoice-paymnet/seatdeposits/cancel',
    getPaymentStatement: '/lead/student/invoice-paymnet/invoicePdf',
    getOldPayments: '/lead/student/invoice-paymnet/oldpayments/studentId',
    studentLoanFinancialAid: '/lead/studentloan/add',

    revenueList: '/lead/accounting/revenue/list',
    clinicalRevenueAccounting: '/lead/accounting/revenue/clinical-revenue/list',
    revenueCloserDates: '/lead/accounting/revenue/closer-date',

    verifiedDatesRevenue: '/lead/accounting/revenue/verified-revenue-date',
    verifyandCloseRevenue: '/lead/accounting/revenue/verify-and-close',
    nonClinicalRevenue: '/lead/accounting/revenue/non-clinical-revenue/list',

    getMedLoaCreateList: '/lead/accounting/loa/createLoa/list',
    getMed6loaInvoiceList: '/lead/accounting/loa/invoice/list',
    getMedLoaPaymentList: '/lead/accounting/loa/payment/list',
    getMed6loaStudentList: '/lead/accounting/loa/student/list',
    sendStudentEmail: '/lead/student/email/send',
    saveMed6Loa: '/lead/accounting/loa/save',
    getClincialCreateLoaList: '/lead/accounting/clinicalLoa/createLoa/list',
    getClincialLoaList: '/lead/accounting/clinicalLoa/invoice/list',
    saveClincalLoa: '/lead/accounting/clinicalLoa/save',
    verifyAndPayMed6Loa: '/lead/accounting/loa/verifyAndPay',

    studentRotationControllerList: '/lead/student/rotation',
    rotationPayNow: '/lead/student/rotation/pay-now',
    getRotationPaymentInfo: '/lead/student/rotation/payment-info',

    // Accepted Lead list in accounting
    acceptedLeadList: '/lead/accounting/lead-accepted/search',
    bankAccount: '/lead/accounting/notifications/bankAccount',
    unverifiedPaymentList: '/lead/accounting/notifications/search',
    updateUnverifiedPaymentList: '/lead/accounting/notifications/update',
    notificationsCount: '/lead/accounting/notifications/count',
    weeklyScheduleSemesterDropDown: '/employee/attendance/weeklySchedule/semesterDropDown',

    deleteDegreeById: '/lead/student/degree/degree/delete',
    getFcmGetDeviceDetails: '/lead/notification/deviceDetails/get',

    // Student Reports
    studentLeaveReportList: '/lead/student/report/leave/search',
    studentReportsList: '/lead/student/report/search',
    studentRotationCount: '/lead/student/report/rotation/count',
    studentRotationPrice: '/lead/student/report/rotation/price',
    studentBirthdayReport: '/lead/student/report/birthday',
    studentLogReports: '/lead/student/report/missinglog',
    transcriptReportsExport: '/employee/report/transcript/export',
    transcriptReports: '/employee/report/transcript',
    attendanceReport: '/employee/report/attendance',
    rotationCountReportsDetails: '/lead/student/report/rotation/count/detailes',
    rotationPriceReportsDetails: '/lead/student/report/rotation/price/detailes',
    studentReportExportToExcel: '/lead/student/report/student/exportlist',
    leaveReportExcel: '/lead/student/report/leave/exportlist',
    clinicalLogMissingExcel: '/lead/student/report/clinical/missinglog/exportlist',
    rotationCountExcel: '/lead/student/report/rotation/count/exportlist',
    rotationPriceExcel: '/lead/student/report/rotation/price/exportlist',
    classByCourseId: '/lead/student/nonClinicalCourse/classList',
    birthDayReportExportToExcel: '/lead/student/report/birthday/exportlist',
    rotationCountDetailsExportExcel: '/lead/student/report/rotation/count/detailes/exportlist',
    rotationPriceDetailsExportExcel: '/lead/student/report/rotation/price/detailes/exportlist',
    gradeReportList: '/lead/student/report/clinical/grade/report',
    gradereportNonClinicalList: '/lead/student/report/non-clinical/grade/report',
    gradeReportListExport: '/lead/student/report/clinical/grade/report/export',
    gradereportNonClinicalListExport: '/lead/student/report/non-clinical/grade/report/export',
    photoReportList: '/lead/student/report/photoreport',
    photoReportExportExcel: '/lead/student/report/photoreport/exporttoexcel',
    complaintReport: '/lead/report/temp/report/complaint',
    complaintReportExcelExport: '/lead/report/temp/report/complaint/export',
    paymentReport: '/lead/report/temp/report/payment',
    paymentReportExcelExport: '/lead/report/temp/report/payment/export',
    adjustmentReportList: '/lead/student/report/Adjustment-Invoice-Report',
    adjustmentReportExcelExport: '/lead/student/report/Adjustment-Invoice-Report/exportlist',
    addressReportList: '/lead/student/report/applicant/address-report',
    addressExportExcel: '/lead/student/report/applicant/address-report/export',
    balanceReportList: '/lead/student/report/student-balance/report',
    balanceExportExcel: '/lead/student/report/student-balance/exportlist',
    attendanceDetailsReport: '/employee/report/attendance/details',
    clinicalGraduationStatusReport: '/lead/student/report/clinical-garduation-report',
    clinicalGraduationStatusReportExportToExcel: '/lead/student/report/clinical-garduation-report-export',
    completedClinicalGraduationReport: '/lead/student/report/completed-clinical-garduation-report',
    completedClinicalGraduationReportExportToExcel: '/lead/student/report/completed-clinical-garduation-report-export',
    // applicant reports
    applicantPool: '/lead/student/report/applicant-pool',
    searchStageGpaReport: '/lead/student/report/Stage-GPA',
    stageGpaReportExportToExcel: '/lead/student/report/Stage-GPA/exportlist',
    med6LoaStudentLeaveTimeReport: '/lead/student/report/med6-loa-student-leave-time-report',
    med6LoaStudentLeaveTimeReportExportToExcel: '/lead/student/report/med6-loa-student-leave-time-report/exportToExcel',

    getLeadPaidList: '/lead/report/Report/leadpaidlist',
    exportLeadPaidList: '/lead/report/Lead-paid-list/report/exportlist',
    getPaidListReport: '/lead/report/Report/leadpaidlist/view',
    exportPaidListReport: '/lead/report/Lead-paid-list/view/exportlist',

    invoicePaymentReport: '/lead/student/report/invoice-payment-report',
    invoicePaymentReportExcel: '/lead/student/report/invoice-payment-report/exporttoexcel',

    problemList: '/lead/student/utility/clinical-log/problem-list',
    problemSearch: '/lead/student/report/case/report/problem',
    optionList: '/lead/student/utility/clinical-log/option-list',
    optionSearch: '/lead/student/report/case/report/option',
    optionExportToExcel: '/lead/student/report/case-report/by-option/exportlist',
    problemExportToExcel: '/lead/student/report/case-report/by-problem/exportlist',
    reportsDetails: '/lead/student/report/case/report/detail',

    paymentTagReport: '/lead/accounting-reports-controller/payment-tag-report',
    paymentTagReportExportoExcel: '/lead/accounting-reports-controller/payment-tag-report/exportToExcel',
    paymentTagReportBankAccount: '/lead/accounting-reports-controller/bank-account',
    paymentTagReportCreatedBy: '/lead/accounting-reports-controller/created-by',
    paymentTagReportView: '/lead/accounting-reports-controller/payment-tag-report/bind-grid',
    paymentTagReportViewExportToExcel: '/lead/accounting-reports-controller/payment-tag-report/bindData/exportToExcel',

    advancedReportStepOne: '/lead/advanced-reports-controller/step1',
    advancedReportDelete: '/lead/advanced-reports-controller/Delete',

    // Employee Reports
    employeeBirthDayReport: '/employee/report/birthday/list',

    // Plan Rotation
    addPlanRotation: '/lead/clinical/plan-rotation/plan',
    getStudentPlanRotationList: '/lead/clinical/plan-rotation/student/list',
    getStudentPlanRotationById: '/lead/clinical/plan-rotation/student/get',
    acceptStudentPlanRotation: '/lead/clinical/plan-rotation/status',
    getPlannedRotationList: '/lead/clinical/plan-rotation/list',
    getPlannedRotationDetails: '/lead/clinical/plan-rotation/get',
    scheduleRotation: '/lead/clinical/plan-rotation/schedule',
    getSemesterDateBySemesterId: '/lead/student/report/semesterdates',
    deletePlannedRotation: '/lead/clinical/plan-rotation/delete',

    // Employee Alert Config
    employeeNamesList: '/employee/alert-config/employee-names',
    mailAlertList: '/employee/alert-config',
    updateConfig: '/employee/alert-config',
    onDemandAlert: '/employee/alert-config/on-demand',

    getLeadStatus: '/lead/report/advanced/leadStatus',
    leadReportsAdvancedSearch: '/lead/report/advanced/search',
    leadAdvancedReportExport: '/lead/report/advanced/exportlist',
    getPreceptor: '/lead/student/utility/preceptor-list',
    feeHeadDropdown: '/admin/accounting/setting/fee-head',

    // Exams
    getKaplanListBySemesterId: '/lead/exam/kaplan-diagonistic/get',
    sendUploadDFile: '/lead/exam/kalpan-diagonistic/upload',
    addKaplanDiagonistic: '/lead/exam/kaplan-diagonistic/add',
    getKaplanSearch: '/lead/exam/kaplan-diagonistic/search',
    kaplanSampleFileDownload: '/lead/exam/kaplan-diagonistic/sample',
    nbmeCompReport: '/lead/exam/nbmeComp/report',
    nbmeCompExamList: '/lead/exam',
    nbmeCompDetailedReport: '/lead/exam/nbmeComp/detailedReport',
    nbmeCompUploadFile: '/lead/exam/nbmeComp/add/step1',
    addNbmeCompFile: '/lead/exam/nbmeComp/add/step2',
    nbmeComprehensiveSampleDocument: '/lead/exam/nbme-comprehensive/sample-document',
    nbmeShelfSampleDocument: '/lead/exam/nbme-shelf/sample-document',

    getShelfExamsList: '/lead/utility/discipline',
    getNbmeShelfReport: '/lead/exam/NBME-shelf/search-list',
    getNbmeShelfDetailedReport: '/lead/exam/NBME-shelf/detail/search-list',
    addNbmeShelf: '/lead/exam/NBME-shelf/add',
    uploadFileByNbme: '/lead/exam/NBME-shelf/upload',

    getRetakeExamDropdown: '/lead/exam/retake/list',
    getRetakeRotationExamDropdown: '/lead/exam/retake-rotation/list',
    getRetakeExamClinicalRotationList: '/lead/exam/retake/class-rotation/list',
    getRetakeExamStudentList: '/lead/exam/retake/student/list',
    postRetakeExamStudentDetails: '/lead/exam/retake',
    getRetakeExamRequestedStudentsList: '/lead/exam/retake/request/list',
    getRetakeExamByStudent: '/lead/exam/retake/student',
    getExamRequestDetails: '/lead/exam/retake/request',
    schduleExamRequest: '/lead/exam/retake/schedule',
    paidExamUpdate: '/lead/exam/retake/update',
    retakExamComplete: '/lead/exam/retake/complete',
    studentNotification: '/lead/student/notification/alert/get',

    leadReportSeatDeposit: '/lead/report/seatDepositduelist',
    leadReportSeatDepositExcelExport: '/lead/report/seatDepostduelist/export',

    // User profile and password
    getUserProfile: '/lead/student/userDetails',
    updateUserProfile: '/lead/student/userDetails/update',
    changeUserPassword: '/lead/student/userDetails/change/password',

    getPlannedCheckedStudents: '/lead/clinical/plan-rotation/students',
    getMed6LOAVerifyList: '/lead/accounting/loa/verifyAndPayList',
    payMed6LOAVerify: '/lead/accounting/loa/verifyAndPay',
    addSetUpRecurring: '/lead/student/invoice-paymnet/set-up-rc',
    getRCDetails: '/lead/student/invoice-paymnet/rc-details',
    deleteRCDetails: '/lead/student/invoice-paymnet/rc',
    interviewSchduledLeadReports: '/lead/report/interviewScheduled/search',
    interviewSchduledLeadReportsExport: '/lead/report/interviewScheduled/exportlist',
    getCampusListDropdown: '/lead/report/advanced/campus',

    // Student SMS
    studentSmsEmailList: '/lead/student/sms/emaillist',
    getStudentSmsDetails: '/lead/student/sms',
    updateStudentSMS: '/lead/student/sms/update',
    sendSMSCredentials: '/lead/student/sms/sendcredentials',

    getStudentEmailView: '/lead/student/email/profile/list',
    postStudentEmail: '/lead/student/email/profile/send',
    getStudentEmail: '/lead/student/email/profile/get',

    // Preceptor Login
    addEvaluation: '/lead/clinical/rotation/evaluation/add',
    getPreceptorRotationList: '/lead/clinical/rotation/evaluation/preceptor-login/list',
    getPreceptorRotationDetails: '/lead/clinical/rotation/evaluation/view',
    getPreceptorDetails: '/lead/student/preceptor/get/user',

    // survey response api call
    getRedirectUrlForSurvey: '/lead/survey',
    saveGraduationAlchmer: '/lead/survey/graduation/save',
    getResponseUrl: '/lead/survey/response',
    getNCSubmitedResponseUrl: '/lead/survey/non-clinical',
    getNCResponseUrl: '/lead/survey/response/non-clinical',
    getPreceptorList: '/lead/survey/preceptor',
    getRotationFeedbackUrl: '/lead/survey/feedback',
    getFeedbackResponseUrl: '/lead/survey/response/feedback',
    getPrecEvalResponseUrl: '/lead/survey/preceptor-survey',

    // mailLog
    incomingMailLog: '/admin/maillog/incoming/add',
    departmentDropdown: '/admin/utility/maillog/department',
    arubaIncomingMailLog: '/admin/maillog/arubaincoming/add',
    searchincomingMailLog: '/admin/maillog/incoming',
    enteredByDropdown: '/admin/utility/maillog/enteredby',
    addOutgoingMailLog: '/admin/maillog/outgoing/add',
    outMailLogSearch: '/admin/maillog/outgoing',
    arubaIncomingList: '/admin/maillog/arubaincoming',
    arubaArubaOutingMailLog: '/admin/maillog/arubaoutgoing/add',
    getArubaOutgoing: '/admin/maillog/arubaoutgoing',
    downloadOutgoingAttachment: '/admin/maillog/outgoing/attachmentdownload',
    downloadArubaOutgoingDownload: '/admin/maillog/arubaoutgoing/attachmentdownload',
    typeOfOutgoingDropdown: '/admin/utility/maillog/typeofoutgoingmail',
    arubaIncomingExportList: '/admin/maillog/arubaincoming/exportlist',
    arubaOutgoingMailLogExport: '/admin/maillog/arubaoutgoing/exportlist',
    incomingMailLogExport: '/admin/maillog/incoming/exportlist',
    outgoignMailLogExport: '/admin/maillog/outgoing/exportlist',
    deleteMailLog: '/admin/maillog/delete',
    returnedMailLogSearch: '/admin/maillog/returning',
    addReturnedMailLog: '/admin/maillog/returning/add',
    returnedMailLogExport: '/admin/maillog/returning/exportlist',
    downloadReturnedAttachment: '/admin/maillog/returning/attachmentdownload',
    getReturnedMailLogById: '/admin/maillog/returning',
    deleteReturnedMailLog: '/admin/maillog/returning/delete',

    // Reports
    exportStudentWithdrawnReport: '/lead/student/report/student-withdrawn-report/export',
    getStudentLedgerReport: '/lead/student/report/ledger',
    exportStudentLedgerReport: '/lead/student/report/ledger/exportlist',

    getInvoicePromotionalReport: '/lead/student/report/invoicepromotional-report',
    getInvoicePromotionalReportToExcel: '/lead/student/report/invoicepromotional-report/exporttoexcel',

    getBilledUnbilled: '/lead/report/temp/report/billed-unbilled',
    exportBilledUnbilled: '/lead/report/temp/report/billed-unbilled/export',

    getSemesterInvioceReport: '/lead/student/report/invoice-semester-report',
    getInvoiceSemesterReportForExport: '/lead/student/report/invoice-semester-report/export',

    getPendingInvoiceSearch: '/lead/accounting-reports-controller/pending-invoice-report',
    pendingInvoiceExportToExcel: '/lead/accounting-reports-controller/pending-invoice-report/exportToExcel',
    sendEmailPendingInvoice: '/lead/accounting-reports-controller/pending-invoice-report/send-email',

    getTuitionFeeAccepted: '/lead/report/temp/report/tuition-fee-accepted',
    exportTuitionFeeAccepted: '/lead/report/temp/report/tuition-fee-accepted/export',

    getInvoicePromotionalMismatchreport: '/lead/report/temp/report/invoice-promotional',
    ExportInvoicePromotionalMismatchreport: '/lead/report/temp/report/invoice-promotional/export',

    getUSMLEReport: '/lead/student/report/USMLE-Test-Report',
    getUSMLEDetailedReport: '/lead/student/report/USMLE-Test-Detaile-Report-New',
    usmleExportToExcel: '/lead/student/report/USMLE-Detiled-Report/exportlist',
    getClinicalMissingGradeReport: '/lead/student/report/clinical/missing-grade',
    exportClinicalMissingGradeReport: '/lead/student/report/clinical/missing-grade/exportlist',
    getApplicationStatusReport: '/lead/student/report/application/status',
    exportApplicationStatusReport: '/lead/student/report/application/status/exportlist',
    getStudentWithdrawnReport: '/lead/student/report/student-withdrawn-report',
    getSchloraSipReport: '/lead/student/report/scholarship-report',
    exportSchloarShip: '/lead/student/report/scholarship-report/export',
    getRefundReport: '/lead/student/report/refund-report',
    exportRefundReport: '/lead/student/report/refund-report/export',
    med6BalanceReport: '/lead/student/report/med6loa-balance-report',
    med6BalanceReportExport: '/lead/student/report/med6loa-balance-report/export',
    tutionFeeReportSearch: '/lead/report/temp/report/tuition-fee-finance-status',
    tutitionFeeExportToExcel: '/lead/report/temp/report/tuition-fee-finance-status/export',
    promotionalReportSearch: '/lead/report/temp/report/promotional',
    promotionalReportExport: '/lead/report/temp/report/promotional/export',
    administrativeInvoiceReportCount: '/lead/student/report/administrative-invoice-report/count',
    administrativeInvoiceReportDetails: '/lead/student/report/administrative-invoice-report/detail',
    administrativeInvoiceExport: '/lead/student/report/administrative-invoice-report/export',
    getScheduledAndAvailableReport: '/lead/student/report/scheduled-available-weeks/report',
    exportScheduledAndAvailableWeek: '/lead/student/report/Scheduled-Avialble-WeeksReport/exportlist',
    getArubaOutGoingList: '/admin/maillog/arubaoutgoing',
    postArubaOutGoingEmail: '/admin/maillog/arubaoutgoing/add',

    // admin ct hr section
    arubaOutgoingMailList: '/admin/control-table/hr/arubaoutgoing-maillogtype',
    getOutgoingMails: '/admin/control-table/hr/outgoing-maillogtype',
    getStatusList: '/admin/control-table/hr/todostatus',
    getPositionList: '/admin/control-table/hr/positions',
    getEmployeeStatus: '/admin/control-table/hr/employeestatus',
    postArubaOutgoingMail: '/admin/control-table/hr/arubaoutgoing-maillogtype/create',
    postOutgoingMails: '/admin/control-table/hr/outgoing-maillogtype/create',
    postStatusList: '/admin/control-table/hr/todostatus/create',
    postPositionList: '/admin/control-table/hr/positions/create',
    postEmployeeStatus: '/admin/control-table/hr/employeestatus/create',
    getArubaOutgoingMailById: '/admin/control-table/hr/arubaoutgoing-maillogtype',
    getOutgoingMailById: '/admin/control-table/hr/outgoing-maillogtype',
    getPositionsById: '/admin/control-table/hr/positions',
    getTodoStatusById: '/admin/control-table/hr/todostatus',
    getEmployeeStatusById: '/admin/control-table/hr/employeestatus',

    // admin ct
    getUsers: '/admin/user/admins',
    serachCourseAdmin: '/admin/control-table/admin/course',
    clinicalCourseAdmin: '/admin/control-table/admin/clinical-course',
    adminCtTable: '/admin/control-table/admin/semester-type/add',
    adminCtTableList: '/admin/control-table/admin/semester-type/search',
    semestertypeAdminbyid: '/admin/control-table/admin/semester-type',
    updateSemesertype: '/admin/control-table/admin/semester-type/update',
    adminClinicalProblemSearch: '/admin/control-table/clinical/problems',
    adminClinicalProblemAdd: '/admin/control-table/clinical/problems/create',
    clinicalsOptionsAdd: '/admin/control-table/clinical/options/create',
    clinicalsOptionsTableList: '/admin/control-table/clinical/options',
    clinicalDocumentAdd: '/admin/control-table/clinical/clinical-documenttype/create',
    getClinicaDocumentList: '/admin/control-table/clinical/clinical-documenttype',
    getClinicalDocumentbyId: '/admin/control-table/clinical/clinical-documenttype',
    problemDropDown: '/admin/utility/clinical/problem',

    getRotationsList: '/admin/control-table/clinical/rotation',
    rotationAdd: '/admin/control-table/clinical/rotation/create',
    getRotationById: '/admin/control-table/clinical/rotation',
    getRotationSyllabusList: '/admin/control-table/clinical/rotation-syllabus',
    addRotationSyllabus: '/admin/control-table/clinical/rotation-syllabus/create',

    semesterCtl: '/admin/control-table/admin/semester',
    getSemesterTypes: '/admin/control-table/admin/semester/semester-type',
    getstudentStatus: '/admin/control-table/admin/student-status',
    getStudentFolderSearch: '/admin/control-table/admin/student-folder',
    getRegistrationDateSearch: '/admin/control-table/admin/registration-date',
    downloadRotationSyllabus: '/admin/control-table/clinical/rotation-syllabus/attachmentdownload',
    getTaskPrioritySearch: '/admin/control-table/admin/task-priority',
    getStageSearch: '/admin/control-table/admin/stage',
    downloadClinicalEvaluationUpload: '/admin/control-table/clinical/clinical-evaluation/attachmentdownload',
    clinicalEvaluationUpload: '/admin/control-table/clinical/clinical-evaluation/upload',

    getDocumentCategoryList: '/admin/control-table/admin/document-category/list',
    postDocumentCategory: '/admin/control-table/admin/document-category/add',
    getDocumentCategoryById: '/admin/control-table/admin/document-category',
    getDocumentCategoryParentType: '/admin/utility/document-category/dropdown',

    getDocumentTemplateList: '/admin/control-table/admin/document-template/list',
    getDocumentTemplateById: '/admin/control-table/admin/document-template',
    postDocumentTemplate: '/admin/control-table/admin/document-template/add',
    downloadDocumentTemplate: '/admin/control-table/admin/document-template/download',

    // lead ct
    getReferralSourceList: '/admin/control-table/lead/referral-source',
    getReferralSourceData: '/admin/control-table/lead/referral-source',
    addReferralSource: '/admin/control-table/lead/referral-source/create',
    premedList: '/admin/control-table/lead/premed-highest-earned-degree',
    premedListById: '/admin/control-table/lead/premed-highest-earned-degree',
    addPremedDegree: '/admin/control-table/lead/premed-highest-earned-degree/create',

    // registrar ct
    getResidencyTypeList: '/admin/admin/control-table/registrar/residency-programm-type/search',
    getResidencyTypeData: '/admin/admin/control-table/registrar/residency-programm-type',
    addResidencyType: '/admin/admin/control-table/registrar/residency-programm-type/add',
    getResidencySpecialityList: '/admin/admin/control-table/registrar/residency-programm-speciality/search',
    getResidencySpecialityData: '/admin/admin/control-table/registrar/residency-programm-speciality',
    addResidencyProgram: '/admin/admin/control-table/registrar/residency-programm-speciality/add',
    gradePointTypeSearch: '/admin/admin/control-table/registrar/Grade-Point-Type/search',
    addGradePointType: '/admin/admin/control-table/registrar/Grade-Point-Type/add',
    getGradePointTypeById: '/admin/admin/control-table/registrar/Grade-Point-Type',
    academicStandingSearch: '/admin/admin/control-table/registrar/Academic-Standings/search',
    addAcademicStanding: '/admin/admin/control-table/registrar/Academic-Standings/add',
    getAcademicStandingData: '/admin/admin/control-table/registrar/Academic-Standings',
    subjectSearch: '/admin/admin/control-table/registrar/class-subject/search',
    getSubjectData: '/admin/admin/control-table/registrar/class-subject',
    addSubject: '/admin/admin/control-table/registrar/class-subject/add',
    shelfDisciplineSearch: '/admin/admin/control-table/registrar/NBME-shelf/search',
    addShelfDiscipline: '/admin/admin/control-table/registrar/NBME-shelf/add',
    getShelfDisciplineById: '/admin/admin/control-table/registrar/NBME-shelf',
    getCompDisciplineSearch: '/admin/admin/control-table/registrar/NBME-comp/search',
    getCompDisciplineData: '/admin/admin/control-table/registrar/NBME-comp',
    addCompDiscipline: '/admin/admin/control-table/registrar/NBME-comp/add',
    registrarDegreeSearch: '/admin/admin/control-table/registrar/degree/search',
    registrarDegreeAdd: '/admin/admin/control-table/registrar/degree/add',
    registrarDegreeById: '/admin/admin/control-table/registrar/degree',
    registrarGradePointSettingById: '/admin/admin/control-table/registrar/Grade-Point-Settings',
    registrarGradePointSettingSearch: '/admin/admin/control-table/registrar/Grade-Point-Settings/search',
    registrarGradePointSettingAdd: '/admin/admin/control-table/registrar/Grade-Point-Settings/add',
    registrarClassSearch: '/admin/admin/control-table/registrar/classes/search',
    registrarClassSave: '/admin/admin/control-table/registrar/classes/add',
    registrarClassId: '/admin/admin/control-table/registrar/classes',
    getCourseDropDown: '/admin/utility/course/list',
    getLoaReasonList: '/admin/admin/control-table/registrar/LOA-Reason/search',
    saveLoaReason: '/admin/admin/control-table/registrar/LOA-Reason/add',
    loaReasonById: '/admin/admin/control-table/registrar/LOA-Reason',
    equivalenceResgitrarSearch: '/admin/admin/control-table/registrar/equivalence-score/search',
    equivalenceResgitrarAdd: '/admin/admin/control-table/registrar/equivalence-score/add',
    equivalenceResgitrarById: '/admin/admin/control-table/registrar/equivalence-score',
    getStageByCourseAdmin: '/admin/admin/control-table/registrar/stage-list',

    searchScholarship: '/admin/admin/control-table/registrar/scholarship/search/',
    getScholarship: '/admin/admin/control-table/registrar/scholarship/get',
    addOrUpdateScholarship: '/admin/admin/control-table/registrar/scholarship/add',

    // hr ct
    hrIncomingMailLog: '/admin/control-table/hr/incoming-department',
    hrIncomingMailLogCreate: '/admin/control-table/hr/incoming-department/create',
    hrGetIncomingMailLogById: '/admin/control-table/hr/incoming-department',
    hrArubaIncoming: '/admin/control-table/hr/arubaincoming-department',
    hrArubaIncomingLogCreate: '/admin/control-table/hr/arubaincoming-department/create',
    hrArubaIncomingLogById: '/admin/control-table/hr/arubaincoming-department',
    getCountryList: '/admin/control-table/admin/country',

    // accounting Invoive Reminder
    getInvoiceRemindList: '/lead/accounting/invoices/reminder/list',
    getInvoiceRemindListBySemester: '/lead/accounting/invoices/reminderrecord',
    addInvoiceReminder: '/lead/accounting/invoices/reminder/add',

    // admin ct as
    getHelpText: '/admin/control-table/admin/helptext',
    addHelpText: '/admin/control-table/admin/helptext',
    getHelpTextById: '/admin/control-table/admin/helptext',
    updateHelpText: '/admin/control-table/admin/helptext',

    getEmailTemplateList: '/admin/control-table/admin/email-template',
    addEmailTemplate: '/admin/control-table/admin/email-template',
    getEmailTemplateById: '/admin/control-table/admin/email-template',
    editEmailTemplateById: '/admin/control-table/admin/email-template',
    getReplyToList: '/admin/control-table/admin/reply-to',
    addReplyTo: '/admin/control-table/admin/reply-to',
    getReplyToById: '/admin/control-table/admin/reply-to',
    editReplyToById: '/admin/control-table/admin/reply-to',
    getNursingStudentList: '/admin/control-table/admin/nursing-student-folder',
    addNursingStudentList: '/admin/control-table/admin/nursing-student-folder',
    getNursingStudentById: '/admin/control-table/admin/nursing-student-folder',
    editNursingStudentList: '/admin/control-table/admin/nursing-student-folder',

    // Assign Classes
    getAssignedClass: '/admin/assign-classes/getAssignClasses',
    addAssignClass: '/admin/assign-classes/addFacultyClasses',
    getAssignedClassById: '/admin/assign-classes/getAssignClasses',
    updateAssignClass: '/admin/assign-classes/updateAssignClasses',
    deleteAssignedClass: '/admin/assign-classes/deleteFacultyClasses',

    getEslStudentList: '/admin/control-table/admin/esl-student-folder',
    getSemesterTypeMonth: '/admin/leadSettings/getSemesterType',
    updateLeadCurrentSemister: '/admin/leadSettings/updateLeadCurrentSemister',
    getLeadCurrentSemester: '/admin/leadSettings/getLeadCurrentSemester',

    getLeadSources: '/admin/leadSettings/leadSourceSearch',
    getLeadSourceById: '/admin/leadSettings/getLeadSource',
    addLeadSource: '/admin/leadSettings/addLeadSource',

    getTaskSubject: '/admin/leadSettings/taskSubjects',
    getTaskSubjectById: '/admin/leadSettings/getTaskSubjects',
    addTaskSubject: '/admin/leadSettings/addTaskSubjects',

    // Accounting
    accountSearch: '/admin/admin/control-table/accounting/account/search',
    saveAccount: '/admin/admin/control-table/accounting/account/add',
    accountById: '/admin/admin/control-table/accounting/account',
    shippingCharges: '/admin/admin/control-table/accounting/shipping-price/add',
    shippingChargesUpdate: '/admin/admin/control-table/accounting/shipping-price/update',
    shippingChargesSearch: '/admin/admin/control-table/accounting/shipping-price/search',
    shippingChargesById: '/admin/admin/control-table/accounting/shipping-price',

    getSchoolList: '/admin/control-table/lead/schoolSearch',
    getSchoolById: '/admin/control-table/lead/getSchool',
    addSchool: '/admin/control-table/lead/addSchool',

    getFinancialAidList: '/admin/admin/control-table/accounting/financial-aid/search',
    getFinancialAidById: '/admin/admin/control-table/accounting/financial-aid',
    addFinancialAid: '/admin/admin/control-table/accounting/financial-aid/add',

    getViewAsStudent: '/lead/student/view-as-student',
    getParentDetail: '/lead/student/parent-detail/get',
    getParentDetailList: '/lead/student/parent-detail/list/get',
    addUpdateParentDetail: '/lead/student/parent-detail/add',

    // admin Alert config
    getConfigList: '/admin/alert/configuration/search',
    addAlertConfig: '/admin/alert/configuration/add-update',
    getConfigData: '/admin/alert/configuration/get',

    forceNotification: '/lead/student/force/notification/update',
    getForceNotification: '/lead/student/force/notification/get',
    getStudentListNotify: '/lead/student/force/notification/get/studnet',

    // update createdOn
    updateCreditCreatedOn: '/lead/student/creditandscholarship/updateCreditCreatedOnDate',
    updateScholarshipCreatedOn: '/lead/accounting/updateScholarshipCreatedOn',
    updateNoInvoiceCreatedOn: '/lead/student/creditandscholarship/updateNoInvoiceCreatedOn',
    updateInvoiceDate: '/lead/student/invoice-paymnet/updateInvoiceDate',

    // Terms and Conditions
    postTermsAndConditions: '/lead/email/terms-and-conditions',
    getEmployeeTermsAndConditionStatus: '/employee/get/hr/terms',
    // documents main

    sessionSeeddms: '/employee/seeddms/session',
    //  Committe
    addCommitte: '/employee/committe/add',
    getCommitte: '/employee/committe/get',
    searchCommitte: '/employee/committe/search/',

    // Committee Meeting
    addCommitteMeeting: '/employee/committe/meeting/add',
    getCommitteMeeting: '/employee/committe/meeting',
    searchCommitteMeeting: '/employee/committe/meeting/search/',
    getCommiteeDropdown: '/employee/committe/get/dropDown/',
    verifyMom: '/employee/committe/meeting/mom/sign',
    commiteeStatusReport: '/employee/committe/get/meeting/report',
    getDocusignSignStatus: '/employee/committe/get/meeting/docusing-report',
    getCommiteeMembers: '/employee/committe/get/committee/members',
    getCommitteeStudents: '/employee/committe/get/dropDown/student',
    updateCommitteeAttendance: '/employee/committe/markAttendance',
    getMomDocumentList: '/employee/committe/meeting/mom',
    committeeAttendanceList: '/employee/committe/markAttendance/search',
    getFacultyByGroup: '/admin/assign-classes/user/bygroupId',
    updateTermAndConditions: '/lead/email/terms-and-conditions/decision',
    addImmigration: '/lead/student/immigration/add-update',
    getImmigrationDetails: '/lead/student/immigration/get',
    immigrationDownload: '/lead/student/immigration/download',

    // Lead Dashboard
    getDashboardChart: '/lead/dashboard/chartData',
    getDashBoardLeadStatusCount: '/lead/dashboard/leadStatusCount',
    leadDashBoardStatusCountExportToExcel: '/lead/dashboard/exportLeadCount',
    leadDashBoardPaidSeatDepositCount: '/lead/dashboard/paidseat-deposite-count',
    leadDashboardInterviewScheduleCount: '/lead/dashboard/interview-schedule-count',
    leadDashboardUpcomingTaskReport: '/lead/dashboard/upcoming-task-report',

    getDashboardBillSummary: '/admin/dashboard/bill-summary',
    getDashboardAppealList: '/admin/dashboard/appeal',
    getAppealDashboardCountDetails: '/admin/dashboard/appeal/list',
    appealDashboardDetailsExportExcel: '/admin/dashboard/appeal/list/export',
    getSemesterInvoiceDetails: '/admin/dashboard/semester-invoice-details',
    getPaidCountList: '/admin/dashboard/paidCount',
    getStudentPaidCountList: '/admin/dashboard/newStudent/paidCount',
    approveFeedback: '/lead/webhook/approve-feedback',
    postStudentRotationList: '/admin/dashboard/student-rotation-list',
    postStudentRotationDetailList: '/admin/dashboard/student-rotation-detail-list',
    getLastNineSem: '/admin/dashboard/last9sem',
    getLast9ClinicalSemDetails: '/admin/dashboard/student-last-9sem-clinical',
    exportLast9ClinicalSemDetailsReport: '/admin/dashboard/student-last-9sem-clinical/exportlist',
    getLast9MedAndPremedSemDetails: '/admin/dashboard/student-last-9sem-med-premed',
    exportLast9MedAndPremedSemDetailsListReport: '/admin/dashboard/student-last-9sem-med-premed/exportlist',
    getStatusWiseReport: '/admin/dashboard/status-wise-report',
    getDetailedStatusWiseReport: '/admin/dashboard/status-wise-detailed-report',
    exportDetailedStatusWiseReport: '/admin/dashboard/status-wise-detailed-report/exportlist',
    getDashboardOrder: '/admin/dashboard/dashboard-order',
    addUpdateDashboardOrder: '/admin/dashboard/dashboard-order/addOrupdate',
    getRevenueReport: '/admin/dashboard/revenue-report',
    getRevenueReportExprtExl: '/admin/dashboard/revenue-report/export',
    getPaymentReport: '/admin/dashboard/payment-report',
    getStudentCount: '/admin/dashboard/student-count',
    paymentReportDetails: '/admin/dashboard/scholarship-used-report',
    paymentDetailsExport: '/admin/dashboard/scholarship-used-report/export',
    // notifications
    invoiceRequestList: '/lead/accounting/notifications/request/search',
    getNBMECompEquivalenceScore: '/lead/exam/nbme-comprehensive/score-document',

    invoiceHeadListRequest: '/lead/accounting/notifications/request/invoice-head-list',

    getStudentSmsNotification: '/lead/student/notification/pop/get',
    postStudentSmsNotification: '/lead/student/notification/pop/update',

    dailyCountLeadNewList: '/lead/report/dailyActivity/report-view',
    reportViewExportExcel: '/lead/report/dailyActivity/report-view/export',

    countryCodeList: '/lead/utility/country/code',
    billingContactInfoEducational: '/lead/student/admissionfrom/education/contact/save',
    documentMultiple: '/lead/student/document/upload/multiple',
    educationStudentAdmission: '/lead/student/admissionfrom/education/contact/get',
    getTaskList: '/employee/task/task-list',
    getTaskAssigned: '/employee/task/assign-dropDown',
    getTaskAssignedTo: '/employee/task/assign-to',
    addTask: '/employee/task/add',
    getTaskById: '/employee/task/task-list',
    updateTask: '/employee/task/update',
    getTaskByTaskId: '/employee/task',
    deleteEducationalInfo: '/lead/student/admissionfrom/education/contact/delete',
    getBillingInfo: '/lead/student/admissionfrom/billing/contact/get',
    addTodo: '/admin/todo/add',
    getTodoList: '/admin/todo/list',
    getTodoListByWeek: '/admin/todo/list/currentWeek',
    updateSatatus: '/admin/todo/update-status',

    clinicalLogsPdf: '/lead/clinicallog/export',
    clinicalLogUpload: '/lead/clinicallog/upload',
    paymentTagIdReport: '/lead/accounting/payment-tag/get',
    surveyResponsePending: '/lead/survey/survey-pending',
    getBillSummaryBySemesterId: '/admin/dashboard/payment-stage-summary-report',
    getSemesterInvoiceDetailsReport: '/admin/dashboard/semester-invoice-details-report',
    graduationSurveyPending: '/lead/student/course/survey-pending',
    getDefermentLogs: '/lead/get/deferment',
    getTutorialVideoList: '/lead/tutorial-video/list',

    todoExportToExcel: '/admin/todo/todo-export',
    paymentStageSummaryReportExport: '/admin/dashboard/payment-stage-summary-report/export',
    semesterInvoiceExportToExcel: '/admin/dashboard/semester-invoice-details-report/insurance/export',
    todoAdminExportToExcel: '/admin/todo/todo-admin-export',
    getActiveLeadsList: '/lead/utility/lead-active',
    roomAndBoardExportToExcel: '/admin/dashboard/semester-invoice-details-report/room-board/export',
    semesterTuitionExportToExcel: '/admin/dashboard/semester-invoice-details-report/tuition/export',
    exportTaskList: '/employee/task/export-task',

    getSemesterInvioceReportDashboard: '/admin/dashboard/semester-invoice-report',
    getInvoiceSemesterReportExportExcel: '/admin/dashboard/semester-invoice-report/export',

    getStudentAlumniEmailList: '/lead/student/alumni/list/mailing-details/export',

    addUserSignature: '/admin/user/signature/add',
    getUserSignature: '/admin/user/signature/get',
    dbDataEntryException: '/admin/dashboard/data-entry-exception-count',
    deleteStudentInvoiceItem: '/lead/student/invoice-paymnet/delete-invoice-item',
    dbMaillog: '/admin/dashboard/dashboard-maillog-count-last-week',
    dbDataEntryExecption: '/admin/dashboard/data-entry-exception-count',
    dbClinicalMarksException: '/admin/dashboard/clinical-marks-exception',
    dbMedPremedMarksException: '/admin/dashboard/med-preMed-marks-exception',
    dbMedPremedMarksExceptionExport: '/admin/dashboard/data-entry-exception/export',
    dbRotationClearanceDueList: '/admin/dashboard/rotation-clearance-due-count',
    dbRotationClearanceDueCount: '/admin/dashboard/rotation-clearance-due-list',
    dbPromotionSummaryReport: '/admin/dashboard/promotion-summary',
    dbStudentRotationList: '/admin/dashboard/student-rotation-list',
    getDownloadExportClinicalLoginfo: '/lead/clinicallog/export-all',

    getDbpaymentDetailedReport: '/admin/dashboard/payment-report-details',

    dbPaymentReportExportToExcel: '/admin/dashboard/payment-report-details/export',

    getDbStudentCountDetailedReport: '/admin/dashboard/student-count-by-semester',
    getDbStudentCountDetailedReportExport: '/admin/dashboard/student-count-by-semester/export',
    getActiveUsersList: '/admin/session/activeUserList/{name}',

    totalClassAttendace: '/employee/attendance_report/total_class',
    getFacultyById: '/employee/attendance_report/faculty_student',
    getIndividualStudent: '/employee/attendance_report/individual_student',
    getMultiStudent: '/employee/attendance_report/multi_student',

    getEmployeeNamesList: '/employee/attendance_report/faculty/list',
    getStudentNamesList: '/employee/attendance_report/student/list',
    attendanceClassList: '/employee/attendance_report/class/list',

    attendaceReportsCourseList: '/employee/attendance_report/course/list',
    attendanceReportSubjectList: '/employee/attendance_report/subject/list',
    getTotalSubjectList: '/employee/attendance_report/total_subject',
    attendanceReportTopicsList: '/employee/attendance_report/topic/list',
    getTotalTopicList: '/employee/attendance_report/total_topic',

    missingInfoReport: '/lead/student/report/missing-data',
    notifyAll: '/lead/student/force/notification/notify_all',
    userSessionLogout: '/admin/session/logout',
    nbmeComprehensiveExport: '/lead/exam/nbme/comprehensive/exportlist',
    snapshotreportDetails: '/lead/student/report/snapshot-report',
    studentRotationEndingWeeksReport: '/lead/student/report/rotation-ending-weeks/search',
    exportStudentRotationEndingWeeksReport: '/lead/student/report/rotation-ending-weeks/export',
    getNoInvoicePaymentList: '/lead/student/invoice-paymnet/studentPaymentDetails/no-invoice',
    getChairPersons: '/employee/committe/employee-list',
    postCommitteeFeedback: '/employee/committe/meeting/mom/feedback',
    getFeedback: '/employee/committe/meeting/mom/feedback/get',
    getParticipantsDetails: '/employee/committe/zoom/get-meeting/participants',
    getMeetingDetails: '/employee/committe/zoom/get-meeting-details',
    getMeetingRecording: '/employee/committe/zoom/get-meeting/recording',
    updateCommitteeMeeting: '/employee/committe/meeting/update',
    getFeedbackById: '/employee/committe/meeting/mom/feedback',
    getTopicList: '/admin/control-table/admin/topics/get-list',
    getTopicById: '/admin/control-table/admin/topics/getbyId',
    addCTTopic: '/admin/control-table/admin/topics/add',
    updateTopicById: '/admin/control-table/admin/topics/update',
    getMomDocument: '/employee/committe/meeting/document',
    getCommiteeActivityLog: '/employee/committe/get/committe/activitylog',
    getCommiteeMeetingActivityLog: '/employee/committe/get/meeting/activitylog',
    studentMed6Loadate: '/lead/student/nonClinicalCourse/med6loa-dates/get',
    studentMed6LoadateUpdate: '/lead/student/nonClinicalCourse/med6loa-dates/update',
    getSemesterDates: '/lead/semester/start-date',
    verifyImmigrationDocuments: '/lead/student/immigration/verify',
    getNbmeNoOfAttempts: '/lead/student/academicDetails/noOfAttempts',

    nonClinicalTransferListExpToExl: '/lead/student/nonClinicalCourse/transfer/list/export',
    clinicalTransferListExpToExl: '/lead/student/course/rotation/transfer/clinical/list/export',

    med6LoaDeadLine: '/admin/dashboard/med6Loa/count',
    getMed6LoaDetailedLlist: '/admin/dashboard/med6Loa/detailed',

    invoiceSemesterCountReport: '/lead/student/report/invoice-semester-report-count',
    invoiceSemesterCountDetailedReport: '/lead/student/report/invoice-semester-report-count/details',
    clinicalRotationCountReport: '/lead/student/report/clinical-rotation-count',
    getClinicalRotationDetailedReport: '/lead/student/report/clinical-rotation-count/detailed',
    clinicalRotationDetailedReportExportToExcel: '/lead/student/report/clinical-rotation-count/detailed/export',

    med6LoaCbseStatusReport: '/lead/student/report/cbse-count',

    getCBSEExamReportList: '/lead/student/report/cbse/list',
    getCBSEExamReportExport: '/lead/student/report/cbse/list/export',

    getHospitalReportList: '/lead/student/report/hospital-report',
    hopitalReportExcelExport: '/lead/student/report/hospital-report/exportlist',

    getImmigrationReportList: '/lead/student/report/immigratioion-report/list',
    getImmigrationEpxortList: '/lead/student/report/immigratioion-report/export',
    // Survey CT
    classChairSave: '/admin/control-table/survey/class-chair/save',
    classChairById: '/admin/control-table/survey/class-chair',
    classChairList: '/admin/control-table/survey/class-chair/list',
    basicScienceSurvey: '/admin/control-table/survey/basic-science/list',
    basicScienceSurveySave: '/admin/control-table/survey/basic-science/save',
    getSurveyList: '/admin/control-table/survey/survey/list',
    getSurveyListDropDown: '/admin/control-table/survey/survey/dropdown',
    addUpdateSurvey: '/admin/control-table/survey/survey/save',

    nbmeComprehensiveDetailedExport: '/lead/exam/nbmeComp/detailedReport/exportToExcel',
    nbmeShelfExport: '/lead/exam/NBME-shelf/search-list/exportToExcel',
    med6LoaCbseDetailed: '/lead/student/report/cbse-count/detailed',
    nbmeShelfDetailedExport: '/lead/exam/NBME-shelf/search-list/detailed/exportToExcel',

    creditDetailsCreditView: '/lead/student/creditandscholarship/creditView',
    creditDetailsScholarShipView: '/lead/student/creditandscholarship/scholarshipView',
    creditDetailsNoInvoiceView: '/lead/student/creditandscholarship/noInvoicePaymentView',
    updateCreditDetailsRefNumber: '/lead/student/creditandscholarship/upadteReferenceNumber',
    graduateSurveyReportList: '/lead/survey-report/list',
    surveyReportExportToExcel: '/lead/survey-report/basic-science-list/export',
    getBasicScienceSurveyList: '/lead/survey-report/basic-science-list',
    creditBalanceReportList: '/lead/student/report/credit-balance-report',
    creditBalanceReportExportList: '/lead/student/report/credit-balance-report/export',
    getBulkNotifyreport: '/lead/student/report/bulk-notify-report',
    getSpecialStageDropdownForCreditReport: '/lead/student/utility/special/stagelist/credit-report',
    plannedRotationsHopitals: '/lead/student/utility/hospitalsrotations',
    getBulkNotifyEpxortList: '/lead/student/report/bulk-notify-report/export',
    leadStatusWiseReportExport: '/lead/report/statusWisecount/export',
    getStudentProfilePicBinary: '/lead/student/profilePicUrl',

    getMedGraderListReportExcel: '/lead/student/report/med-grade-report/export',
    getMedGradeListReportStudent: '/lead/student/report/med-grade-report-student',
    getMedGradeListReportStudentDetails: '/lead/student/report/med-grade-report',

    getArubaStudentCountExcelReport: '/lead/student/report/invoice-semester-report-count/details/export',
    getCbseCountDetailedExportList: '/lead/student/report/cbse-count/detailed/export',
    getMedSixLoaEndCountDetailedExportList: '/admin/dashboard/med6Loa/detailed/export',
    getMenuAccess: '/admin/menu/access/list',
    saveUpdateMenuAccess: '/admin/menu/access/save',

    surveySendMail: '/admin/control-table/survey/survey/sendMail',
    getSurveyListOptions: '/admin/control-table/survey/survey/dropdown',
    getExamTypeSub: '/lead/exam/retake/subject/list',
    docusignDocs: '/lead/student/document/docuSign/list',
    triggerAtrium: '/admin/triggers/atrium-ftp',
    getCreditScholarshipDeletedList: '/lead/student/creditandscholarship/creditScholarshipDeleted',
    creditScholarshipDeleted: '/lead/student/creditandscholarship/creditScholarshipDeleted',
    getAllClassList: '/lead/student/utility/all-classes-list',

    // HousingModule
    addSuites: '/housing/control-table/suit/add',
    suitesList: '/housing/control-table/suit/list',
    updateSuites: '/housing/control-table/suit/update',
    getSuiteData: '/housing/control-table/suit',
    getMealPlanList: '/housing/control-table/mealplan/list',
    addMealPlan: '/housing/control-table/mealplan/addorupdate',
    getMealPlanDetails: '/housing/control-table/mealplan',
    mealPlanFees: '/housing/fee-setting/mealplan-fee',
    roomFeesAdd: '/housing/fee-setting/suit-fees',
    getFeesData: '/housing/fee-setting/suit-meal-fee',
    getFeeSettingHistory: '/housing/fee-setting/suit-meal-fee/history',
    // assignSuite: '/housing/suite/assign',
    assignSuite: '/housing/change/moveInOut',
    deleteSuite: '/housing/assigned/delete',
    exportHousingList: '/housing/assign/list/export',
    searchHousingList: '/housing/search',
    getStudentList: '/housing/control-table/student/list',
    getSuitAndRoom: '/housing/control-table/suit/dropdown',
    getEntireSuitAndRoom: '/housing/control-table/entire-suit/dropdown',
    movingDatesCTList: '/housing/control-table/movein-moveout/list',
    addUpdateMovingDates: '/housing/control-table/movein-moveout/addUpdate',
    getDatesById: '/housing/control-table/movein-moveout',

    getHousingInvoiceAmount: '/housing/fee-setting/feeBySemester',
    getSuiteOrRoomDetails: '/housing/suite/assign',
    getSuiteOrMealbyId: '/housing/fee-setting/suite-meal-fee/details',
    getStudentHousingDetails: '/housing/student/suite/list',
    assignQueue: '/housing/queue/assign',
    deleteQueue: '/housing/queue/delete',
    getQueue: '/housing/queue/search',
    sendDocusignContract: '/housing/send-contract',

    housingAllocateSearch: '/housing/allocated/search',
    updatehousingAllocation: '/housing/allocate/edit',
    addHousingAllocate: '/housing/allocate',
    deleteHousingAllocation: '/housing/allocated/delete',
    exportHousingAllocation: '/housing/allocated/list/export',
    assignRoomorSuite: '/housing/assign',
    queueExportExcel: '/housing/queue/list/export',
    exportAssignListToExcel: '/housing/assign/list/export',
    deleteAssignedSuiteorRoom: '/housing/assigned/delete',
    getBulkAssignAllStudentsList: '/housing/assigned/students/list',
    housingMasterList: '/housing/masterList',
    masterListExportExcel: '/housing/masterList/export',
    housingQueueRoomSuite: '/housing/control-table/suit/dropdown/queue',

    // Lead Unverified
    unverifiedEmailList: '/lead/unverified/list',
    getUnverifiedEmail: '/lead/unverified/list',
    updateUnverifiedEmail: '/lead/unverified/markedRead',
    convertToLeadFromEmail: '/lead/unverified/convertToLead',
    discardUnverifiedEmail: '/lead/unverified/discardEmail',
    bulkSuiteAssign: '/housing/suite/bulk/assign',
    mergeUnverifiedEmail: '/lead/unverified/mergeEmail',
    markAsRead: '/lead/email/markUnread',

    // Exams Bulk Update
    systemSampleFileDownload: '/lead/student/academicDetails/NBME-subject/sample-document',
    systemAddDocument: '/lead/student/academicDetails/NBME-subject/add',
    systemDocumentUpload: '/lead/student/academicDetails/NBME-subject/upload',
    rotationSampleFileDownload: '/lead/student/academicDetails/NBME-rotation/sample-document',
    rotationAddDocument: '/lead/student/academicDetails/NBME-rotation/add',
    rotationUploadDocument: '/lead/student/academicDetails/NBME-rotation/upload',
    comprehensiveSampleFileDownload: '/lead/student/academicDetails/NBME-comprehensive/sample-document',
    comprehensiveAddDocument: '/lead/student/academicDetails/NBME-comprehensive/add',
    comprehensiveUploadDocument: '/lead/student/academicDetails/NBME-comprehensive/upload',
    getBulkUpdateList: '/lead/student/academicDetails/NBME-subject/search-list',
    bulkUpdateExportToExcel: '/lead/student/academicDetails/NBME-subject/search-list/export',
    comprehensiveBulkUploadExcel: '/lead/student/academicDetails/NBME-comprehensive/search-list/export',
    comprehensiveBulkUploadList: '/lead/student/academicDetails/NBME-comprehensive/search-list',
    getCurrentFutureSemester: '/lead/student/nonclinical/invoice/invoicesemester/current-future',
    getStudentTimeTable: '/employee/attendance/student/time-table/get',
  }
};
