import { combineReducers } from 'redux';
import calenderReducer from './calendar';
import emailReducer from './email';
import chatReducer from './chat';
import todoReducer from './todo';
import customizer from './customizer';
import auth from './auth';
import navbar from './navbar/Index';
import dataList from './data-list';
import employeeModule from './employee';
import leadModule from './lead';
import adminModule from './admin';
import oauth from './oauth';
import controlDataReducer from './commonControlData';
import clinicalsModule from './clinicals';
import requestModule from './requests';
import attendanceReducer from './attendance';
import accountingReducer from './accounting';
import reportsReducer from './reports';
import examsReducer from './exams';
import settingsReducer from './settings';
import mailLogReducer from './mailLog';
import documentReducer from './documents';
import housingModule from './housing';

const rootReducer = combineReducers({
  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer,
  auth,
  navbar,
  dataList,
  employeeModule,
  leadModule,
  adminModule,
  oauth,
  controlDataReducer,
  clinicalsModule,
  requestModule,
  attendanceReducer,
  accountingReducer,
  reportsReducer,
  examsReducer,
  settingsReducer,
  mailLogReducer,
  documentReducer,
  housingModule
});

export default rootReducer;
